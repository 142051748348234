import { TaxCode } from "./TaxCode";
import { Condition } from "./Condition";
export class CreateTaxCodeDefinition {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CreateTaxCodeDefinition
     */
    this.$type = "V1.TaxEngine.CreateTaxCodeDefinition";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Conditions"] && _data["Conditions"].constructor === Array) {
        this.Conditions = [];
        for (let item of _data["Conditions"]) {
          const conditions_ = new Condition();
          this.Conditions.push(conditions_.init(item));
        }
      }
      this.ReturnType = CreateTaxCodeDefinitionReturnType[_data["ReturnType"]];
      if (_data["TaxCode"]) {
        const taxCode_ = new TaxCode();
        this.TaxCode = taxCode_.init(_data["TaxCode"]);
      }
      this.TaxCodeStr = _data["TaxCodeStr"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.Conditions && this.Conditions.constructor === Array) {
      _data["Conditions"] = [];
      for (let item of this.Conditions) {
        _data["Conditions"].push(item.toJSON());
      }
    }
    _data["ReturnType"] = CreateTaxCodeDefinitionReturnType[this.ReturnType];
    _data["TaxCode"] = this.TaxCode ? this.TaxCode.toJSON() : void 0;
    _data["TaxCodeStr"] = this.TaxCodeStr;
    return _data;
  }
}
export var CreateTaxCodeDefinitionReturnType = /* @__PURE__ */ ((CreateTaxCodeDefinitionReturnType2) => {
  CreateTaxCodeDefinitionReturnType2["VAT"] = "VAT";
  CreateTaxCodeDefinitionReturnType2["IOSS"] = "IOSS";
  return CreateTaxCodeDefinitionReturnType2;
})(CreateTaxCodeDefinitionReturnType || {});
