import { authenticatedFetch } from "./Authentication";
export class ClientManager {
  constructor(abortSignal) {
    this.abortSignal = abortSignal;
  }
  createSelfClient(ctor) {
    return new ctor(window.location.origin, {
      fetch: authenticatedFetch(this.abortSignal)
    });
  }
}
