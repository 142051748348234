import i18next from "i18next";
export function padLeft(num, size = 2) {
  let text = String(Math.abs(num));
  while (text.length < size) {
    text = `0${text}`;
  }
  return num < 0 ? `-${text}` : text;
}
export function padRight(num, size = 2) {
  let text = String(Math.abs(num));
  while (text.length < size) {
    text = `${text}0`;
  }
  return num < 0 ? `-${text}` : text;
}
export function isEmpty(text) {
  return text === void 0 || text.length === 0;
}
export function newUniqueId() {
  return Math.random().toString(36).substr(2, 9);
}
export function onlyAlpha(val) {
  const regex = RegExp(/^[a-zA-Z]*$/);
  return regex.test(val);
}
export function onlyAlphaNum(val) {
  const regex = RegExp(/^[a-zA-Z0-9]*$/);
  return regex.test(val);
}
export const INVALID_MESSAGE = i18next.t("Invalid");
export const REQUIRED_MESSAGE = i18next.t("Required");
export const MAX_DATE_MESSAGE = i18next.t("Date must be before 2100-01-01");
export const ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE = i18next.t("Only Latin characters are allowed");
export const ONLY_EUROPEAN_CHARS_REGEX = RegExp(/^[a-zA-Z\u00C0-\u024F\u0020-\u0040]*$/);
export function onlyEuropeanCharacters(val) {
  const regex = ONLY_EUROPEAN_CHARS_REGEX;
  return regex.test(val);
}
export const leftPad = (formattable, length, character) => {
  if (!formattable)
    return "";
  if (formattable.toString().length >= length)
    return formattable.toString();
  const count = length - formattable.toString().length + 1;
  return `${Array(count).join(character)}${formattable}`;
};
export const testRegex = (value, regex) => {
  return value.toString().match(regex) !== null;
};
