var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./vatregs.scss";
import { useClient, useEffect, useLoadingReducer, useState } from "GlobalShared/haunted/CustomHooks";
import { notifyError, notifyErrorWithWarning, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import { html } from "lit-html";
import { formatDate } from "GlobalShared/helpers/dateHelper";
import { navigateTo } from "GlobalShared/router";
import { currentLocale, getRouteByKey } from "../../globals";
import { removeIcon, templateTitle } from "GlobalShared/templates/commons";
import {
  getVatRegistrationStatusChange
} from "GlobalShared/models/VatRegModels";
import {
  VatRegistration as VatRegistrationClient,
  MasterData as MasterDataClient,
  SubmissionPeriods as SubmissionPeriodsClient,
  Agents as AgentsClient,
  Company as CompaniesClient
} from "AdminServices/APIs/self/clients";
import { mapSubmissionConfig } from "GlobalShared/models/ServicesModels";
import { VatRegField, VatRegFilter, VatRegPager, VatRegSearchRequest } from "AdminServices/APIs/self/models/V1/Company/VatRegistration/Search";
import { VatRegFieldOrder } from "AdminServices/APIs/self/models/V1/Company/VatRegistration/Search/VatRegField";
import { useCommentModal } from "GlobalShared/components/reg-modals/comment-modal";
import { VatRegFilterCompanyStatus } from "AdminServices/APIs/self/models/V1/Company/VatRegistration/Search/VatRegFilterAbstraction";
import { useEUCountries, usePermissionManager } from "AdminComponents/hooks/AdminCustomHooks";
import { useVatRegLocalVatModal } from "GlobalShared/components/reg-modals/local-vat-modal";
import { useVatRegEuVatModal } from "GlobalShared/components/reg-modals/euvat-modal";
import { SearchRequest as AgentSearchRequest, Field as AgentField, Filter as AgentFilter, Pager as AgentPager } from "AdminServices/APIs/self/models/V1/Agents/Search";
import { FieldOrder as AgentFieldOrder, FieldOrderByField as AgentFieldOrderByField } from "AdminServices/APIs/self/models/V1/Agents/Search/Field";
import { UpdateAdministrator } from "AdminServices/APIs/self/models/V1/Company/VatRegistration";
import { useDeregistrationModal } from "GlobalShared/components/reg-modals/deregistration-modal";
import { getStatusLabel } from "GlobalShared/components/services/services-common";
import { usePoARevokedModal } from "GlobalShared/components/reg-modals/poarevoke-modal";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import moment from "moment";
import { IntrastatInSubscription } from "AdminServices/APIs/self/models/V1/CompanyInfo/Subscriptions/IntrastatInSubscription";
import { IntrastatOutSubscription } from "AdminServices/APIs/self/models/V1/CompanyInfo/Subscriptions/IntrastatOutSubscription";
const FieldNames = {
  CompanyId: "CompanyId",
  CompanyName: "CompanyName",
  ClientSource: "ClientSource",
  Country: "Country",
  SignUpDate: "SignUpDate",
  CompanyStatus: "CompanyStatus",
  SubscriptionStatus: "SubscriptionStatus",
  ResponsibleEmail: "ResponsibleEmail",
  RegType: "RegType",
  VatRegistrationStatus: "VatRegistrationStatus",
  SubscriptionValidTo: "SubscriptionValidTo",
  Actions: "Actions"
};
const DEFAULT_FILTERS = [];
const VatRegStatuses = [
  {
    label: "Pending",
    value: "Pending"
  },
  {
    label: "Waiting for signature",
    value: "WaitingForSignature"
  },
  {
    label: "Documents signed",
    value: "DocumentsSigned"
  },
  {
    label: "Translation in progress",
    value: "TranslationInProgress"
  },
  {
    label: "Sent to TA",
    value: "SentToTA"
  },
  {
    label: "Local Tax Number Saved",
    value: "LocalTaxReceived"
  },
  {
    label: "Completed",
    value: "RegistrationCompleted"
  },
  {
    label: "Deregistration In Progress",
    value: "DeregistrationInProgress"
  },
  {
    label: "Deregistered",
    value: "Deregistered"
  },
  {
    label: "Revoking PoA",
    value: "RevokingPoA"
  },
  {
    label: "PoA revoked",
    value: "PoARevoked"
  }
];
const SearchRegTypes = ["NewReg", "TakOver"];
const ClientSources = ["Amazon", "FedEx", "KPMG", "LaPoste"];
const CompanyStatuses = ["Active", "Inactive", "Rejected"];
const SubscriptionStatuses = ["Active", "Cancelled", "Expired"];
const AGENT_ID_TO_DELETE = "-1";
export function VatRegs() {
  const getVatRegStatusLabel = (status) => {
    const item = VatRegStatuses.find((s) => s.value === status);
    return item && item.label ? item.label : status;
  };
  const changeVatRegStatus = (companyId, vatRegId, oldStatus, newStatus, comment, registrationCompletedChange, localVatCertificate, deregisterChange, poARevokedChange) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        dispatchLoading("inc");
        yield vatRegistrationClient.changeVatRegStatus({
          companyId,
          vatRegId,
          body: getVatRegistrationStatusChange(oldStatus, newStatus, comment, registrationCompletedChange, localVatCertificate, deregisterChange, poARevokedChange)
        });
        loadRegistrations();
        notifySuccess("Status changed");
      }),
      (error) => {
        notifyError(error);
      },
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const loadSubmissionConfig = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield submissionPeriodsClient.getFrequencyConfigurationOptions();
        setSubmissionConfig(mapSubmissionConfig(response.data));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getAvailableStatusChanges = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield vatRegistrationClient.getAvailableStatusChanges();
        setValidVatTransitions(response.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const uploadDocument = (companyId, vatRegId, file) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield vatRegistrationClient.uploadDocument({
          companyId,
          vatRegId,
          file
        });
        notifySuccess("Document Uploaded");
        return result.data;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getFilter = () => {
    const vatRegFilter = new VatRegFilter();
    if (!showAll && !gridState.appliedFilters.some((filter) => filter.field === FieldNames.CompanyStatus)) {
      vatRegFilter.CompanyStatus = VatRegFilterCompanyStatus.Active;
    }
    if (onlyAssignedToMe) {
      vatRegFilter.IsAssignedToMe = true;
    }
    if (serviceExpired && !gridState.appliedFilters.some((filter) => filter.field === FieldNames.SubscriptionValidTo || filter.field === FieldNames.VatRegistrationStatus)) {
      vatRegFilter.VatRegistrationStatuses = ["LocalTaxReceived", "RegistrationCompleted"];
      vatRegFilter.SubscriptionValidToTo = moment().add(-1, "days").toDate();
    }
    gridState.appliedFilters.forEach((filter) => {
      if (filter.field === FieldNames.CompanyId) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.CompanyId = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.CompanyName) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.CompanyName = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.VatRegistrationStatus) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.VatRegistrationStatuses = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.Country) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.Country = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.ResponsibleEmail) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.ResponsibleEmailPart = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.SubscriptionStatus) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.SubscriptionStatus = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.RegType) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.RegType = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.ClientSource) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.ClientSource = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.CompanyStatus) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.CompanyStatus = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.SignUpDate) {
        if (filter.filterProps.min || filter.filterProps.max) {
          vatRegFilter.SignupDateFrom = filter.filterProps.min;
          vatRegFilter.SignupDateTo = filter.filterProps.max;
        }
      } else if (filter.field === FieldNames.SubscriptionValidTo) {
        if (filter.filterProps.min || filter.filterProps.max) {
          vatRegFilter.SubscriptionValidToFrom = filter.filterProps.min;
          vatRegFilter.SubscriptionValidToTo = filter.filterProps.max;
        }
      }
    });
    return vatRegFilter;
  };
  const loadRegistrations = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const result = (yield vatRegistrationClient.searchVatRegistration({
          body: new VatRegSearchRequest({
            Filter: getFilter(),
            Pager: new VatRegPager({
              Skip: gridState.pageIndex * gridState.pageSize,
              Top: gridState.pageSize,
              OrderBy: [
                new VatRegField({
                  Order: gridState.orderDir === "asc" ? VatRegFieldOrder.Ascending : VatRegFieldOrder.Descending,
                  OrderByField: gridState.orderBy
                })
              ]
            })
          })
        })).data;
        setSearchResult(result);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const loadServiceCountries = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const result = (yield masterDataClient.getServiceCountries()).data;
        setServiceCountries(result);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const loadPredefinedBankAccounts = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield vatRegistrationClient.getPredefinedBankAccounts();
        const config = response.data.map((pre) => {
          const vm = {
            Id: pre.Id,
            BankAddress: pre.BankAddress,
            BankName: pre.BankName,
            BeneficiaryAddress: pre.BeneficiaryAddress,
            BeneficiaryName: pre.BeneficiaryName,
            CountryCode: pre.CountryCode,
            EstablishmentCountryIsInEU: pre.EstablishmentCountryIsInEU,
            Iban: pre.Iban,
            Swift: pre.Swift
          };
          return vm;
        });
        setPredefinedBankAccounts(config);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const searchAgents = (emailFragment, roleNames) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const agentsResult = yield agentsClient.searchAgents({
          body: new AgentSearchRequest({
            Filter: new AgentFilter({ Email: emailFragment, RoleNames: roleNames }),
            Pager: new AgentPager({
              Skip: 0,
              Top: 1e3,
              OrderBy: [
                new AgentField({
                  Order: AgentFieldOrder.Ascending,
                  OrderByField: AgentFieldOrderByField.Email
                })
              ]
            })
          })
        });
        return agentsResult.data.Agents;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const assignManager = () => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        if (currentAssignManagerVM.responsibleId) {
          if (currentAssignManagerVM.responsibleId !== AGENT_ID_TO_DELETE) {
            yield vatRegistrationClient.updateAdministrator(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
              body: new UpdateAdministrator({
                UserId: currentAssignManagerVM.responsibleId
              })
            }));
          } else {
            yield vatRegistrationClient.deleteAdministrator(__spreadValues({}, currentAssignManagerVM));
          }
        }
        yield loadRegistrations();
        notifySuccess("Responsible saved successfully");
        setShowAssignModal(false);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const onAssignClicked = (vatReg) => {
    setCurrentAssignManagerVM({
      companyId: vatReg.CompanyId,
      vatRegistrationId: vatReg.VatRegId,
      responsibleEmail: vatReg.ResponsibleEmail
    });
    setShowAssignModal(true);
  };
  const getValidNextVatTransition = (from) => {
    var _a;
    return (_a = validVatTransitions == null ? void 0 : validVatTransitions.reduce((aggr, curr) => {
      if (curr.From === from) {
        return aggr.concat(curr.To);
      } else {
        return aggr;
      }
    }, [])) != null ? _a : [];
  };
  const exportVatRegistrations = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const result = (yield vatRegistrationClient.exportVatRegistrations({
          body: new VatRegSearchRequest({
            Filter: getFilter(),
            Pager: new VatRegPager({
              Skip: 0,
              Top: searchResult.Count,
              OrderBy: [
                new VatRegField({
                  Order: gridState.orderDir === "asc" ? VatRegFieldOrder.Ascending : VatRegFieldOrder.Descending,
                  OrderByField: gridState.orderBy
                })
              ]
            })
          })
        })).data;
        simulateDownload(result);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const resetFilters = () => {
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex: 0, appliedFilters: DEFAULT_FILTERS }));
  };
  const vatRegistrationClient = useClient(VatRegistrationClient);
  const submissionPeriodsClient = useClient(SubmissionPeriodsClient);
  const masterDataClient = useClient(MasterDataClient);
  const agentsClient = useClient(AgentsClient);
  const companiesClient = useClient(CompaniesClient);
  const [gridState, setGridState] = useState({
    pageIndex: 0,
    appliedFilters: DEFAULT_FILTERS,
    pageSize: 20,
    orderBy: FieldNames.CompanyName,
    orderDir: "asc"
  });
  const [showAll, setShowAll] = useState(false);
  const [onlyAssignedToMe, setOnlyAssignedToMe] = useState(false);
  const [serviceExpired, setServiceExpired] = useState(false);
  const [searchResult, setSearchResult] = useState(void 0);
  const [serviceCountries, setServiceCountries] = useState([]);
  const [currentCommentModalProps, setCurrentCommentModalProps] = useState(void 0);
  const commentModal = useCommentModal(currentCommentModalProps);
  const [currentLocalVatModalProps, setCurrentLocalVatModalProps] = useState(void 0);
  const localVatModal = useVatRegLocalVatModal(currentLocalVatModalProps);
  const [currentEuVatModalProps, setCurrentEuVatModalProps] = useState(void 0);
  const euVatModal = useVatRegEuVatModal(currentEuVatModalProps);
  const [currentDeregistrationModalProps, setCurrentDeregistrationModalProps] = useState(void 0);
  const deregistrationModal = useDeregistrationModal(currentDeregistrationModalProps);
  const [currentPoARevokedModalProps, setCurrentPoARevokedModalProps] = useState(void 0);
  const poaRevokedModal = usePoARevokedModal(currentPoARevokedModalProps);
  const euCountries = useEUCountries(currentLocale);
  const [predefinedBankAccounts, setPredefinedBankAccounts] = useState(void 0);
  const [submissionConfig, setSubmissionConfig] = useState(void 0);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [currentAssignManagerVM, setCurrentAssignManagerVM] = useState(void 0);
  const permissionManager = usePermissionManager();
  const [validVatTransitions, setValidVatTransitions] = useState([]);
  const [loading, dispatchLoading] = useLoadingReducer();
  const init = () => {
    loadRegistrations();
    loadServiceCountries();
    loadPredefinedBankAccounts();
    loadSubmissionConfig();
    getAvailableStatusChanges();
  };
  useEffect(init, []);
  useEffect(() => __async(this, null, function* () {
    loadRegistrations();
  }), [gridState, showAll, onlyAssignedToMe, serviceExpired]);
  const onEditClicked = (clientId) => {
    navigateTo(getRouteByKey("clients(/:clientId)").replace(/\(\/:clientId\)/, `/${clientId}`));
  };
  const onSortChanged = (e) => {
    const OrderByValue = e.detail.orderBy;
    const OrderDirValue = e.detail.orderDir;
    setGridState(__spreadProps(__spreadValues({}, gridState), { orderBy: OrderByValue, orderDir: OrderDirValue }));
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridFilter);
  };
  const htmlAssignModal = () => {
    return html`<dc-modal
			.visible=${showAssignModal}
			@close=${() => setShowAssignModal(false)}
			.header=${"Assign responsible"}
			.content=${currentAssignManagerVM ? html`<div>
						<div class="grid md:grid-cols-3 gap-8">
							<div class="flex space-x-4 items-center">
								<dc-select
									class="w-full"
									.label=${"Responsible"}
									.dataSource=${(text) => __async(this, null, function* () {
      if (text === void 0) {
        return currentAssignManagerVM.responsibleEmail ? [
          {
            value: currentAssignManagerVM.responsibleEmail,
            label: currentAssignManagerVM.responsibleEmail
          }
        ] : "Type at least 2 characters";
      } else if (text.length < 2) {
        return "Type at least 2 characters";
      } else {
        const result = yield searchAgents(text, ["countrylead", "fiscalrepresentative"]);
        if (result.length > 0) {
          setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
            responsibleSearchResult: result
          }));
          return result.map((r) => ({ value: r.Email, label: r.Email }));
        } else {
          return "No result found";
        }
      }
    })}
									.filterable=${true}
									.debounceMs=${150}
									.selectedValues=${currentAssignManagerVM.responsibleEmail}
									@change=${(e) => {
      const agent = currentAssignManagerVM.responsibleSearchResult.find((a) => a.Email === e.detail.selectedValue);
      setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
        responsibleEmail: agent.Email,
        responsibleId: agent.Id
      }));
    }}
								></dc-select>
								<div
									class="cursor-pointer mt-6 remove-icon-container"
									@click=${() => __async(this, null, function* () {
      setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
        responsibleEmail: void 0,
        responsibleId: AGENT_ID_TO_DELETE
      }));
    })}
								>
									${removeIcon()}
								</div>
							</div>
						</div>
						<div class="mt-4 flex space-x-4 pb-64">
							<button class="btn btn-primary block" @click=${() => setShowAssignModal(false)}>Cancel</button><button class="btn btn-primary block" @click=${assignManager}>Save</button>
						</div>
				  </div>` : ""}
		>
		</dc-modal>`;
  };
  const htmlGrid = () => {
    var _a;
    const vm = {
      columns: [
        {
          field: FieldNames.CompanyId,
          columnClass: "w-16",
          label: "ID",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: FieldNames.CompanyName,
          label: "Client Name",
          sortable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: FieldNames.ClientSource,
          label: "Source",
          columnType: "enum",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: ClientSources
          }
        },
        {
          field: FieldNames.Country,
          columnClass: "w-20",
          label: "Country",
          sortable: true,
          columnType: "enum",
          filterDescriptor: {
            type: "select",
            dataSource: serviceCountries.map((sc) => ({ label: sc.Name, value: sc.Code })),
            searchable: true,
            searchProps: { type: "includes" }
          }
        },
        {
          field: FieldNames.SignUpDate,
          label: "Signup Date",
          sortable: true,
          columnType: "date",
          filterDescriptor: { from: true, to: true, type: "date" }
        },
        {
          field: FieldNames.SubscriptionValidTo,
          label: "Exp. Date",
          sortable: true,
          columnType: "date",
          filterDescriptor: { from: true, to: true, type: "date" }
        },
        {
          field: FieldNames.SubscriptionStatus,
          label: "Sub. status",
          columnTitle: "Subscription status",
          columnType: "enum",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: SubscriptionStatuses
          }
        },
        {
          field: FieldNames.ResponsibleEmail,
          label: "Responsible email",
          sortable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: FieldNames.RegType,
          label: "Reg.Type",
          columnTitle: "Registration Type",
          columnType: "enum",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: SearchRegTypes
          }
        },
        {
          field: FieldNames.CompanyStatus,
          label: "C. Status",
          columnTitle: "Company status",
          columnType: "enum",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: CompanyStatuses
          }
        },
        {
          field: FieldNames.VatRegistrationStatus,
          label: "Status",
          columnType: "enum",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            searchable: true,
            multiSelect: true,
            type: "select",
            dataSource: VatRegStatuses
          }
        },
        { field: FieldNames.Actions, label: "" }
      ],
      data: searchResult.VatRegSearchContents,
      sorting: {
        orderBy: gridState.orderBy,
        orderDir: gridState.orderDir
      },
      cellClass: (_index, field) => {
        if (field === FieldNames.VatRegistrationStatus) {
          return "actions";
        }
        return "";
      },
      cellTemplate: (index, field) => {
        var _a2;
        const item = searchResult.VatRegSearchContents[index];
        if (field === FieldNames.CompanyId) {
          return item.CompanyId.toString();
        } else if (field === FieldNames.CompanyName) {
          return html`<a href="" @click=${() => onEditClicked(item.CompanyId)}>${item.CompanyName.toString()}</a>`;
        } else if (field === FieldNames.CompanyStatus) {
          return item.CompanyStatus;
        } else if (field === FieldNames.ClientSource) {
          return item.ClientSource;
        } else if (field === FieldNames.Country) {
          return item.Country.Code;
        } else if (field === FieldNames.SignUpDate) {
          return formatDate(item.SignUpDate);
        } else if (field === FieldNames.SubscriptionValidTo) {
          return item.SubscriptionValidTo ? formatDate(item.SubscriptionValidTo) : "";
        } else if (field === FieldNames.SubscriptionStatus) {
          return item.SubscriptionStatus;
        } else if (field === FieldNames.ResponsibleEmail) {
          return item.ResponsibleEmail;
        } else if (field === FieldNames.RegType) {
          return item.RegType;
        } else if (field === FieldNames.VatRegistrationStatus) {
          if (((_a2 = getValidNextVatTransition(item.VatRegistrationStatus)) == null ? void 0 : _a2.length) > 0) {
            return html`<div class="w-full text-center py-1">
							<dc-dropdown
								.isStatusLike=${true}
								.buttonText=${getVatRegStatusLabel(item.VatRegistrationStatus)}
								.selectedValues=${[item.VatRegistrationStatus]}
								.readonly=${!permissionManager.hasPermission("VAT_Modify")}
								.dataSource=${[
              {
                label: getStatusLabel(item.VatRegistrationStatus),
                value: item.VatRegistrationStatus
              }
            ].concat(
              getValidNextVatTransition(item.VatRegistrationStatus).map((status) => ({
                label: getStatusLabel(status),
                value: status
              }))
            )}
								@click=${(e) => __async(this, null, function* () {
              const newVatRegStatus = e.detail.selectedValue;
              if (item.VatRegistrationStatus === "DocumentsSigned" && newVatRegStatus === "WaitingForSignature") {
                setCurrentCommentModalProps({
                  save: (comment) => __async(this, null, function* () {
                    yield changeVatRegStatus(item.CompanyId, item.VatRegId, item.VatRegistrationStatus, newVatRegStatus, comment);
                  })
                });
                commentModal.show();
              } else if (item.VatRegistrationStatus === "SentToTA" && newVatRegStatus === "LocalTaxReceived") {
                const result = yield withErrorHandling(
                  () => __async(this, null, function* () {
                    dispatchLoading("inc");
                    return companiesClient.getCompanySubscriptions({
                      companyId: item.CompanyId
                    });
                  }),
                  (error) => {
                    notifyErrorWithWarning(error);
                  },
                  () => {
                    dispatchLoading("dec");
                  }
                );
                const hasIntrastatSubscription = result.data.find((s) => (s instanceof IntrastatInSubscription || s instanceof IntrastatOutSubscription) && s.CountryCode === item.Country.Code) !== void 0;
                setCurrentLocalVatModalProps({
                  localVatCertificate: {},
                  submissionConfig,
                  euCountries,
                  bankAccountConfig: void 0,
                  predefinedBankAccounts,
                  serviceCountryCode: item.Country.Code,
                  establishmentCountryCode: item.EstablishmentCountryCode,
                  hasIntrastatSubscription,
                  save: (localVatCertificate) => __async(this, null, function* () {
                    yield changeVatRegStatus(item.CompanyId, item.VatRegId, item.VatRegistrationStatus, newVatRegStatus, void 0, void 0, localVatCertificate);
                  }),
                  uploadLocalVatCertificate: (file) => {
                    return uploadDocument(item.CompanyId, item.VatRegId, file);
                  }
                });
                localVatModal.show();
              } else if (item.VatRegistrationStatus === "LocalTaxReceived" && newVatRegStatus === "RegistrationCompleted") {
                setCurrentEuVatModalProps({
                  serviceCountryCode: item.Country.Code,
                  euVatCertificate: {},
                  save: (registrationCompletedChange) => __async(this, null, function* () {
                    yield changeVatRegStatus(item.CompanyId, item.VatRegId, item.VatRegistrationStatus, newVatRegStatus, void 0, registrationCompletedChange);
                  }),
                  uploadEuVatCertificate: (file) => {
                    return uploadDocument(item.CompanyId, item.VatRegId, file);
                  }
                });
                euVatModal.show();
              } else if (item.VatRegistrationStatus === "DeregistrationInProgress" && newVatRegStatus === "Deregistered") {
                setCurrentDeregistrationModalProps({
                  change: {},
                  save: (change) => __async(this, null, function* () {
                    yield changeVatRegStatus(item.CompanyId, item.VatRegId, item.VatRegistrationStatus, newVatRegStatus, void 0, void 0, void 0, change);
                  }),
                  uploadDocument: (file) => {
                    return uploadDocument(item.CompanyId, item.VatRegId, file);
                  }
                });
                deregistrationModal.show();
              } else if (item.VatRegistrationStatus === "RevokingPoA" && newVatRegStatus === "PoARevoked") {
                setCurrentPoARevokedModalProps({
                  change: {},
                  save: (change) => __async(this, null, function* () {
                    yield changeVatRegStatus(
                      item.CompanyId,
                      item.VatRegId,
                      item.VatRegistrationStatus,
                      newVatRegStatus,
                      void 0,
                      void 0,
                      void 0,
                      void 0,
                      change
                    );
                  }),
                  uploadDocument: (file) => {
                    return uploadDocument(item.CompanyId, item.VatRegId, file);
                  }
                });
                poaRevokedModal.show();
              } else {
                changeVatRegStatus(item.CompanyId, item.VatRegId, item.VatRegistrationStatus, newVatRegStatus);
              }
            })}
							></dc-dropdown>
						</div>`;
          } else {
            return "";
          }
        } else if (field === FieldNames.Actions) {
          return html`
						<div class="w-full text-center">
							<div class="flex space-x-2">
								${permissionManager.hasAllPermission(["VAT_Modify", "Agent_Read"]) ? html`<div>
											<button class="btn btn-sm btn-primary" @click=${() => onAssignClicked(item)}>Assign</button>
									  </div>` : ""}
							</div>
						</div>
					`;
        } else {
          return "";
        }
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: searchResult.Count
      }
    };
    return html`<div class="mx-8 xl:mx-auto">
			<div>
				<div>${templateTitle("Vat Registrations")}</div>
				<div class="flex space-x-4 my-4">
					<button class="btn btn-primary whitespace-no-wrap" @click=${() => __async(this, null, function* () {
      return exportVatRegistrations();
    })}>Export</button>
					<dc-checkbox2
						class="space-x-2 whitespace-no-wrap"
						.checked=${showAll}
						.label=${"Show All"}
						@change=${(e) => {
      setShowAll(e.detail.checked);
    }}
					></dc-checkbox2>
					<dc-checkbox2
						.checked=${onlyAssignedToMe}
						.label=${"Only assigned to me"}
						.labelnowrap=${true}
						@change=${(e) => {
      setOnlyAssignedToMe(e.detail.checked);
    }}
					></dc-checkbox2>
					<dc-checkbox2
						.checked=${serviceExpired}
						.label=${"Service Expired"}
						.labelnowrap=${true}
						@change=${(e) => {
      setServiceExpired(e.detail.checked);
    }}
					></dc-checkbox2>
					${((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html`<div>
								<button
									class="btn btn-primary whitespace-no-wrap"
									@click=${() => {
      resetFilters();
    }}
								>
									Reset filters
								</button>
						  </div>` : ""}
				</div>
			</div>
			<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>
		</div>`;
  };
  return html`<div class="my-2 mx-8 mb-16 mt-8">
		${searchResult === void 0 ? "Loading..." : html`${htmlGrid()}${htmlAssignModal()}`} ${commentModal.template()} ${localVatModal.template()} ${euVatModal.template()}
		${deregistrationModal.template} ${poaRevokedModal.template} ${loading.count > 0 ? html`<dc-loader></dc-loader>` : ""}
	</div>`;
}
