import { Pager } from "./Pager";
export class SearchRequest {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SearchRequest
     */
    this.$type = "V1.TaxEngine.Search.SearchRequest";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Fragment = _data["Fragment"];
      this.IsEnabled = _data["IsEnabled"];
      if (_data["Pager"]) {
        const pager_ = new Pager();
        this.Pager = pager_.init(_data["Pager"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Fragment"] = this.Fragment;
    _data["IsEnabled"] = this.IsEnabled;
    _data["Pager"] = this.Pager ? this.Pager.toJSON() : void 0;
    return _data;
  }
}
