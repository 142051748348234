import { DateConverter } from "./../../../converters/DateConverter";
import { Address } from "./../Common/Address";
import { Name } from "./../Common/Person/Name";
export class MigrationCompany {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof MigrationCompany
     */
    this.$type = "V1.Migration.MigrationCompany";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Address"]) {
        const address_ = new Address();
        this.Address = address_.init(_data["Address"]);
      }
      this.ClientAcceptance = MigrationCompanyClientAcceptance[_data["ClientAcceptance"]];
      this.ClientHasOSSReg = _data["ClientHasOSSReg"];
      this.ContactEmail = _data["ContactEmail"];
      this.ContactLanguage = _data["ContactLanguage"];
      if (_data["ContactName"]) {
        const contactName_ = new Name();
        this.ContactName = contactName_.init(_data["ContactName"]);
      }
      this.DOB = DateConverter.from(_data["DOB"]);
      this.EmsNumber = _data["EmsNumber"];
      this.GisNumber = _data["GisNumber"];
      this.HomeCountry = _data["HomeCountry"];
      this.HomeCountryCode = _data["HomeCountryCode"];
      this.LegalStatus = MigrationCompanyLegalStatus[_data["LegalStatus"]];
      this.Name = _data["Name"];
      this.OSSCountryCode = _data["OSSCountryCode"];
      this.OSSDateFrom = DateConverter.from(_data["OSSDateFrom"]);
      this.OSSNumber = _data["OSSNumber"];
      this.PortalId = _data["PortalId"];
      this.SentinelNumber = _data["SentinelNumber"];
      this.SentinelValidTo = DateConverter.from(_data["SentinelValidTo"]);
      this.VatNumber = _data["VatNumber"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Address"] = this.Address ? this.Address.toJSON() : void 0;
    _data["ClientAcceptance"] = MigrationCompanyClientAcceptance[this.ClientAcceptance];
    _data["ClientHasOSSReg"] = this.ClientHasOSSReg;
    _data["ContactEmail"] = this.ContactEmail;
    _data["ContactLanguage"] = this.ContactLanguage;
    _data["ContactName"] = this.ContactName ? this.ContactName.toJSON() : void 0;
    _data["DOB"] = DateConverter.toIsoUtcString(this.DOB);
    _data["EmsNumber"] = this.EmsNumber;
    _data["GisNumber"] = this.GisNumber;
    _data["HomeCountry"] = this.HomeCountry;
    _data["HomeCountryCode"] = this.HomeCountryCode;
    _data["LegalStatus"] = MigrationCompanyLegalStatus[this.LegalStatus];
    _data["Name"] = this.Name;
    _data["OSSCountryCode"] = this.OSSCountryCode;
    _data["OSSDateFrom"] = DateConverter.toIsoUtcString(this.OSSDateFrom);
    _data["OSSNumber"] = this.OSSNumber;
    _data["PortalId"] = this.PortalId;
    _data["SentinelNumber"] = this.SentinelNumber;
    _data["SentinelValidTo"] = DateConverter.toIsoUtcString(this.SentinelValidTo);
    _data["VatNumber"] = this.VatNumber;
    return _data;
  }
}
export var MigrationCompanyClientAcceptance = /* @__PURE__ */ ((MigrationCompanyClientAcceptance2) => {
  MigrationCompanyClientAcceptance2["Pending"] = "Pending";
  MigrationCompanyClientAcceptance2["Completed"] = "Completed";
  MigrationCompanyClientAcceptance2["Failed"] = "Failed";
  MigrationCompanyClientAcceptance2["MFE"] = "MFE";
  return MigrationCompanyClientAcceptance2;
})(MigrationCompanyClientAcceptance || {});
export var MigrationCompanyLegalStatus = /* @__PURE__ */ ((MigrationCompanyLegalStatus2) => {
  MigrationCompanyLegalStatus2["Company"] = "Company";
  MigrationCompanyLegalStatus2["Individual"] = "Individual";
  MigrationCompanyLegalStatus2["Partnership"] = "Partnership";
  return MigrationCompanyLegalStatus2;
})(MigrationCompanyLegalStatus || {});
