import { Name } from "./../Common/Person/Name";
import { BusinessDetail } from "./BusinessDetail";
export class CompanyDetail extends BusinessDetail {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CompanyDetail
     */
    this.$type = "V1.CompanyInfo.CompanyDetail";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.CompanyName = _data["CompanyName"];
      if (_data["ContactName"]) {
        const contactName_ = new Name();
        this.ContactName = contactName_.init(_data["ContactName"]);
      }
      this.LegalStatus = CompanyDetailLegalStatus[_data["LegalStatus"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["CompanyName"] = this.CompanyName;
    _data["ContactName"] = this.ContactName ? this.ContactName.toJSON() : void 0;
    _data["LegalStatus"] = CompanyDetailLegalStatus[this.LegalStatus];
    return _data;
  }
}
export var CompanyDetailLegalStatus = /* @__PURE__ */ ((CompanyDetailLegalStatus2) => {
  CompanyDetailLegalStatus2["Company"] = "Company";
  CompanyDetailLegalStatus2["Partnership"] = "Partnership";
  return CompanyDetailLegalStatus2;
})(CompanyDetailLegalStatus || {});
