export class TaxCode {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof TaxCode
     */
    this.$type = "V1.TaxEngine.TaxCode";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.DistanceSalesCountry = TaxCodeDistanceSalesCountry[_data["DistanceSalesCountry"]];
      this.ReportingCountry = TaxCodeReportingCountry[_data["ReportingCountry"]];
      this.TranTypeCode = _data["TranTypeCode"];
      this.TypeOfGoods = _data["TypeOfGoods"];
      this.VatRateType = TaxCodeVatRateType[_data["VatRateType"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["DistanceSalesCountry"] = TaxCodeDistanceSalesCountry[this.DistanceSalesCountry];
    _data["ReportingCountry"] = TaxCodeReportingCountry[this.ReportingCountry];
    _data["TranTypeCode"] = this.TranTypeCode;
    _data["TypeOfGoods"] = this.TypeOfGoods;
    _data["VatRateType"] = TaxCodeVatRateType[this.VatRateType];
    return _data;
  }
}
export var TaxCodeDistanceSalesCountry = /* @__PURE__ */ ((TaxCodeDistanceSalesCountry2) => {
  TaxCodeDistanceSalesCountry2["Arrival"] = "Arrival";
  TaxCodeDistanceSalesCountry2["Departure"] = "Departure";
  TaxCodeDistanceSalesCountry2["VATReporting"] = "VATReporting";
  return TaxCodeDistanceSalesCountry2;
})(TaxCodeDistanceSalesCountry || {});
export var TaxCodeReportingCountry = /* @__PURE__ */ ((TaxCodeReportingCountry2) => {
  TaxCodeReportingCountry2["Arrival"] = "Arrival";
  TaxCodeReportingCountry2["Departure"] = "Departure";
  TaxCodeReportingCountry2["VATReporting"] = "VATReporting";
  return TaxCodeReportingCountry2;
})(TaxCodeReportingCountry || {});
export var TaxCodeVatRateType = /* @__PURE__ */ ((TaxCodeVatRateType2) => {
  TaxCodeVatRateType2["Standard"] = "Standard";
  TaxCodeVatRateType2["Standard2"] = "Standard2";
  TaxCodeVatRateType2["Reduced"] = "Reduced";
  TaxCodeVatRateType2["Reduced2"] = "Reduced2";
  TaxCodeVatRateType2["SuperReduced"] = "SuperReduced";
  TaxCodeVatRateType2["SuperReduced2"] = "SuperReduced2";
  TaxCodeVatRateType2["Exempt"] = "Exempt";
  TaxCodeVatRateType2["ZeroRated"] = "ZeroRated";
  TaxCodeVatRateType2["Middle"] = "Middle";
  return TaxCodeVatRateType2;
})(TaxCodeVatRateType || {});
