var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { useEffect, useState, useValidationContext, useClient } from "GlobalShared/haunted/CustomHooks";
import { LegalRepresentativesAnswer as WebLegalRepresentativesAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { LegalRepresentativesAnswer as AdminLegalRepresentativesAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { LegalRepsValidator, LegalRepValidator } from "./AnswerValidators";
import { newUniqueId } from "GlobalShared/helpers/stringHelper";
import i18next from "i18next";
import { Questionnaire as AdminQuestionnaire } from "AdminServices/APIs/self/clients";
import { Questionnaire as WebQuestionnaire } from "WebServices/APIs/self/clients";
import { LegalRepresentative as WebLegalRepresentative } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/LegalRepresentative";
import { LegalRepresentative as AdminLegalRepresentative } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/LegalRepresentative";
import { Address } from "WebServices/APIs/self/models/V1/Common/Address";
import { templateTitle } from "GlobalShared/templates/commons";
import { mapBooleanToState, mapStateToBoolean } from "GlobalShared/helpers/booleanHelper";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { checkAnswerDate } from "./answer-helpers";
import { Q11E_DOC_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
export const observedAttributes = ["q11e", "questionId", "worldCountries"];
export const useShadowDOM = false;
export const name = "ww-answer-legal-representatives";
const DEFAULT_LEGAL_REPRESENTATIVE = {
  Id: void 0,
  Title: void 0,
  FirstName: "",
  LastName: "",
  MiddleName: void 0,
  FiscalCode: void 0,
  AddressCountry: "",
  Address: new Address({
    City: "",
    Street: "",
    StreetNumber: "",
    Zip: ""
  }),
  BirthName: "",
  MotherBirthName: "",
  FatherName: "",
  DateOfBirth: void 0,
  CountryOfBirth: "",
  CityOfBirth: "",
  Nationality: "",
  HomeAddressIsSameAsRegistered: void 0,
  DocumentType: void 0,
  DocumentNumber: "",
  DocumentExpirationDate: void 0,
  PhotoCopyReference: "",
  Position: void 0,
  PositionDescription: void 0,
  OwnMoreThan25Percent: void 0,
  IsOnlyDirector: void 0
};
const TITLES = [
  { label: i18next.t("Mr"), value: "Mr" },
  { label: i18next.t("Ms"), value: "Ms" }
];
const IDENTICATION_DOCUMENT_TYPES = [
  { label: i18next.t("Passport"), value: "Passport" },
  { label: i18next.t("Personal Identity card"), value: "PersonalId" },
  { label: i18next.t("Driving License"), value: "DrivingLicense" }
];
const POSITIONS = [
  { label: i18next.t("Owner"), value: "Owner" },
  { label: i18next.t("Director"), value: "Director" },
  { label: i18next.t("CEO"), value: "Ceo" },
  { label: i18next.t("Other"), value: "Other" }
];
const DEFAULTS = {
  validation: {
    required: false
  },
  worldCountries: [],
  birthCountries: []
};
export class LegalRepsChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    validation: host.validation !== void 0 ? __spreadValues(__spreadValues({}, DEFAULTS.validation), host.validation) : DEFAULTS.validation,
    worldCountries: host.worldCountries ? host.worldCountries : DEFAULTS.worldCountries,
    birthCountries: host.birthCountries ? host.birthCountries : DEFAULTS.birthCountries
  };
  const getLabel = (fieldName, required = false) => {
    var _a;
    return ((_a = getSubQuestion(fieldName)) == null ? void 0 : _a.Label) + (required ? "*" : "");
  };
  const getSubQuestion = (questionId) => {
    const filteredSubQuestions = question.SubQuestions.filter((subQuestion) => subQuestion.Id === questionId);
    if (filteredSubQuestions.length === 1) {
      return filteredSubQuestions[0];
    } else {
      return void 0;
    }
  };
  const initForm = () => {
    legalRepValidationContext.clearFieldValidations();
  };
  const getAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminLegalRepresentativesAnswer({
        Value: legalReps.map((legalRepViewModel) => new AdminLegalRepresentative(__spreadValues({}, legalRepViewModel))),
        QuestionId: props.questionId
      });
    }
    return new WebLegalRepresentativesAnswer({
      Value: legalReps.map((legalRepViewModel) => new WebLegalRepresentative(__spreadValues({}, legalRepViewModel))),
      QuestionId: props.questionId
    });
  };
  const onFileUpload = (event) => __async(void 0, null, function* () {
    const file = event.detail.files[0];
    let reference;
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        if (props.q11e.isAdmin === true) {
          reference = (yield AdminQuestionnaireClient.uploadFile({
            companyId: props.q11e.company.Id,
            file
          })).data;
        } else {
          reference = (yield WebQuestionnaireClient.uploadFile({
            file
          })).data;
        }
      }),
      (error) => {
        notifyError(error);
      }
    );
    legalRepValidationContext.validateField("photo_copy_reference");
    setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
      PhotoCopyReference: reference
    }));
  });
  const editLegalRep = (id) => {
    initForm();
    setCurrentLegalRep(legalReps.filter((legalRep) => legalRep.Id === id)[0]);
  };
  const removeLegalRep = (id) => {
    setDispatchIfValid(true);
    setLegalReps(legalReps.filter((legalRep) => legalRep.Id !== id));
  };
  const saveLegalRep = () => {
    if (legalRepValidationContext.validationResult.isValid()) {
      setDispatchIfValid(true);
      if (currentLegalRep.Id === void 0) {
        currentLegalRep.Id = newUniqueId();
        setLegalReps([...legalReps, currentLegalRep]);
      } else {
        setLegalReps(legalReps.map((legalRep) => legalRep.Id === currentLegalRep.Id ? currentLegalRep : legalRep));
      }
      setCurrentLegalRep(void 0);
    } else {
      legalRepValidationContext.validateFields([
        "title",
        "first_name",
        "last_name",
        "birth_name",
        "email",
        "phone",
        "position",
        "position_description",
        "date_of_birth",
        "city_of_birth",
        "nationality",
        "country_of_birth",
        "mother_birth_name",
        "father_name",
        "home_address_is_same_as_registered",
        "address_country",
        "address_city",
        "address_street",
        "address_street_number",
        "address_zip",
        "document_type",
        "document_number",
        "document_expiration_date",
        "photo_copy_reference",
        "own_more_than_25_percent",
        "is_only_director"
      ]);
      legalRepValidationContext.validate(new WebLegalRepresentative(__spreadValues({}, currentLegalRep)));
      setCurrentLegalRep(__spreadValues({}, currentLegalRep));
    }
  };
  const cancelLegalRep = () => {
    setCurrentLegalRep(void 0);
  };
  const startAddingNewLegalRep = () => {
    initForm();
    setCurrentLegalRep(__spreadValues({}, DEFAULT_LEGAL_REPRESENTATIVE));
  };
  const AdminQuestionnaireClient = useClient(AdminQuestionnaire);
  const WebQuestionnaireClient = useClient(WebQuestionnaire);
  const [legalReps, setLegalReps] = useState([]);
  const [currentLegalRep, setCurrentLegalRep] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(
    new LegalRepsValidator(props.validation, props.q11e, true)
  );
  const legalRepValidationContext = useValidationContext(new LegalRepValidator(props.q11e, false));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new LegalRepsChangeEvent({
          answer: getAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(getAnswer());
  }, [legalReps]);
  useEffect(() => {
    legalRepValidationContext.validate(new WebLegalRepresentative(__spreadValues({}, currentLegalRep)));
  }, [currentLegalRep]);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof WebLegalRepresentativesAnswer || answer instanceof AdminLegalRepresentativesAnswer) {
        setLegalReps(
          answer.Value.map((legalRep) => __spreadProps(__spreadValues({}, legalRep), {
            Id: newUniqueId(),
            PositionText: ""
          }))
        );
      }
    }
  }, [props.q11e, props.questionId]);
  const templateBasicInfo = () => {
    var _a, _b, _c, _d;
    return html`<div class="flex space-x-8">
				<dc-select
					class="${props.q11e.isVisible("legal_rep", "title") ? "" : "hidden"} w-32"
					.label=${getLabel("title", true)}
					.selectedValues=${[currentLegalRep.Title]}
					.dataSource=${TITLES}
					.readonly=${props.q11e.isReadonly()}
					.validationMessage=${legalRepValidationContext.getValidationMessage("title")}
					@change=${(e) => {
      legalRepValidationContext.validateField("title");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
        Title: e.detail.selectedValue
      }));
    }}
				></dc-select>
				<dc-input
					class="w-full"
					.readonly=${props.q11e.isReadonly()}
					.label=${getLabel("first_name", true)}
					.value=${currentLegalRep.FirstName}
					.validationMessage=${legalRepValidationContext.getValidationMessage("first_name")}
					@change=${(e) => {
      var _a2;
      legalRepValidationContext.validateField("first_name");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { FirstName: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
				></dc-input>
			</div>
			<dc-input
				class=${props.q11e.isVisible("legal_rep", "middle_name") ? "" : "hidden"}
				.readonly=${props.q11e.isReadonly()}
				.label=${getLabel("middle_name")}
				.value=${currentLegalRep.MiddleName}
				@change=${(e) => {
      var _a2;
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { MiddleName: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
			></dc-input>
			<dc-input
				.readonly=${props.q11e.isReadonly()}
				.label=${getLabel("last_name", true)}
				.value=${currentLegalRep.LastName}
				.validationMessage=${legalRepValidationContext.getValidationMessage("last_name")}
				@change=${(e) => {
      var _a2;
      legalRepValidationContext.validateField("last_name");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { LastName: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
			></dc-input>
			<dc-input
				class=${props.q11e.isVisible("legal_rep", "birth_name") ? "" : "hidden"}
				.readonly=${props.q11e.isReadonly()}
				.label=${getLabel("birth_name")}
				.value=${currentLegalRep.BirthName}
				.validationMessage=${legalRepValidationContext.getValidationMessage("birth_name")}
				@change=${(e) => {
      var _a2;
      legalRepValidationContext.validateField("birth_name");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { BirthName: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
			></dc-input>
			<dc-input
				class=${`${((_a = props.q11e) == null ? void 0 : _a.isVisible("legal_rep", "email")) ? "" : "hidden"}`}
				.readonly=${props.q11e.isReadonly()}
				.label=${getLabel("email", true)}
				.value=${currentLegalRep.Email}
				.validationMessage=${legalRepValidationContext.getValidationMessage("email")}
				@change=${(e) => {
      var _a2;
      legalRepValidationContext.validateField("email");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { Email: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
			></dc-input>
			<dc-input
				class=${`${((_b = props.q11e) == null ? void 0 : _b.isVisible("legal_rep", "phone")) ? "" : "hidden"}`}
				.readonly=${props.q11e.isReadonly()}
				.label=${getLabel("phone", true)}
				.value=${currentLegalRep.Phone}
				.validationMessage=${legalRepValidationContext.getValidationMessage("phone")}
				@change=${(e) => {
      var _a2;
      legalRepValidationContext.validateField("phone");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { Phone: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
			></dc-input>
			<dc-select
				class=${`${((_c = props.q11e) == null ? void 0 : _c.isVisible("legal_rep", "position")) ? "" : "hidden"}`}
				.label=${getLabel("position", true)}
				.selectedValues=${[currentLegalRep.Position]}
				.dataSource=${POSITIONS}
				.readonly=${props.q11e.isReadonly()}
				.validationMessage=${legalRepValidationContext.getValidationMessage("position")}
				@change=${(e) => {
      legalRepValidationContext.validateField("position");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
        Position: e.detail.selectedValue
      }));
    }}
			></dc-select>
			<dc-input
				class=${props.q11e.isVisible("legal_rep", "fiscal_code") ? "" : "hidden"}
				.readonly=${props.q11e.isReadonly()}
				.tooltip=${(_d = getSubQuestion("fiscal_code").Tooltip) != null ? _d : ""}
				.label=${getLabel("fiscal_code")}
				.value=${currentLegalRep.FiscalCode}
				@change="${(e) => {
      var _a2;
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { FiscalCode: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}}"
			></dc-input>
			<dc-input
				class=${currentLegalRep.Position === "Other" ? "visible" : "invisible"}
				.readonly=${props.q11e.isReadonly()}
				.label=${getLabel("position_description", true)}
				.value=${currentLegalRep.PositionDescription}
				.validationMessage=${legalRepValidationContext.getValidationMessage("position_description")}
				@change="${(e) => {
      var _a2;
      legalRepValidationContext.validateField("position_description");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { PositionDescription: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}}"
			></dc-input>`;
  };
  const templateBirthInfo = () => {
    var _a, _b;
    return html`<div
				class=${`lg:col-span-2 ${props.q11e.isVisible("legal_rep", "date_of_birth") || props.q11e.isVisible("legal_rep", "city_of_birth") || props.q11e.isVisible("legal_rep", "nationality") || props.q11e.isVisible("legal_rep", "country_of_birth") || props.q11e.isVisible("legal_rep", "mother_birth_name") || props.q11e.isVisible("legal_rep", "father_name") ? "" : "hidden"}`}
			>
				<h1 class="font-bold">${i18next.t("Birth info")}</h1>
			</div>
			<dc-datepicker
				class=${props.q11e.isVisible("legal_rep", "date_of_birth") ? "" : "hidden"}
				.label=${getLabel("date_of_birth", true)}
				.value=${currentLegalRep.DateOfBirth}
				.validationMessage=${legalRepValidationContext.getValidationMessage("date_of_birth")}
				.readonly=${props.q11e.isReadonly()}
				@change=${(e) => {
      if (checkAnswerDate(e.detail.value)) {
        legalRepValidationContext.validateField("date_of_birth");
        setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { DateOfBirth: e.detail.value }));
      }
    }}
			></dc-datepicker>
			<dc-input
				class=${props.q11e.isVisible("legal_rep", "city_of_birth") ? "" : "hidden"}
				.readonly=${props.q11e.isReadonly()}
				.label=${getLabel("city_of_birth", true)}
				.value=${currentLegalRep.CityOfBirth}
				.validationMessage=${legalRepValidationContext.getValidationMessage("city_of_birth")}
				@change=${(e) => {
      var _a2;
      legalRepValidationContext.validateField("city_of_birth");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { CityOfBirth: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
			></dc-input>
			<dc-select
				class=${props.q11e.isVisible("legal_rep", "nationality") ? "" : "hidden"}
				.label=${getLabel("nationality", true)}
				.selectedValues=${[currentLegalRep.Nationality]}
				.dataSource=${props.worldCountries.map((country) => ({ label: country.Name, value: country.Code }))}
				.filterable=${true}
				.readonly=${props.q11e.isReadonly()}
				.validationMessage=${legalRepValidationContext.getValidationMessage("nationality")}
				@change=${(e) => {
      legalRepValidationContext.validateField("nationality");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
        Nationality: e.detail.selectedValue
      }));
    }}
			></dc-select>
			<dc-select
				.label=${getLabel("country_of_birth", true)}
				.selectedValues=${[currentLegalRep.CountryOfBirth]}
				.dataSource=${props.birthCountries.map((country) => ({ label: country.Name, value: country.Code }))}
				.readonly=${props.q11e.isReadonly()}
				.filterable=${true}
				.validationMessage=${legalRepValidationContext.getValidationMessage("country_of_birth")}
				class=${`${((_a = props.q11e) == null ? void 0 : _a.isVisible("legal_rep", "country_of_birth")) ? "" : "hidden"}`}
				@change=${(e) => {
      legalRepValidationContext.validateField("country_of_birth");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
        CountryOfBirth: e.detail.selectedValue
      }));
    }}
			></dc-select>
			<dc-input
				class=${props.q11e.isVisible("legal_rep", "mother_birth_name") ? "" : "hidden"}
				.readonly=${props.q11e.isReadonly()}
				.label=${getLabel("mother_birth_name", true)}
				.value=${currentLegalRep.MotherBirthName}
				.validationMessage=${legalRepValidationContext.getValidationMessage("mother_birth_name")}
				@change=${(e) => {
      var _a2;
      legalRepValidationContext.validateField("mother_birth_name");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { MotherBirthName: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
			></dc-input>
			<dc-input
				class=${`${((_b = props.q11e) == null ? void 0 : _b.isVisible("legal_rep", "father_name")) ? "" : "hidden"}`}
				.readonly=${props.q11e.isReadonly()}
				.label=${getLabel("father_name", true)}
				.value=${currentLegalRep.FatherName}
				.validationMessage=${legalRepValidationContext.getValidationMessage("father_name")}
				@change=${(e) => {
      var _a2;
      legalRepValidationContext.validateField("father_name");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { FatherName: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
			></dc-input>`;
  };
  const templateAddress = () => {
    return html`
			<div class=${`lg:col-span-2 ${props.q11e.isVisible("legal_rep", "home_address_is_same_as_registered") ? "" : "hidden"}`} class="lg:col-span-2">
				<label class="form-label">${getLabel("home_address_is_same_as_registered", true)}</label>
				<div>
					<dc-toggle
						.readonly=${props.q11e.isReadonly()}
						.state=${mapBooleanToState(currentLegalRep.HomeAddressIsSameAsRegistered)}
						.validationMessage=${legalRepValidationContext.getValidationMessage("home_address_is_same_as_registered")}
						@change=${(e) => {
      var _a;
      legalRepValidationContext.validateField("home_address_is_same_as_registered");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
        HomeAddressIsSameAsRegistered: mapStateToBoolean((_a = e.detail) == null ? void 0 : _a.state)
      }));
    }}
					></dc-toggle>
				</div>
			</div>
			<div class="${currentLegalRep.HomeAddressIsSameAsRegistered === false ? "block" : "hidden"}">
				<label class="text-lg font-bold"> ${i18next.t("Please add the home address")} </label>
			</div>
			<div class="${currentLegalRep.HomeAddressIsSameAsRegistered === false ? "block" : "hidden"} lg:col-span-2 grid md:grid-cols-2 gap-8">
				<dc-select
					.label=${getLabel("address_country", true)}
					.selectedValues=${[currentLegalRep.AddressCountry]}
					.dataSource=${props.worldCountries.map((country) => ({ label: country.Name, value: country.Code }))}
					.readonly=${props.q11e.isReadonly()}
					.filterable=${true}
					.validationMessage=${legalRepValidationContext.getValidationMessage("address_country")}
					@change=${(e) => {
      legalRepValidationContext.validateField("address_country");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
        AddressCountry: e.detail.selectedValue
      }));
    }}
				></dc-select>
				<dc-input
					.readonly=${props.q11e.isReadonly()}
					.label=${getLabel("address_city", true)}
					.value=${currentLegalRep.Address.City}
					.validationMessage=${legalRepValidationContext.getValidationMessage("address_city")}
					@change=${(e) => {
      var _a;
      legalRepValidationContext.validateField("address_city");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
        Address: new Address(__spreadProps(__spreadValues({}, currentLegalRep.Address), { City: (_a = e.detail) == null ? void 0 : _a.value }))
      }));
    }}
				></dc-input>
				<dc-input
					.readonly=${props.q11e.isReadonly()}
					.label=${getLabel("address_street", true)}
					.value=${currentLegalRep.Address.Street}
					.validationMessage=${legalRepValidationContext.getValidationMessage("address_street")}
					@change=${(e) => {
      var _a;
      legalRepValidationContext.validateField("address_street");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
        Address: new Address(__spreadProps(__spreadValues({}, currentLegalRep.Address), { Street: (_a = e.detail) == null ? void 0 : _a.value }))
      }));
    }}
				></dc-input>
				<dc-input
					.readonly=${props.q11e.isReadonly()}
					.label=${getLabel("address_street_number", true)}
					.value=${currentLegalRep.Address.StreetNumber}
					.validationMessage=${legalRepValidationContext.getValidationMessage("address_street_number")}
					@change=${(e) => {
      var _a;
      legalRepValidationContext.validateField("address_street_number");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
        Address: new Address(__spreadProps(__spreadValues({}, currentLegalRep.Address), { StreetNumber: (_a = e.detail) == null ? void 0 : _a.value }))
      }));
    }}
				></dc-input>
				<dc-input
					.readonly=${props.q11e.isReadonly()}
					.label=${getLabel("address_zip", true)}
					.value=${currentLegalRep.Address.Zip}
					.validationMessage=${legalRepValidationContext.getValidationMessage("address_zip")}
					@change=${(e) => {
      var _a;
      legalRepValidationContext.validateField("address_zip");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
        Address: new Address(__spreadProps(__spreadValues({}, currentLegalRep.Address), { Zip: (_a = e.detail) == null ? void 0 : _a.value }))
      }));
    }}
				></dc-input>
			</div>
		`;
  };
  const templateIdentification = () => {
    var _a, _b, _c, _d;
    return html`<div
				class=${`lg:col-span-2 ${props.q11e.isVisible("legal_rep", "document_type") || props.q11e.isVisible("legal_rep", "document_number") || props.q11e.isVisible("legal_rep", "document_expiration_date") || props.q11e.isVisible("legal_rep", "photo_copy_reference") || props.q11e.isVisible("legal_rep", "own_more_than_25_percent") || props.q11e.isVisible("legal_rep", "is_only_director") ? "" : "hidden"}`}
			>
				<h1 class="font-bold">${i18next.t("Identification")}</h1>
			</div>
			<dc-select
				class=${props.q11e.isVisible("legal_rep", "document_type") ? "" : "hidden"}
				.label=${getLabel("document_type", true)}
				.selectedValues=${[currentLegalRep.DocumentType]}
				.dataSource=${IDENTICATION_DOCUMENT_TYPES}
				.readonly=${props.q11e.isReadonly()}
				.validationMessage=${legalRepValidationContext.getValidationMessage("document_type")}
				@change=${(e) => {
      legalRepValidationContext.validateField("document_type");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
        DocumentType: e.detail.selectedValue
      }));
    }}
			></dc-select>
			<dc-input
				class=${props.q11e.isVisible("legal_rep", "document_number") ? "" : "hidden"}
				.readonly=${props.q11e.isReadonly()}
				.label=${getLabel("document_number", true)}
				.value=${currentLegalRep.DocumentNumber}
				.validationMessage=${legalRepValidationContext.getValidationMessage("document_number")}
				@change=${(e) => {
      var _a2;
      legalRepValidationContext.validateField("document_number");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { DocumentNumber: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
			></dc-input>
			<dc-datepicker
				class=${props.q11e.isVisible("legal_rep", "document_expiration_date") ? "" : "hidden"}
				.label=${getLabel("document_expiration_date", true)}
				.value=${currentLegalRep.DocumentExpirationDate}
				.validationMessage=${legalRepValidationContext.getValidationMessage("document_expiration_date")}
				.readonly=${props.q11e.isReadonly()}
				@change=${(e) => {
      if (checkAnswerDate(e.detail.value)) {
        legalRepValidationContext.validateField("document_expiration_date");
        setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), { DocumentExpirationDate: e.detail.value }));
      }
    }}
			></dc-datepicker>
			<dc-fileupload
				class=${props.q11e.isVisible("legal_rep", "photo_copy_reference") ? "" : "hidden"}
				.label=${getLabel("photo_copy_reference", true)}
				.readonly=${props.q11e.isReadonly()}
				.fileReference=${currentLegalRep.PhotoCopyReference}
				.fileName=${currentLegalRep.PhotoCopyReference}
				.validationMessage=${legalRepValidationContext.getValidationMessage("photo_copy_reference")}
				.clientId=${(_b = (_a = props.q11e) == null ? void 0 : _a.company) == null ? void 0 : _b.Id}
				.accept=${Q11E_DOC_ACCEPT_LIST}
				@upload=${(_e) => __async(void 0, null, function* () {
      yield onFileUpload(_e);
      legalRepValidationContext.validateField("photo_copy_reference");
    })}
			></dc-fileupload>
			<div class=${`${((_c = props.q11e) == null ? void 0 : _c.isVisible("legal_rep", "own_more_than_25_percent")) ? "" : "hidden"}`}>
				<label class="form-label">${getLabel("own_more_than_25_percent", true)}</label>
				<div class="flex">
					<dc-toggle
						.readonly=${props.q11e.isReadonly()}
						.state=${mapBooleanToState(currentLegalRep.OwnMoreThan25Percent)}
						.validationMessage=${legalRepValidationContext.getValidationMessage("own_more_than_25_percent")}
						@change=${(e) => {
      var _a2;
      legalRepValidationContext.validateField("own_more_than_25_percent");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
        OwnMoreThan25Percent: mapStateToBoolean((_a2 = e.detail) == null ? void 0 : _a2.state)
      }));
    }}
					></dc-toggle>
				</div>
			</div>
			<div class=${`${((_d = props.q11e) == null ? void 0 : _d.isVisible("legal_rep", "is_only_director")) ? "" : "hidden"}`}>
				<label class="form-label">${getLabel("is_only_director", true)}</label>
				<div class="flex">
					<dc-toggle
						.readonly=${props.q11e.isReadonly()}
						.state=${mapBooleanToState(currentLegalRep.IsOnlyDirector)}
						.validationMessage=${legalRepValidationContext.getValidationMessage("is_only_director")}
						@change=${(e) => {
      var _a2;
      legalRepValidationContext.validateField("is_only_director");
      setCurrentLegalRep(__spreadProps(__spreadValues({}, currentLegalRep), {
        IsOnlyDirector: mapStateToBoolean((_a2 = e.detail) == null ? void 0 : _a2.state)
      }));
    }}
					></dc-toggle>
				</div>
			</div>`;
  };
  const templateLegalRep = () => {
    return html`<div>
			<div class="grid md:grid-cols-2 gap-8 mt-8">${templateBasicInfo()} ${templateBirthInfo()} ${templateAddress()} ${templateIdentification()}</div>
			<div class="mt-8 flex">
				<button class="btn btn-primary" @click=${cancelLegalRep}>${i18next.t("Cancel")}</button>
				${props.q11e.isReadonly() ? "" : html`<button class="btn btn-primary ml-4" @click=${saveLegalRep}>${i18next.t("Save")}</button>`}
			</div>
		</div>`;
  };
  return html`
		${props.q11e !== void 0 && question !== void 0 ? html`<div>
						<div class="my-8">${templateTitle(i18next.t("Authorized signatory"))}</div>
						${legalReps.length > 0 ? legalReps.map(
    (legalRep) => html`<div class="flex justify-between py-2">
												<div>
													<a class="link" href="javascript:void(0);" @click=${() => editLegalRep(legalRep.Id)}>${legalRep.LastName}, ${legalRep.FirstName}</a>
												</div>
												${props.q11e.isReadonly() ? "" : html`<div>
															<a class="link" href="javascript:void(0);" @click=${() => removeLegalRep(legalRep.Id)}>${i18next.t("Remove")}</a>
													  </div>`}
											</div>
											<hr />`
  ) : html`${currentLegalRep === void 0 ? html`<div class="italic mb-10">
											${i18next.t("The authorized signatory is duly authorized to sign and legally act on behalf of the company. Please add at least one authorized signatory.")}
									  </div>` : ""}`}
					</div>
					<div class="mt-8">
						${currentLegalRep !== void 0 ? templateLegalRep() : props.q11e.isReadonly() ? "" : html`<button class="btn btn-primary" @click=${startAddingNewLegalRep}>${i18next.t("Add new authorized signatory")}</button>`}
					</div>` : ""}
	`;
};
