import { DateConverter } from "./../../../converters/DateConverter";
import { MappingItem } from "./MappingItem";
import { PdfField } from "./PdfField";
export class TemplateVersion {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof TemplateVersion
     */
    this.$type = "V1.TemplateMapping.TemplateVersion";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Created = DateConverter.from(_data["Created"]);
      this.CreatedBy = _data["CreatedBy"];
      this.Disabled = _data["Disabled"];
      this.Draft = _data["Draft"];
      this.Id = _data["Id"];
      if (_data["MappingItems"] && _data["MappingItems"].constructor === Array) {
        this.MappingItems = [];
        for (let item of _data["MappingItems"]) {
          const mappingItems_ = new MappingItem();
          this.MappingItems.push(mappingItems_.init(item));
        }
      }
      if (_data["PdfFields"] && _data["PdfFields"].constructor === Array) {
        this.PdfFields = [];
        for (let item of _data["PdfFields"]) {
          const pdfFields_ = new PdfField();
          this.PdfFields.push(pdfFields_.init(item));
        }
      }
      this.TemplateId = _data["TemplateId"];
      this.TemplateName = _data["TemplateName"];
      this.TemplateReference = _data["TemplateReference"];
      this.Updated = DateConverter.from(_data["Updated"]);
      this.UpdatedBy = _data["UpdatedBy"];
      this.ValidFrom = DateConverter.from(_data["ValidFrom"]);
      this.Version = _data["Version"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Created"] = DateConverter.toIsoUtcString(this.Created);
    _data["CreatedBy"] = this.CreatedBy;
    _data["Disabled"] = this.Disabled;
    _data["Draft"] = this.Draft;
    _data["Id"] = this.Id;
    if (this.MappingItems && this.MappingItems.constructor === Array) {
      _data["MappingItems"] = [];
      for (let item of this.MappingItems) {
        _data["MappingItems"].push(item.toJSON());
      }
    }
    if (this.PdfFields && this.PdfFields.constructor === Array) {
      _data["PdfFields"] = [];
      for (let item of this.PdfFields) {
        _data["PdfFields"].push(item.toJSON());
      }
    }
    _data["TemplateId"] = this.TemplateId;
    _data["TemplateName"] = this.TemplateName;
    _data["TemplateReference"] = this.TemplateReference;
    _data["Updated"] = DateConverter.toIsoUtcString(this.Updated);
    _data["UpdatedBy"] = this.UpdatedBy;
    _data["ValidFrom"] = DateConverter.toIsoUtcString(this.ValidFrom);
    _data["Version"] = this.Version;
    return _data;
  }
}
