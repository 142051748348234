var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { parseCSVFile } from "GlobalShared/helpers/fileHelper";
import { useClient, useState, useEffect } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { mapAmazonAcquisitionRowToDto, mapAmazonAcquisitionRowToWebRegistrationRequest } from "../acquisition/AcquistionMapper";
import { nameof } from "GlobalShared/common/Nameof";
import { validateRows } from "../acquisition/Validation/AcquistionValidator";
import { OperationStatus } from "../acquisition/Validation/ValidationModels";
import { DataUpload, MasterData } from "AdminServices/APIs/self/clients";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { templateTitle } from "GlobalShared/templates/commons";
export function Acquisition() {
  const onCsvDropped = (e) => __async(this, null, function* () {
    setUploadResult(void 0);
    setValidationResult(void 0);
    setTableRows(void 0);
    const contentArray = yield parseCSVFile(e.detail.files[0]);
    const rows = contentArray.slice(1).map(mapAmazonAcquisitionRowToDto);
    setTableRows(rows);
    const validationResult2 = validateRows(
      rows,
      countries.map((c) => c.Code),
      serviceCountries.map((c) => c.Code)
    );
    setValidationResult(validationResult2);
  });
  const upload = () => __async(this, null, function* () {
    if (validationResult && validationResult.every((r) => r.status === OperationStatus.OK)) {
      setIsLoading(true);
      withErrorHandling(
        () => __async(this, null, function* () {
          const request = tableRows.map((r) => mapAmazonAcquisitionRowToWebRegistrationRequest(r, countries));
          const result = yield dataUploadClient.updateWebRegistrationRequest({ body: request });
          if (result) {
            setUploadResult({
              message: "File uploaded successfully.",
              status: OperationStatus.OK
            });
            setTableRows(void 0);
          } else {
            setUploadResult({
              message: "Server error uploading csv file.",
              status: OperationStatus.Error
            });
          }
          setIsLoading(false);
        }),
        (error) => {
          setUploadResult({
            message: `Server error uploading csv file. ${error}`,
            status: OperationStatus.Error
          });
          setIsLoading(false);
        }
      );
    }
  });
  const masterDataClient = useClient(MasterData);
  const dataUploadClient = useClient(DataUpload);
  const [tableRows, setTableRows] = useState(void 0);
  const [validationResult, setValidationResult] = useState(void 0);
  const [countries, setCountries] = useState([]);
  const [serviceCountries, setServiceCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(void 0);
  const [uploadResult, setUploadResult] = useState(void 0);
  const init = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const getCountriesResult = yield masterDataClient.getCountries();
        const getServiceCountriesResult = yield masterDataClient.getServiceCountries();
        setCountries(getCountriesResult.data);
        setServiceCountries(getServiceCountriesResult.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  useEffect(init, []);
  const uploadResultTemplate = (result) => {
    return result && result.status ? html`<div class="mb-3 p-3 ${result.status === OperationStatus.OK ? "bg-green-500 text-white" : "bg-brand-primary text-white"}">
					<div class="text-sm font-bold">${result.message}</div>
			  </div>` : "";
  };
  const operationResultTemplate = (result) => {
    if (result && result.length > 0) {
      const validationErrors = result.filter((r) => r.status === OperationStatus.Error);
      return validationErrors.length > 0 ? html`<div class="bg-brand-primary text-white mb-4">
						${validationErrors.map((validationError) => html`${"Validation error in Line"}${validationError.lineNumber + 1}${":"} ${validationError.message}<br />`)}
				  </div>` : "";
    } else {
      return "";
    }
  };
  const csvTable = () => {
    if (tableRows === void 0) {
      return "";
    }
    const data = tableRows;
    const vm = {
      columns: [
        {
          field: "Line",
          label: "Line",
          columnClass: "w-12"
        },
        {
          field: nameof("MerchantId"),
          label: "Merchant Id"
        },
        {
          field: nameof("EstablishmentCountry"),
          label: "PoE",
          columnClass: "w-12"
        },
        {
          field: nameof("SelectedCountries"),
          label: "Selected Countries"
        },
        {
          field: nameof("SailEnabledCountries"),
          label: "SAIL Enabled"
        },
        {
          field: nameof("VatCountries"),
          label: "VAT Countries"
        },
        { field: nameof("Email"), label: nameof("Email") },
        { field: nameof("Phone"), label: nameof("Phone") },
        { field: nameof("WeChat"), label: nameof("WeChat") }
      ],
      data,
      cellTemplate: (index, field) => {
        const row = data[index];
        if (field === "Line") {
          return html`<p class="w-8">${index + 1}</p>`;
        }
        if (field === nameof("MerchantId")) {
          return html`<p>${row.MerchantId}</p>`;
        }
        if (field === nameof("EstablishmentCountry")) {
          return html`<p>${row.EstablishmentCountry}</p>`;
        }
        if (field === nameof("SelectedCountries")) {
          return html`<p>${row.SelectedCountries.join(", ")}</p>`;
        }
        if (field === nameof("SailEnabledCountries")) {
          return html`<p>${row.SailEnabledCountries.join(", ")}</p>`;
        }
        if (field === nameof("VatCountries")) {
          return html`<p>${row.VatCountries.join(", ")}</p>`;
        }
        if (field === nameof("Email")) {
          return html`<p>${row.Email}</p>`;
        }
        if (field === nameof("Phone")) {
          return html`<p>${row.Phone}</p>`;
        }
        if (field === nameof("WeChat")) {
          return html`<p>${row.WeChat}</p>`;
        }
        return "";
      },
      paging: { pageable: false }
    };
    return html`<dc-table .vm=${vm}> </dc-table>`;
  };
  return html`
		<div class="container">
			<div class="mb-4">${templateTitle("Import Amazon Users")}</div>
			${operationResultTemplate(validationResult)} ${uploadResultTemplate(uploadResult)}
			<div class="container">
				<div class="">
					<dc-dropzone @drop=${onCsvDropped} class="h-32 mb-4 w-full bg-yellow-100 border block" .text=${"DROP CSV HERE"}></dc-dropzone>
				</div>
				<div>
					${tableRows && validationResult && validationResult.every((r) => r.status === OperationStatus.OK) ? html`<button class="btn btn-primary mb-4" @click=${upload}>Upload</button>` : ""}
				</div>
				<div class="">${csvTable()}</div>
			</div>
			${isLoading ? html`<dc-loader></dc-loader>` : ""}
		</div>
	`;
}
