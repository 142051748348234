var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { webLanguages } from "GlobalShared/helpers/webLocaleHelper";
import { ALL_COMPANY_DETAILS_FIELDS, CompanyDetailsValidator } from "./CompanyDetailsValidator";
import { TITLES } from "../orders/business-details/BusinessDetailsData";
export const useBusinessDetailsEditor = (props) => {
  const [companyDetails, setCompanyDetails] = useState(props.companyDetails);
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  const validationContext = useValidationContext(new CompanyDetailsValidator(props.hasIossService));
  useEffect(() => {
    if (companyDetails) {
      validationContext.validate(companyDetails);
    }
  }, [companyDetails]);
  useEffect(() => {
    if (props.companyDetails) {
      setCompanyDetails(props.companyDetails);
    }
  }, [props.companyDetails]);
  useEffect(() => __async(void 0, null, function* () {
    if (dispatchIfValid) {
      setDispatchIfValid(false);
      if (validationContext.validationResult.isValid()) {
        props.handlers.save(companyDetails);
      }
    }
  }), [validationContext.validationResult]);
  const templateTitleFirstNameLastName = () => {
    return html` <div class="grid md:grid-cols-${props.hasIossService ? "7" : "5"} gap-8">
			<div class="md:col-span-1">
				<dc-select
					.label=${`${i18next.t("Title")}*`}
					.dataSource=${TITLES}
					.selectedValues=${[companyDetails.contactTitle]}
					.validationMessage=${validationContext.getValidationMessage("contactTitle")}
					@change=${(e) => {
      const tempVal = e.detail.selectedValue;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { contactTitle: tempVal }));
      validationContext.validateField("contactTitle");
    }}
				></dc-select>
			</div>
			<dc-input
				.label=${`${companyDetails.legalStatus === "Individual" ? i18next.t("Sole trader's first name") : i18next.t("Contact first name")}*`}
				class="md:col-span-2"
				.value=${companyDetails.contactFirstName}
				.validationMessage=${validationContext.getValidationMessage("contactFirstName")}
				@change=${(e) => {
      const tempVal = e.detail.value;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { contactFirstName: tempVal }));
      validationContext.validateField("contactFirstName");
    }}
			></dc-input>
			<dc-input
				.label=${`${companyDetails.legalStatus === "Individual" ? i18next.t("Sole trader's last name") : i18next.t("Contact last name")}*`}
				class="md:col-span-2"
				.value=${companyDetails.contactLastName}
				.validationMessage=${validationContext.getValidationMessage("contactLastName")}
				@change=${(e) => {
      const tempVal = e.detail.value;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { contactLastName: tempVal }));
      validationContext.validateField("contactLastName");
    }}
			></dc-input>
			${props.hasIossService ? html`<dc-input
						.label=${`${i18next.t("Position in the company")}*`}
						class="md:col-span-2"
						.value=${companyDetails.contactPosition}
						.validationMessage=${validationContext.getValidationMessage("contactPosition")}
						@change=${(e) => {
      const tempVal = e.detail.value;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { contactPosition: tempVal }));
      validationContext.validateField("contactPosition");
    }}
				  ></dc-input>` : ""}
		</div>`;
  };
  const htmlBusinessDetailsEditor = () => {
    return html`<div>
			<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
				${companyDetails.legalStatus === "Company" || companyDetails.legalStatus === "Partnership" ? html`<dc-input
							class="lg:col-span-2"
							.label=${`${i18next.t("Company name")}*`}
							.value=${companyDetails.companyName}
							.validationMessage=${validationContext.getValidationMessage("companyName")}
							@change=${(e) => {
      const tempVal = e.detail.value;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { companyName: tempVal }));
      validationContext.validateField("companyName");
    }}
					  ></dc-input>` : ""}

				<div class="col-span-1 md:col-span-2 lg:col-span-4">${templateTitleFirstNameLastName()}</div>
				<div class="md:col-span-1 lg:col-span-2">
					<label class="form-label">${i18next.t("Preferred language")}*<dc-tooltip .label=${i18next.t("We will contact you in line with your language preference")}></dc-tooltip></label>
					<dc-select
						.selectedValues=${[companyDetails.preferredLanguage]}
						.validationMessage=${validationContext.getValidationMessage("preferredLanguage")}
						.dataSource=${Object.keys(webLanguages).map((key) => ({
      label: webLanguages[key].name,
      value: webLanguages[key].locale
    }))}
						@change=${(e) => {
      const tempVal = e.detail.selectedValue;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { preferredLanguage: tempVal }));
      validationContext.validateField("preferredLanguage");
    }}
					></dc-select>
				</div>
				<div class="md:col-span-1 lg:col-span-2">
					<dc-input
						.label=${`${i18next.t("Phone Number")}*`}
						.tooltip=${i18next.t("Please add the country calling code too (e.g. +86 for China, +49 for Germany, etc.)")}
						class="md:col-span-1 lg:col-span-3"
						.value=${companyDetails.phone}
						.validationMessage=${validationContext.getValidationMessage("phone")}
						@change=${(e) => {
      const tempVal = e.detail.value;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { phone: tempVal }));
      validationContext.validateField("phone");
    }}
					></dc-input>
				</div>

				<div class="lg:col-span-2 mb-10">
					<div class="relative">
						<dc-select
							class="lg:col-span-2"
							.readonly=${true}
							.label=${`${i18next.t("Country")}*`}
							.selectedValues=${[companyDetails.countryOfEstablishment]}
							.dataSource=${props.worldCountries.map((country) => ({
      label: country.Name,
      value: country.Code
    }))}
							.filterable=${true}
							.validationMessage=${validationContext.getValidationMessage("countryOfEstablishment")}
							@change=${(e) => {
      const tempVal = e.detail.selectedValue;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { countryOfEstablishment: tempVal }));
      validationContext.validateField("countryOfEstablishment");
    }}
						></dc-select>
					</div>
				</div>

				<div class="lg:col-span-2">
					<label class="form-label"
						>${i18next.t("VAT Number")}<dc-tooltip
							.label=${i18next.t(
      "EU based businesses must provide a valid VAT ID in the country of establishment, otherwise we will charge VAT for our services. For non-EU established businesses this field is optional."
    )}
						></dc-tooltip
					></label>
					<div class="relative">
						<div class="flex">
							<dc-input class="w-16" .readonly=${true} .value=${companyDetails.countryOfEstablishment}></dc-input>
							<dc-input
								class="w-full"
								.value=${companyDetails.vatNumber}
								.validationMessage=${validationContext.getValidationMessage("vatNumber")}
								@change=${(e) => {
      const tempVal = e.detail.value;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { vatNumber: tempVal }));
      validationContext.validateField("vatNumber");
    }}
							></dc-input>
						</div>
					</div>
				</div>
				<dc-input
					.label=${`${i18next.t("City")}*`}
					class="md:col-span-1 lg:col-span-3"
					.value=${companyDetails.addressCity}
					.validationMessage=${validationContext.getValidationMessage("addressCity")}
					@change=${(e) => {
      const tempVal = e.detail.value;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { addressCity: tempVal }));
      validationContext.validateField("addressCity");
    }}
				></dc-input>
				${companyDetails.countryOfEstablishment === "CN" ? html` <dc-input
							.label=${`${i18next.t("Province")}`}
							class="md:col-span-1 lg:col-span-3"
							.value=${companyDetails.addressProvince}
							.validationMessage=${validationContext.getValidationMessage("addressProvince")}
							@change=${(e) => {
      const tempVal = e.detail.value;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { addressProvince: tempVal }));
      validationContext.validateField("addressProvince");
    }}
					  ></dc-input>` : ""}

				<dc-input
					.label=${`${i18next.t("Postal/Zip code")}*`}
					class="md:col-span-1"
					.value=${companyDetails.addressZip}
					.validationMessage=${validationContext.getValidationMessage("addressZip")}
					@change=${(e) => {
      const tempVal = e.detail.value;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { addressZip: tempVal }));
      validationContext.validateField("addressZip");
    }}
				></dc-input>
				<dc-input
					.label=${`${i18next.t("Street name")}*`}
					class="md:col-span-1 lg:col-span-3"
					.value=${companyDetails.addressStreet}
					.validationMessage=${validationContext.getValidationMessage("addressStreet")}
					@change=${(e) => {
      const tempVal = e.detail.value;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { addressStreet: tempVal }));
      validationContext.validateField("addressStreet");
    }}
				></dc-input>
				<dc-input
					.label=${`${i18next.t("Street number")}*`}
					class="md:col-span-1"
					.value=${companyDetails.addressStreetNo}
					.validationMessage=${validationContext.getValidationMessage("addressStreetNo")}
					@change=${(e) => {
      const tempVal = e.detail.value;
      setCompanyDetails(__spreadProps(__spreadValues({}, companyDetails), { addressStreetNo: tempVal }));
      validationContext.validateField("addressStreetNo");
    }}
				></dc-input>
			</div>
			<div class="mt-4 space-x-4">
				<button class="btn btn-primary" @click=${props.handlers.close}>Cancel</button>
				<button
					class="btn btn-primary"
					@click=${() => {
      validationContext.validateFields(ALL_COMPANY_DETAILS_FIELDS);
      setCompanyDetails(__spreadValues({}, companyDetails));
      setDispatchIfValid(true);
    }}
				>
					Save
				</button>
			</div>
		</div>`;
  };
  return { template: htmlBusinessDetailsEditor };
};
