import { AbstractValidator } from "fluent-ts-validator";
import { DCValidationResult } from "GlobalShared/haunted/HooksHelpers";
import { isEmpty, ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE, ONLY_EUROPEAN_CHARS_REGEX, REQUIRED_MESSAGE } from "GlobalShared/helpers/stringHelper";
export const ALL_COMPANY_DETAILS_FIELDS = [
  "legalStatus",
  "countryOfEstablishment",
  "vatNumber",
  "companyName",
  "contactTitle",
  "contactFirstName",
  "contactLastName",
  "contactPosition",
  "addressCity",
  "addressZip",
  "addressStreet",
  "addressStreetNo",
  "addressProvince",
  "preferredLanguage",
  "phone"
];
export class CompanyDetailsValidator extends AbstractValidator {
  constructor(hasIossService) {
    super();
    this.validateIfString((s) => s.legalStatus).isNotEmpty().withPropertyName("legalStatus").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.countryOfEstablishment).isNotEmpty().withPropertyName("countryOfEstablishment").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.companyName).isNotEmpty().withFailureMessage(REQUIRED_MESSAGE).withPropertyName("companyName").when((s) => s.legalStatus === "Company" || s.legalStatus === "Partnership");
    this.validateIfString((s) => s.companyName).matches(ONLY_EUROPEAN_CHARS_REGEX).withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).withPropertyName("companyName").when((s) => s.legalStatus === "Company" || s.legalStatus === "Partnership");
    this.validateIfString((s) => s.companyName).hasMaxLength(200).withFailureMessage("Max length: 200").withPropertyName("companyName").when((s) => s.legalStatus === "Company" || s.legalStatus === "Partnership");
    this.validateIfString((s) => s.preferredLanguage).isNotEmpty().withPropertyName("preferredLanguage").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.phone).isNotEmpty().withPropertyName("phone").withFailureMessage("Required");
    this.validateIfString((s) => s.phone).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("phone").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.contactTitle).isNotEmpty().withPropertyName("contactTitle").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.contactFirstName).isNotEmpty().withPropertyName("contactFirstName").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.contactFirstName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("contactFirstName").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.contactFirstName).hasMaxLength(100).withPropertyName("contactFirstName").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.contactLastName).isNotEmpty().withPropertyName("contactLastName").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.contactLastName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("contactLastName").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.contactLastName).hasMaxLength(100).withPropertyName("contactLastName").withFailureMessage("Max length: 100");
    this.validateIfString((s) => s.contactPosition).isNotEmpty().withPropertyName("contactPosition").withFailureMessage(REQUIRED_MESSAGE).when((_s) => hasIossService);
    this.validateIfString((s) => s.contactPosition).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("contactPosition").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((_s) => hasIossService);
    this.validateIfString((s) => s.contactPosition).hasMaxLength(100).withPropertyName("contactPosition").withFailureMessage("Max length: 100").when((_s) => hasIossService);
    this.validateIfString((s) => s.vatNumber).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("vatNumber").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((s) => !isEmpty(s.vatNumber));
    this.validateIfString((s) => s.addressZip).isNotEmpty().withPropertyName("addressZip").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.addressZip).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("addressZip").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.addressZip).hasMaxLength(10).withPropertyName("addressZip").withFailureMessage("Max length: 10");
    this.validateIfString((s) => s.addressCity).isNotEmpty().withPropertyName("addressCity").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.addressCity).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("addressCity").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.addressCity).hasMaxLength(100).withPropertyName("addressCity").withFailureMessage("Max length: 100");
    this.validateIfString((s) => s.addressStreet).isNotEmpty().withPropertyName("addressStreet").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.addressStreet).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("addressStreet").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.addressStreet).hasMaxLength(100).withPropertyName("addressStreet").withFailureMessage("Max length: 100");
    this.validateIfString((s) => s.addressStreetNo).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("addressStreetNo").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.addressStreetNo).isNotEmpty().withPropertyName("addressStreetNo").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.addressStreetNo).hasMaxLength(20).withPropertyName("addressStreetNo").withFailureMessage("Max length: 20");
    this.validateIfString((s) => s.addressProvince).isNotEmpty().withPropertyName("addressProvince").withFailureMessage(REQUIRED_MESSAGE).when((s) => s.countryOfEstablishment === "CN");
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
