import { TaxCodeDefinition } from "./../TaxCodeDefinition";
export class SearchResult {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SearchResult
     */
    this.$type = "V1.TaxEngine.Search.SearchResult";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Count = _data["Count"];
      if (_data["TaxCodes"] && _data["TaxCodes"].constructor === Array) {
        this.TaxCodes = [];
        for (let item of _data["TaxCodes"]) {
          const taxCodes_ = new TaxCodeDefinition();
          this.TaxCodes.push(taxCodes_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Count"] = this.Count;
    if (this.TaxCodes && this.TaxCodes.constructor === Array) {
      _data["TaxCodes"] = [];
      for (let item of this.TaxCodes) {
        _data["TaxCodes"].push(item.toJSON());
      }
    }
    return _data;
  }
}
