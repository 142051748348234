import { Filter } from "./Filter";
import { Pager } from "./Pager";
export class SearchRequest {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SearchRequest
     */
    this.$type = "V1.Company.Search.SearchRequest";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Filter"]) {
        const filter_ = new Filter();
        this.Filter = filter_.init(_data["Filter"]);
      }
      if (_data["Pager"]) {
        const pager_ = new Pager();
        this.Pager = pager_.init(_data["Pager"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Filter"] = this.Filter ? this.Filter.toJSON() : void 0;
    _data["Pager"] = this.Pager ? this.Pager.toJSON() : void 0;
    return _data;
  }
}
