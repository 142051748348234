import { CustomParamBaseFactory } from "./CustomParamBaseFactory";
export class TemplateRequest {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof TemplateRequest
     */
    this.$type = "V1.TemplateMapping.TemplateRequest";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Culture = _data["Culture"];
      const $typeCustomParam = _data["CustomParam"] && _data["CustomParam"].$type;
      if ($typeCustomParam) {
        const customParam_ = CustomParamBaseFactory.create($typeCustomParam);
        this.CustomParam = customParam_.init(_data["CustomParam"]);
      } else {
        this.CustomParam = _data["CustomParam"];
      }
      this.Name = _data["Name"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Culture"] = this.Culture;
    _data["CustomParam"] = this.CustomParam ? this.CustomParam.toJSON() : void 0;
    _data["Name"] = this.Name;
    return _data;
  }
}
