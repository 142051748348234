var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { getLastPathElement } from "../templateHelper";
import { SubstringMappingValidator } from "../TemplateValidator";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
export const observedAttributes = [];
export const useShadowDOM = false;
export const name = "ww-substring-mapping";
export const Component = (host) => {
  const props = {
    substringMapping: host.substringMapping,
    actionInProgress: host.actionInProgress,
    onSave: host.onSave,
    onClear: host.onClear,
    onCancel: host.onCancel
  };
  const onSave = () => {
    setDispatchIfValid(true);
    validationContext.validateFields(["path", "from", "to"]);
    setMapping(__spreadValues({}, mapping));
  };
  const [mapping, setMapping] = useState(void 0);
  const validationContext = useValidationContext(new SubstringMappingValidator());
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => __async(void 0, null, function* () {
    if (dispatchIfValid) {
      setDispatchIfValid(false);
      if (validationContext.validationResult.isValid()) {
        props.onSave(mapping);
      }
    }
  }), [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(mapping);
  }, [mapping]);
  useEffect(() => {
    validationContext.clearFieldValidations();
    setMapping(props.substringMapping);
  }, [props.substringMapping]);
  return html`<div>
		<dc-dropfield
			.label=${"Field"}
			.value=${getLastPathElement(mapping == null ? void 0 : mapping.path)}
			.validationMessage=${validationContext.getValidationMessage("path")}
			.class=${"p-2 w-1/2 mb-2"}
			.onDrop=${(e) => {
    const draggedfield = JSON.parse(e.dataTransfer.getData("payload"));
    validationContext.validateField("path");
    setMapping(__spreadProps(__spreadValues({}, mapping), {
      path: draggedfield.path
    }));
  }}
		></dc-dropfield>
		<dc-input
			.label=${"From"}
			class="w-1/2"
			.value=${mapping == null ? void 0 : mapping.from}
			.validationMessage=${validationContext.getValidationMessage("from")}
			@change=${(e) => {
    validationContext.validateField("from");
    setMapping(__spreadProps(__spreadValues({}, mapping), {
      from: toSafeNumber(e.detail.value)
    }));
  }}
		></dc-input>
		<dc-input
			.label=${"To"}
			class="w-1/2 mt-2"
			.value=${mapping == null ? void 0 : mapping.to}
			.validationMessage=${validationContext.getValidationMessage("to")}
			@change=${(e) => {
    validationContext.validateField("to");
    setMapping(__spreadProps(__spreadValues({}, mapping), {
      to: toSafeNumber(e.detail.value)
    }));
  }}
		></dc-input>
		<div class="mt-4 space-x-4">
			<button class="btn btn-primary" ?disabled=${props.actionInProgress} @click=${onSave}>Save</button>
			<button class="btn btn-primary" ?disabled=${props.actionInProgress} @click=${() => props.onClear(mapping)}>Clear</button>
			<button class="btn btn-primary" ?disabled=${props.actionInProgress} @click=${props.onCancel}>Cancel</button>
		</div>
	</div>`;
};
