import { AmazonValidationMessage } from "./AmazonValidationMessage";
export class AmazonUploadResult {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof AmazonUploadResult
     */
    this.$type = "V1.ComplianceUploads.AmazonUploadResult";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.IsSuccess = _data["IsSuccess"];
      this.NumberOfTotalLinesWithError = _data["NumberOfTotalLinesWithError"];
      if (_data["ValidationMessages"] && _data["ValidationMessages"].constructor === Array) {
        this.ValidationMessages = [];
        for (let item of _data["ValidationMessages"]) {
          const validationMessages_ = new AmazonValidationMessage();
          this.ValidationMessages.push(validationMessages_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["IsSuccess"] = this.IsSuccess;
    _data["NumberOfTotalLinesWithError"] = this.NumberOfTotalLinesWithError;
    if (this.ValidationMessages && this.ValidationMessages.constructor === Array) {
      _data["ValidationMessages"] = [];
      for (let item of this.ValidationMessages) {
        _data["ValidationMessages"].push(item.toJSON());
      }
    }
    return _data;
  }
}
