import i18next from "i18next";
export const QUESTIONS = [
  {
    Id: "comp_reg_num",
    Label: i18next.t("Registration number of your business"),
    Tooltip: i18next.t("You can find the registration number on the Certificate of Incorporation document of your business.")
  },
  {
    Id: "incorp_date",
    Label: i18next.t("Date of incorporation")
  },
  {
    Id: "amount_of_cap",
    Label: i18next.t("Amount of capital in EUR")
  },
  {
    Id: "business_cert",
    Label: i18next.t("Please upload an official certificate confirming your business activities"),
    Tooltip: i18next.t("Must contain the name of the business, registered address of business, name of authorized signatory.")
  },
  {
    Id: "article_assoc",
    Label: i18next.t("Please upload your Articles of Association")
  },
  {
    Id: "corresp_is_same",
    Label: i18next.t("Is the correspondence address the same as the registered business address?")
  },
  {
    Id: "corresp_address",
    Label: i18next.t("Correspondence Address"),
    SubQuestions: [
      {
        Id: "country",
        Label: i18next.t("Country")
      },
      {
        Id: "city",
        Label: i18next.t("City")
      },
      {
        Id: "street",
        Label: i18next.t("Street name")
      },
      {
        Id: "street_number",
        Label: i18next.t("Street number")
      },
      {
        Id: "zip",
        Label: i18next.t("Postal/Zip code")
      }
    ]
  },
  {
    Id: "uk_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "uk_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "de_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "de_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "de_storage_address",
    Label: i18next.t("Address of the storage of goods in Germany (Street name, Street number, City, Postal/Zip code)"),
    Tooltip: i18next.t("If you will use Amazon FBA, add the address of the German FBA warehouse.")
  },
  {
    Id: "de_local_tax",
    Label: i18next.t("Local tax number"),
    Tooltip: i18next.t(
      "The local tax number (or Steuernummer) is an identification number issued by the German Tax Authorities when registering you as a taxpayer. Do not confuse the Tax number with the VAT number. The Tax number is used as a payment reference when you transfer the VAT amount."
    )
  },
  {
    Id: "de_store_start",
    Label: i18next.t("Start date of storing goods in Germany")
  },
  {
    Id: "de_import_country",
    Label: i18next.t("EU country where your goods are first imported to before selling to Germany")
  },
  {
    Id: "de_est_profit",
    Label: i18next.t("Estimated total profit in the first year in Germany (in EUR)")
  },
  {
    Id: "de_est_vat",
    Label: i18next.t("Estimated payable VAT in the first year in Germany (in EUR)")
  },
  {
    Id: "de_product_type",
    Label: i18next.t("Please select the type of products you sell")
  },
  {
    Id: "fr_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "fr_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "fr_vat_liability",
    Label: i18next.t("Are you making already taxable supply in France?")
  },
  {
    Id: "fr_supplies",
    Label: i18next.t("Will you carry out intra-community supplies from France?"),
    Tooltip: i18next.t("Will you store inventory in a French warehouse? Are you planning to sell from the French warehouse to VAT registered business(es) in other EU member states?")
  },
  {
    Id: "fr_acquisitions",
    Label: i18next.t("Will you carry out intra-community acquisitions in France?"),
    Tooltip: i18next.t("Are you planning to buy goods from VAT registered business(es) in other EU member states and store them in a French warehouse ?")
  },
  {
    Id: "fr_cntry_disp",
    Label: i18next.t("From which country do you purchase the goods that you will sell in France?")
  },
  {
    Id: "fr_cntry_inv",
    Label: i18next.t("In which country do you keep the goods that you will later sell in France?"),
    Tooltip: i18next.t("Select the country where the warehouse is located where you keep your goods before selling in/to France")
  },
  {
    Id: "at_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "at_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "at_local_tax",
    Label: i18next.t("Local tax number")
  },
  {
    Id: "at_product_type",
    Label: i18next.t("Please select the type of products you sell")
  },
  {
    Id: "be_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "be_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "ie_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "ie_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "nl_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "nl_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "dk_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "dk_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "ro_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "ro_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "se_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "se_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "pt_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "pt_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "hu_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "hu_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "sk_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "sk_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "fi_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "fi_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "el_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "el_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "lu_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "lu_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "bg_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "bg_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "hr_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "hr_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "cy_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "cy_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "ee_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "ee_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "lt_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "lt_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "lv_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "lv_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "mt_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "mt_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "si_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "si_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "fisc_rep_address",
    Label: i18next.t("Add the address of the previous fiscal representative in France"),
    Tooltip: i18next.t(
      "Do not confuse the fiscal representative with your VAT compliance service provider. The fiscal representative is tax registered in France and acts as your local representative. KPMG will appoint a new fiscal representative for you, and for that we have to provide the name of the previous representative."
    ),
    SubQuestions: [
      {
        Id: "city",
        Label: i18next.t("City")
      },
      {
        Id: "street",
        Label: i18next.t("Street name")
      },
      {
        Id: "street_number",
        Label: i18next.t("Street number")
      },
      {
        Id: "zip",
        Label: i18next.t("Postal/Zip code")
      }
    ]
  },
  {
    Id: "prev_fisc_rep",
    Label: i18next.t("Name of previous fiscal representative in France"),
    Tooltip: i18next.t(
      "Do not confuse the fiscal representative with your VAT compliance service provider. The fiscal representative is tax registered in France and acts as your local representative. KPMG will appoint a new fiscal representative for you, and for that we have to provide the name of the previous representative."
    )
  },
  {
    Id: "it_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "it_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "it_emp",
    Label: i18next.t("Do you have an office and employee(s) in Italy?")
  },
  {
    Id: "it_sales",
    Label: i18next.t("Did you perform sales of goods or services prior to registering in Italy for which it would have been necessary to obtain an Italian VAT number?"),
    Tooltip: i18next.t("Distance sales above the threshold")
  },
  {
    Id: "it_dist_sales",
    Label: i18next.t("Did you perform sales of goods and/or supplies of services before registering in Italy?"),
    Tooltip: i18next.t("It does not apply for the year of registration, only for previous years")
  },
  {
    Id: "it_turnovers",
    Label: i18next.t("Please indicate the net value of the distance sales in Italy for each year."),
    SubQuestions: [
      {
        Id: "year",
        Label: i18next.t("Year")
      },
      {
        Id: "amount",
        Label: i18next.t("Amount")
      }
    ]
  },
  {
    Id: "it_vat_cert",
    Label: i18next.t("Please upload VAT ID certificate in Italy")
  },
  {
    Id: "es_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "es_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "cz_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "cz_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "pl_vat",
    Label: i18next.t("VAT ID"),
    Tooltip: i18next.t("Without the country code prefix.")
  },
  {
    Id: "pl_annual",
    Label: i18next.t("Estimated annual taxable supply (in EUR)"),
    Tooltip: i18next.t("In the year of registration. Taxable supply means that you have local sales and/or distance sales totalling above the registration threshold.")
  },
  {
    Id: "exs_vats",
    Label: i18next.t("Existing VAT numbers"),
    SubQuestions: [
      {
        Id: "country",
        Label: i18next.t("Country")
      },
      {
        Id: "vatnumber",
        Label: i18next.t("VAT ID")
      }
    ]
  },
  {
    Id: "other_vats",
    Label: i18next.t("Do you have any VAT ID anywhere in the EU other than the VAT ID in the country of establishment?")
  },
  {
    Id: "estab_vat",
    Label: i18next.t("VAT Number of country of incorporation"),
    Tooltip: i18next.t("Businesses outside of the EU should provide a local Tax ID in the country of establishment.")
  },
  {
    Id: "estab_vat_cert",
    Label: i18next.t("Please upload the VAT ID certificate in the country of establishment"),
    Tooltip: i18next.t("Businesses outside of the EU should provide a local Tax ID certificate in the country of establishment.")
  },
  {
    Id: "com_reg_name",
    Label: i18next.t("Name of the commercial register in the country of establishment")
  },
  {
    Id: "tax_auth_name",
    Label: i18next.t("Name of the respective Tax Authority in country of establishment")
  },
  {
    Id: "is_on_stock",
    Label: i18next.t("Is your business (or a subsidiary of your business) listed on a regulated stock market?")
  },
  {
    Id: "stock_name",
    Label: i18next.t("Name of the regulated stock market")
  },
  {
    Id: "ticker_symbol",
    Label: i18next.t("Ticker symbol of the listed entity"),
    Tooltip: i18next.t(
      "A ticker symbol or stock symbol is an abbreviation used to uniquely identify publicly traded shares of a particular stock on a particular stock market. A stock symbol may consist of letters, numbers or a combination of both."
    )
  },
  {
    Id: "mp_store_name",
    Label: i18next.t("Amazon Seller account name")
  },
  {
    Id: "sell_id",
    Label: i18next.t("What is your Amazon Seller ID?"),
    Tooltip: i18next.t("This is a unique seller identifier at Amazon - usually a 13-14 character code, e.g. A123456789BCD")
  },
  {
    Id: "mp_name",
    Label: i18next.t("Name the Online Marketplaces where you sell"),
    Tooltip: i18next.t("E.g. Amazon, eBay, your own webshop, etc.")
  },
  {
    Id: "web_link",
    Label: i18next.t("Webstore link")
  },
  {
    Id: "legal_rep",
    Label: i18next.t("Authorized signatory"),
    SubQuestions: [
      {
        Id: "title",
        Label: i18next.t("Title")
      },
      {
        Id: "first_name",
        Label: i18next.t("First name")
      },
      {
        Id: "last_name",
        Label: i18next.t("Last name")
      },
      {
        Id: "middle_name",
        Label: i18next.t("Middle name (if any)")
      },
      {
        Id: "email",
        Label: i18next.t("Email")
      },
      {
        Id: "phone",
        Label: i18next.t("Phone")
      },
      {
        Id: "address_country",
        Label: i18next.t("Country")
      },
      {
        Id: "address_city",
        Label: i18next.t("City")
      },
      {
        Id: "address_street",
        Label: i18next.t("Street")
      },
      {
        Id: "address_street_number",
        Label: i18next.t("Street number")
      },
      {
        Id: "address_zip",
        Label: i18next.t("Postal/Zip code")
      },
      {
        Id: "birth_name",
        Label: i18next.t("Birth name (if different)")
      },
      {
        Id: "mother_birth_name",
        Label: i18next.t("Mother's birth name")
      },
      {
        Id: "father_name",
        Label: i18next.t("Father's full name")
      },
      {
        Id: "date_of_birth",
        Label: i18next.t("Birthdate")
      },
      {
        Id: "country_of_birth",
        Label: i18next.t("Country of birth")
      },
      {
        Id: "city_of_birth",
        Label: i18next.t("City of birth")
      },
      {
        Id: "nationality",
        Label: i18next.t("Nationality")
      },
      {
        Id: "home_address_is_same_as_registered",
        Label: i18next.t("Is the home address same as the registered business address?")
      },
      {
        Id: "document_type",
        Label: i18next.t("Type of identification document")
      },
      {
        Id: "document_number",
        Label: i18next.t("Identification document number")
      },
      {
        Id: "document_expiration_date",
        Label: i18next.t("Expiry date of the identification document")
      },
      {
        Id: "photo_copy_reference",
        Label: i18next.t("Please upload the photocopy of the identification document")
      },
      {
        Id: "position",
        Label: i18next.t("Position in the company")
      },
      {
        Id: "position_description",
        Label: i18next.t("Position in free form")
      },
      {
        Id: "own_more_than_25_percent",
        Label: i18next.t("Does the authorized signatory own more than 25% of the shares?")
      },
      {
        Id: "is_only_director",
        Label: i18next.t("Is the authorized signatory the only director entitled to represent the company?")
      },
      {
        Id: "fiscal_code",
        Label: i18next.t("Italian fiscal code"),
        Tooltip: i18next.t("Codice Fiscale. If you have lived in Italy, you have an individual Italian fiscal code. If you haven't, please leave this empty.")
      }
    ]
  },
  {
    Id: "business_owner",
    Label: i18next.t("Business Owners"),
    SubQuestions: [
      {
        Id: "shareholder_type",
        Label: i18next.t("Shareholder type")
      },
      {
        Id: "first_name",
        Label: i18next.t("First name")
      },
      {
        Id: "last_name",
        Label: i18next.t("Last name")
      },
      {
        Id: "full_name",
        Label: i18next.t("Full name")
      },
      {
        Id: "date_of_birth",
        Label: i18next.t("Birth date")
      },
      {
        Id: "date_of_incorporation",
        Label: i18next.t("Date of incorporation")
      },
      {
        Id: "share_percentage",
        Label: i18next.t("Share percentage")
      },
      {
        Id: "registration_number",
        Label: i18next.t("Registration number")
      },
      {
        Id: "document_number",
        Label: i18next.t("Identification document number")
      },
      {
        Id: "document_type",
        Label: i18next.t("Identification document")
      }
    ]
  },
  {
    Id: "beneficial_owner",
    Label: i18next.t("Beneficial Owner"),
    Tooltip: i18next.t("See FAQ for definition of beneficial owner"),
    SubQuestions: [
      {
        Id: "first_name",
        Label: i18next.t("First name")
      },
      {
        Id: "last_name",
        Label: i18next.t("Last name")
      },
      {
        Id: "birth_name",
        Label: i18next.t("Name at birth")
      },
      {
        Id: "citizenship",
        Label: i18next.t("Citizenship")
      },
      {
        Id: "date_of_birth",
        Label: i18next.t("Birth date")
      },
      {
        Id: "country_of_birth",
        Label: i18next.t("Country of birth")
      },
      {
        Id: "city_of_birth",
        Label: i18next.t("City of birth")
      },
      {
        Id: "address_street",
        Label: i18next.t("Street")
      },
      {
        Id: "address_streetnumber",
        Label: i18next.t("Street number")
      },
      {
        Id: "address_zip",
        Label: i18next.t("Postal/Zip code")
      },
      {
        Id: "address_city",
        Label: i18next.t("City")
      },
      {
        Id: "address_country",
        Label: i18next.t("Country")
      },
      {
        Id: "is_politically_exposed",
        Label: i18next.t("Is the beneficial owner a politically exposed person?"),
        Tooltip: i18next.t(
          "Politically exposed person shall mean a natural person who is entrusted with prominent public functions, or who has been entrusted with prominent public functions within one year before this identification measure."
        )
      },
      {
        Id: "is_relative_politically_exposed",
        Label: i18next.t("Is the beneficial owner a family member of the politically exposed person?"),
        Tooltip: i18next.t(
          "Family members of politically exposed person shall include the spouse or domestic partner of a politically exposed person; the biological and adopted children, stepchildren and foster children and their spouses or domestic partners, of a politically exposed person; the biological, adoptive, step- and foster parents of a politically exposed person."
        )
      },
      {
        Id: "is_associate_politically_exposed",
        Label: i18next.t("Is the beneficial owner known to be a close associate of the politically exposed person?"),
        Tooltip: i18next.t(
          "Close associates of politically exposed persons shall include. a) any natural person who is known to have joint beneficial ownership of legal entities or unincorporated organizations, or any other close business relations, with the politically exposed person. b) any natural person who has sole beneficial ownership of a legal entity or unincorporated organization which is known to have been set up for the benefit of the politically exposed person."
        )
      },
      {
        Id: "data_management",
        Label: i18next.t(
          "The above information can be shared with another KPMG company if the business enters into a contractual relationship with said KPMG company that is subject to client identification."
        ),
        Tooltip: i18next.t("The client identification will have to be repeated if no agreement is given.")
      },
      {
        Id: "beneficial_owner_type",
        Label: i18next.t("Person considered as beneficial owner"),
        Tooltip: i18next.t("See FAQ for definition of beneficial owner.")
      },
      {
        Id: "extent_of_ownership",
        Label: i18next.t("Extent of ownership interest"),
        Tooltip: i18next.t("If the extent of ownership can be quantified.")
      },
      {
        Id: "type_of_ownership",
        Label: i18next.t("Type of ownership interest")
      }
    ]
  },
  {
    Id: "has_pl_bank",
    Label: i18next.t("Do you have a Polish bank account?")
  },
  {
    Id: "pl_bank",
    Label: i18next.t("Polish Bank Information"),
    SubQuestions: [
      {
        Id: "name",
        Label: i18next.t("Name of the Polish Bank")
      },
      {
        Id: "address_street",
        Label: i18next.t("Street")
      },
      {
        Id: "address_street_number",
        Label: i18next.t("Street number")
      },
      {
        Id: "address_zip",
        Label: i18next.t("Postal/Zip code")
      },
      {
        Id: "address_city",
        Label: i18next.t("City")
      },
      {
        Id: "address_country",
        Label: i18next.t("Country")
      },
      {
        Id: "owner",
        Label: i18next.t("Owner of the Polish Bank Account")
      },
      {
        Id: "iban",
        Label: i18next.t("IBAN of the Polish Bank")
      },
      {
        Id: "swift",
        Label: i18next.t("SWIFT/BIC of the Polish Bank")
      }
    ]
  },
  {
    Id: "other_bank",
    Label: i18next.t("Bank Information"),
    SubQuestions: [
      {
        Id: "name",
        Label: i18next.t("Bank name")
      },
      {
        Id: "address_street",
        Label: i18next.t("Street")
      },
      {
        Id: "address_street_number",
        Label: i18next.t("Street number")
      },
      {
        Id: "address_zip",
        Label: i18next.t("Postal/Zip code")
      },
      {
        Id: "address_city",
        Label: i18next.t("City")
      },
      {
        Id: "address_country",
        Label: i18next.t("Country")
      },
      {
        Id: "owner",
        Label: i18next.t("Owner of the Bank account")
      },
      {
        Id: "iban",
        Label: i18next.t("IBAN"),
        Tooltip: i18next.t(
          "IBAN stands for International Bank Account Number. It\u2019s an internationally-agreed code made up of up to 34 letters and numbers that helps banks to process transfers around the world. If your bank does not provide an IBAN, please add your everyday account number again."
        )
      },
      {
        Id: "swift",
        Label: i18next.t("SWIFT/BIC"),
        Tooltip: i18next.t(
          "SWIFT and BIC are unique identifier of the bank in an international transaction to make sure money is going to the correct place. Your bank can help you find the right SWIFT/BIC. "
        )
      }
    ]
  },
  {
    Id: "bank_cert",
    Label: i18next.t("Please upload a bank certificate confirming your banking detals"),
    Tooltip: i18next.t(
      "A document issued by your bank, containing the following data: Name of Bank, Relevant address of Bank, Name of Account Holder, Address of Account Holder, Account Number, IBAN, SWIFT/BIC, Currency."
    )
  },
  {
    Id: "trading_name",
    Label: i18next.t("Trading name (If different from company name)")
  },
  {
    Id: "ioss_web_link",
    Label: i18next.t("Website link")
  },
  {
    Id: "ioss_exs_ioss",
    Label: i18next.t("Add current or previous IOSS number")
  },
  {
    Id: "ioss_fixed_estab",
    Label: i18next.t("Fixed establishments"),
    SubQuestions: [
      {
        Id: "address_country",
        Label: i18next.t("Country")
      },
      {
        Id: "address_city",
        Label: i18next.t("City")
      },
      {
        Id: "address_street",
        Label: i18next.t("Street name")
      },
      {
        Id: "address_street_number",
        Label: i18next.t("Street number")
      },
      {
        Id: "address_zip",
        Label: i18next.t("Postal/Zip code")
      },
      {
        Id: "trading_name",
        Label: i18next.t("Trading name")
      }
    ]
  },
  {
    Id: "oss_has_registration",
    Label: i18next.t("Is your business registered for OSS?"),
    Tooltip: i18next.t("OSS: One stop shop regime of the European Union for VAT reporting on distance sales within the EU at one single tax authority")
  },
  {
    Id: "oss_details",
    Label: i18next.t("OSS Information"),
    SubQuestions: [
      {
        Id: "registrationCountry",
        Label: i18next.t("Select the country of OSS registration")
      },
      {
        Id: "registrationDate",
        Label: i18next.t("Add the effective date of OSS registration")
      },
      {
        Id: "registrationNumber",
        Label: i18next.t("Add the OSS registration number")
      }
    ]
  },
  {
    Id: "account_loc",
    Label: i18next.t("Where are the accounting records being kept?"),
    Tooltip: i18next.t(
      "Accounting records are all of the documentation and books/records involved in the preparation of financial statements or records relevant to audits and financial reviews."
    )
  },
  {
    Id: "account_addrs",
    Label: i18next.t("Please add the address where your accounting records are kept"),
    SubQuestions: [
      {
        Id: "country",
        Label: i18next.t("Country")
      },
      {
        Id: "city",
        Label: i18next.t("City")
      },
      {
        Id: "street",
        Label: i18next.t("Street")
      },
      {
        Id: "street_number",
        Label: i18next.t("Street number")
      },
      {
        Id: "zip",
        Label: i18next.t("Postal/Zip code")
      }
    ]
  },
  {
    Id: "commercial_reg_num",
    Label: i18next.t("Register number of your business at the Commercial Register"),
    Tooltip: i18next.t("This is an individual number in the commercial register document.")
  },
  {
    Id: "commercial_reg_date",
    Label: i18next.t("Date of registration at the Commercial Register")
  },
  {
    Id: "name_of_com_auth",
    Label: i18next.t("Name of Authority keeping the Commercial Register")
  },
  {
    Id: "leg_ent_from",
    Label: i18next.t("Select the type of your business entity"),
    Tooltip: i18next.t(
      "Legal Entity: PVT, PHC, Ltd., bv, bvba, SPRL, SARL, Sagl, GmbH, UG (haftungsbeschr\xE4nkt), \u0395.\u03A0.\u0395., \u0399.\u039A.\u0395. ,s.r.o., spol. s r.o., kft.,  sp. z o.o., d.o.o. (\u0434.\u043E.\u043E.), d.o.o., SL, S.L.,S.r.l., Srl, Lda., ApS, AB, Oy, O\xDC, SIA, UAB, SRL, \u041E\u041E\u0414 (OOD), \u0415\u041E\u041E\u0414 (EOOD), sh.p.k., S.A., N.V Organizational Unit Without Legal Form: General partnership, Limited liability partnership (LLP), Limited partnership (LP), Partnership limited by shares, Belgium Commanditaire vennootschap op aandelen (CVA), Denmark Partnerselskab (or Kommanditaktieselskab)., France Soci\xE9t\xE9 en commandite par actions (SCA)., Germany Kommanditgesellschaft auf Aktien (KGaA), Italy Societ\xE0 in accomandita per azioni (s.a.p.a)., Iceland Samlagshlutaf\xE9lag (slhf.)., Poland  sp\xF3\u0142ka komandytowo-Akcyjna (S.K.A.)., Spain sociedad comanditaria por acciones (SCA)., Portugal sociedade em comandita por ac\xE7\xF5es."
    )
  },
  {
    Id: "leg_ent_type",
    Label: i18next.t("Select the type of legal entity")
  },
  {
    Id: "client_base",
    Label: i18next.t("What type of clientele is your business aimed to?")
  }
];
