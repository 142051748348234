import { DateConverter } from "./../../../../converters/DateConverter";
export class SentinelInformation {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SentinelInformation
     */
    this.$type = "V1.CompanyInfo.Detail.SentinelInformation";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Number = _data["Number"];
      this.ValidTo = DateConverter.from(_data["ValidTo"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Number"] = this.Number;
    _data["ValidTo"] = DateConverter.toUtcDateString(this.ValidTo);
    return _data;
  }
}
