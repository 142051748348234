import { SubmissionPeriodStatusChange } from "./SubmissionPeriodStatusChange";
import { ApprovedByClient } from "./ApprovedByClient";
import { DataUploaded } from "./DataUploaded";
import { ErrorAtTA } from "./ErrorAtTA";
import { ErrorReturnGen } from "./ErrorReturnGen";
import { New } from "./New";
import { NotToBeSubmitted } from "./NotToBeSubmitted";
import { RefusedByClient } from "./RefusedByClient";
import { Rejected } from "./Rejected";
import { ReturnGenerated } from "./ReturnGenerated";
import { SentToTA } from "./SentToTA";
import { Submitted } from "./Submitted";
import { SubmittedByClient } from "./SubmittedByClient";
export class SubmissionPeriodStatusChangeFactory {
  static create($type) {
    if ($type === "V1.Submission.StatusChanges.SubmissionPeriodStatusChange") {
      const result = new SubmissionPeriodStatusChange();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.ApprovedByClient") {
      const result = new ApprovedByClient();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.DataUploaded") {
      const result = new DataUploaded();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.ErrorAtTA") {
      const result = new ErrorAtTA();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.ErrorReturnGen") {
      const result = new ErrorReturnGen();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.New") {
      const result = new New();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.NotToBeSubmitted") {
      const result = new NotToBeSubmitted();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.RefusedByClient") {
      const result = new RefusedByClient();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.Rejected") {
      const result = new Rejected();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.ReturnGenerated") {
      const result = new ReturnGenerated();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.SentToTA") {
      const result = new SentToTA();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.Submitted") {
      const result = new Submitted();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.SubmittedByClient") {
      const result = new SubmittedByClient();
      return result;
    }
    throw new Error($type + " not found");
  }
}
