var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Order as AdminOrder } from "AdminServices/APIs/self/models/V1/Orders/Get/Order";
import { BankTransferPayment as WebBankTransferPayment, PayPalPayment as WebPayPalPayment, DesuclaPayment as WebDesuclaPayment, Order as WebOrder } from "WebServices/APIs/self/models/V1/Orders/Get";
import { BankTransferPayment as AdminBankTransferPayment, PayPalPayment as AdminPayPalPayment, DesuclaPayment as AdminDesuclaPayment } from "AdminServices/APIs/self/models/V1/Orders/Get";
import { mapMoney } from "GlobalShared/mappers/MoneyMapper";
import {
  HourlyService as AdminHourlyService,
  SimpleService as AdminSimpleService,
  SubscriptionService as AdminSubscriptionService,
  CountryBasedSubscriptionService as AdminCountryBasedSubscriptionService
} from "AdminServices/APIs/self/models/V1/Orders/Services";
import {
  HourlyService as WebHourlyService,
  SimpleService as WebSimpleService,
  SubscriptionService as WebSubscriptionService,
  CountryBasedSubscriptionService as WebCountryBasedSubscriptionService
} from "WebServices/APIs/self/models/V1/Orders/Services";
import { SearchOrder as AdminSearchOrder } from "AdminServices/APIs/self/models/V1/Orders/Search";
import i18next from "i18next";
export const NEW_ORDER_ID = "NEW";
export const DEFAULT_CURRENCY_CODE = "EUR";
export const OrderFieldNames = {
  CompanyId: "CompanyId",
  ClientSource: "ClientSource",
  CompanyName: "CompanyName",
  Status: "Status",
  OrderPaymentStatus: "OrderPaymentStatus",
  CreatedAt: "CreatedAt",
  OrderAmount: "OrderAmount",
  ContractId: "ContractId",
  Actions: "Actions"
};
export const PaymentFieldNames = {
  Type: "Type",
  QuotedAmount: "QuotedAmount",
  CollectedAmount: "CollectedAmount",
  Reference: "Reference",
  Status: "Status",
  Actions: "Actions",
  PaymentProof: "PaymentProof"
};
export const ALL_ORDER_PAYMENT_STATUSES = ["None", "Pending", "Approved"];
export const ALL_ORDER_STATUSES = ["Pending", "Cancelled", "Draft", "Confirmed", "Abandoned"];
export const ClientSources = ["Amazon", "FedEx", "KPMG", "LaPoste"];
export const NEW_SERVICE_TYPE_MAPPING = /* @__PURE__ */ new Map([
  ["VatCompliance", "CountryBasedSubscription"],
  ["VatRegistration", "CountryBasedSubscription"],
  ["VatTakeOver", "CountryBasedSubscription"],
  ["VatSpanishBankAccount", "CountryBasedSubscription"],
  ["VatFiscalRepresentative", "CountryBasedSubscription"],
  ["VatFilingAgent", "CountryBasedSubscription"],
  ["VatRetrospectiveReturn", "CountryBasedSubscription"],
  ["VatIntrastatIn", "CountryBasedSubscription"],
  ["VatIntrastatOut", "CountryBasedSubscription"],
  ["VatDeregistration", "CountryBasedSubscription"],
  ["VatSelfRevision", "CountryBasedSubscription"],
  ["IossComplianceTier0", "Subscription"],
  ["IossComplianceTier1", "Subscription"],
  ["IossComplianceTier2", "Subscription"],
  ["IossComplianceTier3", "Subscription"],
  ["IossRegistration", "Subscription"],
  ["IossDeregistration", "Subscription"],
  ["IossTakeOver", "Subscription"],
  ["OssCompliance", "Subscription"],
  ["OssRegistration", "Subscription"],
  ["OssDeregistration", "Subscription"],
  ["OssTakeOver", "Subscription"],
  ["GlobalEoriUk", "Simple"],
  ["GlobalEoriEu", "Simple"],
  ["GlobalGeneralVatAdvice", "Hourly"],
  ["GlobalSpecialistVatAdvice", "CustomPriced"],
  ["GlobalCommunicatingWithTaxAuthorities", "Simple"],
  ["GlobalChangeCompanyDetail", "Simple"],
  ["GlobalContactingTaxAuthorities", "Simple"],
  ["GlobalTranslation", "Hourly"],
  ["GlobalTaxCertificate", "Simple"],
  ["GlobalIntrastatThresholdCheck", "Simple"],
  ["GlobalDistanceSaleThresholdCheck", "Simple"]
]);
export const mapOrderBase = (o) => {
  const vm = {
    Id: o.Id,
    ConfirmedAt: o.ConfirmedAt,
    CreatedAt: o.CreatedAt,
    ContractId: o.ContractId,
    HasContractReference: o.HasContractReference,
    Status: o.Status,
    PaymentStatus: o instanceof WebOrder ? o.PaymentStatus : o.OrderPaymentStatus,
    Total: mapMoney(o instanceof AdminOrder || o instanceof WebOrder ? o.Total : o.TotalPrice),
    CompanyId: o instanceof AdminOrder || o instanceof AdminSearchOrder ? o.CompanyId : void 0,
    CompanyName: o instanceof AdminOrder || o instanceof AdminSearchOrder ? o.CompanyName : void 0,
    ClientSource: o instanceof AdminOrder || o instanceof AdminSearchOrder ? o.ClientSource : void 0,
    HasProcessingFailure: o instanceof AdminOrder || o instanceof AdminSearchOrder ? o.HasProcessingFailure : void 0
  };
  return vm;
};
export const mapSearchOrder = (o) => {
  const vm = __spreadProps(__spreadValues({}, mapOrderBase(o)), {
    PaymentStatus: o.OrderPaymentStatus
  });
  return vm;
};
export const mapOrderService = (s) => {
  if (s instanceof AdminSimpleService || s instanceof WebSimpleService) {
    const vm = {
      Type: "Simple",
      Code: s.Code,
      Price: mapMoney(s.Price),
      Status: s.Status
    };
    return vm;
  }
  if (s instanceof AdminHourlyService || s instanceof WebHourlyService) {
    const vm = {
      Type: "Hourly",
      Code: s.Code,
      Price: mapMoney(s.Price),
      Status: s.Status,
      Hours: s.Hours
    };
    return vm;
  }
  if (s instanceof AdminSubscriptionService || s instanceof WebSubscriptionService) {
    const vm = {
      Type: "Subscription",
      Code: s.Code,
      Price: mapMoney(s.Price),
      Status: s.Status,
      StartDate: s.StartDate,
      EndDate: s.EndDate
    };
    return vm;
  }
  if (s instanceof AdminCountryBasedSubscriptionService || s instanceof WebCountryBasedSubscriptionService) {
    const vm = {
      Type: "CountryBasedSubscription",
      Code: s.Code,
      Price: mapMoney(s.Price),
      Status: s.Status,
      Country: s.Country,
      StartDate: s.StartDate,
      EndDate: s.EndDate
    };
    return vm;
  }
  return void 0;
};
export const mapOrder = (o) => {
  var _a;
  const vm = __spreadProps(__spreadValues({}, mapOrderBase(o)), {
    CancelledAt: o.CancelledAt,
    SubTotal: mapMoney(o.SubTotal),
    Version: o.Version,
    Vat: mapMoney(o.Vat),
    Payments: o.Payments ? o.Payments.map(mapPayment) : [],
    Services: o.Services.map(mapOrderService),
    BillingDocuments: o.BillingDocuments,
    DraftServices: o instanceof AdminOrder ? (_a = o.DraftServices) == null ? void 0 : _a.map(mapOrderService) : void 0,
    NeedToGenerateInvoice: o.NeedToGenerateInvoice
  });
  return vm;
};
export const mapSearchOrderResult = (result) => {
  const vm = {
    total: result.Count,
    orders: result.Orders.map(mapSearchOrder)
  };
  return vm;
};
const mapPayment = (payment) => {
  const baseVM = {
    Collected: mapMoney(payment.Collected),
    CreatedAt: payment.CreatedAt,
    Id: payment.Id,
    Quoted: mapMoney(payment.Quoted),
    MerchantReference: payment.MerchantReference,
    Status: payment.Status,
    IsRefund: payment.IsRefund
  };
  if (payment instanceof AdminBankTransferPayment || payment instanceof WebBankTransferPayment) {
    return __spreadProps(__spreadValues({}, baseVM), {
      Type: "BankTransferPayment",
      HasPaymentProof: payment.HasPaymentProof
    });
  }
  if (payment instanceof AdminPayPalPayment || payment instanceof WebPayPalPayment) {
    return __spreadProps(__spreadValues({}, baseVM), { Type: "PayPalPayment" });
  }
  if (payment instanceof AdminDesuclaPayment || payment instanceof WebDesuclaPayment) {
    return __spreadProps(__spreadValues({}, baseVM), { Type: "DesuclaPayment" });
  }
  return void 0;
};
export const mapOrderServicesToSummary = (service) => {
  const vm = {
    code: service.Code,
    country: service.Country,
    price: service.Price
  };
  return vm;
};
export const ORDER_STATUS_LABELS = {
  Confirmed: i18next.t("Confirmed"),
  Pending: i18next.t("Pending"),
  Cancelled: i18next.t("Cancelled"),
  Draft: i18next.t("Draft"),
  Abandoned: i18next.t("Abandoned")
};
export const PAYMENT_STATUS_LABELS = {
  None: i18next.t("None"),
  Pending: i18next.t("Pending"),
  Approved: i18next.t("Approved")
};
