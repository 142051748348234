import { Field } from "./Field";
export class Pager {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Pager
     */
    this.$type = "V1.ComplianceMasterData.SearchSku.Pager";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["OrderBy"] && _data["OrderBy"].constructor === Array) {
        this.OrderBy = [];
        for (let item of _data["OrderBy"]) {
          const orderBy_ = new Field();
          this.OrderBy.push(orderBy_.init(item));
        }
      }
      this.Skip = _data["Skip"];
      this.Top = _data["Top"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.OrderBy && this.OrderBy.constructor === Array) {
      _data["OrderBy"] = [];
      for (let item of this.OrderBy) {
        _data["OrderBy"].push(item.toJSON());
      }
    }
    _data["Skip"] = this.Skip;
    _data["Top"] = this.Top;
    return _data;
  }
}
