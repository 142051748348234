var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClientAccounts } from "GlobalShared/components/client-accounts/client-accounts";
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { WebUsers as WebUsersClient, Company as CompanyClient } from "AdminServices/APIs/self/clients";
import { Field as CompanySearchField, Filter as CompanySearchFilter, Pager as CompanySearchPager, SearchRequest as CompanySearchRequest } from "AdminServices/APIs/self/models/V1/Company/Search";
import { FieldOrder as CompanySearchFieldOrder, FieldOrderByField as CompanySearchFieldOrderByField } from "AdminServices/APIs/self/models/V1/Company/Search/Field";
import { SearchRequest as UserSearchRequest } from "AdminServices/APIs/self/models/V1/Users/Search/SearchRequest";
import { Field as UserSearchField, Filter as UserSearchFilter, Pager as UserSearchPager } from "AdminServices/APIs/self/models/V1/Users/Search";
import { FieldOrder as UserSearchFieldOrder } from "AdminServices/APIs/self/models/V1/Users/Search/Field";
import { PatchCompanies, UpdateEmail } from "AdminServices/APIs/self/models/V1/Users";
import { InviteUser } from "AdminServices/APIs/self/models/V1/Users/InviteUser";
import { usePermissionManager } from "AdminComponents/hooks/AdminCustomHooks";
export function OfficeClientAccounts() {
  const searchUserCompanies = (gridState, emailFragment, companyNameFragment) => __async(this, null, function* () {
    const filter = new UserSearchFilter();
    filter.EmailPart = emailFragment;
    filter.Company = companyNameFragment;
    const userSearchResult = yield webUsersClient.searchUsers({
      body: new UserSearchRequest({
        Filter: filter,
        Pager: new UserSearchPager({
          Skip: gridState.pageIndex * gridState.pageSize,
          Top: gridState.pageSize,
          OrderBy: [
            new UserSearchField({
              Order: gridState.orderDir === "asc" ? UserSearchFieldOrder.Ascending : UserSearchFieldOrder.Descending,
              OrderByField: gridState.orderBy
            })
          ]
        })
      })
    });
    return {
      userCompanies: userSearchResult.data.Users.map((u) => {
        const ret = {
          userId: u.Id,
          email: u.Email,
          companies: u.Companies.map((c) => ({
            id: c.Id,
            name: c.Name
          }))
        };
        return ret;
      }),
      total: userSearchResult.data.Count
    };
  });
  const searchCompanies = (companyNameFragment) => __async(this, null, function* () {
    const filter = new CompanySearchFilter();
    filter.CompanyName = companyNameFragment;
    const companiesResult = yield companyClient.searchCompanies({
      body: new CompanySearchRequest({
        Filter: filter,
        Pager: new CompanySearchPager({
          Skip: 0,
          Top: 999,
          OrderBy: [
            new CompanySearchField({
              Order: CompanySearchFieldOrder.Ascending,
              OrderByField: CompanySearchFieldOrderByField.CompanyName
            })
          ]
        })
      })
    });
    return companiesResult.data.Companies.map((c) => ({ id: c.Id, name: c.CompanyName }));
  });
  const inviteUser = (email, companies, clientSource) => __async(this, null, function* () {
    yield webUsersClient.inviteUser({
      body: new InviteUser({
        Email: email,
        ClientSource: clientSource,
        CompanyIds: companies.map((c) => c.id)
      })
    });
  });
  const assignCompanies = (userId, addedCompanies, removedCompanies) => __async(this, null, function* () {
    yield webUsersClient.patchCompanies({
      userId,
      body: new PatchCompanies({
        AddedCompanies: addedCompanies.map((c) => c.id),
        RemovedCompanies: removedCompanies.map((c) => c.id)
      })
    });
  });
  const changeEmail = (userId, newEmail) => __async(this, null, function* () {
    yield webUsersClient.changeEmail({ userId, body: new UpdateEmail({ Email: newEmail }) });
  });
  const webUsersClient = useClient(WebUsersClient);
  const companyClient = useClient(CompanyClient);
  const permissionManager = usePermissionManager();
  const clientAccountsHook = useClientAccounts({
    isAdmin: true,
    permissionManager,
    searchUserCompanies,
    searchCompanies,
    inviteUser,
    assignCompanies,
    changeEmail
  });
  return clientAccountsHook.mainTemplate;
}
