import { Subscription } from "./Subscription";
export class SubscriptionSearchResult {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SubscriptionSearchResult
     */
    this.$type = "V1.Subscriptions.SubscriptionSearchResult";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Subscriptions"] && _data["Subscriptions"].constructor === Array) {
        this.Subscriptions = [];
        for (let item of _data["Subscriptions"]) {
          const subscriptions_ = new Subscription();
          this.Subscriptions.push(subscriptions_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.Subscriptions && this.Subscriptions.constructor === Array) {
      _data["Subscriptions"] = [];
      for (let item of this.Subscriptions) {
        _data["Subscriptions"].push(item.toJSON());
      }
    }
    return _data;
  }
}
