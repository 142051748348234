var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { useClient, useEffect, useLoadingReducer, useMemo, useQueryGridState, useState } from "GlobalShared/haunted/CustomHooks";
import { formatDate } from "GlobalShared/helpers/dateHelper";
import { addHandler, navigateTo, getRouteElement, getParameter } from "GlobalShared/router";
import { currentLocale, getRouteByKey } from "../../globals";
import { removeIcon, templateTitle } from "GlobalShared/templates/commons";
import { useServices } from "GlobalShared/components/services/services";
import { useEUCountries, usePermissionManager, useWorldCountries } from "AdminComponents/hooks/AdminCustomHooks";
import {
  Company as CompanyClient,
  VatRegistration as VatRegistrationClient,
  IossRegistration as IossRegistrationClient,
  SubmissionPeriods as SubmissionPeriodsClient,
  ComplianceUploads as ComplianceUploadsClient,
  Questionnaire as QuestionnaireClient,
  Agents as AgentsClient,
  Subscriptions as SubscriptionsClient
} from "AdminServices/APIs/self/clients";
import { Field, Filter, Pager, SearchRequest } from "AdminServices/APIs/self/models/V1/Company/Search";
import { FilterQuestionnaireStatus, FilterStatus } from "AdminServices/APIs/self/models/V1/Company/Search/FilterAbstraction";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import { notifyError, notifyErrorWithWarning, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { DateRange, DateTimeUtcRange } from "AdminServices/APIs/self/models/V1/Common/DateTime";
import {
  mapBankAccountConfig,
  mapSubmissionConfig
} from "GlobalShared/models/ServicesModels";
import { mapCompanyDetailToCompanyServicesModel, mapRegNotificationSubscriptions } from "GlobalShared/mappers/ServicesMapper";
import {
  getVatRegistrationStatusChange
} from "GlobalShared/models/VatRegModels";
import { SentinelInformation } from "AdminServices/APIs/self/models/V1/CompanyInfo/Detail";
import moment from "moment";
import { getIOSSRegistrationStatusChange } from "GlobalShared/models/IossRegistrationModels";
import { Name } from "AdminServices/APIs/self/models/V1/Common/Person";
import { Address } from "AdminServices/APIs/self/models/V1/Common/Address";
import { UpdateClientAcceptance, UpdateCompanyDetail, UpdateIndividualDetail } from "AdminServices/APIs/self/models/V1/Company";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import { FrequenciesChangeRequest, NewFrequencyConfig } from "AdminServices/APIs/self/models/V1/Company/VatRegistration/Frequency";
import { PaymentReferenceChangeRequest, TaxIdentifierChangeRequest } from "AdminServices/APIs/self/models/V1/Company/VatRegistration";
import { FieldOrder } from "AdminServices/APIs/self/models/V1/Company/Search/FieldAbstraction";
import { Field as AgentField, Filter as AgentFilter, Pager as AgentPager, SearchRequest as AgentSearchRequest } from "AdminServices/APIs/self/models/V1/Agents/Search";
import { FieldOrder as AgentFieldOrder, FieldOrderByField as AgentFieldOrderByField } from "AdminServices/APIs/self/models/V1/Agents/Search/Field";
import { UpdateAccountManager, UpdateCoordinator, UpdatePreparer, UpdateReviewer } from "AdminServices/APIs/self/models/V1/Company/Administrators";
import { htmlTruncatedCell } from "GlobalShared/helpers/htmlHelper";
import { CancelSubscription } from "AdminServices/APIs/self/models/V1/Company/Subscriptions";
import { VatRegistrationSubscription, VatRegistrationSubscriptionSearch } from "AdminServices/APIs/self/models/V1/Subscriptions";
import { VatRegistrationSubscriptionSearchEventType } from "AdminServices/APIs/self/models/V1/Subscriptions/VatRegistrationSubscriptionSearchAbstraction";
import { VatRegistrationSubscriptionEventType } from "AdminServices/APIs/self/models/V1/Subscriptions/VatRegistrationSubscriptionAbstraction";
const ClientSources = ["Amazon", "FedEx", "KPMG", "LaPoste"];
const ClientFieldNames = {
  ClientId: "ClientId",
  RegistrationDate: "RegistrationDate",
  CompanyName: "CompanyName",
  Status: "Status",
  IsSignedUp: "IsSignedUp",
  QuestionnaireStatus: "QuestionnaireStatus",
  SentinelValidTo: "SentinelValidTo",
  ClientSource: "ClientSource",
  AccountManagerEmail: "AccountManagerEmail",
  PreparerEmail: "PreparerEmail",
  ReviewerEmail: "ReviewerEmail",
  CoordinatorEmail: "CoordinatorEmail",
  Actions: "Actions"
};
const DEFAULT_FILTERS = [];
const AGENT_ID_TO_DELETE = "-1";
export function OfficeServices() {
  const onEditClicked = (id) => {
    navigateTo(getRouteByKey("clients(/:clientId)").replace(/\(\/:clientId\)/, `/${id}`));
  };
  const onAssignClicked = (company) => {
    setCurrentAssignManagerVM({
      companyId: company.Id,
      accountManagerEmail: company.AccountManagerEmail,
      preparerEmail: company.PreparerEmail,
      reviewerEmail: company.ReviewerEmail,
      coordinatorEmail: company.CoordinatorEmail
    });
    setShowAssignModal(true);
  };
  const reOpenClient = (companyId) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield companyClient.reopenCompany({ companyId });
        window.location.reload();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const rejectClient = (companyId) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield companyClient.rejectCompany({ companyId });
        window.location.reload();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const inactivateClient = (companyId) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield companyClient.inactivateCompany({ companyId });
        window.location.reload();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getCompany = () => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        dispatchLoading("inc");
        const companyDetail = yield companyClient.getCompanyDetail({
          companyId: Number(clientId)
        });
        setCurrentCompanyDetail(companyDetail.data);
      }),
      (error) => {
        notifyError(error);
      },
      () => dispatchLoading("dec")
    );
  });
  const loadPredefinedBankAccounts = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield vatRegistrationClient.getPredefinedBankAccounts();
        const config = response.data.map((pre) => {
          const vm = {
            Id: pre.Id,
            BankAddress: pre.BankAddress,
            BankName: pre.BankName,
            BeneficiaryAddress: pre.BeneficiaryAddress,
            BeneficiaryName: pre.BeneficiaryName,
            CountryCode: pre.CountryCode,
            EstablishmentCountryIsInEU: pre.EstablishmentCountryIsInEU,
            Iban: pre.Iban,
            Swift: pre.Swift
          };
          return vm;
        });
        setPredefinedBankAccounts(config);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const loadSubmissionConfig = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield submissionPeriodsClient.getFrequencyConfigurationOptions();
        setSubmissionConfig(mapSubmissionConfig(response.data));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const onSortChanged = (e) => {
    setGridState(__spreadProps(__spreadValues({}, gridState), { orderBy: e.detail.orderBy, orderDir: e.detail.orderDir }));
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridFilter);
  };
  const mapVatRegSearchEventType = (eventType) => {
    const vatRegSearchEventType = eventType === "ESL" ? VatRegistrationSubscriptionSearchEventType.ESLTaxReturnType : eventType === "VLS" ? VatRegistrationSubscriptionSearchEventType.VLSTaxReturnType : void 0;
    if (vatRegSearchEventType === void 0) {
      throw new Error(`Unknown event type ${eventType}`);
    }
    return vatRegSearchEventType;
  };
  const mapVatRegEventType = (eventType) => {
    const vatRegEventType = eventType === "ESL" ? VatRegistrationSubscriptionEventType.ESLTaxReturnType : eventType === "VLS" ? VatRegistrationSubscriptionEventType.VLSTaxReturnType : void 0;
    if (vatRegEventType === void 0) {
      throw new Error(`Unknown event type ${eventType}`);
    }
    return vatRegEventType;
  };
  const euCountries = useEUCountries(currentLocale);
  const worldCountries = useWorldCountries(currentLocale);
  const [currentCompanyDetail, setCurrentCompanyDetail] = useState(void 0);
  const currentCompanyServices = useMemo(() => {
    return currentCompanyDetail ? mapCompanyDetailToCompanyServicesModel(worldCountries, currentCompanyDetail) : void 0;
  }, [currentCompanyDetail]);
  const permissionManager = usePermissionManager();
  const callbackHandler = useMemo(
    () => ({
      downloadVatRegDocument: (vatRegId, templateId, type) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield vatRegistrationClient.downloadVatRegDocument({
              companyId: currentCompanyServices.Id,
              vatRegId,
              templateId,
              type
            });
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      downloadVatCertificate: (vatRegId, certType) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield vatRegistrationClient.downloadVatCertificate({
              companyId: currentCompanyServices.Id,
              vatRegId,
              certificateType: certType
            });
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      downloadVatDeregistrationDocument: (vatRegId) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield vatRegistrationClient.downloadDeregistrationDocument({
              companyId: currentCompanyServices.Id,
              vatRegId
            });
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      downloadPoaRevokedConfirmationDocument: (vatRegId) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield vatRegistrationClient.downloadPoaRevokedConfirmationDocument({
              companyId: currentCompanyServices.Id,
              vatRegId
            });
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      uploadSignedVatRegDocument: (vatRegId, templateId, file) => __async(this, null, function* () {
        yield withErrorHandling(
          () => __async(this, null, function* () {
            yield vatRegistrationClient.uploadSignedVatRegDocument({
              companyId: currentCompanyServices.Id,
              vatRegId,
              templateId,
              file
            });
            yield getCompany();
            notifySuccess("Document Uploaded");
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      uploadVatDocument: (vatRegId, file) => __async(this, null, function* () {
        return withErrorHandling(
          () => __async(this, null, function* () {
            const result = yield vatRegistrationClient.uploadDocument({
              companyId: currentCompanyServices.Id,
              vatRegId,
              file
            });
            notifySuccess("Certificate Uploaded");
            return result.data;
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      generateVatRegDocument: (vatRegId, templateId) => __async(this, null, function* () {
        yield withErrorHandling(
          () => __async(this, null, function* () {
            yield vatRegistrationClient.generateVatRegDocument({
              companyId: currentCompanyServices.Id,
              vatRegId,
              templateId
            });
            yield getCompany();
            notifySuccess("Document Generated");
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      changeVatRegStatus: (vatRegId, oldStatus, newStatus, registrationCompletedChange, comment, localVatCertificate, deregistrationChange, poaRevocationChange) => __async(this, null, function* () {
        yield withErrorHandling(
          () => __async(this, null, function* () {
            const statusChange = getVatRegistrationStatusChange(oldStatus, newStatus, comment, registrationCompletedChange, localVatCertificate, deregistrationChange, poaRevocationChange);
            yield vatRegistrationClient.changeVatRegStatus({
              companyId: currentCompanyServices.Id,
              vatRegId,
              body: statusChange
            });
            yield getCompany();
            notifySuccess("Status Changed");
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      finalizeVatRegUpload: (_vatRegId) => __async(this, null, function* () {
      }),
      updateEmsNumber: (emsNumber) => __async(this, null, function* () {
        yield companyClient.updateEmsNumber({
          companyId: currentCompanyServices.Id,
          number: emsNumber
        });
        yield getCompany();
      }),
      updateGisNumber: (gisNumber) => __async(this, null, function* () {
        yield companyClient.updateGisNumber({
          companyId: currentCompanyServices.Id,
          number: gisNumber
        });
        yield getCompany();
      }),
      updateSentinelInformation: (sentinelNumber, validTo) => __async(this, null, function* () {
        yield companyClient.updateSentinelInformation({
          companyId: currentCompanyServices.Id,
          body: new SentinelInformation({
            Number: sentinelNumber,
            ValidTo: validTo
          })
        });
        yield getCompany();
      }),
      updateClientAcceptance: (status, completed) => __async(this, null, function* () {
        yield companyClient.updateClientAcceptance({
          companyId: currentCompanyServices.Id,
          body: new UpdateClientAcceptance({
            ClientAcceptance: status,
            Completed: completed
          })
        });
        yield getCompany();
      }),
      changeIossRegistrationStatus: (registrationId, oldStatus, newStatus, comment, iossCertificate, deregisterChange) => __async(this, null, function* () {
        yield withErrorHandling(
          () => __async(this, null, function* () {
            const statusChange = getIOSSRegistrationStatusChange(oldStatus, newStatus, comment, iossCertificate, deregisterChange);
            yield iossRegistrationClient.changeStatus({
              companyId: currentCompanyServices.Id,
              registrationId,
              body: statusChange
            });
            yield getCompany();
            notifySuccess("IOSS Registration Status Changed!");
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      finalizeIOSSUpload: (_registrationId) => __async(this, null, function* () {
      }),
      downloadIossDeregistrationDocument: (registrationId) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield iossRegistrationClient.downloadDeregistrationDocument({
              companyId: currentCompanyServices.Id,
              registrationId
            });
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      uploadIossDocument: (registrationId, file) => __async(this, null, function* () {
        return withErrorHandling(
          () => __async(this, null, function* () {
            const result = yield iossRegistrationClient.uploadDocument({
              companyId: currentCompanyServices.Id,
              registrationId,
              file
            });
            notifySuccess("Certificate Uploaded");
            return result.data;
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      uploadIOSSSignedRegistrationDocument: (registrationId, documentId, file) => __async(this, null, function* () {
        return withErrorHandling(
          () => __async(this, null, function* () {
            const result = yield iossRegistrationClient.uploadSignedRegistrationDocument({
              companyId: currentCompanyServices.Id,
              registrationId,
              documentId,
              file
            });
            yield getCompany();
            notifySuccess("Signed document uploaded");
            return result.data;
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      downloadIOSSCertificate: (registrationId) => __async(this, null, function* () {
        return withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield iossRegistrationClient.downloadCertificate({
              companyId: currentCompanyServices.Id,
              registrationId
            });
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      downloadIOSSRegistrationDocument: (registrationId, documentId, type) => __async(this, null, function* () {
        return withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield iossRegistrationClient.downloadRegistrationDocument({
              type,
              companyId: currentCompanyServices.Id,
              registrationId,
              documentId
            });
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      getPossibleIossStatuses: () => __async(this, null, function* () {
        return withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield iossRegistrationClient.getAvailableStatusChanges();
            return response.data;
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      getPossibleVatStatuses: () => __async(this, null, function* () {
        return withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield vatRegistrationClient.getAvailableStatusChanges();
            return response.data;
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      saveCompanyDetails: (companyDetailsVM) => __async(this, null, function* () {
        yield withErrorHandling(
          () => __async(this, null, function* () {
            let businessDetail;
            if (companyDetailsVM.legalStatus === "Individual") {
              businessDetail = new UpdateIndividualDetail({
                Name: new Name({
                  Title: companyDetailsVM.contactTitle,
                  FirstName: companyDetailsVM.contactFirstName,
                  LastName: companyDetailsVM.contactLastName
                })
              });
            } else {
              businessDetail = new UpdateCompanyDetail({
                CompanyName: companyDetailsVM.companyName,
                ContactName: new Name({
                  Title: companyDetailsVM.contactTitle,
                  FirstName: companyDetailsVM.contactFirstName,
                  LastName: companyDetailsVM.contactLastName
                })
              });
            }
            businessDetail.VatNumber = companyDetailsVM.countryOfEstablishment + companyDetailsVM.vatNumber;
            businessDetail.ContactLanguage = companyDetailsVM.preferredLanguage;
            businessDetail.ContactPosition = companyDetailsVM.contactPosition;
            businessDetail.Phone = companyDetailsVM.phone;
            businessDetail.Address = new Address({
              Zip: companyDetailsVM.addressZip,
              Street: companyDetailsVM.addressStreet,
              StreetNumber: companyDetailsVM.addressStreetNo,
              City: companyDetailsVM.addressCity,
              Province: companyDetailsVM.addressProvince
            });
            yield companyClient.updateBusinessDetail({
              companyId: companyDetailsVM.id,
              body: businessDetail
            });
            yield getCompany();
            notifySuccess("Business details successfully modified");
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      changeBankAccountConfig: (vatRegId, bankAccountConfig) => __async(this, null, function* () {
        yield withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield vatRegistrationClient.changeBankAccount({
              companyId: currentCompanyServices.Id,
              vatRegId,
              body: mapBankAccountConfig(bankAccountConfig)
            });
            yield getCompany();
            notifySuccess("Successfully changed");
            return response.data;
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      changePaymentReference: (vatRegId, paymentReference) => __async(this, null, function* () {
        yield withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield vatRegistrationClient.changePaymentReference({
              companyId: currentCompanyServices.Id,
              vatRegistrationId: vatRegId,
              body: new PaymentReferenceChangeRequest({ PaymentReference: paymentReference })
            });
            yield getCompany();
            notifySuccess("Successfully changed");
            return response.data;
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      changeFrequencies: (vatRegId, sendEmail, returnType, newFrequencyConfigs, frequencyConfigIdsToBeRemoved) => __async(this, null, function* () {
        yield withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield vatRegistrationClient.changeFrequencies({
              companyId: currentCompanyServices.Id,
              vatRegId,
              body: new FrequenciesChangeRequest({
                SendEmail: sendEmail,
                ReturnType: returnType,
                NewFrequencyConfigs: newFrequencyConfigs.map(
                  (nfc) => new NewFrequencyConfig({
                    FrequencyType: nfc.FrequencyType,
                    ValidFrom: new Date(Date.UTC(nfc.ValidFromYear, nfc.ValidFromMonth - 1, 1)),
                    EFiling: nfc.EFiling,
                    FilingExtension: nfc.FilingExtension
                  })
                ),
                FrequencyConfigsToBeRemoved: frequencyConfigIdsToBeRemoved
              })
            });
            yield getCompany();
            notifySuccess("Successfully changed");
            return response.data;
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      cancelSubscription: (subscriptionId, cancellationDate) => __async(this, null, function* () {
        yield companyClient.cancelSubscription({
          companyId: currentCompanyServices.Id,
          subscriptionId,
          body: new CancelSubscription({ CancellationDate: cancellationDate })
        });
        yield getCompany();
      }),
      updateInvoicing: (isEnabled) => __async(this, null, function* () {
        yield companyClient.updateInvoicing({
          companyId: currentCompanyServices.Id,
          isEnabled
        });
        yield getCompany();
        notifySuccess("Client invoicing successfully changed");
      }),
      saveAnswer: (companyId, answer) => __async(this, null, function* () {
        yield questionnaireClient.saveModification({ companyId, body: [answer] });
      }),
      getQuestionnaire: (companyId) => __async(this, null, function* () {
        return (yield questionnaireClient.getQuestionnaire({ companyId })).data;
      }),
      generatePeriods: (companyId) => __async(this, null, function* () {
        yield complianceUploadClient.generatePeriods({
          companyId
        });
        yield submissionPeriodsClient.generatePeriods({
          companyId
        });
      }),
      searchRegNotificationSubscriptions: (companyId, vatRegistrationId, eventType) => __async(this, null, function* () {
        return withErrorHandling(
          () => __async(this, null, function* () {
            const subscriptionsResult = yield subscriptionsClient.search({
              body: new VatRegistrationSubscriptionSearch({
                CompanyId: companyId,
                VatRegistrationId: vatRegistrationId,
                EventType: mapVatRegSearchEventType(eventType)
              })
            });
            return mapRegNotificationSubscriptions(subscriptionsResult.data.Subscriptions);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      subscribeRegNotification: (companyId, vatRegistrationId, eventType, userId) => __async(this, null, function* () {
        yield subscriptionsClient.subscribe({
          body: new VatRegistrationSubscription({
            CompanyId: companyId,
            VatRegistrationId: vatRegistrationId,
            EventType: mapVatRegEventType(eventType),
            UserId: userId
          })
        });
      }),
      unsubscribeRegNotification: (companyId, vatRegistrationId, eventType, userId) => __async(this, null, function* () {
        yield subscriptionsClient.unSubscribe({
          body: new VatRegistrationSubscription({
            CompanyId: companyId,
            VatRegistrationId: vatRegistrationId,
            EventType: mapVatRegEventType(eventType),
            UserId: userId
          })
        });
      }),
      changeTaxIdentifier: (companyId, vatRegistrationId, taxIdentifier) => __async(this, null, function* () {
        yield withErrorHandling(
          () => __async(this, null, function* () {
            yield vatRegistrationClient.changeTaxIdentifier({
              companyId,
              vatRegistrationId,
              body: new TaxIdentifierChangeRequest({
                TaxIdentifier: taxIdentifier
              })
            });
            yield getCompany();
            notifySuccess("Tax identifier changed");
          }),
          (error) => {
            notifyErrorWithWarning(error);
          }
        );
      })
    }),
    [currentCompanyServices]
  );
  const [loading, dispatchLoading] = useLoadingReducer();
  const [clientId, setClientId] = useState(void 0);
  const [clientsResult, setClientsResult] = useState(void 0);
  const [gridState, setGridState] = useQueryGridState(
    {
      pageIndex: 0,
      appliedFilters: DEFAULT_FILTERS,
      pageSize: 20,
      orderBy: ClientFieldNames.ClientId,
      orderDir: "asc"
    },
    "cgs"
  );
  const [showAll, setShowAll] = useState(false);
  const [onlyAssignedToMe, setOnlyAssignedToMe] = useState(false);
  const [selectedSubmenu, setSelectedSubmenu] = useState(void 0);
  const [currentPathname, setCurrentPathname] = useState(window.location.pathname);
  const [predefinedBankAccounts, setPredefinedBankAccounts] = useState(void 0);
  const [submissionConfig, setSubmissionConfig] = useState(void 0);
  const companyClient = useClient(CompanyClient);
  const agentsClient = useClient(AgentsClient);
  const vatRegistrationClient = useClient(VatRegistrationClient);
  const iossRegistrationClient = useClient(IossRegistrationClient);
  const submissionPeriodsClient = useClient(SubmissionPeriodsClient);
  const questionnaireClient = useClient(QuestionnaireClient);
  const complianceUploadClient = useClient(ComplianceUploadsClient);
  const subscriptionsClient = useClient(SubscriptionsClient);
  const servicesHook = useServices({
    predefinedBankAccounts,
    submissionConfig,
    companyDetail: currentCompanyDetail,
    companyServicesModel: currentCompanyServices,
    callbackHandler,
    isAdmin: true,
    permissionManager,
    worldCountries,
    euCountries
  });
  const [currentAssignManagerVM, setCurrentAssignManagerVM] = useState(void 0);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const getFilter = () => {
    const clientFilter = new Filter();
    if (!showAll && !gridState.appliedFilters.some((filter) => filter.field === ClientFieldNames.Status)) {
      clientFilter.Status = FilterStatus.Active;
    }
    if (onlyAssignedToMe) {
      clientFilter.IsAssignedToMe = true;
    }
    gridState.appliedFilters.forEach((filter) => {
      if (filter.field === ClientFieldNames.CompanyName) {
        if (filter.filterProps.selectedValues) {
          clientFilter.CompanyName = filter.filterProps.selectedValues;
        }
      } else if (filter.field === ClientFieldNames.ClientId) {
        if (filter.filterProps.selectedValues) {
          clientFilter.CompanyId = filter.filterProps.selectedValues;
        }
      } else if (filter.field === ClientFieldNames.QuestionnaireStatus) {
        if (filter.filterProps.selectedValues) {
          clientFilter.QuestionnaireStatus = filter.filterProps.selectedValues;
        }
      } else if (filter.field === ClientFieldNames.Status) {
        if (filter.filterProps.selectedValues) {
          clientFilter.Status = filter.filterProps.selectedValues;
        }
      } else if (filter.field === ClientFieldNames.IsSignedUp) {
        if (filter.filterProps.selectedValues) {
          clientFilter.IsSignedUp = filter.filterProps.selectedValues === "Yes" ? true : false;
        }
      } else if (filter.field === ClientFieldNames.RegistrationDate) {
        if (filter.filterProps.min || filter.filterProps.max) {
          clientFilter.RegistrationDate = new DateTimeUtcRange({
            From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
            To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
          });
        }
      } else if (filter.field === ClientFieldNames.SentinelValidTo) {
        if (filter.filterProps.min || filter.filterProps.max) {
          clientFilter.SentinelValidTo = new DateRange({
            From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
            To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
          });
        }
      } else if (filter.field === ClientFieldNames.ClientSource) {
        if (filter.filterProps.selectedValues) {
          clientFilter.ClientSource = filter.filterProps.selectedValues;
        }
      } else if (filter.field === ClientFieldNames.AccountManagerEmail) {
        if (filter.filterProps.selectedValues) {
          clientFilter.AccountManagerEmail = filter.filterProps.selectedValues;
        }
      } else if (filter.field === ClientFieldNames.PreparerEmail) {
        if (filter.filterProps.selectedValues) {
          clientFilter.PreparerEmail = filter.filterProps.selectedValues;
        }
      } else if (filter.field === ClientFieldNames.ReviewerEmail) {
        if (filter.filterProps.selectedValues) {
          clientFilter.ReviewerEmail = filter.filterProps.selectedValues;
        }
      } else if (filter.field === ClientFieldNames.CoordinatorEmail) {
        if (filter.filterProps.selectedValues) {
          clientFilter.CoordinatorEmail = filter.filterProps.selectedValues;
        }
      }
    });
    return clientFilter;
  };
  const loadClients = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const companiesResult = yield companyClient.searchCompanies({
          body: new SearchRequest({
            Filter: getFilter(),
            Pager: new Pager({
              Skip: gridState.pageIndex * gridState.pageSize,
              Top: gridState.pageSize,
              OrderBy: [
                new Field({
                  Order: gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: gridState.orderBy
                })
              ]
            })
          })
        });
        setClientsResult({ total: companiesResult.data.Count, clients: companiesResult.data.Companies });
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const searchAgents = (emailFragment, roleName) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const agentsResult = yield agentsClient.searchAgents({
          body: new AgentSearchRequest({
            Filter: new AgentFilter({ Email: emailFragment, RoleNames: [roleName] }),
            Pager: new AgentPager({
              Skip: 0,
              Top: 1e3,
              OrderBy: [
                new AgentField({
                  Order: AgentFieldOrder.Ascending,
                  OrderByField: AgentFieldOrderByField.Email
                })
              ]
            })
          })
        });
        return agentsResult.data.Agents;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const assignManagers = () => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        if (currentAssignManagerVM.accountManagerId) {
          yield companyClient.updateAdministrator({
            companyId: currentAssignManagerVM.companyId,
            body: new UpdateAccountManager({
              UserId: currentAssignManagerVM.accountManagerId === AGENT_ID_TO_DELETE ? void 0 : currentAssignManagerVM.accountManagerId
            })
          });
        }
        if (currentAssignManagerVM.preparerId) {
          yield companyClient.updateAdministrator({
            companyId: currentAssignManagerVM.companyId,
            body: new UpdatePreparer({
              UserId: currentAssignManagerVM.preparerId === AGENT_ID_TO_DELETE ? void 0 : currentAssignManagerVM.preparerId
            })
          });
        }
        if (currentAssignManagerVM.reviewerId) {
          yield companyClient.updateAdministrator({
            companyId: currentAssignManagerVM.companyId,
            body: new UpdateReviewer({
              UserId: currentAssignManagerVM.reviewerId === AGENT_ID_TO_DELETE ? void 0 : currentAssignManagerVM.reviewerId
            })
          });
        }
        if (currentAssignManagerVM.coordinatorId) {
          yield companyClient.updateAdministrator({
            companyId: currentAssignManagerVM.companyId,
            body: new UpdateCoordinator({
              UserId: currentAssignManagerVM.coordinatorId === AGENT_ID_TO_DELETE ? void 0 : currentAssignManagerVM.coordinatorId
            })
          });
        }
        yield loadClients();
        notifySuccess("Managers saved successfully");
        setShowAssignModal(false);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const init = () => __async(this, null, function* () {
    setSelectedSubmenu(getParameter("sm"));
    addHandler("clients", (location) => {
      setCurrentPathname(location.pathname);
      setSelectedSubmenu(getParameter("sm"));
    });
    yield loadPredefinedBankAccounts();
    yield loadSubmissionConfig();
  });
  useEffect(() => __async(this, null, function* () {
    if (clientId === void 0) {
      setCurrentCompanyDetail(void 0);
    } else {
      yield getCompany();
    }
  }), [clientId]);
  useEffect(() => __async(this, null, function* () {
    setClientId(getRouteElement("clientId"));
  }), [currentPathname]);
  useEffect(init, []);
  useEffect(() => __async(this, null, function* () {
    loadClients();
  }), [gridState, showAll, onlyAssignedToMe]);
  const htmlAssignModal = () => {
    return html` <dc-modal
			.visible=${showAssignModal}
			@close=${() => setShowAssignModal(false)}
			.header=${"Assign agents"}
			.content=${currentAssignManagerVM ? html` <div>
						<div class="grid md:grid-cols-4 gap-8">
							<div class="flex space-x-4 items-center">
								<dc-select
									class="w-full"
									.label=${"Account manager"}
									.dataSource=${(text) => __async(this, null, function* () {
      if (text === void 0) {
        return currentAssignManagerVM.accountManagerEmail ? [
          {
            value: currentAssignManagerVM.accountManagerEmail,
            label: currentAssignManagerVM.accountManagerEmail
          }
        ] : "Type at least 2 characters";
      } else if (text.length < 2) {
        return "Type at least 2 characters";
      } else {
        const result = yield searchAgents(text, "accountmanager");
        if (result.length > 0) {
          setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
            accountManagerSearchResult: result
          }));
          return result.map((r) => ({ value: r.Email, label: r.Email }));
        } else {
          return "No result found";
        }
      }
    })}
									.filterable=${true}
									.debounceMs=${150}
									.selectedValues=${currentAssignManagerVM.accountManagerEmail}
									@change=${(e) => {
      const agent = currentAssignManagerVM.accountManagerSearchResult.find((a) => a.Email === e.detail.selectedValue);
      setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
        accountManagerId: agent.Id,
        accountManagerEmail: agent.Email
      }));
    }}
								></dc-select>
								<div
									class="cursor-pointer mt-6 remove-icon-container"
									@click=${() => __async(this, null, function* () {
      setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
        accountManagerEmail: void 0,
        accountManagerId: AGENT_ID_TO_DELETE
      }));
    })}
								>
									${removeIcon()}
								</div>
							</div>

							<div class="flex space-x-4 items-center">
								<dc-select
									class="w-full"
									.label=${"Preparer"}
									.dataSource=${(text) => __async(this, null, function* () {
      if (text === void 0) {
        return currentAssignManagerVM.preparerEmail ? [
          {
            value: currentAssignManagerVM.preparerEmail,
            label: currentAssignManagerVM.preparerEmail
          }
        ] : "Type at least 2 characters";
      } else if (text.length < 2) {
        return "Type at least 2 characters";
      } else {
        const result = yield searchAgents(text, "preparer");
        if (result.length > 0) {
          setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
            preparerSearchResult: result
          }));
          return result.map((r) => ({ value: r.Email, label: r.Email }));
        } else {
          return "No result found";
        }
      }
    })}
									.filterable=${true}
									.debounceMs=${150}
									.selectedValues=${currentAssignManagerVM.preparerEmail}
									@change=${(e) => {
      const agent = currentAssignManagerVM.preparerSearchResult.find((a) => a.Email === e.detail.selectedValue);
      setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
        preparerId: agent.Id,
        preparerEmail: agent.Email
      }));
    }}
								></dc-select>
								<div
									class="cursor-pointer mt-6 remove-icon-container"
									@click=${() => __async(this, null, function* () {
      setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
        preparerEmail: void 0,
        preparerId: AGENT_ID_TO_DELETE
      }));
    })}
								>
									${removeIcon()}
								</div>
							</div>

							<div class="flex space-x-4 items-center">
								<dc-select
									class="w-full"
									.label=${"Reviewer"}
									.dataSource=${(text) => __async(this, null, function* () {
      if (text === void 0) {
        return currentAssignManagerVM.reviewerEmail ? [
          {
            value: currentAssignManagerVM.reviewerEmail,
            label: currentAssignManagerVM.reviewerEmail
          }
        ] : "Type at least 2 characters";
      } else if (text.length < 2) {
        return "Type at least 2 characters";
      } else {
        const result = yield searchAgents(text, "reviewer");
        if (result.length > 0) {
          setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
            reviewerSearchResult: result
          }));
          return result.map((r) => ({ value: r.Email, label: r.Email }));
        } else {
          return "No result found";
        }
      }
    })}
									.filterable=${true}
									.debounceMs=${150}
									.selectedValues=${currentAssignManagerVM.reviewerEmail}
									@change=${(e) => {
      const agent = currentAssignManagerVM.reviewerSearchResult.find((a) => a.Email === e.detail.selectedValue);
      setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
        reviewerId: agent.Id,
        reviewerEmail: agent.Email
      }));
    }}
								></dc-select>
								<div
									class="cursor-pointer mt-6 remove-icon-container"
									@click=${() => __async(this, null, function* () {
      setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
        reviewerEmail: void 0,
        reviewerId: AGENT_ID_TO_DELETE
      }));
    })}
								>
									${removeIcon()}
								</div>
							</div>
							<div class="flex space-x-4 items-center">
								<dc-select
									class="w-full"
									.label=${"Coordinator"}
									.dataSource=${(text) => __async(this, null, function* () {
      if (text === void 0) {
        return currentAssignManagerVM.coordinatorEmail ? [
          {
            value: currentAssignManagerVM.coordinatorEmail,
            label: currentAssignManagerVM.coordinatorEmail
          }
        ] : "Type at least 2 characters";
      } else if (text.length < 2) {
        return "Type at least 2 characters";
      } else {
        const result = yield searchAgents(text, "coordinator");
        if (result.length > 0) {
          setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
            coordinatorSearchResult: result
          }));
          return result.map((r) => ({ value: r.Email, label: r.Email }));
        } else {
          return "No result found";
        }
      }
    })}
									.filterable=${true}
									.debounceMs=${150}
									.selectedValues=${currentAssignManagerVM.coordinatorEmail}
									@change=${(e) => {
      const agent = currentAssignManagerVM.coordinatorSearchResult.find((a) => a.Email === e.detail.selectedValue);
      setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
        coordinatorId: agent.Id,
        coordinatorEmail: agent.Email
      }));
    }}
								></dc-select>
								<div
									class="cursor-pointer mt-6 remove-icon-container"
									@click=${() => __async(this, null, function* () {
      setCurrentAssignManagerVM(__spreadProps(__spreadValues({}, currentAssignManagerVM), {
        coordinatorEmail: void 0,
        coordinatorId: AGENT_ID_TO_DELETE
      }));
    })}
								>
									${removeIcon()}
								</div>
							</div>
						</div>
						<div class="mt-4 flex space-x-4 pb-64">
							<button class="btn btn-primary block" @click=${() => setShowAssignModal(false)}>Cancel</button>
							<button class="btn btn-primary block" @click=${assignManagers}>Save</button>
						</div>
				  </div>` : ""}
		>
		</dc-modal>`;
  };
  const htmlClientsGrid = () => {
    var _a;
    const vm = {
      columns: [
        {
          field: ClientFieldNames.ClientId,
          label: "ID",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: ClientFieldNames.ClientSource,
          label: "Source",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: ClientSources
          },
          columnType: "enum"
        },
        {
          field: ClientFieldNames.RegistrationDate,
          label: "Reg. date",
          columnTitle: "Registration date",
          sortable: true,
          filterable: true,
          filterDescriptor: { from: true, to: true, type: "date" },
          columnType: "date"
        },
        {
          field: ClientFieldNames.CompanyName,
          label: "Company name",
          columnClass: "w-64",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: ClientFieldNames.Status,
          label: "Status",
          columnTitle: "Company status",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: [FilterStatus.Active, FilterStatus.Rejected, FilterStatus.Inactive],
            searchable: true,
            searchProps: { type: "startsWith" }
          },
          columnType: "enum"
        },
        {
          field: ClientFieldNames.IsSignedUp,
          label: "Signup",
          columnTitle: "Signup completed",
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: ["Yes", "No"]
          },
          columnType: "enum"
        },
        {
          field: ClientFieldNames.QuestionnaireStatus,
          label: "QNR Status",
          columnTitle: "Questionnaire status",
          columnClass: "w-12",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: [FilterQuestionnaireStatus.Approved, FilterQuestionnaireStatus.Completed, FilterQuestionnaireStatus.Incomplete, FilterQuestionnaireStatus.DataVerified]
          },
          columnType: "enum"
        },
        {
          field: ClientFieldNames.SentinelValidTo,
          label: "Sentinel",
          columnTitle: "Sentinel valid to",
          sortable: true,
          filterable: true,
          filterDescriptor: { from: true, to: true, type: "date" },
          columnType: "date"
        },
        {
          field: ClientFieldNames.AccountManagerEmail,
          label: "Account mgr",
          columnTitle: "Account manager",
          columnClass: "w-64",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: ClientFieldNames.PreparerEmail,
          label: "Preparer",
          columnClass: "w-64",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: ClientFieldNames.ReviewerEmail,
          label: "Reviewer",
          columnClass: "w-64",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: ClientFieldNames.CoordinatorEmail,
          label: "Coordinator",
          columnClass: "w-64",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        { field: ClientFieldNames.Actions, label: "" }
      ],
      data: clientsResult.clients,
      sorting: {
        orderBy: gridState.orderBy,
        orderDir: gridState.orderDir
      },
      cellClass: (index, field) => {
        if (field === ClientFieldNames.SentinelValidTo) {
          const item = clientsResult.clients[index];
          return item && item.SentinelValidTo && moment(item.SentinelValidTo).isBefore(moment()) ? "text-red-500 font-bold" : "";
        } else if (field === ClientFieldNames.Actions) {
          return "actions";
        }
        return "";
      },
      cellTemplate: (index, field) => {
        var _a2;
        const item = clientsResult.clients[index];
        if (field === ClientFieldNames.ClientId) {
          return (_a2 = item.Id) == null ? void 0 : _a2.toString();
        } else if (field === ClientFieldNames.ClientSource) {
          return item.ClientSource;
        } else if (field === ClientFieldNames.RegistrationDate) {
          return formatDate(item.RegistrationDate);
        } else if (field === ClientFieldNames.CompanyName) {
          return htmlTruncatedCell(item.CompanyName);
        } else if (field === ClientFieldNames.Status) {
          return item.Status;
        } else if (field === ClientFieldNames.IsSignedUp) {
          return item.IsSignedUp ? "Yes" : "No";
        } else if (field === ClientFieldNames.QuestionnaireStatus) {
          return item.QuestionnaireStatus;
        } else if (field === ClientFieldNames.SentinelValidTo) {
          return item.SentinelValidTo ? formatDate(item.SentinelValidTo) : "";
        } else if (field === ClientFieldNames.Actions) {
          return html`
						<div class="w-full text-center">
							<div class="flex space-x-2">
								<div>
									<button class="btn btn-sm btn-primary" @click=${() => onEditClicked(item.Id)}>Show details</button>
								</div>
								${permissionManager.hasAllPermission(["Company_Admin_User_Modify", "Agent_Read"]) ? html` <div>
											<button class="btn btn-sm btn-primary" @click=${() => onAssignClicked(item)}>Assign</button>
									  </div>` : ""}
							</div>
						</div>
					`;
        } else if (field === ClientFieldNames.AccountManagerEmail) {
          return htmlTruncatedCell(item.AccountManagerEmail);
        } else if (field === ClientFieldNames.PreparerEmail) {
          return htmlTruncatedCell(item.PreparerEmail);
        } else if (field === ClientFieldNames.ReviewerEmail) {
          return htmlTruncatedCell(item.ReviewerEmail);
        } else if (field === ClientFieldNames.CoordinatorEmail) {
          return htmlTruncatedCell(item.CoordinatorEmail);
        } else {
          return "";
        }
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: clientsResult.total
      }
    };
    return html` <div class="mx-8 xl:mx-auto">
			<div class="grid grid-cols-1 gap-4 mb-4">
				<div>${templateTitle("Clients")}</div>
				<div class="flex space-x-4">
					${((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html` <div>
								<button class="btn btn-primary whitespace-no-wrap" @click=${() => setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex: 0, appliedFilters: DEFAULT_FILTERS }))}>Reset filters</button>
						  </div>` : ""}
					<dc-checkbox2
						.checked=${showAll}
						.label=${"Show All"}
						@change=${(e) => {
      setShowAll(e.detail.checked);
    }}
					></dc-checkbox2>
					<dc-checkbox2
						.checked=${onlyAssignedToMe}
						.label=${"Only assigned to me"}
						.labelnowrap=${true}
						@change=${(e) => {
      setOnlyAssignedToMe(e.detail.checked);
    }}
					></dc-checkbox2>
				</div>
			</div>
			<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>
		</div>`;
  };
  const htmlQuestionnaire = () => {
    return html` <ww-clients-questionnaire .clientId=${currentCompanyServices.Id}></ww-clients-questionnaire>`;
  };
  const htmlSku = () => {
    return html` <ww-clients-skus .clientId=${currentCompanyServices.Id}></ww-clients-skus>`;
  };
  const htmlDefaultVatRates = () => {
    return html` <ww-clients-default-vat-rates .clientId=${currentCompanyServices.Id}></ww-clients-default-vat-rates>`;
  };
  const htmlServices = () => {
    return html` <div class="mt-8 mb-2">
			<div class="xl:mx-auto xl:w-1/2 mx-8 font-bold flex justify-between items-center">
				${templateTitle("Services")}
				<div class="flex space-x-4">
					${currentCompanyServices.IsRejected ? html` <button ?disabled=${!permissionManager.hasPermission("Company_Details_Modify")} class="btn btn-primary" @click=${() => reOpenClient(currentCompanyServices.Id)}>
								Reopen
						  </button>` : ""}
					${currentCompanyServices.IsInactive ? html` <button ?disabled=${!permissionManager.hasPermission("Company_Details_Modify")} class="btn btn-primary" @click=${() => reOpenClient(currentCompanyServices.Id)}>
								Activate
						  </button>` : ""}
					${!currentCompanyServices.IsInactive && !currentCompanyServices.IsRejected ? html` <button ?disabled=${!permissionManager.hasPermission("Company_Details_Reject")} class="btn btn-primary" @click=${() => rejectClient(currentCompanyServices.Id)}>
									Reject
								</button>
								<button ?disabled=${!permissionManager.hasPermission("Company_Details_Modify")} class="btn btn-primary" @click=${() => inactivateClient(currentCompanyServices.Id)}>
									Inactivate
								</button>` : ""}
				</div>
			</div>
			${loading.count > 0 ? html` <div class="mx-8">Loading...</div>` : servicesHook.mainTemplate()}
		</div>`;
  };
  const htmlClientDetails = () => {
    if (selectedSubmenu === "q11e") {
      return htmlQuestionnaire();
    } else if (selectedSubmenu === "sku") {
      return htmlSku();
    } else if (selectedSubmenu === "vat_rates") {
      return htmlDefaultVatRates();
    } else {
      return htmlServices();
    }
  };
  return html` <div class="my-2 mx-8 mb-16 mt-8">
		${clientsResult === void 0 || loading.count > 0 ? html` <div class="xl:mx-auto xl:w-2/3">Loading...</div>` : currentCompanyServices ? htmlClientDetails() : html`${htmlClientsGrid()}${htmlAssignModal()}`}
	</div>`;
}
