import "./dc-step-progressbar.scss";
import { html } from "lit-html";
import { styleMap } from "lit-html/directives/style-map.js";
import { classMap } from "lit-html/directives/class-map";
import { component } from "haunted";
export class ClickEvent extends CustomEvent {
  constructor(detail) {
    super("click", { detail });
  }
}
export const Component = (host) => {
  const props = {
    items: host.items,
    activeIndex: host.activeIndex,
    inactiveClickable: host.inactiveClickable !== void 0 ? host.inactiveClickable : false
  };
  const onClick = (e, step) => {
    e.stopPropagation();
    host.dispatchEvent(
      new ClickEvent({
        step
      })
    );
  };
  return html`
		<div>
			<ul>
				${(props.items || []).map(
    (item, i) => html`
						<li
							style=${styleMap({ width: `${100 / props.items.length}%` })}
							class=${classMap({
      "active": i < props.activeIndex,
      "current": i === props.activeIndex,
      "clickable-inactive": props.inactiveClickable
    })}
							@click=${(e) => onClick(e, i)}
						>
							<span class="hidden sm:block capitalize">${item}</span>
						</li>
					`
  )}
			</ul>
		</div>
	`;
};
customElements.define(
  "dc-step-progressbar",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
