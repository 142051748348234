import { CustomParamBase } from "./CustomParamBase";
import { CompanyCustomParam } from "./CompanyCustomParam";
import { VatRegCustomParam } from "./VatRegCustomParam";
export class CustomParamBaseFactory {
  static create($type) {
    if ($type === "V1.TemplateMapping.CustomParamBase") {
      const result = new CustomParamBase();
      return result;
    }
    if ($type === "V1.TemplateMapping.CompanyCustomParam") {
      const result = new CompanyCustomParam();
      return result;
    }
    if ($type === "V1.TemplateMapping.VatRegCustomParam") {
      const result = new VatRegCustomParam();
      return result;
    }
    throw new Error($type + " not found");
  }
}
