var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  AddCertificateStatusChange as IOSSAddCertificateStatusChange,
  BackToClientStatusChange as IOSSBackToClientStatusChange,
  SimpleRegistrationStatusChange as IOSSSimpleRegistrationStatusChange,
  DeregisteredStatusChange as IOSSDeregisteredStatusChange
} from "AdminServices/APIs/self/models/V1/Company/IossRegistration";
export const getIOSSRegistrationStatusChange = (fromStatus, toStatus, comment, iossCertificate, deregisterChange) => {
  const fromTo = {
    From: fromStatus,
    To: toStatus
  };
  if (fromStatus === "SentToTA" && toStatus === "RegistrationCompleted") {
    return new IOSSAddCertificateStatusChange(__spreadProps(__spreadValues({}, fromTo), {
      RegistrationNumber: iossCertificate.RegistrationNumber,
      RegistrationDate: iossCertificate.RegistrationDate,
      CertificateReferenceId: iossCertificate.CertificateReferenceId
    }));
  } else if (fromStatus === "DocumentsSigned" && toStatus === "WaitingForSignature") {
    return new IOSSBackToClientStatusChange(__spreadProps(__spreadValues({}, fromTo), {
      Comment: comment
    }));
  } else if (fromStatus === "DeregistrationInProgress" && toStatus === "Deregistered") {
    return new IOSSDeregisteredStatusChange(__spreadProps(__spreadValues({}, fromTo), {
      DeregistrationConfirmationDocumentId: deregisterChange.DocumentId,
      DateOfDeregistration: deregisterChange.DateOfDeregistration
    }));
  } else {
    return new IOSSSimpleRegistrationStatusChange(fromTo);
  }
};
