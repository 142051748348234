import { ComplianceUploadPeriodFilter } from "./ComplianceUploadPeriodFilter";
export class Filter {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Filter
     */
    this.$type = "V1.ComplianceUploads.Search.Filter";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientId = _data["ClientId"];
      this.ClientName = _data["ClientName"];
      if (_data["ComplianceUploadPeriodFilter"] && _data["ComplianceUploadPeriodFilter"].constructor === Array) {
        this.ComplianceUploadPeriodFilter = [];
        for (let item of _data["ComplianceUploadPeriodFilter"]) {
          const complianceUploadPeriodFilter_ = new ComplianceUploadPeriodFilter();
          this.ComplianceUploadPeriodFilter.push(complianceUploadPeriodFilter_.init(item));
        }
      }
      this.ComplianceUploadType = FilterComplianceUploadType[_data["ComplianceUploadType"]];
      this.Period = _data["Period"];
      if (_data["Statuses"] && _data["Statuses"].constructor === Array) {
        this.Statuses = [];
        for (let item of _data["Statuses"]) {
          this.Statuses.push(FilterStatuses[item]);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientId"] = this.ClientId;
    _data["ClientName"] = this.ClientName;
    if (this.ComplianceUploadPeriodFilter && this.ComplianceUploadPeriodFilter.constructor === Array) {
      _data["ComplianceUploadPeriodFilter"] = [];
      for (let item of this.ComplianceUploadPeriodFilter) {
        _data["ComplianceUploadPeriodFilter"].push(item.toJSON());
      }
    }
    _data["ComplianceUploadType"] = FilterComplianceUploadType[this.ComplianceUploadType];
    _data["Period"] = this.Period;
    if (this.Statuses && this.Statuses.constructor === Array) {
      _data["Statuses"] = [];
      for (let item of this.Statuses) {
        _data["Statuses"].push(FilterStatuses[item]);
      }
    }
    return _data;
  }
}
export var FilterComplianceUploadType = /* @__PURE__ */ ((FilterComplianceUploadType2) => {
  FilterComplianceUploadType2["IOSS"] = "IOSS";
  FilterComplianceUploadType2["VAT"] = "VAT";
  return FilterComplianceUploadType2;
})(FilterComplianceUploadType || {});
export var FilterStatuses = /* @__PURE__ */ ((FilterStatuses2) => {
  FilterStatuses2["New"] = "New";
  FilterStatuses2["WaitingForFinalize"] = "WaitingForFinalize";
  FilterStatuses2["InvalidData"] = "InvalidData";
  FilterStatuses2["SaftCompliance"] = "SaftCompliance";
  FilterStatuses2["MasterDataSetup"] = "MasterDataSetup";
  FilterStatuses2["DataUploadFinalized"] = "DataUploadFinalized";
  FilterStatuses2["DataVatGenerated"] = "DataVatGenerated";
  FilterStatuses2["SentToReturnGeneration"] = "SentToReturnGeneration";
  FilterStatuses2["InsufficientCredit"] = "InsufficientCredit";
  FilterStatuses2["UploadProcessed"] = "UploadProcessed";
  FilterStatuses2["Error"] = "Error";
  return FilterStatuses2;
})(FilterStatuses || {});
