var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient, useEffect, useMemo, useState, useValidationContext, useCallback } from "GlobalShared/haunted/CustomHooks";
import { notifyError, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { addHandler, getRouteElement, navigateTo } from "GlobalShared/router";
import { editIcon, templateTitle } from "GlobalShared/templates/commons";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import { html } from "lit-html";
import {
  DEFAULT_DRAFT_TEMPLATE_VERSION,
  RegTypeDataSource,
  DEFAULT_TEMPLATE,
  TEMPLATE_TYPES,
  VatRegTemplateStatusDataSource,
  CompanyTemplateStatusDataSource,
  CompanyTypeDataSource,
  CategoryDataSource
} from "./TemplateModels";
import { getRouteByKey, currentLocale } from "../../globals";
import { Company as CompanyClient } from "AdminServices/APIs/self/clients";
import { Filter, Pager, SearchRequest } from "AdminServices/APIs/self/models/V1/Company/Search";
import { DateTimeUtcRange } from "AdminServices/APIs/self/models/V1/Common/DateTime/DateTimeUtcRange";
import { Field, FieldOrder, FieldOrderByField } from "AdminServices/APIs/self/models/V1/Company/Search/Field";
import { getAllTemplateMainFields, TemplateMainValidator } from "./TemplateValidator";
import { formatDate, MAX_DATE, MIN_DATE } from "GlobalShared/helpers/dateHelper";
import { DocumentGenerator as DocGenClient } from "AdminServices/APIs/self/clients/DocumentGenerator";
import { Templates as TemplatesClient } from "AdminServices/APIs/self/clients/Templates";
import { VatRegistration as VatRegistrationClient } from "AdminServices/APIs/self/clients/VatRegistration";
import { fromMappingItem, getCustomFromModel, getCustomModel, mapCompanyDetailDataSource as mapDataSource, toMappingItem } from "./templateHelper";
import { PublishRequest, TemplateRequest, TemplateUpdateRequest } from "AdminServices/APIs/self/models/V1/TemplateMapping";
import { PreviewRequest } from "AdminServices/APIs/self/models/V1/DocGenerator/PreviewRequest";
import { useEUCountries, usePermissionManager, useServiceCountries, useWorldCountries } from "AdminComponents/hooks/AdminCustomHooks";
import {
  CompanyTemplateField,
  CompanyTemplateFilter,
  CompanyTemplateSearchRequest,
  TemplatePager,
  VatRegTemplateField,
  VatRegTemplateFilter,
  VatRegTemplateSearchRequest
} from "AdminServices/APIs/self/models/V1/TemplateMapping/TemplateSearch";
import { VatRegTemplateFieldOrder } from "AdminServices/APIs/self/models/V1/TemplateMapping/TemplateSearch/VatRegTemplateField";
import { CompanyTemplateFieldOrder } from "AdminServices/APIs/self/models/V1/TemplateMapping/TemplateSearch/CompanyTemplateField";
import { ONLY_PDF_ACCEPT_LIST, simulateDownload } from "GlobalShared/helpers/fileHelper";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
const VatRegFieldNames = {
  Id: "Id",
  Name: "Name",
  Culture: "Culture",
  Status: "Status",
  Trigger: "Trigger",
  RegCountry: "RegCountry",
  RegType: "RegType",
  EstCountry: "EstCountry",
  Actions: "Actions",
  CompanyType: "CompanyType",
  Category: "Category"
};
const CompanyFieldNames = {
  Id: "Id",
  Name: "Name",
  Culture: "Culture",
  Trigger: "Trigger",
  Status: "Status",
  Actions: "Actions"
};
const DEFAULT_VATREG_FILTERS = [];
const DEFAULT_COMPANY_FILTERS = [];
const ALLEU = "ALLEU";
const ALLNONEU = "ALLNONEU";
const CULTURE_ENABLED_SERVICE_COUNTRIES = ["GB", "DE", "ES", "IT", "FR", "PL", "CZ"];
export function Templates() {
  const getTemplateTriggersDataSource = (templateType) => {
    return TEMPLATE_TYPES.filter((type) => type.Id === templateType).reduce((aggr, curr) => {
      return aggr.concat(
        curr.Triggers.map((trigger) => ({
          value: trigger.Id,
          label: trigger.Label
        }))
      );
    }, []);
  };
  const getCompanyFilter = (text) => {
    const clientFilter = new Filter();
    clientFilter.RegistrationDate = new DateTimeUtcRange({
      From: MIN_DATE,
      To: MAX_DATE
    });
    clientFilter.CompanyName = text;
    return clientFilter;
  };
  const getValidity = () => {
    if (currentTemplateVersion.Version) {
      if (currentTemplateVersion.Draft) {
        return "DRAFT";
      } else {
        return `${formatDate(currentTemplateVersion.ValidFrom)} - ${currentTemplateVersion.ValidTo ? formatDate(currentTemplateVersion.ValidTo) : ""}`;
      }
    } else {
      return "-";
    }
  };
  const setTemplateVersion = (templateId, templateVersionNum) => __async(this, null, function* () {
    if (templateVersionNum) {
      withErrorHandling(
        () => __async(this, null, function* () {
          const templateVersion = (yield templatesClient.getTemplateVersion({
            templateId,
            templateVersion: templateVersionNum
          })).data;
          setCurrentTemplateVersion({
            TemplateId: templateId,
            Version: templateVersion.Version,
            Draft: templateVersion.Draft,
            ValidFrom: templateVersion.ValidFrom,
            ValidTo: currentTemplate.Versions.filter((v) => !v.Draft && !v.Disabled && v.ValidFrom > templateVersion.ValidFrom).reduce((aggr, version) => {
              if (version.ValidFrom !== void 0) {
                const safeAggr = aggr === void 0 ? new Date(2100, 1, 1) : aggr;
                if (version.ValidFrom < safeAggr) {
                  return version.ValidFrom;
                } else {
                  return aggr;
                }
              }
              return aggr;
            }, void 0),
            Disabled: templateVersion.Disabled,
            TemplateReference: templateVersion.TemplateReference,
            PDFFields: templateVersion.PdfFields.map(
              (sup) => ({
                name: sup.Name,
                type: sup.Type,
                values: sup.Values
              })
            ),
            MappingsItems: templateVersion.MappingItems.map(fromMappingItem),
            Created: templateVersion.Created,
            CreatedBy: templateVersion.CreatedBy,
            Updated: templateVersion.Updated,
            UpdatedBy: templateVersion.UpdatedBy
          });
        }),
        (error) => {
          notifyError(error);
        }
      );
    } else {
      setCurrentTemplateVersion(DEFAULT_DRAFT_TEMPLATE_VERSION);
    }
  });
  const addTemplate = (template) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield templatesClient.addTemplate({
          body: new TemplateRequest({
            Name: template.Name,
            Culture: template.Culture,
            CustomParam: getCustomModel(template)
          })
        });
        yield searchTemplates();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const updateTemplate = (template) => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        yield templatesClient.updateTemplate({
          body: new TemplateUpdateRequest({
            Id: template.Id,
            Name: template.Name,
            CustomParam: getCustomModel(template)
          })
        });
        yield setTemplate(template.Id);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const saveMappingItems = (template, templateVersion, mappingItems) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = (yield templatesClient.saveMappings({
          templateId: template.Id,
          templateVersion: templateVersion.Version,
          body: mappingItems.map(toMappingItem)
        })).data;
        return result.map(fromMappingItem);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const removeMapping = (templateVersion, mapping) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        templatesClient.removeMapping({
          templateId: templateVersion.TemplateId,
          templateVersion: templateVersion.Version,
          pdfFieldName: mapping.pdfFieldName
        });
        return true;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const uploadPDFTemplate = (templateVersion, files) => {
    withErrorHandling(
      () => __async(this, null, function* () {
        const uploadPdfResult = (yield templatesClient.uploadPdfTemplate({
          templateId: templateVersion.TemplateId,
          templateVersion: templateVersion.Version,
          file: files[0]
        })).data;
        const supportedPdfFields = uploadPdfResult.SupportedFields.map((sup) => ({
          name: sup.Name,
          type: sup.Type,
          values: sup.Values
        }));
        supportedPdfFields.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCurrentTemplateVersion(__spreadProps(__spreadValues({}, currentTemplateVersion), {
          TemplateReference: uploadPdfResult.TemplateReference,
          PDFFields: supportedPdfFields
        }));
        setShowPDFTemplateUploadModal(false);
      }),
      (error) => {
        notifyError(error);
      }
    );
  };
  const publishTemplateVersion = (templateVersion, validFrom) => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        yield templatesClient.publishTemplateVersion({
          templateId: templateVersion.TemplateId,
          templateVersion: templateVersion.Version,
          body: new PublishRequest({ ValidFrom: validFrom })
        });
        yield setTemplate(templateVersion.TemplateId);
        yield setTemplateVersion(templateVersion.TemplateId, templateVersion.Version);
        yield searchTemplates();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const enableTemplateVersion = (templateVersion) => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        yield templatesClient.enableTemplateVersion({
          templateId: templateVersion.TemplateId,
          templateVersion: templateVersion.Version
        });
        setCurrentTemplateVersion(__spreadProps(__spreadValues({}, currentTemplateVersion), { Disabled: false }));
        yield searchTemplates();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const disableTemplateVersion = (templateVersion) => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        yield templatesClient.disableTemplateVersion({
          templateId: templateVersion.TemplateId,
          templateVersion: templateVersion.Version
        });
        setCurrentTemplateVersion(__spreadProps(__spreadValues({}, currentTemplateVersion), { Disabled: true }));
        yield searchTemplates();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const deleteTemplateVersion = (templateVersion) => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        yield templatesClient.deleteTemplateVersion({
          templateId: templateVersion.TemplateId,
          templateVersion: templateVersion.Version
        });
        yield setTemplate(templateVersion.TemplateId);
        yield searchTemplates();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const setTemplate = (templateId) => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const template = (yield templatesClient.getTemplate({ templateId })).data;
        setCurrentTemplate({
          Id: template.Id,
          Name: template.Name,
          Culture: template.Culture,
          Type: template.TemplateType,
          Versions: template.Versions.map((v) => ({
            Version: v.Version,
            Draft: v.Draft,
            ValidFrom: v.ValidFrom,
            Disabled: v.Disabled
          })),
          Custom: getCustomFromModel(template)
        });
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getCompanyDataSource = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        let companyDataSource;
        if (currentCompany) {
          companyDataSource = (yield vatRegistrationClient.getDataSource({
            companyId: currentCompany.Id,
            vatRegCountry: currentTemplate.Custom.Country,
            templateId: currentTemplate.Id
          })).data;
        } else {
          companyDataSource = (yield vatRegistrationClient.getEmptyDataSource({
            vatRegCountry: currentTemplate.Custom.Country,
            templateId: currentTemplate.Id
          })).data;
        }
        setCurrentDataSource(mapDataSource(companyDataSource));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getVatRegTemplateFilter = () => {
    const vatRegFilter = new VatRegTemplateFilter();
    vatRegGridState.appliedFilters.forEach((filter) => {
      if (filter.field === VatRegFieldNames.Name) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.Name = filter.filterProps.selectedValues;
        }
      } else if (filter.field === VatRegFieldNames.Culture) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.Culture = filter.filterProps.selectedValues;
        }
      } else if (filter.field === VatRegFieldNames.Trigger) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.Trigger = filter.filterProps.selectedValues;
        }
      } else if (filter.field === VatRegFieldNames.RegCountry) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.Country = filter.filterProps.selectedValues;
        }
      } else if (filter.field === VatRegFieldNames.RegType) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.RegistrationType = filter.filterProps.selectedValues;
        }
      } else if (filter.field === VatRegFieldNames.EstCountry) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.EstablishmentCountry = filter.filterProps.selectedValues;
        }
      } else if (filter.field === VatRegFieldNames.Status) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.TemplateStatus = filter.filterProps.selectedValues;
        }
      } else if (filter.field === VatRegFieldNames.CompanyType) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.CompanyType = filter.filterProps.selectedValues;
        }
      } else if (filter.field === VatRegFieldNames.Category) {
        if (filter.filterProps.selectedValues) {
          vatRegFilter.Category = filter.filterProps.selectedValues;
        }
      }
    });
    return vatRegFilter;
  };
  const getCompanyTemplateFilter = () => {
    const companyFilter = new CompanyTemplateFilter();
    companyGridState.appliedFilters.forEach((filter) => {
      if (filter.field === VatRegFieldNames.Name) {
        if (filter.filterProps.selectedValues) {
          companyFilter.Name = filter.filterProps.selectedValues;
        }
      } else if (filter.field === VatRegFieldNames.Culture) {
        if (filter.filterProps.selectedValues) {
          companyFilter.Culture = filter.filterProps.selectedValues;
        }
      } else if (filter.field === VatRegFieldNames.Status) {
        if (filter.filterProps.selectedValues) {
          companyFilter.TemplateStatus = filter.filterProps.selectedValues;
        }
      }
    });
    return companyFilter;
  };
  const searchTemplates = () => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        if (currentTemplateType === "MPVatReg") {
          const result = (yield templatesClient.searchVatRegTemplates({
            body: new VatRegTemplateSearchRequest({
              Filter: getVatRegTemplateFilter(),
              Pager: new TemplatePager({
                Skip: vatRegGridState.pageIndex * vatRegGridState.pageSize,
                Top: vatRegGridState.pageSize
              }),
              OrderBy: [
                new VatRegTemplateField({
                  Order: vatRegGridState.orderDir === "asc" ? VatRegTemplateFieldOrder.Ascending : VatRegTemplateFieldOrder.Descending,
                  OrderByField: vatRegGridState.orderBy
                })
              ]
            })
          })).data;
          const vatRegTemplateGridVM = {
            total: result.Count,
            templates: result.TemplateSearchContents.map((templ) => {
              const row = {
                Id: templ.Id,
                Name: templ.Name,
                Culture: templ.Culture,
                Trigger: templ.Trigger,
                RegCountry: templ.Country,
                RegType: templ.RegistrationType,
                EstCountry: templ.EstablishmentCountries.join(", "),
                Status: templ.TemplateStatus,
                CompanyType: templ.CompanyType,
                Category: templ.Category
              };
              return row;
            })
          };
          setVatRegTemplatesResult(vatRegTemplateGridVM);
        }
        if (currentTemplateType === "MPCompany") {
          const result = (yield templatesClient.searchCompanyTemplates({
            body: new CompanyTemplateSearchRequest({
              Filter: getCompanyTemplateFilter(),
              Pager: new TemplatePager({
                Skip: companyGridState.pageIndex * companyGridState.pageSize,
                Top: companyGridState.pageSize
              }),
              OrderBy: [
                new CompanyTemplateField({
                  Order: companyGridState.orderDir === "asc" ? CompanyTemplateFieldOrder.Ascending : CompanyTemplateFieldOrder.Descending,
                  OrderByField: companyGridState.orderBy
                })
              ]
            })
          })).data;
          const companyTemplateGridVM = {
            total: result.Count,
            templates: result.TemplateSearchContents.map((templ) => {
              const row = {
                Id: templ.Id,
                Name: templ.Name,
                Culture: templ.Culture,
                Trigger: templ.Trigger,
                Status: templ.TemplateStatus
              };
              return row;
            })
          };
          setCompanyTemplatesResult(companyTemplateGridVM);
        }
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const generateVatRegPDF = (template, templateVersion, dataSourceId) => {
    withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield docGenClient.generatePreview({
          body: new PreviewRequest({
            CompanyId: dataSourceId,
            CountryCode: template.Custom.Country,
            TemplateId: templateVersion.TemplateId,
            TemplateVersion: templateVersion.Version
          })
        });
        simulateDownload(response.data);
      }),
      (error) => {
        if (error.statusCode === 422) {
          notifyWarning("No mapping could be evaluated");
        } else {
          notifyError(error);
        }
      }
    );
  };
  const cloneAsDraft = (templateVersion) => {
    withErrorHandling(
      () => __async(this, null, function* () {
        const response = (yield templatesClient.cloneTemplateVersionToDraft({
          templateId: templateVersion.TemplateId,
          templateVersion: templateVersion.Version
        })).data;
        yield setTemplate(templateVersion.TemplateId);
        yield setTemplateVersion(templateVersion.TemplateId, response.Version);
      }),
      (error) => {
        notifyError(error);
      }
    );
  };
  const downloadPDFTemplate = (templateVersion) => {
    withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield templatesClient.downloadPdfTemplate({
          templateId: templateVersion.TemplateId,
          templateVersion: templateVersion.Version
        });
        simulateDownload(response.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  };
  const calculateEstCountries = (selectedEstCountries) => {
    const effectiveCountries = [];
    selectedEstCountries.forEach((ec) => {
      if (ec === "ALLEU") {
        effectiveCountries.push(...euCountryCodes);
      } else if (ec === "ALLNONEU") {
        effectiveCountries.push(...nonEuCountryCodes);
      } else {
        effectiveCountries.push(ec);
      }
    });
    return [...new Set(effectiveCountries)];
  };
  const toSelectItem = (company) => {
    return {
      value: company.Id.toString(),
      label: company.CompanyName
    };
  };
  const companyClient = useClient(CompanyClient);
  const docGenClient = useClient(DocGenClient);
  const templatesClient = useClient(TemplatesClient);
  const vatRegistrationClient = useClient(VatRegistrationClient);
  const [estNewTemplateEstCountries, setNewTemplateEstCountries] = useState(void 0);
  const estCountryDataSourceCallback = useCallback(
    (text) => {
      return estNewTemplateEstCountries.filter((item) => isEmpty(text) || item.label.toLowerCase().includes(text.toLowerCase()));
    },
    [estNewTemplateEstCountries]
  );
  const [vatRegGridState, setVatRegGridState] = useState({
    pageIndex: 0,
    appliedFilters: DEFAULT_VATREG_FILTERS,
    pageSize: 20,
    orderBy: VatRegFieldNames.Name,
    orderDir: "asc"
  });
  const [companyGridState, setCompanyGridState] = useState({
    pageIndex: 0,
    appliedFilters: DEFAULT_COMPANY_FILTERS,
    pageSize: 20,
    orderBy: CompanyFieldNames.Name,
    orderDir: "asc"
  });
  const worldCountries = useWorldCountries(currentLocale);
  const serviceCountries = useServiceCountries(currentLocale);
  const serviceCountryList = useMemo(() => serviceCountries.map((sc) => ({ label: sc.Name, value: sc.Code })), [serviceCountries]);
  const cultureCountryList = useMemo(
    () => serviceCountries.filter((sc) => CULTURE_ENABLED_SERVICE_COUNTRIES.includes(sc.Code)).map((sc) => ({ label: sc.Name, value: sc.Code })),
    [serviceCountries]
  );
  const [vatRegTemplatesResult, setVatRegTemplatesResult] = useState(void 0);
  const [companyTemplatesResult, setCompanyTemplatesResult] = useState(void 0);
  const [currentTemplate, setCurrentTemplate] = useState(void 0);
  const [currentTemplateMain, setCurrentTemplateMain] = useState(DEFAULT_TEMPLATE);
  const [currentTemplateVersion, setCurrentTemplateVersion] = useState(DEFAULT_DRAFT_TEMPLATE_VERSION);
  const [currentPathname, setCurrentPathname] = useState(window.location.pathname);
  const [currentDataSource, setCurrentDataSource] = useState(void 0);
  const [showPDFTemplateUploadModal, setShowPDFTemplateUploadModal] = useState(false);
  const [showTemplateMainModal, setShowTemplateMainModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [publishValidFrom, setPublishValidFrom] = useState(/* @__PURE__ */ new Date());
  const [publishValidFromValidationMessage, setPublishValidFromValidationMessage] = useState(void 0);
  const templateMainValidationContext = useValidationContext(new TemplateMainValidator());
  const [dispatchIfTemplateMainValid, setDispatchIfTemplateMainValid] = useState(false);
  const euCountries = useEUCountries(currentLocale);
  const euCountryCodes = useMemo(() => euCountries.map((c) => c.Code), [euCountries]);
  const nonEuCountryCodes = useMemo(() => worldCountries.filter((wc) => !euCountries.some((ec) => ec.Code === wc.Code)).map((c) => c.Code), [euCountries, worldCountries]);
  const [currentEstCountries, setCurrentEstCountries] = useState([]);
  const [currentTemplateType, setcurrentTemplateType] = useState(void 0);
  const permissionManager = usePermissionManager();
  useEffect(() => {
    setNewTemplateEstCountries(
      [
        { label: "<All EU>", value: ALLEU },
        { label: "<All Non-EU>", value: ALLNONEU }
      ].concat(
        worldCountries.map((country) => ({
          label: country.Name,
          value: country.Code
        }))
      )
    );
  }, [worldCountries]);
  useEffect(() => __async(this, null, function* () {
    if (dispatchIfTemplateMainValid) {
      setDispatchIfTemplateMainValid(false);
      if (templateMainValidationContext.validationResult.isValid()) {
        if (currentTemplateMain.Id === void 0) {
          yield addTemplate(currentTemplateMain);
        } else {
          yield updateTemplate(currentTemplateMain);
        }
        setShowTemplateMainModal(false);
      }
    }
  }), [templateMainValidationContext.validationResult]);
  useEffect(() => {
    if (currentTemplateMain !== void 0) {
      templateMainValidationContext.validate(currentTemplateMain);
    }
  }, [currentTemplateMain]);
  useEffect(() => {
    if (currentTemplateType !== void 0) {
      searchTemplates();
    }
  }, [currentTemplateType]);
  const [companiesResult, setCompaniesResult] = useState(void 0);
  const [currentCompany, setCurrentCompany] = useState(void 0);
  useEffect(() => __async(this, null, function* () {
    const templateTypeFromPath = getRouteElement("templateTypeId");
    const templateIdFromPath = getRouteElement("templateId");
    if (templateTypeFromPath === void 0) {
      setcurrentTemplateType(void 0);
    } else {
      setcurrentTemplateType(templateTypeFromPath);
    }
    setCurrentCompany(void 0);
    setCurrentDataSource(void 0);
    if (templateIdFromPath === void 0) {
      setCurrentTemplate(void 0);
      setCurrentTemplateVersion(DEFAULT_DRAFT_TEMPLATE_VERSION);
    } else {
      setTemplate(Number(templateIdFromPath));
    }
  }), [currentPathname]);
  useEffect(() => __async(this, null, function* () {
    var _a;
    if (currentTemplate !== void 0) {
      const draftVersion = (_a = currentTemplate.Versions.find((version) => version.Draft)) == null ? void 0 : _a.Version;
      if (draftVersion) {
        yield setTemplateVersion(currentTemplate.Id, draftVersion);
      } else {
        const latestVersion = currentTemplate.Versions.reduce((aggr, curr) => aggr === void 0 || curr.Version > aggr ? curr.Version : aggr, void 0);
        if (latestVersion) {
          yield setTemplateVersion(currentTemplate.Id, latestVersion);
        }
      }
    }
  }), [currentTemplate]);
  useEffect(() => __async(this, null, function* () {
    if (currentTemplate && currentTemplate.Type === "MPVatReg") {
      getCompanyDataSource();
    }
  }), [currentCompany, currentTemplate]);
  useEffect(() => __async(this, null, function* () {
    searchTemplates();
  }), [vatRegGridState, companyGridState]);
  useEffect(() => {
    setCurrentTemplateMain(__spreadProps(__spreadValues({}, currentTemplateMain), {
      Custom: __spreadProps(__spreadValues({}, currentTemplateMain.Custom), {
        EstablishmentCountry: calculateEstCountries(currentEstCountries)
      })
    }));
  }, [currentEstCountries]);
  const init = () => {
    addHandler("templates", (location) => {
      setCurrentPathname(location.pathname);
    });
  };
  useEffect(init, []);
  const onEditClicked = (id) => {
    navigateTo(
      getRouteByKey("templates(/:templateTypeId)(/:templateId)").replace(/\(\/:templateTypeId\)/, `/${currentTemplateType}`).replace(/\(\/:templateId\)/, `/${id}`)
    );
  };
  const onSaveMappings = (mappingItem) => __async(this, null, function* () {
    const savedMappingItems = yield saveMappingItems(currentTemplate, currentTemplateVersion, mappingItem);
    let newMappingItems = currentTemplateVersion.MappingsItems;
    savedMappingItems.forEach((smi) => {
      let found = false;
      newMappingItems = newMappingItems.map((mi) => {
        if (mi.PdfFieldName === smi.PdfFieldName) {
          found = true;
          return smi;
        } else {
          return mi;
        }
      });
      if (!found) {
        newMappingItems.push(smi);
      }
    });
    setCurrentTemplateVersion(__spreadProps(__spreadValues({}, currentTemplateVersion), {
      MappingsItems: newMappingItems
    }));
    return true;
  });
  const onClearMapping = (mapping) => __async(this, null, function* () {
    if (currentTemplateVersion.MappingsItems.some((mi) => mi.PdfFieldName === mapping.pdfFieldName)) {
      const result = yield removeMapping(currentTemplateVersion, mapping);
      if (result) {
        setCurrentTemplateVersion(__spreadProps(__spreadValues({}, currentTemplateVersion), {
          MappingsItems: currentTemplateVersion.MappingsItems.filter((mi) => mi.PdfFieldName !== mapping.pdfFieldName)
        }));
      }
      return result;
    }
    return true;
  });
  const onVatRegSortChanged = (e) => {
    const OrderByValue = e.detail.orderBy;
    const OrderDirValue = e.detail.orderDir;
    setVatRegGridState(__spreadProps(__spreadValues({}, vatRegGridState), { orderBy: OrderByValue, orderDir: OrderDirValue }));
  };
  const onVatRegPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setVatRegGridState(__spreadProps(__spreadValues({}, vatRegGridState), { pageIndex, pageSize }));
  };
  const onVatRegFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, vatRegGridState), {
      appliedFilters: mergeAppliedFilter(vatRegGridState.appliedFilters, e.detail)
    });
    setVatRegGridState(newGridFilter);
  };
  const onCompanySortChanged = (e) => {
    const OrderByValue = e.detail.orderBy;
    const OrderDirValue = e.detail.orderDir;
    setCompanyGridState(__spreadProps(__spreadValues({}, companyGridState), { orderBy: OrderByValue, orderDir: OrderDirValue }));
  };
  const onCompanyPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setCompanyGridState(__spreadProps(__spreadValues({}, companyGridState), { pageIndex, pageSize }));
  };
  const onCompanyFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, companyGridState), {
      appliedFilters: mergeAppliedFilter(companyGridState.appliedFilters, e.detail)
    });
    setCompanyGridState(newGridFilter);
  };
  const htmlPublishModal = () => {
    return html`<dc-modal
			.visible=${showPublishModal}
			@close=${() => setShowPublishModal(false)}
			.header=${"Set valid from"}
			.content=${html`<div>
				<div class="grid md:grid-cols-2 xl:grid-cols-4 gap-4">
					<dc-datepicker
						.label=${"Valid from"}
						.value=${publishValidFrom}
						.min=${/* @__PURE__ */ new Date()}
						.validationMessage=${publishValidFromValidationMessage}
						@change=${(e) => {
      setPublishValidFrom(e.detail.value);
    }}
					></dc-datepicker>

					<div>
						<div class="mt-8 space-x-4">
							<button class="btn btn-primary" @click=${() => setShowPublishModal(false)}>Cancel</button>
							<button
								class="btn btn-primary"
								@click=${() => __async(this, null, function* () {
      yield publishTemplateVersion(currentTemplateVersion, publishValidFrom);
      setShowPublishModal(false);
    })}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>`}
		>
		</dc-modal>`;
  };
  const htmlTemplateAttributesModal = () => {
    var _a;
    return html`<dc-modal
			.visible=${showTemplateMainModal}
			@close=${() => setShowTemplateMainModal(false)}
			.header=${currentTemplateMain.Id === void 0 ? `New Template (${currentTemplateType})` : `Edit template (${currentTemplateType})`}
			.content=${html`<div style="height:60vh">
				<div class="grid md:grid-cols-2 xl:grid-cols-4 gap-4">
					<dc-input
						.label=${"Name*"}
						.value=${currentTemplateMain.Name}
						.validationMessage=${templateMainValidationContext.getValidationMessage("Name")}
						@change=${(e) => {
      templateMainValidationContext.validateField("Name");
      setCurrentTemplateMain(__spreadProps(__spreadValues({}, currentTemplateMain), { Name: e.detail.value }));
    }}
					></dc-input>
					<dc-select
						.label=${"Culture*"}
						.selectedValues=${[currentTemplateMain.Culture]}
						.readonly=${currentTemplateMain.Id !== void 0}
						.validationMessage=${templateMainValidationContext.getValidationMessage("Culture")}
						.dataSource=${cultureCountryList}
						@change=${(e) => {
      templateMainValidationContext.validateField("Culture");
      setCurrentTemplateMain(__spreadProps(__spreadValues({}, currentTemplateMain), {
        Culture: e.detail.selectedValue
      }));
    }}
					></dc-select>
					${currentTemplateMain.Type === "MPVatReg" ? html`<dc-select
								.label=${"Category*"}
								.selectedValues=${[currentTemplateMain.Custom.Category]}
								.validationMessage=${templateMainValidationContext.getValidationMessage("VatRegCustom.Category")}
								.dataSource=${CategoryDataSource.map((category) => ({
      value: category,
      label: category
    }))}
								@change=${(e) => {
      templateMainValidationContext.validateField("VatRegCustom.Category");
      setCurrentTemplateMain(__spreadProps(__spreadValues({}, currentTemplateMain), {
        Custom: __spreadProps(__spreadValues({}, currentTemplateMain.Custom), {
          Category: e.detail.selectedValue
        })
      }));
    }}
						  ></dc-select>` : html`<div></div>`}
					<div></div>
					<dc-select
						.label=${"Trigger*"}
						.selectedValues=${[currentTemplateMain.Custom.Trigger]}
						.readonly=${currentTemplateMain.Id !== void 0}
						.validationMessage=${templateMainValidationContext.getValidationMessage("VatRegCustom.Trigger")}
						.dataSource=${getTemplateTriggersDataSource(currentTemplateMain.Type)}
						@change=${(e) => {
      templateMainValidationContext.validateField("VatRegCustom.Trigger");
      setCurrentTemplateMain(__spreadProps(__spreadValues({}, currentTemplateMain), {
        Custom: __spreadProps(__spreadValues({}, currentTemplateMain.Custom), {
          Trigger: e.detail.selectedValue
        })
      }));
    }}
					></dc-select>
					${currentTemplateMain.Type === "MPVatReg" ? html`<dc-select
								.label=${"Service Country*"}
								.selectedValues=${[currentTemplateMain.Custom.Country]}
								.readonly=${currentTemplateMain.Id !== void 0}
								.validationMessage=${templateMainValidationContext.getValidationMessage("VatRegCustom.Country")}
								.dataSource=${serviceCountryList}
								@change=${(e) => {
      templateMainValidationContext.validateField("VatRegCustom.Country");
      setCurrentTemplateMain(__spreadProps(__spreadValues({}, currentTemplateMain), {
        Custom: __spreadProps(__spreadValues({}, currentTemplateMain.Custom), {
          Country: e.detail.selectedValue
        })
      }));
    }}
						  ></dc-select>` : ""}
					${currentTemplateMain.Type === "MPVatReg" ? html`<dc-select
								.label=${"Reg. Type"}
								.selectedValues=${[currentTemplateMain.Custom.RegType]}
								.readonly=${currentTemplateMain.Id !== void 0}
								.validationMessage=${templateMainValidationContext.getValidationMessage("VatRegCustom.RegType")}
								.dataSource=${RegTypeDataSource.map((regType) => ({
      value: regType,
      label: regType
    }))}
								@change=${(e) => {
      templateMainValidationContext.validateField("VatRegCustom.RegType");
      setCurrentTemplateMain(__spreadProps(__spreadValues({}, currentTemplateMain), {
        Custom: __spreadProps(__spreadValues({}, currentTemplateMain.Custom), {
          RegType: e.detail.selectedValue
        })
      }));
    }}
						  ></dc-select>` : ""}
					${currentTemplateMain.Type === "MPVatReg" ? html`<dc-select
								.label=${"Company Type"}
								.selectedValues=${[currentTemplateMain.Custom.CompanyType]}
								.readonly=${currentTemplateMain.Id !== void 0}
								.validationMessage=${templateMainValidationContext.getValidationMessage("VatRegCustom.CompanyType")}
								.dataSource=${CompanyTypeDataSource.map((companyType) => ({
      value: companyType,
      label: companyType
    }))}
								@change=${(e) => {
      templateMainValidationContext.validateField("VatRegCustom.CompanyType");
      setCurrentTemplateMain(__spreadProps(__spreadValues({}, currentTemplateMain), {
        Custom: __spreadProps(__spreadValues({}, currentTemplateMain.Custom), {
          CompanyType: e.detail.selectedValue
        })
      }));
    }}
						  ></dc-select>` : ""}
					${currentTemplateMain.Type === "MPVatReg" ? html`${currentTemplateMain.Id !== void 0 ? html`<div class="md:col-span-2 xl:col-span-4">
										<label class="form-label">Est. Country</label>
										<div>${currentTemplateMain.Custom.EstablishmentCountry.join(", ")}</div>
								  </div>` : html`<dc-multiselect
										.label=${"Est. Country"}
										.selectedItems=${(_a = currentEstCountries == null ? void 0 : currentEstCountries.map((value) => {
      var _a2;
      return {
        label: (_a2 = estNewTemplateEstCountries.find((c) => c.value === value)) == null ? void 0 : _a2.label,
        value
      };
    })) != null ? _a : []}
										.readonly=${currentTemplateMain.Id !== void 0}
										.validationMessage=${templateMainValidationContext.getValidationMessage("VatRegCustom.EstCountry")}
										@change=${(e) => {
      templateMainValidationContext.validateField("VatRegCustom.EstCountry");
      setCurrentEstCountries(e.detail.items.map((item) => item.value));
    }}
										.dataSource=${estCountryDataSourceCallback}
								  ></dc-multiselect>`}` : ""}
				</div>
				<div>
					<div class="mt-8 space-x-4">
						<button class="btn btn-primary" @click=${() => setShowTemplateMainModal(false)}>Cancel</button>
						<button
							class="btn btn-primary"
							@click=${() => {
      templateMainValidationContext.validateFields(getAllTemplateMainFields(currentTemplateMain));
      setDispatchIfTemplateMainValid(true);
      setCurrentTemplateMain(__spreadValues({}, currentTemplateMain));
    }}
						>
							Save
						</button>
					</div>
				</div>
			</div>`}
		>
		</dc-modal>`;
  };
  const htmlVatRegTemplatesGrid = () => {
    const vm = {
      columns: [
        {
          field: VatRegFieldNames.Id,
          columnClass: "w-16",
          label: "Id",
          sortable: false,
          filterable: false,
          columnType: "string"
        },
        {
          field: VatRegFieldNames.Name,
          label: "Name",
          sortable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: VatRegFieldNames.Culture,
          columnClass: "w-24",
          label: "Culture",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: serviceCountryList.map((sc) => sc.value)
          },
          columnType: "enum"
        },
        {
          field: VatRegFieldNames.Trigger,
          label: "Trigger",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: getTemplateTriggersDataSource(currentTemplateType).map((s) => s.value)
          },
          columnType: "enum"
        },
        {
          field: VatRegFieldNames.RegCountry,
          columnClass: "w-32",
          label: "Reg. Country",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: serviceCountryList.map((sc) => sc.value)
          },
          columnType: "enum"
        },
        {
          field: VatRegFieldNames.RegType,
          label: "Reg. Type",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: RegTypeDataSource
          },
          columnType: "enum"
        },
        {
          field: VatRegFieldNames.EstCountry,
          label: "Est. Countries",
          columnClass: "w-32",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: worldCountries.map((sc) => sc.Code)
          },
          columnType: "enum"
        },
        {
          field: VatRegFieldNames.Status,
          label: "Status",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: VatRegTemplateStatusDataSource
          },
          columnType: "enum"
        },
        {
          field: VatRegFieldNames.CompanyType,
          label: "Company Type",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: CompanyTypeDataSource
          },
          columnType: "enum"
        },
        {
          field: VatRegFieldNames.Category,
          label: "Category",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: CategoryDataSource
          },
          columnType: "enum"
        },
        { field: VatRegFieldNames.Actions, columnClass: "w-16", label: "" }
      ],
      data: vatRegTemplatesResult.templates,
      sorting: {
        orderBy: vatRegGridState.orderBy,
        orderDir: vatRegGridState.orderDir
      },
      cellTemplate: (index, field) => {
        const item = vatRegTemplatesResult.templates[index];
        if (field === VatRegFieldNames.Id) {
          return item.Id.toString();
        } else if (field === VatRegFieldNames.Name) {
          return item.Name;
        } else if (field === VatRegFieldNames.Culture) {
          return item.Culture;
        } else if (field === VatRegFieldNames.Trigger) {
          return item.Trigger;
        } else if (field === VatRegFieldNames.RegCountry) {
          return item.RegCountry;
        } else if (field === VatRegFieldNames.RegType) {
          return item.RegType;
        } else if (field === VatRegFieldNames.EstCountry) {
          return html`<div class="w-32 truncate" title=${item.EstCountry}>${item.EstCountry}</div>`;
        } else if (field === VatRegFieldNames.Status) {
          return item.Status;
        } else if (field === VatRegFieldNames.CompanyType) {
          return item.CompanyType;
        } else if (field === VatRegFieldNames.Category) {
          return item.Category;
        } else if (field === VatRegFieldNames.Actions) {
          return html`
						<div class="w-full text-center">
							<button class="btn btn-sm btn-primary" @click=${() => onEditClicked(item.Id)}>${permissionManager.hasPermission("Template_Modify") ? "Edit" : "View"}</button>
						</div>
					`;
        } else {
          return "";
        }
      },
      appliedFilters: vatRegGridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: vatRegGridState.pageIndex,
        pageSize: vatRegGridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: vatRegTemplatesResult.total
      }
    };
    return html` <dc-table .vm=${vm} @onPageChange=${onVatRegPageChanged} @onSortChange=${onVatRegSortChanged} @onFilterChange=${onVatRegFilterChanged}></dc-table>`;
  };
  const htmlCompanyTemplatesGrid = () => {
    const vm = {
      columns: [
        {
          field: CompanyFieldNames.Id,
          columnClass: "w-16",
          label: "Id",
          sortable: false,
          filterable: false,
          columnType: "string"
        },
        {
          field: CompanyFieldNames.Name,
          label: "Name",
          sortable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: CompanyFieldNames.Culture,
          columnClass: "w-24",
          label: "Culture",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: serviceCountryList.map((sc) => sc.value)
          },
          columnType: "enum"
        },
        {
          field: CompanyFieldNames.Trigger,
          label: "Trigger",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: getTemplateTriggersDataSource(currentTemplateType).map((s) => s.value)
          },
          columnType: "enum"
        },
        {
          field: CompanyFieldNames.Status,
          label: "Status",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: CompanyTemplateStatusDataSource
          },
          columnType: "enum"
        },
        { field: CompanyFieldNames.Actions, columnClass: "w-16", label: "" }
      ],
      data: companyTemplatesResult.templates,
      sorting: {
        orderBy: companyGridState.orderBy,
        orderDir: companyGridState.orderDir
      },
      cellTemplate: (index, field) => {
        const item = companyTemplatesResult.templates[index];
        if (field === CompanyFieldNames.Id) {
          return item.Id.toString();
        } else if (field === CompanyFieldNames.Name) {
          return item.Name;
        } else if (field === CompanyFieldNames.Culture) {
          return item.Culture;
        } else if (field === CompanyFieldNames.Trigger) {
          return item.Trigger;
        } else if (field === CompanyFieldNames.Status) {
          return item.Status;
        } else if (field === CompanyFieldNames.Actions) {
          return html`
						<div class="w-full text-center">
							<button class="btn btn-sm btn-primary" @click=${() => onEditClicked(item.Id)}>${permissionManager.hasPermission("Template_Modify") ? "Edit" : "View"}</button>
						</div>
					`;
        } else {
          return "";
        }
      },
      appliedFilters: companyGridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: companyGridState.pageIndex,
        pageSize: companyGridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: companyTemplatesResult.total
      }
    };
    return html` <dc-table .vm=${vm} @onPageChange=${onCompanyPageChanged} @onSortChange=${onCompanySortChanged} @onFilterChange=${onCompanyFilterChanged}></dc-table>`;
  };
  const htmlButtonTmplate = () => {
    var _a, _b;
    return html` <div class="mb-4 items-center">
			<div class="flex space-x-4">
				${currentTemplateType === "MPVatReg" && ((_a = vatRegGridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html`<div>
							<button
								class="btn btn-primary"
								@click=${() => setVatRegGridState(__spreadProps(__spreadValues({}, vatRegGridState), {
      pageIndex: 0,
      appliedFilters: DEFAULT_VATREG_FILTERS
    }))}
							>
								Reset filters
							</button>
					  </div>` : ""}
				${currentTemplateType === "MPCompany" && ((_b = companyGridState.appliedFilters) == null ? void 0 : _b.length) > 0 ? html`<div>
							<button
								class="btn btn-primary"
								@click=${() => setCompanyGridState(__spreadProps(__spreadValues({}, companyGridState), {
      pageIndex: 0,
      appliedFilters: DEFAULT_COMPANY_FILTERS
    }))}
							>
								Reset filters
							</button>
					  </div>` : ""}
				<div class=${permissionManager.hasPermission("Template_Modify") ? "" : "hidden"}>
					<button
						class="btn btn-primary"
						@click=${() => {
      templateMainValidationContext.clearFieldValidations();
      setCurrentTemplateMain(__spreadProps(__spreadValues({}, DEFAULT_TEMPLATE), { Type: currentTemplateType }));
      setCurrentEstCountries([]);
      setShowTemplateMainModal(true);
    }}
					>
						New Template
					</button>
				</div>
			</div>
		</div>`;
  };
  const htmlTemplatesMain = () => {
    return html`<div>
			${currentTemplateType === "MPVatReg" ? html`<div>
						<div class="mb-4">${templateTitle("Vat Reg Templates")}</div>
						${vatRegTemplatesResult === void 0 ? html`<div>Loading...</div>` : html`${htmlButtonTmplate()}${htmlVatRegTemplatesGrid()}`}
				  </div>` : ""}
			${currentTemplateType === "MPCompany" ? html`<div class="mt-8">
						<div class="mb-4">${templateTitle("Company Templates")}</div>
						${companyTemplatesResult === void 0 ? html`<div>Loading...</div>` : html`${htmlButtonTmplate()} ${htmlCompanyTemplatesGrid()}`}
				  </div>` : ""}
		</div>`;
  };
  const htmlDataSourcePanel = () => {
    if (currentTemplate.Type === "MPVatReg") {
      const customDetails = currentTemplate.Custom;
      return html`<div class="space-y-2">
				<dc-select
					.label=${"Companies"}
					.filterable=${true}
					.debounceMs=${150}
					.selectedValues=${[currentCompany == null ? void 0 : currentCompany.Id]}
					.dataSource=${(text) => __async(this, null, function* () {
        if (text === void 0) {
          return currentCompany ? [toSelectItem(currentCompany)] : "Type at least 1 character";
        } else if (text.length < 1) {
          return "Type at least 1 character";
        } else {
          return withErrorHandling(
            () => __async(this, null, function* () {
              const result = (yield companyClient.searchCompanies({
                body: new SearchRequest({
                  Filter: getCompanyFilter(text),
                  Pager: new Pager({
                    Skip: 0,
                    Top: 900,
                    OrderBy: [
                      new Field({
                        Order: FieldOrder.Ascending,
                        OrderByField: FieldOrderByField.CompanyName
                      })
                    ]
                  })
                })
              })).data.Companies;
              if (result.length > 0) {
                setCompaniesResult(result);
                return result.map(toSelectItem);
              } else {
                return "No result found";
              }
            }),
            (error) => {
              notifyError(error);
            }
          );
        }
      })}
					@change=${(e) => {
        setCurrentCompany(companiesResult.find((company) => company.Id === Number(e.detail.selectedValue)));
      }}
				></dc-select>
				<div>Trigger: ${customDetails.Trigger}</div>
				<div>Country: ${customDetails.Country}</div>
				<div>Reg. Type: ${customDetails.RegType}</div>
				<div>Est. Countries: ${customDetails.EstablishmentCountry.join(", ")}</div>
				<div>Company Type: ${customDetails.CompanyType}</div>
				<div>
					Created: ${currentTemplateVersion.Created ? formatDate(currentTemplateVersion.Created) : "-"} ${currentTemplateVersion.CreatedBy ? ` (${currentTemplateVersion.CreatedBy})` : ""}
				</div>
				<div>
					Last updated: ${currentTemplateVersion.Updated ? formatDate(currentTemplateVersion.Updated) : "-"}
					${currentTemplateVersion.UpdatedBy ? ` (${currentTemplateVersion.UpdatedBy})` : ""}
				</div>
			</div>`;
    } else {
      return "";
    }
  };
  const htmlTemplateUploadModal = () => {
    if (showPDFTemplateUploadModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showPDFTemplateUploadModal}
						@close=${() => setShowPDFTemplateUploadModal(false)}
						.header=${"Upload new PDF template"}
						.content=${html`<div>
							<dc-fileupload
								.label=${"PDF template"}
								.fileName=${void 0}
								.fileReference=${void 0}
								.validationMessage=${void 0}
								.accept=${ONLY_PDF_ACCEPT_LIST}
								@upload=${(e) => __async(this, null, function* () {
        var _a;
        if (((_a = e.detail) == null ? void 0 : _a.files) && e.detail.files.length > 0) {
          yield uploadPDFTemplate(currentTemplateVersion, e.detail.files);
        }
      })}
							></dc-fileupload>
							<button class="btn btn-primary mt-4" @click=${() => setShowPDFTemplateUploadModal(false)}>Cancel</button>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlTemplateDetails = () => {
    var _a;
    return html`<div class="mt-8 mb-2">
				<div>
					<div class="flex justify-between">
						<div class="flex items-center">
							${templateTitle(`Template: ${currentTemplate.Name}`)}
							${permissionManager.hasPermission("Template_Modify") ? html`<span
										class="edit-icon-container ml-4"
										@click=${() => {
      setCurrentTemplateMain(currentTemplate);
      templateMainValidationContext.clearFieldValidations();
      setShowTemplateMainModal(true);
    }}
								  >
										${editIcon()}
								  </span>` : ""}
						</div>
						${templateTitle(
      currentTemplateVersion.Draft ? currentTemplateVersion.Version !== void 0 ? "Status: Draft" : "Status: -" : `Status: ${currentTemplateVersion.Disabled ? "Disabled" : "Enabled"}`
    )}
					</div>
					<div class="grid grid-cols-3 gap-4 mb-8">
						<div class="col-span-2 mb-8">${currentTemplate.Culture} | ${currentTemplate.Type}</div>
						<div class="col-span-1 text-right">${!currentTemplateVersion.Draft ? html`<div>Validity: ${getValidity()}</div>` : ""}</div>
						<div class="col-span-2">
							<ww-template-mapping
								.dataSource=${currentDataSource}
								.template=${currentTemplate}
								.templateVersion=${currentTemplateVersion}
								.onSaveMappings=${onSaveMappings}
								.onClearMapping=${onClearMapping}
							></ww-template-mapping>
						</div>
						<div>
							<div class="space-y-4">
								<div class="flex items-center">
									<dc-select
										.label=${"Version"}
										.selectedValues=${[(_a = currentTemplateVersion.Version) == null ? void 0 : _a.toString()]}
										.dataSource=${currentTemplate.Versions.map((version) => ({
      value: version.Version.toString(),
      label: `${version.Draft ? "DRAFT" : version.Version}`
    }))}
										@change=${(e) => __async(this, null, function* () {
      var _a2;
      yield setTemplateVersion(currentTemplate.Id, (_a2 = currentTemplate.Versions.find((version) => version.Version.toString() === e.detail.selectedValue)) == null ? void 0 : _a2.Version);
    })}
									></dc-select>
									${currentTemplateVersion.Draft ? html`<button
												class="btn btn-primary mt-6 ml-4"
												?disabled=${!(currentTemplateVersion == null ? void 0 : currentTemplateVersion.TemplateReference) || !permissionManager.hasPermission("Template_Modify")}
												@click=${() => __async(this, null, function* () {
      setPublishValidFrom(/* @__PURE__ */ new Date());
      setPublishValidFromValidationMessage(void 0);
      setShowPublishModal(true);
    })}
										  >
												Publish
										  </button>` : ""}
									${!currentTemplateVersion.Draft ? html`<button
												class="btn btn-primary mt-6 ml-4"
												?disabled=${!permissionManager.hasPermission("Template_Modify")}
												@click=${() => __async(this, null, function* () {
      yield cloneAsDraft(currentTemplateVersion);
    })}
										  >
												Create Draft
										  </button>` : ""}
								</div>
								<div class="flex space-x-4">
									<button
										class="btn btn-primary"
										?disabled=${!currentTemplateVersion.TemplateReference || currentCompany === void 0}
										@click=${() => {
      if (currentTemplate.Type === "MPVatReg") {
        generateVatRegPDF(currentTemplate, currentTemplateVersion, currentCompany.Id);
      } else {
        notifyError("Unknown template type (when generating PDF)");
      }
    }}
									>
										Generate PDF
									</button>
								</div>
								<div class="flex space-x-4">
									<button
										class="btn btn-primary"
										?disabled=${(currentTemplateVersion == null ? void 0 : currentTemplateVersion.TemplateReference) === void 0}
										@click=${() => __async(this, null, function* () {
      yield downloadPDFTemplate(currentTemplateVersion);
    })}
									>
										Download PDF Template
									</button>
									<button
										class="btn btn-primary"
										?disabled=${(currentTemplateVersion == null ? void 0 : currentTemplateVersion.Draft) !== true || currentTemplateVersion.Version === void 0 || !permissionManager.hasPermission("Template_Modify")}
										@click=${() => setShowPDFTemplateUploadModal(true)}
									>
										Upload PDF template
									</button>
									${htmlTemplateUploadModal()}
								</div>
								${!currentTemplateVersion.Draft ? html`<div class="flex space-x-4">
											${currentTemplateVersion.Disabled ? html`<button
														class="btn btn-primary"
														?disabled=${!permissionManager.hasPermission("Template_Modify")}
														@click=${() => __async(this, null, function* () {
      yield enableTemplateVersion(currentTemplateVersion);
    })}
												  >
														Enable
												  </button>` : ""}
											${!currentTemplateVersion.Disabled ? html`<button
														class="btn btn-primary"
														?disabled=${!permissionManager.hasPermission("Template_Modify")}
														@click=${() => __async(this, null, function* () {
      yield disableTemplateVersion(currentTemplateVersion);
    })}
												  >
														Disable
												  </button>` : ""}
											<button
												class="btn btn-primary"
												?disabled=${!currentTemplateVersion.Disabled || !permissionManager.hasPermission("Template_Modify")}
												@click=${() => __async(this, null, function* () {
      yield deleteTemplateVersion(currentTemplateVersion);
    })}
											>
												Delete
											</button>
									  </div>` : ""}
							</div>
							<div class="mt-8">${htmlDataSourcePanel()}</div>
						</div>
					</div>
				</div>
			</div>
			${htmlPublishModal()}`;
  };
  return html`<div class="my-2 mx-8 mb-16 mt-8">${currentTemplate ? htmlTemplateDetails() : currentTemplateType ? htmlTemplatesMain() : ""} ${htmlTemplateAttributesModal()}</div>`;
}
