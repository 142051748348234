import { TswagClient } from "./../commons/TswagClient";
import { SearchResult } from "./../models/V1/Company/Search/SearchResult";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { CompanyDetail } from "./../models/V1/Company/CompanyInfo/CompanyDetail";
import { Subscription } from "./../models/V1/CompanyInfo/Subscriptions/Subscription";
import { SubscriptionFactory } from "./../models/V1/CompanyInfo/Subscriptions/SubscriptionFactory";
export class Company {
  /**
   * Creates an instance of Company.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Company
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SearchCompaniesRq_ _searchCompaniesRq
   * @returns Promise<SearchCompaniesRq_>
   * @memberof Company
   */
  searchCompanies(pSearchCompaniesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/Companies/Search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchCompaniesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetCompanyDetailRq_ _getCompanyDetailRq
   * @returns Promise<GetCompanyDetailRq_>
   * @memberof Company
   */
  getCompanyDetail(pGetCompanyDetailRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/Companies/{companyId}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pGetCompanyDetailRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: CompanyDetail },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetCompanySubscriptionsRq_ _getCompanySubscriptionsRq
   * @returns Promise<GetCompanySubscriptionsRq_>
   * @memberof Company
   */
  getCompanySubscriptions(pGetCompanySubscriptionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/companies/{companyId}/subscriptions",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pGetCompanySubscriptionsRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Subscription, isArray: true, factory: SubscriptionFactory },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RejectCompanyRq_ _rejectCompanyRq
   * @returns Promise<RejectCompanyRq_>
   * @memberof Company
   */
  rejectCompany(pRejectCompanyRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/Companies/{companyId}/Reject",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pRejectCompanyRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ReopenCompanyRq_ _reopenCompanyRq
   * @returns Promise<ReopenCompanyRq_>
   * @memberof Company
   */
  reopenCompany(pReopenCompanyRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/Companies/{companyId}/ReOpen",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pReopenCompanyRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param InactivateCompanyRq_ _inactivateCompanyRq
   * @returns Promise<InactivateCompanyRq_>
   * @memberof Company
   */
  inactivateCompany(pInactivateCompanyRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/Companies/{companyId}/Inactivate",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pInactivateCompanyRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateGisNumberRq_ _updateGisNumberRq
   * @returns Promise<UpdateGisNumberRq_>
   * @memberof Company
   */
  updateGisNumber(pUpdateGisNumberRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/Companies/{companyId}/GIS/Update",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "number", value: pUpdateGisNumberRq.number }
    ]);
    client.addPathParams([
      { name: "companyId", value: pUpdateGisNumberRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateEmsNumberRq_ _updateEmsNumberRq
   * @returns Promise<UpdateEmsNumberRq_>
   * @memberof Company
   */
  updateEmsNumber(pUpdateEmsNumberRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/Companies/{companyId}/EMS/Update",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "number", value: pUpdateEmsNumberRq.number }
    ]);
    client.addPathParams([
      { name: "companyId", value: pUpdateEmsNumberRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateClientAcceptanceRq_ _updateClientAcceptanceRq
   * @returns Promise<UpdateClientAcceptanceRq_>
   * @memberof Company
   */
  updateClientAcceptance(pUpdateClientAcceptanceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/Companies/{companyId}/ClientAcceptance/Update",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateClientAcceptanceRq.companyId, required: true }
    ]);
    client.addBody({ value: pUpdateClientAcceptanceRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateSentinelInformationRq_ _updateSentinelInformationRq
   * @returns Promise<UpdateSentinelInformationRq_>
   * @memberof Company
   */
  updateSentinelInformation(pUpdateSentinelInformationRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/Companies/{companyId}/Sentinel/Update",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateSentinelInformationRq.companyId, required: true }
    ]);
    client.addBody({ value: pUpdateSentinelInformationRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateBusinessDetailRq_ _updateBusinessDetailRq
   * @returns Promise<UpdateBusinessDetailRq_>
   * @memberof Company
   */
  updateBusinessDetail(pUpdateBusinessDetailRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/Companies/{companyId}/BusinessDetail",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateBusinessDetailRq.companyId, required: true }
    ]);
    client.addBody({ value: pUpdateBusinessDetailRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param HasPendingBankTransferPaymentRq_ _hasPendingBankTransferPaymentRq
   * @returns Promise<HasPendingBankTransferPaymentRq_>
   * @memberof Company
   */
  hasPendingBankTransferPayment(pHasPendingBankTransferPaymentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/companies/{companyId}/payments/pending",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pHasPendingBankTransferPaymentRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200 },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateInvoicingRq_ _updateInvoicingRq
   * @returns Promise<UpdateInvoicingRq_>
   * @memberof Company
   */
  updateInvoicing(pUpdateInvoicingRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/companies/{companyId}/invoicing",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "isEnabled", value: pUpdateInvoicingRq.isEnabled }
    ]);
    client.addPathParams([
      { name: "companyId", value: pUpdateInvoicingRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ReValidateVatNumberRq_ _reValidateVatNumberRq
   * @returns Promise<ReValidateVatNumberRq_>
   * @memberof Company
   */
  reValidateVatNumber(pReValidateVatNumberRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/companies/{companyId}/vatnumber/revalidate",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pReValidateVatNumberRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateAdministratorRq_ _updateAdministratorRq
   * @returns Promise<UpdateAdministratorRq_>
   * @memberof Company
   */
  updateAdministrator(pUpdateAdministratorRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/{companyId}/administrators",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateAdministratorRq.companyId, required: true }
    ]);
    client.addBody({ value: pUpdateAdministratorRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CancelSubscriptionRq_ _cancelSubscriptionRq
   * @returns Promise<CancelSubscriptionRq_>
   * @memberof Company
   */
  cancelSubscription(pCancelSubscriptionRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Company/companies/{companyId}/subscriptions/{subscriptionId}",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pCancelSubscriptionRq.companyId, required: true },
      { name: "subscriptionId", value: pCancelSubscriptionRq.subscriptionId, required: true }
    ]);
    client.addBody({ value: pCancelSubscriptionRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
