import { Name } from "./../Common/Person/Name";
import { UpdateBusinessDetail } from "./UpdateBusinessDetail";
export class UpdateCompanyDetail extends UpdateBusinessDetail {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof UpdateCompanyDetail
     */
    this.$type = "V1.Company.UpdateCompanyDetail";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.CompanyName = _data["CompanyName"];
      if (_data["ContactName"]) {
        const contactName_ = new Name();
        this.ContactName = contactName_.init(_data["ContactName"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["CompanyName"] = this.CompanyName;
    _data["ContactName"] = this.ContactName ? this.ContactName.toJSON() : void 0;
    return _data;
  }
}
