var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { FileReferenceAnswer as WebFileReferenceAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { FileReferenceAnswer as AdminFileReferenceAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { FileRefValidator } from "./AnswerValidators";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { Q11E_DOC_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-fileref";
const DEFAULTS = {
  validation: {
    required: false
  }
};
export class FileRefChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  var _a, _b;
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    onUpload: host.onUpload,
    validation: host.validation !== void 0 ? __spreadValues(__spreadValues({}, DEFAULTS.validation), host.validation) : DEFAULTS.validation
  };
  const getFileDownloadLink = (reference) => {
    if (props.q11e.isAdmin) {
      return `${window.location.origin}/api/v1/Questionnaire/Company/${props.q11e.company.Id}/Files/${reference}`;
    }
    return `${window.location.origin}/api/v1/Questionnaire/Files/${reference}`;
  };
  const getFileRefAnswer = () => {
    var _a2;
    if ((_a2 = props.q11e) == null ? void 0 : _a2.isAdmin) {
      return new AdminFileReferenceAnswer({
        Value: fileReference,
        OriginalName: fileName,
        QuestionId: props.questionId
      });
    }
    return new WebFileReferenceAnswer({
      Value: fileReference,
      OriginalName: fileName,
      QuestionId: props.questionId
    });
  };
  const uploadFile = (file) => __async(void 0, null, function* () {
    var _a2;
    const refAnswer = yield props.onUpload((_a2 = props.q11e) == null ? void 0 : _a2.getUploadToQuestionUrl(props.questionId), file);
    if (refAnswer) {
      setDispatchIfValid(true);
      setFileName(refAnswer.OriginalName);
      setFileReference(refAnswer.Value);
    }
  });
  const [fileName, setFileName] = useState(void 0);
  const [fileReference, setFileReference] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new FileRefValidator(props.validation));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new FileRefChangeEvent({
          answer: getFileRefAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof AdminFileReferenceAnswer || answer instanceof WebFileReferenceAnswer) {
        setFileName(answer.OriginalName);
        setFileReference(answer.Value);
      }
    }
  }, [props.q11e, props.questionId]);
  useEffect(() => {
    if (fileName && fileReference) {
      validationContext.validate(getFileRefAnswer());
    }
  }, [fileName, fileReference]);
  return html`
		${props.q11e !== void 0 && question !== void 0 ? html`<div class=" mt-4">
					<dc-fileupload
						.readonly=${props.q11e.isReadonly(props.questionId)}
						.label=${question.Label + (props.validation.required ? "*" : "")}
						.fileName=${fileName}
						.fileReference=${fileReference}
						.tooltip=${question.Tooltip !== void 0 ? question.Tooltip : ""}
						.validationMessage=${validationContext.getValidationMessage("files")}
						.clientId=${(_b = (_a = props.q11e) == null ? void 0 : _a.company) == null ? void 0 : _b.Id}
						.accept=${Q11E_DOC_ACCEPT_LIST}
						.linkGenerator=${(reference) => getFileDownloadLink(reference)}
						@upload=${(e) => __async(void 0, null, function* () {
    var _a2;
    if (((_a2 = e.detail) == null ? void 0 : _a2.files) && e.detail.files.length > 0) {
      withErrorHandling(
        () => __async(void 0, null, function* () {
          yield uploadFile(e.detail.files[0]);
        }),
        (error) => {
          notifyError(error);
        }
      );
    }
  })}
					></dc-fileupload>
			  </div>` : ""}
	`;
};
