import { DateConverter } from "./../../../../converters/DateConverter";
import { Service } from "./Service";
export class SubscriptionService extends Service {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SubscriptionService
     */
    this.$type = "V1.Orders.Services.SubscriptionService";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.EndDate = DateConverter.from(_data["EndDate"]);
      this.StartDate = DateConverter.from(_data["StartDate"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["EndDate"] = DateConverter.toUtcDateString(this.EndDate);
    _data["StartDate"] = DateConverter.toUtcDateString(this.StartDate);
    return _data;
  }
}
