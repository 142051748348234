import { AbstractValidator } from "fluent-ts-validator";
import { EMAIL_REGEX } from "GlobalShared/common/Constants";
import { DCValidationResult } from "GlobalShared/haunted/HooksHelpers";
import { REQUIRED_MESSAGE } from "GlobalShared/helpers/stringHelper";
import i18next from "i18next";
export const getAllInvitationFields = () => {
  return ["Email", "Companies", "ClientSource"];
};
export class InvitationValidator extends AbstractValidator {
  constructor(isAdmin) {
    super();
    this.validateIfString((i) => i.email).isNotEmpty().withPropertyName("Email").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.email).matches(EMAIL_REGEX).withPropertyName("Email").withFailureMessage(i18next.t("Please provide a valid e-mail address"));
    this.validateIfString((s) => s.clientSource).isNotEmpty().withPropertyName("ClientSource").withFailureMessage(REQUIRED_MESSAGE).when((_) => isAdmin);
    this.validateIfIterable((i) => i.companies).isNotEmpty().withPropertyName("Companies").withFailureMessage(i18next.t("Please select at least one company")).when((i) => i.companies !== void 0);
  }
  validateAllFields(item) {
    return new DCValidationResult(super.validate(item));
  }
}
