import "./dc-dropdown.scss";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { component } from "haunted";
const DEFAULTS = {
  dataSource: [],
  selectedValues: void 0,
  selectedIndices: void 0,
  readonly: false,
  align: "left",
  tooltip: "",
  isStatusLike: false,
  buttonText: "",
  class: ""
};
export class ClickEvent extends CustomEvent {
  constructor(detail) {
    super("click", { detail });
  }
}
export const Component = (host) => {
  const props = {
    label: host.label,
    dataSource: host.dataSource !== void 0 ? host.dataSource : DEFAULTS.dataSource,
    validationMessage: host.validationMessage,
    selectedValues: host.selectedValues !== void 0 ? host.selectedValues : DEFAULTS.selectedValues,
    selectedIndices: host.selectedIndices !== void 0 ? host.selectedIndices : DEFAULTS.selectedIndices,
    readonly: host.readonly !== void 0 ? host.readonly : DEFAULTS.readonly,
    align: host.align !== void 0 ? host.align : DEFAULTS.align,
    tooltip: host.tooltip !== void 0 ? host.tooltip : DEFAULTS.tooltip,
    placeholder: host.placeholder !== void 0 ? host.placeholder : DEFAULTS.placeholder,
    name: host.name,
    isStatusLike: host.isStatusLike !== void 0 ? host.isStatusLike : DEFAULTS.isStatusLike,
    buttonText: host.buttonText !== void 0 ? host.buttonText : DEFAULTS.buttonText,
    class: host.class !== void 0 ? host.class : DEFAULTS.class
  };
  const getSelectedValues = () => {
    var _a;
    let tempSelectedValues;
    if (typeof props.selectedValues === "string") {
      tempSelectedValues = [props.selectedValues];
    } else if (typeof props.selectedValues === "number") {
      tempSelectedValues = [props.selectedValues.toString()];
    } else {
      tempSelectedValues = ((_a = props.selectedValues) != null ? _a : []).map((selVal) => typeof selVal === "number" ? selVal.toString() : selVal);
    }
    return tempSelectedValues;
  };
  const [dataSource, setDataSource] = useState([]);
  const [opened, setOpened] = useState(false);
  const init = () => {
    const onClickedOutside = (e) => {
      if (!host.contains(e.target)) {
        setOpened(false);
      }
    };
    const onCloseOnEscKey = (e) => {
      if (e.key === "Escape") {
        setOpened(false);
      }
    };
    document.addEventListener("keyup", onCloseOnEscKey, true);
    document.addEventListener("click", onClickedOutside, true);
    return () => {
      document.removeEventListener("keyup", onCloseOnEscKey);
      document.removeEventListener("click", onClickedOutside);
    };
  };
  useEffect(init, []);
  useEffect(() => {
    if (typeof props.dataSource !== "function" && !(props.dataSource instanceof Promise)) {
      const tempDataSource = props.dataSource.map((item) => {
        let tempItem;
        if (typeof item === "string") {
          tempItem = { label: item, value: item };
        } else if (typeof item === "number") {
          tempItem = { label: item.toString(), value: item.toString() };
        } else if (item.hasOwnProperty("label")) {
          tempItem = { label: item.label, value: item.value };
        } else {
          tempItem = item;
        }
        return tempItem;
      });
      setDataSource(tempDataSource);
    }
  }, [props.dataSource]);
  const buttonClicked = (index) => {
    setOpened(false);
    if (!getSelectedValues().includes(dataSource[index].value)) {
      host.dispatchEvent(
        new ClickEvent({
          selectedIndex: index,
          selectedValue: index !== void 0 ? dataSource[index].value : void 0
        })
      );
    }
  };
  const labelTemplate = () => props.label !== void 0 ? html` <label class="form-label"> ${props.label}${props.tooltip !== void 0 && props.tooltip.length > 0 ? html`<dc-tooltip .label=${props.tooltip}></dc-tooltip>` : ""} </label> ` : "";
  const inputTemplate = () => html`<button class="select-button" ?disabled=${props.readonly}>${props.buttonText}</button>`;
  return html`
		${labelTemplate()}
		<div
			class=${classMap({
    "dc-dropdown": true,
    "status-like": props.isStatusLike,
    "readonly": props.readonly,
    opened
  })}
			@click=${(e) => {
    e.stopPropagation();
    if (!props.readonly) {
      setOpened(!opened);
    }
  }}
		>
			${inputTemplate()} ${props.validationMessage ? html` <div class="validation-result">${props.validationMessage}</div> ` : ""}
			${opened ? html`
						<div class="select-items ${props.align === "left" ? "left-align" : "right-align"}">
							${dataSource.map(
    (item, index) => html`
									<div
										@click=${(e) => {
      e.stopPropagation();
      buttonClicked(index);
    }}
										class="item"
									>
										${item.label}
									</div>
								`
  )}
						</div>
				  ` : ""}
		</div>
	`;
};
customElements.define(
  "dc-dropdown",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
