import i18next from "i18next";
export const HUNGARIAN_VAT = 0.27;
export const COUNTRY_CODES_FOR_CALC = {
  Spain: "ES",
  Hungary: "HU",
  UnitedKingdom: "GB",
  France: "FR",
  Germany: "DE",
  Italy: "IT",
  CzechRepublic: "CZ",
  Poland: "PL"
};
export const LEGAL_STATUS = [
  { label: i18next.t("Company"), value: "Company" },
  { label: i18next.t("Partnership"), value: "Partnership" },
  { label: i18next.t("Individual"), value: "Individual" }
];
export const TITLES = [
  { label: i18next.t("Mr"), value: "Mr" },
  { label: i18next.t("Ms"), value: "Ms" }
];
