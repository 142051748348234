import { DateConverter } from "./../../../../converters/DateConverter";
export class SubmissionDeadlineConfiguration {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SubmissionDeadlineConfiguration
     */
    this.$type = "V1.SubmissionDeadlineConfigurations.Search.SubmissionDeadlineConfiguration";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientApprovalDueDate = DateConverter.from(_data["ClientApprovalDueDate"]);
      this.Deadline = DateConverter.from(_data["Deadline"]);
      this.EFiling = _data["EFiling"];
      this.EstablishmentCountryType = SubmissionDeadlineConfigurationEstablishmentCountryType[_data["EstablishmentCountryType"]];
      this.FilingExtension = _data["FilingExtension"];
      this.FrequencyType = SubmissionDeadlineConfigurationFrequencyType[_data["FrequencyType"]];
      this.IsEPayment = _data["IsEPayment"];
      this.PaymentDueDate = DateConverter.from(_data["PaymentDueDate"]);
      this.PeriodNumber = _data["PeriodNumber"];
      this.ReturnGenerationDueDate = DateConverter.from(_data["ReturnGenerationDueDate"]);
      this.ReturnType = SubmissionDeadlineConfigurationReturnType[_data["ReturnType"]];
      this.ServiceCountryCode = _data["ServiceCountryCode"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientApprovalDueDate"] = DateConverter.toUtcDateString(this.ClientApprovalDueDate);
    _data["Deadline"] = DateConverter.toUtcDateString(this.Deadline);
    _data["EFiling"] = this.EFiling;
    _data["EstablishmentCountryType"] = SubmissionDeadlineConfigurationEstablishmentCountryType[this.EstablishmentCountryType];
    _data["FilingExtension"] = this.FilingExtension;
    _data["FrequencyType"] = SubmissionDeadlineConfigurationFrequencyType[this.FrequencyType];
    _data["IsEPayment"] = this.IsEPayment;
    _data["PaymentDueDate"] = DateConverter.toUtcDateString(this.PaymentDueDate);
    _data["PeriodNumber"] = this.PeriodNumber;
    _data["ReturnGenerationDueDate"] = DateConverter.toUtcDateString(this.ReturnGenerationDueDate);
    _data["ReturnType"] = SubmissionDeadlineConfigurationReturnType[this.ReturnType];
    _data["ServiceCountryCode"] = this.ServiceCountryCode;
    return _data;
  }
}
export var SubmissionDeadlineConfigurationEstablishmentCountryType = /* @__PURE__ */ ((SubmissionDeadlineConfigurationEstablishmentCountryType2) => {
  SubmissionDeadlineConfigurationEstablishmentCountryType2["EU"] = "EU";
  SubmissionDeadlineConfigurationEstablishmentCountryType2["NonEU"] = "NonEU";
  return SubmissionDeadlineConfigurationEstablishmentCountryType2;
})(SubmissionDeadlineConfigurationEstablishmentCountryType || {});
export var SubmissionDeadlineConfigurationFrequencyType = /* @__PURE__ */ ((SubmissionDeadlineConfigurationFrequencyType2) => {
  SubmissionDeadlineConfigurationFrequencyType2["Monthly"] = "Monthly";
  SubmissionDeadlineConfigurationFrequencyType2["Quarterly"] = "Quarterly";
  SubmissionDeadlineConfigurationFrequencyType2["QuarterlyShifted1"] = "QuarterlyShifted1";
  SubmissionDeadlineConfigurationFrequencyType2["QuarterlyShifted2"] = "QuarterlyShifted2";
  SubmissionDeadlineConfigurationFrequencyType2["BiMonthly"] = "BiMonthly";
  SubmissionDeadlineConfigurationFrequencyType2["FourMonthly"] = "FourMonthly";
  SubmissionDeadlineConfigurationFrequencyType2["SixMonthly"] = "SixMonthly";
  SubmissionDeadlineConfigurationFrequencyType2["Yearly"] = "Yearly";
  return SubmissionDeadlineConfigurationFrequencyType2;
})(SubmissionDeadlineConfigurationFrequencyType || {});
export var SubmissionDeadlineConfigurationReturnType = /* @__PURE__ */ ((SubmissionDeadlineConfigurationReturnType2) => {
  SubmissionDeadlineConfigurationReturnType2["PrimaryVAT"] = "PrimaryVAT";
  SubmissionDeadlineConfigurationReturnType2["SecondaryVAT"] = "SecondaryVAT";
  SubmissionDeadlineConfigurationReturnType2["ESL"] = "ESL";
  SubmissionDeadlineConfigurationReturnType2["VLS"] = "VLS";
  SubmissionDeadlineConfigurationReturnType2["IOSS"] = "IOSS";
  SubmissionDeadlineConfigurationReturnType2["Intrastat"] = "Intrastat";
  SubmissionDeadlineConfigurationReturnType2["OSS"] = "OSS";
  return SubmissionDeadlineConfigurationReturnType2;
})(SubmissionDeadlineConfigurationReturnType || {});
