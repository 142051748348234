import { BankAccount } from "./BankAccount";
export class BankAccountConfig {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof BankAccountConfig
     */
    this.$type = "V1.CompanyInfo.Detail.BankAccountConfig";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["BankAccount"]) {
        const bankAccount_ = new BankAccount();
        this.BankAccount = bankAccount_.init(_data["BankAccount"]);
      }
      this.BankAccountRef = _data["BankAccountRef"];
      this.BankAccountUrl = _data["BankAccountUrl"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["BankAccount"] = this.BankAccount ? this.BankAccount.toJSON() : void 0;
    _data["BankAccountRef"] = this.BankAccountRef;
    _data["BankAccountUrl"] = this.BankAccountUrl;
    return _data;
  }
}
