import { Company } from "./Company";
export class User {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof User
     */
    this.$type = "V1.Users.Search.User";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Companies"] && _data["Companies"].constructor === Array) {
        this.Companies = [];
        for (let item of _data["Companies"]) {
          const companies_ = new Company();
          this.Companies.push(companies_.init(item));
        }
      }
      this.Email = _data["Email"];
      this.Id = _data["Id"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.Companies && this.Companies.constructor === Array) {
      _data["Companies"] = [];
      for (let item of this.Companies) {
        _data["Companies"].push(item.toJSON());
      }
    }
    _data["Email"] = this.Email;
    _data["Id"] = this.Id;
    return _data;
  }
}
