import { Country } from "./../Common/MasterData/Country";
import { DateConverter } from "./../../../converters/DateConverter";
export class WebRegistrationRequest {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof WebRegistrationRequest
     */
    this.$type = "V1.DataUpload.WebRegistrationRequest";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CheckOutDate = DateConverter.from(_data["CheckOutDate"]);
      this.Email = _data["Email"];
      if (_data["EstablishmentCountry"]) {
        const establishmentCountry_ = new Country();
        this.EstablishmentCountry = establishmentCountry_.init(_data["EstablishmentCountry"]);
      }
      this.MerchantId = _data["MerchantId"];
      this.PanEuropeanFbaEnabled = _data["PanEuropeanFbaEnabled"];
      this.Phone = _data["Phone"];
      this.QualtricsRecordedDate = DateConverter.from(_data["QualtricsRecordedDate"]);
      if (_data["SailEnabledCountries"] && _data["SailEnabledCountries"].constructor === Array) {
        this.SailEnabledCountries = [];
        for (let item of _data["SailEnabledCountries"]) {
          const sailEnabledCountries_ = new Country();
          this.SailEnabledCountries.push(sailEnabledCountries_.init(item));
        }
      }
      if (_data["SelectedCountries"] && _data["SelectedCountries"].constructor === Array) {
        this.SelectedCountries = [];
        for (let item of _data["SelectedCountries"]) {
          const selectedCountries_ = new Country();
          this.SelectedCountries.push(selectedCountries_.init(item));
        }
      }
      this.SignTermsDate = DateConverter.from(_data["SignTermsDate"]);
      if (_data["VatCountries"] && _data["VatCountries"].constructor === Array) {
        this.VatCountries = [];
        for (let item of _data["VatCountries"]) {
          const vatCountries_ = new Country();
          this.VatCountries.push(vatCountries_.init(item));
        }
      }
      this.WeChat = _data["WeChat"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CheckOutDate"] = DateConverter.toUtcDateString(this.CheckOutDate);
    _data["Email"] = this.Email;
    _data["EstablishmentCountry"] = this.EstablishmentCountry ? this.EstablishmentCountry.toJSON() : void 0;
    _data["MerchantId"] = this.MerchantId;
    _data["PanEuropeanFbaEnabled"] = this.PanEuropeanFbaEnabled;
    _data["Phone"] = this.Phone;
    _data["QualtricsRecordedDate"] = DateConverter.toUtcDateString(this.QualtricsRecordedDate);
    if (this.SailEnabledCountries && this.SailEnabledCountries.constructor === Array) {
      _data["SailEnabledCountries"] = [];
      for (let item of this.SailEnabledCountries) {
        _data["SailEnabledCountries"].push(item.toJSON());
      }
    }
    if (this.SelectedCountries && this.SelectedCountries.constructor === Array) {
      _data["SelectedCountries"] = [];
      for (let item of this.SelectedCountries) {
        _data["SelectedCountries"].push(item.toJSON());
      }
    }
    _data["SignTermsDate"] = DateConverter.toUtcDateString(this.SignTermsDate);
    if (this.VatCountries && this.VatCountries.constructor === Array) {
      _data["VatCountries"] = [];
      for (let item of this.VatCountries) {
        _data["VatCountries"].push(item.toJSON());
      }
    }
    _data["WeChat"] = this.WeChat;
    return _data;
  }
}
