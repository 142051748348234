import "./dc-modal.scss";
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { component } from "haunted";
const DEFAULTS = {
  dismissable: true,
  visible: false,
  header: html``,
  content: html``,
  overflowH: true,
  overflowW: true,
  showHeader: true,
  customClass: void 0
};
export const Component = (host) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const props = {
    dismissable: (_a = host.dismissable) != null ? _a : DEFAULTS.dismissable,
    visible: (_b = host.visible) != null ? _b : DEFAULTS.visible,
    header: (_c = host.header) != null ? _c : DEFAULTS.header,
    content: (_d = host.content) != null ? _d : DEFAULTS.content,
    overflowH: (_e = host.overflowH) != null ? _e : DEFAULTS.overflowH,
    overflowW: (_f = host.overflowW) != null ? _f : DEFAULTS.overflowW,
    showHeader: (_g = host.showHeader) != null ? _g : DEFAULTS.showHeader,
    customClass: (_h = host.customClass) != null ? _h : DEFAULTS.customClass
  };
  const onClose = (_e2) => {
    if (props.dismissable) {
      setIsVisible(false);
      host.dispatchEvent(new CustomEvent("close"));
    }
  };
  const [isVisible, setIsVisible] = useState(props.visible);
  useEffect(() => {
    if (props.dismissable) {
      const onCloseOnEscKey = (e) => {
        if (e.key === "Escape") {
          onClose(e);
        }
      };
      document.addEventListener("keyup", onCloseOnEscKey, true);
      return () => {
        document.removeEventListener("keyup", onCloseOnEscKey);
      };
    }
    return void 0;
  }, []);
  useEffect(() => {
    setIsVisible(props.visible);
  }, [props.visible]);
  return html`
		${isVisible ? html`
					<div class="modal-overlay" @click=${onClose}></div>
					<div class="dc-modal ${(_i = props.customClass) != null ? _i : "default"}">
						${props.showHeader ? html`
									<div class="modal-header">
										${props.header}${props.dismissable ? html`
													<svg class="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" @click=${onClose}>
														<path
															d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
														/>
													</svg>
											  ` : ""}
									</div>
							  ` : ""}
						<div class="modal-content">
							<div class="p-4 ${props.overflowH ? "modal-overflow-h" : ""} ${props.overflowW ? "modal-overflow-w" : ""}">${props.content}</div>
						</div>
					</div>
			  ` : ""}
	`;
};
customElements.define(
  "dc-modal",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
