import { LegalEntityFormAnswer as WebLegalEntityFormAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { LegalEntityFormAnswer as AdminLegalEntityFormAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { useEnumAnswer } from "./answer-enum";
import { LegalEntityFormAnswerValue } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers/LegalEntityFormAnswerAbstraction";
import i18next from "i18next";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-legalentityform";
export class LegalEntityFormChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId
  };
  const dispatch = (answer) => {
    host.dispatchEvent(
      new LegalEntityFormChangeEvent({
        answer: getAnswer(answer)
      })
    );
  };
  const getAnswer = (answer) => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminLegalEntityFormAnswer({ Value: answer.Value, QuestionId: props.questionId });
    }
    return new WebLegalEntityFormAnswer({ Value: answer.Value, QuestionId: props.questionId });
  };
  const enumHook = useEnumAnswer({
    dataSource: [
      { value: LegalEntityFormAnswerValue.LegalEntity, label: i18next.t("Legal Entity") },
      {
        value: LegalEntityFormAnswerValue.OrganizationalUnitWithoutLegalForm,
        label: i18next.t("Organizational Unit Without Legal Form")
      }
    ],
    isMultiSelect: false,
    questionId: props.questionId,
    q11e: props.q11e,
    dispatch
  });
  return enumHook.mainTemplate;
};
