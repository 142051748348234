import { AbstractValidator } from "fluent-ts-validator";
import { DCValidationResult } from "GlobalShared/haunted/HooksHelpers";
import { REQUIRED_MESSAGE } from "GlobalShared/helpers/stringHelper";
import i18next from "i18next";
export const getAllSellerEntityFields = () => {
  return ["Id", "Name", "MwsAuthToken", "IsAutomated", "IsEnabled"];
};
export class SellerEntityValidator extends AbstractValidator {
  constructor(companyConfiguration, sellerEntityEditMode) {
    super();
    this.validateIfString((s) => s.id).isNotEmpty().withPropertyName("Id").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.id).isNull().withPropertyName("Id").withFailureMessage(i18next.t("Id is taken")).when((s) => {
      var _a;
      return !sellerEntityEditMode && ((_a = companyConfiguration == null ? void 0 : companyConfiguration.amazonConfiguration) == null ? void 0 : _a.sellerEntities.some((se) => se.id === s.id));
    });
    this.validateIfString((s) => s.name).isNotEmpty().withPropertyName("Name").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.mwsAuthToken).isNotEmpty().withPropertyName("MwsAuthToken").withFailureMessage(i18next.t("Required if automated")).when((s) => s.isAutomated);
  }
  validateAllFields(item) {
    return new DCValidationResult(super.validate(item));
  }
}
