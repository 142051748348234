import { ProductTypeAnswer as WebProductTypeAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { ProductTypeAnswer as AdminProductTypeAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { useEnumAnswer } from "./answer-enum";
import { ProductTypeAnswerValue } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers/ProductTypeAnswerAbstraction";
import i18next from "i18next";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-producttype";
export class LegalEntityFormChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId
  };
  const dispatch = (answer) => {
    host.dispatchEvent(
      new LegalEntityFormChangeEvent({
        answer: getAnswer(answer)
      })
    );
  };
  const getAnswer = (answer) => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminProductTypeAnswer({ Value: answer.Value, QuestionId: props.questionId });
    }
    return new WebProductTypeAnswer({ Value: answer.Value, QuestionId: props.questionId });
  };
  const enumHook = useEnumAnswer({
    dataSource: [
      { value: ProductTypeAnswerValue.AutomotiveIndustrial, label: i18next.t("Automotive/Industrial") },
      { value: ProductTypeAnswerValue.BeautyHealth, label: i18next.t("Beauty/Health") },
      { value: ProductTypeAnswerValue.BooksAudio, label: i18next.t("Books/Audio") },
      {
        value: ProductTypeAnswerValue.CollectiblesArtStationary,
        label: i18next.t("Collectibles/Art/Stationary")
      },
      {
        value: ProductTypeAnswerValue.ElectronicsComputerOffice,
        label: i18next.t("Electronics/Computer/Office")
      },
      {
        value: ProductTypeAnswerValue.FashionAccessoriesJewellery,
        label: i18next.t("Fashion/Accessories/Jewellery")
      },
      { value: ProductTypeAnswerValue.FoodGrocery, label: i18next.t("Food/Grocery") },
      { value: ProductTypeAnswerValue.HomeGardenTools, label: i18next.t("Home/Garden/Tools") },
      { value: ProductTypeAnswerValue.KitchenDining, label: i18next.t("Kitchen/Dining") },
      { value: ProductTypeAnswerValue.MusicMoviesGames, label: i18next.t("Music/Movies/Games") },
      { value: ProductTypeAnswerValue.SportingGoodsOutdoors, label: i18next.t("Sporting goods/Outdoors") },
      { value: ProductTypeAnswerValue.ToysKidsBaby, label: i18next.t("Toys/Kids/Baby") }
    ],
    isMultiSelect: true,
    questionId: props.questionId,
    q11e: props.q11e,
    dispatch
  });
  return enumHook.mainTemplate;
};
