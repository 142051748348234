var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./ioss-registration.scss";
import { useClient, useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { notifyError, notifyErrorWithWarning, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import { html } from "lit-html";
import { formatDate } from "GlobalShared/helpers/dateHelper";
import { navigateTo } from "GlobalShared/router";
import { getRouteByKey } from "../../globals";
import { templateTitle } from "GlobalShared/templates/commons";
import { IossRegistration as IossRegistrationClient } from "AdminServices/APIs/self/clients";
import { Field, Filter, Pager, SearchRequest } from "AdminServices/APIs/self/models/V1/Company/IossRegistration/Search";
import { FieldOrder } from "AdminServices/APIs/self/models/V1/Company/IossRegistration/Search/Field";
import { getIOSSRegistrationStatusChange } from "GlobalShared/models/IossRegistrationModels";
import { useCommentModal } from "GlobalShared/components/reg-modals/comment-modal";
import { useIOSSModal } from "GlobalShared/components/reg-modals/ioss-modal";
import { FilterCompanyStatus } from "AdminServices/APIs/self/models/V1/Company/IossRegistration/Search/FilterAbstraction";
import { usePermissionManager } from "AdminComponents/hooks/AdminCustomHooks";
import { useDeregistrationModal } from "GlobalShared/components/reg-modals/deregistration-modal";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import moment from "moment";
const FieldNames = {
  ClientId: "ClientId",
  ClientName: "ClientName",
  ClientSource: "ClientSource",
  SignupDate: "SignupDate",
  RegistrationStatus: "RegistrationStatus",
  RegistrationType: "RegistrationType",
  CompanyStatus: "CompanyStatus",
  SubscriptionStatus: "SubscriptionStatus",
  SubscriptionValidTo: "SubscriptionValidTo",
  Actions: "Actions"
};
const DEFAULT_FILTERS = [];
const Statuses = [
  { label: "Pending", value: "Pending" },
  { label: "Waiting for signature", value: "WaitingForSignature" },
  { label: "Documents signed", value: "DocumentsSigned" },
  { label: "Sent to TA", value: "SentToTA" },
  { label: "Completed", value: "RegistrationCompleted" },
  { label: "Failed", value: "Failed" },
  { label: "Declined", value: "Declined" },
  { label: "Deregistration in progress", value: "DeregistrationInProgress" },
  { label: "Deregistered", value: "Deregistered" }
];
const CompanyStatuses = [
  { label: "Active", value: "Active" },
  { label: "Rejected", value: "Rejected" },
  { label: "Inactive", value: "Inactive" }
];
const RegistrationTypes = ["NewRegistration", "TakeOver"];
const ClientSources = ["Amazon", "FedEx", "KPMG", "LaPoste"];
const SubscriptionStatuses = ["Active", "Expired", "Cancelled"];
export function IossRegistrations() {
  const getStatusLabel = (status) => {
    const item = Statuses.find((s) => s.value === status);
    return item && item.label ? item.label : status;
  };
  const getFilter = () => {
    const iossRegistrationFilter = new Filter();
    if (!showAll && !gridState.appliedFilters.some((filter) => filter.field === FieldNames.CompanyStatus)) {
      iossRegistrationFilter.CompanyStatus = FilterCompanyStatus.Active;
    }
    if (serviceExpired && !gridState.appliedFilters.some((filter) => filter.field === FieldNames.SubscriptionValidTo || filter.field === FieldNames.RegistrationStatus)) {
      iossRegistrationFilter.RegistrationStatuses = ["RegistrationCompleted"];
      iossRegistrationFilter.SubscriptionValidToTo = moment().add(-1, "days").toDate();
    }
    gridState.appliedFilters.forEach((filter) => {
      if (filter.field === FieldNames.ClientId) {
        if (filter.filterProps.selectedValues) {
          iossRegistrationFilter.ClientId = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.ClientName) {
        if (filter.filterProps.selectedValues) {
          iossRegistrationFilter.ClientName = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.RegistrationStatus) {
        if (filter.filterProps.selectedValues) {
          iossRegistrationFilter.RegistrationStatuses = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.SubscriptionStatus) {
        if (filter.filterProps.selectedValues) {
          iossRegistrationFilter.SubscriptionStatus = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.RegistrationType) {
        if (filter.filterProps.selectedValues) {
          iossRegistrationFilter.RegistrationType = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.ClientSource) {
        if (filter.filterProps.selectedValues) {
          iossRegistrationFilter.ClientSource = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.SignupDate) {
        if (filter.filterProps.min || filter.filterProps.max) {
          iossRegistrationFilter.SignUpDateFrom = filter.filterProps.min;
          iossRegistrationFilter.SignUpDateTo = filter.filterProps.max;
        }
      } else if (filter.field === FieldNames.CompanyStatus) {
        if (filter.filterProps.selectedValues) {
          iossRegistrationFilter.CompanyStatus = filter.filterProps.selectedValues;
        }
      } else if (filter.field === FieldNames.SubscriptionValidTo) {
        if (filter.filterProps.min || filter.filterProps.max) {
          iossRegistrationFilter.SubscriptionValidToFrom = filter.filterProps.min;
          iossRegistrationFilter.SubscriptionValidToTo = filter.filterProps.max;
        }
      }
    });
    return iossRegistrationFilter;
  };
  const searchIossRegistrations = () => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield iossRegistrationClient.searchIossRegistrations({
          body: new SearchRequest({
            Filter: getFilter(),
            Pager: new Pager({
              Skip: gridState.pageIndex * gridState.pageSize,
              Top: gridState.pageSize,
              OrderBy: [
                new Field({
                  Order: gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: gridState.orderBy
                })
              ]
            })
          })
        });
        setSearchResult(result.data);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const getPossibleStatusTransitions = () => {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield iossRegistrationClient.getAvailableStatusChanges();
        setValidIOSSTransitions(response.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  };
  const changeStatus = (companyId, registrationId, oldStatus, newStatus, comment, iossCertificate, deregisterChange) => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const statusChange = getIOSSRegistrationStatusChange(oldStatus, newStatus, comment, iossCertificate, deregisterChange);
        yield iossRegistrationClient.changeStatus({ companyId, registrationId, body: statusChange });
        yield searchIossRegistrations();
        notifySuccess("Status changed");
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const uploadDocument = (companyId, registrationId, file) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield iossRegistrationClient.uploadDocument({
          companyId,
          registrationId,
          file
        });
        notifySuccess("Certificate Uploaded");
        return result.data;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getValidNextIossTransaction = (from) => {
    return validIOSSTransitions.reduce((aggr, curr) => {
      if (curr.From === from) {
        return aggr.concat(curr.To);
      } else {
        return aggr;
      }
    }, []);
  };
  const exportIossRegistrations = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const result = (yield iossRegistrationClient.exportIossRegistrations({
          body: new SearchRequest({
            Filter: getFilter(),
            Pager: new Pager({
              Skip: 0,
              Top: searchResult.Count,
              OrderBy: [
                new Field({
                  Order: gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: gridState.orderBy
                })
              ]
            })
          })
        })).data;
        simulateDownload(result);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const resetFilters = () => {
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex: 0, appliedFilters: DEFAULT_FILTERS }));
  };
  const iossRegistrationClient = useClient(IossRegistrationClient);
  const [gridState, setGridState] = useState({
    pageIndex: 0,
    appliedFilters: DEFAULT_FILTERS,
    pageSize: 20,
    orderBy: FieldNames.ClientName,
    orderDir: "asc"
  });
  const [showAll, setShowAll] = useState(false);
  const [serviceExpired, setServiceExpired] = useState(false);
  const [searchResult, setSearchResult] = useState(void 0);
  const [currentCommentModalProps, setCurrentCommentModalProps] = useState(void 0);
  const commentModal = useCommentModal(currentCommentModalProps);
  const [currentIOSSModalProps, setCurrentIOSSModalProps] = useState(void 0);
  const [currentDeregistrationModalProps, setCurrentDeregistrationModalProps] = useState(void 0);
  const deregistrationModal = useDeregistrationModal(currentDeregistrationModalProps);
  const iossModal = useIOSSModal(currentIOSSModalProps);
  const permissionManager = usePermissionManager();
  const init = () => {
    searchIossRegistrations();
    getPossibleStatusTransitions();
  };
  useEffect(init, []);
  useEffect(() => __async(this, null, function* () {
    searchIossRegistrations();
  }), [gridState, showAll, serviceExpired]);
  const [validIOSSTransitions, setValidIOSSTransitions] = useState([]);
  const onEditClicked = (clientId) => {
    navigateTo(getRouteByKey("clients(/:clientId)").replace(/\(\/:clientId\)/, `/${clientId}`));
  };
  const onSortChanged = (e) => {
    const orderByValue = e.detail.orderBy;
    const orderDirValue = e.detail.orderDir;
    setGridState(__spreadProps(__spreadValues({}, gridState), {
      orderBy: orderByValue,
      orderDir: orderDirValue
    }));
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridFilter);
  };
  const htmlGrid = () => {
    var _a;
    const vm = {
      columns: [
        {
          field: FieldNames.ClientId,
          columnClass: "w-24",
          label: "ID",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: FieldNames.ClientName,
          label: "Client Name",
          sortable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: FieldNames.ClientSource,
          label: "Source",
          columnType: "enum",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: ClientSources
          }
        },
        {
          field: FieldNames.SignupDate,
          label: "Signup Date",
          sortable: true,
          columnType: "date",
          filterDescriptor: { from: true, to: true, type: "date" }
        },
        {
          field: FieldNames.SubscriptionValidTo,
          label: "Exp. Date",
          sortable: true,
          columnType: "date",
          filterDescriptor: { from: true, to: true, type: "date" }
        },
        {
          field: FieldNames.SubscriptionStatus,
          label: "Sub.Status",
          columnType: "enum",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: SubscriptionStatuses
          }
        },
        {
          field: FieldNames.RegistrationType,
          label: "Registration Type",
          columnType: "enum",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: RegistrationTypes
          }
        },
        {
          field: FieldNames.RegistrationStatus,
          label: "Status",
          columnType: "enum",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            searchable: true,
            multiSelect: true,
            type: "select",
            dataSource: Statuses
          }
        },
        {
          field: FieldNames.CompanyStatus,
          label: "Company Status",
          columnType: "enum",
          sortable: true,
          filterDescriptor: {
            type: "select",
            dataSource: CompanyStatuses
          }
        }
      ],
      data: searchResult.IossRegistrations,
      sorting: {
        orderBy: gridState.orderBy,
        orderDir: gridState.orderDir
      },
      cellClass: (_index, field) => {
        if (field === FieldNames.RegistrationStatus) {
          return "actions";
        }
        return "";
      },
      cellTemplate: (index, field) => {
        var _a2;
        const item = searchResult.IossRegistrations[index];
        if (field === FieldNames.ClientId) {
          return item.ClientId.toString();
        } else if (field === FieldNames.ClientName) {
          return html`<a href="" @click=${() => onEditClicked(item.ClientId)}>${item.ClientName.toString()}</a>`;
        } else if (field === FieldNames.SignupDate) {
          return formatDate(item.SignupDate);
        } else if (field === FieldNames.SubscriptionValidTo) {
          return item.SubscriptionValidTo ? formatDate(item.SubscriptionValidTo) : "";
        } else if (field === FieldNames.ClientSource) {
          return item.ClientSource;
        } else if (field === FieldNames.SubscriptionStatus) {
          return (_a2 = item.SubscriptionStatus) != null ? _a2 : "";
        } else if (field === FieldNames.RegistrationType) {
          return item.RegistrationType;
        } else if (field === FieldNames.CompanyStatus) {
          return item.CompanyStatus;
        } else if (field === FieldNames.RegistrationStatus) {
          return html`<div class="w-full">
						<dc-dropdown
							.isStatusLike=${true}
							.buttonText=${getStatusLabel(item.Status)}
							.selectedValues=${[item.Status]}
							.dataSource=${getValidNextIossTransaction(item.Status).map((status) => ({
            label: getStatusLabel(status),
            value: status
          }))}
							.readonly=${!permissionManager.hasPermission("IOSS_Modify")}
							@click=${(e) => {
            const newIOSSStatus = e.detail.selectedValue;
            if (item.Status === "DocumentsSigned" && newIOSSStatus === "WaitingForSignature") {
              setCurrentCommentModalProps({
                save: (comment) => __async(this, null, function* () {
                  yield changeStatus(item.ClientId, item.Id, item.Status, newIOSSStatus, comment);
                })
              });
              commentModal.show();
            } else if (item.Status === "SentToTA" && newIOSSStatus === "RegistrationCompleted") {
              setCurrentIOSSModalProps({
                iossCertificate: {},
                save: (iossCertificate) => __async(this, null, function* () {
                  yield changeStatus(item.ClientId, item.Id, item.Status, newIOSSStatus, void 0, iossCertificate);
                }),
                uploadIOSSCertificate: (file) => {
                  return uploadDocument(item.ClientId, item.Id, file);
                }
              });
              iossModal.show();
            } else if (item.Status === "DeregistrationInProgress" && newIOSSStatus === "Deregistered") {
              setCurrentDeregistrationModalProps({
                change: {},
                save: (change) => __async(this, null, function* () {
                  yield changeStatus(item.ClientId, item.Id, item.Status, newIOSSStatus, void 0, void 0, change);
                }),
                uploadDocument: (file) => {
                  return uploadDocument(item.ClientId, item.Id, file);
                }
              });
              deregistrationModal.show();
            } else {
              changeStatus(item.ClientId, item.Id, item.Status, newIOSSStatus);
            }
          }}
						></dc-dropdown>
					</div>`;
        } else {
          return "";
        }
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: searchResult.Count
      }
    };
    return html`<div class="mx-8 xl:mx-auto">
			<div class="mb-4">
				<div>${templateTitle("IOSS Registrations")}</div>
				<div class="flex space-x-4 my-4">
					<button class="btn btn-primary whitespace-no-wrap" @click=${() => __async(this, null, function* () {
      return exportIossRegistrations();
    })}>Export</button>
					<dc-checkbox2
						.checked=${showAll}
						.label=${"Show All"}
						@change=${(e) => {
      setShowAll(e.detail.checked);
    }}
					></dc-checkbox2>
					<dc-checkbox2
						.checked=${serviceExpired}
						.label=${"Service Expired"}
						.labelnowrap=${true}
						@change=${(e) => {
      setServiceExpired(e.detail.checked);
    }}
					></dc-checkbox2>
					${((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html`<div>
								<button class="btn btn-primary whitespace-no-wrap" @click=${() => resetFilters()}>Reset filters</button>
						  </div>` : ""}
				</div>
			</div>
			<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>
		</div>`;
  };
  return html`<div class="my-2 mx-8 mb-16 mt-8">${searchResult === void 0 ? "Loading..." : htmlGrid()} ${commentModal.template()} ${iossModal.template} ${deregistrationModal.template}</div>`;
}
