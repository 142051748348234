var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { templateTitle } from "GlobalShared/templates/commons";
import { html } from "lit-html";
import { usePermissionManager, useWorldCountries } from "AdminComponents/hooks/AdminCustomHooks";
import { useClient, useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import { VATRateTypeSettings as VATRateTypeSettingsClient } from "AdminServices/APIs/self/clients";
import { CreateTypeAndRate } from "AdminServices/APIs/self/models/V1/VatRateTypeSettings/CreateTypeAndRate";
import { notifyErrorWithWarning, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { mapVatRateType, VatRateTypeFieldNames } from "./VatRateTypeModels";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import { formatDate } from "GlobalShared/helpers/dateHelper";
import { SearchRequest, Pager, Field, Filter } from "AdminServices/APIs/self/models/V1/VatRateTypeSettings/Search";
import { FieldOrder } from "AdminServices/APIs/self/models/V1/VatRateTypeSettings/Search/Field";
import { DateRange } from "AdminServices/APIs/self/models/V1/Common/DateTime";
import { AmountRange } from "AdminServices/APIs/self/models/V1/Primitives";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import { useAwesomeValidator, Validation } from "GlobalShared/validation/AwesomeValidator";
const VAT_RATE_TYPE_LABELS = [
  { value: "Exempt", label: "Exempt" },
  { value: "Middle", label: "Middle" },
  { value: "Reduced", label: "Reduced" },
  { value: "Reduced2", label: "Reduced2" },
  { value: "Standard", label: "Standard" },
  { value: "Standard2", label: "Standard2" },
  { value: "SuperReduced", label: "SuperReduced" },
  { value: "SuperReduced2", label: "SuperReduced2" },
  { value: "ZeroRated", label: "ZeroRated" }
];
const DEFAULT_VAT_RATE_TYPE = {
  country: void 0,
  vatRateType: void 0,
  rateValue: void 0,
  validFrom: void 0
};
const DEFAULT_GRID_STATE = {
  pageIndex: 0,
  appliedFilters: [],
  pageSize: 20,
  orderBy: VatRateTypeFieldNames.Country,
  orderDir: "asc"
};
export function VatRateTypes() {
  const addNewVatRateType = () => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield vatRateTypeSettingsClient.createVATRate({
          countryCode: currentNewVatRateType.country.Code,
          body: new CreateTypeAndRate({
            Rate: currentNewVatRateType.rateValue,
            Type: currentNewVatRateType.vatRateType,
            ValidFrom: currentNewVatRateType.validFrom
          })
        });
        notifySuccess("VAT Rate Type saved successfully");
        setCurrentNewVatRateType(DEFAULT_VAT_RATE_TYPE);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const getFilter = (gridState2) => {
    const vatRateTypeFilter = new Filter();
    if (showChangeHistory === false) {
      vatRateTypeFilter.LatestOnly = true;
    }
    if (gridState2.appliedFilters.length > 0) {
      gridState2.appliedFilters.forEach((filter) => {
        if (filter.field === VatRateTypeFieldNames.Country) {
          if (filter.filterProps.selectedValues) {
            vatRateTypeFilter.CountryCode = filter.filterProps.selectedValues;
          }
        } else if (filter.field === VatRateTypeFieldNames.RateType) {
          if (filter.filterProps.selectedValues) {
            vatRateTypeFilter.Type = filter.filterProps.selectedValues;
          }
        } else if (filter.field === VatRateTypeFieldNames.RateValue) {
          if (filter.filterProps.min || filter.filterProps.max) {
            vatRateTypeFilter.RateRange = new AmountRange({
              From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
              To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
            });
          }
        } else if (filter.field === VatRateTypeFieldNames.ValidFrom) {
          if (filter.filterProps.min || filter.filterProps.max) {
            vatRateTypeFilter.ValidFromRange = new DateRange({
              From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
              To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
            });
          }
        }
      });
    }
    return vatRateTypeFilter;
  };
  const search = () => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        const searchResult2 = yield vatRateTypeSettingsClient.search({
          body: new SearchRequest({
            Filter: getFilter(gridState),
            Pager: new Pager({
              Skip: gridState.pageIndex * gridState.pageSize,
              Top: gridState.pageSize,
              OrderBy: [
                new Field({
                  Order: gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: gridState.orderBy
                })
              ]
            })
          })
        });
        setSearchResult({
          total: searchResult2.data.TotalCount,
          vatRateTypes: searchResult2.data.VatRateTypeSettings.map(mapVatRateType)
        });
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const exportVatRateTypes = () => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield vatRateTypeSettingsClient.export({
          body: new SearchRequest({
            Filter: getFilter(gridState),
            Pager: new Pager({
              Skip: gridState.pageIndex * gridState.pageSize,
              Top: gridState.pageSize,
              OrderBy: [
                new Field({
                  Order: gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: gridState.orderBy
                })
              ]
            })
          })
        });
        simulateDownload(response.data);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const onSortChanged = (e) => {
    const orderByValue = e.detail.orderBy;
    const orderDirValue = e.detail.orderDir;
    setGridState(__spreadProps(__spreadValues({}, gridState), {
      orderBy: orderByValue,
      orderDir: orderDirValue
    }));
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridFilter);
  };
  const vatRateTypeSettingsClient = useClient(VATRateTypeSettingsClient);
  const countries = useWorldCountries("en-GB");
  const permissionManager = usePermissionManager();
  const [currentNewVatRateType, setCurrentNewVatRateType] = useState(DEFAULT_VAT_RATE_TYPE);
  const newVatRateTypeValidator = useAwesomeValidator(
    [
      Validation.ruleFor(VatRateTypeFieldNames.Country, (vm) => vm.country).isRequired(),
      Validation.ruleFor(VatRateTypeFieldNames.ValidFrom, (vm) => vm.validFrom).isRequired(),
      Validation.ruleFor(VatRateTypeFieldNames.RateValue, (vm) => vm.rateValue).isNumber().min(0).max(100).isRequired(),
      Validation.ruleFor(VatRateTypeFieldNames.RateType, (vm) => vm.vatRateType).isRequired()
    ],
    () => addNewVatRateType()
  );
  const [gridState, setGridState] = useState(DEFAULT_GRID_STATE);
  const [searchResult, setSearchResult] = useState(void 0);
  const [showChangeHistory, setShowChangeHistory] = useState(false);
  const init = () => {
    search();
  };
  useEffect(init, []);
  useEffect(() => {
    search();
  }, [gridState, showChangeHistory]);
  const htmlAddVatRateType = () => {
    var _a;
    return html`
			<div class="space-y-4">
				<div>${templateTitle("Set New VAT Rate Type")}</div>
				<div class="flex space-x-6 items-center">
					<dc-select
						.label=${"Country"}
						.filterable=${true}
						.selectedValues=${(_a = currentNewVatRateType.country) == null ? void 0 : _a.Code}
						.dataSource=${countries.map((country) => ({
      label: country.Name,
      value: country.Code
    }))}
						.validationMessage=${newVatRateTypeValidator.getMessage(VatRateTypeFieldNames.Country)}
						@change=${(e) => {
      const country = countries.find((c) => c.Code === e.detail.selectedValue);
      setCurrentNewVatRateType(__spreadProps(__spreadValues({}, currentNewVatRateType), { country }));
    }}
					></dc-select>
					<dc-select
						.label=${"VAT Rate Type"}
						.selectedValues=${currentNewVatRateType.vatRateType}
						.dataSource=${VAT_RATE_TYPE_LABELS}
						.validationMessage=${newVatRateTypeValidator.getMessage(VatRateTypeFieldNames.RateType)}
						@change=${(e) => {
      setCurrentNewVatRateType(__spreadProps(__spreadValues({}, currentNewVatRateType), {
        vatRateType: e.detail.selectedValue
      }));
    }}
					></dc-select>
					<dc-input
						.name=${"Rate Value"}
						.label=${"Rate Value"}
						.tooltip=${"Enter a number between 0 and 100. For example, 0 means 0%, 4.5 means 4.5%, and 100 means 100%."}
						.value=${currentNewVatRateType.rateValue}
						.validationMessage=${newVatRateTypeValidator.getMessage(VatRateTypeFieldNames.RateValue)}
						@change=${(e) => {
      const tempVatRateType = toSafeNumber(e.detail.value);
      setCurrentNewVatRateType(__spreadProps(__spreadValues({}, currentNewVatRateType), { rateValue: tempVatRateType }));
    }}
					></dc-input>
					<dc-datepicker
						.label=${"Valid From Date"}
						.value=${currentNewVatRateType.validFrom}
						.validationMessage=${newVatRateTypeValidator.getMessage(VatRateTypeFieldNames.ValidFrom)}
						@change=${(e) => {
      setCurrentNewVatRateType(__spreadProps(__spreadValues({}, currentNewVatRateType), { validFrom: e.detail.value }));
    }}
					></dc-datepicker>
				</div>
				<button class="btn btn-primary" @click=${() => newVatRateTypeValidator.validateAndDispatch(currentNewVatRateType)}>Save</button>
			</div>
		`;
  };
  const htmlVatRateTypesGrid = () => {
    var _a;
    const vm = {
      columns: [
        {
          field: VatRateTypeFieldNames.Country,
          label: "Country",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: countries.map((sc) => ({ label: sc.Name, value: sc.Code })),
            searchable: true,
            searchProps: { type: "includes" }
          },
          columnType: "enum"
        },
        {
          field: VatRateTypeFieldNames.RateType,
          label: "Rate Type",
          columnType: "enum",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: VAT_RATE_TYPE_LABELS
          }
        },
        {
          field: VatRateTypeFieldNames.RateValue,
          label: "Rate Value",
          columnType: "number",
          sortable: true,
          filterable: true,
          filterDescriptor: { from: true, to: true, type: "number" }
        },
        {
          field: VatRateTypeFieldNames.ValidFrom,
          label: "Valid From Date",
          columnType: "date",
          sortable: true,
          filterable: true,
          filterDescriptor: { from: true, to: true, type: "date", rightAlign: true }
        }
      ],
      data: searchResult == null ? void 0 : searchResult.vatRateTypes,
      sorting: {
        orderBy: gridState.orderBy,
        orderDir: gridState.orderDir
      },
      cellTemplate: (index, field) => {
        const item = searchResult == null ? void 0 : searchResult.vatRateTypes[index];
        if (field === VatRateTypeFieldNames.Country) {
          return item.country;
        } else if (field === VatRateTypeFieldNames.RateType) {
          return item.vatRateTypeType;
        } else if (field === VatRateTypeFieldNames.RateValue) {
          return item.rate.toFixed(2);
        } else if (field === VatRateTypeFieldNames.ValidFrom) {
          return formatDate(item.validFrom);
        } else {
          return "";
        }
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: searchResult == null ? void 0 : searchResult.total
      }
    };
    return html` <div class="space-y-4">
			<div>${templateTitle("VAT Rate Type Settings")}</div>
			<div class="flex space-x-2">
				<button class="btn btn-primary" @click=${() => exportVatRateTypes()}>Export</button>
				<dc-checkbox2
					.checked=${showChangeHistory}
					.label=${"Show change history"}
					.labelnowrap=${true}
					@change=${(e) => {
      setShowChangeHistory(e.detail.checked);
    }}
				></dc-checkbox2>
				${((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html`
							<button
								class="btn btn-primary"
								@click=${() => {
      setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex: 0, appliedFilters: [] }));
      setShowChangeHistory(false);
    }}
							>
								Reset filters
							</button>
					  ` : ""}
			</div>
			<div>
				<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>
			</div>
		</div>`;
  };
  return html` <div class="mt-8 mx-8 space-y-10">${permissionManager.hasPermission("VAT_Rates_Modify") ? htmlAddVatRateType() : ""} ${htmlVatRateTypesGrid()}</div>`;
}
