export class VatRegTemplateSearchContent {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRegTemplateSearchContent
     */
    this.$type = "V1.TemplateMapping.TemplateSearch.VatRegTemplateSearchContent";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Category = VatRegTemplateSearchContentCategory[_data["Category"]];
      this.CompanyType = VatRegTemplateSearchContentCompanyType[_data["CompanyType"]];
      this.Country = _data["Country"];
      this.Culture = _data["Culture"];
      if (_data["EstablishmentCountries"] && _data["EstablishmentCountries"].constructor === Array) {
        this.EstablishmentCountries = [];
        for (let item of _data["EstablishmentCountries"]) {
          this.EstablishmentCountries.push(item);
        }
      }
      this.Id = _data["Id"];
      this.Name = _data["Name"];
      this.RegistrationType = VatRegTemplateSearchContentRegistrationType[_data["RegistrationType"]];
      this.TemplateStatus = VatRegTemplateSearchContentTemplateStatus[_data["TemplateStatus"]];
      this.Trigger = VatRegTemplateSearchContentTrigger[_data["Trigger"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Category"] = VatRegTemplateSearchContentCategory[this.Category];
    _data["CompanyType"] = VatRegTemplateSearchContentCompanyType[this.CompanyType];
    _data["Country"] = this.Country;
    _data["Culture"] = this.Culture;
    if (this.EstablishmentCountries && this.EstablishmentCountries.constructor === Array) {
      _data["EstablishmentCountries"] = [];
      for (let item of this.EstablishmentCountries) {
        _data["EstablishmentCountries"].push(item);
      }
    }
    _data["Id"] = this.Id;
    _data["Name"] = this.Name;
    _data["RegistrationType"] = VatRegTemplateSearchContentRegistrationType[this.RegistrationType];
    _data["TemplateStatus"] = VatRegTemplateSearchContentTemplateStatus[this.TemplateStatus];
    _data["Trigger"] = VatRegTemplateSearchContentTrigger[this.Trigger];
    return _data;
  }
}
export var VatRegTemplateSearchContentCategory = /* @__PURE__ */ ((VatRegTemplateSearchContentCategory2) => {
  VatRegTemplateSearchContentCategory2["RequiredElectronicallyOnly"] = "RequiredElectronicallyOnly";
  VatRegTemplateSearchContentCategory2["RequiredByPost"] = "RequiredByPost";
  VatRegTemplateSearchContentCategory2["ForInformationOnly"] = "ForInformationOnly";
  return VatRegTemplateSearchContentCategory2;
})(VatRegTemplateSearchContentCategory || {});
export var VatRegTemplateSearchContentCompanyType = /* @__PURE__ */ ((VatRegTemplateSearchContentCompanyType2) => {
  VatRegTemplateSearchContentCompanyType2["Individual"] = "Individual";
  VatRegTemplateSearchContentCompanyType2["Company"] = "Company";
  return VatRegTemplateSearchContentCompanyType2;
})(VatRegTemplateSearchContentCompanyType || {});
export var VatRegTemplateSearchContentRegistrationType = /* @__PURE__ */ ((VatRegTemplateSearchContentRegistrationType2) => {
  VatRegTemplateSearchContentRegistrationType2["NewReg"] = "NewReg";
  VatRegTemplateSearchContentRegistrationType2["TakeOver"] = "TakeOver";
  return VatRegTemplateSearchContentRegistrationType2;
})(VatRegTemplateSearchContentRegistrationType || {});
export var VatRegTemplateSearchContentTemplateStatus = /* @__PURE__ */ ((VatRegTemplateSearchContentTemplateStatus2) => {
  VatRegTemplateSearchContentTemplateStatus2["Draft"] = "Draft";
  VatRegTemplateSearchContentTemplateStatus2["Enabled"] = "Enabled";
  VatRegTemplateSearchContentTemplateStatus2["Disabled"] = "Disabled";
  return VatRegTemplateSearchContentTemplateStatus2;
})(VatRegTemplateSearchContentTemplateStatus || {});
export var VatRegTemplateSearchContentTrigger = /* @__PURE__ */ ((VatRegTemplateSearchContentTrigger2) => {
  VatRegTemplateSearchContentTrigger2["Q11EApproved"] = "Q11EApproved";
  VatRegTemplateSearchContentTrigger2["Q11ECompleted"] = "Q11ECompleted";
  VatRegTemplateSearchContentTrigger2["Q11EIncomplete"] = "Q11EIncomplete";
  VatRegTemplateSearchContentTrigger2["Q11EVerified"] = "Q11EVerified";
  VatRegTemplateSearchContentTrigger2["CompanyCreated"] = "CompanyCreated";
  VatRegTemplateSearchContentTrigger2["CompanyRejected"] = "CompanyRejected";
  VatRegTemplateSearchContentTrigger2["VatRegPending"] = "VatRegPending";
  VatRegTemplateSearchContentTrigger2["VatRegWaitingForSignature"] = "VatRegWaitingForSignature";
  VatRegTemplateSearchContentTrigger2["VatRegDocumentsSigned"] = "VatRegDocumentsSigned";
  VatRegTemplateSearchContentTrigger2["VatRegTranslationInProgress"] = "VatRegTranslationInProgress";
  VatRegTemplateSearchContentTrigger2["VatRegSentToTA"] = "VatRegSentToTA";
  VatRegTemplateSearchContentTrigger2["VatRegLocalTaxReceived"] = "VatRegLocalTaxReceived";
  VatRegTemplateSearchContentTrigger2["VatRegRegistrationCompleted"] = "VatRegRegistrationCompleted";
  return VatRegTemplateSearchContentTrigger2;
})(VatRegTemplateSearchContentTrigger || {});
