var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { html } from "lit-html";
import { useEffect, useMemo, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { VatCountryServiceValidator } from "./OrderServiceValidator";
import { mapBooleanToState } from "GlobalShared/helpers/booleanHelper";
import { formatDate, getDay } from "GlobalShared/helpers/dateHelper";
import { hasComplianceExpired } from "./order-services-utils";
import { default as moment } from "moment";
import { canSetIntrastatStartDate } from "./CreateServiceModels";
export const observedAttributes = [];
export const useShadowDOM = false;
export const name = "order-vat-country-service";
export class VatCountryChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail });
  }
}
export const Component = (host) => {
  const props = {
    isAdmin: host.isAdmin,
    isSignup: host.isSignup,
    vatCountry: host.vatCountry,
    priceConfig: host.priceConfig,
    label: host.label,
    currentCompanyServices: host.currentCompanyServices
  };
  const hasAdditionalServices = () => {
    return currentCountryPriceConfig.intrastatIn || currentCountryPriceConfig.intrastatOut || currentCountryPriceConfig.spanishBankAccount || currentCountryPriceConfig.deregistration || currentCountryPriceConfig.selfRevision && props.isAdmin || currentCountryPriceConfig.retro && props.isAdmin;
  };
  const canDeregister = () => {
    var _a, _b;
    const status = (_b = (_a = props.currentCompanyServices.VatRegistrations) == null ? void 0 : _a.find((f) => f.ServiceCountry.Code === vatCountry.code)) == null ? void 0 : _b.Status;
    return status === "RegistrationCompleted" || status === "LocalTaxReceived";
  };
  const isDeregisteredOrPoARevoked = () => {
    var _a, _b, _c;
    const status = (_c = (_b = (_a = props.currentCompanyServices) == null ? void 0 : _a.VatRegistrations) == null ? void 0 : _b.find((f) => f.ServiceCountry.Code === vatCountry.code)) == null ? void 0 : _c.Status;
    return status === "Deregistered" || status === "PoARevoked";
  };
  const [vatCountry, setVatCountry] = useState(props.vatCountry);
  const validationContext = useValidationContext(new VatCountryServiceValidator(props.priceConfig));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  const currentCountryPriceConfig = useMemo(
    () => props.vatCountry && props.priceConfig ? props.priceConfig.perCountry[props.vatCountry.code] : void 0,
    [props.priceConfig.perCountry, props.vatCountry.code]
  );
  useEffect(() => {
    if (props.vatCountry) {
      setVatCountry(props.vatCountry);
    }
  }, [props.vatCountry]);
  useEffect(() => {
    if (vatCountry && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new VatCountryChangeEvent({
          vatCountry
        })
      );
    }
  }, [vatCountry]);
  useEffect(() => {
    validationContext.validate(vatCountry);
  }, [vatCountry]);
  const htmlRegistration = () => {
    return html`<div class="flex justify-between">
			<div>
				<div class="form-label">${i18next.t("Do you have a VAT Number?")}</div>
				<dc-toggle
					.state=${mapBooleanToState(vatCountry == null ? void 0 : vatCountry.hasVatNumber)}
					.validationMessage=${validationContext.getValidationMessage("hasVatNumber")}
					@change=${(e) => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), { hasVatNumber: e.detail.state === "Yes" }));
      setDispatchIfValid(true);
      validationContext.validateField("hasVatNumber");
    }}
				></dc-toggle>
			</div>
			<div class="${vatCountry.hasVatNumber !== void 0 ? "" : "hidden"}">
				<div class="flex justify-end">
					<dc-datepicker
						.label=${vatCountry.hasVatNumber ? i18next.t("Start date of representation by KPMG") : i18next.t("Effective date of registration")}
						.tooltip=${vatCountry.hasVatNumber ? i18next.t("Select the start date from when you want KPMG to submit your VAT returns.") : i18next.t(
      "if-you-had-taxable-supplies",
      "If you had taxable supplies in the past, select the date when the first taxable supply was made. If you do not have a registration obligation for the past we recommend you select a future date from which you would like your VAT ID to be valid (e.g. the first month of the next quarter)."
    )}
						.value=${vatCountry.vatStartingDate}
						.validationMessage=${validationContext.getValidationMessage("vatStartingDate")}
						@change=${(e) => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), { vatStartingDate: e.detail.value }));
      setDispatchIfValid(true);
      validationContext.validateField("vatStartingDate");
    }}
					></dc-datepicker>
				</div>
			</div>
		</div>`;
  };
  const htmlIntrastatIn = () => {
    return html`<div class="lg:flex lg:justify-between space-x-4">
			<div
				class="btn-toggle mt-6 mb-1 xl:w-1/2 ${vatCountry.intrastatIn ? "selected" : ""}"
				.validationMessage=${validationContext.getValidationMessage("intrastatIn")}
				@click=${() => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), { intrastatIn: !vatCountry.intrastatIn }));
      setDispatchIfValid(true);
      validationContext.validateField("intrastatIn");
    }}
			>
				<div class="flex justify-between">
					<div class="mr-2">${i18next.t("Intrastat Report In")}</div>
					<div>
						<dc-tooltip
							.label=${i18next.t(
      "Intrastat is a statistical report that records the trade volume of goods between members of the EU and businesses within them. Intrastat Report \u201CIn\u201D must be submitted only when reaching a certain amount of arrivals of goods in that certain country. Check out our Guides and Tutorials for more information about the Intrastat reporting thresholds."
    )}
						></dc-tooltip>
					</div>
				</div>
			</div>
			<div
				class="${canSetIntrastatStartDate(vatCountry.intrastatIn, vatCountry.origComplianceStartDate, vatCountry.origComplianceEndDate, vatCountry.origIntrastatInEndDate) ? "block" : "hidden"} "
			>
				<dc-datepicker
					.label=${i18next.t("Starting date of intrastat in")}
					.value=${vatCountry.intrastatInDate}
					.validationMessage=${validationContext.getValidationMessage("intrastatInDate")}
					@change=${(e) => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), { intrastatInDate: e.detail.value }));
      setDispatchIfValid(true);
      validationContext.validateField("intrastatInDate");
    }}
				></dc-datepicker>
			</div>
		</div>`;
  };
  const htmlIntrastatOut = () => {
    return html`<div class="lg:flex lg:justify-between space-x-4">
			<div
				class="btn-toggle mt-6 mb-1 xl:w-1/2 ${vatCountry.intrastatOut ? "selected" : ""}"
				.validationMessage=${validationContext.getValidationMessage("intrastatOut")}
				@click=${() => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), { intrastatOut: !vatCountry.intrastatOut }));
      setDispatchIfValid(true);
      validationContext.validateField("intrastatOut");
    }}
			>
				<div class="flex justify-between">
					<div class="mr-2">${i18next.t("Intrastat Report Out")}</div>
					<div>
						<dc-tooltip
							.label=${i18next.t(
      'Intrastat is a statistical report that records the trade volume of goods between members of the EU and businesses within them. Intrastat Report "Out" must be submitted only when reaching a certain amount of dispatches of goods in that certain country. Check out our Guides and Tutorials for more information about the Intrastat reporting thresholds.'
    )}
						></dc-tooltip>
					</div>
				</div>
			</div>
			<div
				class="${canSetIntrastatStartDate(vatCountry.intrastatOut, vatCountry.origComplianceStartDate, vatCountry.origComplianceEndDate, vatCountry.origIntrastatOutEndDate) ? "block" : "hidden"} "
			>
				<dc-datepicker
					.label=${i18next.t("Starting date of intrastat out")}
					.value=${vatCountry.intrastatOutDate}
					.validationMessage=${validationContext.getValidationMessage("intrastatOutDate")}
					@change=${(e) => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), { intrastatOutDate: e.detail.value }));
      setDispatchIfValid(true);
      validationContext.validateField("intrastatOutDate");
    }}
				></dc-datepicker>
			</div>
		</div>`;
  };
  const htmlSpanishBankAccount = () => {
    return html`<div class="lg:flex lg:justify-between mt-4 mb-4">
			<div>
				<div class="form-label">${i18next.t("Do you have a Spanish bank account?")}</div>
				<dc-toggle
					.validationMessage=${validationContext.getValidationMessage("spanishBankAccount")}
					.state=${mapBooleanToState(vatCountry.spanishBankAccount)}
					@change=${(e) => {
      validationContext.validateField("spanishBankAccount");
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), {
        spanishBankAccount: e.detail.state === "Yes"
      }));
      setDispatchIfValid(true);
    }}
				></dc-toggle>
			</div>
		</div>`;
  };
  const htmlDeregistration = () => {
    return html`<div class="lg:flex lg:justify-between">
			<div
				class="btn-toggle mt-6 mb-1 xl:w-1/2 ${(vatCountry == null ? void 0 : vatCountry.deregistration) ? "selected" : ""}"
				@click=${() => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), {
        deregistration: !(vatCountry == null ? void 0 : vatCountry.deregistration)
      }));
      setDispatchIfValid(true);
    }}
			>
				<div class="flex justify-between">
					<div class="mr-2">${i18next.t("Deregistration")}</div>
				</div>
			</div>
		</div>`;
  };
  const htmlSelfRevision = () => {
    return html`<div class="lg:flex lg:justify-between space-x-4">
			<div
				class="btn-toggle mt-6 mb-1 xl:w-1/2 ${vatCountry.selfRevision ? "selected" : ""}"
				.validationMessage=${validationContext.getValidationMessage("selfRevision")}
				@click=${() => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), { selfRevision: !vatCountry.selfRevision }));
      setDispatchIfValid(true);
      validationContext.validateField("selfRevision");
    }}
			>
				<div class="flex justify-between">
					<div class="mr-2">${i18next.t("Self Revision / Retrospective Returns")}</div>
				</div>
			</div>
			<div class="${vatCountry.selfRevision ? "block" : "hidden"} ">
				<dc-datepicker
					.label=${i18next.t("Start Date")}
					.value=${vatCountry.selfRevisionStartDate}
					.validationMessage=${validationContext.getValidationMessage("selfRevisionStartDate")}
					@change=${(e) => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), { selfRevisionStartDate: e.detail.value }));
      setDispatchIfValid(true);
      validationContext.validateField("selfRevisionStartDate");
    }}
				></dc-datepicker>
			</div>
			<div class="${vatCountry.selfRevision ? "block" : "hidden"} ">
				<dc-datepicker
					.label=${i18next.t("End Date")}
					.value=${vatCountry.selfRevisionEndDate}
					.validationMessage=${validationContext.getValidationMessage("selfRevisionEndDate")}
					@change=${(e) => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), { selfRevisionEndDate: e.detail.value }));
      setDispatchIfValid(true);
      validationContext.validateField("selfRevisionEndDate");
    }}
				></dc-datepicker>
			</div>
		</div>`;
  };
  const htmlRetrospective = () => {
    return html`<div class="lg:flex lg:justify-between space-x-4">
			<div
				class="btn-toggle mt-6 mb-1 xl:w-1/2 ${vatCountry.retrospective ? "selected" : ""}"
				.validationMessage=${validationContext.getValidationMessage("retrospective")}
				@click=${() => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), { retrospective: !vatCountry.retrospective }));
      setDispatchIfValid(true);
      validationContext.validateField("retrospective");
    }}
			>
				<div class="flex justify-between">
					<div class="mr-2">${i18next.t("Retrospective")}</div>
				</div>
			</div>
			<div class="${vatCountry.retrospective ? "block" : "hidden"} ">
				<dc-datepicker
					.label=${i18next.t("Start Date")}
					.value=${vatCountry.retrospectiveStartDate}
					.validationMessage=${validationContext.getValidationMessage("retrospectiveStartDate")}
					@change=${(e) => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), { retrospectiveStartDate: e.detail.value }));
      setDispatchIfValid(true);
      validationContext.validateField("retrospectiveStartDate");
    }}
				></dc-datepicker>
			</div>
		</div>`;
  };
  const htmlRestartOrExtendCompliance = () => {
    return html`${!isDeregisteredOrPoARevoked() ? html`<div class="lg:flex lg:justify-between">
						<div
							class="btn-toggle mt-6 mb-1 xl:w-1/2 ${!vatCountry.restartCompliance && !vatCountry.monthlyCompliance ? "selected" : ""} ${vatCountry.origComplianceStartDate === void 0 ? "hidden" : ""}"
							@click=${() => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), {
        restartCompliance: false,
        monthlyCompliance: false
      }));
      setDispatchIfValid(true);
    }}
						>
							<div class="flex justify-between">
								<div class="mr-2">${i18next.t("Renew for one year")}</div>
								<dc-tooltip .label=${i18next.t("order-compliance-extension-tooltip", "Select this if you want to renew your existing subscription.")}></dc-tooltip>
							</div>
						</div>
				  </div>` : ""}
        ${hasComplianceExpired(vatCountry) ? html`<div class="lg:flex lg:justify-between">
						<div
							class="btn-toggle mt-6 mb-1 xl:w-1/2 ${vatCountry.restartCompliance ? "selected" : ""}"
							@click=${() => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), {
        restartCompliance: !vatCountry.restartCompliance,
        monthlyCompliance: false
      }));
      setDispatchIfValid(true);
    }}
						>
							<div class="flex justify-between">
								<div class="mr-2">${i18next.t("New sign up")}</div>
								<dc-tooltip .label=${i18next.t("order-compliance-restart-tooltip", "Select this if you are switching from another service provider to us.")}></dc-tooltip>
							</div>
						</div>
						<div class="${vatCountry.restartCompliance ? "block" : "hidden"} ">
							<dc-datepicker
								.label=${i18next.t("Start Date")}
								.value=${vatCountry.restartComplianceStartDate}
								.validationMessage=${validationContext.getValidationMessage("restartComplianceStartDate")}
								.min=${vatCountry.origComplianceEndDate}
								@change=${(e) => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), { restartComplianceStartDate: e.detail.value }));
      setDispatchIfValid(true);
      validationContext.validateField("restartComplianceStartDate");
    }}
							></dc-datepicker>
						</div>
				  </div>` : ""}
			${props.isAdmin && vatCountry.origComplianceStartDate !== void 0 && currentCountryPriceConfig.monthlyCompliance ? html`<div class="lg:flex lg:justify-between">
							<div
								class="btn-toggle mt-6 mb-1 xl:w-1/2 ${vatCountry.monthlyCompliance ? "selected" : ""}"
								@click=${() => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), {
        monthlyCompliance: !vatCountry.monthlyCompliance,
        restartCompliance: false
      }));
      setDispatchIfValid(true);
    }}
							>
								<div class="flex justify-between">
									<div class="mr-2">${i18next.t("Extend by month")}</div>
								</div>
							</div>
							<div class="${vatCountry.monthlyCompliance ? "block" : "hidden"} ">
								<dc-datepicker
									.label=${i18next.t("End Date")}
									.value=${vatCountry.monthlyComplianceEndDate}
									.validationMessage=${validationContext.getValidationMessage("monthlyComplianceEndDate")}
									.min=${moment(vatCountry.origComplianceEndDate).add(1, "days").toDate()}
									@change=${(e) => {
      setVatCountry(__spreadProps(__spreadValues({}, vatCountry), { monthlyComplianceEndDate: e.detail.value }));
      setDispatchIfValid(true);
      validationContext.validateField("monthlyComplianceEndDate");
    }}
								></dc-datepicker>
							</div>
					  </div>` : ""}
		</div>`;
  };
  const htmlAdditionalServices = () => {
    return hasAdditionalServices() ? html`<div class="form-label mt-8">${i18next.t("Select additional services")}</div>
					${currentCountryPriceConfig.intrastatIn && (vatCountry.selected || vatCountry.origIntrastatInEndDate === void 0 || vatCountry.origIntrastatInEndDate !== void 0 && vatCountry.origIntrastatInEndDate < vatCountry.origComplianceEndDate) ? htmlIntrastatIn() : ""}
					${currentCountryPriceConfig.intrastatOut && (vatCountry.selected || vatCountry.origIntrastatOutEndDate === void 0 || vatCountry.origIntrastatOutEndDate !== void 0 && vatCountry.origIntrastatOutEndDate < vatCountry.origComplianceEndDate) ? htmlIntrastatOut() : ""}
					${currentCountryPriceConfig.spanishBankAccount ? htmlSpanishBankAccount() : ""}
					${props.isAdmin ? html`${!vatCountry.selected && currentCountryPriceConfig.selfRevision ? htmlSelfRevision() : ""}
						  ${!vatCountry.selected && currentCountryPriceConfig.retro ? htmlRetrospective() : ""} ` : ""}${currentCountryPriceConfig.deregistration && canDeregister() ? htmlDeregistration() : ""}` : "";
  };
  return html`<div class="bg-gray-100 p-2 rounded">
		<div class="font-bold text-brand-primary">${props.label}</div>
		<div class="ml-4">
			${vatCountry.origComplianceStartDate !== void 0 ? html`${i18next.t("Started")}: ${formatDate(vatCountry.origComplianceStartDate)}` : ""}
			${vatCountry.origComplianceEndDate !== void 0 ? html`${vatCountry.origComplianceStartDate !== void 0 ? "- " : ""}
				  ${getDay(vatCountry.origComplianceEndDate) < getDay(/* @__PURE__ */ new Date()) ? html`<span class="text-red-500"> ${i18next.t("Expired")}: ${formatDate(vatCountry.origComplianceEndDate)} </span>` : html`<span>${i18next.t("Expires")}: ${formatDate(vatCountry.origComplianceEndDate)}</span>`}` : ""}
			${currentCountryPriceConfig ? html`${currentCountryPriceConfig.registration !== void 0 ? htmlRegistration() : ""} ${vatCountry.selected ? htmlRestartOrExtendCompliance() : ""} ${htmlAdditionalServices()}` : ""}
		</div>
	</div>`;
};
