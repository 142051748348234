var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./dc-form-modal.scss";
import { html } from "lit-html";
import { component } from "haunted";
export const MODAL_CONFIG = {
  View: { title: "View item", buttonTitle: "CLOSE" },
  Add: { title: "Add item", buttonTitle: "CREATE" },
  Edit: { title: "Edit item", buttonTitle: "UPDATE" },
  Delete: { title: "Delete item", buttonTitle: "YES" }
};
const DEFAULTS = {
  dismissable: true,
  visible: false,
  displayMode: "View",
  content: html``,
  header: html``
};
export class ChangeEvent extends CustomEvent {
  constructor(detail) {
    super("action", { detail });
  }
}
export const Component = (host) => {
  const props = {
    dismissable: host.dismissable !== void 0 ? host.dismissable : DEFAULTS.dismissable,
    visible: host.visible !== void 0 ? host.visible : DEFAULTS.visible,
    displayMode: host.displayMode !== void 0 ? host.displayMode : DEFAULTS.displayMode,
    header: host.header,
    content: host.content || DEFAULTS.content
  };
  const onAction = (_e) => __async(void 0, null, function* () {
    if (props.displayMode === "View") {
      host.dispatchEvent(
        new ChangeEvent({
          actionType: "Close"
        })
      );
    } else if (props.displayMode === "Add") {
      host.dispatchEvent(
        new ChangeEvent({
          actionType: "Add"
        })
      );
    } else if (props.displayMode === "Edit") {
      host.dispatchEvent(
        new ChangeEvent({
          actionType: "Update"
        })
      );
    } else if (props.displayMode === "Delete") {
      host.dispatchEvent(
        new ChangeEvent({
          actionType: "Delete"
        })
      );
    }
  });
  return html`
		<dc-modal
			.visible=${props.visible}
			@close=${() => {
    host.dispatchEvent(
      new ChangeEvent({
        actionType: "Close"
      })
    );
  }}
			.header=${props.header ? props.header : MODAL_CONFIG[props.displayMode].title}
			.content=${html`
				<div>
					<div>${props.displayMode === "Delete" ? html` <div>Are you sure you want to delete this item?</div> ` : props.content}</div>
					<div class="flex mt-4">
						<button class="btn btn-primary mr-4" @click=${onAction}>${MODAL_CONFIG[props.displayMode].buttonTitle}</button>
						${props.displayMode === "Delete" ? html`
									<button
										class="btn btn-primary"
										@click=${() => {
    host.dispatchEvent(
      new ChangeEvent({
        actionType: "Close"
      })
    );
  }}
									>
										No
									</button>
							  ` : ""}
					</div>
				</div>
			`}
		>
		</dc-modal>
	`;
};
customElements.define(
  "dc-form-modal",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
