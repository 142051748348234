import { DateConverter } from "./../../../../converters/DateConverter";
export class VatRateTypeSetting {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRateTypeSetting
     */
    this.$type = "V1.VatRateTypeSettings.Search.VatRateTypeSetting";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CountryCode = _data["CountryCode"];
      this.Rate = _data["Rate"];
      this.Type = VatRateTypeSettingType[_data["Type"]];
      this.ValidFrom = DateConverter.from(_data["ValidFrom"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CountryCode"] = this.CountryCode;
    _data["Rate"] = this.Rate;
    _data["Type"] = VatRateTypeSettingType[this.Type];
    _data["ValidFrom"] = DateConverter.toUtcDateString(this.ValidFrom);
    return _data;
  }
}
export var VatRateTypeSettingType = /* @__PURE__ */ ((VatRateTypeSettingType2) => {
  VatRateTypeSettingType2["Standard"] = "Standard";
  VatRateTypeSettingType2["Standard2"] = "Standard2";
  VatRateTypeSettingType2["Reduced"] = "Reduced";
  VatRateTypeSettingType2["Reduced2"] = "Reduced2";
  VatRateTypeSettingType2["SuperReduced"] = "SuperReduced";
  VatRateTypeSettingType2["SuperReduced2"] = "SuperReduced2";
  VatRateTypeSettingType2["Exempt"] = "Exempt";
  VatRateTypeSettingType2["ZeroRated"] = "ZeroRated";
  VatRateTypeSettingType2["Middle"] = "Middle";
  return VatRateTypeSettingType2;
})(VatRateTypeSettingType || {});
