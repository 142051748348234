export class VatRegField {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRegField
     */
    this.$type = "V1.Company.VatRegistration.Search.VatRegField";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Order = VatRegFieldOrder[_data["Order"]];
      this.OrderByField = VatRegFieldOrderByField[_data["OrderByField"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Order"] = VatRegFieldOrder[this.Order];
    _data["OrderByField"] = VatRegFieldOrderByField[this.OrderByField];
    return _data;
  }
}
export var VatRegFieldOrder = /* @__PURE__ */ ((VatRegFieldOrder2) => {
  VatRegFieldOrder2["Ascending"] = "Ascending";
  VatRegFieldOrder2["Descending"] = "Descending";
  return VatRegFieldOrder2;
})(VatRegFieldOrder || {});
export var VatRegFieldOrderByField = /* @__PURE__ */ ((VatRegFieldOrderByField2) => {
  VatRegFieldOrderByField2["CompanyId"] = "CompanyId";
  VatRegFieldOrderByField2["CompanyName"] = "CompanyName";
  VatRegFieldOrderByField2["SignUpDate"] = "SignUpDate";
  VatRegFieldOrderByField2["Country"] = "Country";
  VatRegFieldOrderByField2["VatRegistrationStatus"] = "VatRegistrationStatus";
  VatRegFieldOrderByField2["RegType"] = "RegType";
  VatRegFieldOrderByField2["ClientSource"] = "ClientSource";
  VatRegFieldOrderByField2["CompanyStatus"] = "CompanyStatus";
  VatRegFieldOrderByField2["ResponsibleEmail"] = "ResponsibleEmail";
  VatRegFieldOrderByField2["SubscriptionStatus"] = "SubscriptionStatus";
  VatRegFieldOrderByField2["SubscriptionValidTo"] = "SubscriptionValidTo";
  return VatRegFieldOrderByField2;
})(VatRegFieldOrderByField || {});
