export function toBoolean(value) {
  if (!value) {
    return false;
  }
  return typeof value !== "boolean" && value.toLowerCase() === "true" || typeof value === "boolean" && value;
}
export function mapStateToBoolean(state) {
  switch (state) {
    case "No":
      return false;
    case "Yes":
      return true;
    default:
      return void 0;
  }
}
export function mapBooleanToState(value) {
  switch (value) {
    case false:
      return "No";
    case true:
      return "Yes";
    default:
      return "None";
  }
}
