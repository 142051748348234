var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { BankAccountConfigValidator, getAllBankAccountConfigFields } from "./BankAccountConfigValidator";
export const useBankAccountForm = (props) => {
  const manualIbanCountries = ["PL", "SK"];
  const getBankAccountType = () => {
    if (props === void 0) {
      return void 0;
    }
    if (props.serviceCountryCode === "DE") {
      return "BankAccountUrl";
    }
    if (manualIbanCountries.includes(props.serviceCountryCode)) {
      return "Manual";
    }
    return "BankAccountRef";
  };
  const getBankAccountsDataSource = () => {
    if (props.predefinedBankAccounts) {
      return props.predefinedBankAccounts.filter((pba) => pba.CountryCode === props.serviceCountryCode && (pba.EstablishmentCountryIsInEU === void 0 || pba.EstablishmentCountryIsInEU === props.isInEu)).map((pba) => ({
        label: `${pba.BankName} ${pba.BeneficiaryName ? `(${pba.BeneficiaryName})` : ""}`,
        value: pba.Id
      }));
    } else {
      return [];
    }
  };
  const [bankAccountConfig, setBankAccountConfig] = useState({});
  const bankAccountConfigValidationContext = useValidationContext(new BankAccountConfigValidator(getBankAccountType()));
  useEffect(() => {
    var _a, _b, _c;
    if (props == null ? void 0 : props.serviceCountryCode) {
      if (manualIbanCountries.includes(props == null ? void 0 : props.serviceCountryCode)) {
        if (props == null ? void 0 : props.predefinedBankAccounts) {
          const manualIbanPredefinedBankAccount = props.predefinedBankAccounts.find((b) => b.CountryCode === props.serviceCountryCode);
          if (manualIbanPredefinedBankAccount) {
            setBankAccountConfig(__spreadProps(__spreadValues({}, props.bankAccountConfig), {
              BankAccount: __spreadProps(__spreadValues({}, (_a = props.bankAccountConfig) == null ? void 0 : _a.BankAccount), {
                BankAccountId: manualIbanPredefinedBankAccount.Id,
                BankName: manualIbanPredefinedBankAccount.BankName,
                Swift: manualIbanPredefinedBankAccount.Swift,
                BankAddress: manualIbanPredefinedBankAccount.BankAddress,
                BeneficiaryAddress: manualIbanPredefinedBankAccount.BeneficiaryAddress,
                BeneficiaryName: manualIbanPredefinedBankAccount.BeneficiaryName,
                Iban: (_c = (_b = props.bankAccountConfig) == null ? void 0 : _b.BankAccount) == null ? void 0 : _c.Iban
              })
            }));
          }
        }
      } else if (props == null ? void 0 : props.bankAccountConfig) {
        setBankAccountConfig(props.bankAccountConfig);
      }
    }
  }, [props == null ? void 0 : props.bankAccountConfig, props == null ? void 0 : props.predefinedBankAccounts, props == null ? void 0 : props.serviceCountryCode]);
  const htmlTemplate = () => {
    var _a, _b, _c, _d, _e, _f;
    return html`<div>
			${getBankAccountType() === "BankAccountRef" ? html`<div class="w-1/2">
						<dc-select
							.dataSource=${getBankAccountsDataSource()}
							.selectedValues=${bankAccountConfig.BankAccountRef}
							.validationMessage=${bankAccountConfigValidationContext.getValidationMessage("BankAccountRef")}
							.label=${"Bank account"}
							@change=${(e) => {
      bankAccountConfigValidationContext.validateField("BankAccountRef");
      setBankAccountConfig(__spreadProps(__spreadValues({}, bankAccountConfig), {
        BankAccountRef: e.detail.selectedValue
      }));
    }}
						></dc-select>
				  </div>` : ""}
			${getBankAccountType() === "BankAccountUrl" ? html`<div>
						<dc-input
							.label=${"Bank account url"}
							.value=${bankAccountConfig.BankAccountUrl}
							.validationMessage=${bankAccountConfigValidationContext.getValidationMessage("BankAccountUrl")}
							@change=${(e) => {
      bankAccountConfigValidationContext.validateField("BankAccountUrl");
      setBankAccountConfig(__spreadProps(__spreadValues({}, bankAccountConfig), {
        BankAccountUrl: e.detail.value
      }));
    }}
						></dc-input>
				  </div>` : ""}
			${getBankAccountType() === "Manual" ? html`<div class="grid grid-cols-2 gap-4">
						<dc-input
							.label=${"Bank name"}
							.readonly=${true}
							.value=${(_a = bankAccountConfig.BankAccount) == null ? void 0 : _a.BankName}
							.validationMessage=${bankAccountConfigValidationContext.getValidationMessage("BankName")}
							@change=${(e) => {
      bankAccountConfigValidationContext.validateField("BankName");
      setBankAccountConfig(__spreadProps(__spreadValues({}, bankAccountConfig), {
        BankAccount: __spreadProps(__spreadValues({}, bankAccountConfig.BankAccount), {
          BankName: e.detail.value
        })
      }));
    }}
						></dc-input>
						<dc-input
							.label=${"Bank address"}
							.readonly=${true}
							.value=${(_b = bankAccountConfig.BankAccount) == null ? void 0 : _b.BankAddress}
							.validationMessage=${bankAccountConfigValidationContext.getValidationMessage("BankAddress")}
							@change=${(e) => {
      bankAccountConfigValidationContext.validateField("BankAddress");
      setBankAccountConfig(__spreadProps(__spreadValues({}, bankAccountConfig), {
        BankAccount: __spreadProps(__spreadValues({}, bankAccountConfig.BankAccount), {
          BankAddress: e.detail.value
        })
      }));
    }}
						></dc-input>
						<dc-input
							.label=${"Beneficiary name"}
							.readonly=${true}
							.value=${(_c = bankAccountConfig.BankAccount) == null ? void 0 : _c.BeneficiaryName}
							.validationMessage=${bankAccountConfigValidationContext.getValidationMessage("BeneficiaryName")}
							@change=${(e) => {
      bankAccountConfigValidationContext.validateField("BeneficiaryName");
      setBankAccountConfig(__spreadProps(__spreadValues({}, bankAccountConfig), {
        BankAccount: __spreadProps(__spreadValues({}, bankAccountConfig.BankAccount), {
          BeneficiaryName: e.detail.value
        })
      }));
    }}
						></dc-input>
						<dc-input
							.label=${"Beneficiary address"}
							.readonly=${true}
							.value=${(_d = bankAccountConfig.BankAccount) == null ? void 0 : _d.BeneficiaryAddress}
							.validationMessage=${bankAccountConfigValidationContext.getValidationMessage("BeneficiaryAddress")}
							@change=${(e) => {
      bankAccountConfigValidationContext.validateField("BeneficiaryAddress");
      setBankAccountConfig(__spreadProps(__spreadValues({}, bankAccountConfig), {
        BankAccount: __spreadProps(__spreadValues({}, bankAccountConfig.BankAccount), {
          BeneficiaryAddress: e.detail.value
        })
      }));
    }}
						></dc-input>
						<dc-input
							.label=${"Swift"}
							.readonly=${true}
							.value=${(_e = bankAccountConfig.BankAccount) == null ? void 0 : _e.Swift}
							.validationMessage=${bankAccountConfigValidationContext.getValidationMessage("Swift")}
							@change=${(e) => {
      bankAccountConfigValidationContext.validateField("Swift");
      setBankAccountConfig(__spreadProps(__spreadValues({}, bankAccountConfig), {
        BankAccount: __spreadProps(__spreadValues({}, bankAccountConfig.BankAccount), {
          Swift: e.detail.value
        })
      }));
    }}
						></dc-input>
						<dc-input
							.label=${"Iban"}
							.value=${(_f = bankAccountConfig.BankAccount) == null ? void 0 : _f.Iban}
							.validationMessage=${bankAccountConfigValidationContext.getValidationMessage("Iban")}
							@change=${(e) => {
      bankAccountConfigValidationContext.validateField("Iban");
      setBankAccountConfig(__spreadProps(__spreadValues({}, bankAccountConfig), {
        BankAccount: __spreadProps(__spreadValues({}, bankAccountConfig.BankAccount), {
          Iban: e.detail.value
        })
      }));
    }}
						></dc-input>
				  </div>` : ""}
		</div>`;
  };
  return { template: htmlTemplate, bankAccountConfig, setBankAccountConfig, bankAccountConfigValidationContext };
};
export const useBankAccountModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [dispatchIfBankAccountIsValid, setDispatchIfBankAccountIsValid] = useState(false);
  const form = useBankAccountForm(props);
  const save = () => __async(void 0, null, function* () {
    form.setBankAccountConfig(__spreadValues({}, form.bankAccountConfig));
    form.bankAccountConfigValidationContext.validateFields(getAllBankAccountConfigFields());
    setDispatchIfBankAccountIsValid(true);
  });
  const show = () => {
    setShowModal(true);
  };
  useEffect(() => {
    if (showModal) {
      form.bankAccountConfigValidationContext.clearFieldValidations();
    }
  }, [showModal]);
  useEffect(() => __async(void 0, null, function* () {
    if (dispatchIfBankAccountIsValid) {
      setDispatchIfBankAccountIsValid(false);
      if (form.bankAccountConfigValidationContext.validationResult.isValid()) {
        yield props.save(form.bankAccountConfig);
        setShowModal(false);
      }
    }
  }), [form.bankAccountConfigValidationContext.validationResult]);
  useEffect(() => {
    if (form.bankAccountConfig !== void 0) {
      form.bankAccountConfigValidationContext.validate(form.bankAccountConfig);
    }
  }, [form.bankAccountConfig]);
  const template = () => {
    return html`${showModal ? html`
					<div>
						<dc-modal
							.visible=${showModal}
							@close=${() => setShowModal(false)}
							.header=${"Edit bank account config"}
							.content=${html`<div style="height: 50vh">
								${form.template()}
								<div class="mt-4 space-x-4">
									<button class="btn btn-primary" @click=${() => setShowModal(false)}>Cancel</button>
									<button
										class="btn btn-primary"
										@click=${() => __async(void 0, null, function* () {
      yield save();
    })}
									>
										Save
									</button>
								</div>
							</div>`}
						>
						</dc-modal>
					</div>
			  ` : html``}`;
  };
  return { show, template };
};
