var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useCallback, useClient, useState } from "GlobalShared/haunted/CustomHooks";
import { templateTitle } from "GlobalShared/templates/commons";
import { html } from "lit-html";
import { Agents as AgentsClient, Roles as RolesClient } from "AdminServices/APIs/self/clients";
import { Filter, Pager, SearchRequest } from "AdminServices/APIs/self/models/V1/Agents/Search";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import { Field, FieldOrder, FieldOrderByField } from "AdminServices/APIs/self/models/V1/Agents/Search/Field";
import { FilterStatus } from "AdminServices/APIs/self/models/V1/Agents/Search/Filter";
import { notifyError, notifyWarning, notifySuccess, withErrorHandling, notifyErrorWithWarning } from "GlobalShared/helpers/errorHelper";
import { useEffect } from "haunted";
import { AgentStatus } from "AdminServices/APIs/self/models/V1/Agents/Agent";
import { useAwesomeValidator, Validation } from "GlobalShared/validation/AwesomeValidator";
import {
  ACCESS_ALL_ESTABLISHMENT_COUNTRY_ROLE_NAME,
  ACCESS_NON_EU_ESTABLISHMENT_COUNTRY_ONLY_ROLE_NAME,
  ROLE_NAME_LABEL_MAPPING,
  withoutEstablishmentCountryRoles
} from "./AgentModels";
import { Invitation, RolesModify, UpdatedAgent } from "AdminServices/APIs/self/models/V1/Agents";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
import { usePermissionManager } from "AdminComponents/hooks/AdminCustomHooks";
import { htmlTruncatedCell } from "GlobalShared/helpers/htmlHelper";
const AgentFieldNames = {
  Id: "Id",
  Email: "Email",
  Status: "Status",
  Roles: "Roles",
  Actions: "Actions"
};
const DEFAULT_AGENT = {
  id: void 0,
  email: void 0,
  status: "Enabled",
  roles: [],
  origRoles: [],
  twoFactorEnabled: false,
  origAccessToNonEUOnly: false,
  accessToNonEUOnly: false
};
const DEFAULT_FILTERS = [];
export function Agents() {
  const getFilter = () => {
    const clientFilter = new Filter();
    gridState.appliedFilters.forEach((filter) => {
      if (filter.field === AgentFieldNames.Email) {
        if (filter.filterProps.selectedValues) {
          clientFilter.Email = filter.filterProps.selectedValues;
        }
      } else if (filter.field === AgentFieldNames.Status) {
        if (filter.filterProps.selectedValues) {
          clientFilter.Status = filter.filterProps.selectedValues;
        }
      } else if (filter.field === AgentFieldNames.Roles) {
        if (filter.filterProps.selectedValues) {
          clientFilter.RoleNames = filter.filterProps.selectedValues;
        }
      }
    });
    return clientFilter;
  };
  const loadAllRoles = () => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        const rolesResult = yield rolesClient.getRoles();
        setAllRoles(
          withoutEstablishmentCountryRoles(rolesResult.data).map((role) => ({
            label: ROLE_NAME_LABEL_MAPPING[role.toLowerCase()],
            value: role
          }))
        );
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const loadAgents = () => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        const agentsResult = yield agentsClient.searchAgents({
          body: new SearchRequest({
            Filter: getFilter(),
            Pager: new Pager({
              Skip: gridState.pageIndex * gridState.pageSize,
              Top: gridState.pageSize,
              OrderBy: [
                new Field({
                  Order: gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: gridState.orderBy
                })
              ]
            })
          })
        });
        setSearchResult(agentsResult.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const enableAgent = (agentId) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield agentsClient.enableAgent({ agentId });
        notifySuccess("Agent successfully enabled.");
        yield loadAgents();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const disableAgent = (agentId) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield agentsClient.disableAgent({ agentId });
        notifySuccess("Agent successfully disabled.");
        yield loadAgents();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const inviteAgent = () => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield agentsClient.createAdminInvitation({
          body: new Invitation({
            Email: currentAgent.email,
            RoleNames: currentAgent.roles.concat([currentAgent.accessToNonEUOnly ? ACCESS_NON_EU_ESTABLISHMENT_COUNTRY_ONLY_ROLE_NAME : ACCESS_ALL_ESTABLISHMENT_COUNTRY_ROLE_NAME])
          })
        });
        setShowInvitationModal(false);
        notifySuccess("Agent successfully invited.");
      }),
      (error) => {
        if (error.statusCode === 409) {
          notifyWarning("Agent has been already invited.");
        } else {
          notifyError(error);
        }
      }
    );
  });
  const saveAgent = () => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        const rolesToAdd = currentAgent.roles.filter((r) => !currentAgent.origRoles.includes(r));
        const rolesToRemove = currentAgent.origRoles.filter((r) => !currentAgent.roles.includes(r));
        yield agentsClient.modifyAgent({
          agentId: currentAgent.id,
          body: new UpdatedAgent({ IsTwoFactorEnabled: currentAgent.twoFactorEnabled })
        });
        if (currentAgent.origAccessToNonEUOnly && !currentAgent.accessToNonEUOnly) {
          rolesToRemove.push(ACCESS_NON_EU_ESTABLISHMENT_COUNTRY_ONLY_ROLE_NAME);
          rolesToAdd.push(ACCESS_ALL_ESTABLISHMENT_COUNTRY_ROLE_NAME);
        } else if (!currentAgent.origAccessToNonEUOnly && currentAgent.accessToNonEUOnly) {
          rolesToRemove.push(ACCESS_ALL_ESTABLISHMENT_COUNTRY_ROLE_NAME);
          rolesToAdd.push(ACCESS_NON_EU_ESTABLISHMENT_COUNTRY_ONLY_ROLE_NAME);
        }
        if (rolesToAdd.length > 0) {
          yield agentsClient.addRoles({
            agentId: currentAgent.id,
            body: new RolesModify({ Roles: rolesToAdd })
          });
        }
        if (rolesToRemove.length > 0) {
          yield agentsClient.deleteRoles({
            agentId: currentAgent.id,
            body: new RolesModify({ Roles: rolesToRemove })
          });
        }
        setShowEditRolesModal(false);
        loadAgents();
        notifySuccess("Agent successfully saved.");
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const onSortChanged = (e) => {
    const orderByValue = e.detail.orderBy;
    const orderDirValue = e.detail.orderDir;
    setGridState(__spreadProps(__spreadValues({}, gridState), {
      orderBy: orderByValue,
      orderDir: orderDirValue
    }));
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridFilter);
  };
  const [gridState, setGridState] = useState({
    pageIndex: 0,
    appliedFilters: DEFAULT_FILTERS,
    pageSize: 20,
    orderBy: FieldOrderByField.Email,
    orderDir: "asc"
  });
  const [searchResult, setSearchResult] = useState(void 0);
  const [currentAgent, setCurrentAgent] = useState(DEFAULT_AGENT);
  const agentValidator = useAwesomeValidator(
    [
      Validation.ruleFor("Email", (vm) => vm.email).isEmail().isRequired(),
      Validation.ruleFor("Roles", (vm) => vm.roles).min(1, "Select at least one role")
    ],
    () => {
      if (currentAgent.id === void 0) {
        inviteAgent();
      } else {
        saveAgent();
      }
    }
  );
  const [allRoles, setAllRoles] = useState(void 0);
  const rolesDataSourceCallback = useCallback(
    (text) => {
      return allRoles.filter((item) => isEmpty(text) || item.value.toLowerCase().includes(text.toLowerCase()));
    },
    [allRoles]
  );
  const agentsClient = useClient(AgentsClient);
  const rolesClient = useClient(RolesClient);
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [showEditRolesModal, setShowEditRolesModal] = useState(false);
  const permissionManager = usePermissionManager();
  useEffect(() => {
    loadAllRoles();
  }, []);
  useEffect(() => {
    loadAgents();
  }, [gridState]);
  useEffect(() => {
    if (showInvitationModal) {
      agentValidator.clear();
    }
  }, [showInvitationModal]);
  useEffect(() => {
    if (showEditRolesModal) {
      agentValidator.clear();
    }
  }, [showEditRolesModal]);
  useEffect(() => {
    if (currentAgent !== void 0) {
      agentValidator.validate(currentAgent);
    }
  }, [currentAgent]);
  const htmlInvitationModal = () => {
    return html`
			<div style="max-width: 300px">
				<dc-modal
					.visible=${showInvitationModal}
					@close=${() => setShowInvitationModal(false)}
					.header=${"Invite agent"}
					.content=${html`<div>
						<div class="flex flex-col space-y-4 lg:w-1/2">
							<dc-input
								.label=${"Email"}
								.value=${currentAgent.email}
								.validationMessage=${agentValidator.getMessage("Email")}
								@change=${(e) => {
      agentValidator.showMessageFor("Email");
      setCurrentAgent(__spreadProps(__spreadValues({}, currentAgent), { email: e.detail.value }));
    }}
							></dc-input>
							<dc-multiselect
								.label=${"Roles"}
								.selectedItems=${currentAgent.roles.map((role) => ({
      value: role,
      label: ROLE_NAME_LABEL_MAPPING[role]
    }))}
								.validationMessage=${agentValidator.getMessage("Roles")}
								.readonly=${!permissionManager.hasPermission("Agent_Modify_Role")}
								@change=${(e) => {
      agentValidator.showMessageFor("Roles");
      setCurrentAgent(__spreadProps(__spreadValues({}, currentAgent), {
        roles: e.detail.items.map((item) => item.value)
      }));
    }}
								.dataSource=${rolesDataSourceCallback}
							></dc-multiselect>
							<dc-toggle
								.label=${"Access to Non-EU only"}
								.state=${(currentAgent == null ? void 0 : currentAgent.accessToNonEUOnly) ? "Yes" : "No"}
								.readonly=${!permissionManager.hasPermission("Agent_Modify_Role")}
								@change=${(e) => {
      setCurrentAgent(__spreadProps(__spreadValues({}, currentAgent), { accessToNonEUOnly: e.detail.state === "Yes" }));
    }}
							></dc-toggle>
							<!-- <dc-toggle
								.label=${"Two-factor authentication"}
								.state=${(currentAgent == null ? void 0 : currentAgent.twoFactorEnabled) ? "Yes" : "No"}
								@change=${(e) => {
      setCurrentAgent(__spreadProps(__spreadValues({}, currentAgent), { twoFactorEnabled: e.detail.state === "Yes" }));
    }}
							></dc-toggle> -->
						</div>
						<div class="mt-4 flex space-x-4 pb-64">
							<button class="btn btn-primary block" @click=${() => setShowInvitationModal(false)}>Cancel</button
							><button class="btn btn-primary block" @click=${() => agentValidator.validateAndDispatch(currentAgent)}>Invite</button>
						</div>
					</div>`}
				>
				</dc-modal>
			</div>
		`;
  };
  const htmlEditRolesModal = () => {
    return html`
			<div style="max-width: 300px">
				<dc-modal
					.visible=${showEditRolesModal}
					@close=${() => setShowEditRolesModal(false)}
					.header=${"Edit agent"}
					.content=${html`<div>
						<div class="flex flex-col space-y-4 lg:w-1/2">
							<dc-input .label=${"Email"} .value=${currentAgent.email} .readonly=${true}></dc-input>
							<dc-multiselect
								.selectedItems=${currentAgent.roles.map((role) => ({
      value: role,
      label: ROLE_NAME_LABEL_MAPPING[role]
    }))}
								.validationMessage=${agentValidator.getMessage("Roles")}
								.readonly=${!permissionManager.hasPermission("Agent_Modify_Role")}
								@change=${(e) => {
      agentValidator.showMessageFor("Roles");
      setCurrentAgent(__spreadProps(__spreadValues({}, currentAgent), {
        roles: e.detail.items.map((item) => item.value)
      }));
    }}
								.dataSource=${rolesDataSourceCallback}
							></dc-multiselect>
							<dc-toggle
								.label=${"Access to Non-EU only"}
								.state=${(currentAgent == null ? void 0 : currentAgent.accessToNonEUOnly) ? "Yes" : "No"}
								.readonly=${!permissionManager.hasPermission("Agent_Modify_Role")}
								@change=${(e) => {
      setCurrentAgent(__spreadProps(__spreadValues({}, currentAgent), { accessToNonEUOnly: e.detail.state === "Yes" }));
    }}
							></dc-toggle>
							<dc-toggle
								.label=${"Two-factor authentication"}
								.state=${(currentAgent == null ? void 0 : currentAgent.twoFactorEnabled) ? "Yes" : "No"}
								@change=${(e) => {
      setCurrentAgent(__spreadProps(__spreadValues({}, currentAgent), { twoFactorEnabled: e.detail.state === "Yes" }));
    }}
							></dc-toggle>
						</div>
						<div class="mt-4 flex space-x-4 pb-64">
							<button class="btn btn-primary block" @click=${() => setShowEditRolesModal(false)}>Cancel</button
							><button class="btn btn-primary block" @click=${() => agentValidator.validateAndDispatch(currentAgent)}>Save</button>
						</div>
					</div>`}
				>
				</dc-modal>
			</div>
		`;
  };
  const htmlGrid = () => {
    var _a;
    const vm = {
      columns: [
        {
          field: AgentFieldNames.Email,
          label: "Email",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: AgentFieldNames.Status,
          label: "Status",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: [FilterStatus.Disabled, FilterStatus.Enabled]
          },
          columnType: "enum"
        },
        {
          field: AgentFieldNames.Roles,
          label: "Roles",
          sortable: false,
          filterable: true,
          filterDescriptor: {
            type: "select",
            multiSelect: true,
            dataSource: allRoles != null ? allRoles : []
          },
          columnType: "enum"
        },
        { field: AgentFieldNames.Actions, label: "" }
      ],
      data: searchResult.Agents,
      sorting: {
        orderBy: gridState.orderBy,
        orderDir: gridState.orderDir
      },
      cellTemplate: (index, field) => {
        const item = searchResult.Agents[index];
        if (field === AgentFieldNames.Email) {
          return item.Email;
        } else if (field === AgentFieldNames.Status) {
          return item.Status;
        } else if (field === AgentFieldNames.Roles) {
          return htmlTruncatedCell(
            withoutEstablishmentCountryRoles(item.Roles).map((role) => ROLE_NAME_LABEL_MAPPING[role.toLowerCase()]).join(", ")
          );
        } else if (field === AgentFieldNames.Actions) {
          return permissionManager.hasPermission("Agent_Modify") ? html`<div class="w-full flex justify-around">
								<div class="flex space-x-4">
									<div>
										<button class="btn btn-sm btn-primary" @click=${() => item.Status === AgentStatus.Enabled ? disableAgent(item.Id) : enableAgent(item.Id)}>
											${item.Status === AgentStatus.Enabled ? "Disable" : "Enable"}
										</button>
									</div>
									<div>
										<button
											class="btn btn-sm btn-primary"
											@click=${() => __async(this, null, function* () {
            setCurrentAgent({
              id: item.Id,
              email: item.Email,
              status: item.Status,
              origRoles: withoutEstablishmentCountryRoles(item.Roles),
              roles: withoutEstablishmentCountryRoles(item.Roles),
              twoFactorEnabled: item.IsTwoFactorEnabled,
              origAccessToNonEUOnly: item.Roles.includes(ACCESS_NON_EU_ESTABLISHMENT_COUNTRY_ONLY_ROLE_NAME),
              accessToNonEUOnly: item.Roles.includes(ACCESS_NON_EU_ESTABLISHMENT_COUNTRY_ONLY_ROLE_NAME)
            });
            setShowEditRolesModal(true);
          })}
										>
											Edit
										</button>
									</div>
								</div>
						  </div>` : "";
        } else {
          return "";
        }
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: searchResult.Count
      }
    };
    return html`<div class="xl:w-3/4">
			<div class="mb-4">
				<div>${templateTitle("Agents")}</div>
				<div>
					<div class="flex space-x-4 my-4">
						${permissionManager.hasPermission("Agent_Invite") ? html`<div class="flex items-end">
									<div>
										<button
											class="btn btn-primary"
											?disabled=${!permissionManager.hasPermission("Agent_Invite")}
											@click=${() => {
      setCurrentAgent(DEFAULT_AGENT);
      setShowInvitationModal(true);
    }}
										>
											Invite
										</button>
									</div>
							  </div>` : ""}
						${((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html`<div>
									<button
										class="btn btn-primary"
										@click=${() => setGridState(__spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: DEFAULT_FILTERS
    }))}
									>
										Reset filters
									</button>
							  </div>` : ""}
					</div>
				</div>
			</div>
			<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>
		</div>`;
  };
  return html`<div class="my-2 mx-8 mb-16 mt-8">${searchResult !== void 0 ? html`${htmlGrid()}${htmlEditRolesModal()}${htmlInvitationModal()} ` : "Loading..."}</div>`;
}
