import { AbstractValidator } from "fluent-ts-validator";
import { DCValidationResult } from "GlobalShared/haunted/HooksHelpers";
import { REQUIRED_MESSAGE } from "GlobalShared/helpers/stringHelper";
export const AllReturnGeneratedValidatorFieldNames = [
  "intrastatInValue",
  "intrastatInCurrency",
  "intrastatOutValue",
  "intrastatOutCurrency",
  "vatValue",
  "vatCurrency",
  "vatPaymentCategory"
];
export class SubmissionValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfNumber((r) => r.intrastatInValue).isNotEmpty().withPropertyName("intrastatInValue").withFailureMessage("Number required").when((r) => r.isIntrastatInApplicable);
    this.validateIfString((r) => r.intrastatInCurrency).isNotEmpty().withPropertyName("intrastatInCurrency").withFailureMessage(REQUIRED_MESSAGE).when((r) => r.isIntrastatInApplicable);
    this.validateIfNumber((r) => r.intrastatOutValue).isNotEmpty().withPropertyName("intrastatOutValue").withFailureMessage(REQUIRED_MESSAGE).when((r) => r.isIntrastatOutApplicable);
    this.validateIfString((r) => r.intrastatOutCurrency).isNotEmpty().withPropertyName("intrastatOutCurrency").withFailureMessage(REQUIRED_MESSAGE).when((r) => r.isIntrastatOutApplicable);
    this.validateIfNumber((r) => r.vatValue).isNotEmpty().withPropertyName("vatValue").withFailureMessage("Number required").when((r) => r.isVATApplicable);
    this.validateIfString((r) => r.vatCurrency).isNotEmpty().withPropertyName("vatCurrency").withFailureMessage(REQUIRED_MESSAGE).when((r) => r.isVATApplicable);
    this.validateIfString((r) => r.vatPayment).isNotEmpty().withPropertyName("vatPaymentCategory").withFailureMessage(REQUIRED_MESSAGE).when((r) => r.isVATApplicable);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
