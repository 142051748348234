import { TswagClient } from "./../commons/TswagClient";
import { Order } from "./../models/V1/Orders/Get/Order";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { SearchResponse } from "./../models/V1/Orders/Search/SearchResponse";
export class Orders {
  /**
   * Creates an instance of Orders.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Orders
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param CreateOrderRq_ _createOrderRq
   * @returns Promise<CreateOrderRq_>
   * @memberof Orders
   */
  createOrder(pCreateOrderRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pCreateOrderRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 201, clazz: Order },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetOrderRq_ _getOrderRq
   * @returns Promise<GetOrderRq_>
   * @memberof Orders
   */
  getOrder(pGetOrderRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pGetOrderRq.orderId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Order },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CancelOrderRq_ _cancelOrderRq
   * @returns Promise<CancelOrderRq_>
   * @memberof Orders
   */
  cancelOrder(pCancelOrderRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pCancelOrderRq.orderId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadContractRq_ _downloadContractRq
   * @returns Promise<DownloadContractRq_>
   * @memberof Orders
   */
  downloadContract(pDownloadContractRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}/contract",
      "GET",
      "application/json; charset=UTF-8",
      "image/png, image/jpeg, application/pdf",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pDownloadContractRq.orderId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadContractRq_ _uploadContractRq
   * @returns Promise<UploadContractRq_>
   * @memberof Orders
   */
  uploadContract(pUploadContractRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}/contract",
      "PATCH",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pUploadContractRq.orderId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadContractRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ConfirmOrderRq_ _confirmOrderRq
   * @returns Promise<ConfirmOrderRq_>
   * @memberof Orders
   */
  confirmOrder(pConfirmOrderRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}/confirm",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pConfirmOrderRq.orderId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CanCreateNewOrderRq_ _canCreateNewOrderRq
   * @returns Promise<CanCreateNewOrderRq_>
   * @memberof Orders
   */
  canCreateNewOrder(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/creatable",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200 }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchOrdersRq_ _searchOrdersRq
   * @returns Promise<SearchOrdersRq_>
   * @memberof Orders
   */
  searchOrders(pSearchOrdersRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchOrdersRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResponse },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadBillingDocumentRq_ _downloadBillingDocumentRq
   * @returns Promise<DownloadBillingDocumentRq_>
   * @memberof Orders
   */
  downloadBillingDocument(pDownloadBillingDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Orders/{orderId}/documents/{documentId}",
      "GET",
      "application/json; charset=UTF-8",
      "image/png, image/jpeg, application/pdf",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pDownloadBillingDocumentRq.orderId, required: true },
      { name: "documentId", value: pDownloadBillingDocumentRq.documentId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
