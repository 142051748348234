export var FilterRegistrationType = /* @__PURE__ */ ((FilterRegistrationType2) => {
  FilterRegistrationType2["NewRegistration"] = "NewRegistration";
  FilterRegistrationType2["TakeOver"] = "TakeOver";
  return FilterRegistrationType2;
})(FilterRegistrationType || {});
export var FilterRegistrationStatuses = /* @__PURE__ */ ((FilterRegistrationStatuses2) => {
  FilterRegistrationStatuses2["Pending"] = "Pending";
  FilterRegistrationStatuses2["WaitingForSignature"] = "WaitingForSignature";
  FilterRegistrationStatuses2["DocumentsSigned"] = "DocumentsSigned";
  FilterRegistrationStatuses2["SentToTA"] = "SentToTA";
  FilterRegistrationStatuses2["RegistrationCompleted"] = "RegistrationCompleted";
  FilterRegistrationStatuses2["Failed"] = "Failed";
  FilterRegistrationStatuses2["Declined"] = "Declined";
  FilterRegistrationStatuses2["Deregistered"] = "Deregistered";
  FilterRegistrationStatuses2["DeregistrationInProgress"] = "DeregistrationInProgress";
  return FilterRegistrationStatuses2;
})(FilterRegistrationStatuses || {});
export var FilterClientSource = /* @__PURE__ */ ((FilterClientSource2) => {
  FilterClientSource2["Amazon"] = "Amazon";
  FilterClientSource2["KPMG"] = "KPMG";
  FilterClientSource2["FedEx"] = "FedEx";
  FilterClientSource2["LaPoste"] = "LaPoste";
  return FilterClientSource2;
})(FilterClientSource || {});
export var FilterCompanyStatus = /* @__PURE__ */ ((FilterCompanyStatus2) => {
  FilterCompanyStatus2["Active"] = "Active";
  FilterCompanyStatus2["Rejected"] = "Rejected";
  FilterCompanyStatus2["Inactive"] = "Inactive";
  return FilterCompanyStatus2;
})(FilterCompanyStatus || {});
export var FilterSubscriptionStatus = /* @__PURE__ */ ((FilterSubscriptionStatus2) => {
  FilterSubscriptionStatus2["Active"] = "Active";
  FilterSubscriptionStatus2["Cancelled"] = "Cancelled";
  FilterSubscriptionStatus2["Expired"] = "Expired";
  FilterSubscriptionStatus2["Pending"] = "Pending";
  return FilterSubscriptionStatus2;
})(FilterSubscriptionStatus || {});
