var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import i18next from "i18next";
import {
  IossFile as WebIossFile,
  KpmgTemplateFile as WebKpmgTemplateFile,
  AmazonFile as WebAmazonFile,
  AmazonSaftFile as WebAmazonSaftFile
} from "WebServices/APIs/self/models/V1/ComplianceUploads/Files";
import {
  IossFile as AdminIossFile,
  KpmgTemplateFile as AdminKpmgTemplateFile,
  AmazonFile as AdminAmazonFile,
  AmazonSaftFile as AdminAmazonSaftFile
} from "AdminServices/APIs/self/models/V1/ComplianceUploads/Files";
import { formatDate, parseDate } from "GlobalShared/helpers/dateHelper";
import { html } from "lit-html";
export const ComplianceUploadFieldNames = {
  ClientId: "ClientId",
  ClientName: "ClientName",
  Period: "Period",
  Status: "Status",
  Type: "Type",
  Actions: "Actions"
};
export var ComplianceUploadStatusLabel = /* @__PURE__ */ ((ComplianceUploadStatusLabel2) => {
  ComplianceUploadStatusLabel2["New"] = "New";
  ComplianceUploadStatusLabel2["WaitingForFinalize"] = "WaitingForFinalize";
  ComplianceUploadStatusLabel2["InvalidData"] = "InvalidData";
  ComplianceUploadStatusLabel2["SaftCompliance"] = "SAF-T Compliance";
  ComplianceUploadStatusLabel2["MasterDataSetup"] = "MasterDataSetup";
  ComplianceUploadStatusLabel2["DataUploadFinalized"] = "DataUploadFinalized";
  ComplianceUploadStatusLabel2["DataVatGenerated"] = "DataVatGenerated";
  ComplianceUploadStatusLabel2["SentToReturnGeneration"] = "SentToReturnGeneration";
  ComplianceUploadStatusLabel2["UploadProcessed"] = "UploadProcessed";
  ComplianceUploadStatusLabel2["InsufficientCredit"] = "InsufficientCredit";
  ComplianceUploadStatusLabel2["Error"] = "Error";
  return ComplianceUploadStatusLabel2;
})(ComplianceUploadStatusLabel || {});
export const ALL_STATUS = [
  "New",
  "InvalidData",
  "WaitingForFinalize",
  "SaftCompliance",
  "MasterDataSetup",
  "DataUploadFinalized",
  "DataVatGenerated",
  "Error",
  "SentToReturnGeneration",
  "UploadProcessed",
  "InsufficientCredit"
];
export const COMPLIANCE_UPLOAD_TYPES = ["VAT", "IOSS"];
export const DEFAULT_COMPLIANCE_UPLOAD_STATE = {
  showWarningIgnoreButton: false,
  file: void 0,
  transactionType: "Unknown",
  sellerEntityId: void 0
};
const COLUMN_NAME_MAPPING = {
  TransactionType: "Transaction type",
  TransactionDate: "Transaction date",
  DestinationCountry: "Ship-to country",
  Currency: "Currency",
  VatRate: "VAT rate",
  GrossAmount: "Gross amount",
  TransactionIdentifier: "Order number or unique transaction number",
  DateOfSupply: "Date of the supply",
  PaymentDate: "Payment received date",
  InvoiceDate: "Invoice date",
  Description: "Description of goods supplied",
  Quantity: "Quantity of goods supplied",
  DepartureCountry: "Ship-from country",
  TaxableBasis: "Taxable basis",
  VatAmount: "VAT amount",
  ConsignmentNumber: "Unique consignment number ",
  InvoiceCountry: "Sold-to/bill-to country",
  CommodityCode: "Commodity code",
  ExchangeRate: "Exchange rate to EUR",
  ReportingGrossAmount: "Gross amount_reporting",
  ReportingTaxableBasis: "Taxable basis_reporting",
  ReportingVatAmount: "VAT amount_reporting",
  InvoiceNumber: "Invoice number",
  UNIQUE_ACCOUNT_IDENTIFIER: "UNIQUE_ACCOUNT_IDENTIFIER",
  ACTIVITY_PERIOD: "ACTIVITY_PERIOD",
  SALES_CHANNEL: "SALES_CHANNEL",
  MARKETPLACE: "MARKETPLACE",
  PROGRAM_TYPE: "PROGRAM_TYPE",
  ID: "Id",
  TRANSACTION_TYPE: "TRANSACTION_TYPE",
  TRANSACTION_EVENT_ID: "TRANSACTION_EVENT_ID",
  ACTIVITY_TRANSACTION_ID: "ACTIVITY_TRANSACTION_ID",
  TAX_CALCULATION_DATE: "TAX_CALCULATION_DATE",
  TRANSACTION_DEPART_DATE: "TRANSACTION_DEPART_DATE",
  TRANSACTION_ARRIVAL_DATE: "TRANSACTION_ARRIVAL_DATE",
  TRANSACTION_COMPLETE_DATE: "TRANSACTION_COMPLETE_DATE",
  SELLER_SKU: "SELLER_SKU",
  ASIN: "ASIN",
  ITEM_DESCRIPTION: "ITEM_DESCRIPTION",
  ITEM_MANUFACTURE_COUNTRY: "ITEM_MANUFACTURE_COUNTRY",
  QTY: "QTY",
  ITEM_WEIGHT: "ITEM_WEIGHT",
  TOTAL_ACTIVITY_WEIGHT: "TOTAL_ACTIVITY_WEIGHT",
  COST_PRICE_OF_ITEMS: "COST_PRICE_OF_ITEMS",
  PRICE_OF_ITEMS_AMT_VAT_EXCL: "PRICE_OF_ITEMS_AMT_VAT_EXCL",
  PROMO_PRICE_OF_ITEMS_AMT_VAT_EXCL: "PROMO_PRICE_OF_ITEMS_AMT_VAT_EXCL",
  TOTAL_PRICE_OF_ITEMS_AMT_VAT_EXCL: "TOTAL_PRICE_OF_ITEMS_AMT_VAT_EXCL",
  SHIP_CHARGE_AMT_VAT_EXCL: "SHIP_CHARGE_AMT_VAT_EXCL",
  PROMO_SHIP_CHARGE_AMT_VAT_EXCL: "PROMO_SHIP_CHARGE_AMT_VAT_EXCL",
  TOTAL_SHIP_CHARGE_AMT_VAT_EXCL: "TOTAL_SHIP_CHARGE_AMT_VAT_EXCL",
  GIFT_WRAP_AMT_VAT_EXCL: "GIFT_WRAP_AMT_VAT_EXCL",
  PROMO_GIFT_WRAP_AMT_VAT_EXCL: "PROMO_GIFT_WRAP_AMT_VAT_EXCL",
  TOTAL_GIFT_WRAP_AMT_VAT_EXCL: "TOTAL_GIFT_WRAP_AMT_VAT_EXCL",
  TOTAL_ACTIVITY_VALUE_AMT_VAT_EXCL: "TOTAL_ACTIVITY_VALUE_AMT_VAT_EXCL",
  PRICE_OF_ITEMS_VAT_RATE_PERCENT: "PRICE_OF_ITEMS_VAT_RATE_PERCENT",
  PRICE_OF_ITEMS_VAT_AMT: "PRICE_OF_ITEMS_VAT_AMT",
  PROMO_PRICE_OF_ITEMS_VAT_AMT: "PROMO_PRICE_OF_ITEMS_VAT_AMT",
  TOTAL_PRICE_OF_ITEMS_VAT_AMT: "TOTAL_PRICE_OF_ITEMS_VAT_AMT",
  SHIP_CHARGE_VAT_RATE_PERCENT: "SHIP_CHARGE_VAT_RATE_PERCENT",
  SHIP_CHARGE_VAT_AMT: "SHIP_CHARGE_VAT_AMT",
  PROMO_SHIP_CHARGE_VAT_AMT: "PROMO_SHIP_CHARGE_VAT_AMT",
  TOTAL_SHIP_CHARGE_VAT_AMT: "TOTAL_SHIP_CHARGE_VAT_AMT",
  GIFT_WRAP_VAT_RATE_PERCENT: "GIFT_WRAP_VAT_RATE_PERCENT",
  GIFT_WRAP_VAT_AMT: "GIFT_WRAP_VAT_AMT",
  PROMO_GIFT_WRAP_VAT_AMT: "PROMO_GIFT_WRAP_VAT_AMT",
  TOTAL_GIFT_WRAP_VAT_AMT: "TOTAL_GIFT_WRAP_VAT_AMT",
  TOTAL_ACTIVITY_VALUE_VAT_AMT: "TOTAL_ACTIVITY_VALUE_VAT_AMT",
  PRICE_OF_ITEMS_AMT_VAT_INCL: "PRICE_OF_ITEMS_AMT_VAT_INCL",
  PROMO_PRICE_OF_ITEMS_AMT_VAT_INCL: "PROMO_PRICE_OF_ITEMS_AMT_VAT_INCL",
  TOTAL_PRICE_OF_ITEMS_AMT_VAT_INCL: "TOTAL_PRICE_OF_ITEMS_AMT_VAT_INCL",
  SHIP_CHARGE_AMT_VAT_INCL: "SHIP_CHARGE_AMT_VAT_INCL",
  PROMO_SHIP_CHARGE_AMT_VAT_INCL: "PROMO_SHIP_CHARGE_AMT_VAT_INCL",
  TOTAL_SHIP_CHARGE_AMT_VAT_INCL: "TOTAL_SHIP_CHARGE_AMT_VAT_INCL",
  GIFT_WRAP_AMT_VAT_INCL: "GIFT_WRAP_AMT_VAT_INCL",
  PROMO_GIFT_WRAP_AMT_VAT_INCL: "PROMO_GIFT_WRAP_AMT_VAT_INCL",
  TOTAL_GIFT_WRAP_AMT_VAT_INCL: "TOTAL_GIFT_WRAP_AMT_VAT_INCL",
  TOTAL_ACTIVITY_VALUE_AMT_VAT_INCL: "TOTAL_ACTIVITY_VALUE_AMT_VAT_INCL",
  TRANSACTION_CURRENCY_CODE: "TRANSACTION_CURRENCY_CODE",
  COMMODITY_CODE: "COMMODITY_CODE",
  STATISTICAL_CODE_DEPART: "STATISTICAL_CODE_DEPART",
  STATISTICAL_CODE_ARRIVAL: "STATISTICAL_CODE_ARRIVAL",
  COMMODITY_CODE_SUPPLEMENTARY_UNIT: "COMMODITY_CODE_SUPPLEMENTARY_UNIT",
  ITEM_QTY_SUPPLEMENTARY_UNIT: "ITEM_QTY_SUPPLEMENTARY_UNIT",
  TOTAL_ACTIVITY_SUPPLEMENTARY_UNIT: "TOTAL_ACTIVITY_SUPPLEMENTARY_UNIT",
  PRODUCT_TAX_CODE: "PRODUCT_TAX_CODE",
  DEPATURE_CITY: "DEPATURE_CITY",
  DEPARTURE_COUNTRY: "DEPARTURE_COUNTRY",
  DEPARTURE_POST_CODE: "DEPARTURE_POST_CODE",
  ARRIVAL_CITY: "ARRIVAL_CITY",
  ARRIVAL_COUNTRY: "ARRIVAL_COUNTRY",
  ARRIVAL_POST_CODE: "ARRIVAL_POST_CODE",
  SALE_DEPART_COUNTRY: "SALE_DEPART_COUNTRY",
  SALE_ARRIVAL_COUNTRY: "SALE_ARRIVAL_COUNTRY",
  TRANSPORTATION_MODE: "TRANSPORTATION_MODE",
  DELIVERY_CONDITIONS: "DELIVERY_CONDITIONS",
  SELLER_DEPART_VAT_NUMBER_COUNTRY: "SELLER_DEPART_VAT_NUMBER_COUNTRY",
  SELLER_DEPART_COUNTRY_VAT_NUMBER: "SELLER_DEPART_COUNTRY_VAT_NUMBER",
  SELLER_ARRIVAL_VAT_NUMBER_COUNTRY: "SELLER_ARRIVAL_VAT_NUMBER_COUNTRY",
  SELLER_ARRIVAL_COUNTRY_VAT_NUMBER: "SELLER_ARRIVAL_COUNTRY_VAT_NUMBER",
  TRANSACTION_SELLER_VAT_NUMBER_COUNTRY: "TRANSACTION_SELLER_VAT_NUMBER_COUNTRY",
  TRANSACTION_SELLER_VAT_NUMBER: "TRANSACTION_SELLER_VAT_NUMBER",
  BUYER_VAT_NUMBER_COUNTRY: "BUYER_VAT_NUMBER_COUNTRY",
  BUYER_VAT_NUMBER: "BUYER_VAT_NUMBER",
  VAT_CALCULATION_IMPUTATION_COUNTRY: "VAT_CALCULATION_IMPUTATION_COUNTRY",
  TAXABLE_JURISDICTION: "TAXABLE_JURISDICTION",
  TAXABLE_JURISDICTION_LEVEL: "TAXABLE_JURISDICTION_LEVEL",
  VAT_INV_NUMBER: "VAT_INV_NUMBER",
  VAT_INV_CONVERTED_AMT: "VAT_INV_CONVERTED_AMT",
  VAT_INV_CURRENCY_CODE: "VAT_INV_CURRENCY_CODE",
  VAT_INV_EXCHANGE_RATE: "VAT_INV_EXCHANGE_RATE",
  VAT_INV_EXCHANGE_RATE_DATE: "VAT_INV_EXCHANGE_RATE_DATE",
  EXPORT_OUTSIDE_EU: "EXPORT_OUTSIDE_EU",
  INVOICE_URL: "INVOICE_URL",
  BUYER_NAME: "BUYER_NAME",
  ARRIVAL_ADDRESS: "ARRIVAL_ADDRESS",
  SUPPLIER_NAME: "SUPPLIER_NAME",
  SUPPLIER_VAT_NUMBER: "SUPPLIER_VAT_NUMBER",
  TAX_REPORTING_SCHEME: "TAX_REPORTING_SCHEME",
  TAX_COLLECTION_RESPONSIBILITY: "TAX_COLLECTION_RESPONSIBILITY",
  TypeOfSale: "TypeOfSale",
  TypeOfPartner: "TypeOfPartner",
  VatNumber: "VatNumber",
  ShippingFrom: "ShippingFrom",
  CountryOfCustomer: "CountryOfCustomer",
  VatReportingCountry: "VatReportingCountry",
  Consignment: "Consignment",
  OnlineMarketplace: "OnlineMarketplace",
  PostponedAccounting: "PostponedAccounting",
  TaxCollectionResponsibility: "TaxCollectionResponsibility",
  CountryOfOrigin: "CountryOfOrigin",
  Weight: "Weight",
  Unit: "Unit",
  DepartureZipCode: "DepartureZipCode",
  DeliveryConditions: "DeliveryConditions",
  NatureOfTransaction: "NatureOfTransaction",
  ModeOfTransport: "ModeOfTransport",
  ArrivalZipCode: "ArrivalZipCode",
  Sale_MPP: "Sale_MPP",
  SW: "SW",
  EE: "EE",
  TP: "TP",
  TT_WNT: "TT_WNT",
  TT_D: "TT_D",
  MR_T: "MR_T",
  MR_UZ: "MR_UZ",
  I_42: "I_42",
  I_63: "I_63",
  B_SPV: "B_SPV",
  B_SPV_DOSTWA: "B_SPV_DOSTWA",
  B_MPV_PROWIZJA: "B_MPV_PROWIZJA",
  RO: "RO",
  Sale_WEW: "Sale_WEW",
  FP: "FP",
  IMP: "IMP",
  Purchase_MPP: "Purchase_MPP",
  MK: "MK",
  VAT_RR: "VAT_RR",
  Purchase_WEW: "Purchase_WEW",
  GTU_01: "GTU_01",
  GTU_02: "GTU_02",
  GTU_03: "GTU_03",
  GTU_04: "GTU_04",
  GTU_05: "GTU_05",
  GTU_06: "GTU_06",
  GTU_07: "GTU_07",
  GTU_08: "GTU_08",
  GTU_09: "GTU_09",
  GTU_10: "GTU_10",
  GTU_11: "GTU_11",
  GTU_12: "GTU_12",
  GTU_13: "GTU_13",
  PartnerName: "PartnerName"
};
const getEnhancedMessage = (message, validationMessage, _type, exampleValue) => {
  return html`<div>
		<div>
			${i18next.t("validation-column-header", "{{columnName}} (Column {{columnCode}})", {
    columnName: COLUMN_NAME_MAPPING[validationMessage.columnName],
    columnCode: validationMessage.columnCode
  })}:
			${message}.
		</div>
		${exampleValue ? html`<div class="text-red-700">
					${i18next.t("validation-example", "See an example of the incorrect value: {{exampleValue}} (line {{rowNumber}}). Number of incorrect lines: {{total}}", {
    exampleValue,
    rowNumber: validationMessage.rowNumber + 1,
    // Rows are indexed from 1
    total: validationMessage.totalCount
  })}
			  </div>` : ""}
	</div>`;
};
const mapValidationMessageVatRequired = (validationMessage, type) => {
  if (validationMessage.columnName === "VatRate") {
    if (validationMessage.parameters[0] === "INBOUND_OR_OUTBOUND" && validationMessage.parameters[1] === "WHEN_TAXABLE_BASIS") {
      return getEnhancedMessage(
        i18next.t("validation-vat-vatrate-is-required-inbound-or-outbound-when-taxable-basis", "Please note that for Inbounds, the VAT rate and the Taxable basis must correspond"),
        validationMessage,
        type,
        validationMessage.parameters[2]
      );
    }
    if (validationMessage.parameters[0] === "SALE_OR_RETURN" && validationMessage.parameters[1] === "WHEN_TAXABLE_BASIS") {
      return getEnhancedMessage(
        i18next.t(
          "validation-vat-vatrate-is-required-sale-or-return-when-taxable-basis",
          "Please note that the VAT rate column is also mandatory if you provide the NET value (Taxable basis) of a transaction"
        ),
        validationMessage,
        type,
        validationMessage.parameters[2]
      );
    }
    if (validationMessage.parameters[0] === "SALE_OR_RETURN" && validationMessage.parameters[1] === "WHEN_VAT_AMOUNT") {
      return getEnhancedMessage(
        i18next.t("validation-vat-vatrate-is-required-sale-or-return-when-vat_amount", "Please note that the VAT rate column is also mandatory if you provide the VAT value of a transaction"),
        validationMessage,
        type,
        validationMessage.parameters[2]
      );
    }
  }
  if (validationMessage.columnName === "PartnerName") {
    if (validationMessage.parameters[0] === "REGISTERED_BUSINESS") {
      return getEnhancedMessage(
        i18next.t(
          "validation-vat-partnername-is-required-registered-business",
          "Please note that if you choose VAT Registered/Business, you must provide the partner's VAT number and registered name"
        ),
        validationMessage,
        type,
        validationMessage.parameters[1]
      );
    }
  }
  return getEnhancedMessage(i18next.t("validation-data-cannot-be-empty", "Data cannot be empty"), validationMessage, type);
};
const mapValidationMessageVatInvalidData = (validationMessage, type) => {
  if (validationMessage.columnName === "VatReportingCountry") {
    if (validationMessage.parameters[0] === "IN_SHIPPING_FOR_OR_CUSTOMER") {
      return getEnhancedMessage(
        i18next.t(
          "validation-vat-vatreportingcountry-invalid-shipping-or-customer",
          "Please note that VAT Reporting Country cannot be different from the Shipping from or the Customer's country."
        ),
        validationMessage,
        type,
        validationMessage.parameters[1]
      );
    }
  }
  if (validationMessage.columnName === "VatRate") {
    if (validationMessage.parameters[0] === "IN_VAT_REPORTING_COUNTRY") {
      return getEnhancedMessage(
        i18next.t("validation-vat-vatrate-invalid-vat-reporting-country", "Please note that the applied VAT rate is not possible in the provided VAT Reporting country (line {{rowNumber}})", {
          rowNumber: validationMessage.rowNumber + 1
          // Rows are indexed from 1
        }),
        validationMessage,
        type,
        validationMessage.parameters[1]
      );
    }
    if (validationMessage.parameters[0] === "FC_TRANSFER") {
      return getEnhancedMessage(
        i18next.t(
          "validation-vat-vatrate-invalid-fc-transfer-cannot-be-null",
          "Please note that the provided VAT rate and the Transaction type do not match. FC_Transfer must be reported as reverse charge, therefore gross should be calculated with VAT rate"
        ),
        validationMessage,
        type,
        validationMessage.parameters[1]
      );
    }
    if (validationMessage.parameters[0] === "INBOUND_OR_OUTBOUND") {
      return getEnhancedMessage(i18next.t("validation-vat-vatrate-invalid-inbound-or-outbound-cannot-be-null-except"), validationMessage, type, validationMessage.parameters[1]);
    }
  }
  if (validationMessage.columnName === "VatNumber") {
    if (validationMessage.parameters[0] === "REGISTERED_BUSINESS" && validationMessage.parameters[1] === "STARTS_WITH_TWO_LETTERS") {
      return getEnhancedMessage(
        i18next.t(
          "validation-vat-vatnumber-invalid-starts-with-two-letter",
          "Please note that the format of the provided Buyer's VAT Number is incorrect. It must contain the country code as well"
        ),
        validationMessage,
        type,
        validationMessage.parameters[2]
      );
    }
    if (validationMessage.parameters[0] === "INBOUND_OR_OUTBOUND") {
      return getEnhancedMessage(
        i18next.t("validation-vat-vatnumber-invalid-inbound-or-outbound", "Please note that for Inbounds, the VAT Number should correspond with the Dispatch from country"),
        validationMessage,
        type,
        validationMessage.parameters[1]
      );
    }
    if (validationMessage.parameters[0] === "SALE_OR_RETURN") {
      return getEnhancedMessage(
        i18next.t("validation-vat-vatnumber-invalid-sale-or-return", "Please note that the VAT Number should start with the Customer's country"),
        validationMessage,
        type,
        validationMessage.parameters[1]
      );
    }
    if (validationMessage.parameters[0] === "VATNUMBER_MUST_IN_EU") {
      return getEnhancedMessage(i18next.t("validation-vat-vatnumber-invalid-countrycodeprefix"), validationMessage, type, validationMessage.parameters[1]);
    }
  }
  if (validationMessage.columnName === "PartnerName") {
    if (validationMessage.parameters[0] === "REGISTERED_BUSINESS" && validationMessage.parameters[1] === "ONLY_ALPHANUM") {
      return getEnhancedMessage(
        i18next.t("validation-vat-partnername-invalid-registered-business", "Please note that the Partner's Name can only contain letters from the English alphabet"),
        validationMessage,
        type,
        validationMessage.parameters[2]
      );
    }
  }
  if (validationMessage.columnName === "TypeOfSale") {
    if (validationMessage.parameters[0] === "FC_TRANSFER") {
      return getEnhancedMessage(
        i18next.t("validation-vat-typeofsale-invalid-only-goods", "Please note that the Type of Sale for FC_Transfer can only be Goods"),
        validationMessage,
        type,
        validationMessage.parameters[1]
      );
    }
  }
  if (validationMessage.columnName === "TypeOfPartner") {
    if (validationMessage.parameters[0] === "FC_TRANSFER") {
      return getEnhancedMessage(
        i18next.t("validation-vat-typeofpartner-invalid-fc-transfer-only-registered-business", "Please note that the Type of Partner for FC_Transfer can only be VAT Registered/Business"),
        validationMessage,
        type,
        validationMessage.parameters[1]
      );
    }
    if (validationMessage.parameters[0] === "INBOUND_OR_OUTBOUND") {
      return getEnhancedMessage(
        i18next.t("validation-vat-typeofpartner-invalid-inbound-or-outbound-only-registered-business", "Please note that for Inbounds, the Type of Partner must be VAT Registered/Business"),
        validationMessage,
        type,
        validationMessage.parameters[1]
      );
    }
  }
  if (validationMessage.columnName === "CountryOfCustomer") {
    if (validationMessage.parameters[0] === "FC_TRANSFER" && validationMessage.parameters[1] === "MUST_HAVE_VALID_REG_IN") {
      return getEnhancedMessage(
        i18next.t(
          "validation-vat-countryofcustomer-invalid-fc-transfer-valid-reg",
          "Please note that according to our database, your entity does not have valid registration in the Shipping from or Customer's country and it is mandatory to report FC_Transfer"
        ),
        validationMessage,
        type,
        validationMessage.parameters[2]
      );
    }
    if (validationMessage.parameters[0] === "FC_TRANSFER" && validationMessage.parameters[1] === "CUSTOMER_SHIPPING_COUNTRY_NEQ") {
      return getEnhancedMessage(
        i18next.t("validation-vat-countryofcustomer-invalid-fc-transfer-customer-and-shipping-country-neq", "Please note that FC_Transfer is only possible between two different EU countries"),
        validationMessage,
        type,
        validationMessage.parameters[2]
      );
    }
    if (validationMessage.parameters[0] === "INBOUND_OR_OUTBOUND" && validationMessage.parameters[1] === "MUST_BE_IN_EU") {
      return getEnhancedMessage(
        i18next.t("validation-vat-countryofcustomer-invalid-inbound-or-outbound-must-be-in-eu", "Please note that for Inbounds, the Customer's country must be an EU country or GB"),
        validationMessage,
        type,
        validationMessage.parameters[2]
      );
    }
    if (validationMessage.parameters[0] === "INBOUND_OR_OUTBOUND" && validationMessage.parameters[1] === "CUSTOMER_MUST_BE_EQUAL_WITH_REPORTING") {
      return getEnhancedMessage(
        i18next.t("validation-vat-countryofcustomer-invalid-inbound-or-outbound-customer-eq-reporting", "Please note that for Inbounds, the VAT Reporting country must be an EU country or GB"),
        validationMessage,
        type,
        validationMessage.parameters[2]
      );
    }
  }
  if (validationMessage.columnName === "TaxableBasis") {
    if (validationMessage.parameters[0] === "INBOUND_OR_OUTBOUND") {
      return getEnhancedMessage(
        i18next.t(
          "validation-vat-taxablebasis-invalid-inbound-or-outbound-vat-values-correspond",
          "Please note that for Inbounds, the VAT rate, the Taxable basis and the VAT amount must correspond"
        ),
        validationMessage,
        type,
        validationMessage.parameters[1]
      );
    }
    if (validationMessage.parameters[0] === "SALE_OR_RETURN") {
      return getEnhancedMessage(
        i18next.t(
          "validation-vat-taxablebasis-invalid-sale-or-return-vat-values-correspond",
          "Please note that Taxable basis should correspond with the applied VAT rate and the calculated VAT amount, the following lines are incorrect"
        ),
        validationMessage,
        type,
        validationMessage.parameters[1]
      );
    }
  }
  if (validationMessage.columnName === "VatAmount") {
    if (validationMessage.parameters[0] === "SALE_OR_RETURN") {
      return getEnhancedMessage(
        i18next.t(
          "validation-vat-vatamount-invalid-sale-or-return-vat-values-correspond",
          "Please note that Taxable basis should correspond with the applied VAT rate and the calculated VAT amount, the following lines are incorrect"
        ),
        validationMessage,
        type,
        validationMessage.parameters[1]
      );
    }
  }
  return getEnhancedMessage(i18next.t("validation-invalid-data", "Invalid data"), validationMessage, type, validationMessage.parameters[0]);
};
const mapValidationMessageIossInvalidData = (validationMessage, type) => {
  if (validationMessage.columnName === "TransactionType") {
    return getEnhancedMessage(i18next.t("validation-ioss-invalid-transaction-type", "It must be Sale or Return"), validationMessage, type, validationMessage.parameters[0]);
  } else if (validationMessage.columnName === "Currency") {
    return getEnhancedMessage(i18next.t("validation-ioss-invalid-currency", "You must use 3-letter currency ISO code"), validationMessage, type, validationMessage.parameters[0]);
  } else if (validationMessage.columnName === "DestinationCountry") {
    return getEnhancedMessage(
      i18next.t("validation-ioss-invalid-destination-country", "It must be in the EU + Northern Ireland. You must use 2-letter country ISO code"),
      validationMessage,
      type,
      validationMessage.parameters[0]
    );
  } else if (validationMessage.columnName === "VatRate" && validationMessage.parameters[0] === "IN_Destination_Country") {
    return getEnhancedMessage(
      i18next.t("validation-vatrate-invalid-destination-country", "Please note that the applied VAT rate is not possible in the provided Destination country (line {{rowNumber}})", {
        rowNumber: validationMessage.rowNumber + 1
        // Rows are indexed from 1
      }),
      validationMessage,
      type,
      validationMessage.parameters[1]
    );
  } else if (validationMessage.columnName === "DepartureCountry") {
    return getEnhancedMessage(
      i18next.t("validation-ioss-invalid-departure-country", "It must be outside of the EU. You must use 2-letter country ISO code"),
      validationMessage,
      type,
      validationMessage.parameters[0]
    );
  } else if (validationMessage.columnName === "InvoiceCountry") {
    return getEnhancedMessage(i18next.t("validation-ioss-invalid-invoice-country", "You must use 2-letter country ISO code"), validationMessage, type, validationMessage.parameters[0]);
  }
  return getEnhancedMessage(i18next.t("validation-invalid-data", "Invalid data"), validationMessage, type, validationMessage.parameters[0]);
};
export const mapOutOfPeriodTransactionsValidationMessage = (validationMessage, type) => {
  if (type === "Ioss") {
    return getEnhancedMessage(
      i18next.t(
        "validation-ioss-out-of-period-transactions",
        "Your data contains one or more transactions from a previous transaction period. If you want to correct previously submitted returns by submitting new transactions now, you can ignore this message and proceed with the upload. However, if the date was added by mistake you must stop the upload and correct your data before proceeding"
      ),
      validationMessage,
      type,
      validationMessage.parameters[1]
    );
  } else if (type === "Vat" || type === "Amazon") {
    return getEnhancedMessage(
      i18next.t(
        "validation-vat-out-of-period-transactions",
        "Your data contains one or more transactions from a previous transaction period. You can only upload transactions for the current period"
      ),
      validationMessage,
      type,
      validationMessage.parameters[1]
    );
  }
  return getEnhancedMessage(i18next.t("validation-invalid-data", "Invalid data"), validationMessage, type);
};
export const mapRequiredOrWrongFormatalidationMessage = (validationMessage, type) => {
  if (validationMessage.columnName === "VatRate") {
    return getEnhancedMessage(
      i18next.t("validation-vatrate-data-cannot-be-empty-or-invalid", "Data cannot be empty or invalid (expected format: Between 1-27 (or 0) with decimal separator ('.'), e.g.: 21.5)"),
      validationMessage,
      type
    );
  } else if (validationMessage.columnName === "GrossAmount" || validationMessage.columnName === "TaxableBasis" || validationMessage.columnName === "VatAmount" || validationMessage.columnName === "ExchangeRate" || validationMessage.columnName === "ReportingGrossAmount" || validationMessage.columnName === "ReportingTaxableBasis" || validationMessage.columnName === "ReportingVatAmount") {
    return getEnhancedMessage(
      i18next.t("validation-number-data-cannot-be-empty-or-invalid", "Data cannot be empty or invalid (expected format: Number with decimal separator ('.'), e.g.: 21.5)"),
      validationMessage,
      type
    );
  } else if (validationMessage.columnName === "DateOfSupply" || validationMessage.columnName === "PaymentDate" || validationMessage.columnName === "InvoiceDate") {
    return getEnhancedMessage(i18next.t("validation-date-invalid", "Data cannot be empty or invalid (expected format: dd-mm-yyyy)"), validationMessage, type);
  } else if (validationMessage.columnName === "Quantity") {
    return getEnhancedMessage(i18next.t("validation-positive-number-invalid", "Data cannot be empty or invalid (expected format: Non-negative number, e.g.: 435)"), validationMessage, type);
  }
  return getEnhancedMessage(i18next.t("validation-required-or-invalid", "Data cannot be empty or invalid"), validationMessage, type);
};
export const mapValidationMessage = (validationMessage, type) => {
  if (validationMessage.validationCode === "WrongTemplateColumns") {
    return i18next.t(`Wrong template format. Please use the most up to date template. Make sure you do not change the headers (Expected header in column {{column}} is '{{val}}').`, {
      column: validationMessage.parameters[0],
      val: validationMessage.parameters[1]
    });
  } else if (validationMessage.validationCode === "WrongHeader") {
    return i18next.t("wrong-template-format");
  } else if (validationMessage.validationCode === "TaxableBasisReportingExceededTheLimit") {
    return i18next.t(
      "You have one or more transactions of which the taxable basis exceeds the EUR 150 limit. If the value of the goods sold (excluding shipping fee and VAT) is indeed higher, please ignore this message and proceed to upload the data, these transactions will be excluded from your IOSS return. However, if the value of the goods (excluding shipping fee and VAT) is not below the EUR 150 limit, and is only exceeds it because the shipping fee is included, you should cancel this data upload, include the shipping fee into a separate line and reupload the data. Please refer to our IOSS data template guide if you have any questions."
    );
  } else if (validationMessage.validationCode === "OutOfPeriodTransactions") {
    return mapOutOfPeriodTransactionsValidationMessage(validationMessage, type);
  } else if (validationMessage.validationCode === "RequiredReportingFields") {
    return i18next.t(
      "If any of the following 3 fields is filled in, all 3 must be filled in: Column U (Taxable basis_reporting), Column T (Gross amount_reporting), Column V (VAT amount_reporting)"
    );
  } else if (validationMessage.validationCode === "WrongExchangeRate") {
    return getEnhancedMessage(i18next.t("validation-wrong-exchange-rate", "The calculated exchange rate seems to be wrong"), validationMessage, type, validationMessage.parameters[0]);
  } else if (validationMessage.validationCode === "Required") {
    if (type === "Vat") {
      return mapValidationMessageVatRequired(validationMessage, type);
    } else {
      return getEnhancedMessage(i18next.t("validation-data-cannot-be-empty", "Data cannot be empty"), validationMessage, type);
    }
  } else if (validationMessage.validationCode === "RequiredOrWrongFormat") {
    return mapRequiredOrWrongFormatalidationMessage(validationMessage, type);
  } else if (validationMessage.validationCode === "InvalidData") {
    if (type === "Vat") {
      return mapValidationMessageVatInvalidData(validationMessage, type);
    } else {
      return mapValidationMessageIossInvalidData(validationMessage, type);
    }
  }
  if (validationMessage.validationCode === "InvalidDecimal") {
    return getEnhancedMessage(
      i18next.t("validation-vat-decimalwrongformat", {
        rowNumber: validationMessage.rowNumber + 1
        // Rows are indexed from 1
      }),
      validationMessage,
      type,
      validationMessage.parameters[0]
    );
  } else if (validationMessage.validationCode === "UnsupportedCurrency") {
    return getEnhancedMessage(i18next.t("validation-unsupported-currency"), validationMessage, type, validationMessage.parameters[0]);
  } else if (validationMessage.validationCode === "ConstraintNotFulfilled") {
    if (validationMessage.columnName === "TaxableBasis" || validationMessage.columnName === "ReportingTaxableBasis") {
      return i18next.t(
        "validation-ioss-wrong-taxable-basis-calculation",
        `{{columnName}} (Column {{columnCode}}): Taxable basis = Gross amount - VAT amount ({{taxableBasis}} != {{grossAmount}} - {{vatAmount}}).`,
        {
          columnName: COLUMN_NAME_MAPPING[validationMessage.columnName],
          columnCode: validationMessage.columnCode,
          taxableBasis: validationMessage.parameters[0],
          grossAmount: validationMessage.parameters[1],
          vatAmount: validationMessage.parameters[2]
        }
      );
    } else if (validationMessage.columnName === "VatAmount" || validationMessage.columnName === "ReportingVatAmount") {
      return i18next.t(
        "validation-ioss-wrong-vat-calculation",
        `{{columnName}} (Column {{columnCode}}): VAT amount = Taxable basis * VAT rate ({{vatAmount}} != {{taxableBasis}} * {{vatRate}}).`,
        {
          columnName: COLUMN_NAME_MAPPING[validationMessage.columnName],
          columnCode: validationMessage.columnCode,
          vatAmount: validationMessage.parameters[0],
          taxableBasis: validationMessage.parameters[1],
          vatRate: validationMessage.parameters[2]
        }
      );
    } else {
      return getEnhancedMessage(i18next.t("validation-constraint-not-fulfilled", "Constraint not fulfilled"), validationMessage, type);
    }
  } else if (validationMessage.columnName === "TransactionDate" && validationMessage.validationCode === "TransactionIsBeforeService") {
    return getEnhancedMessage(
      i18next.t("validation-ioss-incorrect-transaction-date", "Incorrect transaction date. You cannot report transactions before 1. July 2021 via IOSS)"),
      validationMessage,
      type,
      formatDate(parseDate(validationMessage.parameters[0]))
    );
  } else if (validationMessage.columnName === "TransactionDate" && validationMessage.validationCode === "TransactionIsInTheFuture") {
    return i18next.t("Incorrect transaction period. Transaction period cannot be after the reporting period.");
  }
  return getEnhancedMessage(i18next.t("validation-invalid-data", "Invalid data"), validationMessage, type);
};
export const mapComplianceUpload = (complianceUpload) => {
  const sharedFiles = complianceUpload.Files;
  const filterForLatestVersion = (file) => {
    return (file == null ? void 0 : file.Version) === complianceUpload.CurrentFileVersion;
  };
  const iossFile = sharedFiles.some((f) => f instanceof WebIossFile || f instanceof AdminIossFile) ? sharedFiles.filter((f) => f instanceof WebIossFile || f instanceof AdminIossFile).find(filterForLatestVersion) : void 0;
  const kpmgTemplateFile = sharedFiles.some((f) => f instanceof WebKpmgTemplateFile || f instanceof AdminKpmgTemplateFile) ? sharedFiles.filter((f) => f instanceof WebKpmgTemplateFile || f instanceof AdminKpmgTemplateFile).find(filterForLatestVersion) : void 0;
  const amazonFiles = sharedFiles.some((f) => f instanceof WebAmazonFile || f instanceof AdminAmazonFile) ? sharedFiles.filter((f) => f instanceof WebAmazonFile || f instanceof AdminAmazonFile).filter((f) => filterForLatestVersion(f)) : void 0;
  const saftFile = sharedFiles.some((f) => f instanceof WebAmazonSaftFile || f instanceof AdminAmazonSaftFile) ? sharedFiles.filter((f) => f instanceof WebAmazonSaftFile || f instanceof AdminAmazonSaftFile).find(filterForLatestVersion) : void 0;
  const vm = {
    Id: complianceUpload.Id,
    ClientId: complianceUpload.ClientId,
    ClientName: complianceUpload.ClientName,
    Period: complianceUpload.Period,
    Status: complianceUpload.Status,
    Type: complianceUpload.Type,
    ErrorMessage: complianceUpload.ErrorMessage,
    ErrorReason: complianceUpload.ErrorReason,
    IsAmazonInvoicingServiceOptional: complianceUpload.IsAmazonInvoicingServiceOptional,
    IossFile: iossFile ? __spreadValues({}, iossFile) : void 0,
    SaftFile: saftFile ? __spreadValues({}, saftFile) : void 0,
    KpmgTemplateFile: kpmgTemplateFile ? __spreadValues({}, kpmgTemplateFile) : void 0,
    AmazonFiles: amazonFiles ? amazonFiles.map((a) => {
      return {
        Name: a.Name,
        Reference: a.Reference,
        Version: a.Version,
        SellerEntity: { Id: a.SellerEntity.Id, Name: a.SellerEntity.Name }
      };
    }) : void 0,
    CompanyConfigSnapshot: complianceUpload.CompanyConfigSnapshot ? {
      useAmazonTemplate: complianceUpload.CompanyConfigSnapshot.UseAmazonTemplate,
      useKpmgTemplate: complianceUpload.CompanyConfigSnapshot.UseKpmgTemplate,
      amazonConfiguration: complianceUpload.CompanyConfigSnapshot.AmazonConfiguration ? {
        sellerEntities: complianceUpload.CompanyConfigSnapshot.AmazonConfiguration.SellerEntities.map((se) => ({
          id: se.Id,
          name: se.Name,
          mwsAuthToken: se.MwsAuthToken,
          isEnabled: se.IsEnabled,
          isAutomated: se.IsAutomated
        })),
        useAmazonRates: complianceUpload.CompanyConfigSnapshot.AmazonConfiguration.UseAmazonRates,
        refundMode: complianceUpload.CompanyConfigSnapshot.AmazonConfiguration.RefundMode
      } : void 0
    } : void 0,
    IgnoreOverdue: complianceUpload.IgnoreOverdue,
    IsOverdue: complianceUpload.IsOverdue,
    CurrentFileVersion: complianceUpload.CurrentFileVersion
  };
  return vm;
};
