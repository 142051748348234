import { OssDetail } from "./../OssDetail/OssDetail";
import { Answer } from "./../Answer";
export class OssDetailAnswer extends Answer {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof OssDetailAnswer
     */
    this.$type = "V1.CompanyInfo.Questionnaire.Answers.OssDetailAnswer";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["Value"]) {
        const value_ = new OssDetail();
        this.Value = value_.init(_data["Value"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["Value"] = this.Value ? this.Value.toJSON() : void 0;
    return _data;
  }
}
