import { Money } from "./../../Common/Money";
import { ReturnInformation } from "./ReturnInformation";
export class PrimaryVatReturnInformation extends ReturnInformation {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PrimaryVatReturnInformation
     */
    this.$type = "V1.Submission.StatusChanges.PrimaryVatReturnInformation";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.PaymentCategory = PrimaryVatReturnInformationPaymentCategory[_data["PaymentCategory"]];
      if (_data["VatAmount"]) {
        const vatAmount_ = new Money();
        this.VatAmount = vatAmount_.init(_data["VatAmount"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["PaymentCategory"] = PrimaryVatReturnInformationPaymentCategory[this.PaymentCategory];
    _data["VatAmount"] = this.VatAmount ? this.VatAmount.toJSON() : void 0;
    return _data;
  }
}
export var PrimaryVatReturnInformationPaymentCategory = /* @__PURE__ */ ((PrimaryVatReturnInformationPaymentCategory2) => {
  PrimaryVatReturnInformationPaymentCategory2["Payable"] = "Payable";
  PrimaryVatReturnInformationPaymentCategory2["Reclaimable"] = "Reclaimable";
  PrimaryVatReturnInformationPaymentCategory2["Nil"] = "Nil";
  return PrimaryVatReturnInformationPaymentCategory2;
})(PrimaryVatReturnInformationPaymentCategory || {});
