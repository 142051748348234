import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as PDFHelper from "GlobalShared/helpers/pdfHelper";
import { ADDITIONAL_TAX_SERVICES, NUMBERED_TEXTS, UNORDERED_TEXTS } from "./laposte-pdf-data";
import { formatDate } from "GlobalShared/helpers/dateHelper";
import { colorLightGray, colorDarkGray, colorWhite, colorBlack, iossTermsAndConditions, privacyStatement, horizontalLine } from "../../../helpers/pdfHelper";
import { iossSection, ossSection, vatSection } from "./pdf-common";
const doc = new jsPDF({ unit: "mm" });
const sideMargin = 10;
const pageCenter = doc.internal.pageSize.width / 2;
const WWDoc = {
  doc,
  // Alignment Constants in [mm]
  sideMargin: 10,
  topMargin: 12,
  topMarginForText: 27,
  footerY: 282,
  pageWidth: doc.internal.pageSize.width,
  pageCenter: doc.internal.pageSize.width / 2,
  contentWidth: doc.internal.pageSize.width - 2 * sideMargin,
  // numbered textbox
  marginBetweenNumberedTextBoxes: 1,
  numberedTextBoxWidth: 85,
  rightNumberedX: pageCenter + 6,
  numberedTextBoxIndent: 6,
  // unordered textbox
  marginBetweenUnorderedTextBoxes: 0,
  unorderedTextBoxWidth: 82,
  leftUnorderedX: sideMargin + 5,
  rightUnorderedX: pageCenter + 10,
  unorderedTextBoxIndent: 5,
  // header image
  headerImageWidth: 15,
  headerImageHeight: 6
};
const header = (wwdoc) => {
  wwdoc.doc.addImage("/images/kpmg_logo.png", "PNG", wwdoc.sideMargin, wwdoc.topMargin, wwdoc.headerImageWidth, wwdoc.headerImageHeight);
};
const footer = (wwdoc, pageNumber) => {
  wwdoc.doc.setFontSize(9);
  wwdoc.doc.setFont("times", "normal");
  wwdoc.doc.text(`Page ${pageNumber}`, wwdoc.sideMargin, wwdoc.footerY);
  wwdoc.doc.text(`Indirect Tax Services`, wwdoc.pageWidth - wwdoc.sideMargin - 2, wwdoc.footerY, { align: "right" });
};
const firstPage = (wwdoc, pdfModel) => {
  header(wwdoc);
  wwdoc.doc.setFont("times", "bold");
  wwdoc.doc.setFontSize(11);
  PDFHelper.textCentered(wwdoc.doc, "Engagement contract for IOSS VAT Compliance Services and Additional Tax Services", wwdoc.topMarginForText);
  wwdoc.doc.setFontSize(9);
  const partnerY = 40;
  const marginBottom = 10;
  wwdoc.doc.text("Full legal name of the contracting party", wwdoc.sideMargin, partnerY);
  wwdoc.doc.text("Legal status of the contracting party", wwdoc.sideMargin, partnerY + marginBottom);
  wwdoc.doc.text("Registered address of the contracting party", wwdoc.sideMargin, partnerY + 2 * marginBottom);
  wwdoc.doc.setLineWidth(0.1);
  wwdoc.doc.text("VAT Number", wwdoc.sideMargin, partnerY + 3 * marginBottom);
  wwdoc.doc.text("Signature", wwdoc.sideMargin, partnerY + 4 * marginBottom);
  horizontalLine(wwdoc.sideMargin + 19, partnerY + 4 * marginBottom + 1, wwdoc.pageCenter - 10, wwdoc.doc);
  wwdoc.doc.text("Date", wwdoc.sideMargin, partnerY + 5 * marginBottom);
  wwdoc.doc.setFont("times", "normal");
  wwdoc.doc.text(pdfModel.contractDate ? formatDate(pdfModel.contractDate) : "", wwdoc.sideMargin + 40, partnerY + 5 * marginBottom);
  horizontalLine(wwdoc.sideMargin + 19, partnerY + 5 * marginBottom + 1, wwdoc.pageCenter - 10, wwdoc.doc);
  wwdoc.doc.setFont("times", "bold");
  wwdoc.doc.text("KPMG Reference", wwdoc.sideMargin, partnerY + 6 * marginBottom);
  wwdoc.doc.setFont("times", "normal");
  wwdoc.doc.text(pdfModel.kpmgReference !== void 0 ? pdfModel.kpmgReference : "", wwdoc.sideMargin + 40, partnerY + 6 * marginBottom);
  wwdoc.doc.setFont("times", "bold");
  horizontalLine(wwdoc.sideMargin + 30, partnerY + 6 * marginBottom + 1, wwdoc.pageCenter - 10, wwdoc.doc);
  wwdoc.doc.setFont("times", "normal");
  wwdoc.doc.text(pdfModel.partnerName ? pdfModel.partnerName : "", wwdoc.pageCenter, partnerY);
  wwdoc.doc.text(pdfModel.legalStatus ? pdfModel.legalStatus : "", wwdoc.pageCenter, partnerY + marginBottom);
  wwdoc.doc.text(pdfModel.addressLine ? pdfModel.addressLine : "", wwdoc.pageCenter, partnerY + 2 * marginBottom, {
    maxWidth: 80,
    lineHeightFactor: 1.2
  });
  wwdoc.doc.text(pdfModel.vatNumber ? pdfModel.vatNumber : "", wwdoc.pageCenter, partnerY + 3 * marginBottom);
  wwdoc.doc.setLineWidth(0.3);
  wwdoc.doc.setLineHeightFactor(16);
  horizontalLine(wwdoc.sideMargin, 107, wwdoc.pageWidth - wwdoc.sideMargin, wwdoc.doc);
  wwdoc.doc.setLineHeightFactor(1.4);
  PDFHelper.titleText(`General`, wwdoc.sideMargin, 113, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(0, 2), 119, wwdoc.sideMargin, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.appendixText("Appendix 1", 4, "(a).", wwdoc.sideMargin, 151, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[2].displayNumber, NUMBERED_TEXTS[2].text, wwdoc.sideMargin, 156, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.appendixText("Appendix 1", 4, "(b).", wwdoc.sideMargin + 19, 164, wwdoc.doc);
  PDFHelper.linkInFrontOfTextTextBox(
    NUMBERED_TEXTS[3].linkTitle,
    iossTermsAndConditions,
    NUMBERED_TEXTS[3].displayNumber,
    NUMBERED_TEXTS[3].firstLine,
    NUMBERED_TEXTS[3].text,
    wwdoc.sideMargin,
    170,
    wwdoc.numberedTextBoxWidth,
    wwdoc.doc
  );
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(4, 5), 184, wwdoc.sideMargin, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.titleText(`Our services`, wwdoc.sideMargin, 210, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[5].displayNumber, NUMBERED_TEXTS[5].text, wwdoc.sideMargin, 216, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(0, 2), 227, wwdoc.leftUnorderedX, wwdoc.unorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes + 5, wwdoc.doc);
  PDFHelper.linkWithBrackets("www.kpmg-vat-oss.com/gb/en/fr", wwdoc.leftUnorderedX + wwdoc.unorderedTextBoxIndent, 231, wwdoc.unorderedTextBoxWidth, wwdoc.doc);
  wwdoc.doc.text(
    `intermediary. If you already possess a IOSS number, we will link your IOSS number to the new VAT intermediary. KPMG is not responsible for any delay in processing your application by the tax authorities during the registration process`,
    wwdoc.rightUnorderedX + 5,
    115,
    { maxWidth: wwdoc.unorderedTextBoxWidth, align: "justify" }
  );
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(2, 7), 133, wwdoc.rightUnorderedX, wwdoc.unorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes, wwdoc.doc);
  PDFHelper.unorderedTextBox(UNORDERED_TEXTS[7], wwdoc.rightUnorderedX, 191, wwdoc.unorderedTextBoxWidth, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[6].displayNumber, NUMBERED_TEXTS[6].text, wwdoc.rightNumberedX, 215, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(8, 12), 235, wwdoc.rightUnorderedX, wwdoc.unorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes, wwdoc.doc);
  footer(wwdoc, 1);
};
const secondPage = (wwdoc) => {
  wwdoc.doc.addPage();
  header(wwdoc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(12, 14), wwdoc.topMarginForText, wwdoc.leftUnorderedX, wwdoc.unorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[7].displayNumber, NUMBERED_TEXTS[7].text, wwdoc.sideMargin, 48, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.titleText(`Your obligations`, wwdoc.sideMargin, 81, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(8, 14), 87, wwdoc.sideMargin, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(14, 18), wwdoc.topMarginForText, wwdoc.rightNumberedX, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.titleText(`Payment for services`, wwdoc.rightNumberedX, 108, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(18, 23), 114, wwdoc.rightNumberedX, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.titleText(`Termination`, wwdoc.rightNumberedX, 186, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[23].displayNumber, NUMBERED_TEXTS[23].text, wwdoc.rightNumberedX, 192, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(14, 18), 211, wwdoc.rightUnorderedX, wwdoc.unorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[24].displayNumber, NUMBERED_TEXTS[24].text, wwdoc.rightNumberedX, 261, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  footer(wwdoc, 2);
};
const thirdPage = (wwdoc) => {
  wwdoc.doc.addPage();
  header(wwdoc);
  wwdoc.doc.text(
    `required to carry out any further work following termination nor shall we be responsible or liable for any consequences arising from such termination.`,
    wwdoc.sideMargin + 6,
    wwdoc.topMarginForText,
    {
      align: "justify",
      maxWidth: wwdoc.numberedTextBoxWidth
    }
  );
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(25, 27), 42, wwdoc.sideMargin, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.titleText(`Limitation of liability`, wwdoc.sideMargin, 120, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[27].displayNumber, NUMBERED_TEXTS[27].text, wwdoc.sideMargin, 126, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  wwdoc.doc.text(
    `service period shall be limited, on the basis set out in Appendix 1a and 1b, to a maximum of 1,5 times the Annual Service Fee of the respective service.`,
    wwdoc.rightNumberedX + wwdoc.numberedTextBoxIndent,
    wwdoc.topMarginForText,
    {
      align: "justify",
      maxWidth: wwdoc.numberedTextBoxWidth
    }
  );
  PDFHelper.titleText(`Data Privacy`, wwdoc.rightNumberedX, 42, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[28].displayNumber, NUMBERED_TEXTS[28].text, wwdoc.rightNumberedX, 48, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.linkText("Privacy Statement", privacyStatement, wwdoc.rightNumberedX + 6, 66, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.titleText(`The Engagement Contract`, wwdoc.rightNumberedX, 73, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[29].displayNumber, NUMBERED_TEXTS[29].text, wwdoc.rightNumberedX, 80, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  footer(wwdoc, 3);
};
const fourthPage = (wwdoc, pdfModel) => {
  wwdoc.doc.addPage();
  header(wwdoc);
  wwdoc.doc.setFontSize(11);
  PDFHelper.titleText("Appendix 1", wwdoc.sideMargin, wwdoc.topMarginForText, wwdoc.doc);
  wwdoc.doc.setFontSize(9);
  wwdoc.doc.text(`KPMG will provide the following Core Tax Compliance Services and the Annual Service Fee will apply:`, wwdoc.sideMargin, wwdoc.topMarginForText + 7);
  const tableProps = {
    lineHeight: 6,
    tableLeftPadding: wwdoc.sideMargin + 40,
    eurChar: "EUR",
    secondCol: 80,
    paragraphMargin: 5
  };
  const hasIossService = pdfModel.iossTier !== void 0;
  const hasVatService = pdfModel.hasVatService;
  const hasEuOssVat = false;
  const tableSectionTop = wwdoc.topMarginForText + tableProps.paragraphMargin + tableProps.lineHeight * 2;
  const lineObj = {
    line: 0
  };
  const availableServices = [].concat(hasVatService ? [vatSection] : []).concat(hasEuOssVat ? [ossSection] : []).concat(hasIossService ? [iossSection] : []);
  availableServices.forEach((service, index) => {
    const sectionLetter = String.fromCharCode("a".charCodeAt(0) + index);
    const cumulativeTop = index > 0 ? tableSectionTop + lineObj.line * tableProps.lineHeight + 2 * tableProps.paragraphMargin : tableSectionTop + lineObj.line * tableProps.lineHeight;
    service(sectionLetter, lineObj, cumulativeTop, tableProps, wwdoc, pdfModel);
  });
  footer(wwdoc, 4);
};
const fifthPage = (wwdoc) => {
  wwdoc.doc.addPage();
  header(wwdoc);
  wwdoc.doc.setFontSize(11);
  PDFHelper.titleText("Appendix 2: Additional Tax Services", wwdoc.sideMargin, wwdoc.topMarginForText, wwdoc.doc);
  wwdoc.doc.setFontSize(9);
  wwdoc.doc.text(`The following Additional Tax Services may be provided upon your explicit request:`, wwdoc.sideMargin, wwdoc.topMarginForText + 7);
  autoTable(wwdoc.doc, {
    head: ADDITIONAL_TAX_SERVICES.header,
    body: ADDITIONAL_TAX_SERVICES.body,
    theme: "grid",
    startY: wwdoc.topMarginForText + 14,
    styles: {
      textColor: colorBlack,
      overflow: "linebreak",
      font: "times",
      fontSize: 9,
      lineColor: colorLightGray,
      valign: "middle",
      cellPadding: { top: 2, horizontal: 2, bottom: 3 }
    },
    headStyles: {
      fillColor: colorDarkGray,
      textColor: colorWhite
    },
    columnStyles: {
      0: { cellWidth: 45 },
      1: { cellWidth: 24 },
      2: { cellWidth: 28 }
    },
    didParseCell: (data) => {
      if (data.row.index === 1 || data.row.index === 5 || data.row.index === 6 || data.row.index === 7 || data.row.index === 19) {
        data.cell.styles.cellPadding = { top: 1, horizontal: 2, bottom: 7 };
      }
      if (data.row.index === 8 || data.row.index === 9) {
        if (data.column.index === 1) {
          data.cell.colSpan = 2;
        }
        if (data.column.index === 2) {
          data.cell.colSpan = 0;
        }
      }
    }
  });
  footer(wwdoc, 5);
};
export const generateLaPostePDF = (pdfModel) => {
  firstPage(WWDoc, pdfModel);
  secondPage(WWDoc);
  thirdPage(WWDoc);
  fourthPage(WWDoc, pdfModel);
  fifthPage(WWDoc);
  WWDoc.doc.save(`Contract_${pdfModel.partnerName}_${pdfModel.kpmgReference}.pdf`);
  return WWDoc.doc.output("blob");
};
