import { DateConverter } from "./../../../../converters/DateConverter";
import { File } from "./File";
export class SubmissionPeriod {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SubmissionPeriod
     */
    this.$type = "V1.Submission.Search.SubmissionPeriod";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientApprovalDate = DateConverter.from(_data["ClientApprovalDate"]);
      this.ClientApprovalDueDate = DateConverter.from(_data["ClientApprovalDueDate"]);
      this.EFiling = _data["EFiling"];
      this.EstablishmentCountryCode = _data["EstablishmentCountryCode"];
      this.FrequencyType = SubmissionPeriodFrequencyType[_data["FrequencyType"]];
      this.Id = _data["Id"];
      this.PaymentCategory = SubmissionPeriodPaymentCategory[_data["PaymentCategory"]];
      this.PaymentDueDate = DateConverter.from(_data["PaymentDueDate"]);
      if (_data["PaymentProof"]) {
        const paymentProof_ = new File();
        this.PaymentProof = paymentProof_.init(_data["PaymentProof"]);
      }
      this.PaymentStatus = SubmissionPeriodPaymentStatus[_data["PaymentStatus"]];
      this.PaymentUrl = _data["PaymentUrl"];
      this.Period = _data["Period"];
      this.PeriodStartDate = DateConverter.from(_data["PeriodStartDate"]);
      this.ReturnGenerationDate = DateConverter.from(_data["ReturnGenerationDate"]);
      this.ReturnGenerationDueDate = DateConverter.from(_data["ReturnGenerationDueDate"]);
      this.ServiceCountryCode = _data["ServiceCountryCode"];
      this.Status = SubmissionPeriodStatus[_data["Status"]];
      this.SubmissionDate = DateConverter.from(_data["SubmissionDate"]);
      this.SubmissionDueDate = DateConverter.from(_data["SubmissionDueDate"]);
      if (_data["SubmissionProof"]) {
        const submissionProof_ = new File();
        this.SubmissionProof = submissionProof_.init(_data["SubmissionProof"]);
      }
      if (_data["SubmissionProof2"]) {
        const submissionProof2_ = new File();
        this.SubmissionProof2 = submissionProof2_.init(_data["SubmissionProof2"]);
      }
      this.SubmissionProof2Date = DateConverter.from(_data["SubmissionProof2Date"]);
      this.SubmissionProofDate = DateConverter.from(_data["SubmissionProofDate"]);
      this.SubmittedAndPaidDate = DateConverter.from(_data["SubmittedAndPaidDate"]);
      this.TaxReturnType = SubmissionPeriodTaxReturnType[_data["TaxReturnType"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientApprovalDate"] = DateConverter.toUtcDateString(this.ClientApprovalDate);
    _data["ClientApprovalDueDate"] = DateConverter.toUtcDateString(this.ClientApprovalDueDate);
    _data["EFiling"] = this.EFiling;
    _data["EstablishmentCountryCode"] = this.EstablishmentCountryCode;
    _data["FrequencyType"] = SubmissionPeriodFrequencyType[this.FrequencyType];
    _data["Id"] = this.Id;
    _data["PaymentCategory"] = SubmissionPeriodPaymentCategory[this.PaymentCategory];
    _data["PaymentDueDate"] = DateConverter.toUtcDateString(this.PaymentDueDate);
    _data["PaymentProof"] = this.PaymentProof ? this.PaymentProof.toJSON() : void 0;
    _data["PaymentStatus"] = SubmissionPeriodPaymentStatus[this.PaymentStatus];
    _data["PaymentUrl"] = this.PaymentUrl;
    _data["Period"] = this.Period;
    _data["PeriodStartDate"] = DateConverter.toUtcDateString(this.PeriodStartDate);
    _data["ReturnGenerationDate"] = DateConverter.toUtcDateString(this.ReturnGenerationDate);
    _data["ReturnGenerationDueDate"] = DateConverter.toUtcDateString(this.ReturnGenerationDueDate);
    _data["ServiceCountryCode"] = this.ServiceCountryCode;
    _data["Status"] = SubmissionPeriodStatus[this.Status];
    _data["SubmissionDate"] = DateConverter.toUtcDateString(this.SubmissionDate);
    _data["SubmissionDueDate"] = DateConverter.toUtcDateString(this.SubmissionDueDate);
    _data["SubmissionProof"] = this.SubmissionProof ? this.SubmissionProof.toJSON() : void 0;
    _data["SubmissionProof2"] = this.SubmissionProof2 ? this.SubmissionProof2.toJSON() : void 0;
    _data["SubmissionProof2Date"] = DateConverter.toIsoUtcString(this.SubmissionProof2Date);
    _data["SubmissionProofDate"] = DateConverter.toIsoUtcString(this.SubmissionProofDate);
    _data["SubmittedAndPaidDate"] = DateConverter.toUtcDateString(this.SubmittedAndPaidDate);
    _data["TaxReturnType"] = SubmissionPeriodTaxReturnType[this.TaxReturnType];
    return _data;
  }
}
export var SubmissionPeriodFrequencyType = /* @__PURE__ */ ((SubmissionPeriodFrequencyType2) => {
  SubmissionPeriodFrequencyType2["Monthly"] = "Monthly";
  SubmissionPeriodFrequencyType2["Quarterly"] = "Quarterly";
  SubmissionPeriodFrequencyType2["QuarterlyShifted1"] = "QuarterlyShifted1";
  SubmissionPeriodFrequencyType2["QuarterlyShifted2"] = "QuarterlyShifted2";
  SubmissionPeriodFrequencyType2["BiMonthly"] = "BiMonthly";
  SubmissionPeriodFrequencyType2["FourMonthly"] = "FourMonthly";
  SubmissionPeriodFrequencyType2["SixMonthly"] = "SixMonthly";
  SubmissionPeriodFrequencyType2["Yearly"] = "Yearly";
  return SubmissionPeriodFrequencyType2;
})(SubmissionPeriodFrequencyType || {});
export var SubmissionPeriodPaymentCategory = /* @__PURE__ */ ((SubmissionPeriodPaymentCategory2) => {
  SubmissionPeriodPaymentCategory2["Payable"] = "Payable";
  SubmissionPeriodPaymentCategory2["Reclaimable"] = "Reclaimable";
  SubmissionPeriodPaymentCategory2["Nil"] = "Nil";
  return SubmissionPeriodPaymentCategory2;
})(SubmissionPeriodPaymentCategory || {});
export var SubmissionPeriodPaymentStatus = /* @__PURE__ */ ((SubmissionPeriodPaymentStatus2) => {
  SubmissionPeriodPaymentStatus2["Due"] = "Due";
  SubmissionPeriodPaymentStatus2["Paid"] = "Paid";
  SubmissionPeriodPaymentStatus2["PaidByClient"] = "PaidByClient";
  SubmissionPeriodPaymentStatus2["NoPaymentNeeded"] = "NoPaymentNeeded";
  return SubmissionPeriodPaymentStatus2;
})(SubmissionPeriodPaymentStatus || {});
export var SubmissionPeriodStatus = /* @__PURE__ */ ((SubmissionPeriodStatus2) => {
  SubmissionPeriodStatus2["New"] = "New";
  SubmissionPeriodStatus2["DataUploaded"] = "DataUploaded";
  SubmissionPeriodStatus2["ErrorReturnGen"] = "ErrorReturnGen";
  SubmissionPeriodStatus2["ReturnGenerated"] = "ReturnGenerated";
  SubmissionPeriodStatus2["ApprovedByClient"] = "ApprovedByClient";
  SubmissionPeriodStatus2["RefusedByClient"] = "RefusedByClient";
  SubmissionPeriodStatus2["ErrorAtTA"] = "ErrorAtTA";
  SubmissionPeriodStatus2["SentToTA"] = "SentToTA";
  SubmissionPeriodStatus2["Submitted"] = "Submitted";
  SubmissionPeriodStatus2["Rejected"] = "Rejected";
  SubmissionPeriodStatus2["SubmittedByClient"] = "SubmittedByClient";
  SubmissionPeriodStatus2["NotToBeSubmitted"] = "NotToBeSubmitted";
  return SubmissionPeriodStatus2;
})(SubmissionPeriodStatus || {});
export var SubmissionPeriodTaxReturnType = /* @__PURE__ */ ((SubmissionPeriodTaxReturnType2) => {
  SubmissionPeriodTaxReturnType2["PrimaryVAT"] = "PrimaryVAT";
  SubmissionPeriodTaxReturnType2["SecondaryVAT"] = "SecondaryVAT";
  SubmissionPeriodTaxReturnType2["ESL"] = "ESL";
  SubmissionPeriodTaxReturnType2["VLS"] = "VLS";
  SubmissionPeriodTaxReturnType2["IOSS"] = "IOSS";
  SubmissionPeriodTaxReturnType2["Intrastat"] = "Intrastat";
  SubmissionPeriodTaxReturnType2["OSS"] = "OSS";
  return SubmissionPeriodTaxReturnType2;
})(SubmissionPeriodTaxReturnType || {});
