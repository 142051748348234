export var VatRegFilterClientSource = /* @__PURE__ */ ((VatRegFilterClientSource2) => {
  VatRegFilterClientSource2["Amazon"] = "Amazon";
  VatRegFilterClientSource2["KPMG"] = "KPMG";
  VatRegFilterClientSource2["FedEx"] = "FedEx";
  VatRegFilterClientSource2["LaPoste"] = "LaPoste";
  return VatRegFilterClientSource2;
})(VatRegFilterClientSource || {});
export var VatRegFilterVatRegistrationStatuses = /* @__PURE__ */ ((VatRegFilterVatRegistrationStatuses2) => {
  VatRegFilterVatRegistrationStatuses2["Pending"] = "Pending";
  VatRegFilterVatRegistrationStatuses2["WaitingForSignature"] = "WaitingForSignature";
  VatRegFilterVatRegistrationStatuses2["DocumentsSigned"] = "DocumentsSigned";
  VatRegFilterVatRegistrationStatuses2["TranslationInProgress"] = "TranslationInProgress";
  VatRegFilterVatRegistrationStatuses2["SentToTA"] = "SentToTA";
  VatRegFilterVatRegistrationStatuses2["LocalTaxReceived"] = "LocalTaxReceived";
  VatRegFilterVatRegistrationStatuses2["RegistrationCompleted"] = "RegistrationCompleted";
  VatRegFilterVatRegistrationStatuses2["Deregistered"] = "Deregistered";
  VatRegFilterVatRegistrationStatuses2["DeregistrationInProgress"] = "DeregistrationInProgress";
  VatRegFilterVatRegistrationStatuses2["PoARevoked"] = "PoARevoked";
  VatRegFilterVatRegistrationStatuses2["RevokingPoA"] = "RevokingPoA";
  return VatRegFilterVatRegistrationStatuses2;
})(VatRegFilterVatRegistrationStatuses || {});
export var VatRegFilterRegType = /* @__PURE__ */ ((VatRegFilterRegType2) => {
  VatRegFilterRegType2["NewReg"] = "NewReg";
  VatRegFilterRegType2["TakOver"] = "TakOver";
  return VatRegFilterRegType2;
})(VatRegFilterRegType || {});
export var VatRegFilterCompanyStatus = /* @__PURE__ */ ((VatRegFilterCompanyStatus2) => {
  VatRegFilterCompanyStatus2["Active"] = "Active";
  VatRegFilterCompanyStatus2["Rejected"] = "Rejected";
  VatRegFilterCompanyStatus2["Inactive"] = "Inactive";
  return VatRegFilterCompanyStatus2;
})(VatRegFilterCompanyStatus || {});
export var VatRegFilterSubscriptionStatus = /* @__PURE__ */ ((VatRegFilterSubscriptionStatus2) => {
  VatRegFilterSubscriptionStatus2["Active"] = "Active";
  VatRegFilterSubscriptionStatus2["Cancelled"] = "Cancelled";
  VatRegFilterSubscriptionStatus2["Expired"] = "Expired";
  VatRegFilterSubscriptionStatus2["Pending"] = "Pending";
  return VatRegFilterSubscriptionStatus2;
})(VatRegFilterSubscriptionStatus || {});
