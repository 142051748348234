import { DateConverter } from "./../../../../converters/DateConverter";
export class ComplianceUploadPeriodFilter {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof ComplianceUploadPeriodFilter
     */
    this.$type = "V1.ComplianceUploads.Search.ComplianceUploadPeriodFilter";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.FrequencyType = ComplianceUploadPeriodFilterFrequencyType[_data["FrequencyType"]];
      this.StartDate = DateConverter.from(_data["StartDate"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["FrequencyType"] = ComplianceUploadPeriodFilterFrequencyType[this.FrequencyType];
    _data["StartDate"] = DateConverter.toIsoUtcString(this.StartDate);
    return _data;
  }
}
export var ComplianceUploadPeriodFilterFrequencyType = /* @__PURE__ */ ((ComplianceUploadPeriodFilterFrequencyType2) => {
  ComplianceUploadPeriodFilterFrequencyType2["Monthly"] = "Monthly";
  ComplianceUploadPeriodFilterFrequencyType2["Quarterly"] = "Quarterly";
  ComplianceUploadPeriodFilterFrequencyType2["QuarterlyShifted1"] = "QuarterlyShifted1";
  ComplianceUploadPeriodFilterFrequencyType2["QuarterlyShifted2"] = "QuarterlyShifted2";
  ComplianceUploadPeriodFilterFrequencyType2["BiMonthly"] = "BiMonthly";
  ComplianceUploadPeriodFilterFrequencyType2["FourMonthly"] = "FourMonthly";
  ComplianceUploadPeriodFilterFrequencyType2["SixMonthly"] = "SixMonthly";
  ComplianceUploadPeriodFilterFrequencyType2["Yearly"] = "Yearly";
  return ComplianceUploadPeriodFilterFrequencyType2;
})(ComplianceUploadPeriodFilterFrequencyType || {});
