export var FilterClientSource = /* @__PURE__ */ ((FilterClientSource2) => {
  FilterClientSource2["Amazon"] = "Amazon";
  FilterClientSource2["KPMG"] = "KPMG";
  FilterClientSource2["FedEx"] = "FedEx";
  FilterClientSource2["LaPoste"] = "LaPoste";
  return FilterClientSource2;
})(FilterClientSource || {});
export var FilterStatus = /* @__PURE__ */ ((FilterStatus2) => {
  FilterStatus2["Active"] = "Active";
  FilterStatus2["Rejected"] = "Rejected";
  FilterStatus2["Inactive"] = "Inactive";
  return FilterStatus2;
})(FilterStatus || {});
export var FilterQuestionnaireStatus = /* @__PURE__ */ ((FilterQuestionnaireStatus2) => {
  FilterQuestionnaireStatus2["Incomplete"] = "Incomplete";
  FilterQuestionnaireStatus2["Completed"] = "Completed";
  FilterQuestionnaireStatus2["Approved"] = "Approved";
  FilterQuestionnaireStatus2["DataVerified"] = "DataVerified";
  return FilterQuestionnaireStatus2;
})(FilterQuestionnaireStatus || {});
