var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { TurnOversAnswer as WebTurnOversAnswer, TurnOver as WebTurnOver, TurnOver } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { TurnOversAnswer as AdminTurnOversAnswer, TurnOver as AdminTurnOver } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { TurnOversValidator, TurnOverValidator } from "./AnswerValidators";
import { newUniqueId } from "GlobalShared/helpers/stringHelper";
import i18next from "i18next";
import { Money as AdminMoney } from "AdminServices/APIs/self/models/V1/Common";
import { Money, Money as WebMoney } from "WebServices/APIs/self/models/V1/Common";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-turnovers";
const DEFAULT_CURRENCY = "EUR";
const DEFAULT_EXISTING_VAT_NUMBER = {
  Id: void 0,
  Amount: void 0,
  Year: void 0
};
export class TurnOversChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId
  };
  const getAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminTurnOversAnswer({
        Value: turnOvers.map(
          (turnOver) => new AdminTurnOver({
            Amount: new AdminMoney({ Value: turnOver.Amount, Currency: DEFAULT_CURRENCY }),
            Year: turnOver.Year
          })
        ),
        QuestionId: props.questionId
      });
    }
    return new WebTurnOversAnswer({
      Value: turnOvers.map(
        (turnOver) => new WebTurnOver({
          Amount: new WebMoney({ Value: turnOver.Amount, Currency: DEFAULT_CURRENCY }),
          Year: turnOver.Year
        })
      ),
      QuestionId: props.questionId
    });
  };
  const edit = (id) => {
    const turnOver = turnOvers.find((evn) => evn.Id === id);
    turnOverValidationContext.clearFieldValidations();
    setCurrentTurnOver(turnOver);
  };
  const remove = (id) => {
    setDispatchIfValid(true);
    setTurnOvers(turnOvers.filter((turnOver) => turnOver.Id !== id));
  };
  const save = () => {
    if (turnOverValidationContext.validationResult.isValid()) {
      setDispatchIfValid(true);
      if (currentTurnOver.Id === void 0) {
        currentTurnOver.Id = newUniqueId();
        setTurnOvers([...turnOvers, currentTurnOver]);
      } else {
        setTurnOvers(turnOvers.map((turnOver) => turnOver.Id === currentTurnOver.Id ? currentTurnOver : turnOver));
      }
      setCurrentTurnOver(void 0);
    } else {
      turnOverValidationContext.validateFields(["amount", "year"]);
      turnOverValidationContext.validate(
        new TurnOver({
          Year: currentTurnOver == null ? void 0 : currentTurnOver.Year,
          Amount: new Money({
            Currency: DEFAULT_CURRENCY,
            Value: currentTurnOver == null ? void 0 : currentTurnOver.Amount
          })
        })
      );
      setCurrentTurnOver(__spreadValues({}, currentTurnOver));
    }
  };
  const cancel = () => {
    setCurrentTurnOver(void 0);
  };
  const startAdding = () => {
    turnOverValidationContext.clearFieldValidations();
    setCurrentTurnOver(DEFAULT_EXISTING_VAT_NUMBER);
  };
  const [turnOvers, setTurnOvers] = useState([]);
  const [currentTurnOver, setCurrentTurnOver] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new TurnOversValidator());
  const turnOverValidationContext = useValidationContext(new TurnOverValidator());
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new TurnOversChangeEvent({
          answer: getAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(getAnswer());
  }, [turnOvers]);
  useEffect(() => {
    turnOverValidationContext.validate(
      new TurnOver({
        Year: currentTurnOver == null ? void 0 : currentTurnOver.Year,
        Amount: new Money({
          Currency: DEFAULT_CURRENCY,
          Value: currentTurnOver == null ? void 0 : currentTurnOver.Amount
        })
      })
    );
  }, [currentTurnOver]);
  useEffect(() => {
    if (props.q11e && props.questionId) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof WebTurnOversAnswer || answer instanceof AdminTurnOversAnswer) {
        setTurnOvers(
          answer.Value.map((turnOver) => ({
            Id: newUniqueId(),
            Amount: turnOver.Amount.Value,
            Year: turnOver.Year
          }))
        );
      }
    }
  }, [props.q11e, props.questionId]);
  const templateTurnOver = () => {
    return html`<div>
			<div class="grid md:grid-cols-2 gap-8 mt-4">
				<dc-input
					.label=${`${i18next.t("Year")}*`}
					.type=${"number"}
					.min=${1970}
					.max=${(/* @__PURE__ */ new Date()).getFullYear()}
					.value=${currentTurnOver == null ? void 0 : currentTurnOver.Year}
					.readonly=${props.q11e.isReadonly()}
					.validationMessage=${turnOverValidationContext.getValidationMessage("year")}
					@change=${(e) => {
      const tempVal = __spreadProps(__spreadValues({}, currentTurnOver), {
        Year: toSafeNumber(e.detail.value)
      });
      turnOverValidationContext.validateField("year");
      setCurrentTurnOver(tempVal);
    }}
				></dc-input>
				<div>
					<label class="form-label">${i18next.t("Amount")}*</label>
					<div class="flex">
						<dc-input
							class="w-full"
							.type=${"number"}
							.min=${0}
							.readonly=${props.q11e.isReadonly()}
							.value=${currentTurnOver.Amount}
							.validationMessage=${turnOverValidationContext.getValidationMessage("amount")}
							@change=${(e) => {
      const tempVal = __spreadProps(__spreadValues({}, currentTurnOver), {
        Amount: toSafeNumber(e.detail.value)
      });
      turnOverValidationContext.validateField("amount");
      setCurrentTurnOver(tempVal);
    }}
						></dc-input>
						<dc-input class="w-20" .readonly=${true} .value=${DEFAULT_CURRENCY}></dc-input>
					</div>
				</div>
			</div>
			<div class="mt-8">
				<button class="btn btn-primary mr-4" @click=${cancel}>${i18next.t("Cancel")}</button>
				${props.q11e.isReadonly() ? "" : html`<button class="btn btn-primary mr-4" @click=${save}>${i18next.t("Save")}</button>`}
			</div>
		</div>`;
  };
  return html`
		${props.q11e !== void 0 && question !== void 0 ? html`<div>
						${turnOvers.length > 0 ? turnOvers.map(
    (turnOver) => html`<div class="flex justify-between ">
												<div>${html` <div class="flex cursor-pointer" @click=${() => edit(turnOver.Id)}>${`${turnOver.Amount} EUR - ${turnOver.Year}`}</div> `}</div>
												${props.q11e.isReadonly() ? "" : html` <div>
															<button class="btn btn-secondary" @click=${() => remove(turnOver.Id)}>${i18next.t("Remove")}</button>
													  </div>`}
											</div>
											<hr />`
  ) : html`${currentTurnOver === void 0 ? html`<div class="italic mb-4 mt-4">
											${`${i18next.t("Please indicate the net value of the distance sales in Italy for each year.")} ${(turnOvers == null ? void 0 : turnOvers.length) === 0 ? i18next.t("No turnover has been added yet.") : ""}`}
									  </div>` : ""}`}
					</div>
					<div class="mt-8">
						${currentTurnOver !== void 0 ? templateTurnOver() : props.q11e.isReadonly() ? "" : html`<button class="btn btn-primary" @click=${startAdding}>${i18next.t("Add new turnover")}</button>`}
					</div>` : ""}
	`;
};
