import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { OssDetailValidator } from "./AnswerValidators";
import { OssDetailAnswer as WebOssDetailAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { OssDetailAnswer as AdminOssDetailAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { OssDetail as AdminOssDetail } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/OssDetail";
import { OssDetail as WebOssDetail } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/OssDetail";
import { checkAnswerDate } from "./answer-helpers";
export const observedAttributes = ["q11e", "questionId", "euCountries"];
export const useShadowDOM = false;
export const name = "ww-answer-oss-details";
export class OssDetailChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    euCountries: host.euCountries
  };
  const getLabel = (fieldName, required = false) => {
    var _a;
    return ((_a = getSubQuestion(fieldName)) == null ? void 0 : _a.Label) + (required ? "*" : "");
  };
  const getSubQuestion = (questionId) => {
    const filteredSubQuestions = question.SubQuestions.filter((subQuestion) => subQuestion.Id === questionId);
    if (filteredSubQuestions.length === 1) {
      return filteredSubQuestions[0];
    } else {
      return void 0;
    }
  };
  const getAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminOssDetailAnswer({
        Value: new AdminOssDetail({
          RegistrationCountry: registrationCountry,
          RegistrationDate: registrationDate,
          RegistrationNumber: registrationNumber
        }),
        QuestionId: props.questionId
      });
    }
    return new WebOssDetailAnswer({
      Value: new WebOssDetail({
        RegistrationCountry: registrationCountry,
        RegistrationDate: registrationDate,
        RegistrationNumber: registrationNumber
      }),
      QuestionId: props.questionId
    });
  };
  const [registrationCountry, setRegistrationCountry] = useState(void 0);
  const [registrationDate, setRegistrationDate] = useState(void 0);
  const [registrationNumber, setRegistrationNumber] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new OssDetailValidator());
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof AdminOssDetailAnswer || answer instanceof WebOssDetailAnswer) {
        setRegistrationCountry(answer.Value.RegistrationCountry);
        setRegistrationDate(answer.Value.RegistrationDate);
        setRegistrationNumber(answer.Value.RegistrationNumber);
      }
    }
  }, [props.q11e, props.questionId]);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new OssDetailChangeEvent({
          answer: getAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(getAnswer());
  }, [registrationCountry, registrationDate, registrationNumber]);
  return html`
		${props.q11e !== void 0 && question !== void 0 ? html`<div class="grid md:grid-cols-2 gap-4 mt-4">
					<dc-select
						.label=${getLabel("registrationCountry")}
						.selectedValues=${[registrationCountry]}
						.dataSource=${props.euCountries.map((country) => ({
    label: country.Name,
    value: country.Code
  }))}
						.filterable=${true}
						.readonly=${props.q11e.isReadonly()}
						.validationMessage=${validationContext.getValidationMessage("registrationCountry")}
						@change=${(e) => {
    setRegistrationCountry(e.detail.selectedValue);
    setDispatchIfValid(true);
    validationContext.validateField("registrationCountry");
  }}
					></dc-select>
					<dc-input
						.readonly=${props.q11e.isReadonly()}
						.label=${getLabel("registrationNumber", true)}
						.value=${registrationNumber}
						.validationMessage=${validationContext.getValidationMessage("registrationNumber")}
						@change=${(e) => {
    setRegistrationNumber(e.detail.value);
    setDispatchIfValid(true);
    validationContext.validateField("registrationNumber");
  }}
					></dc-input>
					<div class="grid md:grid-cols-2 gap-4 mt-4">
						<dc-datepicker
							.readonly=${props.q11e.isReadonly(props.questionId)}
							.label=${getLabel("registrationDate", true)}
							.value=${registrationDate}
							.validationMessage=${validationContext.getValidationMessage("registrationDate")}
							@change=${(e) => {
    if (checkAnswerDate(e.detail.value)) {
      setRegistrationDate(e.detail.value);
      setDispatchIfValid(true);
      validationContext.validateField("registrationDate");
    }
  }}
						></dc-datepicker>
					</div>
			  </div>` : ""}
	`;
};
