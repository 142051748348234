export const VatRateTypeFieldNames = {
  Country: "Country",
  RateType: "RateType",
  RateValue: "RateValue",
  ValidFrom: "ValidFrom"
};
export const mapVatRateType = (vatRateType) => {
  return {
    country: vatRateType.CountryCode,
    rate: vatRateType.Rate,
    vatRateTypeType: vatRateType.Type,
    validFrom: vatRateType.ValidFrom
  };
};
