import { Address } from "AdminServices/APIs/self/models/V1/Common";
import { Name as PersonName } from "AdminServices/APIs/self/models/V1/Common/Person";
import { NameTitle } from "AdminServices/APIs/self/models/V1/Common/Person/NameAbstraction";
import { MigrationCompany, MigrationSellerAccount, MigrationSubscriptions, MigrationVat } from "AdminServices/APIs/self/models/V1/Migration";
import { MigrationCompanyClientAcceptance, MigrationCompanyLegalStatus } from "AdminServices/APIs/self/models/V1/Migration/MigrationCompanyAbstraction";
import {
  MigrationSubscriptionsEslFrequency,
  MigrationSubscriptionsIntrastatFrequency,
  MigrationSubscriptionsPrimaryFrequency,
  MigrationSubscriptionsRegistrationStatus,
  MigrationSubscriptionsSecondaryFrequency,
  MigrationSubscriptionsVlsFrequency
} from "AdminServices/APIs/self/models/V1/Migration/MigrationSubscriptionsAbstraction";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
import moment from "moment";
import {
  ClientAcceptanceTypes,
  CompanyLegalStatusTypes,
  ExtendedFrequencyTypeMap,
  FrequencyTypes,
  NameTitleTypes,
  RegistrationStatusTypes
} from "./MigrationModels";
import { getWebLanguageByCountry } from "GlobalShared/helpers/webLocaleHelper";
var CompanyCsvHeaders = /* @__PURE__ */ ((CompanyCsvHeaders2) => {
  CompanyCsvHeaders2[CompanyCsvHeaders2["PortalId"] = 0] = "PortalId";
  CompanyCsvHeaders2[CompanyCsvHeaders2["Name"] = 1] = "Name";
  CompanyCsvHeaders2[CompanyCsvHeaders2["LegalStatus"] = 2] = "LegalStatus";
  CompanyCsvHeaders2[CompanyCsvHeaders2["DOB"] = 3] = "DOB";
  CompanyCsvHeaders2[CompanyCsvHeaders2["HomeCountry"] = 4] = "HomeCountry";
  CompanyCsvHeaders2[CompanyCsvHeaders2["CountryCode"] = 5] = "CountryCode";
  CompanyCsvHeaders2[CompanyCsvHeaders2["AddressCity"] = 6] = "AddressCity";
  CompanyCsvHeaders2[CompanyCsvHeaders2["AddressStreet"] = 7] = "AddressStreet";
  CompanyCsvHeaders2[CompanyCsvHeaders2["AddressZip"] = 8] = "AddressZip";
  CompanyCsvHeaders2[CompanyCsvHeaders2["AddressStreetNumber"] = 9] = "AddressStreetNumber";
  CompanyCsvHeaders2[CompanyCsvHeaders2["VatNumber"] = 10] = "VatNumber";
  CompanyCsvHeaders2[CompanyCsvHeaders2["ContactLanguage"] = 11] = "ContactLanguage";
  CompanyCsvHeaders2[CompanyCsvHeaders2["ContactEmail"] = 12] = "ContactEmail";
  CompanyCsvHeaders2[CompanyCsvHeaders2["ContactTitle"] = 13] = "ContactTitle";
  CompanyCsvHeaders2[CompanyCsvHeaders2["ContactFirstName"] = 14] = "ContactFirstName";
  CompanyCsvHeaders2[CompanyCsvHeaders2["ContactLastName"] = 15] = "ContactLastName";
  CompanyCsvHeaders2[CompanyCsvHeaders2["GisNumber"] = 16] = "GisNumber";
  CompanyCsvHeaders2[CompanyCsvHeaders2["EmsNumber"] = 17] = "EmsNumber";
  CompanyCsvHeaders2[CompanyCsvHeaders2["ClientAcceptance"] = 18] = "ClientAcceptance";
  CompanyCsvHeaders2[CompanyCsvHeaders2["SentinelNumber"] = 19] = "SentinelNumber";
  CompanyCsvHeaders2[CompanyCsvHeaders2["SentinelValidTo"] = 20] = "SentinelValidTo";
  CompanyCsvHeaders2[CompanyCsvHeaders2["ClientHasOSSReg"] = 21] = "ClientHasOSSReg";
  CompanyCsvHeaders2[CompanyCsvHeaders2["OSSCountryCode"] = 22] = "OSSCountryCode";
  CompanyCsvHeaders2[CompanyCsvHeaders2["OSSNumber"] = 23] = "OSSNumber";
  CompanyCsvHeaders2[CompanyCsvHeaders2["OSSDateFrom"] = 24] = "OSSDateFrom";
  return CompanyCsvHeaders2;
})(CompanyCsvHeaders || {});
var SubscriptionCsvHeaders = /* @__PURE__ */ ((SubscriptionCsvHeaders2) => {
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["PortalId"] = 0] = "PortalId";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["Name"] = 1] = "Name";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["ServiceCountry"] = 2] = "ServiceCountry";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["Countrycode"] = 3] = "Countrycode";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["LocalVatNumber"] = 4] = "LocalVatNumber";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["LocalVatStartDate"] = 5] = "LocalVatStartDate";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["EuVatNumber"] = 6] = "EuVatNumber";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["EuVatStartDate"] = 7] = "EuVatStartDate";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["PrimaryFrequency"] = 8] = "PrimaryFrequency";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["PrimaryFrequencyEFiling"] = 9] = "PrimaryFrequencyEFiling";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["PrimaryFrequencyFilingExtension"] = 10] = "PrimaryFrequencyFilingExtension";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["SecondaryFrequency"] = 11] = "SecondaryFrequency";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["SecondaryFrequencyEFiling"] = 12] = "SecondaryFrequencyEFiling";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["SecondaryFrequencyFilingExtension"] = 13] = "SecondaryFrequencyFilingExtension";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["EslFrequency"] = 14] = "EslFrequency";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["VlsFrequency"] = 15] = "VlsFrequency";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["IbanOrLink"] = 16] = "IbanOrLink";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["FirstMonthOfGeneration"] = 17] = "FirstMonthOfGeneration";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["SubscriptionValidTo"] = 18] = "SubscriptionValidTo";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["RegistrationStatus"] = 19] = "RegistrationStatus";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["FiscRep"] = 20] = "FiscRep";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["GermanFilingAgent"] = 21] = "GermanFilingAgent";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["EsVatPayment"] = 22] = "EsVatPayment";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["IntrastatIn"] = 23] = "IntrastatIn";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["IntrastatOut"] = 24] = "IntrastatOut";
  SubscriptionCsvHeaders2[SubscriptionCsvHeaders2["IntrastatFrequency"] = 25] = "IntrastatFrequency";
  return SubscriptionCsvHeaders2;
})(SubscriptionCsvHeaders || {});
var VatNumberCsvHeader = /* @__PURE__ */ ((VatNumberCsvHeader2) => {
  VatNumberCsvHeader2[VatNumberCsvHeader2["PortalId"] = 0] = "PortalId";
  VatNumberCsvHeader2[VatNumberCsvHeader2["Name"] = 1] = "Name";
  VatNumberCsvHeader2[VatNumberCsvHeader2["Country"] = 2] = "Country";
  VatNumberCsvHeader2[VatNumberCsvHeader2["VatNumber"] = 3] = "VatNumber";
  VatNumberCsvHeader2[VatNumberCsvHeader2["ValidFromDate"] = 4] = "ValidFromDate";
  VatNumberCsvHeader2[VatNumberCsvHeader2["ValidEndDate"] = 5] = "ValidEndDate";
  return VatNumberCsvHeader2;
})(VatNumberCsvHeader || {});
var SelleraccountCsvHeader = /* @__PURE__ */ ((SelleraccountCsvHeader2) => {
  SelleraccountCsvHeader2[SelleraccountCsvHeader2["PortalId"] = 0] = "PortalId";
  SelleraccountCsvHeader2[SelleraccountCsvHeader2["Name"] = 1] = "Name";
  SelleraccountCsvHeader2[SelleraccountCsvHeader2["SellerAccountName"] = 2] = "SellerAccountName";
  SelleraccountCsvHeader2[SelleraccountCsvHeader2["SellerAccountIdentifier"] = 3] = "SellerAccountIdentifier";
  SelleraccountCsvHeader2[SelleraccountCsvHeader2["Mwstoken"] = 4] = "Mwstoken";
  return SelleraccountCsvHeader2;
})(SelleraccountCsvHeader || {});
export function mapCompanyRowToDto(row) {
  return {
    PortalId: parseNumber(row[0 /* PortalId */]),
    Name: parseString(row[1 /* Name */]),
    LegalStatus: parseEnum(row[2 /* LegalStatus */], CompanyLegalStatusTypes),
    DOB: parseDate(row[3 /* DOB */]),
    HomeCountry: parseString(row[4 /* HomeCountry */]),
    CountryCode: parseString(row[5 /* CountryCode */]),
    AddressCity: parseString(row[6 /* AddressCity */]),
    AddressStreet: parseString(row[7 /* AddressStreet */]),
    AddressZip: parseString(row[8 /* AddressZip */]),
    AddressStreetNumber: parseString(row[9 /* AddressStreetNumber */]),
    VatNumber: parseString(row[10 /* VatNumber */]),
    ContactLanguage: parseContactLanguage(row[11 /* ContactLanguage */]),
    ContactEmail: parseString(row[12 /* ContactEmail */]),
    ContactTitle: parseEnum(row[13 /* ContactTitle */], NameTitleTypes),
    ContactFirstName: parseString(row[14 /* ContactFirstName */]),
    ContactLastName: parseString(row[15 /* ContactLastName */]),
    GisNumber: parseString(row[16 /* GisNumber */]),
    EmsNumber: parseString(row[17 /* EmsNumber */]),
    ClientAcceptance: parseEnum(row[18 /* ClientAcceptance */], ClientAcceptanceTypes),
    SentinelNumber: parseString(row[19 /* SentinelNumber */]),
    SentinelValidTo: parseDate(row[20 /* SentinelValidTo */]),
    ClientHasOSSReg: parseBoolean(row[21 /* ClientHasOSSReg */]),
    OSSCountryCode: parseString(row[22 /* OSSCountryCode */]),
    OSSNumber: parseString(row[23 /* OSSNumber */]),
    OSSDateFrom: parseDate(row[24 /* OSSDateFrom */])
  };
}
export function mapSubscriptionRowToDto(row) {
  return {
    CountryCode: parseString(row[3 /* Countrycode */]),
    EslFrequency: parseEnum(row[14 /* EslFrequency */], FrequencyTypes),
    EsVatPayment: parseBoolean(row[22 /* EsVatPayment */]),
    EuVatNumber: parseString(row[6 /* EuVatNumber */]),
    EuVatStartDate: parseDate(row[7 /* EuVatStartDate */]),
    FirstMonthOfGeneration: parseDate(row[17 /* FirstMonthOfGeneration */]),
    FiscRep: parseBoolean(row[20 /* FiscRep */]),
    GermanFilingAgent: parseBoolean(row[21 /* GermanFilingAgent */]),
    IbanOrLink: parseString(row[16 /* IbanOrLink */]),
    IntrastatFrequency: parseEnum(row[25 /* IntrastatFrequency */], FrequencyTypes),
    IntrastatIn: parseBoolean(row[23 /* IntrastatIn */]),
    IntrastatOut: parseBoolean(row[24 /* IntrastatOut */]),
    LocalVatNumber: parseString(row[4 /* LocalVatNumber */]),
    LocalVatStartDate: parseDate(row[5 /* LocalVatStartDate */]),
    Name: parseString(row[1 /* Name */]),
    PortalId: parseNumber(row[0 /* PortalId */]),
    PrimaryFrequency: parsePrimaryFrequencyTypeEnum(row[8 /* PrimaryFrequency */]),
    PrimaryFrequencyEFiling: ensureDefault(parseBoolean(row[9 /* PrimaryFrequencyEFiling */]), true),
    PrimaryFrequencyFilingExtension: ensureDefault(parseBoolean(row[10 /* PrimaryFrequencyFilingExtension */]), false),
    RegistrationStatus: parseEnum(row[19 /* RegistrationStatus */], RegistrationStatusTypes),
    SecondaryFrequency: parseEnum(row[11 /* SecondaryFrequency */], FrequencyTypes),
    SecondaryFrequencyEFiling: ensureDefault(parseBoolean(row[12 /* SecondaryFrequencyEFiling */]), true),
    SecondaryFrequencyFilingExtension: ensureDefault(parseBoolean(row[13 /* SecondaryFrequencyFilingExtension */]), false),
    ServiceCountry: parseString(row[2 /* ServiceCountry */]),
    SubscriptionValidTo: parseDate(row[18 /* SubscriptionValidTo */]),
    VlsFrequency: parseEnum(row[15 /* VlsFrequency */], FrequencyTypes)
  };
}
export function mapVatNumberRowToDto(row) {
  return {
    Country: parseString(row[2 /* Country */]),
    Name: parseString(row[1 /* Name */]),
    PortalId: parseNumber(row[0 /* PortalId */]),
    ValidEndDate: parseDate(row[5 /* ValidEndDate */]),
    ValidFromDate: parseDate(row[4 /* ValidFromDate */]),
    VatNumber: parseString(row[3 /* VatNumber */])
  };
}
export function mapSellerAccountRowToDto(row) {
  return {
    Mwstoken: parseString(row[4 /* Mwstoken */]),
    Name: parseString(row[1 /* Name */]),
    PortalId: parseNumber(row[0 /* PortalId */]),
    SellerAccountIdentifier: parseString(row[3 /* SellerAccountIdentifier */]),
    SellerAccountName: parseString(row[2 /* SellerAccountName */])
  };
}
export function mapCompanyRowToMigrateCompanyRequest(row) {
  var _a, _b, _c;
  return new MigrationCompany({
    PortalId: row.PortalId,
    Name: row.Name,
    LegalStatus: MigrationCompanyLegalStatus[row.LegalStatus],
    DOB: (_a = row.DOB) == null ? void 0 : _a.toDate(),
    HomeCountry: row.HomeCountry,
    HomeCountryCode: row.CountryCode,
    Address: new Address({
      City: row.AddressCity,
      Street: row.AddressStreet,
      Zip: row.AddressZip,
      StreetNumber: row.AddressStreetNumber
    }),
    VatNumber: row.VatNumber,
    ContactLanguage: row.ContactLanguage,
    ContactEmail: row.ContactEmail,
    ContactName: new PersonName({
      Title: NameTitle[row.ContactTitle],
      FirstName: row.ContactFirstName,
      LastName: row.ContactLastName
    }),
    GisNumber: row.GisNumber,
    EmsNumber: row.EmsNumber,
    ClientAcceptance: MigrationCompanyClientAcceptance[row.ClientAcceptance],
    SentinelNumber: row.SentinelNumber,
    SentinelValidTo: (_b = row.SentinelValidTo) == null ? void 0 : _b.toDate(),
    ClientHasOSSReg: row.ClientHasOSSReg,
    OSSNumber: row.OSSNumber,
    OSSCountryCode: row.OSSCountryCode,
    OSSDateFrom: (_c = row.OSSDateFrom) == null ? void 0 : _c.toDate()
  });
}
export function mapSubscriptionRowToMigrateSubscriptionRequest(row) {
  var _a, _b, _c, _d;
  return new MigrationSubscriptions({
    PortalId: row.PortalId,
    Name: row.Name,
    ServiceCountry: row.ServiceCountry,
    CountryCode: row.CountryCode,
    LocalVatNumber: row.LocalVatNumber,
    LocalVatStartDate: (_a = row.LocalVatStartDate) == null ? void 0 : _a.toDate(),
    EuVatNumber: row.EuVatNumber,
    EuVatStartDate: (_b = row.EuVatStartDate) == null ? void 0 : _b.toDate(),
    PrimaryFrequency: MigrationSubscriptionsPrimaryFrequency[row.PrimaryFrequency],
    PrimaryFrequencyEFiling: row.PrimaryFrequencyEFiling,
    PrimaryFrequencyFilingExtension: row.PrimaryFrequencyFilingExtension,
    SecondaryFrequency: MigrationSubscriptionsSecondaryFrequency[row.SecondaryFrequency],
    SecondaryFrequencyEFiling: row.SecondaryFrequencyEFiling,
    SecondaryFrequencyFilingExtension: row.SecondaryFrequencyFilingExtension,
    EslFrequency: MigrationSubscriptionsEslFrequency[row.EslFrequency],
    VlsFrequency: MigrationSubscriptionsVlsFrequency[row.VlsFrequency],
    IbanOrLink: row.IbanOrLink,
    FirstMonthOfGeneration: (_c = row.FirstMonthOfGeneration) == null ? void 0 : _c.toDate(),
    SubscriptionValidTo: (_d = row.SubscriptionValidTo) == null ? void 0 : _d.toDate(),
    RegistrationStatus: MigrationSubscriptionsRegistrationStatus[row.RegistrationStatus],
    FiscRep: row.FiscRep,
    GermanFilingAgent: row.GermanFilingAgent,
    EsVatPayment: row.EsVatPayment,
    IntrastatIn: row.IntrastatIn,
    IntrastatOut: row.IntrastatOut,
    IntrastatFrequency: MigrationSubscriptionsIntrastatFrequency[row.IntrastatFrequency]
  });
}
export function mapVatNumberRowToMigrateVatNumberRequest(row) {
  var _a, _b;
  return new MigrationVat({
    PortalId: row.PortalId,
    Country: row.Country,
    VatNumber: row.VatNumber,
    ValidFromDate: (_a = row.ValidFromDate) == null ? void 0 : _a.toDate(),
    ValidEndDate: (_b = row.ValidEndDate) == null ? void 0 : _b.toDate()
  });
}
export function mapSellerAccountRowToMigrateSellerAccountRequest(row) {
  return new MigrationSellerAccount({
    PortalId: row.PortalId,
    Name: row.Name,
    SellerAccountName: row.SellerAccountName,
    SellerAccountIdentifier: row.SellerAccountIdentifier,
    Mwstoken: row.Mwstoken
  });
}
const parseContactLanguage = (contactCountryStr) => {
  var _a, _b;
  return (_b = (_a = getWebLanguageByCountry(contactCountryStr.toLowerCase())) == null ? void 0 : _a.locale) != null ? _b : "en-GB";
};
const parseBoolean = (boolStr) => {
  if (isEmpty(boolStr)) {
    return void 0;
  }
  if (boolStr.trim().toLowerCase() === "y" || boolStr.trim().toLowerCase() === "yes") {
    return true;
  }
  if (boolStr.trim().toLowerCase() === "n" || boolStr.trim().toLowerCase() === "no") {
    return false;
  }
  return void 0;
};
const parseEnum = (str, validValues) => {
  if (isEmpty(str)) {
    return void 0;
  }
  const valToCheck = str.trim().toLowerCase();
  for (const validVal of validValues) {
    if (valToCheck === validVal.toLowerCase()) {
      return validVal;
    }
  }
  return void 0;
};
const parsePrimaryFrequencyTypeEnum = (str) => {
  const val = parseEnum(str, FrequencyTypes);
  if (val) {
    return val;
  }
  const valToCheck = str.trim().toLowerCase();
  for (const kv of ExtendedFrequencyTypeMap) {
    if (kv[0].toLowerCase() === valToCheck) {
      return kv[1];
    }
  }
  return void 0;
};
const parseString = (str) => {
  return str == null ? void 0 : str.trim();
};
const parseNumber = (numStr) => {
  return toSafeNumber(numStr);
};
const parseDate = (dateStr) => {
  if (isEmpty(dateStr)) {
    return void 0;
  }
  if (dateStr.includes("-")) {
    const m = moment.utc(dateStr.trim(), "YYYY-MM-DD");
    return m.isValid() ? m : void 0;
  } else if (dateStr.includes("/")) {
    const m = moment.utc(dateStr.trim(), "DD/MM/YYYY");
    return m.isValid() ? m : void 0;
  } else {
    return void 0;
  }
};
const ensureDefault = (b, defaultVal) => {
  return b === void 0 ? defaultVal : b;
};
