import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class Migration {
  /**
   * Creates an instance of Migration.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Migration
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param MigrateCompanyRq_ _migrateCompanyRq
   * @returns Promise<MigrateCompanyRq_>
   * @memberof Migration
   */
  migrateCompany(pMigrateCompanyRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Migration/company",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pMigrateCompanyRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param MigrateSubscriptionsRq_ _migrateSubscriptionsRq
   * @returns Promise<MigrateSubscriptionsRq_>
   * @memberof Migration
   */
  migrateSubscriptions(pMigrateSubscriptionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Migration/subscriptions",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pMigrateSubscriptionsRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param MigrateSellerAccountRq_ _migrateSellerAccountRq
   * @returns Promise<MigrateSellerAccountRq_>
   * @memberof Migration
   */
  migrateSellerAccount(pMigrateSellerAccountRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Migration/seller-acount",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pMigrateSellerAccountRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param MigrateOtherVatRq_ _migrateOtherVatRq
   * @returns Promise<MigrateOtherVatRq_>
   * @memberof Migration
   */
  migrateOtherVat(pMigrateOtherVatRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Migration/other-vat",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pMigrateOtherVatRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
