export const createRange = (start, end) => {
  if (end < start) {
    return [];
  } else {
    return [...Array(end - start + 1).keys()].map((i) => i + start);
  }
};
export const arrayEquals = (arr1, arr2) => {
  if (arr1 === arr2)
    return true;
  if (arr1 === null || arr2 === null)
    return false;
  if ((arr1 == null ? void 0 : arr1.length) !== (arr2 == null ? void 0 : arr2.length))
    return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i])
      return false;
  }
  return true;
};
export function groupBy(array, prop) {
  return array.reduce((groups, item) => {
    const val = prop instanceof Function ? prop(item) : item[prop];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
}
