import { UpdateBusinessDetail } from "./UpdateBusinessDetail";
import { UpdateCompanyDetail } from "./UpdateCompanyDetail";
import { UpdateIndividualDetail } from "./UpdateIndividualDetail";
export class UpdateBusinessDetailFactory {
  static create($type) {
    if ($type === "V1.Company.UpdateBusinessDetail") {
      const result = new UpdateBusinessDetail();
      return result;
    }
    if ($type === "V1.Company.UpdateCompanyDetail") {
      const result = new UpdateCompanyDetail();
      return result;
    }
    if ($type === "V1.Company.UpdateIndividualDetail") {
      const result = new UpdateIndividualDetail();
      return result;
    }
    throw new Error($type + " not found");
  }
}
