export class Name {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Name
     */
    this.$type = "V1.Common.Person.Name";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.FirstName = _data["FirstName"];
      this.LastName = _data["LastName"];
      this.Title = NameTitle[_data["Title"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["FirstName"] = this.FirstName;
    _data["LastName"] = this.LastName;
    _data["Title"] = NameTitle[this.Title];
    return _data;
  }
}
export var NameTitle = /* @__PURE__ */ ((NameTitle2) => {
  NameTitle2["Mr"] = "Mr";
  NameTitle2["Mrs"] = "Mrs";
  NameTitle2["Ms"] = "Ms";
  return NameTitle2;
})(NameTitle || {});
