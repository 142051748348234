export var FieldOrderByField = /* @__PURE__ */ ((FieldOrderByField2) => {
  FieldOrderByField2["CompanyId"] = "CompanyId";
  FieldOrderByField2["CompanyName"] = "CompanyName";
  FieldOrderByField2["CreatedAt"] = "CreatedAt";
  FieldOrderByField2["ConfirmedAt"] = "ConfirmedAt";
  FieldOrderByField2["OrderPaymentStatus"] = "OrderPaymentStatus";
  FieldOrderByField2["Status"] = "Status";
  FieldOrderByField2["TotalPrice"] = "TotalPrice";
  FieldOrderByField2["ClientSource"] = "ClientSource";
  return FieldOrderByField2;
})(FieldOrderByField || {});
export var FieldOrder = /* @__PURE__ */ ((FieldOrder2) => {
  FieldOrder2["Ascending"] = "Ascending";
  FieldOrder2["Descending"] = "Descending";
  return FieldOrder2;
})(FieldOrder || {});
