export class VatRegTemplateField {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRegTemplateField
     */
    this.$type = "V1.TemplateMapping.TemplateSearch.VatRegTemplateField";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Order = VatRegTemplateFieldOrder[_data["Order"]];
      this.OrderByField = VatRegTemplateFieldOrderByField[_data["OrderByField"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Order"] = VatRegTemplateFieldOrder[this.Order];
    _data["OrderByField"] = VatRegTemplateFieldOrderByField[this.OrderByField];
    return _data;
  }
}
export var VatRegTemplateFieldOrder = /* @__PURE__ */ ((VatRegTemplateFieldOrder2) => {
  VatRegTemplateFieldOrder2["Ascending"] = "Ascending";
  VatRegTemplateFieldOrder2["Descending"] = "Descending";
  return VatRegTemplateFieldOrder2;
})(VatRegTemplateFieldOrder || {});
export var VatRegTemplateFieldOrderByField = /* @__PURE__ */ ((VatRegTemplateFieldOrderByField2) => {
  VatRegTemplateFieldOrderByField2["Name"] = "Name";
  VatRegTemplateFieldOrderByField2["Culture"] = "Culture";
  VatRegTemplateFieldOrderByField2["Trigger"] = "Trigger";
  VatRegTemplateFieldOrderByField2["RegistrationCountry"] = "RegistrationCountry";
  VatRegTemplateFieldOrderByField2["RegistrationType"] = "RegistrationType";
  VatRegTemplateFieldOrderByField2["TemplateStatus"] = "TemplateStatus";
  VatRegTemplateFieldOrderByField2["CompanyType"] = "CompanyType";
  VatRegTemplateFieldOrderByField2["Category"] = "Category";
  return VatRegTemplateFieldOrderByField2;
})(VatRegTemplateFieldOrderByField || {});
