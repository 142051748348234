var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { generateAmazonPDF } from "./amazon-pdf";
import { generateFedexPDF } from "./fedex-pdf";
import { generateKpmgPDF } from "./kpmg-pdf";
import { generateLaPostePDF } from "./laposte-pdf";
export const generatePDFContract = (pdfModel) => __async(void 0, null, function* () {
  let blob;
  const fileName = `Contract_${pdfModel.partnerName}${pdfModel.kpmgReference ? `_${pdfModel.kpmgReference}` : ""}`;
  if (pdfModel.clientSource === "FedEx") {
    blob = generateFedexPDF(pdfModel);
  } else if (pdfModel.clientSource === "Amazon") {
    blob = generateAmazonPDF(pdfModel);
  } else if (pdfModel.clientSource === "KPMG") {
    blob = generateKpmgPDF(pdfModel);
  } else if (pdfModel.clientSource === "LaPoste") {
    blob = generateLaPostePDF(pdfModel);
  }
  return { blob, fileName };
});
