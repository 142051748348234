import { AbstractValidator } from "fluent-ts-validator";
import { DCValidationResult } from "GlobalShared/haunted/HooksHelpers";
export const getAllTemplateMainFields = (template) => {
  return ["Name", "Culture", "Type"].concat(
    template.Type === "MPVatReg" ? ["VatRegCustom.Trigger", "VatRegCustom.Country", "VatRegCustom.EstCountry", "VatRegCustom.RegType", "VatRegCustom.CompanyType", "VatRegCustom.Category"] : []
  );
};
export class TemplateMainValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfString((s) => s.Name).isNotEmpty().withPropertyName("Name").withFailureMessage("Required");
    this.validateIfString((s) => s.Culture).isNotEmpty().withPropertyName("Culture").withFailureMessage("Required");
    this.validateIfString((s) => s.Type).isNotEmpty().withPropertyName("Type").withFailureMessage("Required");
    this.validateIfString((s) => s.Custom.Trigger).isNotEmpty().withPropertyName("VatRegCustom.Trigger").withFailureMessage("Required").when((s) => s.Id === void 0 && s.Type === "MPVatReg");
    this.validateIfString((s) => s.Custom.Country).isNotEmpty().withPropertyName("VatRegCustom.Country").withFailureMessage("Required").when((s) => s.Id === void 0 && s.Type === "MPVatReg");
    this.validateIfString((s) => s.Custom.Category).isNotEmpty().withPropertyName("VatRegCustom.Category").withFailureMessage("Required").when((s) => s.Id === void 0 && s.Type === "MPVatReg");
  }
  validateAllFields(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class IdentityMappingValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfString((s) => s.path).isNotEmpty().withPropertyName("path").withFailureMessage("Required");
  }
  validateAllFields(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class DateFormatMappingValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfString((s) => s.path).isNotEmpty().withPropertyName("path").withFailureMessage("Required");
    this.validateIfString((s) => s.format).isNotEmpty().withPropertyName("format").withFailureMessage("Required");
  }
  validateAllFields(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class SubstringMappingValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfString((s) => s.path).isNotEmpty().withPropertyName("path").withFailureMessage("Required");
    this.validateIfNumber((s) => s.from).isGreaterThan(0).withPropertyName("from").withFailureMessage("from must be bigger or equal with 1").when((s) => (s == null ? void 0 : s.from) !== void 0);
    this.validateIfNumber((s) => s.to).isGreaterThan(0).withPropertyName("to").withFailureMessage("to must be bigger or equal with 1").when((s) => (s == null ? void 0 : s.to) !== void 0);
    this.validateIf((s) => s).fulfills((s) => s.to >= s.from).withPropertyName("to").withFailureMessage('"to" must be bigger or equal than "from"').when((s) => (s == null ? void 0 : s.from) !== void 0 && (s == null ? void 0 : s.to) !== void 0);
  }
  validateAllFields(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class ConcatMappingValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfIterable((s) => s.paths).isNotEmpty().withPropertyName("paths").withFailureMessage("Set at least one field");
  }
  validateAllFields(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class EnumMappingValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfString((s) => s.path).isNotEmpty().withPropertyName("path").withFailureMessage("Required");
    this.validateIfIterable((s) => s.pairs).isNotEmpty().withPropertyName("pairs").withFailureMessage("Set at least one mapping");
  }
  validateAllFields(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class ConditionMappingValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfIterable((s) => s.terms).isNotEmpty().withPropertyName("terms").withFailureMessage("Set at least one term");
  }
  validateAllFields(item) {
    return new DCValidationResult(super.validate(item));
  }
}
