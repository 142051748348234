import { Money } from "./../../Common/Money";
import { ReturnInformation } from "./ReturnInformation";
export class IntrastatReturnInformation extends ReturnInformation {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof IntrastatReturnInformation
     */
    this.$type = "V1.Submission.StatusChanges.IntrastatReturnInformation";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["IntraInVatAmount"]) {
        const intraInVatAmount_ = new Money();
        this.IntraInVatAmount = intraInVatAmount_.init(_data["IntraInVatAmount"]);
      }
      if (_data["IntraOutVatAmount"]) {
        const intraOutVatAmount_ = new Money();
        this.IntraOutVatAmount = intraOutVatAmount_.init(_data["IntraOutVatAmount"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["IntraInVatAmount"] = this.IntraInVatAmount ? this.IntraInVatAmount.toJSON() : void 0;
    _data["IntraOutVatAmount"] = this.IntraOutVatAmount ? this.IntraOutVatAmount.toJSON() : void 0;
    return _data;
  }
}
