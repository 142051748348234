var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { notifyError, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { Money } from "AdminServices/APIs/self/models/V1/Common";
import { DateTimeUtcRange } from "AdminServices/APIs/self/models/V1/Common/DateTime";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import { AmountRange } from "AdminServices/APIs/self/models/V1/Primitives";
import {
  mapSearchOrderResult,
  OrderFieldNames,
  mapOrder,
  ALL_ORDER_STATUSES,
  DEFAULT_CURRENCY_CODE,
  mapOrderService,
  mapOrderServicesToSummary
} from "GlobalShared/components/orders/OrderModels";
import { useOrders } from "GlobalShared/components/orders/orders";
import { Company as CompanyClient, Orders as OrdersClient, Payments as PaymentsClient, Services as ServicesClient } from "AdminServices/APIs/self/clients";
import { Field as CompanyField, Filter as CompanyFilter, Pager as CompanyPager } from "AdminServices/APIs/self/models/V1/Company/Search";
import { FieldOrder as CompanyFieldOrder, FieldOrderByField as CompanyFieldOrderByField } from "AdminServices/APIs/self/models/V1/Company/Search/Field";
import { SearchRequest as CompanySearchRequest } from "AdminServices/APIs/self/models/V1/Company/Search/SearchRequest";
import { useEUCountries, usePermissionManager, useServiceCountries, useWorldCountries } from "AdminComponents/hooks/AdminCustomHooks";
import { mapCompanyDetailToCompanyServicesModel } from "GlobalShared/mappers/ServicesMapper";
import { GetServicesRequest, SelectedService } from "AdminServices/APIs/self/models/V1/Services";
import { CreateOrderRequest } from "AdminServices/APIs/self/models/V1/Orders/Create/CreateOrderRequest";
import { getRouteByKey } from "../../globals";
import { CountryBasedSubscriptionService, CustomPricedService, HourlyService, SimpleService, SubscriptionService } from "AdminServices/APIs/self/models/V1/Orders/Create";
import { Filter } from "AdminServices/APIs/self/models/V1/Orders/Search/Filter";
import { SearchRequest as OrderSearchRequest } from "AdminServices/APIs/self/models/V1/Orders/Search/SearchRequest";
import { Pager as OrderPager } from "AdminServices/APIs/self/models/V1/Orders/Search/Pager";
import { Field as OrderField } from "AdminServices/APIs/self/models/V1/Orders/Search/Field";
import { FieldOrder as OrderFieldOrder } from "AdminServices/APIs/self/models/V1/Orders/Search/FieldAbstraction";
import { BankTransferApproveRequest } from "AdminServices/APIs/self/models/V1/Payments";
import { UpdatedOrder, UpdatedService } from "AdminServices/APIs/self/models/V1/Orders/Update";
import { getAlreadySelectedServices } from "GlobalShared/components/orders/services/order-services-utils";
import { mapMoney } from "GlobalShared/mappers/MoneyMapper";
import { mapServiceDescriptor } from "GlobalShared/components/orders/OrderHelpers";
export function OfficeOrders() {
  const mapCreatedServices = (createdServices) => {
    return createdServices.map((cs) => {
      if (cs.Type === "Country") {
        return new CountryBasedSubscriptionService({
          Code: cs.Code,
          Country: cs.Country,
          StartDate: cs.StartDate,
          EndDate: cs.EndDate
        });
      } else if (cs.Type === "Simple") {
        return new SimpleService({
          Code: cs.Code
        });
      } else if (cs.Type === "Hourly") {
        return new HourlyService({
          Code: cs.Code,
          Hours: cs.Hours
        });
      } else if (cs.Type === "CustomPriced") {
        return new CustomPricedService({
          Code: cs.Code,
          Price: new Money({
            Currency: cs.Price.Currency,
            Value: cs.Price.Value
          })
        });
      } else if (cs.Type === "GlobalSubscription") {
        return new SubscriptionService({
          Code: cs.Code,
          StartDate: cs.StartDate,
          EndDate: cs.EndDate
        });
      } else {
        throw new Error("Unknown created service: " + cs.Code);
      }
    });
  };
  const getOrderFilter = (gridState, showAll, showProcessingFailure) => {
    const clientFilter = new Filter();
    if (showProcessingFailure) {
      clientFilter.HasProcessingFailure = true;
    }
    if (!gridState.appliedFilters.some((filter) => filter.field === OrderFieldNames.Status)) {
      clientFilter.Statuses = showAll ? ALL_ORDER_STATUSES : ALL_ORDER_STATUSES.filter((f) => f !== "Cancelled" && f !== "Abandoned");
    }
    gridState.appliedFilters.forEach((filter) => {
      if (filter.field === OrderFieldNames.CreatedAt) {
        if (filter.filterProps.min || filter.filterProps.max) {
          clientFilter.CreatedAt = new DateTimeUtcRange({
            From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
            To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
          });
        }
      } else if (filter.field === OrderFieldNames.CompanyName) {
        if (filter.filterProps.selectedValues) {
          clientFilter.CompanyName = filter.filterProps.selectedValues;
        }
      } else if (filter.field === OrderFieldNames.CompanyId) {
        if (filter.filterProps.selectedValues) {
          clientFilter.CompanyIdPart = filter.filterProps.selectedValues;
        }
      } else if (filter.field === OrderFieldNames.Status) {
        if (filter.filterProps.selectedValues) {
          clientFilter.Statuses = filter.filterProps.selectedValues;
        }
      } else if (filter.field === OrderFieldNames.OrderPaymentStatus) {
        if (filter.filterProps.selectedValues) {
          clientFilter.OrderPaymentStatuses = filter.filterProps.selectedValues;
        }
      } else if (filter.field === OrderFieldNames.ClientSource) {
        if (filter.filterProps.selectedValues) {
          clientFilter.ClientSources = filter.filterProps.selectedValues;
        }
      } else if (filter.field === OrderFieldNames.ContractId) {
        if (filter.filterProps.selectedValues) {
          clientFilter.ContractId = filter.filterProps.selectedValues;
        }
      } else if (filter.field === OrderFieldNames.OrderAmount) {
        if (filter.filterProps.min || filter.filterProps.max) {
          clientFilter.TotalPrice = new AmountRange({
            From: filter.filterProps.min !== void 0 ? filter.filterProps.min : 0,
            To: filter.filterProps.max !== void 0 ? filter.filterProps.max : 1e6
          });
        }
      }
    });
    return clientFilter;
  };
  const searchOrders = (gridState, showAll, showProcessingFailure) => __async(this, null, function* () {
    const orderResult = yield ordersClient.searchOrders({
      body: new OrderSearchRequest({
        Filter: getOrderFilter(gridState, showAll, showProcessingFailure),
        Pager: new OrderPager({
          Skip: gridState.pageIndex * gridState.pageSize,
          Top: gridState.pageSize,
          OrderBy: [
            new OrderField({
              Order: gridState.orderDir === "asc" ? OrderFieldOrder.Ascending : OrderFieldOrder.Descending,
              OrderByField: gridState.orderBy
            })
          ]
        })
      })
    });
    return mapSearchOrderResult(orderResult.data);
  });
  const searchClients = (fragment) => __async(this, null, function* () {
    if (isNaN(parseInt(fragment))) {
      const companiesResultByName = yield companyClient.searchCompanies({
        body: new CompanySearchRequest({
          Filter: new CompanyFilter({
            CompanyName: fragment
          }),
          Pager: new CompanyPager({
            Skip: 0,
            Top: 100,
            OrderBy: [
              new CompanyField({
                Order: CompanyFieldOrder.Ascending,
                OrderByField: CompanyFieldOrderByField.CompanyId
              })
            ]
          })
        })
      });
      return companiesResultByName.data.Companies.map((c) => ({ id: c.Id, name: c.CompanyName }));
    } else {
      const companiesResultById = yield companyClient.searchCompanies({
        body: new CompanySearchRequest({
          Filter: new CompanyFilter({
            CompanyId: fragment
          }),
          Pager: new CompanyPager({
            Skip: 0,
            Top: 100,
            OrderBy: [
              new CompanyField({
                Order: CompanyFieldOrder.Ascending,
                OrderByField: CompanyFieldOrderByField.CompanyId
              })
            ]
          })
        })
      });
      return companiesResultById.data.Companies.map((c) => ({ id: c.Id, name: c.CompanyName }));
    }
  });
  const getCompanyServices = (companyId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield companyClient.getCompanyDetail({ companyId });
        return mapCompanyDetailToCompanyServicesModel(worldCountries, result.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const reValidateCompanyVatNumber = (companyId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield companyClient.reValidateVatNumber({ companyId });
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getAvailableServices = (companyId, currencyCode, vatCountrySelection, iossSelection) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const resp = yield servicesClient.getAvailableServices({
          companyId,
          body: new GetServicesRequest({
            CurrencyCode: currencyCode,
            SelectedServices: getAlreadySelectedServices({
              vatCountrySelection,
              iossSelection
            }).map((s) => {
              return new SelectedService(__spreadProps(__spreadValues({}, s), {
                ServiceCode: s.ServiceCode
              }));
            })
          })
        });
        return resp.data.map(mapServiceDescriptor);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const createOrder = (companyId, createdServices) => __async(this, null, function* () {
    if (createdServices.length === 0) {
      notifyWarning("Select at least one service");
      return void 0;
    }
    const orderResponse = (yield ordersClient.createOrder({
      body: new CreateOrderRequest({
        CompanyId: companyId,
        Currency: DEFAULT_CURRENCY_CODE,
        Services: mapCreatedServices(createdServices)
      })
    })).data;
    const orderSummaryVM = orderResponse.Services ? {
      services: orderResponse.Services ? orderResponse.Services.map(mapOrderService).map(mapOrderServicesToSummary) : void 0,
      subTotal: mapMoney(orderResponse.SubTotal),
      total: mapMoney(orderResponse.Total),
      vat: mapMoney(orderResponse.Vat)
    } : void 0;
    return {
      Id: orderResponse.Id,
      ContractId: orderResponse.ContractId,
      ClientSource: orderResponse.ClientSource,
      OrderSummary: orderSummaryVM
    };
  });
  const getOrder = (orderId) => __async(this, null, function* () {
    const result = yield ordersClient.getOrder({
      orderId
    });
    return mapOrder(result.data);
  });
  const getPaymentInfo = (_orderId) => __async(this, null, function* () {
    return void 0;
  });
  const payAndConfirm = (_payment, _orderId) => __async(this, null, function* () {
  });
  const uploadContract = (_orderId, _contract) => __async(this, null, function* () {
  });
  const uploadBankTransferPaymentProof = (orderId, paymentId, file) => __async(this, null, function* () {
    yield paymentsClient.uploadBankTransferPaymentProof({
      orderId,
      paymentId,
      file
    });
  });
  const downloadBankTransferPaymentProof = (orderId, paymentId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const resp = yield paymentsClient.downloadBankTransferPaymentProof({
          orderId,
          paymentId
        });
        simulateDownload(resp.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const approveBankTransferPayment = (model) => __async(this, null, function* () {
    yield paymentsClient.approveBankTransferPayment({
      orderId: model.orderId,
      paymentId: model.paymentId,
      body: new BankTransferApproveRequest({
        Collected: model.collected ? new Money(__spreadValues({}, model.collected)) : void 0,
        Reference: model.reference
      })
    });
  });
  const downloadContract = (orderId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const resp = yield ordersClient.downloadContract({
          orderId
        });
        simulateDownload(resp.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const updateOrder = (orderId, updatedServices) => __async(this, null, function* () {
    yield ordersClient.updateOrder({
      orderId,
      body: new UpdatedOrder({
        UpdatedServices: updatedServices.map(
          (us) => new UpdatedService({
            Code: us.Code,
            Country: us.Country,
            IsCancelled: us.IsCancelled,
            Price: new Money({ Value: us.Price.Value, Currency: us.Price.Currency })
          })
        )
      })
    });
  });
  const approveDraftOrder = (orderId) => __async(this, null, function* () {
    yield ordersClient.approveDraftOrder({
      orderId
    });
  });
  const rejectDraftOrder = (orderId) => __async(this, null, function* () {
    yield ordersClient.rejectDraftOrder({
      orderId
    });
  });
  const cancelOrder = (orderId) => __async(this, null, function* () {
    yield ordersClient.cancelOrder({
      orderId
    });
  });
  const downloadBillingDocument = (orderId, documentId) => __async(this, null, function* () {
    const result = yield ordersClient.downloadBillingDocument({
      orderId,
      documentId
    });
    simulateDownload(result.data);
  });
  const fixOrder = (orderId) => __async(this, null, function* () {
    yield ordersClient.fixOrder({ orderId });
  });
  const ordersClient = useClient(OrdersClient);
  const paymentsClient = useClient(PaymentsClient);
  const companyClient = useClient(CompanyClient);
  const servicesClient = useClient(ServicesClient);
  const serviceCountries = useServiceCountries("en-GB");
  const euCountries = useEUCountries("en-GB");
  const worldCountries = useWorldCountries("en-GB");
  const permissionManager = usePermissionManager();
  const orderHook = useOrders({
    isAdmin: true,
    permissionManager,
    serviceCountries,
    euCountries,
    worldCountries,
    englishWorldCountries: worldCountries,
    getRouteByKey,
    approveBankTransferPayment,
    searchOrders,
    searchClients,
    getCompanyServices,
    reValidateCompanyVatNumber,
    downloadContract,
    downloadBankTransferPaymentProof,
    uploadBankTransferPaymentProof,
    getAvailableServices: (companyId, currencyCode, vatCountrySelection, iossSelection) => getAvailableServices(companyId, currencyCode, vatCountrySelection, iossSelection),
    createOrder,
    getOrder,
    getPaymentInfo,
    payAndConfirm,
    uploadContract,
    approveDraftOrder,
    rejectDraftOrder,
    cancelOrder,
    updateOrder,
    downloadBillingDocument,
    fixOrder
  });
  return orderHook.mainTemplate;
}
