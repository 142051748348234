import { Subscription } from "./Subscription";
export class RetrospectiveReturnSubscription extends Subscription {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof RetrospectiveReturnSubscription
     */
    this.$type = "V1.CompanyInfo.Subscriptions.RetrospectiveReturnSubscription";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.CountryCode = _data["CountryCode"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["CountryCode"] = this.CountryCode;
    return _data;
  }
}
