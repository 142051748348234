import { TswagClient } from "./../commons/TswagClient";
import { SearchResult } from "./../models/V1/ComplianceUploads/Search/SearchResult";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { UploadResult } from "./../models/V1/ComplianceUploads/UploadResult";
import { KpmgUploadResult } from "./../models/V1/ComplianceUploads/KpmgUploadResult";
import { AmazonUploadResult } from "./../models/V1/ComplianceUploads/AmazonUploadResult";
import { AmazonSaftUploadResult } from "./../models/V1/ComplianceUploads/AmazonSaftUploadResult";
import { StatusResult } from "./../models/V1/ComplianceUploads/StatusResult";
import { ComplianceUpload } from "./../models/V1/ComplianceUploads/ComplianceUpload";
export class ComplianceUploads {
  /**
   * Creates an instance of ComplianceUploads.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof ComplianceUploads
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SearchComplianceUploadsRq_ _searchComplianceUploadsRq
   * @returns Promise<SearchComplianceUploadsRq_>
   * @memberof ComplianceUploads
   */
  searchComplianceUploads(pSearchComplianceUploadsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchComplianceUploadsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadIossTransactionsRq_ _uploadIossTransactionsRq
   * @returns Promise<UploadIossTransactionsRq_>
   * @memberof ComplianceUploads
   */
  uploadIossTransactions(pUploadIossTransactionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Transactions/IOSS",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "ignoreWarnings", value: pUploadIossTransactionsRq.ignoreWarnings, required: true }
    ]);
    client.addPathParams([
      { name: "complianceUploadId", value: pUploadIossTransactionsRq.complianceUploadId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadIossTransactionsRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: UploadResult },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadVatKpmgTransactionsRq_ _uploadVatKpmgTransactionsRq
   * @returns Promise<UploadVatKpmgTransactionsRq_>
   * @memberof ComplianceUploads
   */
  uploadVatKpmgTransactions(pUploadVatKpmgTransactionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Transactions/VAT",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "ignoreWarnings", value: pUploadVatKpmgTransactionsRq.ignoreWarnings, required: true }
    ]);
    client.addPathParams([
      { name: "complianceUploadId", value: pUploadVatKpmgTransactionsRq.complianceUploadId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadVatKpmgTransactionsRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: KpmgUploadResult },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RemoveUploadedVatKpmgTransactionsRq_ _removeUploadedVatKpmgTransactionsRq
   * @returns Promise<RemoveUploadedVatKpmgTransactionsRq_>
   * @memberof ComplianceUploads
   */
  removeUploadedVatKpmgTransactions(pRemoveUploadedVatKpmgTransactionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Transactions/VAT",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pRemoveUploadedVatKpmgTransactionsRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadAmazonTransactionsRq_ _uploadAmazonTransactionsRq
   * @returns Promise<UploadAmazonTransactionsRq_>
   * @memberof ComplianceUploads
   */
  uploadAmazonTransactions(pUploadAmazonTransactionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Transactions/Amazon/{sellerEntityId}",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "ignoreWarnings", value: pUploadAmazonTransactionsRq.ignoreWarnings, required: true }
    ]);
    client.addPathParams([
      { name: "complianceUploadId", value: pUploadAmazonTransactionsRq.complianceUploadId, required: true },
      { name: "sellerEntityId", value: pUploadAmazonTransactionsRq.sellerEntityId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadAmazonTransactionsRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: AmazonUploadResult },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RemoveUploadedAmazonTransactionsRq_ _removeUploadedAmazonTransactionsRq
   * @returns Promise<RemoveUploadedAmazonTransactionsRq_>
   * @memberof ComplianceUploads
   */
  removeUploadedAmazonTransactions(pRemoveUploadedAmazonTransactionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Transactions/Amazon",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pRemoveUploadedAmazonTransactionsRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadSaftTransactionsRq_ _uploadSaftTransactionsRq
   * @returns Promise<UploadSaftTransactionsRq_>
   * @memberof ComplianceUploads
   */
  uploadSaftTransactions(pUploadSaftTransactionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Transactions/SAFT",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pUploadSaftTransactionsRq.complianceUploadId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadSaftTransactionsRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: AmazonSaftUploadResult },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GenerateSaftTemplateRq_ _generateSaftTemplateRq
   * @returns Promise<GenerateSaftTemplateRq_>
   * @memberof ComplianceUploads
   */
  generateSaftTemplate(pGenerateSaftTemplateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/GenerateSaftTemplate",
      "GET",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pGenerateSaftTemplateRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadFileRq_ _downloadFileRq
   * @returns Promise<DownloadFileRq_>
   * @memberof ComplianceUploads
   */
  downloadFile(pDownloadFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/Files/{reference}",
      "GET",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pDownloadFileRq.complianceUploadId, required: true },
      { name: "reference", value: pDownloadFileRq.reference, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeVatComplianceUploadRq_ _finalizeVatComplianceUploadRq
   * @returns Promise<FinalizeVatComplianceUploadRq_>
   * @memberof ComplianceUploads
   */
  finalizeVatComplianceUpload(pFinalizeVatComplianceUploadRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/FinalizeVat",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pFinalizeVatComplianceUploadRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: StatusResult },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeSAFTRq_ _finalizeSAFTRq
   * @returns Promise<FinalizeSAFTRq_>
   * @memberof ComplianceUploads
   */
  finalizeSAFT(pFinalizeSAFTRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/FinalizeSAFT",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "confirmNoSaft", value: pFinalizeSAFTRq.confirmNoSaft }
    ]);
    client.addPathParams([
      { name: "complianceUploadId", value: pFinalizeSAFTRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: StatusResult },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeMasterDataRq_ _finalizeMasterDataRq
   * @returns Promise<FinalizeMasterDataRq_>
   * @memberof ComplianceUploads
   */
  finalizeMasterData(pFinalizeMasterDataRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/FinalizeMasterData",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "acknowledgeIncompleteSkus", value: pFinalizeMasterDataRq.acknowledgeIncompleteSkus }
    ]);
    client.addPathParams([
      { name: "complianceUploadId", value: pFinalizeMasterDataRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: StatusResult },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeIossComplianceUploadRq_ _finalizeIossComplianceUploadRq
   * @returns Promise<FinalizeIossComplianceUploadRq_>
   * @memberof ComplianceUploads
   */
  finalizeIossComplianceUpload(pFinalizeIossComplianceUploadRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/FinalizeIoss",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pFinalizeIossComplianceUploadRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CheckBalanceRq_ _checkBalanceRq
   * @returns Promise<CheckBalanceRq_>
   * @memberof ComplianceUploads
   */
  checkBalance(pCheckBalanceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}/CheckBalance",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pCheckBalanceRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetComplianceRq_ _getComplianceRq
   * @returns Promise<GetComplianceRq_>
   * @memberof ComplianceUploads
   */
  getCompliance(pGetComplianceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceUploads/{complianceUploadId}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "complianceUploadId", value: pGetComplianceRq.complianceUploadId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: ComplianceUpload },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
