import { formatDate, getDay } from "GlobalShared/helpers/dateHelper";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import { isEmpty, REQUIRED_MESSAGE, testRegex } from "GlobalShared/helpers/stringHelper";
import { useEffect, useState } from "haunted";
import i18next from "i18next";
import moment from "moment";
const _Validation = class {
  constructor() {
    this.rules = [];
    this.condition = (_) => true;
    this.isRequired = (message) => {
      const rule = {
        message: message != null ? message : REQUIRED_MESSAGE,
        type: "required",
        validate: (value) => !isEmpty(value == null ? void 0 : value.toString())
      };
      this.rules.push(rule);
      return this;
    };
    this.hasLength = (length, message) => {
      const rule = {
        message: message != null ? message : i18next.t("Required length: {{length}}", { length }),
        type: "length",
        validate: (value) => {
          var _a;
          if (Array.isArray(value)) {
            return (value == null ? void 0 : value.length) === length;
          } else {
            return ((_a = value == null ? void 0 : value.toString()) == null ? void 0 : _a.length) === length;
          }
        }
      };
      this.rules.push(rule);
      return this;
    };
    this.isNumber = (message) => {
      const rule = {
        message: message != null ? message : i18next.t("Number required"),
        type: "number",
        validate: (value) => toSafeNumber(value == null ? void 0 : value.toString()) !== void 0
      };
      this.rules.push(rule);
      return this;
    };
    this.isDateAfter = (date, message) => {
      const rule = {
        message: message != null ? message : i18next.t("Date must be after {{date}}", { date: formatDate(date) }),
        type: "date_after",
        validate: (value) => moment(getDay(value)).isAfter(getDay(date))
      };
      this.rules.push(rule);
      return this;
    };
    this.isDateSameOrAfter = (date, message) => {
      const rule = {
        message: message != null ? message : i18next.t("Date must be same or after {{date}}", { date: formatDate(date) }),
        type: "date_same_or_after",
        validate: (value) => moment(getDay(value)).isSameOrAfter(getDay(date))
      };
      this.rules.push(rule);
      return this;
    };
    this.isDateBefore = (date, message) => {
      const rule = {
        message: message != null ? message : i18next.t("Date must be before {{date}}", { date: formatDate(date) }),
        type: "date_before",
        validate: (value) => moment(getDay(value)).isBefore(getDay(date))
      };
      this.rules.push(rule);
      return this;
    };
    this.isDateSameOrBefore = (date, message) => {
      const rule = {
        message: message != null ? message : i18next.t("Date must be same or before {{date}}", { date: formatDate(date) }),
        type: "date_same_or_before",
        validate: (value) => moment(getDay(value)).isSameOrBefore(getDay(date))
      };
      this.rules.push(rule);
      return this;
    };
    this.max = (max, message) => {
      const rule = {
        message: message != null ? message : i18next.t("Maximum {{max}} length", { max }),
        type: "max",
        validate: (value) => {
          if (typeof value === "number") {
            return value <= max;
          } else if (Array.isArray(value)) {
            return value.length <= max;
          } else if (isEmpty(value)) {
            return true;
          } else {
            return (value == null ? void 0 : value.toString().length) <= max;
          }
        }
      };
      this.rules.push(rule);
      return this;
    };
    this.min = (min, message) => {
      const rule = {
        message: message != null ? message : i18next.t("Minimum {{min}} length", { min }),
        type: "min",
        validate: (value) => {
          if (typeof value === "number") {
            return value >= min;
          } else if (Array.isArray(value)) {
            return value.length >= min;
          } else {
            return (value == null ? void 0 : value.toString().length) >= min;
          }
        }
      };
      this.rules.push(rule);
      return this;
    };
    this.isEmail = (message) => {
      const rule = {
        message: message != null ? message : i18next.t("Must be a valid email address"),
        type: "email",
        validate: (value) => isEmpty(value) || testRegex(value, /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
      };
      this.rules.push(rule);
      return this;
    };
    this.isIn = (list, message) => {
      const rule = {
        message: message != null ? message : i18next.t("Value must be in list"),
        type: "in",
        validate: (value) => list.includes(value)
      };
      this.rules.push(rule);
      return this;
    };
    this.isNotIn = (list, message) => {
      const rule = {
        message: message != null ? message : i18next.t("Value must be in list"),
        type: "not_in",
        validate: (value) => !list.includes(value)
      };
      this.rules.push(rule);
      return this;
    };
    this.isRegex = (regex, message) => {
      const rule = {
        message: message != null ? message : i18next.t("The value must match the required pattern"),
        type: "regex",
        validate: (value) => testRegex(value, regex)
      };
      this.rules.push(rule);
      return this;
    };
    this.isNotRegex = (regex, message) => {
      const rule = {
        message: message != null ? message : i18next.t("The value must not match the required pattern"),
        type: "regex",
        validate: (value) => !testRegex(value, regex)
      };
      this.rules.push(rule);
      return this;
    };
    this.fullfilsValidations = (validator) => {
      this.validator = validator;
      return this;
    };
    this.fullfils = (condition, message) => {
      const rule = {
        message,
        type: "condition",
        validate: condition
      };
      this.rules.push(rule);
      return this;
    };
    this.when = (condition) => {
      this.condition = condition;
      return this;
    };
    this.validate = (model) => {
      if (this.condition(model)) {
        const value = this.selector(model);
        for (const rule of this.rules) {
          if (!rule.validate(value)) {
            return [{ field: this.field, message: rule.message }];
          }
        }
        const results = [];
        if (this.validator) {
          for (const validation of this.validator) {
            const arrValue = value;
            let i = 0;
            for (const value2 of arrValue) {
              const results2 = validation.validate(value2);
              if (results2.length > 0) {
                for (const res of results2) {
                  const field = `${this.field}.${i}.${res.field}`;
                  results.push({ field, message: res.message });
                }
              }
              i++;
            }
          }
        }
        return results;
      }
      return [];
    };
  }
};
export let Validation = _Validation;
Validation.ruleFor = (field, selector) => {
  const v = new _Validation();
  v.field = field;
  v.selector = selector;
  return v;
};
export const useAwesomeValidator = (validations, dispatcher) => {
  const isValid = () => {
    return Object.keys(messages).length === 0;
  };
  const [messages, setMessages] = useState({});
  const [visibleFields, setVisibleFields] = useState([]);
  const [allFieldsVisible, setAllFieldsVisible] = useState(false);
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (dispatchIfValid) {
      setDispatchIfValid(false);
      if (isValid() && dispatcher) {
        dispatcher();
      }
    }
  }, [messages]);
  const validate = (model) => {
    const newMessages = {};
    for (const validation of validations) {
      for (const res of validation.validate(model)) {
        newMessages[res.field] = res.message;
      }
    }
    setMessages(newMessages);
    return Object.keys(newMessages).length === 0;
  };
  const validateAndDispatch = (model) => {
    setDispatchIfValid(true);
    setAllFieldsVisible(true);
    validate(model);
  };
  const getMessage = (field) => {
    if (allFieldsVisible || visibleFields.includes(field)) {
      return messages[field];
    }
    return void 0;
  };
  const showMessageFor = (field) => {
    if (!visibleFields.includes(field)) {
      setVisibleFields(visibleFields.concat(field));
    }
  };
  const clear = () => {
    setVisibleFields([]);
    setAllFieldsVisible(false);
  };
  return {
    getMessage,
    messages,
    validate,
    validateAndDispatch,
    showMessageFor,
    clear
  };
};
