import "./dc-datepicker.scss";
import { html } from "lit-html";
import { formatDate } from "GlobalShared/helpers/dateHelper";
import { component } from "haunted";
const DEFAULTS = {
  label: void 0,
  value: void 0,
  readonly: false,
  tooltip: "",
  max: new Date(2100, 0, 1)
};
export class ChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail });
  }
}
export const Component = (host) => {
  var _a;
  const props = {
    label: host.label !== void 0 ? host.label : DEFAULTS.label,
    value: host.value !== void 0 ? host.value : DEFAULTS.value,
    validationMessage: host.validationMessage,
    readonly: host.readonly !== void 0 ? host.readonly : DEFAULTS.readonly,
    tooltip: host.tooltip !== void 0 ? host.tooltip : DEFAULTS.tooltip,
    min: host.min,
    max: (_a = host.max) != null ? _a : DEFAULTS.max
  };
  const onChange = (e) => {
    var _a2;
    if (!props.readonly) {
      e.stopPropagation();
      host.dispatchEvent(
        new ChangeEvent({
          value: (_a2 = e.currentTarget.valueAsDate) != null ? _a2 : void 0
        })
      );
    }
  };
  return html`
		${props.label !== void 0 ? html`<label class="form-label">${props.label}${props.tooltip !== void 0 && props.tooltip.length > 0 ? html`<dc-tooltip .label=${props.tooltip}></dc-tooltip>` : ""}</label>` : ""}
		${props.readonly ? html`<input class=${props.validationMessage ? "invalid" : ""} value=${props.value ? formatDate(props.value) : ""} ?readonly=${true} />` : html`<input
					type="date"
					class=${props.validationMessage ? "invalid" : ""}
					value=${props.value ? formatDate(props.value) : ""}
					min=${props.min ? formatDate(props.min) : ""}
					max=${props.max ? formatDate(props.max) : ""}
					@change=${onChange}
			  />`}
		${props.validationMessage ? html` <div class="validation-result">${props.validationMessage}</div> ` : ""}
	`;
};
customElements.define(
  "dc-datepicker",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
