var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { NameAnswer as WebNameAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { NameAnswer as AdminNameAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { NameValidator } from "./AnswerValidators";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-name";
const DEFAULTS = {
  validation: {
    required: false
  }
};
export class NameChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    validation: host.validation !== void 0 ? __spreadValues(__spreadValues({}, DEFAULTS.validation), host.validation) : DEFAULTS.validation
  };
  const getNameAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminNameAnswer({ FirstName: firstName, LastName: lastName, QuestionId: props.questionId });
    }
    return new WebNameAnswer({ FirstName: firstName, LastName: lastName, QuestionId: props.questionId });
  };
  const getLabel = (fieldName) => {
    var _a;
    return ((_a = getSubQuestion(fieldName)) == null ? void 0 : _a.Label) + (props.validation.required ? "*" : "");
  };
  const getSubQuestion = (questionId) => {
    const filteredSubQuestions = question.SubQuestions.filter((subQuestion) => subQuestion.Id === questionId);
    if (filteredSubQuestions.length === 1) {
      return filteredSubQuestions[0];
    } else {
      return void 0;
    }
  };
  const [firstName, setFirstName] = useState(void 0);
  const [lastName, setLastName] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new NameValidator(props.validation));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new NameChangeEvent({
          answer: getNameAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof WebNameAnswer || answer instanceof AdminNameAnswer) {
        setFirstName(answer.FirstName);
        setLastName(answer.LastName);
      }
    }
  }, [props.q11e, props.questionId]);
  useEffect(() => {
    validationContext.validate(getNameAnswer());
  }, [firstName, lastName]);
  return html`
		${props.q11e !== void 0 && question !== void 0 ? html`<div class="grid md:grid-cols-2 gap-4 mt-4">
					<dc-input
						.readonly=${props.q11e.isReadonly(props.questionId)}
						.label=${getLabel("first_name")}
						.value=${firstName}
						.validationMessage=${validationContext.getValidationMessage("firstName")}
						@change=${(e) => {
    setFirstName(e.detail.value);
    setDispatchIfValid(true);
    validationContext.validateField("firstName");
  }}
					></dc-input
					><dc-input
						.readonly=${props.q11e.isReadonly(props.questionId)}
						.label=${getLabel("last_name")}
						.value=${lastName}
						.validationMessage=${validationContext.getValidationMessage("lastName")}
						@change=${(e) => {
    setLastName(e.detail.value);
    setDispatchIfValid(true);
    validationContext.validateField("lastName");
  }}
					></dc-input>
			  </div>` : ""}
	`;
};
