import { AbstractValidator } from "fluent-ts-validator";
import { DCValidationResult } from "GlobalShared/haunted/HooksHelpers";
import i18next from "i18next";
import { BusinessOwnerShareholderType } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/BusinessOwner/BusinessOwner";
import { INVALID_MESSAGE, isEmpty, ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE, ONLY_EUROPEAN_CHARS_REGEX, REQUIRED_MESSAGE } from "GlobalShared/helpers/stringHelper";
import { LegalRepresentativePosition } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/LegalRepresentative/LegalRepresentative";
import { EMAIL_REGEX } from "GlobalShared/common/Constants";
import { BeneficialOwnerBeneficialOwnerType } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/BeneficialOwner/BeneficialOwner";
export class AddressValidator extends AbstractValidator {
  constructor(validationConfig, withCountry) {
    super();
    this.validateIfString((s) => s.CountryCode).isNotEmpty().withPropertyName("countryCode").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true && withCountry);
    this.validateIfString((s) => s.Address.City).isNotEmpty().withPropertyName("city").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfString((s) => s.Address.City).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("city").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfString((s) => s.Address.City).hasMaxLength(100).withPropertyName("city").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 }));
    this.validateIfString((s) => s.Address.Street).isNotEmpty().withPropertyName("street").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfString((s) => s.Address.Street).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("street").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfString((s) => s.Address.Street).hasMaxLength(100).withPropertyName("street").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 }));
    this.validateIfString((s) => s.Address.StreetNumber).isNotEmpty().withPropertyName("streetNo").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfString((s) => s.Address.StreetNumber).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("streetNo").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfString((s) => s.Address.StreetNumber).hasMaxLength(20).withPropertyName("streetNo").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 20 }));
    this.validateIfString((s) => s.Address.Zip).isNotEmpty().withPropertyName("zip").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfString((s) => s.Address.Zip).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("zip").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfString((s) => s.Address.Zip).hasMaxLength(10).withPropertyName("zip").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 10 }));
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class StringValidator extends AbstractValidator {
  constructor(validationConfig) {
    super();
    this.validateIfString((s) => s.Value).isNotEmpty().withPropertyName("value").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfString((s) => s.Value).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("value").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((s) => !isEmpty(s == null ? void 0 : s.Value));
    this.validateIfString((s) => s.Value).hasMaxLength(100).withPropertyName("value").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when(() => validationConfig.maxLength !== void 0);
    this.validateIfString((s) => s.Value).matches(RegExp(validationConfig.regEx)).withPropertyName("value").withFailureMessage(validationConfig.regExValidationMessage).when(() => validationConfig.regEx !== void 0);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class BoolValidator extends AbstractValidator {
  constructor(validationConfig) {
    super();
    this.validateIf((s) => s.Value).isNotEqualTo(void 0).withPropertyName("value").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class MoneyValidator extends AbstractValidator {
  constructor(validationConfig) {
    super();
    this.validateIfNumber((s) => s.Value.Value).isNotEqualTo(void 0).withPropertyName("value").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfString((s) => s.Value.Currency).isNotEmpty().withPropertyName("currency").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class DateValidator extends AbstractValidator {
  constructor(validationConfig) {
    super();
    this.validateIfDate((s) => s.Value).isNotEqualTo(void 0).withPropertyName("value").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class FileRefValidator extends AbstractValidator {
  constructor(validationConfig) {
    super();
    this.validateIf((s) => s.Value).isNotEqualTo(void 0).withPropertyName("files").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class MultiStringValidator extends AbstractValidator {
  constructor(validationConfig) {
    super();
    this.validateIf((s) => s.Value).isNotEmpty().withPropertyName("values").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfEachString((s) => s.Value).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("values").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when(() => validationConfig.required === true);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class NameValidator extends AbstractValidator {
  constructor(validationConfig) {
    super();
    this.validateIfString((s) => s.FirstName).isNotEmpty().withPropertyName("firstName").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfString((s) => s.FirstName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("firstName").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIf((s) => s.LastName).isNotEmpty().withPropertyName("lastName").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfString((s) => s.LastName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("lastName").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when(() => validationConfig.required === true);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class CountryValidator extends AbstractValidator {
  constructor(validationConfig) {
    super();
    this.validateIf((s) => s.CountryCode).isNotEmpty().withPropertyName("countryCode").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class MultiCountryValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfIterable((s) => s.CountryCodes).isNotEmpty().withPropertyName("countryCodes").withFailureMessage(REQUIRED_MESSAGE);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class VatNumberValidator extends AbstractValidator {
  constructor(validationConfig) {
    super();
    this.validateIf((s) => s.Value.CountryCode).isNotEmpty().withPropertyName("countryCode").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIf((s) => s.Value.VatNumber).isNotEmpty().withPropertyName("vatNumber").withFailureMessage(REQUIRED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIfString((s) => s.Value.VatNumber).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("vatNumber").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when(() => validationConfig.required === true);
    this.validateIf((s) => s.Value).fulfills((s) => !s.VatNumber.toLocaleLowerCase().startsWith(s.CountryCode.toLocaleLowerCase())).withPropertyName("vatNumber").withFailureMessage(i18next.t("Please enter only the tax number without country prefix")).when((s) => {
      var _a, _b;
      return validationConfig.required === true && ((_a = s == null ? void 0 : s.Value) == null ? void 0 : _a.CountryCode) !== void 0 && ((_b = s == null ? void 0 : s.Value) == null ? void 0 : _b.VatNumber) !== void 0;
    });
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class ExistingVatNumbersValidator extends AbstractValidator {
  constructor(ignoreValidatingOptionalMigratedFields) {
    super();
    this.validateIfEach((s) => s.value).fulfills(new ExistingVatNumberValidator(ignoreValidatingOptionalMigratedFields)).withPropertyName("existingVatNumber").withFailureMessage(INVALID_MESSAGE);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class ExistingVatNumberValidator extends AbstractValidator {
  constructor(ignoreValidatingOptionalMigratedFields) {
    super();
    this.validateIfString((s) => s.CountryCode).isNotEmpty().withPropertyName("countryCode").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.CountryCode).isNotEmpty().withPropertyName("countryCode").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.VatNumber).isNotEmpty().withPropertyName("vatNumber").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.VatNumber).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("vatNumber").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfDate((s) => s.ValidFrom).isNotEmpty().withPropertyName("validFrom").withFailureMessage(REQUIRED_MESSAGE).when((_) => !ignoreValidatingOptionalMigratedFields);
    this.validateIf((s) => s).fulfills((s) => !s.VatNumber.toLocaleLowerCase().startsWith(s.CountryCode.toLocaleLowerCase())).withPropertyName("vatNumber").withFailureMessage(i18next.t("Please enter only the tax number without country prefix")).when((s) => (s == null ? void 0 : s.CountryCode) !== void 0 && (s == null ? void 0 : s.VatNumber) !== void 0);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class LegalRepsValidator extends AbstractValidator {
  constructor(_validationConfig, context, ignoreValidatingOptionalMigratedFields) {
    super();
    this.validateIfEach((s) => s.Value).fulfills(new LegalRepValidator(context, ignoreValidatingOptionalMigratedFields)).withPropertyName("legalRep").withFailureMessage(INVALID_MESSAGE);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class LegalRepValidator extends AbstractValidator {
  constructor(context, ignoreValidatingOptionalMigratedFields) {
    super();
    this.validateIfString((s) => s.Title).isNotEmpty().withPropertyName("title").withFailureMessage(REQUIRED_MESSAGE).when(() => context == null ? void 0 : context.isVisible("legal_rep", "title"));
    this.validateIfString((s) => s.FirstName).isNotEmpty().withPropertyName("first_name").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.FirstName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("first_name").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.FirstName).hasMaxLength(100).withPropertyName("first_name").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 }));
    this.validateIfString((s) => s.LastName).isNotEmpty().withPropertyName("last_name").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.LastName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("last_name").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.LastName).hasMaxLength(100).withPropertyName("last_name").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 }));
    this.validateIfString((s) => s.BirthName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("birth_name").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "birth_name")));
    this.validateIfString((s) => s.BirthName).hasMaxLength(100).withPropertyName("birth_name").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "birth_name")));
    this.validateIfString((s) => s.Position).isNotEmpty().withPropertyName("position").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "position")));
    this.validateIfString((s) => s.PositionDescription).isNotEmpty().withPropertyName("position_description").withFailureMessage(REQUIRED_MESSAGE).when((s) => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "position_description")) && s.Position === LegalRepresentativePosition.Other);
    this.validateIfString((s) => s.PositionDescription).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("position_description").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((s) => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "position_description")) && s.Position === LegalRepresentativePosition.Other);
    this.validateIfString((s) => s.PositionDescription).hasMaxLength(100).withPropertyName("position_description").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when((s) => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "position_description")) && s.Position === LegalRepresentativePosition.Other);
    this.validateIfString((s) => s.Email).isNotEmpty().withPropertyName("email").withFailureMessage(REQUIRED_MESSAGE).when(() => context == null ? void 0 : context.isVisible("legal_rep", "email"));
    this.validateIfString((s) => s.Email).hasMaxLength(100).withPropertyName("email").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 200 })).when(() => context == null ? void 0 : context.isVisible("legal_rep", "email"));
    this.validateIfString((s) => s.Email).matches(EMAIL_REGEX).withPropertyName("email").withFailureMessage(i18next.t("Please provide a valid e-mail address.")).when(() => context == null ? void 0 : context.isVisible("legal_rep", "email"));
    this.validateIfString((s) => s.Phone).isNotEmpty().withPropertyName("phone").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "phone")));
    this.validateIfString((s) => s.Phone).hasMaxLength(100).withPropertyName("phone").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when(() => context == null ? void 0 : context.isVisible("legal_rep", "phone"));
    this.validateIfDate((s) => s.DateOfBirth).isNotEmpty().withPropertyName("date_of_birth").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "date_of_birth")));
    this.validateIfString((s) => s.CityOfBirth).isNotEmpty().withPropertyName("city_of_birth").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "city_of_birth")));
    this.validateIfString((s) => s.Nationality).isNotEmpty().withPropertyName("nationality").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "nationality")));
    this.validateIfString((s) => s.CountryOfBirth).isNotEmpty().withPropertyName("country_of_birth").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "country_of_birth")));
    this.validateIfString((s) => s.MotherBirthName).isNotEmpty().withPropertyName("mother_birth_name").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "mother_birth_name")));
    this.validateIfString((s) => s.MotherBirthName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("mother_birth_name").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "mother_birth_name")));
    this.validateIfString((s) => s.MotherBirthName).hasMaxLength(100).withPropertyName("mother_birth_name").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "mother_birth_name")));
    this.validateIfString((s) => s.FatherName).isNotEmpty().withPropertyName("father_name").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "father_name")));
    this.validateIfString((s) => s.FatherName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("father_name").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "father_name")));
    this.validateIfString((s) => s.FatherName).hasMaxLength(100).withPropertyName("father_name").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "father_name")));
    this.validateIf((s) => s.HomeAddressIsSameAsRegistered).isNotEmpty().withPropertyName("home_address_is_same_as_registered").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "home_address_is_same_as_registered")));
    this.validateIf((s) => s.AddressCountry).isNotEmpty().withPropertyName("address_country").withFailureMessage(REQUIRED_MESSAGE).when((s) => s.HomeAddressIsSameAsRegistered === false);
    this.validateIfString((s) => s.Address.City).isNotEmpty().withPropertyName("address_city").withFailureMessage(REQUIRED_MESSAGE).when((s) => s.HomeAddressIsSameAsRegistered === false);
    this.validateIfString((s) => s.Address.City).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("address_city").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((s) => s.HomeAddressIsSameAsRegistered === false);
    this.validateIfString((s) => s.Address.City).hasMaxLength(200).withPropertyName("address_city").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 200 })).when((s) => s.HomeAddressIsSameAsRegistered === false);
    this.validateIfString((s) => s.Address.Street).isNotEmpty().withPropertyName("address_street").withFailureMessage(REQUIRED_MESSAGE).when((s) => s.HomeAddressIsSameAsRegistered === false);
    this.validateIfString((s) => s.Address.Street).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("address_street").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((s) => s.HomeAddressIsSameAsRegistered === false);
    this.validateIfString((s) => s.Address.Street).hasMaxLength(100).withPropertyName("address_street").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when((s) => s.HomeAddressIsSameAsRegistered === false);
    this.validateIfString((s) => s.Address.StreetNumber).isNotEmpty().withPropertyName("address_street_number").withFailureMessage(REQUIRED_MESSAGE).when((s) => s.HomeAddressIsSameAsRegistered === false);
    this.validateIfString((s) => s.Address.StreetNumber).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("address_street_number").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((s) => s.HomeAddressIsSameAsRegistered === false);
    this.validateIfString((s) => s.Address.StreetNumber).hasMaxLength(100).withPropertyName("address_street_number").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when((s) => s.HomeAddressIsSameAsRegistered === false);
    this.validateIf((s) => s.Address.Zip).isNotEmpty().withPropertyName("address_zip").withFailureMessage(REQUIRED_MESSAGE).when((s) => s.HomeAddressIsSameAsRegistered === false);
    this.validateIfString((s) => s.Address.Zip).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("address_zip").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((s) => s.HomeAddressIsSameAsRegistered === false);
    this.validateIfString((s) => s.Address.Zip).hasMaxLength(100).withPropertyName("address_zip").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when((s) => s.HomeAddressIsSameAsRegistered === false);
    this.validateIf((s) => s.DocumentType).isNotEmpty().withPropertyName("document_type").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "document_type")));
    this.validateIfString((s) => s.DocumentNumber).isNotEmpty().withPropertyName("document_number").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "document_number")));
    this.validateIfString((s) => s.DocumentNumber).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("document_number").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "document_number")));
    this.validateIf((s) => s.DocumentExpirationDate).isNotEmpty().withPropertyName("document_expiration_date").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "document_expiration_date")));
    this.validateIf((s) => s.PhotoCopyReference).isNotEmpty().withPropertyName("photo_copy_reference").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "photo_copy_reference")));
    this.validateIf((s) => s.OwnMoreThan25Percent).isNotEmpty().withPropertyName("own_more_than_25_percent").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "own_more_than_25_percent")));
    this.validateIf((s) => s.IsOnlyDirector).isNotEmpty().withPropertyName("is_only_director").withFailureMessage(REQUIRED_MESSAGE).when(() => !ignoreValidatingOptionalMigratedFields && (context == null ? void 0 : context.isVisible("legal_rep", "is_only_director")));
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class BusinessOwnersValidator extends AbstractValidator {
  constructor(_validationConfig, context) {
    super();
    this.validateIfEach((s) => s.Value).fulfills(new BusinessOwnerValidator(context)).withPropertyName("businessOwner").withFailureMessage(INVALID_MESSAGE);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class BusinessOwnerValidator extends AbstractValidator {
  constructor(context) {
    super();
    this.validateIfString((s) => s.ShareholderType).isNotEmpty().withPropertyName("shareholder_type").withFailureMessage(REQUIRED_MESSAGE).when(() => context == null ? void 0 : context.isVisible("business_owner", "shareholder_type"));
    this.validateIfString((s) => s.FirstName).isNotEmpty().withPropertyName("first_name").withFailureMessage(REQUIRED_MESSAGE).when((s) => (context == null ? void 0 : context.isVisible("business_owner", "first_name")) && s.ShareholderType === BusinessOwnerShareholderType.NaturalPerson);
    this.validateIfString((s) => s.FirstName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("first_name").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((s) => (context == null ? void 0 : context.isVisible("business_owner", "first_name")) && s.ShareholderType === BusinessOwnerShareholderType.NaturalPerson);
    this.validateIfString((s) => s.FirstName).hasMaxLength(100).withPropertyName("first_name").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when((s) => (context == null ? void 0 : context.isVisible("business_owner", "first_name")) && s.ShareholderType === BusinessOwnerShareholderType.NaturalPerson);
    this.validateIfString((s) => s.LastName).isNotEmpty().withPropertyName("last_name").withFailureMessage(REQUIRED_MESSAGE).when((s) => (context == null ? void 0 : context.isVisible("business_owner", "last_name")) && s.ShareholderType === BusinessOwnerShareholderType.NaturalPerson);
    this.validateIfString((s) => s.LastName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("last_name").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((s) => (context == null ? void 0 : context.isVisible("business_owner", "last_name")) && s.ShareholderType === BusinessOwnerShareholderType.NaturalPerson);
    this.validateIfString((s) => s.LastName).hasMaxLength(100).withPropertyName("last_name").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when((s) => (context == null ? void 0 : context.isVisible("business_owner", "last_name")) && s.ShareholderType === BusinessOwnerShareholderType.NaturalPerson);
    this.validateIfString((s) => s.FullName).isNotEmpty().withPropertyName("full_name").withFailureMessage(REQUIRED_MESSAGE).when((s) => (context == null ? void 0 : context.isVisible("business_owner", "full_name")) && s.ShareholderType === BusinessOwnerShareholderType.Company);
    this.validateIfString((s) => s.FullName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("full_name").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((s) => (context == null ? void 0 : context.isVisible("business_owner", "full_name")) && s.ShareholderType === BusinessOwnerShareholderType.Company);
    this.validateIfString((s) => s.FullName).hasMaxLength(100).withPropertyName("full_name").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when((s) => (context == null ? void 0 : context.isVisible("business_owner", "full_name")) && s.ShareholderType === BusinessOwnerShareholderType.Company);
    this.validateIfDate((s) => s.DateOfBirth).isNotEmpty().withPropertyName("date_of_birth").withFailureMessage(REQUIRED_MESSAGE).when((s) => (context == null ? void 0 : context.isVisible("business_owner", "date_of_birth")) && s.ShareholderType === BusinessOwnerShareholderType.NaturalPerson);
    this.validateIfDate((s) => s.DateOfIncorporation).isNotEmpty().withPropertyName("date_of_incorporation").withFailureMessage(REQUIRED_MESSAGE).when((s) => (context == null ? void 0 : context.isVisible("business_owner", "date_of_incorporation")) && s.ShareholderType === BusinessOwnerShareholderType.Company);
    this.validateIfNumber((s) => s.SharePercentage).isNotEmpty().withPropertyName("share_percentage").withFailureMessage(REQUIRED_MESSAGE).when(() => context == null ? void 0 : context.isVisible("business_owner", "share_percentage"));
    this.validateIfNumber((s) => s.SharePercentage).isGreaterThanOrEqual(0).isLessThanOrEqual(100).withPropertyName("share_percentage").withFailureMessage(i18next.t("Value must be between (inclusive): 0-100")).when(() => context == null ? void 0 : context.isVisible("business_owner", "share_percentage"));
    this.validateIfString((s) => s.RegistrationNumber).isNotEmpty().withPropertyName("registration_number").withFailureMessage(REQUIRED_MESSAGE).when((s) => (context == null ? void 0 : context.isVisible("business_owner", "registration_number")) && s.ShareholderType === BusinessOwnerShareholderType.Company);
    this.validateIfString((s) => s.DocumentNumber).isNotEmpty().withPropertyName("document_number").withFailureMessage(REQUIRED_MESSAGE).when((s) => (context == null ? void 0 : context.isVisible("business_owner", "document_number")) && s.ShareholderType === BusinessOwnerShareholderType.NaturalPerson);
    this.validateIfString((s) => s.DocumentType).isNotEmpty().withPropertyName("document_type").when((s) => (context == null ? void 0 : context.isVisible("business_owner", "document_type")) && s.ShareholderType === BusinessOwnerShareholderType.NaturalPerson);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class BankInformationValidator extends AbstractValidator {
  constructor(_validationConfig) {
    super();
    this.validateIfString((s) => s.Name).isNotEmpty().withPropertyName("name").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.Name).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("name").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.Name).hasMaxLength(100).withPropertyName("name").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 }));
    this.validateIfString((s) => s.AddressCountry).isNotEmpty().withPropertyName("addressCountry").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIf((s) => {
      var _a;
      return (_a = s.Address) == null ? void 0 : _a.City;
    }).isNotEmpty().withPropertyName("address_city").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIf((s) => {
      var _a;
      return (_a = s.Address) == null ? void 0 : _a.Street;
    }).isNotEmpty().withPropertyName("address_street").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIf((s) => {
      var _a;
      return (_a = s.Address) == null ? void 0 : _a.StreetNumber;
    }).isNotEmpty().withPropertyName("address_street_number").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIf((s) => {
      var _a;
      return (_a = s.Address) == null ? void 0 : _a.Zip;
    }).isNotEmpty().withPropertyName("address_zip").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.Owner).isNotEmpty().withPropertyName("owner").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.Owner).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("owner").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.Owner).hasMaxLength(100).withPropertyName("owner").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 }));
    this.validateIfString((s) => s.Iban).isNotEmpty().withPropertyName("iban").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.Iban).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("iban").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.Iban).hasMaxLength(100).withPropertyName("iban").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 }));
    this.validateIfString((s) => s.Swift).isNotEmpty().withPropertyName("swift").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.Swift).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("swift").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.Swift).hasMaxLength(100).withPropertyName("swift").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 }));
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class FixedEstablishmentsValidator extends AbstractValidator {
  constructor(_validationConfig, context) {
    super();
    this.validateIfEach((s) => s.Value).fulfills(new FixedEstablishmentValidator(context)).withPropertyName("fixedEstablishment").withFailureMessage(INVALID_MESSAGE);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class FixedEstablishmentValidator extends AbstractValidator {
  constructor(context) {
    super();
    this.validateIf((s) => s.AddressCountry).isNotEmpty().withPropertyName("address_country").withFailureMessage(REQUIRED_MESSAGE).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
    this.validateIfString((s) => s.Address.City).isNotEmpty().withPropertyName("address_city").withFailureMessage(REQUIRED_MESSAGE).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
    this.validateIfString((s) => s.Address.City).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("address_city").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
    this.validateIfString((s) => s.Address.City).hasMaxLength(200).withPropertyName("address_city").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 200 })).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
    this.validateIfString((s) => s.Address.Street).isNotEmpty().withPropertyName("address_street").withFailureMessage(REQUIRED_MESSAGE).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
    this.validateIfString((s) => s.Address.Street).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("address_street").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
    this.validateIfString((s) => s.Address.Street).hasMaxLength(100).withPropertyName("address_street").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
    this.validateIfString((s) => s.Address.StreetNumber).isNotEmpty().withPropertyName("address_street_number").withFailureMessage(REQUIRED_MESSAGE).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
    this.validateIfString((s) => s.Address.StreetNumber).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("address_street_number").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
    this.validateIfString((s) => s.Address.StreetNumber).hasMaxLength(100).withPropertyName("address_street_number").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
    this.validateIf((s) => s.Address.Zip).isNotEmpty().withPropertyName("address_zip").withFailureMessage(REQUIRED_MESSAGE).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
    this.validateIfString((s) => s.Address.Zip).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("address_zip").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
    this.validateIfString((s) => s.Address.Zip).hasMaxLength(100).withPropertyName("address_zip").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 })).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
    this.validateIfString((s) => s.TradingName).isNotEmpty().withPropertyName("trading_name").withFailureMessage(REQUIRED_MESSAGE).when((_) => context == null ? void 0 : context.isVisible("ioss_fixed_estab"));
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class OssDetailValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfString((s) => s.Value.RegistrationCountry).isNotEmpty().withPropertyName("registrationCountry").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfDate((s) => s.Value.RegistrationDate).isNotNull().withPropertyName("registrationDate").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.Value.RegistrationNumber).isNotEmpty().withPropertyName("registrationNumber").withFailureMessage(REQUIRED_MESSAGE);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class EnumAnswerValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIf((s) => s.Value).isNotEmpty().withPropertyName("values").withFailureMessage(REQUIRED_MESSAGE);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class TurnOversValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIf((s) => s.Value).isNotEmpty().withPropertyName("turnOver").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfEach((s) => s.Value).fulfills(new TurnOverValidator()).withPropertyName("turnOver").withFailureMessage(INVALID_MESSAGE);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class TurnOverValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfNumber((s) => {
      var _a;
      return (_a = s.Amount) == null ? void 0 : _a.Value;
    }).isNotNull().withPropertyName("amount").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfNumber((s) => {
      var _a;
      return (_a = s.Amount) == null ? void 0 : _a.Value;
    }).isGreaterThanOrEqual(0).withPropertyName("amount").withFailureMessage(i18next.t("Amount must be greater than or equal to zero."));
    this.validateIfNumber((s) => s.Year).isNotNull().withPropertyName("year").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfNumber((s) => s.Year).isGreaterThanOrEqual(1970).isLessThanOrEqual((/* @__PURE__ */ new Date()).getFullYear()).withPropertyName("year").withFailureMessage(i18next.t("Please enter a valid year."));
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class BeneficialOwnersValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfEach((s) => s.Value).fulfills(new BeneficialOwnerValidator()).withPropertyName("beneficialOwner").withFailureMessage(INVALID_MESSAGE);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class BeneficialOwnerValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfString((s) => s.FirstName).isNotEmpty().withPropertyName("first_name").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.FirstName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("first_name").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.FirstName).hasMaxLength(100).withPropertyName("first_name").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 }));
    this.validateIfString((s) => s.LastName).isNotEmpty().withPropertyName("last_name").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.LastName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("last_name").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.LastName).hasMaxLength(100).withPropertyName("last_name").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 }));
    this.validateIfString((s) => s.BirthName).isNotEmpty().withPropertyName("birth_name").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.BirthName).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("birth_name").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIf((s) => s.DateOfBirth).isNotNull().withPropertyName("date_of_birth").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.CountryOfBirth).isNotEmpty().withPropertyName("country_of_birth").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.CityOfBirth).isNotEmpty().withPropertyName("city_of_birth").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.Nationality).isNotEmpty().withPropertyName("citizenship").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIf((s) => s.AddressCountry).isNotEmpty().withPropertyName("address_country").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.Address.City).isNotEmpty().withPropertyName("address_city").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.Address.City).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("address_city").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.Address.City).hasMaxLength(200).withPropertyName("address_city").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 200 }));
    this.validateIfString((s) => s.Address.Street).isNotEmpty().withPropertyName("address_street").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.Address.Street).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("address_street").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.Address.Street).hasMaxLength(100).withPropertyName("address_street").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 }));
    this.validateIfString((s) => s.Address.StreetNumber).isNotEmpty().withPropertyName("address_streetnumber").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.Address.StreetNumber).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("address_street_number").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.Address.StreetNumber).hasMaxLength(100).withPropertyName("address_street_number").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 }));
    this.validateIf((s) => s.Address.Zip).isNotEmpty().withPropertyName("address_zip").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.Address.Zip).matches(ONLY_EUROPEAN_CHARS_REGEX).withPropertyName("address_zip").withFailureMessage(ONLY_EUROPEAN_CHARS_ARE_ALLOWED_MESSAGE);
    this.validateIfString((s) => s.Address.Zip).hasMaxLength(100).withPropertyName("address_zip").withFailureMessage(i18next.t("Max length: {{maxLength}}", { maxLength: 100 }));
    this.validateIf((s) => s.IsPoliticallyExposed).isNotNull().withPropertyName("is_politically_exposed").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIf((s) => s.IsRelativeToPoliticallyExposed).isNotNull().withPropertyName("is_relative_politically_exposed").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIf((s) => s.IsAssociateToPoliticallyExposed).isNotNull().withPropertyName("is_associate_politically_exposed").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIf((s) => s.DataManagement).isNotNull().withPropertyName("data_management").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.BeneficialOwnerType).isNotEmpty().withPropertyName("beneficial_owner_type").withFailureMessage(REQUIRED_MESSAGE);
    this.validateIfString((s) => s.TypeOfOwnership).isNotEmpty().withPropertyName("type_of_ownership").withFailureMessage(REQUIRED_MESSAGE).when((c) => c.BeneficialOwnerType === BeneficialOwnerBeneficialOwnerType.NaturalPerson);
    this.validateIfNumber((s) => s.ExtentOfOwnership).isGreaterThanOrEqual(0).isLessThanOrEqual(100).withPropertyName("extent_of_ownership").withFailureMessage(i18next.t("Value must be between (inclusive): 0-100")).when((c) => c.ExtentOfOwnership !== void 0 && c.BeneficialOwnerType === BeneficialOwnerBeneficialOwnerType.NaturalPerson);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
