export class CompanyTemplateSearchContent {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CompanyTemplateSearchContent
     */
    this.$type = "V1.TemplateMapping.TemplateSearch.CompanyTemplateSearchContent";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Culture = _data["Culture"];
      this.Id = _data["Id"];
      this.Name = _data["Name"];
      this.TemplateStatus = CompanyTemplateSearchContentTemplateStatus[_data["TemplateStatus"]];
      this.Trigger = CompanyTemplateSearchContentTrigger[_data["Trigger"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Culture"] = this.Culture;
    _data["Id"] = this.Id;
    _data["Name"] = this.Name;
    _data["TemplateStatus"] = CompanyTemplateSearchContentTemplateStatus[this.TemplateStatus];
    _data["Trigger"] = CompanyTemplateSearchContentTrigger[this.Trigger];
    return _data;
  }
}
export var CompanyTemplateSearchContentTemplateStatus = /* @__PURE__ */ ((CompanyTemplateSearchContentTemplateStatus2) => {
  CompanyTemplateSearchContentTemplateStatus2["Draft"] = "Draft";
  CompanyTemplateSearchContentTemplateStatus2["Enabled"] = "Enabled";
  CompanyTemplateSearchContentTemplateStatus2["Disabled"] = "Disabled";
  return CompanyTemplateSearchContentTemplateStatus2;
})(CompanyTemplateSearchContentTemplateStatus || {});
export var CompanyTemplateSearchContentTrigger = /* @__PURE__ */ ((CompanyTemplateSearchContentTrigger2) => {
  CompanyTemplateSearchContentTrigger2["OrderCreated"] = "OrderCreated";
  return CompanyTemplateSearchContentTrigger2;
})(CompanyTemplateSearchContentTrigger || {});
