import { DateConverter } from "./../../../../../converters/DateConverter";
export class VatRegFilter {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRegFilter
     */
    this.$type = "V1.Company.VatRegistration.Search.VatRegFilter";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientSource = VatRegFilterClientSource[_data["ClientSource"]];
      this.CompanyId = _data["CompanyId"];
      this.CompanyName = _data["CompanyName"];
      this.CompanyStatus = VatRegFilterCompanyStatus[_data["CompanyStatus"]];
      this.Country = _data["Country"];
      this.IsAssignedToMe = _data["IsAssignedToMe"];
      this.RegType = VatRegFilterRegType[_data["RegType"]];
      this.ResponsibleEmailPart = _data["ResponsibleEmailPart"];
      this.SignupDateFrom = DateConverter.from(_data["SignupDateFrom"]);
      this.SignupDateTo = DateConverter.from(_data["SignupDateTo"]);
      this.SubscriptionStatus = VatRegFilterSubscriptionStatus[_data["SubscriptionStatus"]];
      this.SubscriptionValidToFrom = DateConverter.from(_data["SubscriptionValidToFrom"]);
      this.SubscriptionValidToTo = DateConverter.from(_data["SubscriptionValidToTo"]);
      if (_data["VatRegistrationStatuses"] && _data["VatRegistrationStatuses"].constructor === Array) {
        this.VatRegistrationStatuses = [];
        for (let item of _data["VatRegistrationStatuses"]) {
          this.VatRegistrationStatuses.push(VatRegFilterVatRegistrationStatuses[item]);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientSource"] = VatRegFilterClientSource[this.ClientSource];
    _data["CompanyId"] = this.CompanyId;
    _data["CompanyName"] = this.CompanyName;
    _data["CompanyStatus"] = VatRegFilterCompanyStatus[this.CompanyStatus];
    _data["Country"] = this.Country;
    _data["IsAssignedToMe"] = this.IsAssignedToMe;
    _data["RegType"] = VatRegFilterRegType[this.RegType];
    _data["ResponsibleEmailPart"] = this.ResponsibleEmailPart;
    _data["SignupDateFrom"] = DateConverter.toUtcDateString(this.SignupDateFrom);
    _data["SignupDateTo"] = DateConverter.toUtcDateString(this.SignupDateTo);
    _data["SubscriptionStatus"] = VatRegFilterSubscriptionStatus[this.SubscriptionStatus];
    _data["SubscriptionValidToFrom"] = DateConverter.toUtcDateString(this.SubscriptionValidToFrom);
    _data["SubscriptionValidToTo"] = DateConverter.toUtcDateString(this.SubscriptionValidToTo);
    if (this.VatRegistrationStatuses && this.VatRegistrationStatuses.constructor === Array) {
      _data["VatRegistrationStatuses"] = [];
      for (let item of this.VatRegistrationStatuses) {
        _data["VatRegistrationStatuses"].push(VatRegFilterVatRegistrationStatuses[item]);
      }
    }
    return _data;
  }
}
export var VatRegFilterClientSource = /* @__PURE__ */ ((VatRegFilterClientSource2) => {
  VatRegFilterClientSource2["Amazon"] = "Amazon";
  VatRegFilterClientSource2["KPMG"] = "KPMG";
  VatRegFilterClientSource2["FedEx"] = "FedEx";
  VatRegFilterClientSource2["LaPoste"] = "LaPoste";
  return VatRegFilterClientSource2;
})(VatRegFilterClientSource || {});
export var VatRegFilterCompanyStatus = /* @__PURE__ */ ((VatRegFilterCompanyStatus2) => {
  VatRegFilterCompanyStatus2["Active"] = "Active";
  VatRegFilterCompanyStatus2["Rejected"] = "Rejected";
  VatRegFilterCompanyStatus2["Inactive"] = "Inactive";
  return VatRegFilterCompanyStatus2;
})(VatRegFilterCompanyStatus || {});
export var VatRegFilterRegType = /* @__PURE__ */ ((VatRegFilterRegType2) => {
  VatRegFilterRegType2["NewReg"] = "NewReg";
  VatRegFilterRegType2["TakOver"] = "TakOver";
  return VatRegFilterRegType2;
})(VatRegFilterRegType || {});
export var VatRegFilterSubscriptionStatus = /* @__PURE__ */ ((VatRegFilterSubscriptionStatus2) => {
  VatRegFilterSubscriptionStatus2["Active"] = "Active";
  VatRegFilterSubscriptionStatus2["Cancelled"] = "Cancelled";
  VatRegFilterSubscriptionStatus2["Expired"] = "Expired";
  VatRegFilterSubscriptionStatus2["Pending"] = "Pending";
  return VatRegFilterSubscriptionStatus2;
})(VatRegFilterSubscriptionStatus || {});
export var VatRegFilterVatRegistrationStatuses = /* @__PURE__ */ ((VatRegFilterVatRegistrationStatuses2) => {
  VatRegFilterVatRegistrationStatuses2["Pending"] = "Pending";
  VatRegFilterVatRegistrationStatuses2["WaitingForSignature"] = "WaitingForSignature";
  VatRegFilterVatRegistrationStatuses2["DocumentsSigned"] = "DocumentsSigned";
  VatRegFilterVatRegistrationStatuses2["TranslationInProgress"] = "TranslationInProgress";
  VatRegFilterVatRegistrationStatuses2["SentToTA"] = "SentToTA";
  VatRegFilterVatRegistrationStatuses2["LocalTaxReceived"] = "LocalTaxReceived";
  VatRegFilterVatRegistrationStatuses2["RegistrationCompleted"] = "RegistrationCompleted";
  VatRegFilterVatRegistrationStatuses2["Deregistered"] = "Deregistered";
  VatRegFilterVatRegistrationStatuses2["DeregistrationInProgress"] = "DeregistrationInProgress";
  VatRegFilterVatRegistrationStatuses2["PoARevoked"] = "PoARevoked";
  VatRegFilterVatRegistrationStatuses2["RevokingPoA"] = "RevokingPoA";
  return VatRegFilterVatRegistrationStatuses2;
})(VatRegFilterVatRegistrationStatuses || {});
