export class PossibleStatusChange {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PossibleStatusChange
     */
    this.$type = "V1.Company.VatRegistration.PossibleStatusChange";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.From = PossibleStatusChangeFrom[_data["From"]];
      this.To = PossibleStatusChangeTo[_data["To"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["From"] = PossibleStatusChangeFrom[this.From];
    _data["To"] = PossibleStatusChangeTo[this.To];
    return _data;
  }
}
export var PossibleStatusChangeFrom = /* @__PURE__ */ ((PossibleStatusChangeFrom2) => {
  PossibleStatusChangeFrom2["Pending"] = "Pending";
  PossibleStatusChangeFrom2["WaitingForSignature"] = "WaitingForSignature";
  PossibleStatusChangeFrom2["DocumentsSigned"] = "DocumentsSigned";
  PossibleStatusChangeFrom2["TranslationInProgress"] = "TranslationInProgress";
  PossibleStatusChangeFrom2["SentToTA"] = "SentToTA";
  PossibleStatusChangeFrom2["LocalTaxReceived"] = "LocalTaxReceived";
  PossibleStatusChangeFrom2["RegistrationCompleted"] = "RegistrationCompleted";
  PossibleStatusChangeFrom2["Deregistered"] = "Deregistered";
  PossibleStatusChangeFrom2["DeregistrationInProgress"] = "DeregistrationInProgress";
  PossibleStatusChangeFrom2["PoARevoked"] = "PoARevoked";
  PossibleStatusChangeFrom2["RevokingPoA"] = "RevokingPoA";
  return PossibleStatusChangeFrom2;
})(PossibleStatusChangeFrom || {});
export var PossibleStatusChangeTo = /* @__PURE__ */ ((PossibleStatusChangeTo2) => {
  PossibleStatusChangeTo2["Pending"] = "Pending";
  PossibleStatusChangeTo2["WaitingForSignature"] = "WaitingForSignature";
  PossibleStatusChangeTo2["DocumentsSigned"] = "DocumentsSigned";
  PossibleStatusChangeTo2["TranslationInProgress"] = "TranslationInProgress";
  PossibleStatusChangeTo2["SentToTA"] = "SentToTA";
  PossibleStatusChangeTo2["LocalTaxReceived"] = "LocalTaxReceived";
  PossibleStatusChangeTo2["RegistrationCompleted"] = "RegistrationCompleted";
  PossibleStatusChangeTo2["Deregistered"] = "Deregistered";
  PossibleStatusChangeTo2["DeregistrationInProgress"] = "DeregistrationInProgress";
  PossibleStatusChangeTo2["PoARevoked"] = "PoARevoked";
  PossibleStatusChangeTo2["RevokingPoA"] = "RevokingPoA";
  return PossibleStatusChangeTo2;
})(PossibleStatusChangeTo || {});
