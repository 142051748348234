var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { StringAnswer as WebStringAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { StringAnswer as AdminStringAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { StringValidator } from "./AnswerValidators";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-string";
const DEFAULTS = {
  type: "string",
  validation: {
    required: false
  },
  nolabel: false
};
export class StringChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    type: host.type !== void 0 ? host.type : DEFAULTS.type,
    validation: host.validation !== void 0 ? __spreadValues(__spreadValues({}, DEFAULTS.validation), host.validation) : DEFAULTS.validation,
    selectProps: host.selectProps,
    nolabel: host.nolabel !== void 0 ? host.nolabel : DEFAULTS.nolabel
  };
  const getStringAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminStringAnswer({ Value: currentVal, QuestionId: props.questionId });
    }
    return new WebStringAnswer({ Value: currentVal, QuestionId: props.questionId });
  };
  const getLabel = () => {
    return props.nolabel ? void 0 : question.Label + (props.validation.required ? "*" : "");
  };
  const [currentVal, setCurrentVal] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new StringValidator(props.validation));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new StringChangeEvent({
          answer: getStringAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof AdminStringAnswer || answer instanceof WebStringAnswer) {
        setCurrentVal(answer.Value);
      }
    }
  }, [props.q11e, props.questionId]);
  useEffect(() => {
    validationContext.validate(getStringAnswer());
  }, [currentVal]);
  const templateInput = () => {
    return html`<dc-input
			.readonly=${props.q11e.isReadonly(props.questionId)}
			.label=${getLabel()}
			.value=${currentVal}
			.tooltip=${question.Tooltip !== void 0 ? question.Tooltip : ""}
			.validationMessage=${validationContext.getValidationMessage("value")}
			@change=${(e) => {
      setCurrentVal(e.detail.value);
      setDispatchIfValid(true);
      validationContext.validateField("value");
    }}
		></dc-input>`;
  };
  const templateSelect = () => {
    return html`<dc-select
			.readonly=${props.q11e.isReadonly(props.questionId)}
			.label=${getLabel()}
			.tooltip=${question.Tooltip !== void 0 ? question.Tooltip : ""}
			.selectedValues=${[currentVal]}
			.validationMessage=${validationContext.getValidationMessage("value")}
			.dataSource=${props.selectProps.dataSource}
			.filterable=${props.selectProps.filterable}
			.filterProps=${props.selectProps.filterProps}
			.multiSelect=${props.selectProps.multiSelect}
			@change=${(e) => {
      setCurrentVal(e.detail.selectedValue);
      setDispatchIfValid(true);
      validationContext.validateField("value");
    }}
		></dc-select>`;
  };
  return html` ${props.q11e !== void 0 && question !== void 0 ? html`${props.type === "string" ? templateInput() : templateSelect()}` : ""} `;
};
