import { DateConverter } from "./../../../../../converters/DateConverter";
export class Filter {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Filter
     */
    this.$type = "V1.Company.IossRegistration.Search.Filter";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientId = _data["ClientId"];
      this.ClientName = _data["ClientName"];
      this.ClientSource = FilterClientSource[_data["ClientSource"]];
      this.CompanyStatus = FilterCompanyStatus[_data["CompanyStatus"]];
      if (_data["RegistrationStatuses"] && _data["RegistrationStatuses"].constructor === Array) {
        this.RegistrationStatuses = [];
        for (let item of _data["RegistrationStatuses"]) {
          this.RegistrationStatuses.push(FilterRegistrationStatuses[item]);
        }
      }
      this.RegistrationType = FilterRegistrationType[_data["RegistrationType"]];
      this.SignUpDateFrom = DateConverter.from(_data["SignUpDateFrom"]);
      this.SignUpDateTo = DateConverter.from(_data["SignUpDateTo"]);
      this.SubscriptionStatus = FilterSubscriptionStatus[_data["SubscriptionStatus"]];
      this.SubscriptionValidToFrom = DateConverter.from(_data["SubscriptionValidToFrom"]);
      this.SubscriptionValidToTo = DateConverter.from(_data["SubscriptionValidToTo"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientId"] = this.ClientId;
    _data["ClientName"] = this.ClientName;
    _data["ClientSource"] = FilterClientSource[this.ClientSource];
    _data["CompanyStatus"] = FilterCompanyStatus[this.CompanyStatus];
    if (this.RegistrationStatuses && this.RegistrationStatuses.constructor === Array) {
      _data["RegistrationStatuses"] = [];
      for (let item of this.RegistrationStatuses) {
        _data["RegistrationStatuses"].push(FilterRegistrationStatuses[item]);
      }
    }
    _data["RegistrationType"] = FilterRegistrationType[this.RegistrationType];
    _data["SignUpDateFrom"] = DateConverter.toUtcDateString(this.SignUpDateFrom);
    _data["SignUpDateTo"] = DateConverter.toUtcDateString(this.SignUpDateTo);
    _data["SubscriptionStatus"] = FilterSubscriptionStatus[this.SubscriptionStatus];
    _data["SubscriptionValidToFrom"] = DateConverter.toUtcDateString(this.SubscriptionValidToFrom);
    _data["SubscriptionValidToTo"] = DateConverter.toUtcDateString(this.SubscriptionValidToTo);
    return _data;
  }
}
export var FilterClientSource = /* @__PURE__ */ ((FilterClientSource2) => {
  FilterClientSource2["Amazon"] = "Amazon";
  FilterClientSource2["KPMG"] = "KPMG";
  FilterClientSource2["FedEx"] = "FedEx";
  FilterClientSource2["LaPoste"] = "LaPoste";
  return FilterClientSource2;
})(FilterClientSource || {});
export var FilterCompanyStatus = /* @__PURE__ */ ((FilterCompanyStatus2) => {
  FilterCompanyStatus2["Active"] = "Active";
  FilterCompanyStatus2["Rejected"] = "Rejected";
  FilterCompanyStatus2["Inactive"] = "Inactive";
  return FilterCompanyStatus2;
})(FilterCompanyStatus || {});
export var FilterRegistrationStatuses = /* @__PURE__ */ ((FilterRegistrationStatuses2) => {
  FilterRegistrationStatuses2["Pending"] = "Pending";
  FilterRegistrationStatuses2["WaitingForSignature"] = "WaitingForSignature";
  FilterRegistrationStatuses2["DocumentsSigned"] = "DocumentsSigned";
  FilterRegistrationStatuses2["SentToTA"] = "SentToTA";
  FilterRegistrationStatuses2["RegistrationCompleted"] = "RegistrationCompleted";
  FilterRegistrationStatuses2["Failed"] = "Failed";
  FilterRegistrationStatuses2["Declined"] = "Declined";
  FilterRegistrationStatuses2["Deregistered"] = "Deregistered";
  FilterRegistrationStatuses2["DeregistrationInProgress"] = "DeregistrationInProgress";
  return FilterRegistrationStatuses2;
})(FilterRegistrationStatuses || {});
export var FilterRegistrationType = /* @__PURE__ */ ((FilterRegistrationType2) => {
  FilterRegistrationType2["NewRegistration"] = "NewRegistration";
  FilterRegistrationType2["TakeOver"] = "TakeOver";
  return FilterRegistrationType2;
})(FilterRegistrationType || {});
export var FilterSubscriptionStatus = /* @__PURE__ */ ((FilterSubscriptionStatus2) => {
  FilterSubscriptionStatus2["Active"] = "Active";
  FilterSubscriptionStatus2["Cancelled"] = "Cancelled";
  FilterSubscriptionStatus2["Expired"] = "Expired";
  FilterSubscriptionStatus2["Pending"] = "Pending";
  return FilterSubscriptionStatus2;
})(FilterSubscriptionStatus || {});
