export const colorWhite = [255, 255, 255];
export const colorBlack = [0, 0, 0];
export const colorDarkGray = [166, 166, 166];
export const colorLightGray = [224, 224, 224];
export const colorUrlBlue = [22, 7, 255];
export const colorRed = [209, 52, 56];
const indentForNumberedTextBox = 6;
export const iossTermsAndConditions = "https://www.kpmg-vat-compliance.com/Content/documents/KGSH-GToB-DPR-en.pdf";
export const privacyStatement = "https://home.kpmg/content/dam/kpmg/hu/pdf/KGSH_Privacy_Statement_v2.pdf";
export const textCentered = (doc, text, y) => {
  doc.text(text, doc.internal.pageSize.width / 2, y, {
    align: "center"
  });
};
export const titleText = (text, x, y, doc) => {
  doc.setFont(doc.getFont().toString(), "bold");
  doc.text(text, x, y);
  doc.setFont(doc.getFont().toString(), "normal");
};
export const numberedParagraph = (data, paragraphY, paragraphX, textBoxWidth, marginBetweenTextBoxes, doc) => {
  let textBoxY = paragraphY;
  for (const textBox of data) {
    numberedTextBox(textBox.displayNumber, textBox.text, paragraphX, textBoxY, textBoxWidth, doc);
    textBoxY = textBoxY + calculateTextBoxHeight(textBox.text, textBoxWidth, doc.getLineHeightFactor(), doc.getFontSize()) + marginBetweenTextBoxes;
  }
};
export const unorderedParagraph = (data, paragraphY, paragraphX, textBoxWidth, marginBetweenTextBoxes, doc) => {
  let textBoxY = paragraphY;
  for (const textBox of data) {
    unorderedTextBox(textBox, paragraphX, textBoxY, textBoxWidth, doc);
    textBoxY = textBoxY + calculateTextBoxHeight(textBox, textBoxWidth, doc.getLineHeightFactor(), doc.getFontSize()) + marginBetweenTextBoxes;
  }
};
export const alphabeticalParagraph = (data, paragraphY, paragraphX, textBoxWidth, marginBetweenTextBoxes, doc) => {
  let textBoxY = paragraphY;
  for (const textBox of data) {
    alphabeticalTextBox(textBox.symbol, textBox.text, paragraphX, textBoxY, textBoxWidth, doc);
    textBoxY = textBoxY + calculateTextBoxHeight(textBox.text, textBoxWidth, doc.getLineHeightFactor(), doc.getFontSize()) + marginBetweenTextBoxes;
  }
};
const calculateTextBoxHeight = (text, paragraphWidth, lineHeight, fontSize) => {
  const ptToMm = 2.834;
  const fontSizeHeight = fontSize / ptToMm;
  const textWidth = calculateStringWidth(text, fontSize);
  const numberOfRows = Math.ceil(textWidth / paragraphWidth);
  return Math.round(numberOfRows * fontSizeHeight * lineHeight);
};
const calculateStringWidth = (text, fontSize) => {
  const ptToPx = 1.364;
  const pxToMm = 3.7;
  const context = document.createElement("canvas").getContext("2d");
  context.font = `${fontSize * ptToPx}px times new roman`;
  return Math.round(context.measureText(text).width / pxToMm);
};
export const numberedTextBox = (index, text, x, y, maxWidth, doc) => {
  doc.text(`${index.toString()}.`, x, y);
  doc.text(makeLastLineNotStickOut(text), x + indentForNumberedTextBox, y, {
    align: "justify",
    maxWidth,
    lineHeightFactor: 1.4
  });
};
export const unorderedTextBox = (text, x, y, maxWidth, doc) => {
  doc.text(`-`, x + 1, y);
  doc.text(makeLastLineNotStickOut(text), x + 5, y, {
    align: "justify",
    maxWidth,
    lineHeightFactor: 1.4
  });
};
export const alphabeticalTextBox = (orderLetter, text, x, y, maxWidth, doc) => {
  doc.text(`${orderLetter}`, x, y);
  doc.text(makeLastLineNotStickOut(text), x + indentForNumberedTextBox, y, {
    align: "justify",
    maxWidth,
    lineHeightFactor: 1.4
  });
};
export const linkText = (urlText, urlPath, x, y, maxWidth, doc) => {
  const urlTextLength = calculateStringWidth(urlText, doc.getFontSize());
  doc.setTextColor(...colorUrlBlue);
  doc.textWithLink(urlText, x, y, {
    url: urlPath,
    align: "justify",
    maxWidth,
    lineHeightFactor: 1.4
  });
  if (urlTextLength < maxWidth) {
    doc.setDrawColor(...colorUrlBlue);
    doc.line(x, y + 1, x + urlTextLength, y + 1);
    doc.setDrawColor(...colorBlack);
  }
  doc.setTextColor(...colorBlack);
};
export const linkInFrontOfTextTextBox = (urlText, urlPath, index, firstLine, text, x, y, maxWidth, doc) => {
  doc.text(`${index.toString()}.`, x, y);
  linkText(urlText, urlPath, x + indentForNumberedTextBox, y, maxWidth, doc);
  doc.text(firstLine, x + indentForNumberedTextBox + calculateStringWidth(urlText, doc.getFontSize()), y, {
    align: "justify",
    maxWidth: maxWidth - indentForNumberedTextBox,
    lineHeightFactor: 1.4
  });
  doc.text(makeLastLineNotStickOut(text), x + indentForNumberedTextBox, y + 4, {
    align: "justify",
    maxWidth: maxWidth - indentForNumberedTextBox,
    lineHeightFactor: 1.4
  });
};
export const appendixText = (appendixTitle, pageNumber, textAfterAppendix, x, y, doc) => {
  const appendixLength = calculateStringWidth(appendixTitle, doc.getFontSize());
  doc.setTextColor(...colorRed);
  doc.textWithLink(appendixTitle, x + indentForNumberedTextBox, y, { pageNumber });
  doc.setDrawColor(...colorRed);
  doc.line(x + indentForNumberedTextBox, y + 1, x + indentForNumberedTextBox + appendixLength, y + 1);
  doc.setDrawColor(...colorBlack);
  doc.setTextColor(...colorBlack);
  doc.text(textAfterAppendix, x + indentForNumberedTextBox + appendixLength, y);
};
const makeLastLineNotStickOut = (text) => {
  return text + "                                ";
};
export const linkWithBrackets = (url, x, y, maxWidth, doc) => {
  const urlTextLength = calculateStringWidth(url, doc.getFontSize());
  doc.text("(", x, y);
  linkText(url, url, x + 1, y, maxWidth, doc);
  doc.text(")", x + 1 + urlTextLength, y);
};
export const getRowIndex = (iossTier) => {
  switch (iossTier) {
    case "Tier0":
      return 1;
    case "Tier1":
      return 2;
    case "Tier2":
      return 3;
    case "Tier3":
      return 4;
    default:
      return 0;
  }
};
export const mainLine = (countryName, price, pLine, pCumulativeTop, tableProps, doc) => {
  doc.text(countryName, tableProps.tableLeftPadding, pCumulativeTop + tableProps.lineHeight * pLine);
  doc.text(`${price} ${tableProps.eurChar}`, tableProps.tableLeftPadding + tableProps.secondCol, pCumulativeTop + tableProps.lineHeight * pLine);
};
export const serviceLine = (serviceName, price, pLine, pCumulativeTop, tableProps, doc) => {
  doc.text(`	${serviceName}`, tableProps.tableLeftPadding, pCumulativeTop + tableProps.lineHeight * pLine);
  doc.text(`${price} ${tableProps.eurChar}`, tableProps.tableLeftPadding + tableProps.secondCol, pCumulativeTop + tableProps.lineHeight * pLine);
};
export const horizontalLine = (x1, y, x2, doc) => {
  doc.line(x1, y, x2, y);
};
