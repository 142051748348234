import cookie from "js-cookie";
import i18next from "i18next";
import moment from "moment";
import Numbro from "numbro";
import cultureEnGB from "numbro/languages/en-GB";
import cultureDeDE from "numbro/languages/de-DE";
import cultureEsES from "numbro/languages/es-ES";
import cultureItIT from "numbro/languages/it-IT";
import cultureFrFR from "numbro/languages/fr-FR";
import cultureZhCN from "numbro/languages/zh-CN";
import languagesJson from "../../../../Infrastructure/Services/WonderWoman.AdminHost/languages.json";
import { getDynamicRoutesByKey } from "GlobalShared/router";
export const languages = Object.assign(
  {},
  ...languagesJson.Languages.Items.map((item) => {
    const key = item.key;
    delete item.key;
    return { [key]: item };
  })
);
const cultureMap = {
  engb: cultureEnGB,
  dede: cultureDeDE,
  eses: cultureEsES,
  itit: cultureItIT,
  frfr: cultureFrFR,
  zhcn: cultureZhCN
};
const pathNameItems = window.location.pathname.split("/");
const countryFromPath = pathNameItems[1] ? pathNameItems[1] : "gb";
const langFromPath = pathNameItems[2] ? pathNameItems[2] : "en";
export const currentLang = `${langFromPath}-${countryFromPath}`;
export const currentLangKey = currentLang.replace("-", "");
export const currentLangPath = `${countryFromPath}/${langFromPath}`;
export const currentLanguage = languages[currentLangKey];
export const currentLocale = currentLanguage.locale;
const culture = cultureMap[currentLangKey];
moment.locale(currentLocale);
Numbro.culture(culture.cultureCode, culture);
Numbro.culture(culture.cultureCode);
Numbro.defaultCurrencyFormat("0,0[.]00");
Numbro.defaultFormat("0,0[.]00");
import enGBPo from "../../../../locale/translated/en-gb.mpclient.json";
i18next.init({
  initImmediate: false,
  nsSeparator: false,
  keySeparator: false,
  lng: currentLangKey,
  fallbackLng: ["engb"],
  returnEmptyString: false,
  resources: {
    // language: { namespace: resourse }
    engb: { translation: enGBPo }
  },
  interpolation: {
    format: (value, format) => {
      if (format === "custom" && value) {
        const linkObject = value;
        return `<a target="blank" href="${linkObject.url}">${linkObject.title}</a>`;
      }
      return value;
    }
  }
});
const CurrencyKey = "currency";
const CurrencyPosition = "prefix";
export const Currencies = ["EUR", "HUF", "GBP", "USD"];
export function changeCurrency(currency) {
  Numbro.cultureData().currency = {
    position: CurrencyPosition,
    symbol: currency
  };
  localStorage.setItem(CurrencyKey, currency);
}
export function getCurrency() {
  const storageCurrency = localStorage.getItem(CurrencyKey);
  const cookieCurrency = cookie.get(CurrencyKey);
  return storageCurrency || cookieCurrency || "EUR";
}
export function getLocalizedUrl(url) {
  if (url.startsWith("/")) {
    return `/${currentLangPath}${url}`;
  } else {
    return `/${currentLangPath}/${url}`;
  }
}
export const getRouteByKey = (key) => {
  const items = getDynamicRoutesByKey(key);
  if (items.length === 1) {
    const route = items[0];
    const translations = route.trans.filter((trans) => trans.locale === currentLocale);
    if (translations.length === 1) {
      const trans = translations[0];
      return getLocalizedUrl(trans.value);
    } else if (items.length > 1) {
      return getLocalizedUrl("ambiguous_trans");
    } else {
      return getLocalizedUrl("notfound_trans");
    }
  } else if (items.length > 1) {
    return getLocalizedUrl("ambiguous");
  } else {
    return getLocalizedUrl("notfound");
  }
};
