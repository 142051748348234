export class Document {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Document
     */
    this.$type = "V1.CompanyInfo.Detail.IossRegistration.Document";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Category = DocumentCategory[_data["Category"]];
      this.DocumentReference = _data["DocumentReference"];
      this.Id = _data["Id"];
      this.Name = _data["Name"];
      this.SignedDocumentReference = _data["SignedDocumentReference"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Category"] = DocumentCategory[this.Category];
    _data["DocumentReference"] = this.DocumentReference;
    _data["Id"] = this.Id;
    _data["Name"] = this.Name;
    _data["SignedDocumentReference"] = this.SignedDocumentReference;
    return _data;
  }
}
export var DocumentCategory = /* @__PURE__ */ ((DocumentCategory2) => {
  DocumentCategory2["RequiredElectronicallyOnly"] = "RequiredElectronicallyOnly";
  DocumentCategory2["ForInformationOnly"] = "ForInformationOnly";
  return DocumentCategory2;
})(DocumentCategory || {});
