import { jsPDF } from "jspdf";
import * as PDFHelper from "GlobalShared/helpers/pdfHelper";
import { ALPHABETICAL_TEXTS, NUMBERED_TEXTS, UNORDERED_TEXTS } from "./amazon-pdf-data";
class WWDoc {
  constructor() {
    this.doc = new jsPDF({ unit: "mm" });
    this.sideMargin = 25;
    this.topMargin = 12;
    this.topMarginForText = 28;
    this.footerY = 280;
    this.pageWidth = this.doc.internal.pageSize.width;
    this.pageCenter = this.doc.internal.pageSize.width / 2;
    this.marginBetweenTextBoxes = 3;
    // numbered textbox
    this.numberedTextBoxWidth = 68;
    this.rightNumberedX = this.pageCenter + 6;
    // unordered textbox
    this.regularUnorderedTextBoxWidth = 65;
    this.smallUnorderedTextBoxWidth = 60;
    this.leftRegularUnorderedX = this.sideMargin + 5;
    this.rightRegularUnorderedX = this.pageCenter + 10;
    this.rightSmallUnorderedX = this.pageCenter + 15;
    // alphabetical ordered textbox
    this.alphabeticalOrderedTextBoxWidth = 60;
    this.leftAlphabeticalX = this.sideMargin + 8;
    this.rightAlphabeticalX = this.pageCenter + 11;
    // header image
    this.headerImageWidth = 15;
    this.headerImageHeight = 6;
  }
}
const header = (wwdoc) => {
  wwdoc.doc.addImage("/images/kpmg_logo.png", "PNG", wwdoc.sideMargin, wwdoc.topMargin, wwdoc.headerImageWidth, wwdoc.headerImageHeight);
};
const footer = (wwdoc, pageNumber) => {
  wwdoc.doc.setFontSize(9);
  wwdoc.doc.setFont("times", "normal");
  wwdoc.doc.text(`Page ${pageNumber}`, wwdoc.sideMargin, wwdoc.footerY);
  wwdoc.doc.text(`Indirect Tax Services`, wwdoc.pageWidth - wwdoc.sideMargin - 2, wwdoc.footerY, { align: "right" });
};
const firstPage = (wwdoc, pdfModel) => {
  header(wwdoc);
  PDFHelper.textCentered(wwdoc.doc, "Engagement contract for Core Tax Compliance Services and Additional Tax Services", wwdoc.topMarginForText);
  wwdoc.doc.setFontSize(9);
  const partnerY = 42;
  const marginBottom = 12;
  wwdoc.doc.text("Full legal name of the contracting party", wwdoc.sideMargin, partnerY);
  wwdoc.doc.text("Legal status of the contracting party", wwdoc.sideMargin, partnerY + marginBottom);
  wwdoc.doc.text("Registered address of the contracting party", wwdoc.sideMargin, partnerY + 2 * marginBottom);
  wwdoc.doc.setLineWidth(0.1);
  wwdoc.doc.text("Signature", wwdoc.sideMargin, partnerY + 3 * marginBottom);
  wwdoc.doc.line(wwdoc.sideMargin + 15, partnerY + 3 * marginBottom + 1, wwdoc.pageCenter - 10, partnerY + 3 * marginBottom + 1);
  wwdoc.doc.text("Date", wwdoc.sideMargin, partnerY + 4 * marginBottom);
  wwdoc.doc.line(wwdoc.sideMargin + 15, partnerY + 4 * marginBottom + 1, wwdoc.pageCenter - 10, partnerY + 4 * marginBottom + 1);
  wwdoc.doc.setFont("times", "normal");
  wwdoc.doc.text(pdfModel.partnerName ? pdfModel.partnerName : "undefined", wwdoc.pageCenter, partnerY);
  wwdoc.doc.text(pdfModel.legalStatus ? pdfModel.legalStatus : "undefined", wwdoc.pageCenter, partnerY + marginBottom);
  wwdoc.doc.text(pdfModel.addressLine ? pdfModel.addressLine : "undefined", wwdoc.pageCenter, partnerY + 2 * marginBottom);
  wwdoc.doc.setFont("times", "bold");
  wwdoc.doc.text("KPMG Reference", wwdoc.sideMargin, partnerY + 5 * marginBottom);
  wwdoc.doc.setFont("times", "normal");
  wwdoc.doc.text(pdfModel.kpmgReference !== void 0 ? pdfModel.kpmgReference : "", wwdoc.sideMargin + 40, partnerY + 5 * marginBottom);
  wwdoc.doc.setFont("times", "bold");
  wwdoc.doc.line(wwdoc.sideMargin + 30, partnerY + 5 * marginBottom + 1, wwdoc.pageCenter - 10, partnerY + 5 * marginBottom + 1);
  wwdoc.doc.setLineWidth(0.3);
  wwdoc.doc.setLineHeightFactor(16);
  wwdoc.doc.line(wwdoc.sideMargin, 110, wwdoc.pageWidth - wwdoc.sideMargin, 110);
  wwdoc.doc.setLineHeightFactor(1.6);
  PDFHelper.titleText(`General`, wwdoc.sideMargin, 117, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(0, 5), 125, wwdoc.sideMargin, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenTextBoxes, wwdoc.doc);
  PDFHelper.titleText(`Our services`, wwdoc.sideMargin, 255, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[5].displayNumber, `Core Tax Compliance Services includes the`, wwdoc.sideMargin, 263, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  wwdoc.doc.text(`following services:`, wwdoc.rightRegularUnorderedX, 117);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(0, 3), 125, wwdoc.rightRegularUnorderedX, wwdoc.regularUnorderedTextBoxWidth, wwdoc.marginBetweenTextBoxes, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(3, 5), 190, wwdoc.rightSmallUnorderedX, wwdoc.smallUnorderedTextBoxWidth, wwdoc.marginBetweenTextBoxes, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(5, 7), 212, wwdoc.rightRegularUnorderedX, wwdoc.regularUnorderedTextBoxWidth, wwdoc.marginBetweenTextBoxes, wwdoc.doc);
  footer(wwdoc, 1);
};
const secondPage = (wwdoc) => {
  wwdoc.doc.addPage();
  header(wwdoc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(7, 11), wwdoc.topMarginForText, wwdoc.leftRegularUnorderedX, wwdoc.regularUnorderedTextBoxWidth, wwdoc.marginBetweenTextBoxes, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[6].displayNumber, NUMBERED_TEXTS[6].text, wwdoc.sideMargin, 99, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(11, 17), 126, wwdoc.leftRegularUnorderedX, wwdoc.regularUnorderedTextBoxWidth, wwdoc.marginBetweenTextBoxes, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[7].displayNumber, NUMBERED_TEXTS[7].text, wwdoc.sideMargin, 240, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  wwdoc.doc.text(
    `provide advice as our subcontractor. Such assistance will be subject to a separate fee to be advised by the other KPMG firm. We will not engage the subcontractor without your prior written acceptance of their fee estimate.`,
    wwdoc.rightNumberedX + 6,
    wwdoc.topMarginForText,
    { maxWidth: wwdoc.numberedTextBoxWidth }
  );
  PDFHelper.titleText(`Your obligations`, wwdoc.rightNumberedX, 56, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(8, 15), 64, wwdoc.rightNumberedX, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenTextBoxes, wwdoc.doc);
  footer(wwdoc, 2);
};
const thirdPage = (wwdoc) => {
  wwdoc.doc.addPage();
  header(wwdoc);
  wwdoc.doc.text(
    `not receive your explicit approval (or alternative instruction) by the earlier of 5 working days after we send the draft return to you or 12:00 CET on the submission deadline, we will assume that you agree with our calculations and that KPMG should submit the return to the tax authority, where permitted to do so.`,
    wwdoc.sideMargin + 6,
    wwdoc.topMarginForText,
    {
      align: "justify",
      maxWidth: wwdoc.numberedTextBoxWidth
    }
  );
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(15, 21), 67, wwdoc.sideMargin, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenTextBoxes, wwdoc.doc);
  wwdoc.doc.text(`affairs in accordance with legal requirements.`, wwdoc.rightRegularUnorderedX, wwdoc.topMarginForText, {
    align: "justify",
    maxWidth: 65,
    lineHeightFactor: 1.6
  });
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[21].displayNumber, NUMBERED_TEXTS[21].text, wwdoc.rightNumberedX, 34, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.titleText(`Payment for services`, wwdoc.rightNumberedX, 66, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(22, 27), 74, wwdoc.rightNumberedX, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenTextBoxes, wwdoc.doc);
  PDFHelper.titleText(`Termination`, wwdoc.rightNumberedX, 186, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[27].displayNumber, NUMBERED_TEXTS[27].text, wwdoc.rightNumberedX, 194, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.alphabeticalParagraph(ALPHABETICAL_TEXTS.slice(0, 2), 222, wwdoc.rightAlphabeticalX, wwdoc.alphabeticalOrderedTextBoxWidth, wwdoc.marginBetweenTextBoxes, wwdoc.doc);
  footer(wwdoc, 3);
};
const fourthPage = (wwdoc) => {
  wwdoc.doc.addPage();
  header(wwdoc);
  PDFHelper.alphabeticalTextBox(ALPHABETICAL_TEXTS[2].symbol, ALPHABETICAL_TEXTS[2].text, wwdoc.leftAlphabeticalX, wwdoc.topMarginForText, wwdoc.alphabeticalOrderedTextBoxWidth, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(28, 30), 51, wwdoc.sideMargin, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenTextBoxes, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[30].displayNumber, NUMBERED_TEXTS[30].text, wwdoc.rightNumberedX, wwdoc.topMarginForText, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.titleText(`Limitation of liability`, wwdoc.rightNumberedX, 52, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[31].displayNumber, NUMBERED_TEXTS[31].text, wwdoc.rightNumberedX, 60, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  footer(wwdoc, 4);
};
const servicesPage = (wwdoc, pdfModel) => {
  var _a;
  wwdoc.doc.addPage();
  header(wwdoc);
  PDFHelper.titleText("Order Summary", wwdoc.sideMargin, wwdoc.topMarginForText, wwdoc.doc);
  const lineHeight = 6;
  const secondCol = 80;
  let cumulativeTop = 1;
  let line = 0;
  const eurChar = "EUR";
  function mainLine(countryName, price, pLine, pCumulativeTop) {
    wwdoc.doc.text(countryName, wwdoc.sideMargin, wwdoc.topMarginForText + pCumulativeTop + lineHeight * pLine);
    wwdoc.doc.text(`${price} ${eurChar}`, wwdoc.sideMargin + secondCol, wwdoc.topMarginForText + cumulativeTop + lineHeight * pLine);
  }
  const serviceLine = (serviceName, price, pLine, pCumulativeTop) => {
    wwdoc.doc.text(`	${serviceName}`, wwdoc.sideMargin, wwdoc.topMarginForText + pCumulativeTop + lineHeight * pLine);
    wwdoc.doc.text(`${price} ${eurChar}`, wwdoc.sideMargin + secondCol, wwdoc.topMarginForText + pCumulativeTop + lineHeight * pLine);
  };
  (_a = pdfModel.summary.countries) == null ? void 0 : _a.map((country) => {
    cumulativeTop += 2;
    line++;
    mainLine(country.label, country.total, line, cumulativeTop);
    if (country.fiscalRep > 0) {
      line++;
      serviceLine("Fiscal Representation", country.fiscalRep, line, cumulativeTop);
    }
    if (country.spanishBankAccount > 0) {
      line++;
      serviceLine("Spanish VAT Payment Service", country.spanishBankAccount, line, cumulativeTop);
    }
    if (country.retro > 0) {
      line++;
      serviceLine("Retrospective Return", country.retro, line, cumulativeTop);
    }
    if (country.intrastat > 0) {
      line++;
      serviceLine("Intrastat Report", country.intrastat, line, cumulativeTop);
    }
  });
  if (pdfModel.summary.eori > 0) {
    cumulativeTop += 2;
    line++;
    mainLine("EORI number application in the EU", pdfModel.summary.eori, line, cumulativeTop);
  }
  if (pdfModel.summary.ukEori > 0) {
    cumulativeTop += 2;
    line++;
    mainLine("EORI number application in the UK", pdfModel.summary.ukEori, line, cumulativeTop);
  }
  cumulativeTop += 5;
  wwdoc.doc.line(wwdoc.sideMargin, wwdoc.topMarginForText + line * lineHeight + cumulativeTop, wwdoc.sideMargin + secondCol + 20, wwdoc.topMarginForText + line * lineHeight + cumulativeTop);
  line++;
  mainLine("Sub-total", pdfModel.summary.subTotal, line, cumulativeTop);
  line++;
  mainLine("VAT", pdfModel.summary.vat, line, cumulativeTop);
  cumulativeTop += 3;
  wwdoc.doc.line(wwdoc.sideMargin, wwdoc.topMarginForText + line * lineHeight + cumulativeTop, wwdoc.sideMargin + secondCol + 20, wwdoc.topMarginForText + line * lineHeight + cumulativeTop);
  wwdoc.doc.setFont("times", "bold");
  wwdoc.doc.setFontSize(11);
  line++;
  mainLine("Total fee", pdfModel.summary.total, line, cumulativeTop);
  footer(wwdoc, 5);
};
export const generateAmazonPDF = (pdfModel) => {
  const wwdoc = new WWDoc();
  wwdoc.doc.setFont("times", "bold");
  wwdoc.doc.setFontSize(11);
  firstPage(wwdoc, pdfModel);
  secondPage(wwdoc);
  thirdPage(wwdoc);
  fourthPage(wwdoc);
  servicesPage(wwdoc, pdfModel);
  wwdoc.doc.save(`Contract_${pdfModel.partnerName}_${pdfModel.kpmgReference ? pdfModel.kpmgReference : ""}.pdf`);
  return wwdoc.doc.output("blob");
};
