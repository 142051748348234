import { UpdatedService } from "./UpdatedService";
export class UpdatedOrder {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof UpdatedOrder
     */
    this.$type = "V1.Orders.Update.UpdatedOrder";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["UpdatedServices"] && _data["UpdatedServices"].constructor === Array) {
        this.UpdatedServices = [];
        for (let item of _data["UpdatedServices"]) {
          const updatedServices_ = new UpdatedService();
          this.UpdatedServices.push(updatedServices_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.UpdatedServices && this.UpdatedServices.constructor === Array) {
      _data["UpdatedServices"] = [];
      for (let item of this.UpdatedServices) {
        _data["UpdatedServices"].push(item.toJSON());
      }
    }
    return _data;
  }
}
