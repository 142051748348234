import { BusinessDetailFactory } from "./../../CompanyInfo/BusinessDetailFactory";
import { VatRegistration } from "./VatRegistration";
import { IossRegistration } from "./IossRegistration";
import { SentinelInformation } from "./../../CompanyInfo/Detail/SentinelInformation";
import { DateConverter } from "./../../../../converters/DateConverter";
import { SubscriptionFactory } from "./../../CompanyInfo/Subscriptions/SubscriptionFactory";
import { EoriRegistration } from "./../../CompanyInfo/Detail/EoriRegistrations/EoriRegistration";
export class CompanyDetail {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CompanyDetail
     */
    this.$type = "V1.Company.CompanyInfo.CompanyDetail";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      const $typeBusinessDetail = _data["BusinessDetail"] && _data["BusinessDetail"].$type;
      if ($typeBusinessDetail) {
        const businessDetail_ = BusinessDetailFactory.create($typeBusinessDetail);
        this.BusinessDetail = businessDetail_.init(_data["BusinessDetail"]);
      } else {
        this.BusinessDetail = _data["BusinessDetail"];
      }
      this.ClientAcceptance = CompanyDetailClientAcceptance[_data["ClientAcceptance"]];
      this.ClientAcceptanceCompleted = DateConverter.from(_data["ClientAcceptanceCompleted"]);
      this.ClientSource = CompanyDetailClientSource[_data["ClientSource"]];
      this.ContactEmail = _data["ContactEmail"];
      this.ContactLanguage = _data["ContactLanguage"];
      this.ContactPhone = _data["ContactPhone"];
      this.EmsAccountNumber = _data["EmsAccountNumber"];
      if (_data["EoriRegistrations"] && _data["EoriRegistrations"].constructor === Array) {
        this.EoriRegistrations = [];
        for (let item of _data["EoriRegistrations"]) {
          const eoriRegistrations_ = new EoriRegistration();
          this.EoriRegistrations.push(eoriRegistrations_.init(item));
        }
      }
      this.GisIdentifier = _data["GisIdentifier"];
      this.Id = _data["Id"];
      if (_data["IossRegistrations"] && _data["IossRegistrations"].constructor === Array) {
        this.IossRegistrations = [];
        for (let item of _data["IossRegistrations"]) {
          const iossRegistrations_ = new IossRegistration();
          this.IossRegistrations.push(iossRegistrations_.init(item));
        }
      }
      this.IsEmsNumberEditable = _data["IsEmsNumberEditable"];
      this.IsInvoicingEnabled = _data["IsInvoicingEnabled"];
      this.IsVatNumberValid = _data["IsVatNumberValid"];
      this.QuestionnaireStatus = CompanyDetailQuestionnaireStatus[_data["QuestionnaireStatus"]];
      if (_data["SentinelInformation"]) {
        const sentinelInformation_ = new SentinelInformation();
        this.SentinelInformation = sentinelInformation_.init(_data["SentinelInformation"]);
      }
      this.Status = CompanyDetailStatus[_data["Status"]];
      if (_data["Subscriptions"] && _data["Subscriptions"].constructor === Array) {
        this.Subscriptions = [];
        for (let item of _data["Subscriptions"]) {
          const $typeSubscriptions = item && item.$type;
          if ($typeSubscriptions) {
            const subscriptions_ = SubscriptionFactory.create($typeSubscriptions);
            this.Subscriptions.push(subscriptions_.init(item));
          }
        }
      }
      if (_data["VatRegistrations"] && _data["VatRegistrations"].constructor === Array) {
        this.VatRegistrations = [];
        for (let item of _data["VatRegistrations"]) {
          const vatRegistrations_ = new VatRegistration();
          this.VatRegistrations.push(vatRegistrations_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["BusinessDetail"] = this.BusinessDetail ? this.BusinessDetail.toJSON() : void 0;
    _data["ClientAcceptance"] = CompanyDetailClientAcceptance[this.ClientAcceptance];
    _data["ClientAcceptanceCompleted"] = DateConverter.toUtcDateString(this.ClientAcceptanceCompleted);
    _data["ClientSource"] = CompanyDetailClientSource[this.ClientSource];
    _data["ContactEmail"] = this.ContactEmail;
    _data["ContactLanguage"] = this.ContactLanguage;
    _data["ContactPhone"] = this.ContactPhone;
    _data["EmsAccountNumber"] = this.EmsAccountNumber;
    if (this.EoriRegistrations && this.EoriRegistrations.constructor === Array) {
      _data["EoriRegistrations"] = [];
      for (let item of this.EoriRegistrations) {
        _data["EoriRegistrations"].push(item.toJSON());
      }
    }
    _data["GisIdentifier"] = this.GisIdentifier;
    _data["Id"] = this.Id;
    if (this.IossRegistrations && this.IossRegistrations.constructor === Array) {
      _data["IossRegistrations"] = [];
      for (let item of this.IossRegistrations) {
        _data["IossRegistrations"].push(item.toJSON());
      }
    }
    _data["IsEmsNumberEditable"] = this.IsEmsNumberEditable;
    _data["IsInvoicingEnabled"] = this.IsInvoicingEnabled;
    _data["IsVatNumberValid"] = this.IsVatNumberValid;
    _data["QuestionnaireStatus"] = CompanyDetailQuestionnaireStatus[this.QuestionnaireStatus];
    _data["SentinelInformation"] = this.SentinelInformation ? this.SentinelInformation.toJSON() : void 0;
    _data["Status"] = CompanyDetailStatus[this.Status];
    if (this.Subscriptions && this.Subscriptions.constructor === Array) {
      _data["Subscriptions"] = [];
      for (let item of this.Subscriptions) {
        _data["Subscriptions"].push(item.toJSON());
      }
    }
    if (this.VatRegistrations && this.VatRegistrations.constructor === Array) {
      _data["VatRegistrations"] = [];
      for (let item of this.VatRegistrations) {
        _data["VatRegistrations"].push(item.toJSON());
      }
    }
    return _data;
  }
}
export var CompanyDetailClientAcceptance = /* @__PURE__ */ ((CompanyDetailClientAcceptance2) => {
  CompanyDetailClientAcceptance2["Pending"] = "Pending";
  CompanyDetailClientAcceptance2["Completed"] = "Completed";
  CompanyDetailClientAcceptance2["Failed"] = "Failed";
  CompanyDetailClientAcceptance2["MFE"] = "MFE";
  return CompanyDetailClientAcceptance2;
})(CompanyDetailClientAcceptance || {});
export var CompanyDetailClientSource = /* @__PURE__ */ ((CompanyDetailClientSource2) => {
  CompanyDetailClientSource2["Amazon"] = "Amazon";
  CompanyDetailClientSource2["KPMG"] = "KPMG";
  CompanyDetailClientSource2["FedEx"] = "FedEx";
  CompanyDetailClientSource2["LaPoste"] = "LaPoste";
  return CompanyDetailClientSource2;
})(CompanyDetailClientSource || {});
export var CompanyDetailQuestionnaireStatus = /* @__PURE__ */ ((CompanyDetailQuestionnaireStatus2) => {
  CompanyDetailQuestionnaireStatus2["Incomplete"] = "Incomplete";
  CompanyDetailQuestionnaireStatus2["Completed"] = "Completed";
  CompanyDetailQuestionnaireStatus2["Approved"] = "Approved";
  CompanyDetailQuestionnaireStatus2["DataVerified"] = "DataVerified";
  return CompanyDetailQuestionnaireStatus2;
})(CompanyDetailQuestionnaireStatus || {});
export var CompanyDetailStatus = /* @__PURE__ */ ((CompanyDetailStatus2) => {
  CompanyDetailStatus2["Active"] = "Active";
  CompanyDetailStatus2["Rejected"] = "Rejected";
  CompanyDetailStatus2["Inactive"] = "Inactive";
  return CompanyDetailStatus2;
})(CompanyDetailStatus || {});
