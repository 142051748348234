var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import "./dc-tabs.scss";
import { html, TemplateResult } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useQueryState } from "GlobalShared/haunted/CustomHooks";
import { component } from "haunted";
const DEFAULTS = {
  queryPrefix: "tab",
  data: []
};
export const Component = (host) => {
  const props = {
    queryPrefix: host.queryPrefix !== void 0 ? host.queryPrefix : DEFAULTS.queryPrefix,
    data: host.data !== void 0 ? host.data : DEFAULTS.data
  };
  const getVisibleTabs = (tabs) => {
    return tabs.filter((tab) => !tab.isUnavailable);
  };
  const getTemplate = (tab) => {
    if (tab.template) {
      if (tab.template instanceof TemplateResult) {
        return tab.template;
      } else {
        return tab.template();
      }
    } else if (tab.tag) {
      const content = `<${tab.tag}></${tab.tag}>`;
      return html` ${unsafeHTML(content)} `;
    } else {
      return html``;
    }
  };
  const onTabSelect = (e) => {
    const tabName = e.currentTarget.dataset.tabName;
    setSelectedTab(tabName);
  };
  const [selectedTab, setSelectedTab] = useQueryState(props.queryPrefix, getVisibleTabs(props.data)[0].name);
  const constantClasses = {
    "text-xs": true,
    "sm:text-sm": true,
    "md:text-base": true,
    "mr-2": true,
    "sm:mr-4": true,
    "px-1": true,
    "sm:px-5": true
  };
  return html`
		<ul class="tab-navigation">
			${getVisibleTabs(props.data).map(
    (tab) => html`
					<li
						data-tab-name=${tab.name}
						class="${classMap(__spreadValues({
      active: selectedTab === tab.name
    }, constantClasses))}}"
						@click=${onTabSelect}
						title=${tab.name}
					>
						${tab.label}
					</li>
				`
  )}
		</ul>
		${getVisibleTabs(props.data).map((tab) => html`<div class="tab-panel ${tab.name === selectedTab ? "active" : ""}">${getTemplate(tab)}</div>`)}
	`;
};
customElements.define(
  "dc-tabs",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
