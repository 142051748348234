export var BeneficialOwnerBeneficialOwnerType = /* @__PURE__ */ ((BeneficialOwnerBeneficialOwnerType2) => {
  BeneficialOwnerBeneficialOwnerType2["NaturalPerson"] = "NaturalPerson";
  BeneficialOwnerBeneficialOwnerType2["ExecutiveOfficer"] = "ExecutiveOfficer";
  return BeneficialOwnerBeneficialOwnerType2;
})(BeneficialOwnerBeneficialOwnerType || {});
export var BeneficialOwnerTypeOfOwnership = /* @__PURE__ */ ((BeneficialOwnerTypeOfOwnership2) => {
  BeneficialOwnerTypeOfOwnership2["VotingRights"] = "VotingRights";
  BeneficialOwnerTypeOfOwnership2["DecisiveInfluence"] = "DecisiveInfluence";
  return BeneficialOwnerTypeOfOwnership2;
})(BeneficialOwnerTypeOfOwnership || {});
