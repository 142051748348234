import { File } from "./File";
import { AmazonFile } from "./AmazonFile";
import { AmazonSaftFile } from "./AmazonSaftFile";
import { IossFile } from "./IossFile";
import { KpmgTemplateFile } from "./KpmgTemplateFile";
export class FileFactory {
  static create($type) {
    if ($type === "V1.ComplianceUploads.Files.File") {
      const result = new File();
      return result;
    }
    if ($type === "V1.ComplianceUploads.Files.AmazonFile") {
      const result = new AmazonFile();
      return result;
    }
    if ($type === "V1.ComplianceUploads.Files.AmazonSaftFile") {
      const result = new AmazonSaftFile();
      return result;
    }
    if ($type === "V1.ComplianceUploads.Files.IossFile") {
      const result = new IossFile();
      return result;
    }
    if ($type === "V1.ComplianceUploads.Files.KpmgTemplateFile") {
      const result = new KpmgTemplateFile();
      return result;
    }
    throw new Error($type + " not found");
  }
}
