import { TswagClient } from "./../commons/TswagClient";
import { SearchResult } from "./../models/V1/Submission/Search/SearchResult";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { SubmissionPeriodFiles } from "./../models/V1/Submission/SubmissionPeriodFiles";
import { FrequencyConfigurationOption } from "./../models/V1/Submission/FrequencyConfigurationOption";
export class SubmissionPeriods {
  /**
   * Creates an instance of SubmissionPeriods.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof SubmissionPeriods
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SearchSubmissionsRq_ _searchSubmissionsRq
   * @returns Promise<SearchSubmissionsRq_>
   * @memberof SubmissionPeriods
   */
  searchSubmissions(pSearchSubmissionsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/SubmissionPeriods/Search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchSubmissionsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetSubmissionPeriodFilesRq_ _getSubmissionPeriodFilesRq
   * @returns Promise<GetSubmissionPeriodFilesRq_>
   * @memberof SubmissionPeriods
   */
  getSubmissionPeriodFiles(pGetSubmissionPeriodFilesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/SubmissionPeriods/{submissionPeriodId}/Files",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "submissionPeriodId", value: pGetSubmissionPeriodFilesRq.submissionPeriodId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SubmissionPeriodFiles },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeStatusRq_ _changeStatusRq
   * @returns Promise<ChangeStatusRq_>
   * @memberof SubmissionPeriods
   */
  changeStatus(pChangeStatusRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/SubmissionPeriods/{submissionPeriodId}/Status",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "submissionPeriodId", value: pChangeStatusRq.submissionPeriodId, required: true }
    ]);
    client.addBody({ value: pChangeStatusRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangePaymentStatusRq_ _changePaymentStatusRq
   * @returns Promise<ChangePaymentStatusRq_>
   * @memberof SubmissionPeriods
   */
  changePaymentStatus(pChangePaymentStatusRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/SubmissionPeriods/{submissionPeriodId}/PaymentStatus",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "submissionPeriodId", value: pChangePaymentStatusRq.submissionPeriodId, required: true }
    ]);
    client.addBody({ value: pChangePaymentStatusRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadDocumentRq_ _downloadDocumentRq
   * @returns Promise<DownloadDocumentRq_>
   * @memberof SubmissionPeriods
   */
  downloadDocument(pDownloadDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/SubmissionPeriods/{submissionPeriodId}/Documents/{type}/{documentId}",
      "POST",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([
      { name: "submissionPeriodId", value: pDownloadDocumentRq.submissionPeriodId, required: true },
      { name: "documentId", value: pDownloadDocumentRq.documentId, required: true },
      { name: "type", value: pDownloadDocumentRq.type, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadSubmissionProofRq_ _uploadSubmissionProofRq
   * @returns Promise<UploadSubmissionProofRq_>
   * @memberof SubmissionPeriods
   */
  uploadSubmissionProof(pUploadSubmissionProofRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/SubmissionPeriods/{submissionPeriodId}/Documents/SubmissionProof",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "submissionPeriodId", value: pUploadSubmissionProofRq.submissionPeriodId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadSubmissionProofRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadPaymentProofRq_ _uploadPaymentProofRq
   * @returns Promise<UploadPaymentProofRq_>
   * @memberof SubmissionPeriods
   */
  uploadPaymentProof(pUploadPaymentProofRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/SubmissionPeriods/{submissionPeriodId}/Documents/PaymentProof",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "submissionPeriodId", value: pUploadPaymentProofRq.submissionPeriodId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadPaymentProofRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadRejectionAttachmentsRq_ _uploadRejectionAttachmentsRq
   * @returns Promise<UploadRejectionAttachmentsRq_>
   * @memberof SubmissionPeriods
   */
  uploadRejectionAttachments(pUploadRejectionAttachmentsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/SubmissionPeriods/{submissionPeriodId}/rejection/attachments",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "submissionPeriodId", value: pUploadRejectionAttachmentsRq.submissionPeriodId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "files", value: pUploadRejectionAttachmentsRq.files }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isArray: true },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetEffectiveSubmissionConfigRq_ _getEffectiveSubmissionConfigRq
   * @returns Promise<GetEffectiveSubmissionConfigRq_>
   * @memberof SubmissionPeriods
   */
  getEffectiveSubmissionConfig(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/SubmissionPeriods/EffectiveSubmissionConfig",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: FrequencyConfigurationOption, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
export var DownloadDocumentType = /* @__PURE__ */ ((DownloadDocumentType2) => {
  DownloadDocumentType2["SubmissionProof"] = "SubmissionProof";
  DownloadDocumentType2["SubmissionProofIntrastatIn"] = "SubmissionProofIntrastatIn";
  DownloadDocumentType2["SubmissionProofIntrastatOut"] = "SubmissionProofIntrastatOut";
  DownloadDocumentType2["PaymentProof"] = "PaymentProof";
  DownloadDocumentType2["NotToBeSubmittedProof"] = "NotToBeSubmittedProof";
  DownloadDocumentType2["Return"] = "Return";
  DownloadDocumentType2["DataVat"] = "DataVat";
  DownloadDocumentType2["Transaction"] = "Transaction";
  DownloadDocumentType2["InvalidVatNumbersReport"] = "InvalidVatNumbersReport";
  DownloadDocumentType2["OutOfScopeFile"] = "OutOfScopeFile";
  return DownloadDocumentType2;
})(DownloadDocumentType || {});
