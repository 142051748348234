export class SelectedService {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SelectedService
     */
    this.$type = "V1.Services.SelectedService";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CountryCode = _data["CountryCode"];
      this.ServiceCode = SelectedServiceServiceCode[_data["ServiceCode"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CountryCode"] = this.CountryCode;
    _data["ServiceCode"] = SelectedServiceServiceCode[this.ServiceCode];
    return _data;
  }
}
export var SelectedServiceServiceCode = /* @__PURE__ */ ((SelectedServiceServiceCode2) => {
  SelectedServiceServiceCode2["VatCompliance"] = "VatCompliance";
  SelectedServiceServiceCode2["VatMonthlyCompliance"] = "VatMonthlyCompliance";
  SelectedServiceServiceCode2["VatRegistration"] = "VatRegistration";
  SelectedServiceServiceCode2["VatTakeOver"] = "VatTakeOver";
  SelectedServiceServiceCode2["VatSpanishBankAccount"] = "VatSpanishBankAccount";
  SelectedServiceServiceCode2["VatFiscalRepresentative"] = "VatFiscalRepresentative";
  SelectedServiceServiceCode2["VatFilingAgent"] = "VatFilingAgent";
  SelectedServiceServiceCode2["VatRetrospectiveReturn"] = "VatRetrospectiveReturn";
  SelectedServiceServiceCode2["VatIntrastatIn"] = "VatIntrastatIn";
  SelectedServiceServiceCode2["VatIntrastatOut"] = "VatIntrastatOut";
  SelectedServiceServiceCode2["VatDeregistration"] = "VatDeregistration";
  SelectedServiceServiceCode2["VatSelfRevision"] = "VatSelfRevision";
  SelectedServiceServiceCode2["VatHomeCountryCompliance"] = "VatHomeCountryCompliance";
  SelectedServiceServiceCode2["IossComplianceTier0"] = "IossComplianceTier0";
  SelectedServiceServiceCode2["IossComplianceTier1"] = "IossComplianceTier1";
  SelectedServiceServiceCode2["IossComplianceTier2"] = "IossComplianceTier2";
  SelectedServiceServiceCode2["IossComplianceTier3"] = "IossComplianceTier3";
  SelectedServiceServiceCode2["IossRegistration"] = "IossRegistration";
  SelectedServiceServiceCode2["IossTakeOver"] = "IossTakeOver";
  SelectedServiceServiceCode2["IossDeregistration"] = "IossDeregistration";
  SelectedServiceServiceCode2["OssCompliance"] = "OssCompliance";
  SelectedServiceServiceCode2["OssRegistration"] = "OssRegistration";
  SelectedServiceServiceCode2["OssTakeOver"] = "OssTakeOver";
  SelectedServiceServiceCode2["OssDeregistration"] = "OssDeregistration";
  SelectedServiceServiceCode2["GlobalEoriUk"] = "GlobalEoriUk";
  SelectedServiceServiceCode2["GlobalEoriEu"] = "GlobalEoriEu";
  SelectedServiceServiceCode2["GlobalGeneralVatAdvice"] = "GlobalGeneralVatAdvice";
  SelectedServiceServiceCode2["GlobalSpecialistVatAdvice"] = "GlobalSpecialistVatAdvice";
  SelectedServiceServiceCode2["GlobalCommunicatingWithTaxAuthorities"] = "GlobalCommunicatingWithTaxAuthorities";
  SelectedServiceServiceCode2["GlobalChangeCompanyDetail"] = "GlobalChangeCompanyDetail";
  SelectedServiceServiceCode2["GlobalContactingTaxAuthorities"] = "GlobalContactingTaxAuthorities";
  SelectedServiceServiceCode2["GlobalTranslation"] = "GlobalTranslation";
  SelectedServiceServiceCode2["GlobalTaxCertificate"] = "GlobalTaxCertificate";
  SelectedServiceServiceCode2["GlobalIntrastatThresholdCheck"] = "GlobalIntrastatThresholdCheck";
  SelectedServiceServiceCode2["GlobalDistanceSaleThresholdCheck"] = "GlobalDistanceSaleThresholdCheck";
  return SelectedServiceServiceCode2;
})(SelectedServiceServiceCode || {});
