import {
  ApprovedStatusChange,
  BackToCompletedStatusChange,
  BackToDataVerifiedStatusChange,
  BackToIncompleteStatusChange,
  FinalizeStatusChange,
  VerifyStatusChange
} from "AdminServices/APIs/self/models/V1/Company/QuestionnaireStatusChange";
export const getQuestionnaireStatusChange = (fromStatus, toStatus, comment) => {
  const pair = `${fromStatus}-${toStatus}`;
  const factory = {
    "Incomplete-Completed": () => new FinalizeStatusChange(),
    "Completed-DataVerified": () => new VerifyStatusChange(),
    "DataVerified-Approved": () => new ApprovedStatusChange(),
    "Approved-DataVerified": () => new BackToDataVerifiedStatusChange(),
    "DataVerified-Completed": () => new BackToCompletedStatusChange(),
    "Completed-Incomplete": () => new BackToIncompleteStatusChange({ Comment: comment })
  };
  return factory[pair]();
};
