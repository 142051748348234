import { Money } from "./../../Common/Money";
import { DateConverter } from "./../../../../converters/DateConverter";
import { BillingDocument } from "./BillingDocument";
import { PaymentFactory } from "./PaymentFactory";
import { ServiceFactory } from "./../Services/ServiceFactory";
export class Order {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Order
     */
    this.$type = "V1.Orders.Get.Order";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["BillingDocuments"] && _data["BillingDocuments"].constructor === Array) {
        this.BillingDocuments = [];
        for (let item of _data["BillingDocuments"]) {
          const billingDocuments_ = new BillingDocument();
          this.BillingDocuments.push(billingDocuments_.init(item));
        }
      }
      this.CancelledAt = DateConverter.from(_data["CancelledAt"]);
      this.ClientSource = OrderClientSource[_data["ClientSource"]];
      this.CompanyId = _data["CompanyId"];
      this.ConfirmedAt = DateConverter.from(_data["ConfirmedAt"]);
      this.ContractId = _data["ContractId"];
      this.CreatedAt = DateConverter.from(_data["CreatedAt"]);
      this.HasContractReference = _data["HasContractReference"];
      this.Id = _data["Id"];
      this.NeedToGenerateInvoice = _data["NeedToGenerateInvoice"];
      if (_data["Payments"] && _data["Payments"].constructor === Array) {
        this.Payments = [];
        for (let item of _data["Payments"]) {
          const $typePayments = item && item.$type;
          if ($typePayments) {
            const payments_ = PaymentFactory.create($typePayments);
            this.Payments.push(payments_.init(item));
          }
        }
      }
      this.PaymentStatus = OrderPaymentStatus[_data["PaymentStatus"]];
      if (_data["Services"] && _data["Services"].constructor === Array) {
        this.Services = [];
        for (let item of _data["Services"]) {
          const $typeServices = item && item.$type;
          if ($typeServices) {
            const services_ = ServiceFactory.create($typeServices);
            this.Services.push(services_.init(item));
          }
        }
      }
      this.Status = OrderStatus[_data["Status"]];
      if (_data["SubTotal"]) {
        const subTotal_ = new Money();
        this.SubTotal = subTotal_.init(_data["SubTotal"]);
      }
      if (_data["Total"]) {
        const total_ = new Money();
        this.Total = total_.init(_data["Total"]);
      }
      if (_data["Vat"]) {
        const vat_ = new Money();
        this.Vat = vat_.init(_data["Vat"]);
      }
      this.Version = _data["Version"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.BillingDocuments && this.BillingDocuments.constructor === Array) {
      _data["BillingDocuments"] = [];
      for (let item of this.BillingDocuments) {
        _data["BillingDocuments"].push(item.toJSON());
      }
    }
    _data["CancelledAt"] = DateConverter.toIsoUtcString(this.CancelledAt);
    _data["ClientSource"] = OrderClientSource[this.ClientSource];
    _data["CompanyId"] = this.CompanyId;
    _data["ConfirmedAt"] = DateConverter.toIsoUtcString(this.ConfirmedAt);
    _data["ContractId"] = this.ContractId;
    _data["CreatedAt"] = DateConverter.toIsoUtcString(this.CreatedAt);
    _data["HasContractReference"] = this.HasContractReference;
    _data["Id"] = this.Id;
    _data["NeedToGenerateInvoice"] = this.NeedToGenerateInvoice;
    if (this.Payments && this.Payments.constructor === Array) {
      _data["Payments"] = [];
      for (let item of this.Payments) {
        _data["Payments"].push(item.toJSON());
      }
    }
    _data["PaymentStatus"] = OrderPaymentStatus[this.PaymentStatus];
    if (this.Services && this.Services.constructor === Array) {
      _data["Services"] = [];
      for (let item of this.Services) {
        _data["Services"].push(item.toJSON());
      }
    }
    _data["Status"] = OrderStatus[this.Status];
    _data["SubTotal"] = this.SubTotal ? this.SubTotal.toJSON() : void 0;
    _data["Total"] = this.Total ? this.Total.toJSON() : void 0;
    _data["Vat"] = this.Vat ? this.Vat.toJSON() : void 0;
    _data["Version"] = this.Version;
    return _data;
  }
}
export var OrderClientSource = /* @__PURE__ */ ((OrderClientSource2) => {
  OrderClientSource2["Amazon"] = "Amazon";
  OrderClientSource2["KPMG"] = "KPMG";
  OrderClientSource2["FedEx"] = "FedEx";
  OrderClientSource2["LaPoste"] = "LaPoste";
  return OrderClientSource2;
})(OrderClientSource || {});
export var OrderPaymentStatus = /* @__PURE__ */ ((OrderPaymentStatus2) => {
  OrderPaymentStatus2["None"] = "None";
  OrderPaymentStatus2["Pending"] = "Pending";
  OrderPaymentStatus2["Approved"] = "Approved";
  return OrderPaymentStatus2;
})(OrderPaymentStatus || {});
export var OrderStatus = /* @__PURE__ */ ((OrderStatus2) => {
  OrderStatus2["Pending"] = "Pending";
  OrderStatus2["Cancelled"] = "Cancelled";
  OrderStatus2["Draft"] = "Draft";
  OrderStatus2["Confirmed"] = "Confirmed";
  OrderStatus2["Abandoned"] = "Abandoned";
  return OrderStatus2;
})(OrderStatus || {});
