var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { CountryAnswer as WebCountryAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { CountryAnswer as AdminCountryAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { CountryValidator } from "./AnswerValidators";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-country";
const DEFAULTS = {
  validation: {
    required: false
  },
  nolabel: false
};
export class CountryChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    validation: host.validation !== void 0 ? __spreadValues(__spreadValues({}, DEFAULTS.validation), host.validation) : DEFAULTS.validation,
    countries: host.countries,
    nolabel: host.nolabel !== void 0 ? host.nolabel : DEFAULTS.nolabel
  };
  const getCountryAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminCountryAnswer({ CountryCode: currentVal, QuestionId: props.questionId });
    }
    return new WebCountryAnswer({ CountryCode: currentVal, QuestionId: props.questionId });
  };
  const getLabel = () => {
    return props.nolabel ? void 0 : question.Label + (props.validation.required ? "*" : "");
  };
  const [currentVal, setCurrentVal] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new CountryValidator(props.validation));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new CountryChangeEvent({
          answer: getCountryAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof WebCountryAnswer || answer instanceof AdminCountryAnswer) {
        setCurrentVal(answer.CountryCode);
      }
    }
  }, [props.q11e, props.questionId]);
  useEffect(() => {
    validationContext.validate(getCountryAnswer());
  }, [currentVal]);
  const templateSelect = () => {
    var _a;
    return html`<dc-select
			.readonly=${(_a = props.q11e) == null ? void 0 : _a.isReadonly()}
			.label=${getLabel()}
			.tooltip=${question.Tooltip !== void 0 ? question.Tooltip : ""}
			.selectedValues=${[currentVal]}
			.validationMessage=${validationContext.getValidationMessage("countryCode")}
			.dataSource=${props.countries.map((country) => ({
      label: country.Name,
      value: country.Code
    }))}
			.filterable=${true}
			@change=${(e) => {
      setCurrentVal(e.detail.selectedValue);
      setDispatchIfValid(true);
      validationContext.validateField("countryCode");
    }}
		></dc-select>`;
  };
  return html` ${props.q11e !== void 0 && question !== void 0 ? html`${templateSelect()}` : ""} `;
};
