export class Address {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Address
     */
    this.$type = "V1.Common.Address";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.City = _data["City"];
      this.Province = _data["Province"];
      this.Street = _data["Street"];
      this.StreetNumber = _data["StreetNumber"];
      this.Zip = _data["Zip"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["City"] = this.City;
    _data["Province"] = this.Province;
    _data["Street"] = this.Street;
    _data["StreetNumber"] = this.StreetNumber;
    _data["Zip"] = this.Zip;
    return _data;
  }
}
