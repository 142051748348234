export class DateConverter {
  // yyyy-MM-dd
  static toUtcDateString(date) {
    if (date) {
      return this.padYear(date.getUTCFullYear()) + "-" + this.pad(date.getUTCMonth() + 1) + "-" + this.pad(date.getUTCDate());
    } else {
      return void 0;
    }
  }
  // yyyy-MM-dd
  static toClientLocalDateString(date) {
    if (date) {
      return this.padYear(date.getFullYear()) + "-" + this.pad(date.getMonth() + 1) + "-" + this.pad(date.getDate());
    } else {
      return void 0;
    }
  }
  // yyyy-MM-ddThh:mm:ss.sssZ
  static toIsoUtcString(date) {
    if (date) {
      return date.toISOString();
    } else {
      return void 0;
    }
  }
  // yyyy-MM-ddThh:mm:ss.sss+hhmm
  static toIsoClientLocalString(date) {
    if (date) {
      const hoursOffset = Math.abs(date.getTimezoneOffset() / 60);
      const minutesOffset = Math.abs(date.getTimezoneOffset() % 60);
      const offsetCharacter = date.getTimezoneOffset() < 0 ? "+" : "-";
      return this.padYear(date.getFullYear()) + "-" + this.pad(date.getMonth() + 1) + "-" + this.pad(date.getDate()) + "T" + this.pad(date.getHours()) + ":" + this.pad(date.getMinutes()) + ":" + this.pad(date.getSeconds()) + offsetCharacter + this.pad(hoursOffset) + this.pad(minutesOffset);
    } else {
      return void 0;
    }
  }
  // yyyy-MM-ddThh:mm:ss.sss (without Z)
  static toIsoUtcUnspecifiedString(date) {
    if (date) {
      return date.toISOString().slice(0, -1);
    } else {
      return void 0;
    }
  }
  // yyyy-MM-ddThh:mm:ss.sss (without +hhmm)
  static toIsoClientLocalUnspecifiedString(date) {
    if (date) {
      return this.padYear(date.getFullYear()) + "-" + this.pad(date.getMonth() + 1) + "-" + this.pad(date.getDate()) + "T" + this.pad(date.getHours()) + ":" + this.pad(date.getMinutes()) + ":" + this.pad(date.getSeconds());
    } else {
      return void 0;
    }
  }
  // To avoid browser incompatibility, if the timezone or Z is missing, we assume UTC timezone
  static from(dateString) {
    if (!dateString) {
      return void 0;
    }
    if (!this.isTimeSpecified(dateString)) {
      dateString = `${dateString}T00:00:00.000Z`;
    }
    if (!this.isTimezoneSpecified(dateString)) {
      dateString = `${dateString}Z`;
    }
    return new Date(dateString);
  }
  static isTimeSpecified(dateString) {
    return dateString.includes("T");
  }
  static isTimezoneSpecified(dateString) {
    return dateString.includes("Z") || dateString.includes("+");
  }
  static pad(n) {
    return n < 10 ? "0" + n : n.toString();
  }
  static padYear(n) {
    return n.toString().padStart(4, "0");
  }
}
