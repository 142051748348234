import { Answer } from "./../Answer";
export class ClientBaseAnswer extends Answer {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof ClientBaseAnswer
     */
    this.$type = "V1.CompanyInfo.Questionnaire.Answers.ClientBaseAnswer";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["Value"] && _data["Value"].constructor === Array) {
        this.Value = [];
        for (let item of _data["Value"]) {
          this.Value.push(ClientBaseAnswerValue[item]);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    if (this.Value && this.Value.constructor === Array) {
      _data["Value"] = [];
      for (let item of this.Value) {
        _data["Value"].push(ClientBaseAnswerValue[item]);
      }
    }
    return _data;
  }
}
export var ClientBaseAnswerValue = /* @__PURE__ */ ((ClientBaseAnswerValue2) => {
  ClientBaseAnswerValue2["B2C"] = "B2C";
  ClientBaseAnswerValue2["B2B"] = "B2B";
  ClientBaseAnswerValue2["Both"] = "Both";
  return ClientBaseAnswerValue2;
})(ClientBaseAnswerValue || {});
