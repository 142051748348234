var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import * as case_ from "case";
const MULTIPART_FORM_DATA = "multipart/form-data";
export class TswagClient {
  constructor(url, method, contentType, accept, options) {
    this.addQueryParams = (params) => {
      const queryParams = params.filter(this.paramCanBeAdded).map((param) => `${param.name}=${encodeURIComponent("" + param.value)}`);
      this.url += queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
    };
    this.addPathParams = (params) => {
      params.filter(this.paramCanBeAdded).forEach((param) => this.url = this.url.replace(`{${param.name}}`, encodeURIComponent("" + param.value)));
    };
    this.addBody = (param) => {
      let body;
      if (param.isArray) {
        if (param.isPrimitive) {
          body = JSON.stringify(param.value);
        } else {
          const contentData = [];
          for (const item of param.value) {
            contentData.push(item.toJSON());
          }
          body = JSON.stringify(contentData);
        }
      } else {
        if (param.isPrimitive) {
          body = `"${param.value.toString()}"`;
        } else {
          body = JSON.stringify(param.value ? param.value.toJSON() : void 0);
        }
      }
      this.options.body = body;
    };
    this.addFormData = (contentType, params) => {
      if (contentType === MULTIPART_FORM_DATA) {
        const body = new FormData();
        params.filter(this.paramCanBeAdded).forEach((param) => {
          if (Array.isArray(param.value)) {
            param.value.forEach((param2) => {
              body.append(param2.name, param2);
            });
          } else {
            body.append(param.name, param.value);
          }
        });
        this.options.body = body;
      } else {
        this.options.body = params.filter(this.paramCanBeAdded).map((param) => `${param.name}=${param.value}`).join("&");
      }
    };
    this.addHeaderParams = (params) => {
      params.filter(this.paramCanBeAdded).forEach((param) => this.headers.set(param.name, param.value));
    };
    this.paramCanBeAdded = (param) => {
      if (param.required && (param.value === void 0 || param.value === null)) {
        throw new Error(`The parameter '${param.name}' must be defined.`);
      }
      return param.value !== void 0;
    };
    this.fetch = (http, respDescriptor) => __async(this, null, function* () {
      var _a;
      this.options.headers = this.headers;
      const response = yield http.fetch(this.url, this.options);
      const headers = {};
      respDescriptor.headerParams.forEach((name) => {
        headers[case_.camel(name)] = response.headers.get(name);
      });
      const respDescItems = respDescriptor.items.filter((item) => item.statusCode === response.status);
      if (respDescItems.length > 0) {
        const respDescItem = respDescItems[0];
        if (respDescItem.isFile) {
          const responseBlob = yield response.blob();
          let fileName = "unknown." + responseBlob.type.split("/")[1];
          const fileNamePart = (_a = response.headers.get("content-disposition")) == null ? void 0 : _a.split("filename=");
          if (fileNamePart !== void 0 && fileNamePart.length > 0) {
            fileName = fileNamePart[1].split(";")[0];
          }
          const file = new File([responseBlob], fileName);
          return { headers, data: file };
        } else {
          const responseText = yield response.text();
          const resultData = responseText === void 0 || responseText === "" ? void 0 : (() => {
            try {
              return JSON.parse(responseText);
            } catch (e) {
            }
            return responseText;
          })();
          let result;
          if (resultData !== void 0) {
            if (respDescItem.isArray) {
              result = new Array();
              result = [];
              for (const item of resultData) {
                if (respDescItem.clazz === void 0 && respDescItem.factory === void 0) {
                  result.push(item);
                } else {
                  const inst = respDescItem.factory ? respDescItem.factory.create(item.$type) : new respDescItem.clazz();
                  result.push(inst.init(item));
                }
              }
            } else {
              if (respDescItem.clazz === void 0 && respDescItem.factory === void 0) {
                result = resultData;
              } else {
                result = respDescItem.factory ? respDescItem.factory.create(resultData.$type) : new respDescItem.clazz();
                result.init(resultData);
              }
            }
          }
          if (response.status >= 200 && response.status < 300) {
            return { headers, data: result };
          } else {
            const error = result === void 0 ? {} : result;
            error.statusCode = response.status;
            error.transactionId = response.headers.get("TransactionId");
            throw error;
          }
        }
      } else {
        const responseText = yield response.text();
        const error = new Error(`Unexpected Error`);
        error.responseText = responseText;
        error.statusCode = response.status;
        error.transactionId = response.headers.get("TransactionId");
        try {
          error.data = JSON.parse(responseText);
        } catch (e) {
        }
        throw error;
      }
    });
    this.url = url;
    this.headers = new Headers(options.headers);
    if (contentType !== MULTIPART_FORM_DATA) {
      this.headers.set("Content-Type", contentType);
    }
    this.headers.set("Accept", accept);
    this.options = options;
    this.options.method = method;
  }
  getFetch(http, responseHandler) {
    return __async(this, null, function* () {
      const result = yield http.fetch(this.url, this.options);
      return responseHandler(result);
    });
  }
}
