import { TswagClient } from "./../commons/TswagClient";
import { SearchResult } from "./../models/V1/Agents/Search/SearchResult";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class Agents {
  /**
   * Creates an instance of Agents.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Agents
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SearchAgentsRq_ _searchAgentsRq
   * @returns Promise<SearchAgentsRq_>
   * @memberof Agents
   */
  searchAgents(pSearchAgentsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Agents",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchAgentsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param EnableAgentRq_ _enableAgentRq
   * @returns Promise<EnableAgentRq_>
   * @memberof Agents
   */
  enableAgent(pEnableAgentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Agents/{agentId}/Enable",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "agentId", value: pEnableAgentRq.agentId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DisableAgentRq_ _disableAgentRq
   * @returns Promise<DisableAgentRq_>
   * @memberof Agents
   */
  disableAgent(pDisableAgentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Agents/{agentId}/Disable",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "agentId", value: pDisableAgentRq.agentId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CreateAdminInvitationRq_ _createAdminInvitationRq
   * @returns Promise<CreateAdminInvitationRq_>
   * @memberof Agents
   */
  createAdminInvitation(pCreateAdminInvitationRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Agents/Invitation",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pCreateAdminInvitationRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetAgentRq_ _getAgentRq
   * @returns Promise<GetAgentRq_>
   * @memberof Agents
   */
  getAgent(pGetAgentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Agents/{agentId}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "agentId", value: pGetAgentRq.agentId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isArray: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ModifyAgentRq_ _modifyAgentRq
   * @returns Promise<ModifyAgentRq_>
   * @memberof Agents
   */
  modifyAgent(pModifyAgentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Agents/{agentId}",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "agentId", value: pModifyAgentRq.agentId, required: true }
    ]);
    client.addBody({ value: pModifyAgentRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param AddRolesRq_ _addRolesRq
   * @returns Promise<AddRolesRq_>
   * @memberof Agents
   */
  addRoles(pAddRolesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Agents/{agentId}/roles",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "agentId", value: pAddRolesRq.agentId, required: true }
    ]);
    client.addBody({ value: pAddRolesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteRolesRq_ _deleteRolesRq
   * @returns Promise<DeleteRolesRq_>
   * @memberof Agents
   */
  deleteRoles(pDeleteRolesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Agents/{agentId}/roles",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "agentId", value: pDeleteRolesRq.agentId, required: true }
    ]);
    client.addBody({ value: pDeleteRolesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
