var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
export const DeadlineConfigurationFieldNames = {
  ServiceCountryCode: "ServiceCountryCode",
  ReturnType: "ReturnType",
  FrequencyType: "FrequencyType",
  FilingExtension: "FilingExtension",
  EFiling: "EFiling",
  PeriodNumber: "PeriodNumber",
  Deadline: "Deadline",
  ReturnGenerationDueDate: "ReturnGenerationDueDate",
  ClientApprovalDueDate: "ClientApprovalDueDate",
  PaymentDueDate: "PaymentDueDate",
  IsEPayment: "IsEPayment",
  EstablishmentCountryType: "EstablishmentCountryType"
};
export const ALL_FREQUENCY_TYPES = ["Monthly", "Quarterly", "QuarterlyShifted1", "QuarterlyShifted2", "BiMonthly", "FourMonthly", "SixMonthly", "Yearly"];
export const ALL_FREQUENCY_TYPE_LABELS = {
  Monthly: "Monthly",
  Quarterly: "Quarterly",
  QuarterlyShifted1: "Quarterly Shifted 1",
  QuarterlyShifted2: "Quarterly Shifted 2",
  BiMonthly: "Bi Monthly",
  FourMonthly: "Four Monthly",
  SixMonthly: "Six Monthly",
  Yearly: "Yearly"
};
export const ALL_TAX_RETURN_TYPES = ["PrimaryVAT", "SecondaryVAT", "ESL", "VLS", "IOSS", "Intrastat"];
export const ALL_TAX_RETURN_TYPE_LABELS = {
  PrimaryVAT: "Primary VAT",
  SecondaryVAT: "Secondary VAT",
  ESL: "ESL",
  VLS: "VLS",
  IOSS: "IOSS",
  Intrastat: "Intrastat",
  OSS: "OSS"
};
export const ALL_ESTABLISHMENT_COUNTRY_TYPE_TYPES = ["EU", "NonEU"];
export const ALL_ESTABLISHMENT_COUNTRY_TYPE_TYPE_LABELS = {
  EU: "EU",
  NonEU: "Non EU"
};
export const mapDeadlineConfiguration = (deadlineConfiguration) => {
  return __spreadValues({
    Period: mapPeriodNumber(deadlineConfiguration.PeriodNumber, deadlineConfiguration.FrequencyType)
  }, deadlineConfiguration);
};
const mapPeriodNumber = (periodNumber, frequencyType) => {
  switch (frequencyType) {
    case "Monthly":
      switch (periodNumber) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
      }
      break;
    case "Quarterly":
      switch (periodNumber) {
        case 1:
          return "January - March";
        case 2:
          return "April - June";
        case 3:
          return "July - September";
        case 4:
          return "October - December";
      }
      break;
    case "QuarterlyShifted1":
      switch (periodNumber) {
        case 1:
          return "February - April";
        case 2:
          return "May - July";
        case 3:
          return "August - October";
        case 4:
          return "November - January";
      }
      break;
    case "QuarterlyShifted2":
      switch (periodNumber) {
        case 1:
          return "March - May";
        case 2:
          return "June - August";
        case 3:
          return "September - November";
        case 4:
          return "December - February";
      }
      break;
    case "BiMonthly":
      switch (periodNumber) {
        case 1:
          return "January - February";
        case 2:
          return "March - April";
        case 3:
          return "May - June";
        case 4:
          return "July - August";
        case 5:
          return "September - October";
        case 6:
          return "November - December";
      }
      break;
    case "FourMonthly":
      switch (periodNumber) {
        case 1:
          return "January - April";
        case 2:
          return "May - August";
        case 3:
          return "September - December";
      }
      break;
    case "SixMonthly":
      switch (periodNumber) {
        case 1:
          return "January - June";
        case 2:
          return "July - December";
      }
      break;
    case "Yearly":
      switch (periodNumber) {
        case 1:
          return "January - December";
      }
      break;
  }
  return periodNumber.toString();
};
