import { component } from "haunted";
import { Header } from "AdminComponents/header";
import { Footer } from "AdminComponents/footer";
import { Home } from "AdminComponents/home";
import "@webcomponents/custom-elements";
import "GlobalShared/ui/dc-components";
import { Acquisition } from "AdminComponents/acquisition/acquisition";
import { VatRegs } from "AdminComponents/vatregs/vatregs";
import { IossRegistrations } from "AdminComponents/ioss-registrations/ioss-registrations";
import { OfficeServices } from "AdminComponents/clients/office-services";
import { OfficeOrders } from "AdminComponents/orders/office-orders";
import { OfficeComplianceUploads } from "AdminComponents/compliance-uploads/office-compliance-uploads";
import { Agents } from "AdminComponents/agents/agents";
import { Reporting } from "AdminComponents/reporting/reporting";
import { Templates } from "AdminComponents/templates/templates";
import { TaxCodes } from "AdminComponents/taxcodes/taxcodes";
import * as Login from "AdminComponents/login";
import * as Register from "AdminComponents/register";
import * as ForgotPassword from "AdminComponents/forgot-password";
import * as ResetPassword from "AdminComponents/reset-password";
import "AdminComponents/login-twofactor";
import "AdminComponents/clients/office-questionnaire";
import "AdminComponents/clients/office-amazon-skus";
import "AdminComponents/clients/office-default-vat-rates";
import * as TemplateMapping from "AdminComponents/templates/template-mapping";
import * as IdentityMapping from "AdminComponents/templates/mappings/identity-mapping";
import * as DateFormatMapping from "AdminComponents/templates/mappings/date-format-mapping";
import * as SubstringMapping from "AdminComponents/templates/mappings/substring-mapping";
import * as ConcatMapping from "AdminComponents/templates/mappings/concat-mapping";
import * as EnumMapping from "AdminComponents/templates/mappings/enum-mapping";
import * as ConditionMapping from "AdminComponents/templates/mappings/condition-mapping";
import * as AC from "GlobalShared/components/q11e/all-components";
import * as Q11eBankingInformation from "GlobalShared/components/q11e/q11e-banking-information";
import * as Q11eBusinessDetails from "GlobalShared/components/q11e/q11e-business-details";
import * as OrderVatCountryServices from "GlobalShared/components/orders/services/order-vat-country-service";
import { OfficeReturnLibrary } from "AdminComponents/return-library/office-return-library";
import { OfficeClientAccounts } from "AdminComponents/client-accounts/office-client-accounts";
import * as CookieConsent from "AdminComponents/cookie-consent";
import { Migration } from "AdminComponents/migration/migration";
import { VatRateTypes } from "AdminComponents/vat-rate-types/vat-rate-types";
import { DeadlineConfiguration } from "AdminComponents/deadline-configuration/deadline-configuration";
customElements.define("ww-home", component(Home, { useShadowDOM: false }));
customElements.define("ww-header", component(Header, { useShadowDOM: false }));
customElements.define("ww-footer", component(Footer, { useShadowDOM: false }));
customElements.define("ww-acquisition", component(Acquisition, { useShadowDOM: false }));
customElements.define("ww-migration", component(Migration, { useShadowDOM: false }));
customElements.define("ww-vatregs", component(VatRegs, { useShadowDOM: false }));
customElements.define("ww-ioss-registrations", component(IossRegistrations, { useShadowDOM: false }));
customElements.define("ww-clients", component(OfficeServices, { useShadowDOM: false }));
customElements.define("ww-orders", component(OfficeOrders, { useShadowDOM: false }));
customElements.define("ww-reporting", component(Reporting, { useShadowDOM: false }));
customElements.define("ww-templates", component(Templates, { useShadowDOM: false }));
customElements.define("ww-agents", component(Agents, { useShadowDOM: false }));
customElements.define("ww-taxcodes", component(TaxCodes, { useShadowDOM: false }));
customElements.define("ww-compliance-uploads", component(OfficeComplianceUploads, { useShadowDOM: false }));
customElements.define("ww-return-library", component(OfficeReturnLibrary, { useShadowDOM: false }));
customElements.define("ww-client-accounts", component(OfficeClientAccounts, { useShadowDOM: false }));
customElements.define("ww-vat-rates-types", component(VatRateTypes, { useShadowDOM: false }));
customElements.define("ww-deadline-configuration", component(DeadlineConfiguration, { useShadowDOM: false }));
customElements.define(
  Login.name,
  component(Login.Component, {
    useShadowDOM: false,
    observedAttributes: Login.observedAttributes
  })
);
customElements.define(
  Register.name,
  component(Register.Component, {
    useShadowDOM: false,
    observedAttributes: Register.observedAttributes
  })
);
customElements.define(
  ForgotPassword.name,
  component(ForgotPassword.Component, {
    useShadowDOM: false,
    observedAttributes: ForgotPassword.observedAttributes
  })
);
customElements.define(
  ResetPassword.name,
  component(ResetPassword.Component, {
    useShadowDOM: false,
    observedAttributes: ResetPassword.observedAttributes
  })
);
customElements.define(
  AC.AnswerString.name,
  component(AC.AnswerString.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerString.observedAttributes
  })
);
customElements.define(
  AC.AnswerOssDetail.name,
  component(AC.AnswerOssDetail.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerOssDetail.observedAttributes
  })
);
customElements.define(
  AC.AnswerBool.name,
  component(AC.AnswerBool.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerBool.observedAttributes
  })
);
customElements.define(
  AC.AnswerAddress.name,
  component(AC.AnswerAddress.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerAddress.observedAttributes
  })
);
customElements.define(
  AC.AnswerMoney.name,
  component(AC.AnswerMoney.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerMoney.observedAttributes
  })
);
customElements.define(
  AC.AnswerDate.name,
  component(AC.AnswerDate.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerDate.observedAttributes
  })
);
customElements.define(
  AC.AnswerFileRef.name,
  component(AC.AnswerFileRef.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerFileRef.observedAttributes
  })
);
customElements.define(
  AC.AnswerName.name,
  component(AC.AnswerName.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerName.observedAttributes
  })
);
customElements.define(
  AC.AnswerMultiString.name,
  component(AC.AnswerMultiString.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerMultiString.observedAttributes
  })
);
customElements.define(
  AC.AnswerExistingVatNumbers.name,
  component(AC.AnswerExistingVatNumbers.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerExistingVatNumbers.observedAttributes
  })
);
customElements.define(
  AC.AnswerTurnOvers.name,
  component(AC.AnswerTurnOvers.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerTurnOvers.observedAttributes
  })
);
customElements.define(
  AC.AnswerLegalRepresentatives.name,
  component(AC.AnswerLegalRepresentatives.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerLegalRepresentatives.observedAttributes
  })
);
customElements.define(
  AC.AnswerBusinessOwners.name,
  component(AC.AnswerBusinessOwners.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerBusinessOwners.observedAttributes
  })
);
customElements.define(
  AC.AnswerBeneficialOwners.name,
  component(AC.AnswerBeneficialOwners.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerBeneficialOwners.observedAttributes
  })
);
customElements.define(
  AC.AnswerBankInformation.name,
  component(AC.AnswerBankInformation.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerBankInformation.observedAttributes
  })
);
customElements.define(
  AC.AnswerVatNumber.name,
  component(AC.AnswerVatNumber.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerVatNumber.observedAttributes
  })
);
customElements.define(
  AC.AnswerCountry.name,
  component(AC.AnswerCountry.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerCountry.observedAttributes
  })
);
customElements.define(
  AC.AnswerMultiCountry.name,
  component(AC.AnswerMultiCountry.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerMultiCountry.observedAttributes
  })
);
customElements.define(
  Q11eBankingInformation.name,
  component(Q11eBankingInformation.Component, {
    useShadowDOM: false,
    observedAttributes: Q11eBankingInformation.observedAttributes
  })
);
customElements.define(
  Q11eBusinessDetails.name,
  component(Q11eBusinessDetails.Component, {
    useShadowDOM: false,
    observedAttributes: Q11eBusinessDetails.observedAttributes
  })
);
customElements.define(
  AC.AnswerAccountingLocation.name,
  component(AC.AnswerAccountingLocation.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerAccountingLocation.observedAttributes
  })
);
customElements.define(
  AC.AnswerClientBase.name,
  component(AC.AnswerClientBase.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerClientBase.observedAttributes
  })
);
customElements.define(
  AC.AnswerLegalEntityForm.name,
  component(AC.AnswerLegalEntityForm.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerLegalEntityForm.observedAttributes
  })
);
customElements.define(
  AC.AnswerLegalEntityType.name,
  component(AC.AnswerLegalEntityType.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerLegalEntityType.observedAttributes
  })
);
customElements.define(
  AC.AnswerProductType.name,
  component(AC.AnswerProductType.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerProductType.observedAttributes
  })
);
customElements.define(
  TemplateMapping.name,
  component(TemplateMapping.Component, {
    useShadowDOM: false,
    observedAttributes: TemplateMapping.observedAttributes
  })
);
customElements.define(
  IdentityMapping.name,
  component(IdentityMapping.Component, {
    useShadowDOM: false,
    observedAttributes: IdentityMapping.observedAttributes
  })
);
customElements.define(
  DateFormatMapping.name,
  component(DateFormatMapping.Component, {
    useShadowDOM: false,
    observedAttributes: DateFormatMapping.observedAttributes
  })
);
customElements.define(
  SubstringMapping.name,
  component(SubstringMapping.Component, {
    useShadowDOM: false,
    observedAttributes: SubstringMapping.observedAttributes
  })
);
customElements.define(
  ConcatMapping.name,
  component(ConcatMapping.Component, {
    useShadowDOM: false,
    observedAttributes: ConcatMapping.observedAttributes
  })
);
customElements.define(
  EnumMapping.name,
  component(EnumMapping.Component, {
    useShadowDOM: false,
    observedAttributes: EnumMapping.observedAttributes
  })
);
customElements.define(
  ConditionMapping.name,
  component(ConditionMapping.Component, {
    useShadowDOM: false,
    observedAttributes: ConditionMapping.observedAttributes
  })
);
customElements.define(
  OrderVatCountryServices.name,
  component(OrderVatCountryServices.Component, {
    useShadowDOM: false,
    observedAttributes: OrderVatCountryServices.observedAttributes
  })
);
customElements.define(
  CookieConsent.name,
  component(CookieConsent.Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
