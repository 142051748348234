import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class DocumentGenerator {
  /**
   * Creates an instance of DocumentGenerator.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof DocumentGenerator
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GeneratePreviewRq_ _generatePreviewRq
   * @returns Promise<GeneratePreviewRq_>
   * @memberof DocumentGenerator
   */
  generatePreview(pGeneratePreviewRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/DocumentGenerator/preview",
      "POST",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addBody({ value: pGeneratePreviewRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
