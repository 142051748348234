var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { notifyError, notifyErrorWithWarning, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { ComplianceUploads as ComplianceUploadsClient } from "AdminServices/APIs/self/clients/ComplianceUploads";
import { SearchRequest, Filter, Pager, Field, ComplianceUploadPeriodFilter } from "AdminServices/APIs/self/models/V1/ComplianceUploads/Search";
import { useComplianceUploads } from "GlobalShared/components/compliance-uploads/compliance-uploads";
import {
  ALL_STATUS,
  ComplianceUploadFieldNames,
  mapComplianceUpload
} from "GlobalShared/components/compliance-uploads/ComplianceUploadModels";
import { FieldOrder } from "AdminServices/APIs/self/models/V1/ComplianceUploads/Search/Field";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import { ComplianceCompany as ComplianceCompanyClient } from "AdminServices/APIs/self/clients/ComplianceCompany";
import { Company as CompanyClient, ComplianceMasterData as ComplianceMasterDataClient } from "AdminServices/APIs/self/clients";
import { mapCompanyDetailToCompanyServicesModel, mapDefaultCompanyConfiguration } from "GlobalShared/mappers/ServicesMapper";
import { usePermissionManager, useWorldCountries } from "AdminComponents/hooks/AdminCustomHooks";
import { currentLocale, getRouteByKey } from "../../globals";
import { UpdatedCompanyConfiguration } from "AdminServices/APIs/self/models/V1/ComplianceUploads/Configuration";
import { UpdatedAmazonConfiguration, UpdatedSellerEntity } from "AdminServices/APIs/self/models/V1/ComplianceUploads/Configuration/Amazon";
import { DataUploadFinalized, DataVatGenerated } from "AdminServices/APIs/self/models/V1/ComplianceUploads/StatusChange";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import { parseDate } from "GlobalShared/helpers/dateHelper";
export function OfficeComplianceUploads() {
  const getFilter = (gridState, showAll) => {
    const clientFilter = new Filter();
    if (!gridState.appliedFilters.some((filter) => filter.field === ComplianceUploadFieldNames.Status)) {
      clientFilter.Statuses = showAll ? ALL_STATUS : ALL_STATUS.filter((f) => f !== "DataUploadFinalized" && f !== "Error" && f !== "SentToReturnGeneration" && f !== "DataVatGenerated" && f !== "UploadProcessed");
    }
    gridState.appliedFilters.forEach((filter) => {
      var _a;
      if (filter.field === ComplianceUploadFieldNames.Status) {
        if (filter.filterProps.selectedValues) {
          clientFilter.Statuses = [filter.filterProps.selectedValues];
        }
      } else if (filter.field === ComplianceUploadFieldNames.Period) {
        if (filter.filterProps.selectedValues) {
          if (filter.filterProps.selectedValues) {
            const selectedPeriods = filter.filterProps.selectedValues;
            const filterPeriods = [];
            selectedPeriods.forEach((period) => {
              const details = period.split(",");
              if (details.length === 2) {
                filterPeriods.push(
                  new ComplianceUploadPeriodFilter({
                    StartDate: parseDate(details[0]).toDate(),
                    FrequencyType: details[1]
                  })
                );
              }
            });
            clientFilter.ComplianceUploadPeriodFilter = filterPeriods;
          }
        }
      } else if (filter.field === ComplianceUploadFieldNames.Type) {
        if (filter.filterProps.selectedValues) {
          clientFilter.ComplianceUploadType = filter.filterProps.selectedValues;
        }
      } else if (filter.field === ComplianceUploadFieldNames.ClientName) {
        if (filter.filterProps.selectedValues) {
          clientFilter.ClientName = filter.filterProps.selectedValues;
        }
      } else if (filter.field === ComplianceUploadFieldNames.ClientId) {
        if (filter.filterProps.selectedValues) {
          clientFilter.ClientId = (_a = toSafeNumber(filter.filterProps.selectedValues)) != null ? _a : -1;
        }
      }
    });
    return clientFilter;
  };
  const uploadIossTransactions = (complianceUploadId, ignoreWarnings, file) => __async(this, null, function* () {
    const uploadResult = yield complianceUploadClient.uploadIossTransactions({
      complianceUploadId,
      ignoreWarnings,
      file
    });
    const validationMessages = uploadResult.data.ValidationMessages ? uploadResult.data.ValidationMessages.map((m) => {
      const vm = {
        validationCode: m.ValidationCode,
        columnName: m.ColumnName,
        columnCode: m.ColumnCode,
        rowNumber: m.RowNumber,
        totalCount: m.TotalCount,
        isWarning: m.IsWarning,
        parameters: m.Parameters
      };
      return vm;
    }) : [];
    const uploadResultVM = {
      // TODO: give back file reference too
      fileReference: void 0,
      validationMessages,
      numberOfTotalLinesWithError: uploadResult.data.NumberOfTotalLinesWithError
    };
    return uploadResultVM;
  });
  const uploadVatTransactions = (complianceUploadId, ignoreWarnings, file) => __async(this, null, function* () {
    const uploadResult = yield complianceUploadClient.uploadVatKpmgTransactions({
      complianceUploadId,
      ignoreWarnings,
      file
    });
    const validationMessages = uploadResult.data.ValidationMessages ? uploadResult.data.ValidationMessages.map((m) => {
      const vm = {
        validationCode: m.ValidationCode,
        columnName: m.ColumnName,
        columnCode: m.ColumnCode,
        rowNumber: m.RowNumber,
        totalCount: m.TotalCount,
        isWarning: m.IsWarning,
        parameters: m.Parameters
      };
      return vm;
    }) : [];
    const uploadResultVM = {
      // TODO: give back file reference too
      fileReference: void 0,
      validationMessages,
      numberOfTotalLinesWithError: uploadResult.data.NumberOfTotalLinesWithError
    };
    return uploadResultVM;
  });
  const removeUploadedVatTransactions = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceUploadClient.removeUploadedVatKpmgTransactions({
          complianceUploadId
        });
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const uploadAmazonTransactions = (complianceUploadId, sellerEntityId, ignoreWarnings, file) => __async(this, null, function* () {
    const uploadResult = yield complianceUploadClient.uploadAmazonTransactions({
      complianceUploadId,
      sellerEntityId,
      ignoreWarnings,
      file
    });
    const validationMessages = uploadResult.data.ValidationMessages ? uploadResult.data.ValidationMessages.map((m) => {
      const vm = {
        validationCode: m.ValidationCode,
        columnName: m.ColumnName,
        rowNumber: m.RowNumber,
        totalCount: m.TotalCount,
        isWarning: m.IsWarning,
        parameters: m.Parameters
      };
      return vm;
    }) : [];
    const uploadResultVM = {
      // TODO: give back file reference too
      fileReference: void 0,
      validationMessages,
      numberOfTotalLinesWithError: uploadResult.data.NumberOfTotalLinesWithError
    };
    return uploadResultVM;
  });
  const removeUploadedAmazonTransactions = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceUploadClient.removeUploadedAmazonTransactions({
          complianceUploadId
        });
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const uploadSaftTransactions = (complianceUploadId, file) => __async(this, null, function* () {
    const uploadResult = yield complianceUploadClient.uploadSaftTransactions({
      complianceUploadId,
      file
    });
    const validationMessages = uploadResult.data.ValidationMessages ? uploadResult.data.ValidationMessages.map((m) => {
      const vm = {
        validationCode: m.ValidationCode,
        columnName: m.ColumnName,
        rowNumber: m.RowNumber,
        totalCount: m.TotalCount,
        isWarning: m.IsWarning,
        parameters: m.Parameters
      };
      return vm;
    }) : [];
    const uploadResultVM = {
      // TODO: give back file reference too
      fileReference: void 0,
      validationMessages,
      numberOfTotalLinesWithError: uploadResult.data.NumberOfTotalLinesWithError
    };
    return uploadResultVM;
  });
  const finalizeComplianceUpload = (complianceUpload, confirmNoSaft, acknowledgeIncompleteSkus) => __async(this, null, function* () {
    if (complianceUpload.Type === "IOSS") {
      yield complianceUploadClient.finalizeIossComplianceUpload({
        complianceUploadId: complianceUpload.Id
      });
    } else {
      if (complianceUpload.Status === "New") {
        yield complianceUploadClient.finalizeVatComplianceUpload({
          complianceUploadId: complianceUpload.Id
        });
      } else if (complianceUpload.Status === "SaftCompliance") {
        yield complianceUploadClient.finalizeSAFT({
          complianceUploadId: complianceUpload.Id,
          confirmNoSaft: confirmNoSaft === void 0 ? false : confirmNoSaft
        });
      } else if (complianceUpload.Status === "MasterDataSetup") {
        yield complianceUploadClient.finalizeMasterData({
          complianceUploadId: complianceUpload.Id,
          acknowledgeIncompleteSkus: acknowledgeIncompleteSkus === void 0 ? false : acknowledgeIncompleteSkus
        });
      }
    }
  });
  const searchComplianceUploads = (gridState, showAll) => __async(this, null, function* () {
    const response = yield complianceUploadClient.searchComplianceUploads({
      body: new SearchRequest({
        Filter: getFilter(gridState, showAll),
        Pager: new Pager({
          Skip: gridState.pageIndex * gridState.pageSize,
          Top: gridState.pageSize,
          OrderBy: [
            new Field({
              Order: gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
              OrderByField: gridState.orderBy
            })
          ]
        })
      })
    });
    const vm = {
      uploads: response.data.ComplianceUploads.map(mapComplianceUpload),
      total: response.data.Count
    };
    return vm;
  });
  const exportComplianceUploads = (gridState, showAll) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield complianceUploadClient.exportComplianceUploads({
          body: new SearchRequest({
            Filter: getFilter(gridState, showAll),
            Pager: new Pager({
              Skip: gridState.pageIndex * gridState.pageSize,
              Top: gridState.pageSize,
              OrderBy: [
                new Field({
                  Order: gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: gridState.orderBy
                })
              ]
            })
          })
        });
        simulateDownload(response.data);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const generateSaftTemplate = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield complianceUploadClient.generateSaftTemplate({
          complianceUploadId
        });
        simulateDownload(response.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getDefaultCompanyConfiguration = (companyId) => __async(this, null, function* () {
    const response = yield complianceCompanyClient.getDefaultConfiguration({ companyId });
    return mapDefaultCompanyConfiguration(response.data);
  });
  const getCompanyServices = (companyId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const companyDetailResult = yield companyClient.getCompanyDetail({ companyId });
        return mapCompanyDetailToCompanyServicesModel(worldCountries, companyDetailResult.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const updateDefaultCompanyConfiguration = (companyConfiguration, companyId) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceCompanyClient.updateDefaultConfiguration({
          companyId,
          body: new UpdatedCompanyConfiguration({
            AmazonConfiguration: companyConfiguration.amazonConfiguration ? new UpdatedAmazonConfiguration({
              SellerEntities: companyConfiguration.amazonConfiguration.sellerEntities.map(
                (se) => new UpdatedSellerEntity({
                  Id: se.id,
                  Name: se.name,
                  MwsAuthToken: se.mwsAuthToken,
                  IsEnabled: se.isEnabled,
                  IsAutomated: se.isAutomated
                })
              ),
              UseAmazonRates: companyConfiguration.amazonConfiguration.useAmazonRates,
              RefundMode: companyConfiguration.amazonConfiguration.refundMode
            }) : void 0,
            UseKpmgTemplate: companyConfiguration.useKpmgTemplate,
            UseAmazonTemplate: companyConfiguration.useAmazonTemplate
          })
        });
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const hasAnyIncompleteSKUs = (companyId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const companyDetailResult = yield complianceMasterDataClient.hasAnyIncompleteSKUs({ companyId });
        return companyDetailResult.data;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const resendNotification = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceUploadClient.changeStatus({
          complianceUploadId,
          body: new DataVatGenerated()
        });
        notifySuccess("Notification sent!");
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const regenerateDataVat = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceUploadClient.changeStatus({
          complianceUploadId,
          body: new DataUploadFinalized()
        });
        notifySuccess("DataVat generation scheduled");
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const generateTextFile = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield complianceUploadClient.generateTextFile({
          complianceUploadId
        });
        simulateDownload(response.data);
      }),
      (error) => {
        if (error.statusCode === 404) {
          notifyWarning("DataVAT not generated yet");
        } else {
          notifyError(error);
        }
      }
    );
  });
  const reconfirmUpload = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceUploadClient.checkBalance({
          complianceUploadId
        });
        const compliance = yield complianceUploadClient.getCompliance({
          complianceUploadId
        });
        return compliance.data.Status;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const cancelUpload = (complianceUploadId) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceUploadClient.cancelComplianceUpload({
          complianceUploadId
        });
        const compliance = yield complianceUploadClient.getCompliance({
          complianceUploadId
        });
        return compliance.data.Status;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const changeIgnoreOverdue = (complianceUploadId, newValue) => __async(this, null, function* () {
    yield complianceUploadClient.changeIgnoreOverdue({
      complianceUploadId,
      newValue
    });
  });
  const worldCountries = useWorldCountries(currentLocale);
  const complianceCompanyClient = useClient(ComplianceCompanyClient);
  const complianceMasterDataClient = useClient(ComplianceMasterDataClient);
  const complianceUploadClient = useClient(ComplianceUploadsClient);
  const companyClient = useClient(CompanyClient);
  const permissionManager = usePermissionManager();
  const complianceUploadHook = useComplianceUploads({
    isAdmin: true,
    permissionManager,
    searchComplianceUploads,
    exportComplianceUploads,
    uploadIossTransactions,
    uploadVatTransactions,
    removeUploadedVatTransactions,
    uploadAmazonTransactions,
    removeUploadedAmazonTransactions,
    uploadSaftTransactions,
    generateSaftTemplate,
    finalizeComplianceUpload,
    getDefaultCompanyConfiguration,
    getCompanyServices,
    updateDefaultCompanyConfiguration,
    hasAnyIncompleteSKUs,
    getRouteByKey,
    resendNotification,
    regenerateDataVat,
    generateTextFile,
    reconfirmUpload,
    cancelUpload,
    changeIgnoreOverdue
  });
  return complianceUploadHook.mainTemplate;
}
