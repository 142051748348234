import { Subscription } from "./Subscription";
export class IossComplianceSubscription extends Subscription {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof IossComplianceSubscription
     */
    this.$type = "V1.CompanyInfo.Subscriptions.IossComplianceSubscription";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.PackageAmount = _data["PackageAmount"];
      this.Tier = IossComplianceSubscriptionTier[_data["Tier"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["PackageAmount"] = this.PackageAmount;
    _data["Tier"] = IossComplianceSubscriptionTier[this.Tier];
    return _data;
  }
}
export var IossComplianceSubscriptionTier = /* @__PURE__ */ ((IossComplianceSubscriptionTier2) => {
  IossComplianceSubscriptionTier2["Tier0"] = "Tier0";
  IossComplianceSubscriptionTier2["Tier1"] = "Tier1";
  IossComplianceSubscriptionTier2["Tier2"] = "Tier2";
  IossComplianceSubscriptionTier2["Tier3"] = "Tier3";
  return IossComplianceSubscriptionTier2;
})(IossComplianceSubscriptionTier || {});
