var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { getTermOpTypeListItemsByDSFieldType } from "../TemplateModels";
import { getLastPathElement } from "../templateHelper";
import { ConditionMappingValidator } from "../TemplateValidator";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
export const observedAttributes = [];
export const useShadowDOM = false;
export const name = "ww-condition-mapping";
const DEFAULT_CONDITION_MAPPING = {
  id: void 0,
  pdfFieldName: void 0,
  type: "CONDITION",
  boolOp: "And",
  terms: []
};
export const Component = (host) => {
  const props = {
    conditionMapping: host.conditionMapping,
    actionInProgress: host.actionInProgress,
    onSave: host.onSave,
    onClear: host.onClear,
    onCancel: host.onCancel
  };
  const onSave = () => {
    let tempDispatchIfValid = true;
    setMapping(__spreadProps(__spreadValues({}, mapping), {
      terms: mapping.terms.map((term) => {
        const newTerm = __spreadProps(__spreadValues({}, term), { validationMessages: __spreadValues({}, term.validationMessages) });
        if (newTerm.path === void 0) {
          newTerm.validationMessages.path = "Required";
          tempDispatchIfValid = false;
        } else {
          if ((newTerm.dsFieldType === "Int" || newTerm.dsFieldType === "Decimal" || newTerm.dsFieldType === "String" || newTerm.dsFieldType === "DateTime") && newTerm.op === void 0) {
            newTerm.validationMessages.op = "Required";
            tempDispatchIfValid = false;
          }
          if ((newTerm.dsFieldType === "Int" || newTerm.dsFieldType === "Decimal") && newTerm.numValue === void 0) {
            newTerm.validationMessages.numValue = "Number required";
            tempDispatchIfValid = false;
          } else if (newTerm.dsFieldType === "String" && isEmpty(newTerm.stringValue)) {
            newTerm.validationMessages.stringValue = "Required";
            tempDispatchIfValid = false;
          } else if (newTerm.dsFieldType === "DateTime" && newTerm.dateValue === void 0) {
            newTerm.validationMessages.dateValue = "Required";
            tempDispatchIfValid = false;
          }
        }
        return newTerm;
      })
    }));
    setDispatchIfValid(tempDispatchIfValid);
    validationContext.validateFields(["terms"]);
  };
  const [mapping, setMapping] = useState(DEFAULT_CONDITION_MAPPING);
  const validationContext = useValidationContext(new ConditionMappingValidator());
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => __async(void 0, null, function* () {
    if (dispatchIfValid) {
      setDispatchIfValid(false);
      if (validationContext.validationResult.isValid()) {
        props.onSave(mapping);
      }
    }
  }), [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(mapping);
  }, [mapping]);
  useEffect(() => {
    if (props.conditionMapping === void 0) {
      setMapping(DEFAULT_CONDITION_MAPPING);
    } else {
      validationContext.clearFieldValidations();
      setMapping(__spreadProps(__spreadValues({}, props.conditionMapping), {
        terms: props.conditionMapping.terms.map((term) => __spreadProps(__spreadValues({}, term), { validationMessages: {} }))
      }));
    }
  }, [props.conditionMapping]);
  return html`<div class="space-y-4">
		<dc-toggle
			.state=${(mapping == null ? void 0 : mapping.boolOp) === "And" ? "No" : "Yes"}
			.labelYes=${"OR"}
			.labelNo=${"AND"}
			@change=${(e) => {
    const boolOp = e.detail.state === "Yes" ? "Or" : "And";
    setMapping(__spreadProps(__spreadValues({}, mapping), { boolOp }));
  }}
		></dc-toggle>
		<div class="text-red-500">${validationContext.getValidationMessage("terms")}</div>
		${mapping == null ? void 0 : mapping.terms.map((term, i) => {
    return html`<div class="flex space-x-2 items-center">
				<div class="flex items-center">
					<dc-checkbox
						.checked=${term.not}
						.label=${"NOT"}
						@change=${(_e) => {
      setMapping(__spreadProps(__spreadValues({}, mapping), {
        terms: mapping.terms.map((term2, i2) => {
          if (i === i2) {
            return __spreadProps(__spreadValues({}, term2), { not: !term2.not });
          } else {
            return term2;
          }
        })
      }));
    }}
					></dc-checkbox>
				</div>
				<dc-dropfield
					.class=${"p-2 mb-0"}
					.value=${getLastPathElement(term == null ? void 0 : term.path)}
					.validationMessage=${term.validationMessages.path}
					.onDrop=${(e) => {
      const draggedfield = JSON.parse(e.dataTransfer.getData("payload"));
      term.validationMessages.path = void 0;
      setMapping(__spreadProps(__spreadValues({}, mapping), {
        terms: mapping.terms.map((term2, i2) => {
          if (i === i2) {
            return __spreadProps(__spreadValues({}, term2), {
              path: draggedfield.path,
              dsFieldType: draggedfield.field.type,
              validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
                path: void 0
              })
            });
          } else {
            return term2;
          }
        })
      }));
    }}
				></dc-dropfield>
				${term.dsFieldType === "String" || term.dsFieldType === "Int" || term.dsFieldType === "Decimal" || term.dsFieldType === "DateTime" ? html`<dc-select
							.selectedValues=${[term.op]}
							.dataSource=${getTermOpTypeListItemsByDSFieldType(term.dsFieldType)}
							.validationMessage=${term.validationMessages.op}
							@change=${(e) => {
      setMapping(__spreadProps(__spreadValues({}, mapping), {
        terms: mapping.terms.map((term2, i2) => {
          if (i === i2) {
            return __spreadProps(__spreadValues({}, term2), {
              op: e.detail.selectedValue,
              validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
                op: void 0
              })
            });
          } else {
            return term2;
          }
        })
      }));
    }}
					  ></dc-select>` : ""}
				${term.dsFieldType === "DateTime" ? html`<dc-datepicker
							.value=${term.dateValue}
							.validationMessage=${term.validationMessages.dateValue}
							@change=${(e) => {
      setMapping(__spreadProps(__spreadValues({}, mapping), {
        terms: mapping.terms.map((term2, i2) => {
          if (i === i2) {
            return __spreadProps(__spreadValues({}, term2), {
              dateValue: e.detail.value,
              validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
                dateValue: e.detail.value === void 0 ? "Required" : ""
              })
            });
          } else {
            return term2;
          }
        })
      }));
    }}
					  ></dc-datepicker>` : ""}
				${term.dsFieldType === "Int" || term.dsFieldType === "Decimal" ? html`<dc-input
							.autocomplete=${"off"}
							.value=${term.numValue}
							.validationMessage=${term.validationMessages.numValue}
							@change=${(e) => {
      const tempNum = toSafeNumber(e.detail.value);
      setMapping(__spreadProps(__spreadValues({}, mapping), {
        terms: mapping.terms.map((term2, i2) => {
          if (i === i2) {
            return __spreadProps(__spreadValues({}, term2), {
              numValue: tempNum,
              validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
                numValue: tempNum === void 0 ? "Number required" : ""
              })
            });
          } else {
            return term2;
          }
        })
      }));
    }}
					  ></dc-input>` : ""}
				${term.dsFieldType === "String" ? html`<dc-input
							.autocomplete=${"off"}
							.value=${term.stringValue}
							.validationMessage=${term.validationMessages.stringValue}
							@change=${(e) => {
      setMapping(__spreadProps(__spreadValues({}, mapping), {
        terms: mapping.terms.map((term2, i2) => {
          if (i === i2) {
            return __spreadProps(__spreadValues({}, term2), {
              stringValue: e.detail.value,
              validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
                stringValue: isEmpty(e.detail.value) ? "Required" : ""
              })
            });
          } else {
            return term2;
          }
        })
      }));
    }}
					  ></dc-input>` : ""}
				<button
					class="btn btn-primary"
					@click=${() => {
      setMapping(__spreadProps(__spreadValues({}, mapping), { terms: mapping.terms.filter((_, i2) => i2 !== i) }));
    }}
				>
					&times;
				</button>
			</div>`;
  })}
		<button
			class="btn btn-primary"
			@click=${() => {
    setMapping(__spreadProps(__spreadValues({}, mapping), {
      terms: mapping.terms.concat([{ not: false, validationMessages: {} }])
    }));
  }}
		>
			&plus;
		</button>

		<div class="mt-4 space-x-4">
			<button class="btn btn-primary" ?disabled=${props.actionInProgress} @click=${onSave}>Save</button>
			<button class="btn btn-primary" ?disabled=${props.actionInProgress} @click=${() => props.onClear(mapping)}>Clear</button>
			<button class="btn btn-primary" ?disabled=${props.actionInProgress} @click=${props.onCancel}>Cancel</button>
		</div>
	</div>`;
};
