export class MigrationSellerAccount {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof MigrationSellerAccount
     */
    this.$type = "V1.Migration.MigrationSellerAccount";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Mwstoken = _data["Mwstoken"];
      this.Name = _data["Name"];
      this.PortalId = _data["PortalId"];
      this.SellerAccountIdentifier = _data["SellerAccountIdentifier"];
      this.SellerAccountName = _data["SellerAccountName"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Mwstoken"] = this.Mwstoken;
    _data["Name"] = this.Name;
    _data["PortalId"] = this.PortalId;
    _data["SellerAccountIdentifier"] = this.SellerAccountIdentifier;
    _data["SellerAccountName"] = this.SellerAccountName;
    return _data;
  }
}
