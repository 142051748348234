import { VatRegistrationStatusChange } from "./VatRegistrationStatusChange";
import { AddCertificateStatusChange } from "./AddCertificateStatusChange";
import { AddLocalTaxStatusChange } from "./AddLocalTaxStatusChange";
import { BackToAddCertificateStatusChange } from "./BackToAddCertificateStatusChange";
import { BackToAddLocalTaxStatusChange } from "./BackToAddLocalTaxStatusChange";
import { BackToClientStatusChange } from "./BackToClientStatusChange";
import { BackToGenerateDocsStatusChange } from "./BackToGenerateDocsStatusChange";
import { BackToTAStatusChange } from "./BackToTAStatusChange";
import { BackToTranslationStatusChange } from "./BackToTranslationStatusChange";
import { DeregisteredStatusChange } from "./DeregisteredStatusChange";
import { DeregistrationInProgressStatusChange } from "./DeregistrationInProgressStatusChange";
import { GenerateDocsStatusChange } from "./GenerateDocsStatusChange";
import { PoaRevokedStatusChange } from "./PoaRevokedStatusChange";
import { ReopenStatusChange } from "./ReopenStatusChange";
import { RevokeTranslateStatusChange } from "./RevokeTranslateStatusChange";
import { RevokingPoAStatusChange } from "./RevokingPoAStatusChange";
import { ToTAStatusChange } from "./ToTAStatusChange";
import { TranslateStatusChange } from "./TranslateStatusChange";
import { UploadDocsStatusChange } from "./UploadDocsStatusChange";
export class VatRegistrationStatusChangeFactory {
  static create($type) {
    if ($type === "V1.Company.VatRegistration.VatRegistrationStatusChange") {
      const result = new VatRegistrationStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.AddCertificateStatusChange") {
      const result = new AddCertificateStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.AddLocalTaxStatusChange") {
      const result = new AddLocalTaxStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.BackToAddCertificateStatusChange") {
      const result = new BackToAddCertificateStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.BackToAddLocalTaxStatusChange") {
      const result = new BackToAddLocalTaxStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.BackToClientStatusChange") {
      const result = new BackToClientStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.BackToGenerateDocsStatusChange") {
      const result = new BackToGenerateDocsStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.BackToTAStatusChange") {
      const result = new BackToTAStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.BackToTranslationStatusChange") {
      const result = new BackToTranslationStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.DeregisteredStatusChange") {
      const result = new DeregisteredStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.DeregistrationInProgressStatusChange") {
      const result = new DeregistrationInProgressStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.GenerateDocsStatusChange") {
      const result = new GenerateDocsStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.PoaRevokedStatusChange") {
      const result = new PoaRevokedStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.ReopenStatusChange") {
      const result = new ReopenStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.RevokeTranslateStatusChange") {
      const result = new RevokeTranslateStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.RevokingPoAStatusChange") {
      const result = new RevokingPoAStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.ToTAStatusChange") {
      const result = new ToTAStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.TranslateStatusChange") {
      const result = new TranslateStatusChange();
      return result;
    }
    if ($type === "V1.Company.VatRegistration.UploadDocsStatusChange") {
      const result = new UploadDocsStatusChange();
      return result;
    }
    throw new Error($type + " not found");
  }
}
