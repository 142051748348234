import { colorBlack, colorLightGray, getRowIndex, horizontalLine, mainLine, serviceLine, titleText } from "GlobalShared/helpers/pdfHelper";
import autoTable from "jspdf-autotable";
export const IOSS_TIER_FEES = {
  body: [
    ["Name of the Services", "One time registration/takeover fee", "Core Tax Compliance Services Fee, including VAT intermediary services"],
    [
      "Pay As You Ship",
      "EUR 70",
      "EUR 10 per month, including up to 5 packages, plus EUR 2 per additional package if the number of packages exceeds 5, payable before the IOSS return submission for that month. "
    ],
    ["Tier 1", "None", "EUR 800 for up to 500 packages in a 12 month period, renewable after the 500 packages credit is used up or after 12 months."],
    ["Tier 2", "None", "EUR 2500 for up to 2500 packages in a 12 month period, renewable after the 2500 packages credit is used up or after 12 months."],
    ["Tier 3", "None", "EUR 4800 for unlimited packages in a 12 month period, renewable after 12 months."]
  ]
};
export const vatSection = (sectionLetter, lineObj, top, tableProps, wwdoc, pdfModel) => {
  var _a;
  const vatSubTotal = pdfModel.summary.subTotal - pdfModel.summary.ioss;
  titleText(`${sectionLetter}) Core Tax Compliance Services \u2013 Country specific VAT registration(s)`, wwdoc.sideMargin, top, wwdoc.doc);
  (_a = pdfModel.summary.countries) == null ? void 0 : _a.map((country) => {
    top += 2;
    lineObj.line++;
    mainLine(country.label, country.total, lineObj.line, top, tableProps, wwdoc.doc);
    if (country.fiscalRep > 0) {
      lineObj.line++;
      serviceLine("Fiscal representation", country.fiscalRep, lineObj.line, top, tableProps, wwdoc.doc);
    }
    if (country.filingAgent > 0) {
      lineObj.line++;
      serviceLine("Filing agent", country.filingAgent, lineObj.line, top, tableProps, wwdoc.doc);
    }
    if (country.retro > 0) {
      lineObj.line++;
      serviceLine("Retrospective Return", country.retro, lineObj.line, top, tableProps, wwdoc.doc);
    }
    if (country.monthlyCompliance > 0) {
      lineObj.line++;
      serviceLine("Monthly country compliance", country.monthlyCompliance, lineObj.line, top, tableProps, wwdoc.doc);
    }
    if (country.selfRevision > 0) {
      lineObj.line++;
      serviceLine("Self Revision / Retrospective Returns", country.selfRevision, lineObj.line, top, tableProps, wwdoc.doc);
    }
    if (country.spanishBankAccount > 0) {
      lineObj.line++;
      serviceLine("Spanish VAT Payment Service", country.spanishBankAccount, lineObj.line, top, tableProps, wwdoc.doc);
    }
    if (country.intrastat > 0) {
      lineObj.line++;
      serviceLine("Intrastat Report", country.intrastat, lineObj.line, top, tableProps, wwdoc.doc);
    }
    if (country.deregistration > 0) {
      lineObj.line++;
      serviceLine("Deregistration", country.deregistration, lineObj.line, top, tableProps, wwdoc.doc);
    }
  });
  if (pdfModel.summary.eori > 0) {
    top += 2;
    lineObj.line++;
    mainLine("EORI number application in the EU", pdfModel.summary.eori, lineObj.line, top, tableProps, wwdoc.doc);
  }
  if (pdfModel.summary.ukEori > 0) {
    top += 2;
    lineObj.line++;
    mainLine("EORI number application in the UK", pdfModel.summary.ukEori, lineObj.line, top, tableProps, wwdoc.doc);
  }
  if (pdfModel.summary.changeCompanyDetail > 0) {
    top += 2;
    lineObj.line++;
    mainLine("Change Company Detail", pdfModel.summary.changeCompanyDetail, lineObj.line, top, tableProps, wwdoc.doc);
  }
  if (pdfModel.summary.iossDeregistration > 0) {
    top += 2;
    lineObj.line++;
    mainLine("IOSS Deregistration", pdfModel.summary.iossDeregistration, lineObj.line, top, tableProps, wwdoc.doc);
  }
  if (pdfModel.summary.communicatingWithTaxAuthorities > 0) {
    top += 2;
    lineObj.line++;
    mainLine("Communicating with Tax Authorities", pdfModel.summary.communicatingWithTaxAuthorities, lineObj.line, top, tableProps, wwdoc.doc);
  }
  if (pdfModel.summary.generalVatAdvice > 0) {
    top += 2;
    lineObj.line++;
    mainLine("General VAT Advice", pdfModel.summary.generalVatAdvice, lineObj.line, top, tableProps, wwdoc.doc);
  }
  if (pdfModel.summary.specialistVatAdvice > 0) {
    top += 2;
    lineObj.line++;
    mainLine("Specialist VAT Advice", pdfModel.summary.specialistVatAdvice, lineObj.line, top, tableProps, wwdoc.doc);
  }
  top += 5;
  horizontalLine(tableProps.tableLeftPadding, lineObj.line * tableProps.lineHeight + top, tableProps.tableLeftPadding + tableProps.secondCol + 20, wwdoc.doc);
  lineObj.line++;
  mainLine("Sub-total", vatSubTotal, lineObj.line, top, tableProps, wwdoc.doc);
  lineObj.line++;
  mainLine("VAT", pdfModel.summary.vat, lineObj.line, top, tableProps, wwdoc.doc);
  top += 3;
  horizontalLine(tableProps.tableLeftPadding, lineObj.line * tableProps.lineHeight + top, tableProps.tableLeftPadding + tableProps.secondCol + 20, wwdoc.doc);
  wwdoc.doc.setFont("times", "bold");
  wwdoc.doc.setFontSize(11);
  lineObj.line++;
  mainLine("Total", vatSubTotal + pdfModel.summary.vat, lineObj.line, top, tableProps, wwdoc.doc);
  wwdoc.doc.setFontSize(9);
};
export const ossSection = (sectionLetter, lineObj, top, tableProps, wwdoc, pdfModel) => {
  lineObj.line += 4;
  const templine = lineObj.line;
  lineObj.line = 0;
  let ossCumulativeTop = tableProps.paragraphMargin + top + tableProps.lineHeight;
  titleText(`${sectionLetter}) Core Tax Compliance Services \u2013 EU One-Stop-Shop VAT registration`, wwdoc.sideMargin, top, wwdoc.doc);
  horizontalLine(tableProps.tableLeftPadding, lineObj.line * tableProps.lineHeight + ossCumulativeTop, tableProps.tableLeftPadding + tableProps.secondCol + 20, wwdoc.doc);
  lineObj.line++;
  mainLine("Core Tax Compliance Services Fee", pdfModel.summary.ioss, lineObj.line, ossCumulativeTop, tableProps, wwdoc.doc);
  lineObj.line++;
  mainLine("VAT", pdfModel.summary.vat, lineObj.line, ossCumulativeTop, tableProps, wwdoc.doc);
  ossCumulativeTop += 3;
  horizontalLine(tableProps.tableLeftPadding, lineObj.line * tableProps.lineHeight + ossCumulativeTop, tableProps.tableLeftPadding + tableProps.secondCol + 20, wwdoc.doc);
  wwdoc.doc.setFont("times", "bold");
  wwdoc.doc.setFontSize(11);
  lineObj.line++;
  mainLine("Total fee", pdfModel.summary.ioss + pdfModel.summary.vat, lineObj.line, ossCumulativeTop, tableProps, wwdoc.doc);
  lineObj.line += templine;
  wwdoc.doc.setFontSize(9);
};
export const iossSection = (sectionLetter, lineObj, top, tableProps, wwdoc, pdfModel) => {
  lineObj.line = 0;
  const iossCumulativeTop = tableProps.paragraphMargin + top + tableProps.lineHeight;
  titleText(`${sectionLetter}) Core Tax Compliance Services \u2013 EU Import-One-Stop-Shop VAT registration`, wwdoc.sideMargin, iossCumulativeTop, wwdoc.doc);
  wwdoc.doc.setFontSize(9);
  wwdoc.doc.text(`The Core Tax Compliance Services Fee for the chosen IOSS compliance services is as follows:`, wwdoc.sideMargin, iossCumulativeTop + tableProps.lineHeight);
  autoTable(wwdoc.doc, {
    body: IOSS_TIER_FEES.body,
    theme: "grid",
    startY: iossCumulativeTop + tableProps.lineHeight * 2,
    styles: {
      textColor: colorBlack,
      overflow: "linebreak",
      font: "times",
      fontSize: 9,
      lineColor: colorLightGray,
      valign: "middle",
      cellPadding: { top: 2, horizontal: 2, bottom: 3 }
    },
    columnStyles: {
      0: { cellWidth: 59 },
      1: { cellWidth: 59 }
    },
    didParseCell: (data) => {
      if (data.row.index === 0 || data.row.index === getRowIndex(pdfModel.iossTier)) {
        data.cell.styles.fontStyle = "bold";
      }
    }
  });
  wwdoc.doc.setFontSize(9);
};
