export class InviteUser {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof InviteUser
     */
    this.$type = "V1.Users.InviteUser";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientSource = InviteUserClientSource[_data["ClientSource"]];
      if (_data["CompanyIds"] && _data["CompanyIds"].constructor === Array) {
        this.CompanyIds = [];
        for (let item of _data["CompanyIds"]) {
          this.CompanyIds.push(item);
        }
      }
      this.Email = _data["Email"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientSource"] = InviteUserClientSource[this.ClientSource];
    if (this.CompanyIds && this.CompanyIds.constructor === Array) {
      _data["CompanyIds"] = [];
      for (let item of this.CompanyIds) {
        _data["CompanyIds"].push(item);
      }
    }
    _data["Email"] = this.Email;
    return _data;
  }
}
export var InviteUserClientSource = /* @__PURE__ */ ((InviteUserClientSource2) => {
  InviteUserClientSource2["FedEx"] = "FedEx";
  InviteUserClientSource2["KPMG"] = "KPMG";
  InviteUserClientSource2["Amazon"] = "Amazon";
  InviteUserClientSource2["LaPoste"] = "LaPoste";
  return InviteUserClientSource2;
})(InviteUserClientSource || {});
