export class BankAccount {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof BankAccount
     */
    this.$type = "V1.CompanyInfo.Detail.BankAccount";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.BankAccountId = _data["BankAccountId"];
      this.BankAddress = _data["BankAddress"];
      this.BankName = _data["BankName"];
      this.BeneficiaryAddress = _data["BeneficiaryAddress"];
      this.BeneficiaryName = _data["BeneficiaryName"];
      this.Iban = _data["Iban"];
      this.Swift = _data["Swift"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["BankAccountId"] = this.BankAccountId;
    _data["BankAddress"] = this.BankAddress;
    _data["BankName"] = this.BankName;
    _data["BeneficiaryAddress"] = this.BeneficiaryAddress;
    _data["BeneficiaryName"] = this.BeneficiaryName;
    _data["Iban"] = this.Iban;
    _data["Swift"] = this.Swift;
    return _data;
  }
}
