import { Address } from "./../../../Common/Address";
import { DateConverter } from "./../../../../../converters/DateConverter";
export class BeneficialOwner {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof BeneficialOwner
     */
    this.$type = "V1.CompanyInfo.Questionnaire.BeneficialOwner.BeneficialOwner";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Address"]) {
        const address_ = new Address();
        this.Address = address_.init(_data["Address"]);
      }
      this.AddressCountry = _data["AddressCountry"];
      this.BeneficialOwnerType = BeneficialOwnerBeneficialOwnerType[_data["BeneficialOwnerType"]];
      this.BirthName = _data["BirthName"];
      this.CityOfBirth = _data["CityOfBirth"];
      this.CountryOfBirth = _data["CountryOfBirth"];
      this.DataManagement = _data["DataManagement"];
      this.DateOfBirth = DateConverter.from(_data["DateOfBirth"]);
      this.ExtentOfOwnership = _data["ExtentOfOwnership"];
      this.FirstName = _data["FirstName"];
      this.IsAssociateToPoliticallyExposed = _data["IsAssociateToPoliticallyExposed"];
      this.IsPoliticallyExposed = _data["IsPoliticallyExposed"];
      this.IsRelativeToPoliticallyExposed = _data["IsRelativeToPoliticallyExposed"];
      this.LastName = _data["LastName"];
      this.Nationality = _data["Nationality"];
      this.TypeOfOwnership = BeneficialOwnerTypeOfOwnership[_data["TypeOfOwnership"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Address"] = this.Address ? this.Address.toJSON() : void 0;
    _data["AddressCountry"] = this.AddressCountry;
    _data["BeneficialOwnerType"] = BeneficialOwnerBeneficialOwnerType[this.BeneficialOwnerType];
    _data["BirthName"] = this.BirthName;
    _data["CityOfBirth"] = this.CityOfBirth;
    _data["CountryOfBirth"] = this.CountryOfBirth;
    _data["DataManagement"] = this.DataManagement;
    _data["DateOfBirth"] = DateConverter.toUtcDateString(this.DateOfBirth);
    _data["ExtentOfOwnership"] = this.ExtentOfOwnership;
    _data["FirstName"] = this.FirstName;
    _data["IsAssociateToPoliticallyExposed"] = this.IsAssociateToPoliticallyExposed;
    _data["IsPoliticallyExposed"] = this.IsPoliticallyExposed;
    _data["IsRelativeToPoliticallyExposed"] = this.IsRelativeToPoliticallyExposed;
    _data["LastName"] = this.LastName;
    _data["Nationality"] = this.Nationality;
    _data["TypeOfOwnership"] = BeneficialOwnerTypeOfOwnership[this.TypeOfOwnership];
    return _data;
  }
}
export var BeneficialOwnerBeneficialOwnerType = /* @__PURE__ */ ((BeneficialOwnerBeneficialOwnerType2) => {
  BeneficialOwnerBeneficialOwnerType2["NaturalPerson"] = "NaturalPerson";
  BeneficialOwnerBeneficialOwnerType2["ExecutiveOfficer"] = "ExecutiveOfficer";
  return BeneficialOwnerBeneficialOwnerType2;
})(BeneficialOwnerBeneficialOwnerType || {});
export var BeneficialOwnerTypeOfOwnership = /* @__PURE__ */ ((BeneficialOwnerTypeOfOwnership2) => {
  BeneficialOwnerTypeOfOwnership2["VotingRights"] = "VotingRights";
  BeneficialOwnerTypeOfOwnership2["DecisiveInfluence"] = "DecisiveInfluence";
  return BeneficialOwnerTypeOfOwnership2;
})(BeneficialOwnerTypeOfOwnership || {});
