export var MigrationCompanyLegalStatus = /* @__PURE__ */ ((MigrationCompanyLegalStatus2) => {
  MigrationCompanyLegalStatus2["Company"] = "Company";
  MigrationCompanyLegalStatus2["Individual"] = "Individual";
  MigrationCompanyLegalStatus2["Partnership"] = "Partnership";
  return MigrationCompanyLegalStatus2;
})(MigrationCompanyLegalStatus || {});
export var MigrationCompanyClientAcceptance = /* @__PURE__ */ ((MigrationCompanyClientAcceptance2) => {
  MigrationCompanyClientAcceptance2["Pending"] = "Pending";
  MigrationCompanyClientAcceptance2["Completed"] = "Completed";
  MigrationCompanyClientAcceptance2["Failed"] = "Failed";
  MigrationCompanyClientAcceptance2["MFE"] = "MFE";
  return MigrationCompanyClientAcceptance2;
})(MigrationCompanyClientAcceptance || {});
