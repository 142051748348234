var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import {
  getAllowedDSFieldTypesByPDFFieldType,
  getAllowedExpressionTypes
} from "./TemplateModels";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { createRange } from "GlobalShared/helpers/collectionHelper";
import { formatDateTime } from "GlobalShared/helpers/dateHelper";
import {
  convertToMapping,
  getDSFieldByPath,
  textualFormat,
  toMappingItemConcat,
  toMappingItemCondition,
  toMappingItemDateFormat,
  toMappingItemEnum,
  toMappingItemIdentity,
  toMappingItemSubstring
} from "./templateHelper";
import { setDataTransfer } from "GlobalShared/ui/dc-dropfield";
import { notifyWarning } from "GlobalShared/helpers/errorHelper";
import { editIcon } from "GlobalShared/templates/commons";
import { usePermissionManager } from "AdminComponents/hooks/AdminCustomHooks";
export const observedAttributes = [];
export const useShadowDOM = false;
export const name = "ww-template-mapping";
export const Component = (host) => {
  const props = {
    dataSource: host.dataSource,
    template: host.template,
    templateVersion: host.templateVersion,
    onSaveMappings: host.onSaveMappings,
    onClearMapping: host.onClearMapping
  };
  const copyMappingId = (exprType) => {
    if (exprType === "IDENTITY") {
      setCurrentIdentityMapping(__spreadProps(__spreadValues({}, currentIdentityMapping), {
        id: currentMappingId
      }));
    } else if (exprType === "DATEFORMAT") {
      setCurrentDateFormatMapping(__spreadProps(__spreadValues({}, currentDateFormatMapping), {
        id: currentMappingId
      }));
    } else if (exprType === "SUBSTRING") {
      setCurrentSubstringMapping(__spreadProps(__spreadValues({}, currentSubstringMapping), {
        id: currentMappingId
      }));
    } else if (exprType === "CONCAT") {
      setCurrentConcatMapping(__spreadProps(__spreadValues({}, currentConcatMapping), {
        id: currentMappingId
      }));
    } else if (exprType === "ENUM") {
      setCurrentEnumMapping(__spreadProps(__spreadValues({}, currentEnumMapping), {
        id: currentMappingId
      }));
    } else if (exprType === "CONDITION") {
      setCurrentConditionMapping(__spreadProps(__spreadValues({}, currentConditionMapping), {
        id: currentMappingId
      }));
    }
  };
  const [currentPDFField, setCurrentPDFField] = useState(void 0);
  const [mappingItems, setMappingItems] = useState([]);
  const [allowedExpressionTypes, setAllowedExpressionTypes] = useState([]);
  const [selectedExpressionType, setSelectedExpressionType] = useState(void 0);
  const [showMappingsModal, setShowMappingsModal] = useState(false);
  const [currentMappingId, setCurrentMappingId] = useState(void 0);
  const [currentIdentityMapping, setCurrentIdentityMapping] = useState(void 0);
  const [currentDateFormatMapping, setCurrentDateFormatMapping] = useState(void 0);
  const [currentEnumMapping, setCurrentEnumMapping] = useState(void 0);
  const [currentSubstringMapping, setCurrentSubstringMapping] = useState(void 0);
  const [currentConcatMapping, setCurrentConcatMapping] = useState(void 0);
  const [currentConditionMapping, setCurrentConditionMapping] = useState(void 0);
  const [filterTerm, setFilterTerm] = useState("");
  const [actionInProgress, setActionInProgress] = useState(false);
  const permissionManager = usePermissionManager();
  useEffect(() => {
    var _a, _b;
    setMappingItems(((_a = props.templateVersion) == null ? void 0 : _a.MappingsItems) ? (_b = props.templateVersion) == null ? void 0 : _b.MappingsItems : []);
  }, [props.templateVersion]);
  const onEditMappingClicked = (pdfField, mappingItem) => {
    if (props.dataSource === void 0 && props.template.Type === "MPCompany") {
      notifyWarning("No data source defined yet");
    } else if (props.dataSource === void 0 && props.template.Type === "MPVatReg") {
      notifyWarning("Select Company first");
    } else {
      const tempAllowedExpressionTypes = getAllowedExpressionTypes(pdfField.type);
      setAllowedExpressionTypes(tempAllowedExpressionTypes);
      if (tempAllowedExpressionTypes.length === 1) {
        setSelectedExpressionType(tempAllowedExpressionTypes[0]);
      } else {
        setSelectedExpressionType(mappingItem == null ? void 0 : mappingItem.Type);
      }
      setCurrentPDFField(pdfField);
      setCurrentIdentityMapping(void 0);
      setCurrentDateFormatMapping(void 0);
      setCurrentSubstringMapping(void 0);
      setCurrentConcatMapping(void 0);
      if (pdfField.type === "Dropdown") {
        setCurrentEnumMapping({
          id: void 0,
          pdfFieldValues: pdfField.values,
          pdfFieldName: pdfField.name,
          type: "ENUM",
          path: void 0,
          srcValues: void 0,
          pairs: []
        });
      }
      setCurrentConditionMapping(void 0);
      if ((mappingItem == null ? void 0 : mappingItem.Type) === "IDENTITY") {
        setCurrentIdentityMapping(convertToMapping(mappingItem));
      } else if ((mappingItem == null ? void 0 : mappingItem.Type) === "DATEFORMAT") {
        setCurrentDateFormatMapping(convertToMapping(mappingItem));
      } else if ((mappingItem == null ? void 0 : mappingItem.Type) === "SUBSTRING") {
        setCurrentSubstringMapping(convertToMapping(mappingItem));
      } else if ((mappingItem == null ? void 0 : mappingItem.Type) === "CONCAT") {
        setCurrentConcatMapping(convertToMapping(mappingItem));
      } else if ((mappingItem == null ? void 0 : mappingItem.Type) === "ENUM") {
        const enumMapping = convertToMapping(mappingItem);
        enumMapping.pdfFieldValues = pdfField.values;
        const srcField = getDSFieldByPath(props.dataSource, enumMapping.path);
        enumMapping.srcValues = srcField == null ? void 0 : srcField.values;
        setCurrentEnumMapping(enumMapping);
      } else if ((mappingItem == null ? void 0 : mappingItem.Type) === "CONDITION") {
        setCurrentConditionMapping(convertToMapping(mappingItem));
      }
      setCurrentMappingId(mappingItem == null ? void 0 : mappingItem.Id);
      setShowMappingsModal(true);
    }
  };
  const onSaveMappings = (pMappingItems) => __async(void 0, null, function* () {
    try {
      setActionInProgress(true);
      if (yield props.onSaveMappings(pMappingItems)) {
        setShowMappingsModal(false);
      } else {
      }
    } finally {
      setActionInProgress(false);
    }
  });
  const onClearMapping = (mapping) => __async(void 0, null, function* () {
    try {
      setActionInProgress(true);
      if (yield props.onClearMapping(mapping)) {
        setShowMappingsModal(false);
      } else {
      }
      setShowMappingsModal(false);
    } finally {
      setActionInProgress(false);
    }
  });
  const onSaveIdentityMapping = (identityMapping) => {
    onSaveMappings([toMappingItemIdentity(currentPDFField, identityMapping)]);
  };
  const onSaveDateFormatMapping = (dateFormatMapping) => {
    onSaveMappings([toMappingItemDateFormat(currentPDFField, dateFormatMapping)]);
  };
  const onSaveSubstringMapping = (substringMapping) => {
    onSaveMappings([toMappingItemSubstring(currentPDFField, substringMapping)]);
  };
  const onSaveConcatMapping = (concatMapping) => {
    onSaveMappings([toMappingItemConcat(currentPDFField, concatMapping)]);
  };
  const onSaveEnumMapping = (enumMapping) => {
    onSaveMappings([toMappingItemEnum(currentPDFField, enumMapping)]);
  };
  const onSaveConditionMapping = (conditionMapping) => {
    onSaveMappings([toMappingItemCondition(currentPDFField, conditionMapping)]);
  };
  const onCancelMapping = () => {
    setShowMappingsModal(false);
  };
  const htmlDataSourceItem = (allowedFieldTypes, draggable, key, item, depth, path) => {
    if (depth > 10) {
      return html``;
    }
    if (item.type !== void 0 && item.name !== void 0) {
      const field = item;
      const textVal = `${key}:
			${field.type === "DateTime" ? formatDateTime(field.value) : field.type === "Enum" && !isNaN(Number(field.value)) && field.values.length > Number(field.value) ? field.values[Number(field.value)] : field.value}
			(${field.type === "Enum" ? `[${field.values.join(", ")}]` : field.type})`;
      if (filterTerm && (!textVal.toLowerCase().includes(filterTerm.toLowerCase()) || !allowedFieldTypes.includes(field.type))) {
        return html``;
      }
      const htmlVal = html`${unsafeHTML(
        createRange(0, depth - 1).map((_) => "&nbsp;&nbsp;").join("")
      )}${textVal}`;
      if (!allowedFieldTypes.includes(field.type)) {
        return html`<div class="${!allowedFieldTypes.includes(field.type) ? "text-gray-400" : ""}">${htmlVal}</div>`;
      } else {
        if (draggable) {
          return html`<div
						draggable="true"
						class="border bg-yellow-300"
						@dragstart=${(e) => {
            const draggedItem = {
              type: "TemplateDataSource",
              field,
              path: `${path}`
            };
            setDataTransfer(e, draggedItem);
          }}
					>
						${htmlVal}
					</div>`;
        } else {
          return html`<div>${htmlVal}</div>`;
        }
      }
    } else {
      const node = item;
      return html`<div class="font-bold">
					${unsafeHTML(
        createRange(0, depth - 1).map((_) => "&nbsp;&nbsp;").join("")
      )}${key}
				</div>
				${Object.keys(item).map((key2) => htmlDataSourceItem(allowedFieldTypes, draggable, key2, node[key2], depth + 1, `${path}${path ? "." : ""}${key2}`))}`;
    }
  };
  const htmlDataSource = (allowedFieldTypes, draggable) => {
    if (props.template.Type === "MPVatReg" && props.dataSource !== void 0) {
      return html` <dc-input class="mb-4" .placeholder=${"Filter"} .value=${filterTerm} @input=${(e) => setFilterTerm(e.detail.value)}></dc-input>
				${Object.keys(props.dataSource).map((key) => htmlDataSourceItem(allowedFieldTypes, draggable, key, props.dataSource[key], 0, key))}`;
    } else {
      return "";
    }
  };
  const htmlMappingEditor = (expressionType) => {
    let template = html``;
    switch (expressionType) {
      case "IDENTITY":
        template = html`<ww-identity-mapping
					.identityMapping=${currentIdentityMapping}
					.actionInProgress=${actionInProgress}
					.onSave=${onSaveIdentityMapping}
					.onClear=${onClearMapping}
					.onCancel=${onCancelMapping}
				></ww-identity-mapping>`;
        break;
      case "CONCAT":
        template = html`<ww-concat-mapping
					.concatMapping=${currentConcatMapping}
					.actionInProgress=${actionInProgress}
					.onSave=${onSaveConcatMapping}
					.onClear=${onClearMapping}
					.onCancel=${onCancelMapping}
				></ww-concat-mapping>`;
        break;
      case "SUBSTRING":
        template = html`<ww-substring-mapping
					.substringMapping=${currentSubstringMapping}
					.actionInProgress=${actionInProgress}
					.onSave=${onSaveSubstringMapping}
					.onClear=${onClearMapping}
					.onCancel=${onCancelMapping}
				></ww-substring-mapping>`;
        break;
      case "DATEFORMAT":
        template = html`<ww-date-format-mapping
					.dateFormatMapping=${currentDateFormatMapping}
					.actionInProgress=${actionInProgress}
					.onSave=${onSaveDateFormatMapping}
					.onClear=${onClearMapping}
					.onCancel=${onCancelMapping}
				></ww-date-format-mapping>`;
        break;
      case "CONDITION":
        template = html`<ww-condition-mapping
					.conditionMapping=${currentConditionMapping}
					.actionInProgress=${actionInProgress}
					.onSave=${onSaveConditionMapping}
					.onClear=${onClearMapping}
					.onCancel=${onCancelMapping}
				></ww-condition-mapping>`;
        break;
      case "ENUM":
        template = html`<ww-enum-mapping
					.enumMapping=${currentEnumMapping}
					.actionInProgress=${actionInProgress}
					.onSave=${onSaveEnumMapping}
					.onClear=${onClearMapping}
					.onCancel=${onCancelMapping}
				></ww-enum-mapping>`;
        break;
      default:
        if (expressionType !== void 0) {
          template = "No Editor exising yet for this mapping";
        } else {
          template = "";
        }
        break;
    }
    return html`<div class="p-2">${template}</div>`;
  };
  const htmlMappingsModal = () => {
    if (showMappingsModal) {
      return html`
				<div class="mappings-modal-container">
					<dc-modal
						.visible=${showMappingsModal}
						@close=${() => setShowMappingsModal(false)}
						.header=${`Edit Mapping | ${currentPDFField.name} | ${currentPDFField.type}`}
						.content=${html`<div class="w-full h-full flex items-start space-x-4">
							<div class="w-1/2 h-full">
								<div class="${selectedExpressionType !== "ENUM" ? "xl:w-1/2" : ""}">
									${allowedExpressionTypes.length > 1 ? html`<dc-select
												.label=${"Type"}
												.selectedValues=${[selectedExpressionType]}
												.dataSource=${allowedExpressionTypes.map((type) => ({
        value: type,
        label: type
      }))}
												@change=${(e) => {
        const exprType = e.detail.selectedValue;
        setSelectedExpressionType(exprType);
        copyMappingId(exprType);
      }}
										  ></dc-select>` : allowedExpressionTypes.length === 1 ? html`Type: ${allowedExpressionTypes[0]}` : ""}
									<div>${htmlMappingEditor(selectedExpressionType)}</div>
								</div>
							</div>
							<div class="w-1/2 h-full">
								<div class="text-xl font-bold">Datasource</div>
								<div class="h-full overflow-auto">
									<!-- DEVNOTE Sorry, the modal has to be rewritten first -->
									<div class="border p-2" style="max-height: 75vh">${htmlDataSource(getAllowedDSFieldTypesByPDFFieldType(currentPDFField.type, selectedExpressionType), true)}</div>
								</div>
							</div>
						</div> `}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlMappings = () => {
    var _a;
    if ((_a = props.templateVersion) == null ? void 0 : _a.PDFFields) {
      return html`<table class="p-4 w-full">
				${props.templateVersion.PDFFields.map((pdfField) => {
        const mappingItem = mappingItems.find((m) => m.PdfFieldName.toLowerCase() === pdfField.name.toLowerCase());
        return html`<tr class="color-odd-row border-b border-solid border-gray-300 bg-white">
						<td class="p-1 pl-4 align-middle break-all">
							<span class="flex items-center">
								<span>${pdfField.name} </span>
							</span>
						</td>
						<td class="p-1 align-middle">
							<span class="uppercase text-xs italic">${pdfField.type}</span>
						</td>
						<td class="p-1 align-middle">
							<span
								class="edit-icon-container"
								?disabled=${!props.templateVersion.Draft || !permissionManager.hasPermission("Template_Modify")}
								@click=${() => onEditMappingClicked(pdfField, mappingItem)}
							>
								${editIcon()}
							</span>
						</td>

						<td class="p-1 align-middle">${mappingItem ? html`<span class="mx-4">&#8680;</span>` : ""}</td>
						<td class="p-1 align-middle break-all">${mappingItem ? html`${html`<span class="text-sm">${unsafeHTML(textualFormat(mappingItem))}</span>`}` : ""}</td>
					</tr>`;
      })}
				${htmlMappingsModal()}
			</table>`;
    } else {
      return "";
    }
  };
  return html`<div class="h-full">
		<div class="border h-full">${htmlMappings()}</div>
	</div>`;
};
