var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { MasterData as WebMasterDataClient } from "WebServices/APIs/self/clients/MasterData";
const WORLD_COUNTRIES_KEY = "md_world_countries";
const SERVICE_COUNTRIES_KEY = "md_service_countries";
const EU_COUNTRIES_KEY = "md_eu_countries";
const BIRTH_COUNTRIES_KEY = "md_birth_countries";
export const TEST_QUESTIONNAIRE_MODEL = "test_questionnaire_model";
export const getWorldCountries = (masterDataClient, locale) => __async(void 0, null, function* () {
  const key = `${WORLD_COUNTRIES_KEY}:${locale}`;
  const storedCountries = sessionStorage[key];
  if (storedCountries) {
    return JSON.parse(storedCountries);
  } else {
    const countries = masterDataClient instanceof WebMasterDataClient ? (yield masterDataClient.getCountries({ locale })).data : (yield masterDataClient.getCountries()).data;
    countries.sort((a, b) => a.Name.localeCompare(b.Name));
    sessionStorage.setItem(key, JSON.stringify(countries));
    return countries;
  }
});
export const getEuCountries = (masterDataClient, locale) => __async(void 0, null, function* () {
  const key = `${EU_COUNTRIES_KEY}:${locale}`;
  const storedCountries = sessionStorage[key];
  if (storedCountries) {
    return JSON.parse(storedCountries);
  } else {
    const countries = masterDataClient instanceof WebMasterDataClient ? (yield masterDataClient.getEuCountries({ locale })).data : (yield masterDataClient.getEuCountries()).data;
    sessionStorage.setItem(key, JSON.stringify(countries));
    return countries;
  }
});
export const getServiceCountries = (masterDataClient, locale) => __async(void 0, null, function* () {
  const key = `${SERVICE_COUNTRIES_KEY}:${locale}`;
  const storedCountries = sessionStorage[key];
  if (storedCountries) {
    return JSON.parse(storedCountries);
  } else {
    const countries = masterDataClient instanceof WebMasterDataClient ? (yield masterDataClient.getServiceCountries({ locale })).data : (yield masterDataClient.getServiceCountries()).data;
    sessionStorage.setItem(key, JSON.stringify(countries));
    return countries;
  }
});
export const getBirthCountries = (masterDataClient, locale) => __async(void 0, null, function* () {
  const key = `${BIRTH_COUNTRIES_KEY}:${locale}`;
  const storedCountries = sessionStorage[key];
  if (storedCountries) {
    return JSON.parse(storedCountries);
  } else {
    const countries = masterDataClient instanceof WebMasterDataClient ? (yield masterDataClient.getBirthCountries({ locale })).data : (yield masterDataClient.getBirthCountries()).data;
    sessionStorage.setItem(key, JSON.stringify(countries));
    return countries;
  }
});
