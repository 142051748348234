export const NUMBERED_TEXTS = [
  {
    displayNumber: 1,
    text: `This engagement contract governs the services that KPMG Global Services Hungary Ltd, V\xE1ci \xFAt 41, 1134 Budapest, Hungary ("KPMG", "we") will provide to the contracting party indicated above ("you").`
  },
  {
    displayNumber: 2,
    text: `Upon entering into this contract, you agree that KPMG will provide the Core Tax Compliance Services set out in clause 6 below in respect of the countries listed in Appendix 1(a) and for the Annual Service Fee set out in Appendix 1(a).`
  },
  {
    displayNumber: 3,
    text: `In addition, upon your specific request, KPMG may provide you with Additional Tax Services for Additional Service Fees, which are set out in Appendix 1(b).`
  },
  {
    displayNumber: 4,
    text: `The General Terms of Business (KGSH) form an integral part of this contract.`
  },
  {
    displayNumber: 5,
    text: `KPMG has a process of approving acceptance of new engagements, which includes the assessment of our compliance with applicable regulatory requirements. KPMG's acceptance of this contract is subject to the satisfactory completion of KPMG's pre-approval procedures and Anti-Money Laundering obligations.`
  },
  {
    displayNumber: 6,
    text: `Core Tax Compliance Services includes the following services:`
  },
  {
    displayNumber: 7,
    text: `For the avoidance of doubt, the following services are not included in the scope of the Core Tax Compliance Services, although they can be provided as Additional Tax Services upon payment of the respective Additional Service Fee:`
  },
  {
    displayNumber: 8,
    text: `For the avoidance of doubt, the following services are not included in the scope of the Core Tax Compliance Services, although they can be provided as Additional Tax Services upon payment of the respective Additional Service Fee:`
  },
  {
    displayNumber: 9,
    text: `KPMG cannot accept any liability for errors arising from incomplete information or inaccuracies in the data provided to us.`
  },
  {
    displayNumber: 10,
    text: `In order for us to prepare your VATand associated returns, you must provide us with the Amazon VAT Transactions Report (typically downloaded from your Amazon Seller Central account) in text format without modification or the KPMG template file. The KPMG VAT Compliance Portal has been tailored to process these specific data reports and requires this fixed format. If you modify the data before uploading it to the KPMG VATCompliance Portal, you do so at your own risk.`
  },
  {
    displayNumber: 11,
    text: `Alternatively, you may provide us with the exact sales and purchase values by inputting the data into the online form on the KPMG VAT Compliance Portal.`
  },
  {
    displayNumber: 12,
    text: `Youmust provide us with all required data to prepare the VAT and associated returns for each calendar month by 17:00 Central European Time ("CET") on the 6th working day following the end of the calendar month. If data is received late, we reserve the right to charge you a penalty fee of 50 Euros.`
  },
  {
    displayNumber: 13,
    text: `If no data is received by the statutory submission deadline for a particular return and you have not provided us with any alternative instructions, KPMG will not submit a return to the tax authority. This may result in financial or other consequences from the tax authority, for which you will be fully liable.`
  },
  {
    displayNumber: 14,
    text: `In case you are trading on multiple platforms (for example, several marketplaces and/or through your own website), you must provide KPMG with the data needed to prepare the VATreturns in a format specified by KPMG.`
  },
  {
    displayNumber: 15,
    text: `We will ask you to approve each return prior to our submitting it to the relevant tax authority. If we do`
  },
  {
    displayNumber: 16,
    text: `In some countries, KPMG is not authorized to file returns on behalf of another person or entity. In these cases, it will be your responsibility to sign the returns and submit them directly to the taxing authority. KPMG will provide you with the return to be submitted together with the postal address where it should be sent. You will be responsible for ensuring that the return is delivered.`
  },
  {
    displayNumber: 17,
    text: `Notwithstanding clause 18, KPMG will not make VATpayments on your behalf. We will provide you with the necessary payment instructions but the responsibility to make the payments will remain with you.`
  },
  {
    displayNumber: 18,
    text: `In Spain, VAT payments must be made from a Spanish bank account. As an Additional Tax Service (and subject to a separate fee set out in Appendix 1(b), we may be able to make these payments on your behalf. In order to provide this service, you must transfer the full amount of VATthat you owe to our Spanish bank account as it should be received by us at least 1 working day before the payment deadline`
  },
  {
    displayNumber: 19,
    text: `You shall retain responsibility for the correctness and completeness of all returns and other filings we submit on your behalf, and for the payment of your tax liabilities. Further, KPMG cannot accept any responsibility for penalties and interest charged by tax authorities if returns are submitted late due to the late delivery of data to us or if you pay your VAT liabilities late`
  },
  {
    displayNumber: 20,
    text: `You shall send to us promptly any notices, assessments or determinations issued by relevant tax or other authorities relating to the Services requiring action by us.`
  },
  {
    displayNumber: 21,
    text: `You shall retain responsibility for maintaining records and associated papers concerning your tax`
  },
  {
    displayNumber: 22,
    text: `You shall provide a bank guarantee for the fiscal representative You shall provide a bank guarantee for the fiscal representative (instructions will be provided by KPMG) which should be valid for the full period during which KPMG is providing services to you.`
  },
  {
    displayNumber: 23,
    text: `All Core Tax Compliance Service Fees must be paid annually in advance through Pay with Amazon.`
  },
  {
    displayNumber: 24,
    text: `Where you request us to provide Additional Tax Services, we will seek approval from for the estimated fees and disbursements in advance in order to permit you to decide whether you wish us to provide the respective service.`
  },
  {
    displayNumber: 25,
    text: `KPMG will issue a VAT invoice electronically by email after payment of the respective fees. KPMG shall have no obligation to provide services if payment is not received in advance.`
  },
  {
    displayNumber: 26,
    text: `Our fee amounts and rates set out in Appendix 1 are fixed for one year from the date of payment of the first Annual Service Fee and may be revised annually thereafter. Revised rates will be notified to you at least 2 months in advance of their application.`
  },
  {
    displayNumber: 27,
    text: `All fees are exclusive of VAT(where applicable) and any non-Hungarian taxes payable thereon or deductible therefrom.`
  },
  {
    displayNumber: 28,
    text: `Either party has the right to terminate this contract by providing 30 days written notice to  ${ClientContext.ContactEmailAddress} (if cancelled by you) or to the designated contact email (if cancelled by KPMG). If you do so:`
  },
  {
    displayNumber: 29,
    text: `KPMG may be required to terminate this contract for legal or professional reasons. In these circumstances, the contract may be cancelled immediately, and we shall not be required to carry out any further work following termination, nor shall we be responsible or liable for any consequences arising from such termination.`
  },
  {
    displayNumber: 30,
    text: `If KPMG cancels this contract due to a material breach by you, no refund will be made of the Annual Service Fee. In case of cancellation by KPMG for any other reason, a refund of the Annual Service Fee will be paid pro-rata according to the number of completed months of service.`
  },
  {
    displayNumber: 31,
    text: `If you are required to provide a bank guarantee and this is not provided or is cancelled, KPMG is entitled to stop providing services immediately and no Annual Service fee will be repaid.`
  },
  {
    displayNumber: 32,
    text: `Exclusions and limitations on KPMG's liability to you are set out in clauses 34 to 38 of the General Terms of Business. By signing this contract, you and we mutually agree that KPMG's liability to you in connection with the services provided in each annual service period shall be limited, on the basis set out in Appendix 2, to a maximum of three times the Annual Service fee.`
  }
];
export const ALPHABETICAL_TEXTS = [
  {
    symbol: "a)",
    text: `Within the first 14 calendar days from the date of payment of the first Annual Service Fee, you will be eligible for a full refund of all fees paid, minus any costs already incurred by KPMG`
  },
  {
    symbol: "b)",
    text: `After the first 14 calendar days but within the first 12 months, no refund of the Annual Service Fee will be paid`
  },
  {
    symbol: "c)",
    text: `After the first 12 months, a refund of the Annual Service Fee will be paid pro-rata according to the number of completed months of service`
  }
];
export const UNORDERED_TEXTS = [
  "Provide access to the KPMG VAT Compliance Portal",
  "Arrange the VAT registration in each country on your behalf, unless you already possess a VAT registration number in the respective country(ies)",
  "Prepare the VAT returns, EC Sales and/or Purchase Lists and VAT local listings (where required) for approval by you before submission. KPMG will prepare these returns based on data that you upload to the KPMG VAT Compliance Portal. KPMG currently accepts (may be subject to change):",
  "the Amazon VAT Transactions Report",
  "the standard KPMG template available on the KPMG VAT Compliance Portal",
  "Automatically extract transactional data feed required for VAT reporting purposes (this is currently only available for Amazon Sellers)",
  "Where the necessary data is received according to the deadline set out in clause 12 and there are no additional queries on the data, KPMG will provide the draft returns to you at least 3 working days prior to the statutory submission deadline to provide sufficient time for you to approve the returns and pay any VAT due",
  "Submit returns to the appropriate tax authority where legally possible and confirm submission to you",
  "Provide payment instructions for VAT liabilities to you",
  "Act as your correspondence address for the tax authority where legally permitted, and inform you of any action required as a result of correspondence received from the tax authority",
  "Act as the fiscal representative in front of the tax authorities, where this is required (this service may be subcontracted by KPMG",
  "Carrying out sworn translation or legalization of documents that are required in order to obtain the VAT registration numbers in each country. KPMG may arrange for a third party to perform sworn translations on your behalf; however, the third-party service fees will be recharged to you as a disbursement",
  "Preparing and submitting VAT and associated returns for tax reporting periods which ended before the date of this contract",
  "Advising you of the tax treatment of your business transactions",
  "Reviewing invoices and confirming that they meet the required regulatory requirements",
  "Preparing your VAT returns (or any other return) based on data which is not provided in the correct format, unless specifically agreed with you",
  "Auditing or verifying the correctness or completeness of the data you provide to us"
];
