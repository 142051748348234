import { IossRegistrationStatusChange } from "./IossRegistrationStatusChange";
import { AddCertificateStatusChange } from "./AddCertificateStatusChange";
import { BackToClientStatusChange } from "./BackToClientStatusChange";
import { DeregisteredStatusChange } from "./DeregisteredStatusChange";
import { SimpleRegistrationStatusChange } from "./SimpleRegistrationStatusChange";
export class IossRegistrationStatusChangeFactory {
  static create($type) {
    if ($type === "V1.Company.IossRegistration.IossRegistrationStatusChange") {
      const result = new IossRegistrationStatusChange();
      return result;
    }
    if ($type === "V1.Company.IossRegistration.AddCertificateStatusChange") {
      const result = new AddCertificateStatusChange();
      return result;
    }
    if ($type === "V1.Company.IossRegistration.BackToClientStatusChange") {
      const result = new BackToClientStatusChange();
      return result;
    }
    if ($type === "V1.Company.IossRegistration.DeregisteredStatusChange") {
      const result = new DeregisteredStatusChange();
      return result;
    }
    if ($type === "V1.Company.IossRegistration.SimpleRegistrationStatusChange") {
      const result = new SimpleRegistrationStatusChange();
      return result;
    }
    throw new Error($type + " not found");
  }
}
