export class ValidationMessage {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof ValidationMessage
     */
    this.$type = "V1.ComplianceMasterData.Upload.ValidationMessage";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ColumnName = _data["ColumnName"];
      if (_data["Parameters"] && _data["Parameters"].constructor === Array) {
        this.Parameters = [];
        for (let item of _data["Parameters"]) {
          this.Parameters.push(item);
        }
      }
      this.RowNumber = _data["RowNumber"];
      this.ValidationCode = ValidationMessageValidationCode[_data["ValidationCode"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ColumnName"] = this.ColumnName;
    if (this.Parameters && this.Parameters.constructor === Array) {
      _data["Parameters"] = [];
      for (let item of this.Parameters) {
        _data["Parameters"].push(item);
      }
    }
    _data["RowNumber"] = this.RowNumber;
    _data["ValidationCode"] = ValidationMessageValidationCode[this.ValidationCode];
    return _data;
  }
}
export var ValidationMessageValidationCode = /* @__PURE__ */ ((ValidationMessageValidationCode2) => {
  ValidationMessageValidationCode2["Required"] = "Required";
  ValidationMessageValidationCode2["RequiredOrWrongFormat"] = "RequiredOrWrongFormat";
  ValidationMessageValidationCode2["NotFound"] = "NotFound";
  ValidationMessageValidationCode2["CannotParse"] = "CannotParse";
  ValidationMessageValidationCode2["InvalidValue"] = "InvalidValue";
  ValidationMessageValidationCode2["Unknown"] = "Unknown";
  return ValidationMessageValidationCode2;
})(ValidationMessageValidationCode || {});
