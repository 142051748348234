import { DateConverter } from "./../../../../converters/DateConverter";
export class DateTimeUtcRange {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof DateTimeUtcRange
     */
    this.$type = "V1.Common.DateTime.DateTimeUtcRange";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.From = DateConverter.from(_data["From"]);
      this.To = DateConverter.from(_data["To"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["From"] = DateConverter.toIsoUtcString(this.From);
    _data["To"] = DateConverter.toIsoUtcString(this.To);
    return _data;
  }
}
