import { DateConverter } from "./../../../converters/DateConverter";
export class MigrationSubscriptions {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof MigrationSubscriptions
     */
    this.$type = "V1.Migration.MigrationSubscriptions";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CountryCode = _data["CountryCode"];
      this.EslFrequency = MigrationSubscriptionsEslFrequency[_data["EslFrequency"]];
      this.EsVatPayment = _data["EsVatPayment"];
      this.EuVatNumber = _data["EuVatNumber"];
      this.EuVatStartDate = DateConverter.from(_data["EuVatStartDate"]);
      this.FirstMonthOfGeneration = DateConverter.from(_data["FirstMonthOfGeneration"]);
      this.FiscRep = _data["FiscRep"];
      this.GermanFilingAgent = _data["GermanFilingAgent"];
      this.IbanOrLink = _data["IbanOrLink"];
      this.IntrastatFrequency = MigrationSubscriptionsIntrastatFrequency[_data["IntrastatFrequency"]];
      this.IntrastatIn = _data["IntrastatIn"];
      this.IntrastatOut = _data["IntrastatOut"];
      this.LocalVatNumber = _data["LocalVatNumber"];
      this.LocalVatStartDate = DateConverter.from(_data["LocalVatStartDate"]);
      this.Name = _data["Name"];
      this.PortalId = _data["PortalId"];
      this.PrimaryFrequency = MigrationSubscriptionsPrimaryFrequency[_data["PrimaryFrequency"]];
      this.PrimaryFrequencyEFiling = _data["PrimaryFrequencyEFiling"];
      this.PrimaryFrequencyFilingExtension = _data["PrimaryFrequencyFilingExtension"];
      this.RegistrationStatus = MigrationSubscriptionsRegistrationStatus[_data["RegistrationStatus"]];
      this.SecondaryFrequency = MigrationSubscriptionsSecondaryFrequency[_data["SecondaryFrequency"]];
      this.SecondaryFrequencyEFiling = _data["SecondaryFrequencyEFiling"];
      this.SecondaryFrequencyFilingExtension = _data["SecondaryFrequencyFilingExtension"];
      this.ServiceCountry = _data["ServiceCountry"];
      this.SubscriptionValidTo = DateConverter.from(_data["SubscriptionValidTo"]);
      this.VlsFrequency = MigrationSubscriptionsVlsFrequency[_data["VlsFrequency"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CountryCode"] = this.CountryCode;
    _data["EslFrequency"] = MigrationSubscriptionsEslFrequency[this.EslFrequency];
    _data["EsVatPayment"] = this.EsVatPayment;
    _data["EuVatNumber"] = this.EuVatNumber;
    _data["EuVatStartDate"] = DateConverter.toUtcDateString(this.EuVatStartDate);
    _data["FirstMonthOfGeneration"] = DateConverter.toUtcDateString(this.FirstMonthOfGeneration);
    _data["FiscRep"] = this.FiscRep;
    _data["GermanFilingAgent"] = this.GermanFilingAgent;
    _data["IbanOrLink"] = this.IbanOrLink;
    _data["IntrastatFrequency"] = MigrationSubscriptionsIntrastatFrequency[this.IntrastatFrequency];
    _data["IntrastatIn"] = this.IntrastatIn;
    _data["IntrastatOut"] = this.IntrastatOut;
    _data["LocalVatNumber"] = this.LocalVatNumber;
    _data["LocalVatStartDate"] = DateConverter.toUtcDateString(this.LocalVatStartDate);
    _data["Name"] = this.Name;
    _data["PortalId"] = this.PortalId;
    _data["PrimaryFrequency"] = MigrationSubscriptionsPrimaryFrequency[this.PrimaryFrequency];
    _data["PrimaryFrequencyEFiling"] = this.PrimaryFrequencyEFiling;
    _data["PrimaryFrequencyFilingExtension"] = this.PrimaryFrequencyFilingExtension;
    _data["RegistrationStatus"] = MigrationSubscriptionsRegistrationStatus[this.RegistrationStatus];
    _data["SecondaryFrequency"] = MigrationSubscriptionsSecondaryFrequency[this.SecondaryFrequency];
    _data["SecondaryFrequencyEFiling"] = this.SecondaryFrequencyEFiling;
    _data["SecondaryFrequencyFilingExtension"] = this.SecondaryFrequencyFilingExtension;
    _data["ServiceCountry"] = this.ServiceCountry;
    _data["SubscriptionValidTo"] = DateConverter.toUtcDateString(this.SubscriptionValidTo);
    _data["VlsFrequency"] = MigrationSubscriptionsVlsFrequency[this.VlsFrequency];
    return _data;
  }
}
export var MigrationSubscriptionsEslFrequency = /* @__PURE__ */ ((MigrationSubscriptionsEslFrequency2) => {
  MigrationSubscriptionsEslFrequency2["Monthly"] = "Monthly";
  MigrationSubscriptionsEslFrequency2["Quarterly"] = "Quarterly";
  MigrationSubscriptionsEslFrequency2["QuarterlyShifted1"] = "QuarterlyShifted1";
  MigrationSubscriptionsEslFrequency2["QuarterlyShifted2"] = "QuarterlyShifted2";
  MigrationSubscriptionsEslFrequency2["BiMonthly"] = "BiMonthly";
  MigrationSubscriptionsEslFrequency2["FourMonthly"] = "FourMonthly";
  MigrationSubscriptionsEslFrequency2["SixMonthly"] = "SixMonthly";
  MigrationSubscriptionsEslFrequency2["Yearly"] = "Yearly";
  return MigrationSubscriptionsEslFrequency2;
})(MigrationSubscriptionsEslFrequency || {});
export var MigrationSubscriptionsIntrastatFrequency = /* @__PURE__ */ ((MigrationSubscriptionsIntrastatFrequency2) => {
  MigrationSubscriptionsIntrastatFrequency2["Monthly"] = "Monthly";
  MigrationSubscriptionsIntrastatFrequency2["Quarterly"] = "Quarterly";
  MigrationSubscriptionsIntrastatFrequency2["QuarterlyShifted1"] = "QuarterlyShifted1";
  MigrationSubscriptionsIntrastatFrequency2["QuarterlyShifted2"] = "QuarterlyShifted2";
  MigrationSubscriptionsIntrastatFrequency2["BiMonthly"] = "BiMonthly";
  MigrationSubscriptionsIntrastatFrequency2["FourMonthly"] = "FourMonthly";
  MigrationSubscriptionsIntrastatFrequency2["SixMonthly"] = "SixMonthly";
  MigrationSubscriptionsIntrastatFrequency2["Yearly"] = "Yearly";
  return MigrationSubscriptionsIntrastatFrequency2;
})(MigrationSubscriptionsIntrastatFrequency || {});
export var MigrationSubscriptionsPrimaryFrequency = /* @__PURE__ */ ((MigrationSubscriptionsPrimaryFrequency2) => {
  MigrationSubscriptionsPrimaryFrequency2["Monthly"] = "Monthly";
  MigrationSubscriptionsPrimaryFrequency2["Quarterly"] = "Quarterly";
  MigrationSubscriptionsPrimaryFrequency2["QuarterlyShifted1"] = "QuarterlyShifted1";
  MigrationSubscriptionsPrimaryFrequency2["QuarterlyShifted2"] = "QuarterlyShifted2";
  MigrationSubscriptionsPrimaryFrequency2["BiMonthly"] = "BiMonthly";
  MigrationSubscriptionsPrimaryFrequency2["FourMonthly"] = "FourMonthly";
  MigrationSubscriptionsPrimaryFrequency2["SixMonthly"] = "SixMonthly";
  MigrationSubscriptionsPrimaryFrequency2["Yearly"] = "Yearly";
  return MigrationSubscriptionsPrimaryFrequency2;
})(MigrationSubscriptionsPrimaryFrequency || {});
export var MigrationSubscriptionsRegistrationStatus = /* @__PURE__ */ ((MigrationSubscriptionsRegistrationStatus2) => {
  MigrationSubscriptionsRegistrationStatus2["LocalTaxReceived"] = "LocalTaxReceived";
  MigrationSubscriptionsRegistrationStatus2["RegistrationCompleted"] = "RegistrationCompleted";
  return MigrationSubscriptionsRegistrationStatus2;
})(MigrationSubscriptionsRegistrationStatus || {});
export var MigrationSubscriptionsSecondaryFrequency = /* @__PURE__ */ ((MigrationSubscriptionsSecondaryFrequency2) => {
  MigrationSubscriptionsSecondaryFrequency2["Monthly"] = "Monthly";
  MigrationSubscriptionsSecondaryFrequency2["Quarterly"] = "Quarterly";
  MigrationSubscriptionsSecondaryFrequency2["QuarterlyShifted1"] = "QuarterlyShifted1";
  MigrationSubscriptionsSecondaryFrequency2["QuarterlyShifted2"] = "QuarterlyShifted2";
  MigrationSubscriptionsSecondaryFrequency2["BiMonthly"] = "BiMonthly";
  MigrationSubscriptionsSecondaryFrequency2["FourMonthly"] = "FourMonthly";
  MigrationSubscriptionsSecondaryFrequency2["SixMonthly"] = "SixMonthly";
  MigrationSubscriptionsSecondaryFrequency2["Yearly"] = "Yearly";
  return MigrationSubscriptionsSecondaryFrequency2;
})(MigrationSubscriptionsSecondaryFrequency || {});
export var MigrationSubscriptionsVlsFrequency = /* @__PURE__ */ ((MigrationSubscriptionsVlsFrequency2) => {
  MigrationSubscriptionsVlsFrequency2["Monthly"] = "Monthly";
  MigrationSubscriptionsVlsFrequency2["Quarterly"] = "Quarterly";
  MigrationSubscriptionsVlsFrequency2["QuarterlyShifted1"] = "QuarterlyShifted1";
  MigrationSubscriptionsVlsFrequency2["QuarterlyShifted2"] = "QuarterlyShifted2";
  MigrationSubscriptionsVlsFrequency2["BiMonthly"] = "BiMonthly";
  MigrationSubscriptionsVlsFrequency2["FourMonthly"] = "FourMonthly";
  MigrationSubscriptionsVlsFrequency2["SixMonthly"] = "SixMonthly";
  MigrationSubscriptionsVlsFrequency2["Yearly"] = "Yearly";
  return MigrationSubscriptionsVlsFrequency2;
})(MigrationSubscriptionsVlsFrequency || {});
