import { VatRegSearchContent } from "./VatRegSearchContent";
export class VatRegSearchResult {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRegSearchResult
     */
    this.$type = "V1.Company.VatRegistration.Search.VatRegSearchResult";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Count = _data["Count"];
      if (_data["VatRegSearchContents"] && _data["VatRegSearchContents"].constructor === Array) {
        this.VatRegSearchContents = [];
        for (let item of _data["VatRegSearchContents"]) {
          const vatRegSearchContents_ = new VatRegSearchContent();
          this.VatRegSearchContents.push(vatRegSearchContents_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Count"] = this.Count;
    if (this.VatRegSearchContents && this.VatRegSearchContents.constructor === Array) {
      _data["VatRegSearchContents"] = [];
      for (let item of this.VatRegSearchContents) {
        _data["VatRegSearchContents"].push(item.toJSON());
      }
    }
    return _data;
  }
}
