import { DateConverter } from "./../../../../converters/DateConverter";
export class CancelSubscription {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CancelSubscription
     */
    this.$type = "V1.Company.Subscriptions.CancelSubscription";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CancellationDate = DateConverter.from(_data["CancellationDate"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CancellationDate"] = DateConverter.toUtcDateString(this.CancellationDate);
    return _data;
  }
}
