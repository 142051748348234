import { UpdatedSellerEntity } from "./UpdatedSellerEntity";
export class UpdatedAmazonConfiguration {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof UpdatedAmazonConfiguration
     */
    this.$type = "V1.ComplianceUploads.Configuration.Amazon.UpdatedAmazonConfiguration";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.RefundMode = UpdatedAmazonConfigurationRefundMode[_data["RefundMode"]];
      if (_data["SellerEntities"] && _data["SellerEntities"].constructor === Array) {
        this.SellerEntities = [];
        for (let item of _data["SellerEntities"]) {
          const sellerEntities_ = new UpdatedSellerEntity();
          this.SellerEntities.push(sellerEntities_.init(item));
        }
      }
      this.UseAmazonRates = _data["UseAmazonRates"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["RefundMode"] = UpdatedAmazonConfigurationRefundMode[this.RefundMode];
    if (this.SellerEntities && this.SellerEntities.constructor === Array) {
      _data["SellerEntities"] = [];
      for (let item of this.SellerEntities) {
        _data["SellerEntities"].push(item.toJSON());
      }
    }
    _data["UseAmazonRates"] = this.UseAmazonRates;
    return _data;
  }
}
export var UpdatedAmazonConfigurationRefundMode = /* @__PURE__ */ ((UpdatedAmazonConfigurationRefundMode2) => {
  UpdatedAmazonConfigurationRefundMode2["Normal"] = "Normal";
  UpdatedAmazonConfigurationRefundMode2["WithMfn"] = "WithMfn";
  UpdatedAmazonConfigurationRefundMode2["DeductAll"] = "DeductAll";
  return UpdatedAmazonConfigurationRefundMode2;
})(UpdatedAmazonConfigurationRefundMode || {});
