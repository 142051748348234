import { TswagClient } from "./../commons/TswagClient";
import { SearchResult } from "./../models/V1/VatRateTypeSettings/Search/SearchResult";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class VATRateTypeSettings {
  /**
   * Creates an instance of VATRateTypeSettings.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof VATRateTypeSettings
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SearchRq_ _searchRq
   * @returns Promise<SearchRq_>
   * @memberof VATRateTypeSettings
   */
  search(pSearchRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VATRateTypeSettings/search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportRq_ _exportRq
   * @returns Promise<ExportRq_>
   * @memberof VATRateTypeSettings
   */
  export(pExportRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VATRateTypeSettings/export",
      "POST",
      "application/json; charset=UTF-8",
      "text/csv, application/json",
      options
    );
    client.addBody({ value: pExportRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CreateVATRateRq_ _createVATRateRq
   * @returns Promise<CreateVATRateRq_>
   * @memberof VATRateTypeSettings
   */
  createVATRate(pCreateVATRateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VATRateTypeSettings/{countryCode}/vatrate",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "countryCode", value: pCreateVATRateRq.countryCode, required: true }
    ]);
    client.addBody({ value: pCreateVATRateRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
