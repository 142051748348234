var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { useState, useEffect, useLoadingReducer } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import {
  DEFAULT_CURRENCY_CODE,
  NEW_ORDER_ID
} from "./OrderModels";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import { notifyError, notifyErrorWithWarning, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { downloadPDFIcon, editIcon, removeIcon, templateTitle } from "GlobalShared/templates/commons";
import { SERVICE_DESCRIPTOR_METAS } from "./OrderHelpers";
import { useOrderSummary } from "./order-summary";
import { generatePDFContract } from "./contract/pdf-generator";
import { formatDate, formatDateTime } from "GlobalShared/helpers/dateHelper";
import { ifDefined } from "lit-html/directives/if-defined";
import { useOrderServices } from "./services/order-services";
import { PRICE_CONFIG_MOCK } from "./services/OrderServiceModels";
import { priceBreakdownTemplate } from "./order-price-breakdown";
import { createPriceConfig, getFeeSummary, getFeeSummaryByOrder, getVatPrice } from "./services/order-services-utils";
import { mapToCreatedServices } from "./services/CreateServiceModels";
import { getCompanyName } from "GlobalShared/helpers/servicesHelper";
import { useConfirmModal } from "../modals/commonModals";
import { ONLY_PDF_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
import { classMap } from "lit-html/directives/class-map";
const DEV_MODE = ClientContext.FeatureFlags.DevMode;
const SERVICES_PROGRESS_ITEMS = [i18next.t("Service Selection"), i18next.t("Additional Services"), i18next.t("Global Services")];
const SERVICE_STEPS = {
  SELECTOR: 0,
  ADDITIONAL: 1,
  GLOBAL: 2
};
const servicesWithValidity = [
  "IossComplianceTier0",
  "IossComplianceTier1",
  "IossComplianceTier2",
  "IossComplianceTier3",
  "VatCompliance",
  "VatMonthlyCompliance",
  "VatRetrospectiveReturn",
  "VatIntrastatIn",
  "VatIntrastatOut"
];
export const useOrderDetails = (props) => {
  const calcSum = () => {
    if (currentOrder.Payments.every((p) => p.Type === "DesuclaPayment")) {
      return {
        SubTotal: { Currency: currentOrder.SubTotal.Currency, Value: currentOrder.SubTotal.Value },
        Vat: { Currency: currentOrder.Vat.Currency, Value: currentOrder.Vat.Value },
        Total: { Currency: currentOrder.Total.Currency, Value: currentOrder.Total.Value }
      };
    }
    let subTotal = currentOrder.SubTotal.Value;
    currentUpdatedServices.forEach((cus) => {
      subTotal += (cus.IsCancelled ? 0 : cus.Price.Value) - cus.OrigPrice.Value;
    });
    const vat = getVatPrice(subTotal, currentCompanyServices.EstablishmentCountryCode, props.euCountries, currentCompanyServices.IsVatNumberValid);
    return {
      SubTotal: { Currency: currentOrder.SubTotal.Currency, Value: subTotal },
      Vat: { Currency: currentOrder.SubTotal.Currency, Value: vat },
      Total: { Currency: currentOrder.SubTotal.Currency, Value: subTotal + vat }
    };
  };
  const reset = () => {
    setCurrentStep(0);
  };
  const createOrder = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        setCreateOrderInProgress(true);
        const result = yield props.createOrder(currentCompanyServices == null ? void 0 : currentCompanyServices.Id, mapToCreatedServices(props.isAdmin, /* @__PURE__ */ new Date(), priceConfig, servicesHook.state));
        if (result) {
          props.navigate();
          notifySuccess("Order successfully saved");
        }
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      () => {
        setCreateOrderInProgress(false);
      }
    );
  });
  const getDescriptorMetaByCode = (code) => {
    return SERVICE_DESCRIPTOR_METAS.find((desc) => code === desc.Code);
  };
  const toSelectItem = (clientItem) => {
    return {
      value: clientItem.id.toString(),
      label: `${clientItem.name} (${clientItem.id.toString()})`
    };
  };
  const loadCompanyServices = (companyId) => __async(void 0, null, function* () {
    try {
      dispatchLoading("inc");
      const company = yield props.getCompanyServices(companyId);
      setCurrentCompanyServices(company);
    } finally {
      dispatchLoading("dec");
    }
  });
  const hasDraft = (order) => {
    var _a;
    return ((_a = order.DraftServices) == null ? void 0 : _a.length) > 0;
  };
  const loadOrder = () => __async(void 0, null, function* () {
    const o = yield props.getOrder(props.orderId);
    yield props.loadOrders();
    setCurrentOrder(o);
  });
  const canEdit = () => {
    return props.permissionManager.hasPermission("Orders_and_Payments_Modify") && currentOrder.Status === "Confirmed" && (!currentOrder.NeedToGenerateInvoice || currentOrder.BillingDocuments.some((bd) => bd.OrderVersion === currentOrder.Version));
  };
  const payAndConfirm = (payment, orderId) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.payAndConfirm(payment, orderId);
        yield loadOrder();
        setShowPaymentModal(false);
        notifySuccess("Payment was successful");
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const saveDraft = () => __async(void 0, null, function* () {
    const updates = getEffectiveDraftChanges();
    if (updates.length > 0) {
      yield withErrorHandling(
        () => __async(void 0, null, function* () {
          dispatchLoading("inc");
          yield props.updateOrder(currentOrder.Id, updates);
          yield loadOrder();
          setIsInEditMode(false);
          notifySuccess(i18next.t("Draft successfully saved"));
        }),
        (error) => {
          if (error.statusCode === 422) {
            notifyWarning("Invalid state (draft can only be saved if there is a billing document for this version)");
          } else {
            notifyError(error);
          }
        },
        () => {
          dispatchLoading("dec");
        }
      );
    }
  });
  const rejectDraft = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.rejectDraftOrder(currentOrder.Id);
        yield loadOrder();
        notifySuccess(i18next.t("Draft successfully cancelled"));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const approveDraft = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.approveDraftOrder(currentOrder.Id);
        yield loadOrder();
        notifySuccess(i18next.t("Draft successfully approved"));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getUpdatedServiceByCodeAndCountry = (service) => {
    let cus = currentUpdatedServices.find((cus2) => cus2.Code === service.Code && (service.Country === void 0 || service.Country === cus2.Country));
    if (cus === void 0) {
      cus = {
        Code: service.Code,
        Price: service.Price,
        OrigPrice: service.Price,
        Country: service.Country,
        IsCancelled: service.Status === "Cancelled",
        PriceValidationMessage: void 0
      };
    }
    return cus;
  };
  const editOrderItem = (service) => __async(void 0, null, function* () {
    setShowServiceItemModal(true);
    setCurrentUpdatedService(__spreadValues({}, getUpdatedServiceByCodeAndCountry(service)));
  });
  const cancelOrderItem = (service) => __async(void 0, null, function* () {
    const us = getUpdatedServiceByCodeAndCountry(service);
    us.IsCancelled = true;
    us.Price = us.OrigPrice;
    changeUpdatedService(us);
  });
  const getEffectiveDraftChanges = () => {
    return currentUpdatedServices.filter((cus) => {
      const s = currentOrder.Services.find((s2) => s2.Code === cus.Code && s2.Country === cus.Country);
      return cus.PriceValidationMessage === void 0 && (s.Price.Value !== cus.Price.Value || s.Status === "Active" && cus.IsCancelled);
    });
  };
  const changeUpdatedService = (updatedService) => {
    if (currentUpdatedServices.some((cus) => cus.Code === updatedService.Code && (updatedService.Country === void 0 || updatedService.Country === cus.Country))) {
      setCurrentUpdatedServices(
        currentUpdatedServices.map((cus2) => cus2.Code === updatedService.Code && (updatedService.Country === void 0 || updatedService.Country === cus2.Country) ? updatedService : cus2)
      );
    } else {
      setCurrentUpdatedServices(currentUpdatedServices.concat(updatedService));
    }
  };
  const [selectedCompanyItemForNewOrder, setSelectedCompanyItemForNewOrder] = useState(void 0);
  const [clientResults, setClientResults] = useState(void 0);
  const [currentCompanyServices, setCurrentCompanyServices] = useState(void 0);
  const [currentOrder, setCurrentOrder] = useState(void 0);
  const [currentUpdatedServices, setCurrentUpdatedServices] = useState([]);
  const [currentUpdatedService, setCurrentUpdatedService] = useState(void 0);
  const [currentPayment, setCurrentPayment] = useState(void 0);
  const [loading, dispatchLoading] = useLoadingReducer();
  const [loadingServices, dispatchLoadingServices] = useLoadingReducer();
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showUploadPaymentProofModal, setShowUploadPaymentProofModal] = useState(false);
  const [showServiceItemModal, setShowServiceItemModal] = useState(false);
  const [priceConfig, setPriceConfig] = useState(void 0);
  const [feeSummary, setFeeSummary] = useState(void 0);
  const [currentStep, setCurrentStep] = useState(0);
  const [createOrderInProgress, setCreateOrderInProgress] = useState(false);
  const confirmModal = useConfirmModal();
  const servicesHook = useOrderServices({
    devMode: DEV_MODE,
    isAdmin: props.isAdmin,
    isSignup: false,
    countryOfEstablishment: currentCompanyServices == null ? void 0 : currentCompanyServices.CompanyDetails.countryOfEstablishment,
    servicesFlowType: "AddServices",
    euCountries: props.euCountries,
    worldCountries: props.worldCountries,
    serviceCountries: props.serviceCountries,
    priceConfig,
    priceBreakdownTemplate: () => priceBreakdownTemplate(feeSummary),
    currentCompanyServices,
    loadingServices
  });
  const orderSummaryHook = useOrderSummary({
    isAdmin: props.isAdmin,
    priceBreakdownTemplate: () => priceBreakdownTemplate(feeSummary),
    isParentValid: true,
    order: currentOrder,
    hasContractReference: currentOrder == null ? void 0 : currentOrder.HasContractReference,
    isSignup: false,
    companyId: currentOrder == null ? void 0 : currentOrder.CompanyId,
    generateContractPdf: () => {
      var _a;
      const pdfModel = {
        partnerName: getCompanyName(
          currentCompanyServices.CompanyDetails.legalStatus,
          currentCompanyServices.CompanyDetails.companyName,
          currentCompanyServices.CompanyDetails.contactFirstName,
          currentCompanyServices.CompanyDetails.contactLastName
        ),
        legalStatus: currentCompanyServices.CompanyDetails.legalStatus,
        addressLine: `${currentCompanyServices.CompanyDetails.addressStreet} ${currentCompanyServices.CompanyDetails.addressStreetNo}, ${currentCompanyServices.CompanyDetails.addressZip} ${currentCompanyServices.CompanyDetails.addressCity} ${props.englishWorldCountries.find((wc) => wc.Code === currentCompanyServices.CompanyDetails.countryOfEstablishment).Name}`,
        summary: __spreadProps(__spreadValues({}, feeSummary), {
          countries: feeSummary.countries.map((sc) => __spreadProps(__spreadValues({}, sc), {
            label: props.englishWorldCountries.find((wc) => wc.Code === sc.countryCode).Name
          }))
        }),
        contractDate: /* @__PURE__ */ new Date(),
        iossTier: (_a = servicesHook.state.iossSelection) == null ? void 0 : _a.tier,
        vatNumber: currentCompanyServices.CompanyDetails.vatNumber ? currentCompanyServices.CompanyDetails.countryOfEstablishment + currentCompanyServices.CompanyDetails.vatNumber : "",
        kpmgReference: currentOrder.ContractId,
        hasVatService: servicesHook.state.vatCountrySelection.length > 0,
        clientSource: currentCompanyServices.ClientSource
      };
      return generatePDFContract(pdfModel);
    },
    getPaymentInfo: props.getPaymentInfo,
    payAndConfirm,
    uploadContract: props.uploadContract,
    downloadContract: (orderId) => {
      return props.downloadContract(orderId, currentOrder.CompanyId);
    }
  });
  const init = () => __async(void 0, null, function* () {
    if (!props.isAdmin) {
      yield loadCompanyServices(void 0);
    }
  });
  useEffect(init, []);
  useEffect(() => __async(void 0, null, function* () {
    if (selectedCompanyItemForNewOrder !== void 0) {
      yield props.reValidateCompanyVatNumber(selectedCompanyItemForNewOrder.id);
      yield loadCompanyServices(selectedCompanyItemForNewOrder.id);
    }
  }), [selectedCompanyItemForNewOrder]);
  useEffect(() => __async(void 0, null, function* () {
    setIsInEditMode(false);
    if (props.orderId !== void 0) {
      if (props.orderId !== NEW_ORDER_ID) {
        yield withErrorHandling(
          () => __async(void 0, null, function* () {
            dispatchLoading("inc");
            const o = yield props.getOrder(props.orderId);
            if (props.isAdmin && o.CompanyId !== void 0) {
              yield loadCompanyServices(o.CompanyId);
            }
            setCurrentOrder(o);
          }),
          (error) => {
            if (error.statusCode !== 404) {
              notifyError(error);
            }
          },
          () => {
            dispatchLoading("dec");
          }
        );
      } else {
        setSelectedCompanyItemForNewOrder(void 0);
        setCurrentCompanyServices(void 0);
      }
    }
  }), [props.orderId]);
  useEffect(() => __async(void 0, null, function* () {
    var _a;
    if (currentOrder) {
      if (currentOrder.DraftServices) {
        setCurrentUpdatedServices(
          currentOrder.DraftServices.map((ds) => {
            const s = currentOrder.Services.find((cus2) => cus2.Code === ds.Code);
            const us = {
              Code: ds.Code,
              Price: ds.Price,
              OrigPrice: s.Price,
              Country: ds.Country,
              IsCancelled: ds.Status === "Cancelled",
              PriceValidationMessage: void 0
            };
            return us;
          })
        );
      }
      const tempFeeSummary = getFeeSummaryByOrder(
        currentCompanyServices == null ? void 0 : currentCompanyServices.EstablishmentCountryCode,
        (_a = currentCompanyServices == null ? void 0 : currentCompanyServices.IsVatNumberValid) != null ? _a : false,
        currentOrder,
        props.euCountries,
        props.serviceCountries
      );
      setFeeSummary(tempFeeSummary);
    }
  }), [currentOrder]);
  useEffect(() => __async(void 0, null, function* () {
    if (!props.isAdmin && (currentCompanyServices == null ? void 0 : currentCompanyServices.Id) && servicesHook.state.vatCountrySelection !== void 0 || props.isAdmin && props.permissionManager.hasPermission("Orders_and_Payments_Modify")) {
      if (DEV_MODE && PRICE_CONFIG_MOCK) {
        setPriceConfig(PRICE_CONFIG_MOCK);
      } else {
        dispatchLoadingServices("inc");
        try {
          const availableServices = yield props.getAvailableServices(
            currentCompanyServices == null ? void 0 : currentCompanyServices.Id,
            DEFAULT_CURRENCY_CODE,
            servicesHook.state.vatCountrySelection,
            servicesHook.state.iossSelection
          );
          setPriceConfig(createPriceConfig(availableServices));
        } finally {
          dispatchLoadingServices("dec");
        }
      }
    }
  }), [servicesHook.state.iossSelection, servicesHook.state.vatCountrySelection]);
  useEffect(() => {
    var _a;
    if (props.orderId === NEW_ORDER_ID && priceConfig && currentCompanyServices && servicesHook.state && props.euCountries && props.serviceCountries) {
      const tempFeeSummary = getFeeSummary(
        props.isAdmin,
        /* @__PURE__ */ new Date(),
        priceConfig,
        currentCompanyServices == null ? void 0 : currentCompanyServices.EstablishmentCountryCode,
        (_a = currentCompanyServices == null ? void 0 : currentCompanyServices.IsVatNumberValid) != null ? _a : false,
        servicesHook.state,
        props.euCountries,
        props.serviceCountries
      );
      setFeeSummary(tempFeeSummary);
    }
  }, [priceConfig, currentCompanyServices, servicesHook.state, props.euCountries, props.serviceCountries]);
  useEffect(() => {
    if (priceConfig && currentCompanyServices && servicesHook.state && props.euCountries && props.serviceCountries) {
      reset();
    }
  }, [currentCompanyServices]);
  const htmlPrice = (price) => {
    return html`<div class="whitespace-no-wrap">${price.Currency === "EUR" ? html`&euro; ` : ""}${price.Value}${price.Currency !== "EUR" ? ` ${price.Currency}` : ""}</div>`;
  };
  const htmlNewOrder = () => {
    return html`
			<div>
				<div class="grid md:grid-cols-2 gap-8 mt-4">
					<dc-select
						.label=${"Company"}
						.dataSource=${(text) => __async(void 0, null, function* () {
      if (text === void 0) {
        return selectedCompanyItemForNewOrder ? [toSelectItem(selectedCompanyItemForNewOrder)] : i18next.t("Type at least 2 characters");
      } else if (text.length < 2) {
        return i18next.t("Type at least 2 characters");
      } else {
        const result = yield props.searchClients(text);
        if (result.length > 0) {
          setClientResults(result);
          return result.map(toSelectItem);
        } else {
          return i18next.t("No result found");
        }
      }
    })}
						.filterable=${true}
						.debounceMs=${150}
						.selectedValues=${selectedCompanyItemForNewOrder == null ? void 0 : selectedCompanyItemForNewOrder.id}
						@change=${(e) => {
      setSelectedCompanyItemForNewOrder(clientResults.find((cr) => cr.id === parseInt(e.detail.selectedValue)));
    }}
					></dc-select>
				</div>
				${currentCompanyServices ? html`<div class="flex justify-around w-full my-4">
				<dc-step-progressbar
					.items=${SERVICES_PROGRESS_ITEMS}
					.activeIndex=${currentStep}
					@click=${(e) => {
      if (e.detail.step < currentStep) {
        setCurrentStep(e.detail.step);
      }
    }}
				></dc-step-progressbar>
			</div>
			${currentStep === 0 ? servicesHook.htmlMainSelector() : ""}
			${currentStep === 1 ? servicesHook.htmlAdditionalServices() : ""}
			${currentStep === 2 ? servicesHook.htmlGlobalServices() : ""}
			<div class="mt-12 flex w-full justify-between">
				<button
					class="px-4 h-12 border-2 border-brand-primary-light rounded text-brand-primary text-sm bg-white font-semibold ${currentStep === 0 ? "invisible" : ""}"
					@click="${() => {
      if (currentStep === SERVICE_STEPS.GLOBAL) {
        setCurrentStep(SERVICE_STEPS.ADDITIONAL);
      } else if (currentStep === SERVICE_STEPS.ADDITIONAL) {
        setCurrentStep(SERVICE_STEPS.SELECTOR);
      }
    }}}"
				>
					${i18next.t("Previous")}
				</button>
				<button
					class="btn btn-primary h-12"
					?disabled=${currentStep === SERVICE_STEPS.SELECTOR && !servicesHook.isValidMainSelector || currentStep === SERVICE_STEPS.ADDITIONAL && (!servicesHook.isValidMainSelector || !servicesHook.isValidAdditionalServices) || currentStep === SERVICE_STEPS.GLOBAL && !servicesHook.isValid || createOrderInProgress || loadingServices.count > 0}
					@click=${() => __async(void 0, null, function* () {
      if (currentStep === SERVICE_STEPS.SELECTOR) {
        if (servicesHook.isValidMainSelector) {
          setCurrentStep(SERVICE_STEPS.ADDITIONAL);
        }
      } else if (currentStep === SERVICE_STEPS.ADDITIONAL) {
        if (servicesHook.isValidMainSelector && servicesHook.isValidAdditionalServices) {
          const hasRestart = servicesHook.state.vatServices.some((v) => v.restartCompliance);
          if (!hasRestart || (yield confirmModal.confirm(
            i18next.t(
              "order-compliance-restart-warning",
              "Warning! This will remove any previously saved settings from your account. If you wanted to renew an expiring subscription, please select NO and select 'Renew for one year' instead."
            )
          ))) {
            setCurrentStep(SERVICE_STEPS.GLOBAL);
          }
        }
      } else if (currentStep === SERVICE_STEPS.GLOBAL) {
        if (servicesHook.isValid) {
          yield createOrder();
        }
      }
    })}
				>
					${currentStep !== SERVICE_STEPS.GLOBAL ? i18next.t("Next") : createOrderInProgress ? `${i18next.t("Saving")}...` : i18next.t("Create Order")}
				</button>
			</div>
		</div>` : ""}
			</div>
		`;
  };
  const htmlUploadPaymentProofModal = () => {
    return html`<dc-modal
			.visible=${showUploadPaymentProofModal}
			@close=${() => setShowUploadPaymentProofModal(false)}
			.header=${i18next.t("Upload payment proof")}
			.content=${html`<div>
				<dc-fileupload
					.label=${i18next.t("Payment proof")}
					.fileName=${void 0}
					.fileReference=${void 0}
					.validationMessage=${void 0}
					.accept=${ONLY_PDF_ACCEPT_LIST}
					@upload=${(e) => __async(void 0, null, function* () {
      var _a;
      if (((_a = e.detail) == null ? void 0 : _a.files) && e.detail.files.length > 0) {
        yield withErrorHandling(
          () => __async(void 0, null, function* () {
            yield props.uploadBankTransferPaymentProof(props.orderId, currentPayment.Id, e.detail.files[0]);
            yield loadOrder();
            notifySuccess(i18next.t("Payment proof successfully uploaded"));
            setShowUploadPaymentProofModal(false);
          }),
          (error) => {
            notifyErrorWithWarning(error);
          }
        );
      }
    })}
				></dc-fileupload>

				<button class="btn btn-primary mt-4" @click=${() => setShowUploadPaymentProofModal(false)}>Cancel</button>
			</div>`}
		>
		</dc-modal>`;
  };
  const htmlServiceItemModal = () => {
    return html` <dc-modal
			.visible=${showServiceItemModal}
			@close=${() => setShowServiceItemModal(false)}
			.header=${i18next.t("Change item")}
			.content=${(() => {
      if (currentUpdatedService) {
        const descriptorMetaInfo = getDescriptorMetaByCode(currentUpdatedService.Code);
        return html`<div>
						<div class="flex space-x-4 items-baseline">
							<div>${descriptorMetaInfo.Label}</div>
							<div>
								<dc-input
									.value=${currentUpdatedService.Price.Value}
									.validationMessage=${currentUpdatedService.PriceValidationMessage}
									@change=${(e) => {
          const tempNum = toSafeNumber(e.detail.value);
          if (tempNum === void 0) {
            setCurrentUpdatedService(__spreadProps(__spreadValues({}, currentUpdatedService), {
              PriceValidationMessage: i18next.t("Required")
            }));
          } else if (tempNum > currentUpdatedService.OrigPrice.Value) {
            setCurrentUpdatedService(__spreadProps(__spreadValues({}, currentUpdatedService), {
              PriceValidationMessage: i18next.t("Price must be lower")
            }));
          } else {
            setCurrentUpdatedService(__spreadProps(__spreadValues({}, currentUpdatedService), {
              Price: {
                Value: tempNum,
                Currency: currentUpdatedService.Price.Currency
              }
            }));
          }
        }}
								></dc-input>
							</div>
							<div>${currentUpdatedService.Price.Currency}</div>
						</div>
						<div class="flex space-x-4 mt-6">
							<button
								class="btn btn-primary"
								@click=${() => {
          setShowServiceItemModal(false);
        }}
							>
								Cancel
							</button>
							<button
								class="btn btn-primary"
								@click=${() => {
          if (currentUpdatedService.PriceValidationMessage === void 0) {
            changeUpdatedService(__spreadProps(__spreadValues({}, currentUpdatedService), { IsCancelled: false }));
            setShowServiceItemModal(false);
          }
        }}
							>
								Save
							</button>
						</div>
					</div>`;
      } else {
        return "";
      }
    })()}
		>
		</dc-modal>`;
  };
  const htmlPaymentModal = () => {
    return html` <dc-modal .visible=${showPaymentModal} @close=${() => setShowPaymentModal(false)} .header=${i18next.t("Pay order")} .content=${orderSummaryHook.mainTemplate()}> </dc-modal>`;
  };
  const htmlValidity = (service) => {
    var _a;
    const originalService = currentOrder.Services.find((s) => s.Country === service.Country && s.Code === "VatCompliance");
    const endDate = (_a = service.EndDate) != null ? _a : originalService == null ? void 0 : originalService.EndDate;
    return servicesWithValidity.includes(service.Code) ? endDate ? html`<span>${formatDate(service.StartDate)} - ${formatDate(endDate)}</span> ` : html`<span>${formatDate(service.StartDate)}</span> ` : "";
  };
  const htmlServiceLine = (service) => {
    const descriptorMetaInfo = getDescriptorMetaByCode(service.Code);
    const updatedService = getUpdatedServiceByCodeAndCountry(service);
    if (descriptorMetaInfo) {
      return html`<div class="flex justify-between text-sm text-gray-900 m-4">
				<div class="px-6 py-2 flex space-x-4">
					<div class="w-64">${descriptorMetaInfo.Label} ${descriptorMetaInfo.Tooltip !== void 0 ? html`<dc-tooltip .label=${descriptorMetaInfo.Tooltip}></dc-tooltip>` : ""}</div>
					${updatedService ? html`<div class="flex space-x-4">
								${updatedService.Price.Value !== updatedService.OrigPrice.Value ? html`<div class="flex space-x-4">
											<div class="line-through">${htmlPrice(updatedService.OrigPrice)}</div>
											<div>${htmlPrice(updatedService.Price)}</div>
									  </div>` : htmlPrice(service.Price)}

								<div>${updatedService.IsCancelled ? i18next.t("Cancelled") : ""}</div>
						  </div>` : htmlPrice(service.Price)}
					${htmlValidity(service)}
				</div>

				<div class="h-8">
					${isInEditMode && !updatedService.IsCancelled ? html`<div class="flex space-x-4">
								<div>
									<span class="edit-icon-container mr-4" @click=${() => editOrderItem(service)}> ${editIcon()} </span>
								</div>
								<div>
									<span class="cursor-pointer remove-icon-container" @click=${() => cancelOrderItem(service)}> ${removeIcon()} </span>
								</div>
						  </div>` : ""}
				</div>
			</div>`;
    } else {
      return "";
    }
  };
  const htmlOrderServices = () => {
    var _a;
    const { SubTotal: subTotal, Vat: vat, Total: total } = calcSum();
    return html`<div class="mt-4 lg:w-1/2">
			<div class="flex space-x-4">
				<div class="font-bold flex items-center">
					Services ${currentOrder.Version ? html` (v${currentOrder.Version})` : ""} ${props.isAdmin && hasDraft(currentOrder) ? html` - DRAFT` : ""}
				</div>

				${props.isAdmin ? html`<div class="flex space-x-4">
							${!hasDraft(currentOrder) ? html`${isInEditMode ? html` <button
													class="btn btn-primary"
													@click=${() => __async(void 0, null, function* () {
      setCurrentUpdatedServices([]);
      setIsInEditMode(false);
    })}
												>
													${i18next.t("Cancel")}
												</button>
												${getEffectiveDraftChanges().length > 0 ? html`<button class="btn btn-primary" @click=${saveDraft}>${i18next.t("Save")}</button>` : ""}` : html`<div title=${ifDefined(canEdit() ? void 0 : 'Only confirmed and "invoiced" orders can be edited')}>
												<button class="btn btn-primary" ?disabled=${!canEdit()} @click=${() => __async(void 0, null, function* () {
      return setIsInEditMode(true);
    })}>${i18next.t("Edit")}</button>
										  </div>`}` : ""}
							<div class="flex space-x-4">
								<div class="flex space-x-4">
									${currentOrder.Status !== "Abandoned" && currentOrder.Status !== "Cancelled" && (props.isAdmin || !props.isAdmin && (currentOrder.Status === "Pending" || currentOrder.Status === "Confirmed" && currentOrder.PaymentStatus !== "Approved")) ? html`<button
												class="btn btn-primary"
												?disabled=${!props.permissionManager.hasPermission("Orders_and_Payments_Modify")}
												@click=${() => __async(void 0, null, function* () {
      yield withErrorHandling(
        () => __async(void 0, null, function* () {
          yield props.cancelOrder(currentOrder.Id);
          window.location.reload();
        }),
        (error) => {
          if (error.statusCode === 409) {
            notifyWarning(i18next.t("Invalid state, please reload order"));
          } else {
            notifyError(error);
          }
        }
      );
    })}
										  >
												${i18next.t("Delete all services")}
										  </button>` : ""}
								</div>
							</div>
							${hasDraft(currentOrder) && !isInEditMode && props.permissionManager.hasPermission("Orders_and_Payments_Modify") ? html`<button class="btn btn-primary" @click=${rejectDraft}>${i18next.t("Cancel draft")}</button
										><button class="btn btn-primary" @click=${approveDraft}>${i18next.t("Approve draft")}</button>` : ""}
					  </div>` : ""}
			</div>
			<div class="flex flex-col">
				<div class="sm:-mx-6 lg:-mx-8">
					<div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
						<div class="overflow-hidden">
							<table class="min-w-full">
								<thead class="bg-white border-b">
									<tr>
										<th scope="col" class="text-base font-semibold text-gray-900 px-6 py-4 text-left">${i18next.t("Service detail")}</th>
									</tr>
								</thead>

								${[...new Set(currentOrder.Services.filter((s) => s.Type === "CountryBasedSubscription").map((s) => s.Country))].map(
      (country) => {
        var _a2, _b;
        return html`<tbody>
											<tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
												<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
													<div class="font-semibold">${(_b = (_a2 = props.serviceCountries) == null ? void 0 : _a2.find((sc) => sc.Code === country)) == null ? void 0 : _b.Name}:</div>
													${currentOrder.Services.filter((s) => s.Country === country).map(
          (s) => html`
														${htmlServiceLine(s)}
													</td>`
        )}
												</td>
											</tr>
										</tbody>`;
      }
    )}
								${currentOrder.Services.filter((s) => s.Type !== "CountryBasedSubscription").map(htmlServiceLine)}
							</table>
						</div>
					</div>
				</div>
			</div>

			<div class="text-left content-center">
				<div class="grid grid-cols-3 font-bold justify-between content-center">
					<div>${i18next.t("Subtotal")}</div>
					<div class="pl-6">${htmlPrice(subTotal)}</div>
				</div>
				<div class="grid grid-cols-3 font-bold justify-between content-center">
					<div>${i18next.t("Tax")}</div>
					<div class="pl-6">${htmlPrice(vat)}</div>
				</div>
			</div>
			<hr />
			<div class="grid grid-cols-3 font-bold text-xl justify-between content-center">
				<div>${i18next.t("Total")}</div>
				<div class="pl-6">${htmlPrice(total)}</div>
			</div>

			${!props.isAdmin && ((_a = currentOrder.Payments) == null ? void 0 : _a.length) === 0 ? html`<button
						class="btn btn-primary ml-4 mt-12"
						@click=${() => __async(void 0, null, function* () {
      orderSummaryHook.resetSummary();
      setShowPaymentModal(true);
    })}
				  >
						${i18next.t("Pay")}
				  </button>` : ""}
			${htmlServiceItemModal()}
		</div>`;
  };
  const htmlPaymentActions = (p) => {
    if (p.Type === "BankTransferPayment" && !p.IsRefund) {
      return html`<div class="flex space-x-4">
				<button
					class="btn btn-primary whitespace-no-wrap"
					?disabled=${!props.permissionManager.hasPermission("Orders_and_Payments_Modify") || isInEditMode || currentOrder.Status !== "Confirmed" || p.HasPaymentProof}
					@click=${() => {
        setCurrentPayment(p);
        setShowUploadPaymentProofModal(true);
      }}
				>
					${i18next.t("Upload proof")}
				</button>
				${p.HasPaymentProof ? html`<button class="btn btn-primary whitespace-no-wrap" @click=${() => props.downloadBankTransferPaymentProof(props.orderId, p.Id)}>${i18next.t("Download proof")}</button>` : ""}
				${props.isAdmin ? html`<button
							class="btn btn-primary"
							?disabled=${!props.permissionManager.hasPermission("Orders_and_Payments_Modify") || p.Status === "Approved" || currentOrder.Status === "Abandoned" || currentOrder.Status === "Cancelled" || isInEditMode}
							@click=${() => __async(void 0, null, function* () {
        yield props.approveBankTransferPayment({
          paymentId: p.Id,
          orderId: props.orderId,
          collected: p.Quoted,
          reference: ""
        });
        yield loadOrder();
      })}
					  >
							${i18next.t("Approve")}
					  </button>` : ""}
			</div>`;
    } else {
      return "";
    }
  };
  const htmlOrder = () => {
    var _a;
    return html`<div class="mt-4">
			<div>
				<div class="grid grid-cols-2 space-y-2 md:space-y-0 md:grid-cols-5 lg:grid-cols-6">
					${props.isAdmin ? html`
								<div class="text-base py-4 text-left">
									<div class="font-semibold mb-2">${i18next.t("Client id")}:</div>
									${currentOrder.CompanyId}
								</div>
								<div class="text-base py-4 text-left">
									<div class="font-semibold mb-2">${i18next.t("Client name")}:</div>
									${currentOrder.CompanyName}
								</div>
								<div class="text-base py-4 text-left">
									<div class="font-semibold mb-2">${i18next.t("Source")}:</div>
									${currentOrder.ClientSource}
								</div>
						  ` : ""}

					<div class="text-base  py-4 text-left">
						<div class="font-semibold mb-2">${i18next.t("Created date")}:</div>
						${formatDateTime(currentOrder.CreatedAt)}
					</div>
					<div class="text-base  py-4 text-left">
						<div class="font-semibold mb-2">${i18next.t("Status")}:</div>
						${currentOrder.Status}
					</div>
					<div class="text-base  py-4 text-left">
						<div class="font-semibold mb-2">${i18next.t("Contract id")}:</div>
						${currentOrder.ContractId}
						<button
							?disabled=${!currentOrder.HasContractReference}
							class=${classMap({
      "download-icon-container": true,
      "not-allowed": !currentOrder.HasContractReference
    })}
							@click=${() => props.downloadContract(currentOrder.Id, currentOrder.CompanyId)}
						>
							${downloadPDFIcon(!currentOrder.HasContractReference ? i18next.t("Order does not confirmed yet.") : void 0)}
						</button>
					</div>
				</div>
			</div>
			<div class="mt-4">
				<div class="font-bold mb-2">${i18next.t("Payments")}</div>
				${htmlUploadPaymentProofModal()}
				<div>
					${currentOrder.Payments.sort((a, b) => a.CreatedAt < b.CreatedAt ? -1 : 1).map((p) => {
      return html`
							<div class="grid grid-cols-1 space-y-2 md:space-y-0 md:grid-cols-5 lg:grid-cols-6 rounded-md items-center p-2 ${p.Quoted.Value > 0 ? "bg-green-100" : "bg-red-100"}">
								<div>${p.Type}</div>
								<div>${htmlPrice(p.Quoted)}</div>
								<div>${p.Status}</div>
								<div class="text-sm">${p.MerchantReference}</div>
								<div class="grid-cols-2">${htmlPaymentActions(p)}</div>
							</div>
						`;
    })}
				</div>
				<div class="mt-4">
					<div class="font-bold mb-2">${i18next.t("Billing documents")}</div>
					${((_a = currentOrder.BillingDocuments) == null ? void 0 : _a.length) > 0 ? html`<div class="flex space-x-4 flex-wrap">
								${currentOrder.BillingDocuments.map((billingDoc) => {
      return html`<div>
										<a
											class="link"
											href="javascript:void()"
											@click=${() => __async(void 0, null, function* () {
        yield withErrorHandling(
          () => __async(void 0, null, function* () {
            yield props.downloadBillingDocument(currentOrder.Id, billingDoc.Id);
          }),
          (error) => {
            notifyError(error);
          }
        );
      })}
											>${billingDoc.Name}</a
										>
										(v${billingDoc.OrderVersion}) ${billingDoc.ReceivedAt ? html` (${formatDateTime(billingDoc.ReceivedAt)})` : ""}
									</div>`;
    })}
						  </div>` : ""}
				</div>
				${htmlOrderServices()} ${htmlPaymentModal()}
			</div>
		</div>`;
  };
  const mainTemplate = () => {
    return html`<div class="xl:mx-auto md:mx-8">
			<div>${templateTitle(i18next.t(`Order Details`))} ${confirmModal.mainTemplate()}</div>
			<div class="mt-4">
				${props.orderId === NEW_ORDER_ID ? htmlNewOrder() : ""}
				${props.orderId !== NEW_ORDER_ID ? loading.count > 0 ? `${i18next.t("Loading")}...` : currentOrder && currentCompanyServices ? htmlOrder() : html`${i18next.t("Order not found")} ${props.orderId}` : ""}
			</div>
		</div>`;
  };
  return { mainTemplate };
};
