export var ProductTypeAnswerValue = /* @__PURE__ */ ((ProductTypeAnswerValue2) => {
  ProductTypeAnswerValue2["AutomotiveIndustrial"] = "AutomotiveIndustrial";
  ProductTypeAnswerValue2["BeautyHealth"] = "BeautyHealth";
  ProductTypeAnswerValue2["BooksAudio"] = "BooksAudio";
  ProductTypeAnswerValue2["CollectiblesArtStationary"] = "CollectiblesArtStationary";
  ProductTypeAnswerValue2["ElectronicsComputerOffice"] = "ElectronicsComputerOffice";
  ProductTypeAnswerValue2["FashionAccessoriesJewellery"] = "FashionAccessoriesJewellery";
  ProductTypeAnswerValue2["FoodGrocery"] = "FoodGrocery";
  ProductTypeAnswerValue2["HomeGardenTools"] = "HomeGardenTools";
  ProductTypeAnswerValue2["KitchenDining"] = "KitchenDining";
  ProductTypeAnswerValue2["MusicMoviesGames"] = "MusicMoviesGames";
  ProductTypeAnswerValue2["SportingGoodsOutdoors"] = "SportingGoodsOutdoors";
  ProductTypeAnswerValue2["ToysKidsBaby"] = "ToysKidsBaby";
  return ProductTypeAnswerValue2;
})(ProductTypeAnswerValue || {});
