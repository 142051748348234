var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
export const useCommentModal = (props) => {
  const [comment, setComment] = useState("");
  const [showModal, setShowModal] = useState(false);
  const show = () => {
    setShowModal(true);
  };
  useEffect(() => {
    setComment("");
  }, [showModal]);
  const template = () => {
    if (!showModal) {
      return html``;
    }
    return html`
			<div>
				<dc-modal
					.visible=${showModal}
					@close=${() => setShowModal(false)}
					.header=${"Add comment"}
					.content=${html`<div>
						<textarea
							class="form-textarea h-48"
							@change=${function() {
      setComment(this.value);
    }}
						>
${comment}</textarea
						>
						<div>
							<button
								class="btn btn-primary"
								@click=${() => __async(void 0, null, function* () {
      yield props.save(comment);
      setShowModal(false);
    })}
							>
								Save</button
							><button class="btn btn-primary ml-4" @click=${() => setShowModal(false)}>Cancel</button>
						</div>
					</div>`}
				>
				</dc-modal>
			</div>
		`;
  };
  return { show, template };
};
