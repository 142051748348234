import { default as moment } from "moment";
import { DEFAULT_DATE_FORMAT } from "GlobalShared/common/Constants";
const DefaultDateTimeFormat = "YYYY-MM-DD HH:mm";
const DefaultTimestampFormat = "YYYY-MM-DD HH:mm:ss.SSS";
export const MIN_DATE = new Date(1970, 0, 1);
export const MAX_DATE = new Date(2100, 0, 1);
export const parseDate = (dateStr) => {
  return moment.utc(dateStr, DEFAULT_DATE_FORMAT);
};
export const parseDateTimeUTC = (dateStr) => {
  return moment.utc(dateStr, DefaultDateTimeFormat);
};
export const parseTimestampUTC = (dateStr) => {
  return moment.utc(dateStr, DefaultTimestampFormat);
};
export const formatDate = (date) => {
  if (!date) {
    return "undefined";
  } else if (date instanceof Date) {
    return moment(date).format(DEFAULT_DATE_FORMAT);
  } else {
    return date.format(DEFAULT_DATE_FORMAT);
  }
};
export const formatDateTime = (date) => {
  if (!date) {
    return "undefined";
  } else if (date instanceof Date) {
    return moment(date).format(DefaultDateTimeFormat);
  } else {
    return date.format(DefaultDateTimeFormat);
  }
};
export const formatTimestamp = (date) => {
  if (!date) {
    return "undefined";
  } else if (date instanceof Date) {
    return moment(date).format(DefaultTimestampFormat);
  } else {
    return date.format(DefaultTimestampFormat);
  }
};
export const formatDateTimeUTC = (date) => {
  if (!date) {
    return "undefined";
  } else {
    return moment.utc(date).format(DefaultDateTimeFormat);
  }
};
export const formatTimestampUTC = (date) => {
  if (!date) {
    return "undefined";
  } else {
    return moment.utc(date).format(DefaultTimestampFormat);
  }
};
export const createTodayTime = (hour, minute, second) => {
  const nowFrom = moment();
  nowFrom.set({ hour, minute, second, millisecond: 0 });
  return nowFrom.toDate();
};
export const getDay = (date) => {
  if (date === void 0) {
    return void 0;
  }
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};
export const getFirstDayOfMonth = (date) => {
  if (date === void 0) {
    return void 0;
  }
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1, 12, 12));
};
export const getFirstDayOfNextMonth = (date) => {
  if (date === void 0) {
    return void 0;
  }
  return new Date(Date.UTC(date.getFullYear() + (date.getMonth() + 1) / 12, (date.getMonth() + 1) % 12, 1, 12, 12));
};
export const getLastDayOfMonth = (date) => {
  if (date === void 0) {
    return void 0;
  }
  return getLastDayOfPreviousMonth(getFirstDayOfNextMonth(date));
};
export const getLastDayOfNextMonth = (date) => {
  if (date === void 0) {
    return void 0;
  }
  return getLastDayOfMonth(getFirstDayOfNextMonth(date));
};
export const getLastDayOfPreviousMonth = (date) => {
  if (date === void 0) {
    return void 0;
  }
  return moment(getFirstDayOfMonth(date)).subtract(1, "days").toDate();
};
export const addMonths = (date, months) => {
  if (date === void 0) {
    return void 0;
  }
  return moment(date).add(months, "months").toDate();
};
export const interpretYearOneAsUndefined = (date) => {
  return date && date.getFullYear() > 1 ? date : void 0;
};
export const diffInMonthForMonthlyCalc = (fromDate, toDate) => {
  if (toDate === void 0 || fromDate === void 0 || fromDate > toDate) {
    return 0;
  }
  const diff = 12 * (toDate.getFullYear() - fromDate.getFullYear()) + toDate.getMonth() - fromDate.getMonth();
  return diff + 1;
};
export const diffInMonths = (startDate, endDate) => {
  return Math.ceil(diffInMonthsPrecise(startDate, endDate));
};
export const diffInMonthsPrecise = (startDate, endDate) => {
  if (startDate === void 0 || startDate === null || endDate === void 0 || endDate === null) {
    return 0;
  }
  const mStartDate = moment([startDate.getFullYear(), startDate.getMonth(), startDate.getDate()]);
  const mTermDate = moment([endDate.getFullYear(), endDate.getMonth(), endDate.getDate()]);
  return mTermDate.diff(mStartDate, "months", true);
};
