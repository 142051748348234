export class CompanyTemplateFilter {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CompanyTemplateFilter
     */
    this.$type = "V1.TemplateMapping.TemplateSearch.CompanyTemplateFilter";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Country = _data["Country"];
      this.Culture = _data["Culture"];
      this.Name = _data["Name"];
      this.TemplateStatus = CompanyTemplateFilterTemplateStatus[_data["TemplateStatus"]];
      this.Trigger = CompanyTemplateFilterTrigger[_data["Trigger"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Country"] = this.Country;
    _data["Culture"] = this.Culture;
    _data["Name"] = this.Name;
    _data["TemplateStatus"] = CompanyTemplateFilterTemplateStatus[this.TemplateStatus];
    _data["Trigger"] = CompanyTemplateFilterTrigger[this.Trigger];
    return _data;
  }
}
export var CompanyTemplateFilterTemplateStatus = /* @__PURE__ */ ((CompanyTemplateFilterTemplateStatus2) => {
  CompanyTemplateFilterTemplateStatus2["Draft"] = "Draft";
  CompanyTemplateFilterTemplateStatus2["Enabled"] = "Enabled";
  CompanyTemplateFilterTemplateStatus2["Disabled"] = "Disabled";
  return CompanyTemplateFilterTemplateStatus2;
})(CompanyTemplateFilterTemplateStatus || {});
export var CompanyTemplateFilterTrigger = /* @__PURE__ */ ((CompanyTemplateFilterTrigger2) => {
  CompanyTemplateFilterTrigger2["OrderCreated"] = "OrderCreated";
  return CompanyTemplateFilterTrigger2;
})(CompanyTemplateFilterTrigger || {});
