import { DateConverter } from "./../../../../converters/DateConverter";
export class Company {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Company
     */
    this.$type = "V1.Company.Search.Company";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.AccountManagerEmail = _data["AccountManagerEmail"];
      this.ClientSource = CompanyClientSource[_data["ClientSource"]];
      this.CompanyName = _data["CompanyName"];
      this.CoordinatorEmail = _data["CoordinatorEmail"];
      this.Id = _data["Id"];
      this.IsSignedUp = _data["IsSignedUp"];
      this.PreparerEmail = _data["PreparerEmail"];
      this.QuestionnaireStatus = CompanyQuestionnaireStatus[_data["QuestionnaireStatus"]];
      this.RegistrationDate = DateConverter.from(_data["RegistrationDate"]);
      this.ReviewerEmail = _data["ReviewerEmail"];
      this.SentinelValidTo = DateConverter.from(_data["SentinelValidTo"]);
      this.Status = CompanyStatus[_data["Status"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["AccountManagerEmail"] = this.AccountManagerEmail;
    _data["ClientSource"] = CompanyClientSource[this.ClientSource];
    _data["CompanyName"] = this.CompanyName;
    _data["CoordinatorEmail"] = this.CoordinatorEmail;
    _data["Id"] = this.Id;
    _data["IsSignedUp"] = this.IsSignedUp;
    _data["PreparerEmail"] = this.PreparerEmail;
    _data["QuestionnaireStatus"] = CompanyQuestionnaireStatus[this.QuestionnaireStatus];
    _data["RegistrationDate"] = DateConverter.toIsoUtcString(this.RegistrationDate);
    _data["ReviewerEmail"] = this.ReviewerEmail;
    _data["SentinelValidTo"] = DateConverter.toUtcDateString(this.SentinelValidTo);
    _data["Status"] = CompanyStatus[this.Status];
    return _data;
  }
}
export var CompanyClientSource = /* @__PURE__ */ ((CompanyClientSource2) => {
  CompanyClientSource2["Amazon"] = "Amazon";
  CompanyClientSource2["KPMG"] = "KPMG";
  CompanyClientSource2["FedEx"] = "FedEx";
  CompanyClientSource2["LaPoste"] = "LaPoste";
  return CompanyClientSource2;
})(CompanyClientSource || {});
export var CompanyQuestionnaireStatus = /* @__PURE__ */ ((CompanyQuestionnaireStatus2) => {
  CompanyQuestionnaireStatus2["Incomplete"] = "Incomplete";
  CompanyQuestionnaireStatus2["Completed"] = "Completed";
  CompanyQuestionnaireStatus2["Approved"] = "Approved";
  CompanyQuestionnaireStatus2["DataVerified"] = "DataVerified";
  return CompanyQuestionnaireStatus2;
})(CompanyQuestionnaireStatus || {});
export var CompanyStatus = /* @__PURE__ */ ((CompanyStatus2) => {
  CompanyStatus2["Active"] = "Active";
  CompanyStatus2["Rejected"] = "Rejected";
  CompanyStatus2["Inactive"] = "Inactive";
  return CompanyStatus2;
})(CompanyStatus || {});
