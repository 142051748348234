import { Money } from "./../../Common/Money";
export class UpdatedService {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof UpdatedService
     */
    this.$type = "V1.Orders.Update.UpdatedService";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Code = UpdatedServiceCode[_data["Code"]];
      this.Country = _data["Country"];
      this.IsCancelled = _data["IsCancelled"];
      if (_data["Price"]) {
        const price_ = new Money();
        this.Price = price_.init(_data["Price"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Code"] = UpdatedServiceCode[this.Code];
    _data["Country"] = this.Country;
    _data["IsCancelled"] = this.IsCancelled;
    _data["Price"] = this.Price ? this.Price.toJSON() : void 0;
    return _data;
  }
}
export var UpdatedServiceCode = /* @__PURE__ */ ((UpdatedServiceCode2) => {
  UpdatedServiceCode2["VatCompliance"] = "VatCompliance";
  UpdatedServiceCode2["VatMonthlyCompliance"] = "VatMonthlyCompliance";
  UpdatedServiceCode2["VatRegistration"] = "VatRegistration";
  UpdatedServiceCode2["VatTakeOver"] = "VatTakeOver";
  UpdatedServiceCode2["VatSpanishBankAccount"] = "VatSpanishBankAccount";
  UpdatedServiceCode2["VatFiscalRepresentative"] = "VatFiscalRepresentative";
  UpdatedServiceCode2["VatFilingAgent"] = "VatFilingAgent";
  UpdatedServiceCode2["VatRetrospectiveReturn"] = "VatRetrospectiveReturn";
  UpdatedServiceCode2["VatIntrastatIn"] = "VatIntrastatIn";
  UpdatedServiceCode2["VatIntrastatOut"] = "VatIntrastatOut";
  UpdatedServiceCode2["VatDeregistration"] = "VatDeregistration";
  UpdatedServiceCode2["VatSelfRevision"] = "VatSelfRevision";
  UpdatedServiceCode2["VatHomeCountryCompliance"] = "VatHomeCountryCompliance";
  UpdatedServiceCode2["IossComplianceTier0"] = "IossComplianceTier0";
  UpdatedServiceCode2["IossComplianceTier1"] = "IossComplianceTier1";
  UpdatedServiceCode2["IossComplianceTier2"] = "IossComplianceTier2";
  UpdatedServiceCode2["IossComplianceTier3"] = "IossComplianceTier3";
  UpdatedServiceCode2["IossRegistration"] = "IossRegistration";
  UpdatedServiceCode2["IossTakeOver"] = "IossTakeOver";
  UpdatedServiceCode2["IossDeregistration"] = "IossDeregistration";
  UpdatedServiceCode2["OssCompliance"] = "OssCompliance";
  UpdatedServiceCode2["OssRegistration"] = "OssRegistration";
  UpdatedServiceCode2["OssTakeOver"] = "OssTakeOver";
  UpdatedServiceCode2["OssDeregistration"] = "OssDeregistration";
  UpdatedServiceCode2["GlobalEoriUk"] = "GlobalEoriUk";
  UpdatedServiceCode2["GlobalEoriEu"] = "GlobalEoriEu";
  UpdatedServiceCode2["GlobalGeneralVatAdvice"] = "GlobalGeneralVatAdvice";
  UpdatedServiceCode2["GlobalSpecialistVatAdvice"] = "GlobalSpecialistVatAdvice";
  UpdatedServiceCode2["GlobalCommunicatingWithTaxAuthorities"] = "GlobalCommunicatingWithTaxAuthorities";
  UpdatedServiceCode2["GlobalChangeCompanyDetail"] = "GlobalChangeCompanyDetail";
  UpdatedServiceCode2["GlobalContactingTaxAuthorities"] = "GlobalContactingTaxAuthorities";
  UpdatedServiceCode2["GlobalTranslation"] = "GlobalTranslation";
  UpdatedServiceCode2["GlobalTaxCertificate"] = "GlobalTaxCertificate";
  UpdatedServiceCode2["GlobalIntrastatThresholdCheck"] = "GlobalIntrastatThresholdCheck";
  UpdatedServiceCode2["GlobalDistanceSaleThresholdCheck"] = "GlobalDistanceSaleThresholdCheck";
  return UpdatedServiceCode2;
})(UpdatedServiceCode || {});
