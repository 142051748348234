import { TswagClient } from "./../commons/TswagClient";
export class DataUpload {
  /**
   * Creates an instance of DataUpload.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof DataUpload
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param UpdateWebRegistrationRequestRq_ _updateWebRegistrationRequestRq
   * @returns Promise<UpdateWebRegistrationRequestRq_>
   * @memberof DataUpload
   */
  updateWebRegistrationRequest(pUpdateWebRegistrationRequestRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/DataUpload/UpdateWebRegistrationRequest",
      "PUT",
      "application/json; charset=UTF-8",
      "application/json; charset=UTF-8",
      options
    );
    client.addBody({ value: pUpdateWebRegistrationRequestRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200 }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
