import {
  CompanyCustomParam,
  ConcatExpression,
  ConditionExpression,
  DateExpression,
  EnumExpression,
  IdentityExpression,
  MappingItem,
  SubstringExpression,
  Term,
  VatRegCustomParam
} from "AdminServices/APIs/self/models/V1/TemplateMapping";
import { formatDate, parseDateTimeUTC } from "GlobalShared/helpers/dateHelper";
import {
  TermOpTypes
} from "./TemplateModels";
import { ConditionExpressionBoolOperator } from "AdminServices/APIs/self/models/V1/TemplateMapping/ConditionExpression";
import { TermDSFieldType, TermTermOperator } from "AdminServices/APIs/self/models/V1/TemplateMapping/Term";
import { DataSourceFieldFieldType } from "AdminServices/APIs/self/models/V1/DocGenerator/DataSourceField";
import { VatRegCustomParamCompanyType, VatRegCustomParamRegType, VatRegCustomParamTrigger, VatRegCustomParamCategory } from "AdminServices/APIs/self/models/V1/TemplateMapping/VatRegCustomParam";
import { CompanyCustomParamTrigger } from "AdminServices/APIs/self/models/V1/TemplateMapping/CompanyCustomParam";
export const getLastPathElement = (path) => {
  if (path === void 0) {
    return void 0;
  }
  if (path.lastIndexOf(".") > -1) {
    return path.substring(path.lastIndexOf(".") + 1);
  } else {
    return path;
  }
};
export const getDSFieldByPath = (node, pathFragment) => {
  return node[pathFragment];
};
export const toMappingItemIdentity = (pdfField, mapping) => {
  return {
    Id: mapping.id,
    Expression: new IdentityExpression({
      FieldPath: mapping.path
    }),
    Type: "IDENTITY",
    PdfFieldName: pdfField.name
  };
};
export const toMappingItemDateFormat = (pdfField, mapping) => {
  return {
    Id: mapping.id,
    Expression: new DateExpression({
      FieldPath: mapping.path,
      Format: mapping.format
    }),
    Type: "DATEFORMAT",
    PdfFieldName: pdfField.name
  };
};
export const toMappingItemSubstring = (pdfField, mapping) => {
  return {
    Id: mapping.id,
    Expression: new SubstringExpression({
      FieldPath: mapping.path,
      From: mapping.from,
      To: mapping.to
    }),
    Type: "SUBSTRING",
    PdfFieldName: pdfField.name
  };
};
export const toMappingItemConcat = (pdfField, mapping) => {
  return {
    Id: mapping.id,
    Expression: new ConcatExpression({
      FieldPaths: mapping.paths,
      Separator: mapping.separator ? mapping.separator : ""
    }),
    Type: "CONCAT",
    PdfFieldName: pdfField.name
  };
};
export const toMappingItemEnum = (pdfField, mapping) => {
  return {
    Id: mapping.id,
    Expression: new EnumExpression({
      FieldPath: mapping.path,
      Pairs: mapping.pairs.reduce((aggr, pair) => {
        aggr[mapping.srcValues.indexOf(pair.src).toString()] = pair.dst;
        return aggr;
      }, {})
    }),
    Type: "ENUM",
    PdfFieldName: pdfField.name,
    EnumSourceValues: mapping.srcValues
  };
};
export const toMappingItemCondition = (pdfField, mapping) => {
  return {
    Id: mapping.id,
    Expression: {
      BoolOperator: ConditionExpressionBoolOperator[mapping.boolOp],
      Terms: mapping.terms.map(
        (termVM) => new Term({
          Not: termVM.not,
          Path: termVM.path,
          DSFieldType: TermDSFieldType[termVM.dsFieldType],
          TermOperator: TermTermOperator[termVM.op],
          NumberValue: termVM.numValue,
          StringValue: termVM.stringValue,
          DateTimeValue: termVM.dateValue
        })
      )
    },
    Type: "CONDITION",
    PdfFieldName: pdfField.name
  };
};
export const toMappingItem = (mappingItemVM) => {
  const mappingItem = new MappingItem({
    Id: mappingItemVM.Id,
    Type: mappingItemVM.Type,
    PdfFieldName: mappingItemVM.PdfFieldName
  });
  let mappingExpression;
  if (mappingItemVM.Type === "CONCAT") {
    mappingExpression = new ConcatExpression(mappingItemVM.Expression);
  } else if (mappingItemVM.Type === "SUBSTRING") {
    mappingExpression = new SubstringExpression(mappingItemVM.Expression);
  } else if (mappingItemVM.Type === "IDENTITY") {
    mappingExpression = new IdentityExpression(mappingItemVM.Expression);
  } else if (mappingItemVM.Type === "DATEFORMAT") {
    mappingExpression = new DateExpression(mappingItemVM.Expression);
  } else if (mappingItemVM.Type === "ENUM") {
    mappingExpression = new EnumExpression(mappingItemVM.Expression);
    mappingItem.EnumSourceValues = mappingItemVM.EnumSourceValues;
  } else if (mappingItemVM.Type === "CONDITION") {
    mappingExpression = new ConditionExpression(mappingItemVM.Expression);
  }
  mappingItem.Expression = mappingExpression;
  return mappingItem;
};
export const fromMappingItem = (mappingItem) => {
  const type = getExpressionTypeByModel(mappingItem.Expression);
  const mappingItemVM = {
    Id: mappingItem.Id,
    Expression: mappingItem.Expression,
    Type: type,
    PdfFieldName: mappingItem.PdfFieldName,
    EnumSourceValues: type === "ENUM" ? mappingItem.EnumSourceValues : void 0
  };
  return mappingItemVM;
};
export const convertToMapping = (mappingItem) => {
  if (mappingItem === void 0) {
    return void 0;
  }
  const mapping = {
    id: mappingItem.Id,
    pdfFieldName: mappingItem.PdfFieldName,
    type: mappingItem.Type
  };
  if (mappingItem.Type === "DATEFORMAT") {
    const expression = mappingItem.Expression;
    const dateFormatMapping = mapping;
    dateFormatMapping.path = expression.FieldPath;
    dateFormatMapping.format = expression.Format;
    return dateFormatMapping;
  } else if (mappingItem.Type === "IDENTITY") {
    const expression = mappingItem.Expression;
    const identityMapping = mapping;
    identityMapping.path = expression.FieldPath;
    return identityMapping;
  } else if (mappingItem.Type === "SUBSTRING") {
    const expression = mappingItem.Expression;
    const substringMapping = mapping;
    substringMapping.path = expression.FieldPath;
    substringMapping.from = expression.From;
    substringMapping.to = expression.To;
    return substringMapping;
  } else if (mappingItem.Type === "CONCAT") {
    const expression = mappingItem.Expression;
    const concatMapping = mapping;
    concatMapping.paths = expression.FieldPaths;
    concatMapping.separator = expression.Separator;
    return concatMapping;
  } else if (mappingItem.Type === "ENUM") {
    const expression = mappingItem.Expression;
    const enumMapping = mapping;
    enumMapping.path = expression.FieldPath;
    enumMapping.pairs = Object.keys(expression.Pairs).map((key) => {
      enumMapping.srcValues = mappingItem.EnumSourceValues;
      return { src: enumMapping.srcValues[Number(key)], dst: expression.Pairs[key] };
    });
    return enumMapping;
  } else if (mappingItem.Type === "CONDITION") {
    const expression = mappingItem.Expression;
    const conditionMapping = mapping;
    conditionMapping.boolOp = expression.BoolOperator;
    conditionMapping.terms = expression.Terms.map(
      (term) => ({
        not: term.Not,
        path: term.Path,
        dsFieldType: term.DSFieldType,
        op: term.TermOperator,
        numValue: term.DSFieldType === TermDSFieldType.Decimal || term.DSFieldType === TermDSFieldType.Int ? term.NumberValue : void 0,
        dateValue: term.DSFieldType === TermDSFieldType.DateTime ? term.DateTimeValue : void 0,
        stringValue: term.DSFieldType === TermDSFieldType.String || TermDSFieldType.Boolean || TermDSFieldType.Enum ? term.StringValue : void 0
      })
    );
    return conditionMapping;
  }
  return mapping;
};
export const textualFormat = (mappingItem) => {
  if (mappingItem === void 0) {
    return "";
  }
  const mapping = convertToMapping(mappingItem);
  if (mapping.type === "DATEFORMAT") {
    const dateFormatMapping = mapping;
    return `DATEFORMAT(${dateFormatMapping.path}, '${dateFormatMapping.format}')`;
  } else if (mapping.type === "IDENTITY") {
    const identityMapping = mapping;
    return `IDENTITY(${identityMapping.path})`;
  } else if (mapping.type === "SUBSTRING") {
    const substringMapping = mapping;
    return `SUBSTRING(${substringMapping.path}, ${substringMapping.from !== void 0 ? substringMapping.from : ""}, ${substringMapping.to !== void 0 ? substringMapping.to : ""})`;
  } else if (mapping.type === "CONCAT") {
    const concatMapping = mapping;
    return `CONCAT ${concatMapping.paths.map((item) => `<span class="template-enum">${item}</span>`).join(concatMapping.separator !== void 0 ? concatMapping.separator : "")}`;
  } else if (mapping.type === "CONDITION") {
    const conditionMapping = mapping;
    return conditionMapping.terms.map(
      (term) => `<span class="template-enum">${term.not ? "NOT " : ""}${term.path} ${term.op && term.dsFieldType !== "Boolean" ? ` <span class="italic">${TermOpTypes.find((tot) => tot.value === term.op).label}</span> ` : ""} ${term.dsFieldType === "DateTime" && term.dateValue !== void 0 ? formatDate(term.dateValue) : ""}${(term.dsFieldType === "Int" || term.dsFieldType === "Decimal") && term.numValue ? term.numValue : ""}${(term.dsFieldType === "String" || term.dsFieldType === "Boolean" || term.dsFieldType === "Enum") && term.stringValue ? term.stringValue : ""}</span>`
    ).join(` ${conditionMapping.boolOp.toUpperCase()} `);
  } else if (mapping.type === "ENUM") {
    const enumMapping = mapping;
    return `ENUM ${enumMapping.path}: <br /> ${enumMapping.pairs.map((pair) => `<span class="template-enum">${pair.src} &roarr; ${pair.dst}</span>`).join("")}`;
  } else {
    return `Unknown type: ${mapping.type}`;
  }
};
export const getCustomModel = (template) => {
  if (template.Type === "MPVatReg") {
    const custom = template.Custom;
    return new VatRegCustomParam({
      Country: custom.Country,
      Trigger: VatRegCustomParamTrigger[custom.Trigger],
      EstablishmentCountries: custom.EstablishmentCountry,
      RegType: VatRegCustomParamRegType[custom.RegType],
      CompanyType: VatRegCustomParamCompanyType[custom.CompanyType],
      Category: VatRegCustomParamCategory[custom.Category]
    });
  } else if (template.Type === "MPCompany") {
    const custom = template.Custom;
    return new CompanyCustomParam({
      Trigger: CompanyCustomParamTrigger[custom.Trigger]
    });
  } else {
    throw new Error("Unknown Custom Trigger");
  }
};
export const getCustomFromModel = (template) => {
  if (template.TemplateType === "MPVatReg") {
    const customModel = template.CustomParam;
    return {
      Country: customModel.Country,
      Trigger: customModel.Trigger,
      EstablishmentCountry: customModel.EstablishmentCountries,
      RegType: customModel.RegType,
      CompanyType: customModel.CompanyType,
      Category: customModel.Category
    };
  } else if (template.TemplateType === "MPCompany") {
    const customModel = template.CustomParam;
    return {
      Trigger: customModel.Trigger
    };
  } else {
    throw new Error("Unknown Custom Trigger");
  }
};
export const getExpressionTypeByModel = (expression) => {
  if (expression instanceof IdentityExpression) {
    return "IDENTITY";
  } else if (expression instanceof SubstringExpression) {
    return "SUBSTRING";
  } else if (expression instanceof DateExpression) {
    return "DATEFORMAT";
  } else if (expression instanceof ConcatExpression) {
    return "CONCAT";
  } else if (expression instanceof EnumExpression) {
    return "ENUM";
  } else if (expression instanceof ConditionExpression) {
    return "CONDITION";
  } else {
    throw new Error("Unknown Expression type");
  }
};
export const mapCompanyDetailDataSource = (fields) => {
  return fields.reduce((aggr, field) => {
    aggr[field.Name] = {
      name: field.Name,
      type: field.FieldType,
      value: field.FieldType === DataSourceFieldFieldType.DateTime ? parseDateTimeUTC(field.Value) : field.Value,
      values: field.Values
    };
    return aggr;
  }, {});
};
