var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import {
  mapCompanyRowToDto,
  mapCompanyRowToMigrateCompanyRequest,
  mapSellerAccountRowToDto,
  mapSellerAccountRowToMigrateSellerAccountRequest,
  mapSubscriptionRowToDto,
  mapSubscriptionRowToMigrateSubscriptionRequest,
  mapVatNumberRowToDto,
  mapVatNumberRowToMigrateVatNumberRequest
} from "./MigrationMapper";
import { Migration as MigarationClient } from "AdminServices/APIs/self/clients";
import { nameof } from "GlobalShared/common/Nameof";
import { useClient, useLoadingReducer, useState } from "GlobalShared/haunted/CustomHooks";
import { withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { parseCSVFile } from "GlobalShared/helpers/fileHelper";
import { templateTitle } from "GlobalShared/templates/commons";
import { html } from "lit-html";
import { validateRows } from "./Validation/MigrationValidator";
import { formatDate } from "GlobalShared/helpers/dateHelper";
const CSV_HEADER_NO_OF_ROWS = 1;
const DEFAULT_SERVER_ERROR_MESSAGE = "Unknown server error, please report to the developers";
const DEFAULT_SUCCESS_MESSAGE = "File uploaded successfully.";
export function Migration() {
  const formatBoolean = (b) => {
    if (b === void 0) {
      return "";
    }
    return b ? "Y" : "N";
  };
  const formatDate2 = (m) => {
    if (m === void 0) {
      return "";
    }
    return formatDate(m);
  };
  const onCsvDropped = (e, csvType) => __async(this, null, function* () {
    setSuccessMessage(void 0);
    setUploadValidationErrors(void 0);
    setDroppedCsvType(csvType);
    const contentArray = yield parseCSVFile(e.detail.files[0]);
    let rows;
    switch (csvType) {
      case "CompaniesCSV":
        rows = contentArray.slice(CSV_HEADER_NO_OF_ROWS).map(mapCompanyRowToDto);
        setCompaniesTableRows(rows);
        break;
      case "SubscriptionsCSV":
        rows = contentArray.slice(CSV_HEADER_NO_OF_ROWS).map(mapSubscriptionRowToDto);
        setSubscriptionsTableRows(rows);
        break;
      case "VATNumberCSV":
        rows = contentArray.slice(CSV_HEADER_NO_OF_ROWS).map(mapVatNumberRowToDto);
        setVatNumbersTableRows(rows);
        break;
      case "SellerAccountsCSV":
        rows = contentArray.slice(CSV_HEADER_NO_OF_ROWS).map(mapSellerAccountRowToDto);
        setSellerAccountsTableRows(rows);
        break;
      default:
        break;
    }
    const validationResult = validateRows(rows, csvType);
    setValidationErrors(validationResult);
  });
  const upload = () => __async(this, null, function* () {
    if ((validationErrors == null ? void 0 : validationErrors.length) === 0) {
      dispatchLoading("inc");
      setUploadValidationErrors(void 0);
      setSuccessMessage(void 0);
      withErrorHandling(
        () => __async(this, null, function* () {
          switch (droppedCsvType) {
            case "CompaniesCSV": {
              const request = companiesTableRows.map((r) => mapCompanyRowToMigrateCompanyRequest(r));
              const result = yield migrationClient.migrateCompany({ body: request });
              if (result) {
                setSuccessMessage(DEFAULT_SUCCESS_MESSAGE);
                setCompaniesTableRows(void 0);
              } else {
                setUploadValidationErrors([
                  {
                    message: DEFAULT_SERVER_ERROR_MESSAGE
                  }
                ]);
              }
              break;
            }
            case "SubscriptionsCSV": {
              const request = subscriptionsTableRows.map((r) => mapSubscriptionRowToMigrateSubscriptionRequest(r));
              const result = yield migrationClient.migrateSubscriptions({ body: request });
              if (result) {
                setSuccessMessage(DEFAULT_SUCCESS_MESSAGE);
                setSubscriptionsTableRows(void 0);
              } else {
                setUploadValidationErrors([
                  {
                    message: DEFAULT_SERVER_ERROR_MESSAGE
                  }
                ]);
              }
              break;
            }
            case "VATNumberCSV": {
              const request = vatNumbersTableRows.map((r) => mapVatNumberRowToMigrateVatNumberRequest(r));
              const result = yield migrationClient.migrateOtherVat({ body: request });
              if (result) {
                setSuccessMessage(DEFAULT_SUCCESS_MESSAGE);
                setVatNumbersTableRows(void 0);
              } else {
                setUploadValidationErrors([
                  {
                    message: DEFAULT_SERVER_ERROR_MESSAGE
                  }
                ]);
              }
              break;
            }
            case "SellerAccountsCSV": {
              const request = sellerAccountstableRows.map((r) => mapSellerAccountRowToMigrateSellerAccountRequest(r));
              const result = yield migrationClient.migrateSellerAccount({ body: request });
              if (result) {
                setSuccessMessage(DEFAULT_SUCCESS_MESSAGE);
                setSellerAccountsTableRows(void 0);
              } else {
                setUploadValidationErrors([
                  {
                    message: DEFAULT_SERVER_ERROR_MESSAGE
                  }
                ]);
              }
              break;
            }
            default:
              break;
          }
        }),
        (error) => {
          var _a, _b, _c, _d;
          const errors = [];
          if (error.ErrorMessages) {
            (_a = error.ErrorMessages) == null ? void 0 : _a.forEach((message) => {
              errors.push({
                message: message.Message
              });
            });
            setUploadValidationErrors(errors);
          } else if (error.Message) {
            setUploadValidationErrors([
              {
                message: error.Message
              }
            ]);
          } else {
            const error2 = error;
            if ((_b = error2.data) == null ? void 0 : _b.ErrorMessages) {
              (_c = error2.data.ErrorMessages) == null ? void 0 : _c.forEach((message) => {
                errors.push({
                  message: message.Message
                });
              });
              setUploadValidationErrors(errors);
            } else if ((_d = error2.data) == null ? void 0 : _d.Message) {
              setUploadValidationErrors([
                {
                  message: error2.data.Message
                }
              ]);
            } else {
              setUploadValidationErrors([
                {
                  message: DEFAULT_SERVER_ERROR_MESSAGE
                }
              ]);
            }
          }
        },
        () => {
          dispatchLoading("dec");
        }
      );
    }
  });
  const migrationClient = useClient(MigarationClient);
  const [companiesTableRows, setCompaniesTableRows] = useState(void 0);
  const [subscriptionsTableRows, setSubscriptionsTableRows] = useState(void 0);
  const [vatNumbersTableRows, setVatNumbersTableRows] = useState(void 0);
  const [sellerAccountstableRows, setSellerAccountsTableRows] = useState(void 0);
  const [validationErrors, setValidationErrors] = useState(void 0);
  const [uploadValidationErrors, setUploadValidationErrors] = useState(void 0);
  const [successMessage, setSuccessMessage] = useState(void 0);
  const [loading, dispatchLoading] = useLoadingReducer();
  const [droppedCsvType, setDroppedCsvType] = useState(void 0);
  const successTemplate = () => {
    return successMessage ? html`<div class="mb-3 p-3 bg-green-500 text-white">
					<div class="text-sm font-bold">${successMessage}</div>
			  </div>` : "";
  };
  const validationErrorsTemplate = (vErrors) => {
    return (vErrors == null ? void 0 : vErrors.length) > 0 ? html`<div class="bg-brand-primary text-white mb-4 p-2">
					${vErrors.map(
      (validationError) => html`${"Validation error"} ${validationError.lineNumber !== void 0 ? " in line " + validationError.lineNumber + 1 : ""}${":"} ${validationError.message}<br />`
    )}
			  </div>` : "";
  };
  const csvTable = () => {
    switch (droppedCsvType) {
      case "CompaniesCSV":
        return companiesCsvTable();
      case "SubscriptionsCSV":
        return subscriptionsCsvTable();
      case "VATNumberCSV":
        return vatNumbersCsvTable();
      case "SellerAccountsCSV":
        return sellerAccountsCsvTable();
      default:
        return "";
    }
  };
  const companiesCsvTable = () => {
    const data = companiesTableRows;
    const vm = {
      columns: [
        {
          field: "Line",
          label: "Line",
          columnClass: "w-12"
        },
        {
          field: nameof("PortalId"),
          label: "Portal Id"
        },
        {
          field: nameof("Name"),
          label: nameof("Name"),
          columnClass: "w-12"
        },
        {
          field: nameof("LegalStatus"),
          label: "Legal status"
        },
        {
          field: nameof("DOB"),
          label: nameof("DOB")
        },
        {
          field: nameof("HomeCountry"),
          label: "Home Country"
        },
        { field: nameof("CountryCode"), label: "Country Code" },
        { field: nameof("AddressCity"), label: "Address City" },
        { field: nameof("AddressStreet"), label: "Address street" },
        { field: nameof("AddressZip"), label: "Address zip" },
        { field: nameof("AddressStreetNumber"), label: "Address streetnumber" },
        { field: nameof("VatNumber"), label: "VAT number" },
        { field: nameof("ContactLanguage"), label: "Contact language" },
        { field: nameof("ContactEmail"), label: "Contact email" },
        { field: nameof("ContactTitle"), label: "Contact title" },
        { field: nameof("ContactFirstName"), label: "Contact first name" },
        { field: nameof("ContactLastName"), label: "Contact last name" },
        { field: nameof("GisNumber"), label: "GIS ID" },
        { field: nameof("EmsNumber"), label: "EMS" },
        { field: nameof("ClientAcceptance"), label: "Client acceptance" },
        { field: nameof("SentinelNumber"), label: "Sentinel number" },
        { field: nameof("SentinelValidTo"), label: "Sentinel valid to" },
        { field: nameof("ClientHasOSSReg"), label: "Client has OSS reg" },
        { field: nameof("OSSCountryCode"), label: "Country code" },
        { field: nameof("OSSNumber"), label: "OSS number" },
        { field: nameof("OSSDateFrom"), label: "Date from" }
      ],
      data,
      // eslint-disable-next-line complexity
      cellTemplate: (index, field) => {
        const row = data[index];
        if (field === "Line") {
          return html`<p class="w-8">${index + 1}</p>`;
        }
        if (field === nameof("PortalId")) {
          return html`<p>${row.PortalId}</p>`;
        }
        if (field === nameof("Name")) {
          return html`<p>${row.Name}</p>`;
        }
        if (field === nameof("LegalStatus")) {
          return html`<p>${row.LegalStatus}</p>`;
        }
        if (field === nameof("DOB")) {
          return html`<p>${formatDate2(row.DOB)}</p>`;
        }
        if (field === nameof("HomeCountry")) {
          return html`<p>${row.HomeCountry}</p>`;
        }
        if (field === nameof("CountryCode")) {
          return html`<p>${row.CountryCode}</p>`;
        }
        if (field === nameof("AddressCity")) {
          return html`<p>${row.AddressCity}</p>`;
        }
        if (field === nameof("AddressStreet")) {
          return html`<p>${row.AddressStreet}</p>`;
        }
        if (field === nameof("AddressZip")) {
          return html`<p>${row.AddressZip}</p>`;
        }
        if (field === nameof("AddressStreetNumber")) {
          return html`<p>${row.AddressStreetNumber}</p>`;
        }
        if (field === nameof("VatNumber")) {
          return html`<p>${row.VatNumber}</p>`;
        }
        if (field === nameof("ContactLanguage")) {
          return html`<p>${row.ContactLanguage}</p>`;
        }
        if (field === nameof("ContactEmail")) {
          return html`<p>${row.ContactEmail}</p>`;
        }
        if (field === nameof("ContactTitle")) {
          return html`<p>${row.ContactTitle}</p>`;
        }
        if (field === nameof("ContactFirstName")) {
          return html`<p>${row.ContactFirstName}</p>`;
        }
        if (field === nameof("ContactLastName")) {
          return html`<p>${row.ContactLastName}</p>`;
        }
        if (field === nameof("GisNumber")) {
          return html`<p>${row.GisNumber}</p>`;
        }
        if (field === nameof("EmsNumber")) {
          return html`<p>${row.EmsNumber}</p>`;
        }
        if (field === nameof("ClientAcceptance")) {
          return html`<p>${row.ClientAcceptance}</p>`;
        }
        if (field === nameof("SentinelNumber")) {
          return html`<p>${row.SentinelNumber}</p>`;
        }
        if (field === nameof("SentinelValidTo")) {
          return html`<p>${formatDate2(row.SentinelValidTo)}</p>`;
        }
        if (field === nameof("ClientHasOSSReg")) {
          return html`<p>${formatBoolean(row.ClientHasOSSReg)}</p>`;
        }
        if (field === nameof("OSSNumber")) {
          return html`<p>${row.OSSNumber}</p>`;
        }
        if (field === nameof("OSSCountryCode")) {
          return html`<p>${row.OSSCountryCode}</p>`;
        }
        if (field === nameof("OSSDateFrom")) {
          return html`<p>${formatDate2(row.OSSDateFrom)}</p>`;
        }
        return "";
      },
      paging: { pageable: false }
    };
    return html`<dc-table .vm=${vm}> </dc-table>`;
  };
  const subscriptionsCsvTable = () => {
    const data = subscriptionsTableRows;
    const vm = {
      columns: [
        {
          field: "Line",
          label: "Line",
          columnClass: "w-12"
        },
        { field: nameof("PortalId"), label: "Portal Id" },
        { field: nameof("Name"), label: "Name" },
        { field: nameof("ServiceCountry"), label: "Service Country" },
        {
          field: nameof("CountryCode"),
          label: "Country code"
        },
        { field: nameof("LocalVatNumber"), label: "Local Vat Number" },
        { field: nameof("LocalVatStartDate"), label: "Local Vat Start Date" },
        {
          field: nameof("EuVatNumber"),
          label: "EuVat Number"
        },
        {
          field: nameof("EuVatStartDate"),
          label: "EuVat Start Date"
        },
        { field: nameof("PrimaryFrequency"), label: "Primary Frequency" },
        { field: nameof("PrimaryFrequencyEFiling"), label: "Primary Frequency EFiling" },
        {
          field: nameof("PrimaryFrequencyFilingExtension"),
          label: "Primary Frequency Filing Extension"
        },
        { field: nameof("SecondaryFrequency"), label: "Secondary Frequency" },
        { field: nameof("SecondaryFrequencyEFiling"), label: "Secondary Frequency EFiling" },
        {
          field: nameof("SecondaryFrequencyFilingExtension"),
          label: "Secondary Frequency Filing Extension"
        },
        {
          field: nameof("EslFrequency"),
          label: "Esl Frequency"
        },
        { field: nameof("VlsFrequency"), label: "Vls Frequency" },
        { field: nameof("IbanOrLink"), label: "Iban Or Link" },
        { field: nameof("FirstMonthOfGeneration"), label: "First Month Of Generation" },
        { field: nameof("SubscriptionValidTo"), label: "Subscription Valid To" },
        { field: nameof("RegistrationStatus"), label: "Registration Status" },
        { field: nameof("FiscRep"), label: "Fisc Rep" },
        { field: nameof("GermanFilingAgent"), label: "German Filing Agent" },
        {
          field: nameof("EsVatPayment"),
          label: "EsVat Payment"
        },
        { field: nameof("IntrastatIn"), label: "Intrastat In" },
        { field: nameof("IntrastatOut"), label: "Intrastat Out" },
        { field: nameof("IntrastatFrequency"), label: "Intrastat Frequency" }
      ],
      data,
      // eslint-disable-next-line complexity
      cellTemplate: (index, field) => {
        const row = data[index];
        if (field === "Line") {
          return html`<p class="w-8">${index + 1}</p>`;
        }
        if (field === nameof("CountryCode")) {
          return html`<p>${row.CountryCode}</p>`;
        }
        if (field === nameof("EslFrequency")) {
          return html`<p>${row.EslFrequency}</p>`;
        }
        if (field === nameof("EsVatPayment")) {
          return html`<p>${formatBoolean(row.EsVatPayment)}</p>`;
        }
        if (field === nameof("EuVatNumber")) {
          return html`<p>${row.EuVatNumber}</p>`;
        }
        if (field === nameof("EuVatStartDate")) {
          return html`<p>${formatDate2(row.EuVatStartDate)}</p>`;
        }
        if (field === nameof("FirstMonthOfGeneration")) {
          return html`<p>${formatDate2(row.FirstMonthOfGeneration)}</p>`;
        }
        if (field === nameof("FiscRep")) {
          return html`<p>${formatBoolean(row.FiscRep)}</p>`;
        }
        if (field === nameof("GermanFilingAgent")) {
          return html`<p>${formatBoolean(row.GermanFilingAgent)}</p>`;
        }
        if (field === nameof("IbanOrLink")) {
          return html`<p>${row.IbanOrLink}</p>`;
        }
        if (field === nameof("IntrastatFrequency")) {
          return html`<p>${row.IntrastatFrequency}</p>`;
        }
        if (field === nameof("IntrastatIn")) {
          return html`<p>${formatBoolean(row.IntrastatIn)}</p>`;
        }
        if (field === nameof("IntrastatOut")) {
          return html`<p>${formatBoolean(row.IntrastatOut)}</p>`;
        }
        if (field === nameof("LocalVatNumber")) {
          return html`<p>${row.LocalVatNumber}</p>`;
        }
        if (field === nameof("LocalVatStartDate")) {
          return html`<p>${formatDate2(row.LocalVatStartDate)}</p>`;
        }
        if (field === nameof("Name")) {
          return html`<p>${row.Name}</p>`;
        }
        if (field === nameof("PortalId")) {
          return html`<p>${row.PortalId}</p>`;
        }
        if (field === nameof("PrimaryFrequency")) {
          return html`<p>${row.PrimaryFrequency}</p>`;
        }
        if (field === nameof("PrimaryFrequencyEFiling")) {
          return html`<p>${formatBoolean(row.PrimaryFrequencyEFiling)}</p>`;
        }
        if (field === nameof("PrimaryFrequencyFilingExtension")) {
          return html`<p>${formatBoolean(row.PrimaryFrequencyFilingExtension)}</p>`;
        }
        if (field === nameof("RegistrationStatus")) {
          return html`<p>${row.RegistrationStatus}</p>`;
        }
        if (field === nameof("SecondaryFrequency")) {
          return html`<p>${row.SecondaryFrequency}</p>`;
        }
        if (field === nameof("SecondaryFrequencyEFiling")) {
          return html`<p>${formatBoolean(row.SecondaryFrequencyEFiling)}</p>`;
        }
        if (field === nameof("SecondaryFrequencyFilingExtension")) {
          return html`<p>${formatBoolean(row.SecondaryFrequencyFilingExtension)}</p>`;
        }
        if (field === nameof("ServiceCountry")) {
          return html`<p>${row.ServiceCountry}</p>`;
        }
        if (field === nameof("SubscriptionValidTo")) {
          return html`<p>${formatDate2(row.SubscriptionValidTo)}</p>`;
        }
        if (field === nameof("VlsFrequency")) {
          return html`<p>${row.VlsFrequency}</p>`;
        }
        return "";
      },
      paging: { pageable: false }
    };
    return html`<dc-table .vm=${vm}> </dc-table>`;
  };
  const vatNumbersCsvTable = () => {
    const data = vatNumbersTableRows;
    const vm = {
      columns: [
        {
          field: "Line",
          label: "Line",
          columnClass: "w-12"
        },
        {
          field: nameof("Country"),
          label: "Country"
        },
        {
          field: nameof("Name"),
          label: nameof("Name")
        },
        {
          field: nameof("PortalId"),
          label: "Portal Id"
        },
        {
          field: nameof("ValidEndDate"),
          label: "Valid End Date"
        },
        {
          field: nameof("ValidFromDate"),
          label: "Valid From Date"
        },
        { field: nameof("VatNumber"), label: "Vat Number" }
      ],
      data,
      cellTemplate: (index, field) => {
        const row = data[index];
        if (field === "Line") {
          return html`<p class="w-8">${index + 1}</p>`;
        }
        if (field === nameof("Country")) {
          return html`<p>${row.Country}</p>`;
        }
        if (field === nameof("Name")) {
          return html`<p>${row.Name}</p>`;
        }
        if (field === nameof("PortalId")) {
          return html`<p>${row.PortalId}</p>`;
        }
        if (field === nameof("ValidEndDate")) {
          return html`<p>${formatDate2(row.ValidEndDate)}</p>`;
        }
        if (field === nameof("ValidFromDate")) {
          return html`<p>${formatDate2(row.ValidFromDate)}</p>`;
        }
        if (field === nameof("VatNumber")) {
          return html`<p>${row.VatNumber}</p>`;
        }
        return "";
      },
      paging: { pageable: false }
    };
    return html`<dc-table .vm=${vm}> </dc-table>`;
  };
  const sellerAccountsCsvTable = () => {
    const data = sellerAccountstableRows;
    const vm = {
      columns: [
        {
          field: "Line",
          label: "Line",
          columnClass: "w-12"
        },
        {
          field: nameof("PortalId"),
          label: "Portal Id"
        },
        {
          field: nameof("Name"),
          label: nameof("Name")
        },
        {
          field: nameof("SellerAccountName"),
          label: "Seller Account Name"
        },
        {
          field: nameof("SellerAccountIdentifier"),
          label: "Seller Account Identifier"
        },
        {
          field: nameof("Mwstoken"),
          label: "Mws token"
        }
      ],
      data,
      cellTemplate: (index, field) => {
        const row = data[index];
        if (field === "Line") {
          return html`<p class="w-8">${index + 1}</p>`;
        }
        if (field === nameof("Mwstoken")) {
          return html`<p>${row.Mwstoken}</p>`;
        }
        if (field === nameof("Name")) {
          return html`<p>${row.Name}</p>`;
        }
        if (field === nameof("PortalId")) {
          return html`<p>${row.PortalId}</p>`;
        }
        if (field === nameof("SellerAccountIdentifier")) {
          return html`<p>${row.SellerAccountIdentifier}</p>`;
        }
        if (field === nameof("SellerAccountName")) {
          return html`<p>${row.SellerAccountName}</p>`;
        }
        return "";
      },
      paging: { pageable: false }
    };
    return html`<dc-table .vm=${vm}> </dc-table>`;
  };
  return html`
		<div class="container px-4">
			<div class="my-4">${templateTitle("Migration")}</div>
			<div>
				<div class="grid grid-cols-2 gap-4 mb-4">
					<div>
						<dc-dropzone
							@drop=${(e) => {
    onCsvDropped(e, "CompaniesCSV");
  }}
							class="h-32 bg-yellow-100 border block"
							.text=${"DROP COMPANIES CSV HERE"}
						></dc-dropzone>
					</div>
					<div>
						<dc-dropzone
							@drop=${(e) => {
    onCsvDropped(e, "SubscriptionsCSV");
  }}
							class="h-32 bg-yellow-100 border block"
							.text=${"DROP SUBSCRIPTIONS CSV HERE"}
						></dc-dropzone>
					</div>
					<div>
						<dc-dropzone
							@drop=${(e) => {
    onCsvDropped(e, "VATNumberCSV");
  }}
							class="h-32 bg-yellow-100 border block"
							.text=${"DROP VAT NUMBERS CSV HERE"}
						></dc-dropzone>
					</div>
					<div>
						<dc-dropzone
							@drop=${(e) => {
    onCsvDropped(e, "SellerAccountsCSV");
  }}
							class="h-32 bg-yellow-100 border block"
							.text=${"DROP SELLER ACCOUNTS CSV HERE"}
						></dc-dropzone>
					</div>
				</div>
				${successTemplate()} ${validationErrorsTemplate(validationErrors)} ${validationErrorsTemplate(uploadValidationErrors)}
				<div>
					${companiesTableRows || subscriptionsTableRows || vatNumbersTableRows || sellerAccountstableRows ? html`<button class="btn btn-primary mb-4" ?disabled=${(validationErrors == null ? void 0 : validationErrors.length) !== 0} @click=${upload}>Upload</button>` : ""}
				</div>
				<div class="">${csvTable()}</div>
			</div>
			${loading.count > 0 ? html`<dc-loader></dc-loader>` : ""}
		</div>
	`;
}
