import { TswagClient } from "./../commons/TswagClient";
import { Questionnaire as QuestionnaireModel } from "./../models/V1/CompanyInfo/Questionnaire/Questionnaire";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { FileReferenceAnswer } from "./../models/V1/CompanyInfo/Questionnaire/Answers/FileReferenceAnswer";
export class Questionnaire {
  /**
   * Creates an instance of Questionnaire.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Questionnaire
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GetQuestionnaireRq_ _getQuestionnaireRq
   * @returns Promise<GetQuestionnaireRq_>
   * @memberof Questionnaire
   */
  getQuestionnaire(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Questionnaire",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: QuestionnaireModel }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SaveModificationRq_ _saveModificationRq
   * @returns Promise<SaveModificationRq_>
   * @memberof Questionnaire
   */
  saveModification(pSaveModificationRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Questionnaire",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSaveModificationRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadFileToQuestionRq_ _uploadFileToQuestionRq
   * @returns Promise<UploadFileToQuestionRq_>
   * @memberof Questionnaire
   */
  uploadFileToQuestion(pUploadFileToQuestionRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Questionnaire/Answers/{questionId}/Upload",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "questionId", value: pUploadFileToQuestionRq.questionId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadFileToQuestionRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: FileReferenceAnswer },
        { statusCode: 415, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadFileRq_ _uploadFileRq
   * @returns Promise<UploadFileRq_>
   * @memberof Questionnaire
   */
  uploadFile(pUploadFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Questionnaire/Answers/FileUpload",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadFileRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 201 },
        { statusCode: 415, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeQuestionnaireRq_ _finalizeQuestionnaireRq
   * @returns Promise<FinalizeQuestionnaireRq_>
   * @memberof Questionnaire
   */
  finalizeQuestionnaire(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Questionnaire/Finalize",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadFileRq_ _downloadFileRq
   * @returns Promise<DownloadFileRq_>
   * @memberof Questionnaire
   */
  downloadFile(pDownloadFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Questionnaire/Files/{reference}",
      "GET",
      "application/json; charset=UTF-8",
      "image/png, image/jpeg, application/pdf",
      options
    );
    client.addPathParams([
      { name: "reference", value: pDownloadFileRq.reference, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
