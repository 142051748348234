var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient, useEffect, useMemo, useState } from "GlobalShared/haunted/CustomHooks";
import { getWorldCountries, getEuCountries, getBirthCountries, getServiceCountries } from "GlobalShared/helpers/masterDataHelper";
import { getCurrencies } from "GlobalShared/helpers/complianceMasterDataHelper";
import { MasterData as MasterDataClient } from "AdminServices/APIs/self/clients/MasterData";
import { ComplianceMasterData as ComplianceMasterDataClient } from "AdminServices/APIs/self/clients/ComplianceMasterData";
import { SessionManagerStorage } from "AdminServices/session/SessionManagerStorage";
import { Agent as AgentClient } from "AdminServices/APIs/self/clients/Agent";
export const useWorldCountries = (locale) => {
  const [worldCountries, setWorldCountries] = useState([]);
  const masterDataClient = useClient(MasterDataClient);
  const init = () => __async(void 0, null, function* () {
    const countries = yield getWorldCountries(masterDataClient, locale);
    setWorldCountries(countries);
  });
  useEffect(init, []);
  return worldCountries;
};
export const useEUCountries = (locale) => {
  const [euCountries, setEuCountries] = useState([]);
  const masterDataClient = useClient(MasterDataClient);
  const init = () => __async(void 0, null, function* () {
    const euCountriesResponse = yield getEuCountries(masterDataClient, locale);
    setEuCountries(euCountriesResponse);
  });
  useEffect(init, []);
  return euCountries;
};
export const useServiceCountries = (locale) => {
  const [serviceCountries, setServiceCountries] = useState([]);
  const masterDataClient = useClient(MasterDataClient);
  const init = () => __async(void 0, null, function* () {
    const servieCountriesResponse = yield getServiceCountries(masterDataClient, locale);
    setServiceCountries(servieCountriesResponse);
  });
  useEffect(init, []);
  return serviceCountries;
};
export const useBirthCountries = (locale) => {
  const [birthCountries, setBirthCountries] = useState([]);
  const masterDataClient = useClient(MasterDataClient);
  const init = () => __async(void 0, null, function* () {
    const birthCountriesResponse = yield getBirthCountries(masterDataClient, locale);
    setBirthCountries(birthCountriesResponse);
  });
  useEffect(init, []);
  return birthCountries;
};
export const useCurrencies = () => {
  const [currencies, setCurrencies] = useState([]);
  const masterDataClient = useClient(ComplianceMasterDataClient);
  const init = () => __async(void 0, null, function* () {
    const currenciesResponse = yield getCurrencies(masterDataClient);
    setCurrencies(currenciesResponse);
  });
  useEffect(init, []);
  return currencies;
};
let permissionFetchCallbacks = void 0;
export const usePermissionManager = () => {
  const fetchPermissions = () => __async(void 0, null, function* () {
    var _a;
    const response = yield agentClient.getPermissions();
    sessionManagerStorage.permissions = (_a = response.data) != null ? _a : [];
  });
  const sessionManagerStorage = useMemo(() => new SessionManagerStorage(), []);
  const [permissions, setPermissions] = useState([]);
  const agentClient = useClient(AgentClient);
  const permissionManager = useMemo(
    () => ({
      hasPermission: (ps) => {
        return permissions.includes(ps);
      },
      hasAnyPermission: (ps) => {
        return ps.some((p) => permissions.includes(p));
      },
      hasAllPermission: (ps) => {
        return !ps.some((p) => !permissions.includes(p));
      }
    }),
    [permissions]
  );
  const init = () => __async(void 0, null, function* () {
    if (sessionManagerStorage.arePermissionsAvailable()) {
      setPermissions(sessionManagerStorage.permissions);
    } else {
      if (permissionFetchCallbacks) {
        permissionFetchCallbacks.push(() => {
          setPermissions(sessionManagerStorage.permissions);
        });
      } else {
        try {
          permissionFetchCallbacks = [];
          yield fetchPermissions();
          permissionFetchCallbacks.forEach((callback) => callback());
        } finally {
          permissionFetchCallbacks = void 0;
        }
      }
    }
    setPermissions(sessionManagerStorage.permissions);
  });
  useEffect(init, []);
  return permissionManager;
};
