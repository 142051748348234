import { BankAccountChangeRequest, NewManualBankAccount } from "AdminServices/APIs/self/models/V1/Company/VatRegistration";
import i18next from "i18next";
export const mapBankAccountConfig = (bankAccountConfig) => {
  return new BankAccountChangeRequest({
    BankAccountRef: bankAccountConfig.BankAccountRef,
    BankAccountUrl: bankAccountConfig.BankAccountUrl,
    ManualBankAccount: bankAccountConfig.BankAccount ? new NewManualBankAccount({
      BankAccountId: bankAccountConfig.BankAccount.BankAccountId,
      Iban: bankAccountConfig.BankAccount.Iban
    }) : void 0
  });
};
export const mapSubmissionConfig = (options) => {
  const vm = {
    Frequencies: options.map((f) => {
      const fvm = {
        CountryCode: f.CountryCode,
        EFiling: f.EFiling,
        FilingExtension: f.FilingExtension,
        FrequencyType: f.FrequencyType,
        ReturnType: f.ReturnType
      };
      return fvm;
    })
  };
  return vm;
};
export const ClientAcceptanceLabels = [
  { label: "Pending", value: "Pending" },
  { label: "Completed", value: "Completed" },
  { label: "MFE", value: "MFE" },
  { label: "Failed", value: "Failed" }
];
export const getClientAcceptanceStatusLabel = (status) => {
  const item = ClientAcceptanceLabels.find((s) => s.value === status);
  return item && item.label ? item.label : status;
};
export const COMPLIANCE_SUBSCRIPTION_STATUS_LABELS = {
  Active: i18next.t("Active"),
  Cancelled: i18next.t("Cancelled"),
  Pending: i18next.t("Pending")
};
export const SUBMISSION_FREQUENCY_TYPE_LABELS = {
  NotAvailable: i18next.t("Not Available"),
  Monthly: i18next.t("Monthly"),
  Quarterly: i18next.t("Quarterly"),
  QuarterlyShifted1: i18next.t("QuarterlyShifted1"),
  QuarterlyShifted2: i18next.t("QuarterlyShifted2"),
  BiMonthly: i18next.t("BiMonthly"),
  FourMonthly: i18next.t("FourMonthly"),
  SixMonthly: i18next.t("SixMonthly"),
  Yearly: i18next.t("Yearly")
};
