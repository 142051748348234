import { Term } from "./Term";
import { MappingExpressionBase } from "./MappingExpressionBase";
export class ConditionExpression extends MappingExpressionBase {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof ConditionExpression
     */
    this.$type = "V1.TemplateMapping.ConditionExpression";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.BoolOperator = ConditionExpressionBoolOperator[_data["BoolOperator"]];
      if (_data["Terms"] && _data["Terms"].constructor === Array) {
        this.Terms = [];
        for (let item of _data["Terms"]) {
          const terms_ = new Term();
          this.Terms.push(terms_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["BoolOperator"] = ConditionExpressionBoolOperator[this.BoolOperator];
    if (this.Terms && this.Terms.constructor === Array) {
      _data["Terms"] = [];
      for (let item of this.Terms) {
        _data["Terms"].push(item.toJSON());
      }
    }
    return _data;
  }
}
export var ConditionExpressionBoolOperator = /* @__PURE__ */ ((ConditionExpressionBoolOperator2) => {
  ConditionExpressionBoolOperator2["And"] = "And";
  ConditionExpressionBoolOperator2["Or"] = "Or";
  return ConditionExpressionBoolOperator2;
})(ConditionExpressionBoolOperator || {});
