import { Condition, CreateTaxCodeDefinition, TaxCodeDefinition, UpdateTaxCodeDefinition } from "AdminServices/APIs/self/models/V1/TaxEngine";
import { TaxCode } from "AdminServices/APIs/self/models/V1/TaxEngine/TaxCode";
import { TaxCodeTerm } from "AdminServices/APIs/self/models/V1/TaxEngine/TaxCodeTerm";
export const TERM_CODE_MAPPING = {
  TransactionType: "Multiselect",
  EstablishmentCountry: "Multiselect",
  DepartureCountry: "Multiselect",
  ArrivalCountry: "Multiselect",
  CustomerType: "Select",
  NetConsignmentVal: "Number",
  OnlineMarketplace: "Select",
  TaxCollectionResponsibility: "Select",
  DeliveryCondition: "Select",
  TransactionDate: "DateRange",
  SourceType: "Select",
  IsRegisteredInArrivalCountry: "Select",
  DepartureAndArrivalCountry: "Select",
  AccountWithReverseCharge: "Select",
  ExportOutsideEU: "Select",
  TypeOfSale: "Select",
  PriceOfItem: "Select",
  ClientHasOSSNumber: "Select",
  OSSRegistrationCountry: "Multiselect"
};
export const TRANSACTION_TYPE_LABELS = [
  { value: "Inbound_In", label: "InboundIn" },
  { value: "Inbound_Out", label: "InboundOut" },
  { value: "Sale", label: "Sale" },
  { value: "Return", label: "Return" },
  { value: "FC_Transfer_In", label: "FCTransferIn" },
  { value: "FC_Transfer_Out", label: "FCTransferOut" },
  { value: "Commingling_Sell", label: "ComminglingSell" },
  { value: "Commingling_Buy", label: "ComminglingBuy" },
  { value: "Liquidation_Sale", label: "LiquidationSale" },
  { value: "Liquidation_Refund", label: "LiquidationRefund" },
  { value: "Brexit_Sale", label: "BrexitSale" },
  { value: "Brexit_Return", label: "BrexitReturn" },
  { value: "Donation", label: "Donation" }
];
export const RETURN_TYPE_LABELS = [
  { value: "IOSS", label: "IOSS" },
  { value: "VAT", label: "VAT" }
];
export const TAX_COLLECTION_LABELS = [
  { value: "Seller", label: "Seller" },
  { value: "Buyer", label: "Buyer" },
  { value: "Marketplace", label: "Marketplace" }
];
export const CUSTOMER_TYPE_LABELS = [
  { value: "B2C", label: "B2C" },
  { value: "B2B", label: "B2B" },
  { value: "InvalidB2B", label: "Invalid B2B" }
];
export const DELIVERY_CONDITION_LABELS = [
  { value: "DDP", label: "Delivery Duty Paid (DDP)" },
  { value: "NonDDP", label: "Non Delivery Duty Paid (Non-DDP)" }
];
export const DEPARTURE_AND_ARRIVAL_COUNTRY_LABELS = [
  { value: "EQ", label: "Equals" },
  { value: "NEQ", label: "Not Equals" }
];
export const YES_NO_LABELS = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];
export const TYPE_OF_SALE_LABELS = [
  { value: "Goods", label: "Goods" },
  { value: "Services", label: "Services" }
];
export const PRICE_OF_ITEM_LABELS = [
  { value: "Empty", label: "Empty" },
  { value: "Zero", label: "Zero" },
  { value: "NotZero", label: "NotZero" }
];
export const TERM_CODE_LABELS = [
  { value: "TransactionType", label: "Transaction Type" },
  { value: "EstablishmentCountry", label: "Establishment Country" },
  { value: "DepartureCountry", label: "Departure Country" },
  { value: "ArrivalCountry", label: "Arrival Country" },
  { value: "CustomerType", label: "Customer Type" },
  { value: "NetConsignmentVal", label: "Net Consignment Value" },
  { value: "OnlineMarketplace", label: "Online Marketplace" },
  { value: "TaxCollectionResponsibility", label: "Tax Collection Responsibility" },
  { value: "DeliveryCondition", label: "Delivery Condition" },
  { value: "TransactionDate", label: "Transaction Date" },
  { value: "SourceType", label: "Source Type" },
  { value: "IsRegisteredInArrivalCountry", label: "Registered in Arrival Country" },
  { value: "DepartureAndArrivalCountry", label: "Departure And Arrival Country" },
  { value: "AccountWithReverseCharge", label: "Account With Reverse Charge" },
  { value: "ExportOutsideEU", label: "Export Outside EU" },
  { value: "TypeOfSale", label: "Type Of Sale" },
  { value: "PriceOfItem", label: "Price Of Item" },
  { value: "ClientHasOSSNumber", label: "Client Has OSS Number" },
  { value: "OSSRegistrationCountry", label: "Country of OSS Registration" }
];
export const NUMBER_OP_LABELS = [
  { value: "Equals", label: "Equals" },
  { value: "NotEquals", label: "Not equals" },
  { value: "LessThan", label: "Less than" },
  { value: "GreaterThan", label: "Greater than" },
  { value: "LessThanEquals", label: "Less than equals" },
  { value: "GreaterThanEquals", label: "Greater than equals" }
];
export const MULTI_SELECT_OP_LABELS = [
  { value: "In", label: "In" },
  { value: "NotIn", label: "Not In" }
];
export const REPORTING_COUNTRY_TYPE_LABELS = [
  { value: "Arrival", label: "Arrival (AC)" },
  { value: "Departure", label: "Departure (DC)" },
  { value: "VATReporting", label: "VATReporting (RC)" }
];
export const DISTANCE_SALES_COUNTRY_TYPE_LABELS = REPORTING_COUNTRY_TYPE_LABELS;
export const REPORTING_COUNTRY_SHORT_CODE_MAPPING = {
  Arrival: "AC",
  Departure: "DC",
  VATReporting: "RC"
};
export const DISTANCE_SALES_COUNTRY_SHORT_CODE_MAPPING = REPORTING_COUNTRY_SHORT_CODE_MAPPING;
export const VAT_RATE_TYPE_LABELS = [
  { value: "Exempt", label: "Exempt (EXE)" },
  { value: "Middle", label: "Middle (MID)" },
  { value: "Reduced", label: "Reduced (RE1)" },
  { value: "Reduced2", label: "Reduced2 (RE2)" },
  { value: "Standard", label: "Standard (ST1)" },
  { value: "Standard2", label: "Standard2 (ST2)" },
  { value: "SuperReduced", label: "SuperReduced (SRE)" },
  { value: "SuperReduced2", label: "SuperReduced2 (SRE2)" },
  { value: "ZeroRated", label: "ZeroRated (ZER)" }
];
export const VAT_RATE_TYPE_SHORT_CODE_MAPPING = {
  Exempt: "EXE",
  Middle: "DC",
  Reduced: "RE1",
  Reduced2: "RE2",
  Standard: "ST1",
  Standard2: "ST2",
  SuperReduced: "SRE",
  SuperReduced2: "SRE2",
  ZeroRated: "ZER"
};
export const SOURCE_TYPE_LABELS = [
  { value: "Amazon", label: "Amazon" },
  { value: "KPMG", label: "External" }
];
export const DEFAULT_TAX_CODE = {
  id: void 0,
  returnType: void 0,
  taxCode: void 0,
  enabled: true,
  conditions: []
};
export const DEFAULT_TAX_CODES = [
  {
    id: 0,
    taxCode: {
      reportingCountry: "Arrival",
      tranTypeCode: "SA",
      vatRateType: "Standard",
      typeOfGoods: "A",
      distanceSalesCountry: "Departure"
    },
    returnType: "IOSS",
    enabled: true,
    conditions: [
      {
        condition: [
          {
            code: "ArrivalCountry",
            type: "Multiselect",
            op: "In",
            multiSelectVal: ["GB"]
          },
          {
            code: "TransactionType",
            type: "Multiselect",
            op: "NotIn",
            multiSelectVal: ["Inbound", "Outbound"]
          }
        ]
      },
      {
        condition: [
          {
            code: "CustomerType",
            type: "Select",
            op: "Is",
            selectVal: "B2C"
          },
          {
            code: "TaxCollectionResponsibility",
            type: "Select",
            op: "Is",
            selectVal: "Marketplace"
          }
        ]
      }
    ]
  },
  {
    id: 1,
    returnType: "VAT",
    taxCode: {
      reportingCountry: "Departure",
      tranTypeCode: "OR",
      vatRateType: "Exempt",
      typeOfGoods: "E"
    },
    enabled: true,
    conditions: []
  },
  {
    id: 1,
    returnType: "IOSS",
    taxCode: {
      reportingCountry: "VATReporting",
      tranTypeCode: "G",
      vatRateType: "Standard2",
      typeOfGoods: "",
      distanceSalesCountry: "Arrival"
    },
    enabled: true,
    conditions: []
  }
];
export const toTaxCodeString = (taxCode) => {
  var _a, _b;
  if (taxCode !== void 0) {
    return `${taxCode.reportingCountry ? REPORTING_COUNTRY_SHORT_CODE_MAPPING[taxCode.reportingCountry] : "XX"}${(_a = taxCode.tranTypeCode) == null ? void 0 : _a.toUpperCase()}${taxCode.vatRateType ? VAT_RATE_TYPE_SHORT_CODE_MAPPING[taxCode.vatRateType] : "XXX"}${(_b = taxCode.typeOfGoods) == null ? void 0 : _b.toUpperCase()}${taxCode.distanceSalesCountry ? DISTANCE_SALES_COUNTRY_SHORT_CODE_MAPPING[taxCode.distanceSalesCountry] : ""}`;
  } else {
    return "-";
  }
};
export const getTaxCodeByStringTemp = (taxCodeString) => {
  return DEFAULT_TAX_CODES.find((dtc) => toTaxCodeString(dtc.taxCode) === taxCodeString);
};
export const mapTaxCodeDefToVM = (taxCodeDef) => {
  const vm = {
    id: taxCodeDef.Id,
    taxCode: {
      reportingCountry: taxCodeDef.TaxCode.ReportingCountry,
      tranTypeCode: taxCodeDef.TaxCode.TranTypeCode,
      vatRateType: taxCodeDef.TaxCode.VatRateType,
      typeOfGoods: taxCodeDef.TaxCode.TypeOfGoods,
      distanceSalesCountry: taxCodeDef.TaxCode.DistanceSalesCountry
    },
    returnType: taxCodeDef.ReturnType,
    enabled: taxCodeDef.Enabled,
    conditions: taxCodeDef.Conditions.map((c) => {
      const termList = c.Terms.map((term) => {
        const tempTerm = {
          code: term.Code,
          type: term.Type,
          op: term.Operation,
          numberVal: term.NumberVal,
          selectVal: term.SelectVal,
          multiSelectVal: term.MultiSelectVal,
          dateRange: term.DateRange
        };
        return tempTerm;
      });
      return {
        condition: termList
      };
    })
  };
  return vm;
};
export const mapTaxCodeDefVMToCreateDTO = (taxCodeDefVM) => {
  const taxCodeDef = mapTaxCodeDefVMToDTO(taxCodeDefVM);
  return new CreateTaxCodeDefinition({
    TaxCode: taxCodeDef.TaxCode,
    TaxCodeStr: taxCodeDef.TaxCodeStr,
    Conditions: taxCodeDef.Conditions,
    ReturnType: taxCodeDefVM.returnType
  });
};
export const mapTaxCodeDefVMToUpdateDTO = (taxCodeDefVM) => {
  const taxCodeDef = mapTaxCodeDefVMToDTO(taxCodeDefVM);
  return new UpdateTaxCodeDefinition({
    TaxCode: taxCodeDef.TaxCode,
    TaxCodeStr: taxCodeDef.TaxCodeStr,
    Conditions: taxCodeDef.Conditions,
    ReturnType: taxCodeDefVM.returnType
  });
};
export const mapTaxCodeDefVMToDTO = (taxCodeDefVM) => {
  const vm = new TaxCodeDefinition({
    Id: taxCodeDefVM.id,
    TaxCodeStr: toTaxCodeString(taxCodeDefVM.taxCode),
    TaxCode: new TaxCode({
      ReportingCountry: taxCodeDefVM.taxCode.reportingCountry,
      TranTypeCode: taxCodeDefVM.taxCode.tranTypeCode,
      VatRateType: taxCodeDefVM.taxCode.vatRateType,
      TypeOfGoods: taxCodeDefVM.taxCode.typeOfGoods,
      DistanceSalesCountry: taxCodeDefVM.taxCode.distanceSalesCountry
    }),
    ReturnType: taxCodeDefVM.returnType,
    Conditions: taxCodeDefVM.conditions.map((conditionObj) => {
      const termList = conditionObj.condition.map((term) => {
        const tempTerm = new TaxCodeTerm({
          Code: term.code,
          Type: term.type,
          Operation: term.op,
          NumberVal: term.numberVal,
          SelectVal: term.selectVal,
          MultiSelectVal: term.multiSelectVal,
          DateRange: term.dateRange
        });
        return tempTerm;
      });
      return new Condition({
        Terms: termList
      });
    })
  });
  return vm;
};
