import { LegalEntityTypeAnswer as WebLegalEntityTypeAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { LegalEntityTypeAnswer as AdminLegalEntityTypeAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { useEnumAnswer } from "./answer-enum";
import { LegalEntityTypeAnswerValue } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers/LegalEntityTypeAnswerAbstraction";
import i18next from "i18next";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-legalentitytype";
export class LegalEntityTypeChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId
  };
  const dispatch = (answer) => {
    host.dispatchEvent(
      new LegalEntityTypeChangeEvent({
        answer: getAnswer(answer)
      })
    );
  };
  const getAnswer = (answer) => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminLegalEntityTypeAnswer({ Value: answer.Value, QuestionId: props.questionId });
    }
    return new WebLegalEntityTypeAnswer({ Value: answer.Value, QuestionId: props.questionId });
  };
  const enumHook = useEnumAnswer({
    dataSource: [
      { value: LegalEntityTypeAnswerValue.Ltd, label: i18next.t("Ltd, GmbH, B.V., Srl, Sprl, SA, OY, Sarl") },
      { value: LegalEntityTypeAnswerValue.Sas, label: i18next.t("SAS") },
      { value: LegalEntityTypeAnswerValue.Llp, label: i18next.t("LLP, GbR") },
      { value: LegalEntityTypeAnswerValue.Kg, label: i18next.t("KG, GmbH & Co.Kg") },
      { value: LegalEntityTypeAnswerValue.Ohg, label: i18next.t("OHG") }
    ],
    isMultiSelect: false,
    questionId: props.questionId,
    q11e: props.q11e,
    dispatch
  });
  return enumHook.mainTemplate;
};
