import { Answer } from "./../Answer";
export class MultiCountryAnswer extends Answer {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof MultiCountryAnswer
     */
    this.$type = "V1.CompanyInfo.Questionnaire.Answers.MultiCountryAnswer";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["CountryCodes"] && _data["CountryCodes"].constructor === Array) {
        this.CountryCodes = [];
        for (let item of _data["CountryCodes"]) {
          this.CountryCodes.push(item);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    if (this.CountryCodes && this.CountryCodes.constructor === Array) {
      _data["CountryCodes"] = [];
      for (let item of this.CountryCodes) {
        _data["CountryCodes"].push(item);
      }
    }
    return _data;
  }
}
