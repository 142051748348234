import { DateConverter } from "./../../../converters/DateConverter";
export class UpdateClientAcceptance {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof UpdateClientAcceptance
     */
    this.$type = "V1.Company.UpdateClientAcceptance";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientAcceptance = UpdateClientAcceptanceClientAcceptance[_data["ClientAcceptance"]];
      this.Completed = DateConverter.from(_data["Completed"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientAcceptance"] = UpdateClientAcceptanceClientAcceptance[this.ClientAcceptance];
    _data["Completed"] = DateConverter.toUtcDateString(this.Completed);
    return _data;
  }
}
export var UpdateClientAcceptanceClientAcceptance = /* @__PURE__ */ ((UpdateClientAcceptanceClientAcceptance2) => {
  UpdateClientAcceptanceClientAcceptance2["Pending"] = "Pending";
  UpdateClientAcceptanceClientAcceptance2["Completed"] = "Completed";
  UpdateClientAcceptanceClientAcceptance2["Failed"] = "Failed";
  UpdateClientAcceptanceClientAcceptance2["MFE"] = "MFE";
  return UpdateClientAcceptanceClientAcceptance2;
})(UpdateClientAcceptanceClientAcceptance || {});
