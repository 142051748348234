import { VatIdentificationNumber as AdminVatIdentificationNumber } from "AdminServices/APIs/self/models/V1/Common";
import { mapBankAccountConfig } from "./ServicesModels";
import {
  AddCertificateStatusChange as VRAddCertificateStatusChange,
  AddLocalTaxStatusChange as VRAddLocalTaxStatusChange,
  BackToClientStatusChange as VRBackToClientStatusChange,
  BackToGenerateDocsStatusChange as VRBackToGenerateDocsStatusChange,
  BackToTAStatusChange as VRBackToTAStatusChange,
  BackToTranslationStatusChange as VRBackToTranslationStatusChange,
  BackToAddCertificateStatusChange as VRBackToAddCertificateStatusChange,
  BackToAddLocalTaxStatusChange as VRBackToAddLocalTaxStatusChange,
  GenerateDocsStatusChange as VRGenerateDocsStatusChange,
  ReopenStatusChange as VRReopenStatusChange,
  RevokeTranslateStatusChange as VRRevokeTranslateStatusChange,
  ToTAStatusChange as VRToTAStatusChange,
  TranslateStatusChange as VRTranslateStatusChange,
  UploadDocsStatusChange as VRUploadDocsStatusChange,
  DeregistrationInProgressStatusChange as VRDeregistrationInProgressStatusChange,
  DeregisteredStatusChange as VRDeregisteredStatusChange,
  RevokingPoAStatusChange as VRRevokingPoAStatusChange,
  PoaRevokedStatusChange as VRPoaRevokedStatusChange
} from "AdminServices/APIs/self/models/V1/Company/VatRegistration";
import i18next from "i18next";
export const VAT_REG_DOCUMENT_STATUS_TRANSLATIONS = {
  None: i18next.t("None"),
  InProgress: i18next.t("In Progress"),
  Generated: i18next.t("Generated"),
  Error: i18next.t("Error")
};
export const VAT_REG_DOCUMENT_ERROR_TRANSLATIONS = {
  SOMETHING_WENT_WRONG_01: i18next.t("Something went wrong")
};
export const getFrequencyType = (frequencyType) => {
  return frequencyType === "NotAvailable" ? void 0 : frequencyType;
};
export const getVatRegistrationStatusChange = (fromStatus, toStatus, comment, registrationCompletedChange, localVatChange, deregistrationChange, poARevokedChange) => {
  const pair = `${fromStatus}-${toStatus}`;
  const factory = {
    "Pending-WaitingForSignature": () => new VRGenerateDocsStatusChange(),
    "WaitingForSignature-DocumentsSigned": () => new VRUploadDocsStatusChange(),
    "DocumentsSigned-TranslationInProgress": () => new VRTranslateStatusChange(),
    "TranslationInProgress-SentToTA": () => new VRToTAStatusChange(),
    "SentToTA-LocalTaxReceived": () => new VRAddLocalTaxStatusChange({
      LocalVatNumber: localVatChange.LocalVatNumber,
      TaxIdentifier: localVatChange.TaxIdentifier,
      LocalVatRegistrationDate: localVatChange.LocalVatRegistrationDate,
      LocalVATCertificateReferenceId: localVatChange.LocalVATCertificateReferenceId,
      PaymentReference: localVatChange.PaymentReference,
      PrimaryVATFrequencyType: getFrequencyType(localVatChange.PrimaryVATFrequencyType),
      PrimaryVATEFiling: localVatChange.PrimaryVATEFiling,
      PrimaryVATFilingExtension: localVatChange.PrimaryVATFilingExtension,
      SecondaryVATFrequencyType: getFrequencyType(localVatChange.SecondaryVATFrequencyType),
      SecondaryVATEFiling: localVatChange.SecondaryVATEFiling,
      SecondaryVATFilingExtension: localVatChange.SecondaryVATFilingExtension,
      ESLFrequencyType: getFrequencyType(localVatChange.ESLFrequencyType),
      ESLEFiling: localVatChange.ESLEFiling,
      ESLFilingExtension: localVatChange.ESLFilingExtension,
      VLSFrequencyType: getFrequencyType(localVatChange.VLSFrequencyType),
      VLSEFiling: localVatChange.VLSEFiling,
      VLSFilingExtension: localVatChange.VLSFilingExtension,
      IntrastatFrequencyType: getFrequencyType(localVatChange.IntrastatFrequencyType),
      IntrastatEFiling: localVatChange.IntrastatEFiling,
      IntrastatFilingExtension: localVatChange.IntrastatFilingExtension,
      BankAccountConfig: mapBankAccountConfig(localVatChange.BankAccountConfig),
      NotifyUser: localVatChange.sendEmail
    }),
    "LocalTaxReceived-RegistrationCompleted": () => new VRAddCertificateStatusChange({
      EUVATCertificateReferenceId: registrationCompletedChange.EUVatCertificate.EUVATCertificateReferenceId,
      EUVatNumber: new AdminVatIdentificationNumber({
        CountryCode: registrationCompletedChange.EUVatCertificate.EUVatNumber.CountryCode,
        VatNumber: registrationCompletedChange.EUVatCertificate.EUVatNumber.VatNumber
      }),
      EUVatRegistrationDate: registrationCompletedChange.EUVatCertificate.EUVatRegistrationDate,
      NotifyUser: registrationCompletedChange.sendEmail
    }),
    "RegistrationCompleted-LocalTaxReceived": () => new VRReopenStatusChange(),
    "LocalTaxReceived-SentToTA": () => new VRBackToTAStatusChange(),
    "SentToTA-TranslationInProgress": () => new VRBackToTranslationStatusChange(),
    "TranslationInProgress-DocumentsSigned": () => new VRRevokeTranslateStatusChange(),
    "DocumentsSigned-WaitingForSignature": () => new VRBackToClientStatusChange({ Comment: comment }),
    "WaitingForSignature-Pending": () => new VRBackToGenerateDocsStatusChange(),
    "LocalTaxReceived-DeregistrationInProgress": () => new VRDeregistrationInProgressStatusChange(),
    "RegistrationCompleted-DeregistrationInProgress": () => new VRDeregistrationInProgressStatusChange(),
    "Deregistered-DeregistrationInProgress": () => new VRDeregistrationInProgressStatusChange(),
    "DeregistrationInProgress-RegistrationCompleted": () => new VRBackToAddCertificateStatusChange(),
    "DeregistrationInProgress-LocalTaxReceived": () => new VRBackToAddLocalTaxStatusChange(),
    "DeregistrationInProgress-Deregistered": () => new VRDeregisteredStatusChange({
      DeregistrationDate: deregistrationChange.DateOfDeregistration,
      DeregistrationConfirmationDocumentId: deregistrationChange.DocumentId
    }),
    "Deregistered-Pending": () => new VRBackToGenerateDocsStatusChange(),
    "LocalTaxReceived-RevokingPoA": () => new VRRevokingPoAStatusChange(),
    "RegistrationCompleted-RevokingPoA": () => new VRRevokingPoAStatusChange(),
    "PoARevoked-RevokingPoA": () => new VRRevokingPoAStatusChange(),
    "RevokingPoA-RegistrationCompleted": () => new VRBackToAddCertificateStatusChange(),
    "RevokingPoA-LocalTaxReceived": () => new VRBackToAddLocalTaxStatusChange(),
    "RevokingPoA-PoARevoked": () => new VRPoaRevokedStatusChange({
      DateOfRevocation: poARevokedChange.DateOfRevocation,
      PoaRevocationConfirmationDocumentId: poARevokedChange.DocumentId
    }),
    "PoARevoked-Pending": () => new VRBackToGenerateDocsStatusChange()
  };
  return factory[pair]();
};
