import { TswagClient } from "./../commons/TswagClient";
import { Agent } from "./../models/V1/Agents/Agent";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class AdminUsers {
  /**
   * Creates an instance of AdminUsers.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof AdminUsers
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GetAdminUserRq_ _getAdminUserRq
   * @returns Promise<GetAdminUserRq_>
   * @memberof AdminUsers
   */
  getAdminUser(pGetAdminUserRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/AdminUsers",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "id", value: pGetAdminUserRq.id }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Agent },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchAdminUsersRq_ _searchAdminUsersRq
   * @returns Promise<SearchAdminUsersRq_>
   * @memberof AdminUsers
   */
  searchAdminUsers(pSearchAdminUsersRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/AdminUsers/search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchAdminUsersRq.body, isArray: true, isPrimitive: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Agent, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
