var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { getLastPathElement } from "../templateHelper";
import { EnumMappingValidator } from "../TemplateValidator";
import { setDataTransfer } from "GlobalShared/ui/dc-dropfield";
export const observedAttributes = [];
export const useShadowDOM = false;
export const name = "ww-enum-mapping";
export const Component = (host) => {
  var _a, _b, _c;
  const props = {
    enumMapping: host.enumMapping,
    actionInProgress: host.actionInProgress,
    onSave: host.onSave,
    onClear: host.onClear,
    onCancel: host.onCancel
  };
  const onSave = () => {
    setDispatchIfValid(true);
    validationContext.validateFields(["path", "pairs"]);
    setMapping(__spreadValues({}, mapping));
  };
  const [mapping, setMapping] = useState(void 0);
  const validationContext = useValidationContext(new EnumMappingValidator());
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => __async(void 0, null, function* () {
    if (dispatchIfValid) {
      setDispatchIfValid(false);
      if (validationContext.validationResult.isValid()) {
        props.onSave(mapping);
      }
    }
  }), [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(mapping);
  }, [mapping]);
  useEffect(() => {
    validationContext.clearFieldValidations();
    setMapping(props.enumMapping);
  }, [props.enumMapping]);
  return html`<div class="grid grid-cols-3 gap-4">
		<div class="col-span-2">
			${(_a = mapping == null ? void 0 : mapping.pdfFieldValues) == null ? void 0 : _a.map(
    (pdfFieldValue) => {
      var _a2;
      return html`<div class="flex flex-wrap space-x-4 items-center">
						<div class="font-bold">${pdfFieldValue}:</div>
						<div class="flex flex-wrap">
							${(_a2 = mapping == null ? void 0 : mapping.pairs) == null ? void 0 : _a2.filter((pair) => pair.dst === pdfFieldValue).map((pair) => {
        return html`<dc-dropfield
										.value=${pair.src}
										.class=${"p-2 mb-2 mr-2"}
										.removable=${true}
										.allowedDragTypes=${["TemplateDSEnumVal"]}
										.onDrop=${(e) => {
          const draggedItem = JSON.parse(e.dataTransfer.getData("payload"));
          setMapping(__spreadProps(__spreadValues({}, mapping), {
            pairs: mapping.pairs.concat({
              src: draggedItem.srcValue,
              dst: pdfFieldValue
            })
          }));
        }}
										.onRemove=${() => {
          setMapping(__spreadProps(__spreadValues({}, mapping), {
            pairs: mapping.pairs.filter((p) => p.src !== pair.src)
          }));
        }}
									></dc-dropfield>`;
      })}
							<dc-dropfield
								.class=${"p-2 mb-2 mr-2"}
								.allowedDragTypes=${["TemplateDSEnumVal"]}
								.emptyText=${"Drop value here"}
								.onDrop=${(e) => {
        const draggedItem = JSON.parse(e.dataTransfer.getData("payload"));
        setMapping(__spreadProps(__spreadValues({}, mapping), {
          pairs: mapping.pairs.concat({ src: draggedItem.srcValue, dst: pdfFieldValue })
        }));
      }}
							></dc-dropfield>
						</div>
					</div> `;
    }
  )}
			<div class="text-red-500">${validationContext.getValidationMessage("pairs")}</div>
		</div>
		<div>
			<dc-dropfield
				.label=${"Field"}
				.value=${getLastPathElement(mapping == null ? void 0 : mapping.path)}
				.validationMessage=${validationContext.getValidationMessage("path")}
				.class=${"p-2 w-1/2 mb-2"}
				.allowedDragTypes=${["TemplateDataSource"]}
				.emptyText=${"Drop field here"}
				.onDrop=${(e) => {
    const draggedfield = JSON.parse(e.dataTransfer.getData("payload"));
    validationContext.validateField("path");
    setMapping(__spreadProps(__spreadValues({}, mapping), {
      path: draggedfield.path,
      srcValues: draggedfield.field.values,
      pairs: []
    }));
  }}
			></dc-dropfield>
			<div class="flex flex-wrap mt-8">
				${(_c = (_b = mapping == null ? void 0 : mapping.srcValues) == null ? void 0 : _b.filter((srcValue) => {
    var _a2;
    return (mapping == null ? void 0 : mapping.pairs) === void 0 || ((_a2 = mapping == null ? void 0 : mapping.pairs) == null ? void 0 : _a2.find((pair) => pair.src === srcValue)) === void 0;
  })) == null ? void 0 : _c.map(
    (srcValue) => html`<div
								draggable="true"
								class="border bg-yellow-300 p-2 mr-2 mb-2"
								@dragstart=${(e) => {
      const draggedItem = {
        type: "TemplateDSEnumVal",
        srcValue
      };
      setDataTransfer(e, draggedItem);
    }}
							>
								${srcValue}
							</div>`
  )}
			</div>
		</div>

		<div class="mt-4 space-x-4 col-span-2">
			<button class="btn btn-primary" ?disabled=${props.actionInProgress} @click=${onSave}>Save</button>
			<button class="btn btn-primary" ?disabled=${props.actionInProgress} @click=${() => props.onClear(mapping)}>Clear</button>
			<button class="btn btn-primary" ?disabled=${props.actionInProgress} @click=${props.onCancel}>Cancel</button>
		</div>
	</div>`;
};
