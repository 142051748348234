export function validateRows(rows, csvType) {
  let validationErrors = [];
  for (const row of rows) {
    const tempValidationErrors = validateRow(rows, row, csvType);
    validationErrors = validationErrors.concat(tempValidationErrors);
  }
  return validationErrors;
}
function validateRow(rows, row, csvType) {
  const validationErrors = [];
  let rules = [];
  let typedRow;
  switch (csvType) {
    case "CompaniesCSV":
      rules = companysValidationRules;
      typedRow = row;
      break;
    case "SubscriptionsCSV":
      rules = subscriptionValidationRules;
      typedRow = row;
      break;
    case "VATNumberCSV":
      rules = vatNumberValidationRules;
      typedRow = row;
      break;
    case "SellerAccountsCSV":
      rules = sellerAccountValidationRules;
      typedRow = row;
      break;
    default:
      break;
  }
  rules.forEach((rule) => {
    if (!rule.isValid({ row })) {
      const error = {
        message: rule.message,
        lineNumber: rows.indexOf(typedRow)
      };
      validationErrors.push(error);
    }
  });
  return validationErrors;
}
const companysValidationRules = [
  {
    isValid: ({ row }) => row.PortalId !== 0,
    message: "Portal id is required."
  },
  {
    isValid: ({ row }) => row.LegalStatus === "Individual" ? row.DOB !== void 0 : true,
    message: "If legal status is individual then DOB is required."
  },
  {
    isValid: ({ row }) => row.ClientHasOSSReg ? row.OSSNumber !== "" : true,
    message: "If client has OSS reg then OSS number is required."
  },
  {
    isValid: ({ row }) => row.ClientHasOSSReg ? row.OSSDateFrom !== void 0 : true,
    message: "If client has OSS reg then OSS date from is required."
  },
  {
    isValid: ({ row }) => row.ClientHasOSSReg ? row.OSSCountryCode !== "" : true,
    message: "If client has OSS reg then OSS country code is required."
  }
];
const subscriptionValidationRules = [
  {
    isValid: ({ row }) => row.RegistrationStatus === "LocalTaxReceived" ? row.LocalVatNumber !== "" : true,
    message: "If registration status is local tax received then local vat number is required."
  },
  {
    isValid: ({ row }) => row.RegistrationStatus === "RegistrationCompleted" ? row.LocalVatNumber !== "" : true,
    message: "If registration status is completed then local vat number is required."
  },
  {
    isValid: ({ row }) => row.RegistrationStatus === "RegistrationCompleted" ? row.EuVatNumber !== "" : true,
    message: "If registration status is completed then eu vat number is required."
  },
  {
    isValid: ({ row }) => row.IntrastatIn || row.IntrastatOut ? row.IntrastatFrequency.toString() !== "" : true,
    message: "If intrastat in or intrastat out is true then intrastat frequency required."
  },
  {
    isValid: ({ row }) => row.PrimaryFrequency ? true : false,
    message: "Primary Frequency is required"
  }
];
const vatNumberValidationRules = [];
const sellerAccountValidationRules = [];
