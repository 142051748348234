var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { IndividualDetail as AdminIndividualDetail } from "AdminServices/APIs/self/models/V1/CompanyInfo";
import { IndividualDetail as WebIndividualDetail } from "WebServices/APIs/self/models/V1/CompanyInfo";
import { FileReferenceAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
export const getNameAsString = (name) => {
  return `${name.Title} ${name.FirstName} ${name.LastName}`;
};
export const resolveCountryName = (worldCountries, countryCode) => {
  var _a;
  const country = resolveCountry(worldCountries, countryCode);
  return (_a = country == null ? void 0 : country.Name) != null ? _a : countryCode;
};
export const resolveCountry = (worldCountries, countryCode) => {
  return worldCountries.find((country) => country.Code === countryCode);
};
export const getLegalStatus = (businessDetail) => {
  if (businessDetail instanceof WebIndividualDetail || businessDetail instanceof AdminIndividualDetail) {
    return "Individual";
  } else {
    return businessDetail.LegalStatus;
  }
};
export const getFullName = (businessDetail) => {
  if (businessDetail instanceof WebIndividualDetail || businessDetail instanceof AdminIndividualDetail) {
    return getNameAsString(businessDetail.Name);
  } else {
    return businessDetail.CompanyName;
  }
};
export const getAddressAsString = (address) => {
  return `${address.Zip}, ${address.City}, ${address.Street} ${address.StreetNumber}`;
};
export const uploadFileToQuestion = (url, file) => __async(void 0, null, function* () {
  const content = new FormData();
  content.append("file", file);
  const headers = {
    "Accept": "*/*",
    "Access-Control-Allow-Origin": ""
  };
  const options = {
    body: content,
    headers,
    method: "POST"
  };
  const response = yield fetch(url, options);
  return new FileReferenceAnswer(yield response.json());
});
