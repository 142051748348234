export class KpmgValidationMessage {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof KpmgValidationMessage
     */
    this.$type = "V1.ComplianceUploads.KpmgValidationMessage";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ColumnCode = KpmgValidationMessageColumnCode[_data["ColumnCode"]];
      this.ColumnName = KpmgValidationMessageColumnName[_data["ColumnName"]];
      this.IsWarning = _data["IsWarning"];
      if (_data["Parameters"] && _data["Parameters"].constructor === Array) {
        this.Parameters = [];
        for (let item of _data["Parameters"]) {
          this.Parameters.push(item);
        }
      }
      this.RowNumber = _data["RowNumber"];
      this.TotalCount = _data["TotalCount"];
      this.ValidationCode = KpmgValidationMessageValidationCode[_data["ValidationCode"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ColumnCode"] = KpmgValidationMessageColumnCode[this.ColumnCode];
    _data["ColumnName"] = KpmgValidationMessageColumnName[this.ColumnName];
    _data["IsWarning"] = this.IsWarning;
    if (this.Parameters && this.Parameters.constructor === Array) {
      _data["Parameters"] = [];
      for (let item of this.Parameters) {
        _data["Parameters"].push(item);
      }
    }
    _data["RowNumber"] = this.RowNumber;
    _data["TotalCount"] = this.TotalCount;
    _data["ValidationCode"] = KpmgValidationMessageValidationCode[this.ValidationCode];
    return _data;
  }
}
export var KpmgValidationMessageColumnCode = /* @__PURE__ */ ((KpmgValidationMessageColumnCode2) => {
  KpmgValidationMessageColumnCode2["A"] = "A";
  KpmgValidationMessageColumnCode2["B"] = "B";
  KpmgValidationMessageColumnCode2["C"] = "C";
  KpmgValidationMessageColumnCode2["D"] = "D";
  KpmgValidationMessageColumnCode2["E"] = "E";
  KpmgValidationMessageColumnCode2["F"] = "F";
  KpmgValidationMessageColumnCode2["G"] = "G";
  KpmgValidationMessageColumnCode2["H"] = "H";
  KpmgValidationMessageColumnCode2["I"] = "I";
  KpmgValidationMessageColumnCode2["J"] = "J";
  KpmgValidationMessageColumnCode2["K"] = "K";
  KpmgValidationMessageColumnCode2["L"] = "L";
  KpmgValidationMessageColumnCode2["M"] = "M";
  KpmgValidationMessageColumnCode2["N"] = "N";
  KpmgValidationMessageColumnCode2["O"] = "O";
  KpmgValidationMessageColumnCode2["P"] = "P";
  KpmgValidationMessageColumnCode2["Q"] = "Q";
  KpmgValidationMessageColumnCode2["R"] = "R";
  KpmgValidationMessageColumnCode2["S"] = "S";
  KpmgValidationMessageColumnCode2["T"] = "T";
  KpmgValidationMessageColumnCode2["U"] = "U";
  KpmgValidationMessageColumnCode2["V"] = "V";
  KpmgValidationMessageColumnCode2["W"] = "W";
  KpmgValidationMessageColumnCode2["X"] = "X";
  KpmgValidationMessageColumnCode2["Y"] = "Y";
  KpmgValidationMessageColumnCode2["Z"] = "Z";
  KpmgValidationMessageColumnCode2["AA"] = "AA";
  KpmgValidationMessageColumnCode2["AB"] = "AB";
  KpmgValidationMessageColumnCode2["AC"] = "AC";
  KpmgValidationMessageColumnCode2["AD"] = "AD";
  KpmgValidationMessageColumnCode2["AE"] = "AE";
  KpmgValidationMessageColumnCode2["AF"] = "AF";
  KpmgValidationMessageColumnCode2["AG"] = "AG";
  KpmgValidationMessageColumnCode2["AH"] = "AH";
  KpmgValidationMessageColumnCode2["AI"] = "AI";
  KpmgValidationMessageColumnCode2["AJ"] = "AJ";
  KpmgValidationMessageColumnCode2["AK"] = "AK";
  KpmgValidationMessageColumnCode2["AL"] = "AL";
  KpmgValidationMessageColumnCode2["AM"] = "AM";
  KpmgValidationMessageColumnCode2["AN"] = "AN";
  KpmgValidationMessageColumnCode2["AO"] = "AO";
  KpmgValidationMessageColumnCode2["AP"] = "AP";
  KpmgValidationMessageColumnCode2["AQ"] = "AQ";
  KpmgValidationMessageColumnCode2["AR"] = "AR";
  KpmgValidationMessageColumnCode2["AS"] = "AS";
  KpmgValidationMessageColumnCode2["AT"] = "AT";
  KpmgValidationMessageColumnCode2["AU"] = "AU";
  KpmgValidationMessageColumnCode2["AV"] = "AV";
  KpmgValidationMessageColumnCode2["AW"] = "AW";
  KpmgValidationMessageColumnCode2["AX"] = "AX";
  KpmgValidationMessageColumnCode2["AY"] = "AY";
  KpmgValidationMessageColumnCode2["AZ"] = "AZ";
  KpmgValidationMessageColumnCode2["BA"] = "BA";
  KpmgValidationMessageColumnCode2["BB"] = "BB";
  KpmgValidationMessageColumnCode2["BC"] = "BC";
  KpmgValidationMessageColumnCode2["BD"] = "BD";
  KpmgValidationMessageColumnCode2["BE"] = "BE";
  KpmgValidationMessageColumnCode2["BF"] = "BF";
  KpmgValidationMessageColumnCode2["BG"] = "BG";
  KpmgValidationMessageColumnCode2["BH"] = "BH";
  KpmgValidationMessageColumnCode2["BI"] = "BI";
  KpmgValidationMessageColumnCode2["BJ"] = "BJ";
  KpmgValidationMessageColumnCode2["BK"] = "BK";
  KpmgValidationMessageColumnCode2["BL"] = "BL";
  return KpmgValidationMessageColumnCode2;
})(KpmgValidationMessageColumnCode || {});
export var KpmgValidationMessageColumnName = /* @__PURE__ */ ((KpmgValidationMessageColumnName2) => {
  KpmgValidationMessageColumnName2["TransactionType"] = "TransactionType";
  KpmgValidationMessageColumnName2["TypeOfSale"] = "TypeOfSale";
  KpmgValidationMessageColumnName2["TypeOfPartner"] = "TypeOfPartner";
  KpmgValidationMessageColumnName2["VatNumber"] = "VatNumber";
  KpmgValidationMessageColumnName2["TransactionDate"] = "TransactionDate";
  KpmgValidationMessageColumnName2["InvoiceNumber"] = "InvoiceNumber";
  KpmgValidationMessageColumnName2["ShippingFrom"] = "ShippingFrom";
  KpmgValidationMessageColumnName2["CountryOfCustomer"] = "CountryOfCustomer";
  KpmgValidationMessageColumnName2["Currency"] = "Currency";
  KpmgValidationMessageColumnName2["GrossAmount"] = "GrossAmount";
  KpmgValidationMessageColumnName2["VatReportingCountry"] = "VatReportingCountry";
  KpmgValidationMessageColumnName2["VatRate"] = "VatRate";
  KpmgValidationMessageColumnName2["TaxableBasis"] = "TaxableBasis";
  KpmgValidationMessageColumnName2["VatAmount"] = "VatAmount";
  KpmgValidationMessageColumnName2["InvoiceDate"] = "InvoiceDate";
  KpmgValidationMessageColumnName2["TransactionIdentifier"] = "TransactionIdentifier";
  KpmgValidationMessageColumnName2["DateOfSupply"] = "DateOfSupply";
  KpmgValidationMessageColumnName2["PaymentDate"] = "PaymentDate";
  KpmgValidationMessageColumnName2["Consignment"] = "Consignment";
  KpmgValidationMessageColumnName2["OnlineMarketplace"] = "OnlineMarketplace";
  KpmgValidationMessageColumnName2["PostponedAccounting"] = "PostponedAccounting";
  KpmgValidationMessageColumnName2["TaxCollectionResponsibility"] = "TaxCollectionResponsibility";
  KpmgValidationMessageColumnName2["CountryOfOrigin"] = "CountryOfOrigin";
  KpmgValidationMessageColumnName2["Weight"] = "Weight";
  KpmgValidationMessageColumnName2["Unit"] = "Unit";
  KpmgValidationMessageColumnName2["Quantity"] = "Quantity";
  KpmgValidationMessageColumnName2["DepartureZipCode"] = "DepartureZipCode";
  KpmgValidationMessageColumnName2["DeliveryConditions"] = "DeliveryConditions";
  KpmgValidationMessageColumnName2["NatureOfTransaction"] = "NatureOfTransaction";
  KpmgValidationMessageColumnName2["CommodityCode"] = "CommodityCode";
  KpmgValidationMessageColumnName2["ModeOfTransport"] = "ModeOfTransport";
  KpmgValidationMessageColumnName2["ArrivalZipCode"] = "ArrivalZipCode";
  KpmgValidationMessageColumnName2["Sale_MPP"] = "Sale_MPP";
  KpmgValidationMessageColumnName2["SW"] = "SW";
  KpmgValidationMessageColumnName2["EE"] = "EE";
  KpmgValidationMessageColumnName2["TP"] = "TP";
  KpmgValidationMessageColumnName2["TT_WNT"] = "TT_WNT";
  KpmgValidationMessageColumnName2["TT_D"] = "TT_D";
  KpmgValidationMessageColumnName2["MR_T"] = "MR_T";
  KpmgValidationMessageColumnName2["MR_UZ"] = "MR_UZ";
  KpmgValidationMessageColumnName2["I_42"] = "I_42";
  KpmgValidationMessageColumnName2["I_63"] = "I_63";
  KpmgValidationMessageColumnName2["B_SPV"] = "B_SPV";
  KpmgValidationMessageColumnName2["B_SPV_DOSTWA"] = "B_SPV_DOSTWA";
  KpmgValidationMessageColumnName2["B_MPV_PROWIZJA"] = "B_MPV_PROWIZJA";
  KpmgValidationMessageColumnName2["RO"] = "RO";
  KpmgValidationMessageColumnName2["Sale_WEW"] = "Sale_WEW";
  KpmgValidationMessageColumnName2["FP"] = "FP";
  KpmgValidationMessageColumnName2["IMP"] = "IMP";
  KpmgValidationMessageColumnName2["Purchase_MPP"] = "Purchase_MPP";
  KpmgValidationMessageColumnName2["MK"] = "MK";
  KpmgValidationMessageColumnName2["VAT_RR"] = "VAT_RR";
  KpmgValidationMessageColumnName2["Purchase_WEW"] = "Purchase_WEW";
  KpmgValidationMessageColumnName2["GTU_01"] = "GTU_01";
  KpmgValidationMessageColumnName2["GTU_02"] = "GTU_02";
  KpmgValidationMessageColumnName2["GTU_03"] = "GTU_03";
  KpmgValidationMessageColumnName2["GTU_04"] = "GTU_04";
  KpmgValidationMessageColumnName2["GTU_05"] = "GTU_05";
  KpmgValidationMessageColumnName2["GTU_06"] = "GTU_06";
  KpmgValidationMessageColumnName2["GTU_07"] = "GTU_07";
  KpmgValidationMessageColumnName2["GTU_08"] = "GTU_08";
  KpmgValidationMessageColumnName2["GTU_09"] = "GTU_09";
  KpmgValidationMessageColumnName2["GTU_10"] = "GTU_10";
  KpmgValidationMessageColumnName2["GTU_11"] = "GTU_11";
  KpmgValidationMessageColumnName2["GTU_12"] = "GTU_12";
  KpmgValidationMessageColumnName2["GTU_13"] = "GTU_13";
  KpmgValidationMessageColumnName2["PartnerName"] = "PartnerName";
  return KpmgValidationMessageColumnName2;
})(KpmgValidationMessageColumnName || {});
export var KpmgValidationMessageValidationCode = /* @__PURE__ */ ((KpmgValidationMessageValidationCode2) => {
  KpmgValidationMessageValidationCode2["WrongTemplateColumns"] = "WrongTemplateColumns";
  KpmgValidationMessageValidationCode2["WrongHeader"] = "WrongHeader";
  KpmgValidationMessageValidationCode2["TaxableBasisReportingExceededTheLimit"] = "TaxableBasisReportingExceededTheLimit";
  KpmgValidationMessageValidationCode2["OutOfPeriodTransactions"] = "OutOfPeriodTransactions";
  KpmgValidationMessageValidationCode2["Required"] = "Required";
  KpmgValidationMessageValidationCode2["RequiredOrWrongFormat"] = "RequiredOrWrongFormat";
  KpmgValidationMessageValidationCode2["RequiredReportingFields"] = "RequiredReportingFields";
  KpmgValidationMessageValidationCode2["WrongExchangeRate"] = "WrongExchangeRate";
  KpmgValidationMessageValidationCode2["InvalidData"] = "InvalidData";
  KpmgValidationMessageValidationCode2["InvalidDecimal"] = "InvalidDecimal";
  KpmgValidationMessageValidationCode2["ConstraintNotFulfilled"] = "ConstraintNotFulfilled";
  KpmgValidationMessageValidationCode2["TransactionIsInTheFuture"] = "TransactionIsInTheFuture";
  KpmgValidationMessageValidationCode2["TransactionIsBeforeService"] = "TransactionIsBeforeService";
  KpmgValidationMessageValidationCode2["UnsupportedCurrency"] = "UnsupportedCurrency";
  return KpmgValidationMessageValidationCode2;
})(KpmgValidationMessageValidationCode || {});
