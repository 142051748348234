import * as redux from "redux";
import { combineReducers } from "redux-immutable";
const _ReduxContext = class {
  static dispatch(action) {
    _ReduxContext.store.dispatch(action);
  }
  static registerReducer(key, reducer) {
    _ReduxContext.reducerRegistry[key] = reducer;
  }
  static init() {
    const w = window;
    const rootReducer = combineReducers(_ReduxContext.reducerRegistry);
    _ReduxContext.store = redux.createStore(rootReducer, w.__REDUX_DEVTOOLS_EXTENSION__ && w.__REDUX_DEVTOOLS_EXTENSION__());
  }
};
export let ReduxContext = _ReduxContext;
ReduxContext.reducerRegistry = {};
