import { Money } from "./../../Common/Money";
export class PaymentInfo {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PaymentInfo
     */
    this.$type = "V1.Payments.PaymentInfo.PaymentInfo";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["AvailablePaymentTypes"] && _data["AvailablePaymentTypes"].constructor === Array) {
        this.AvailablePaymentTypes = [];
        for (let item of _data["AvailablePaymentTypes"]) {
          this.AvailablePaymentTypes.push(PaymentInfoAvailablePaymentTypes[item]);
        }
      }
      if (_data["TotalPrice"]) {
        const totalPrice_ = new Money();
        this.TotalPrice = totalPrice_.init(_data["TotalPrice"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.AvailablePaymentTypes && this.AvailablePaymentTypes.constructor === Array) {
      _data["AvailablePaymentTypes"] = [];
      for (let item of this.AvailablePaymentTypes) {
        _data["AvailablePaymentTypes"].push(PaymentInfoAvailablePaymentTypes[item]);
      }
    }
    _data["TotalPrice"] = this.TotalPrice ? this.TotalPrice.toJSON() : void 0;
    return _data;
  }
}
export var PaymentInfoAvailablePaymentTypes = /* @__PURE__ */ ((PaymentInfoAvailablePaymentTypes2) => {
  PaymentInfoAvailablePaymentTypes2["BankTransferPayment"] = "BankTransferPayment";
  PaymentInfoAvailablePaymentTypes2["PayPalPayment"] = "PayPalPayment";
  return PaymentInfoAvailablePaymentTypes2;
})(PaymentInfoAvailablePaymentTypes || {});
