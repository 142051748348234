var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { getEUVatChangeFields, EuVatChangeValidator } from "./EuVatChangeValidator";
import { DEAFULT_MIN_DATE } from "GlobalShared/common/Constants";
import { ONLY_PDF_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
export const useVatRegEuVatModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [regCompletedChange, setRegCompletedChange] = useState(void 0);
  const regCompletedValidationContext = useValidationContext(new EuVatChangeValidator());
  const [dispatchIfRegCompletedIsValid, setDispatchIfRegCompletedIsValid] = useState(false);
  const show = () => {
    setShowModal(true);
  };
  useEffect(() => __async(void 0, null, function* () {
    var _a, _b;
    if (showModal) {
      const euVatCertificate = __spreadProps(__spreadValues({}, props == null ? void 0 : props.euVatCertificate), {
        EUVatRegistrationDate: ((_a = props == null ? void 0 : props.euVatCertificate) == null ? void 0 : _a.EUVatRegistrationDate) <= DEAFULT_MIN_DATE ? /* @__PURE__ */ new Date() : (_b = props == null ? void 0 : props.euVatCertificate) == null ? void 0 : _b.EUVatRegistrationDate
      });
      setRegCompletedChange(__spreadProps(__spreadValues({}, regCompletedChange), {
        EUVatCertificate: euVatCertificate,
        sendEmail: true
      }));
      regCompletedValidationContext.clearFieldValidations();
    }
  }), [showModal]);
  const save = () => __async(void 0, null, function* () {
    setRegCompletedChange(__spreadValues({}, regCompletedChange));
    regCompletedValidationContext.validateFields(getEUVatChangeFields());
    setDispatchIfRegCompletedIsValid(true);
  });
  useEffect(() => __async(void 0, null, function* () {
    if (dispatchIfRegCompletedIsValid) {
      setDispatchIfRegCompletedIsValid(false);
      if (regCompletedValidationContext.validationResult.isValid()) {
        yield props.save(regCompletedChange);
        setShowModal(false);
      }
    }
  }), [regCompletedValidationContext.validationResult]);
  useEffect(() => {
    if (regCompletedChange !== void 0) {
      regCompletedValidationContext.validate(regCompletedChange);
    }
  }, [regCompletedChange]);
  const htmlEUVatNumberAndReg = () => {
    var _a, _b;
    return html`<div class="md:flex md:space-x-8">
			<div class="md:w-1/2">
				<label class="form-label">EU VAT number</label>
				<div class="flex">
					<dc-input class="w-16" .value=${(props == null ? void 0 : props.serviceCountryCode) === "GR" ? "EL" : props.serviceCountryCode} .readonly=${true}></dc-input>
					<dc-input
						.value=${(_b = (_a = regCompletedChange.EUVatCertificate) == null ? void 0 : _a.EUVatNumber) == null ? void 0 : _b.VatNumber}
						.validationMessage=${regCompletedValidationContext.getValidationMessage("EUVatNumber")}
						@change=${(e) => {
      regCompletedValidationContext.validateField("EUVatNumber");
      setRegCompletedChange(__spreadProps(__spreadValues({}, regCompletedChange), {
        EUVatCertificate: __spreadProps(__spreadValues({}, regCompletedChange.EUVatCertificate), {
          EUVatNumber: {
            CountryCode: props.serviceCountryCode,
            VatNumber: e.detail.value
          }
        })
      }));
    }}
					></dc-input>
				</div>
			</div>
			<dc-datepicker
				class="md:w-1/2"
				.label=${"EU VAT registration date"}
				.value=${regCompletedChange.EUVatCertificate.EUVatRegistrationDate}
				.validationMessage=${regCompletedValidationContext.getValidationMessage("EUVatRegistrationDate")}
				@change=${(e) => {
      regCompletedValidationContext.validateField("EUVatRegistrationDate");
      setRegCompletedChange(__spreadProps(__spreadValues({}, regCompletedChange), {
        EUVatCertificate: __spreadProps(__spreadValues({}, regCompletedChange.EUVatCertificate), {
          EUVatRegistrationDate: e.detail.value
        })
      }));
    }}
			></dc-datepicker>
		</div>`;
  };
  const template = () => {
    var _a;
    if (!showModal || regCompletedChange === void 0) {
      return html``;
    }
    return html`<div>
			<dc-modal
				.visible=${showModal}
				@close=${() => setShowModal(false)}
				.header=${"Add EU VAT certificate"}
				.content=${html`<div>
					<div class="space-y-4 md:w-1/2">
						<div>
							<dc-fileupload
								.label=${"EU VAT certificate"}
								.fileName=${void 0}
								.fileReference=${(_a = regCompletedChange.EUVatCertificate) == null ? void 0 : _a.EUVATCertificateReferenceId}
								.validationMessage=${regCompletedValidationContext.getValidationMessage("EUVatCertificate")}
								.accept=${ONLY_PDF_ACCEPT_LIST}
								@upload=${(e) => __async(void 0, null, function* () {
      var _a2;
      if (((_a2 = e.detail) == null ? void 0 : _a2.files) && e.detail.files.length > 0) {
        setRegCompletedChange(__spreadProps(__spreadValues({}, regCompletedChange), {
          EUVatCertificate: __spreadProps(__spreadValues({}, regCompletedChange.EUVatCertificate), {
            EUVATCertificateReferenceId: yield props.uploadEuVatCertificate(e.detail.files[0])
          })
        }));
      }
    })}
							></dc-fileupload>
						</div>
						<div>${htmlEUVatNumberAndReg()}</div>
						<dc-checkbox
							.checked=${regCompletedChange.sendEmail}
							.label=${"Send email"}
							}
							@change=${(e) => {
      setRegCompletedChange(__spreadProps(__spreadValues({}, regCompletedChange), {
        sendEmail: e.detail.checked
      }));
    }}
						></dc-checkbox>
					</div>
					<div class="flex space-x-4 mt-24">
						<button class="btn btn-primary" @click=${() => setShowModal(false)}>Cancel</button>
						<button
							class="btn btn-primary"
							@click=${() => __async(void 0, null, function* () {
      yield save();
    })}
						>
							Save
						</button>
					</div>
				</div>`}
			>
			</dc-modal>
		</div>`;
  };
  return { show, template };
};
