import i18next from "i18next";
export const SkuFieldNames = {
  SkuId: "SkuId",
  SellerEntityId: "SellerEntityId",
  CommodityCode: "CommodityCode",
  ItemIdentifier: "ItemIdentifier",
  Price: "Price",
  Actions: "Actions"
};
export const SupportedSkuCurrencies = ["EUR", "GBP", "CZK", "PLN", "SEK", "DKK", "RON", "USD", "CNY", "HUF"];
export const SupportedSkuCurrencyDataSource = SupportedSkuCurrencies.map((ac) => ({
  value: ac,
  label: ac
}));
export const mapSearchResult = (searchResult) => {
  const result = {
    skus: searchResult.Skus.map((s) => {
      const sku = {
        Id: s.Id,
        SkuId: s.SkuId,
        SellerEntityId: s.SellerEntityId,
        CommodityCode: s.CommodityCode,
        ItemIdentifier: s.ItemIdentifier,
        Price: s.Price
      };
      return sku;
    }),
    total: searchResult.Count
  };
  return result;
};
const COLUMN_NAME_MAPPING = {
  Id: "Id",
  SellerEntityId: "Seller account",
  CommodityCode: "Commodity code",
  ItemIdentifier: "Item identifier",
  Price: "Price",
  Currency: "Currency"
};
export const mapSkuValidationMessage = (validationMessages) => {
  return validationMessages.map((msg) => {
    const vm = {
      columnName: msg.ColumnName,
      parameters: msg.Parameters,
      rowNumber: msg.RowNumber,
      validationCode: msg.ValidationCode
    };
    return vm;
  });
};
export const getLocalizedValidationMessage = (validationMessage) => {
  if (validationMessage.validationCode === "Required") {
    return i18next.t(`{{columnName}} ({{rowNumber}}): Data cannot be empty.`, {
      columnName: COLUMN_NAME_MAPPING[validationMessage.columnName],
      rowNumber: validationMessage.rowNumber
    });
  } else if (validationMessage.validationCode === "RequiredOrWrongFormat") {
    return i18next.t(`{{columnName}} ({{rowNumber}}): Wrong format`, {
      columnName: COLUMN_NAME_MAPPING[validationMessage.columnName],
      rowNumber: validationMessage.rowNumber
    });
  } else if (validationMessage.validationCode === "NotFound") {
    return i18next.t(`{{columnName}} ({{rowNumber}}): Not found`, {
      columnName: COLUMN_NAME_MAPPING[validationMessage.columnName],
      rowNumber: validationMessage.rowNumber
    });
  } else {
    return i18next.t(`{{columnName}} ({{rowNumber}}): Invalid`, {
      columnName: COLUMN_NAME_MAPPING[validationMessage.columnName],
      rowNumber: validationMessage.rowNumber
    });
  }
};
export const mapGtuCodes = (gtuCodes) => {
  return gtuCodes.map((gc) => {
    const vm = {
      id: gc.Id,
      name: gc.Name
    };
    return vm;
  });
};
export const GTU_CODES = {
  GTU1: i18next.t("GTU1"),
  GTU2: i18next.t("GTU2"),
  GTU3: i18next.t("GTU3"),
  GTU4: i18next.t("GTU4"),
  GTU5: i18next.t("GTU5"),
  GTU6: i18next.t("GTU6"),
  GTU7: i18next.t("GTU7"),
  GTU8: i18next.t("GTU8"),
  GTU9: i18next.t("GTU9"),
  GTU10: i18next.t("GTU10"),
  GTU11: i18next.t("GTU11"),
  GTU12: i18next.t("GTU12"),
  GTU13: i18next.t("GTU13")
};
