var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { sluggify } from "GlobalShared/sluggifier";
import RcsRoute from "route-parser";
export const getParameters = (prefix) => {
  const params = /* @__PURE__ */ new Map();
  const shouldAddAll = !prefix;
  const paramList = getSearchParamList(window.location.search);
  paramList.forEach((entry) => {
    const isPrefixedTemp = isPrefixed(entry[0], prefix);
    if (entry[0] && (shouldAddAll || isPrefixedTemp)) {
      const key = isPrefixedTemp ? getKeyWithoutPrefix(entry[0], prefix) : entry[0];
      params.set(key, decodeURI(entry[1]));
    }
  });
  return params;
};
export const hasParameter = (key, prefix) => {
  const keyToSearch = getKeyWithPrefix(key, prefix);
  return getParameters().has(keyToSearch);
};
export const getParameter = (key, prefix) => {
  const keyToSearch = getKeyWithPrefix(key, prefix);
  return hasParameter(keyToSearch) ? getParameters().get(keyToSearch) : void 0;
};
export const getUrlParams = (search) => {
  const hashes = search.slice(search.indexOf("?") + 1).split("&");
  const params = {};
  hashes.map((hash) => {
    const [key, val] = hash.split("=");
    params[key] = decodeURIComponent(val);
  });
  return params;
};
const handlers = /* @__PURE__ */ new Map();
let currentParams;
let currentRoute;
let routesJson;
export const notifyHandlers = (location, event) => {
  handlers.forEach((handler) => {
    handler(location, event);
  });
};
export const addHandler = (name, locationUpdatedCallback) => {
  handlers.set(name, locationUpdatedCallback);
};
export const navigateTo = (newRoute, title) => {
  const url = `${newRoute.startsWith("/") ? "" : "/"}${newRoute}`;
  window.history.pushState({}, title, url);
  notifyHandlers(window.location);
};
export const replaceTo = (newRoute, title) => {
  const url = `${newRoute.startsWith("/") ? "" : "/"}${newRoute}`;
  window.history.replaceState({}, title, url);
  notifyHandlers(window.location);
};
export const updateParameter = (key, value, prefix) => {
  if (key !== void 0 && value !== void 0) {
    updateParameters(/* @__PURE__ */ new Map([[key, value]]), prefix);
  }
};
export const updateParameters = (map, prefix) => {
  if (map) {
    const newParams = getParameters();
    for (const entry of map) {
      const keyToUpdate = getKeyWithPrefix(entry[0], prefix);
      if (keyToUpdate) {
        newParams.set(keyToUpdate, entry[1]);
      }
    }
    replaceTo(toUri(newParams));
  }
};
export const removeParameters = (keys, prefix) => {
  if (keys) {
    const newParams = getParameters();
    for (const key of keys) {
      const keyToRemove = getKeyWithPrefix(key, prefix);
      if (keyToRemove) {
        newParams.delete(keyToRemove);
      }
    }
    replaceTo(toUri(newParams));
  }
};
export const removeParameter = (key, prefix) => {
  if (key) {
    removeParameters([key], prefix);
  }
};
export const getCurrentFullPath = () => {
  return `${window.location.pathname}${window.location.search}${window.location.hash}`;
};
export const removeOptionals = (localizedUrl) => {
  return localizedUrl.replace(/(\(.*\))/, "");
};
export const getRouteElement = (name) => {
  return currentParams[name.toLocaleLowerCase()];
};
export const getDynamicRoutesByKey = (key) => {
  return routesJson.Routes.Items.filter((route) => route.key === key);
};
export const startRouting = (pRoutesJson, routeFilter) => {
  routesJson = pRoutesJson;
  const routes = getDynamicRoutes(routeFilter);
  const root = document.querySelector(".router-mount-point");
  installHandler(root, routes, (route, params) => {
    currentParams = params;
    if (route && route === currentRoute) {
      return;
    }
    currentRoute = route;
    if (!root) {
      return;
    }
    if (route === void 0) {
      const div = document.createElement("div");
      div.innerHTML = `<div class="m-8">Page not found</div>`;
      while (root.firstChild) {
        root.removeChild(root.firstChild);
      }
      root.appendChild(div);
    } else if (route.tag instanceof Function) {
      route.tag(route.path);
    } else {
      if (!route.type || route.type === "wc") {
        while (root.firstChild) {
          root.removeChild(root.firstChild);
        }
        root.appendChild(document.createElement(route.tag));
      }
    }
  });
};
const getSearchParamList = (search = ``) => {
  const hashes = search.slice(search.indexOf(`?`) + 1).split(`&`);
  return hashes.reduce((acc, hash) => {
    const [key, val] = hash.split(`=`);
    acc.push([key, decodeURIComponent(val)]);
    return acc;
  }, []);
};
const toUri = (params) => {
  let uri = `${window.location.pathname}`;
  const query = toQueryString(params);
  if (query && query.length > 0) {
    uri += encodeURI(`?${query}`);
  }
  return uri;
};
const getKeyWithPrefix = (key, prefix) => {
  if (!key) {
    return void 0;
  }
  return prefix ? `${prefix}_${key}` : key;
};
const getKeyWithoutPrefix = (key, prefix) => {
  if (!key) {
    return void 0;
  }
  const prefixToRemove = `${prefix}_`;
  return prefix ? key.replace(prefixToRemove, "") : key;
};
const isPrefixed = (key, prefix) => {
  if (!key || !prefix) {
    return false;
  }
  return key.startsWith(`${prefix}_`);
};
const toQueryString = (params) => {
  const items = [];
  for (const entry of params.entries()) {
    const key = entry[0];
    const value = entry[1];
    if (key !== void 0 && value !== void 0) {
      items.push(`${key}=${value}`);
    }
  }
  return items.join("&");
};
const installHandler = (root, pRoutes, handler) => {
  const routes = pRoutes.map((pRoute) => __spreadProps(__spreadValues({}, pRoute), {
    rcsRoute: new RcsRoute(pRoute.path)
  }));
  const locationUpdatedCallback = (location, _event) => {
    const { route, params } = matchRoute(routes, location);
    handler(route, params);
  };
  installRouter(root, locationUpdatedCallback);
};
const installRouter = (root, locationUpdatedCallback) => {
  addHandler("router34892823", locationUpdatedCallback);
  (root ? root : document.body).addEventListener("click", (e) => {
    if (e.defaultPrevented || e.button !== 0 || e.metaKey || e.ctrlKey || e.shiftKey) {
      return;
    }
    const anchor = e.composedPath().filter((n) => n.tagName === "A")[0];
    if (!anchor || anchor.target || anchor.hasAttribute("download") || anchor.getAttribute("rel") === "external" || anchor.dataset.routing && anchor.dataset.routing === "server") {
      return;
    }
    const href = anchor.href;
    if (!href || href.indexOf("mailto:") !== -1) {
      return;
    }
    const location = window.location;
    const origin = location.origin || `${location.protocol}//${location.host}`;
    if (href.indexOf(origin) !== 0) {
      return;
    }
    e.preventDefault();
    if (href !== location.href) {
      window.history.pushState({}, "", href);
      notifyHandlers(window.location, e);
    }
  });
  window.addEventListener("popstate", (e) => notifyHandlers(window.location, e));
  locationUpdatedCallback(window.location);
};
const getDynamicRoutes = (routeFilter) => {
  return routesJson.Routes.Items.filter(routeFilter).reduce((aggr, route) => {
    return aggr.concat(
      route.trans.map((trans) => {
        const [lang, country] = trans.locale.split("-");
        return {
          path: `/${country.toLowerCase()}/${lang}/${sluggify(trans.value)}${route.routePostfix ? route.routePostfix : ""}`,
          tag: route.tag
        };
      })
    );
  }, []);
};
const matchRoute = (routes, location) => {
  for (const route of routes) {
    const path = `${location.pathname}${location.search}${location.hash}`;
    const params = route.rcsRoute.match(path);
    if (params) {
      return { route, params };
    }
  }
  return { route: void 0, params: void 0 };
};
