import { DateConverter } from "./../../../../../converters/DateConverter";
export class BusinessOwner {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof BusinessOwner
     */
    this.$type = "V1.CompanyInfo.Questionnaire.BusinessOwner.BusinessOwner";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.DateOfBirth = DateConverter.from(_data["DateOfBirth"]);
      this.DateOfIncorporation = DateConverter.from(_data["DateOfIncorporation"]);
      this.DocumentNumber = _data["DocumentNumber"];
      this.DocumentType = BusinessOwnerDocumentType[_data["DocumentType"]];
      this.FirstName = _data["FirstName"];
      this.FullName = _data["FullName"];
      this.LastName = _data["LastName"];
      this.RegistrationNumber = _data["RegistrationNumber"];
      this.ShareholderType = BusinessOwnerShareholderType[_data["ShareholderType"]];
      this.SharePercentage = _data["SharePercentage"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["DateOfBirth"] = DateConverter.toUtcDateString(this.DateOfBirth);
    _data["DateOfIncorporation"] = DateConverter.toUtcDateString(this.DateOfIncorporation);
    _data["DocumentNumber"] = this.DocumentNumber;
    _data["DocumentType"] = BusinessOwnerDocumentType[this.DocumentType];
    _data["FirstName"] = this.FirstName;
    _data["FullName"] = this.FullName;
    _data["LastName"] = this.LastName;
    _data["RegistrationNumber"] = this.RegistrationNumber;
    _data["ShareholderType"] = BusinessOwnerShareholderType[this.ShareholderType];
    _data["SharePercentage"] = this.SharePercentage;
    return _data;
  }
}
export var BusinessOwnerDocumentType = /* @__PURE__ */ ((BusinessOwnerDocumentType2) => {
  BusinessOwnerDocumentType2["Passport"] = "Passport";
  BusinessOwnerDocumentType2["PersonalId"] = "PersonalId";
  BusinessOwnerDocumentType2["DrivingLicense"] = "DrivingLicense";
  return BusinessOwnerDocumentType2;
})(BusinessOwnerDocumentType || {});
export var BusinessOwnerShareholderType = /* @__PURE__ */ ((BusinessOwnerShareholderType2) => {
  BusinessOwnerShareholderType2["Company"] = "Company";
  BusinessOwnerShareholderType2["NaturalPerson"] = "NaturalPerson";
  return BusinessOwnerShareholderType2;
})(BusinessOwnerShareholderType || {});
