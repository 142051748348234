import { Money } from "./../Common/Money";
export class AddSku {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof AddSku
     */
    this.$type = "V1.ComplianceMasterData.AddSku";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CommodityCode = _data["CommodityCode"];
      this.ItemIdentifier = _data["ItemIdentifier"];
      if (_data["Price"]) {
        const price_ = new Money();
        this.Price = price_.init(_data["Price"]);
      }
      this.SellerEntityId = _data["SellerEntityId"];
      this.SkuId = _data["SkuId"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CommodityCode"] = this.CommodityCode;
    _data["ItemIdentifier"] = this.ItemIdentifier;
    _data["Price"] = this.Price ? this.Price.toJSON() : void 0;
    _data["SellerEntityId"] = this.SellerEntityId;
    _data["SkuId"] = this.SkuId;
    return _data;
  }
}
