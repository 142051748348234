export class CompanyTemplateField {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CompanyTemplateField
     */
    this.$type = "V1.TemplateMapping.TemplateSearch.CompanyTemplateField";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Order = CompanyTemplateFieldOrder[_data["Order"]];
      this.OrderByField = CompanyTemplateFieldOrderByField[_data["OrderByField"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Order"] = CompanyTemplateFieldOrder[this.Order];
    _data["OrderByField"] = CompanyTemplateFieldOrderByField[this.OrderByField];
    return _data;
  }
}
export var CompanyTemplateFieldOrder = /* @__PURE__ */ ((CompanyTemplateFieldOrder2) => {
  CompanyTemplateFieldOrder2["Ascending"] = "Ascending";
  CompanyTemplateFieldOrder2["Descending"] = "Descending";
  return CompanyTemplateFieldOrder2;
})(CompanyTemplateFieldOrder || {});
export var CompanyTemplateFieldOrderByField = /* @__PURE__ */ ((CompanyTemplateFieldOrderByField2) => {
  CompanyTemplateFieldOrderByField2["Name"] = "Name";
  CompanyTemplateFieldOrderByField2["Culture"] = "Culture";
  CompanyTemplateFieldOrderByField2["Trigger"] = "Trigger";
  CompanyTemplateFieldOrderByField2["TemplateStatus"] = "TemplateStatus";
  return CompanyTemplateFieldOrderByField2;
})(CompanyTemplateFieldOrderByField || {});
