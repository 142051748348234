import * as Immutable from "immutable";
import { createAction, createReducer } from "redux-act";
import { ReduxContext } from "GlobalShared/ReduxContext";
import { nameof } from "GlobalShared/common/Nameof";
const defaultState = {
  notifications: []
};
const _DcNotifierReducer = class {
  static getPath(key) {
    return `${_DcNotifierReducer.REDUCER_KEY}.${key}`;
  }
};
export let DcNotifierReducer = _DcNotifierReducer;
DcNotifierReducer.REDUCER_KEY = "dc-notifier";
DcNotifierReducer.ADD_NOTIFICATION = "DcNotifierReducer::ADD_NOTIFICATION";
DcNotifierReducer.CHANGE_NOTIFICATION = "DcNotifierReducer::CHANGE_NOTIFICATION";
DcNotifierReducer.REMOVE_NOTIFICATION = "DcNotifierReducer::REMOVE_NOTIFICATION";
DcNotifierReducer.addNotification = createAction(_DcNotifierReducer.ADD_NOTIFICATION);
DcNotifierReducer.changeNotifications = createAction(_DcNotifierReducer.CHANGE_NOTIFICATION);
DcNotifierReducer.removeNotification = createAction(_DcNotifierReducer.REMOVE_NOTIFICATION);
ReduxContext.registerReducer(
  DcNotifierReducer.REDUCER_KEY,
  createReducer(
    {
      [DcNotifierReducer.addNotification.toString()]: (state, item) => {
        const newList = [...state.get(nameof("notifications")), item];
        return state.updateIn([nameof("notifications")], (_) => Immutable.fromJS(newList));
      },
      [DcNotifierReducer.changeNotifications.toString()]: (state, mapper) => {
        return state.updateIn([nameof("notifications")], (notifications) => Immutable.fromJS(notifications.toJS().map(mapper)));
      },
      [DcNotifierReducer.removeNotification.toString()]: (state, id) => {
        return state.updateIn([nameof("notifications")], (notifications) => Immutable.fromJS(notifications.toJS().filter((note) => note.id !== id)));
      }
    },
    Immutable.fromJS(defaultState)
  )
);
