export const getPrimaryVatFrequencyOptions = (submissionConfig, serviceCountryCode, primaryVATFrequencyType) => {
  const primaryVatEFilingOptions = getEFilingOptions(submissionConfig, serviceCountryCode, "PrimaryVAT", primaryVATFrequencyType);
  const primaryVatFilingExtensionOptions = getFilingExtensionOptions(submissionConfig, serviceCountryCode, "PrimaryVAT", primaryVATFrequencyType);
  return {
    primaryVatEFilingOptions,
    primaryVatFilingExtensionOptions
  };
};
export const getSecondaryVatFrequencyOptions = (submissionConfig, serviceCountryCode, secondaryVATFrequencyType) => {
  const secondaryVatEFilingOptions = getEFilingOptions(submissionConfig, serviceCountryCode, "SecondaryVAT", secondaryVATFrequencyType);
  const secondaryVatFilingExtensionOptions = getFilingExtensionOptions(submissionConfig, serviceCountryCode, "SecondaryVAT", secondaryVATFrequencyType);
  return {
    secondaryVatEFilingOptions,
    secondaryVatFilingExtensionOptions
  };
};
export const getVlsFrequencyOptions = (submissionConfig, serviceCountryCode, vlsFrequencyType) => {
  const vlsEFilingOptions = getEFilingOptions(submissionConfig, serviceCountryCode, "VLS", vlsFrequencyType);
  const vlsFilingExtensionOptions = getFilingExtensionOptions(submissionConfig, serviceCountryCode, "VLS", vlsFrequencyType);
  return {
    vlsEFilingOptions,
    vlsFilingExtensionOptions
  };
};
export const getEslFrequencyOptions = (submissionConfig, serviceCountryCode, eslFrequencyType) => {
  const eslEFilingOptions = getEFilingOptions(submissionConfig, serviceCountryCode, "ESL", eslFrequencyType);
  const eslFilingExtensionOptions = getFilingExtensionOptions(submissionConfig, serviceCountryCode, "ESL", eslFrequencyType);
  return {
    eslEFilingOptions,
    eslFilingExtensionOptions
  };
};
export const getIntrastatFrequencyOptions = (submissionConfig, serviceCountryCode, intrastatFrequencyType) => {
  const intrastatEFilingOptions = getEFilingOptions(submissionConfig, serviceCountryCode, "Intrastat", intrastatFrequencyType);
  const intrastatFilingExtensionOptions = getFilingExtensionOptions(submissionConfig, serviceCountryCode, "Intrastat", intrastatFrequencyType);
  return {
    intrastatEFilingOptions,
    intrastatFilingExtensionOptions
  };
};
export const getFrequencyDataSource = (submissionConfig, serviceCountryCode, returnType) => {
  var _a;
  if (submissionConfig === void 0) {
    return [];
  }
  return (_a = submissionConfig.Frequencies) == null ? void 0 : _a.filter((freq) => freq.ReturnType === returnType && freq.CountryCode === serviceCountryCode).reduce((aggr, curr) => {
    if (!aggr.includes(curr.FrequencyType)) {
      return aggr.concat([curr.FrequencyType]);
    } else {
      return aggr;
    }
  }, []);
};
export const canHavePaymentReference = (countryCode) => {
  return ["BE", "FI", "SE", "DE"].includes(countryCode);
};
const getEFilingOptions = (submissionConfig, serviceCountryCode, returnType, frequencyType) => {
  if (submissionConfig === void 0) {
    return [];
  }
  return submissionConfig.Frequencies.filter((f) => f.CountryCode === serviceCountryCode && f.ReturnType === returnType && f.FrequencyType === frequencyType).reduce((aggr, curr) => {
    if (curr.EFiling && !aggr.includes("Yes")) {
      return aggr.concat(["Yes"]);
    }
    if (!curr.EFiling && !aggr.includes("No")) {
      return aggr.concat(["No"]);
    }
    return aggr;
  }, []);
};
const getFilingExtensionOptions = (submissionConfig, serviceCountryCode, returnType, frequencyType) => {
  if (submissionConfig === void 0) {
    return [];
  }
  return submissionConfig.Frequencies.filter((f) => f.CountryCode === serviceCountryCode && f.ReturnType === returnType && f.FrequencyType === frequencyType).reduce((aggr, curr) => {
    if (curr.FilingExtension && !aggr.includes("Yes")) {
      return aggr.concat(["Yes"]);
    }
    if (!curr.FilingExtension && !aggr.includes("No")) {
      return aggr.concat(["No"]);
    }
    return aggr;
  }, []);
};
