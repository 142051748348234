export const ACCESS_NON_EU_ESTABLISHMENT_COUNTRY_ONLY_ROLE_NAME = "accessnoneuestablishmentcountryonly";
export const ACCESS_ALL_ESTABLISHMENT_COUNTRY_ROLE_NAME = "accessallestablishmentcountry";
export const ROLE_NAME_LABEL_MAPPING = {
  superadmin: "Superadmin",
  accessallestablishmentcountry: "Access all countries",
  countrylead: "Country Lead",
  reviewer: "Reviewer",
  fiscalrepresentative: "Fiscal Representative",
  preparer: "Preparer",
  accessnoneuestablishmentcountryonly: "Access only non-EU establishment countries",
  teamlead: "Team Lead",
  coordinator: "Coordinator",
  accountmanager: "Account Manager"
};
export const withoutEstablishmentCountryRoles = (roles) => {
  return roles.filter((r) => r !== ACCESS_ALL_ESTABLISHMENT_COUNTRY_ROLE_NAME && r !== ACCESS_NON_EU_ESTABLISHMENT_COUNTRY_ONLY_ROLE_NAME);
};
