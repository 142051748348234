var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { BeneficialOwnersAnswer as WebBeneficialOwnerAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { BeneficialOwnersAnswer as AdminBeneficialOwnerAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { newUniqueId } from "GlobalShared/helpers/stringHelper";
import i18next from "i18next";
import { BeneficialOwner as WebBeneficialOwner } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/BeneficialOwner";
import { BeneficialOwner as AdminBeneficialOwner } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/BeneficialOwner";
import { BeneficialOwnersValidator, BeneficialOwnerValidator } from "./AnswerValidators";
import { templateTitle } from "GlobalShared/templates/commons";
import { mapBooleanToState, mapStateToBoolean } from "GlobalShared/helpers/booleanHelper";
import { Address as AdminAddress } from "AdminServices/APIs/self/models/V1/Common";
import { Address } from "WebServices/APIs/self/models/V1/Common";
import { BeneficialOwnerBeneficialOwnerType } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/BeneficialOwner/BeneficialOwnerAbstraction";
import { checkAnswerDate } from "./answer-helpers";
export const observedAttributes = ["q11e", "questionId", "worldCountries"];
export const useShadowDOM = false;
export const name = "ww-answer-beneficial-owners";
export class BeneficialOwnersChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
const DATA_MANAGEMENT_CONSENT = [
  { label: i18next.t("Agree"), value: "Agree" },
  { label: i18next.t("Do not agree"), value: "DoNotAgree" }
];
const BENEFICIAL_OWNER_TYPES = [
  {
    label: i18next.t("The business has a natural person as beneficial owner"),
    value: "NaturalPerson"
  },
  {
    label: i18next.t("The executive officer of the business must be considered as the beneficial owner"),
    value: "ExecutiveOfficer"
  }
];
const TYPE_OF_OWNERSHIP = [
  {
    label: i18next.t("Owns at least 25% of the business or the voting rights"),
    value: "VotingRights"
  },
  {
    label: i18next.t("Has decisive influence in the business"),
    value: "DecisiveInfluence"
  }
];
export const DEFAULT_BENEFICIAL_OWNER = {
  Id: void 0,
  FirstName: "",
  LastName: "",
  BirthName: "",
  AddressCountry: void 0,
  Address: new Address({
    City: "",
    Street: "",
    StreetNumber: "",
    Zip: ""
  }),
  IsPoliticallyExposed: void 0,
  IsRelativeToPoliticallyExposed: void 0,
  IsAssociateToPoliticallyExposed: void 0,
  BeneficialOwnerType: void 0,
  DataManagement: void 0,
  CountryOfBirth: void 0,
  DateOfBirth: void 0,
  ExtentOfOwnership: void 0,
  Nationality: void 0,
  TypeOfOwnership: void 0
};
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    worldCountries: host.worldCountries
  };
  const getLabel = (fieldName, required = false) => {
    var _a;
    return ((_a = getSubQuestion(fieldName)) == null ? void 0 : _a.Label) + (required ? "*" : "");
  };
  const getSubQuestion = (questionId) => {
    const filteredSubQuestions = question.SubQuestions.filter((subQuestion) => subQuestion.Id === questionId);
    if (filteredSubQuestions.length === 1) {
      return filteredSubQuestions[0];
    } else {
      return void 0;
    }
  };
  const initForm = () => {
    beneficialOwnerValidationContext.clearFieldValidations();
  };
  const getAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminBeneficialOwnerAnswer({
        Value: beneficialOwners.map(
          (vm) => {
            var _a2, _b, _c, _d;
            return new AdminBeneficialOwner(__spreadProps(__spreadValues({}, vm), {
              Address: new AdminAddress({
                City: (_a2 = vm.Address) == null ? void 0 : _a2.City,
                Street: (_b = vm.Address) == null ? void 0 : _b.Street,
                StreetNumber: (_c = vm.Address) == null ? void 0 : _c.StreetNumber,
                Zip: (_d = vm.Address) == null ? void 0 : _d.Zip
              })
            }));
          }
        ),
        QuestionId: props.questionId
      });
    }
    return new WebBeneficialOwnerAnswer({
      Value: beneficialOwners.map((beneficialOwnerViewModel) => new WebBeneficialOwner(__spreadValues({}, beneficialOwnerViewModel))),
      QuestionId: props.questionId
    });
  };
  const editBeneficialOwner = (id) => {
    initForm();
    setCurrentBeneficialOwner(beneficialOwners.filter((beneficialOwner) => beneficialOwner.Id === id)[0]);
  };
  const removeBeneficialOwner = (id) => {
    setDispatchIfValid(true);
    setBeneficialOwners(beneficialOwners.filter((beneficialOwner) => beneficialOwner.Id !== id));
  };
  const saveBeneficialOwner = () => {
    if (beneficialOwnerValidationContext.validationResult.isValid()) {
      setDispatchIfValid(true);
      if (currentBeneficialOwner.Id === void 0) {
        currentBeneficialOwner.Id = newUniqueId();
        setBeneficialOwners([...beneficialOwners, currentBeneficialOwner]);
      } else {
        setBeneficialOwners(beneficialOwners.map((beneficialOwner) => beneficialOwner.Id === currentBeneficialOwner.Id ? currentBeneficialOwner : beneficialOwner));
      }
      setCurrentBeneficialOwner(void 0);
    } else {
      beneficialOwnerValidationContext.validateFields([
        "first_name",
        "last_name",
        "birth_name",
        "citizenship",
        "date_of_birth",
        "country_of_birth",
        "city_of_birth",
        "is_relative_politically_exposed",
        "is_associate_politically_exposed",
        "address_city",
        "address_country",
        "address_street",
        "address_streetnumber",
        "address_zip",
        "data_management",
        "is_politically_exposed",
        "beneficial_owner_type",
        "type_of_ownership",
        "extent_of_ownership"
      ]);
      beneficialOwnerValidationContext.validate(new WebBeneficialOwner(__spreadValues({}, currentBeneficialOwner)));
      setCurrentBeneficialOwner(__spreadValues({}, currentBeneficialOwner));
    }
  };
  const cancelBeneficialOwner = () => {
    setCurrentBeneficialOwner(void 0);
  };
  const startAddingBeneficialOwner = () => {
    initForm();
    setCurrentBeneficialOwner(__spreadValues({}, DEFAULT_BENEFICIAL_OWNER));
  };
  const [beneficialOwners, setBeneficialOwners] = useState([]);
  const [currentBeneficialOwner, setCurrentBeneficialOwner] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new BeneficialOwnersValidator());
  const beneficialOwnerValidationContext = useValidationContext(new BeneficialOwnerValidator());
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new BeneficialOwnersChangeEvent({
          answer: getAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(getAnswer());
  }, [beneficialOwners]);
  useEffect(() => {
    beneficialOwnerValidationContext.validate(new WebBeneficialOwner(__spreadValues({}, currentBeneficialOwner)));
  }, [currentBeneficialOwner]);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof WebBeneficialOwnerAnswer || answer instanceof AdminBeneficialOwnerAnswer) {
        setBeneficialOwners(
          answer.Value.map((beneficialOwner) => __spreadProps(__spreadValues({}, beneficialOwner), {
            Id: newUniqueId()
          }))
        );
      }
    }
  }, [props.q11e, props.questionId]);
  const templateYesNoQuestions = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return html` <div class="grid md:grid-cols-1 gap-8 mt-8">
			<div>
				<label class="form-label"
					>${getLabel("is_politically_exposed", true)}
					<dc-tooltip .label=${(_b = (_a = getSubQuestion("is_politically_exposed")) == null ? void 0 : _a.Tooltip) != null ? _b : ""}></dc-tooltip>
				</label>
				<div class="flex">
					<dc-toggle
						.readonly=${props.q11e.isReadonly()}
						.state=${mapBooleanToState(currentBeneficialOwner.IsPoliticallyExposed)}
						.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("is_politically_exposed")}
						@change=${(e) => {
      var _a2;
      beneficialOwnerValidationContext.validateField("is_politically_exposed");
      setDispatchIfValid(true);
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        IsPoliticallyExposed: mapStateToBoolean((_a2 = e.detail) == null ? void 0 : _a2.state)
      }));
    }}
					></dc-toggle>
				</div>
			</div>
			<div>
				<label class="form-label"
					>${getLabel("is_relative_politically_exposed", true)}
					<dc-tooltip .label=${(_d = (_c = getSubQuestion("is_relative_politically_exposed")) == null ? void 0 : _c.Tooltip) != null ? _d : ""}></dc-tooltip>
				</label>
				<div class="flex relative mt-8" style="top:-25px">
					<dc-toggle
						.readonly=${props.q11e.isReadonly()}
						.state=${mapBooleanToState(currentBeneficialOwner.IsRelativeToPoliticallyExposed)}
						.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("is_relative_politically_exposed")}
						@change=${(e) => {
      var _a2;
      beneficialOwnerValidationContext.validateField("is_relative_politically_exposed");
      setDispatchIfValid(true);
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        IsRelativeToPoliticallyExposed: mapStateToBoolean((_a2 = e.detail) == null ? void 0 : _a2.state)
      }));
    }}
					></dc-toggle>
				</div>
			</div>
			<div>
				<label class="form-label"
					>${getLabel("is_associate_politically_exposed", true)}
					<dc-tooltip .label=${(_f = (_e = getSubQuestion("is_associate_politically_exposed")) == null ? void 0 : _e.Tooltip) != null ? _f : ""}></dc-tooltip>
				</label>
				<div class="flex relative mt-8" style="top:-25px">
					<dc-toggle
						.readonly=${props.q11e.isReadonly()}
						.state=${mapBooleanToState(currentBeneficialOwner.IsAssociateToPoliticallyExposed)}
						.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("is_associate_politically_exposed")}
						@change=${(e) => {
      var _a2;
      beneficialOwnerValidationContext.validateField("is_associate_politically_exposed");
      setDispatchIfValid(true);
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        IsAssociateToPoliticallyExposed: mapStateToBoolean((_a2 = e.detail) == null ? void 0 : _a2.state)
      }));
    }}
					></dc-toggle>
				</div>
			</div>
			<div>
				<label class="form-label"
					>${getLabel("data_management", true)}
					<dc-tooltip .label=${(_h = (_g = getSubQuestion("data_management")) == null ? void 0 : _g.Tooltip) != null ? _h : ""}></dc-tooltip>
				</label>
				<div class="flex relative mt-8" style="top:-25px">
					<dc-select
						.selectedValues=${[currentBeneficialOwner.DataManagement !== void 0 ? currentBeneficialOwner.DataManagement === true ? "Agree" : "DoNotAgree" : void 0]}
						.dataSource=${DATA_MANAGEMENT_CONSENT}
						.readonly=${props.q11e.isReadonly()}
						.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("data_management")}
						@change=${(e) => {
      beneficialOwnerValidationContext.validateField("data_management");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        DataManagement: e.detail.selectedValue === "Agree" ? true : false
      }));
    }}
					></dc-select>
				</div>
			</div>
		</div>`;
  };
  const templateBeneficialOwner = () => {
    var _a, _b, _c, _d, _e, _f;
    return html`<div>
			<div class="grid md:grid-cols-2 gap-8 mt-8">
				<dc-select
					.label=${getLabel("beneficial_owner_type", true)}
					.selectedValues=${[currentBeneficialOwner.BeneficialOwnerType]}
					.tooltip=${(_b = (_a = getSubQuestion("beneficial_owner_type")) == null ? void 0 : _a.Tooltip) != null ? _b : ""}
					.dataSource=${BENEFICIAL_OWNER_TYPES}
					.readonly=${props.q11e.isReadonly()}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("beneficial_owner_type")}
					@change=${(e) => {
      beneficialOwnerValidationContext.validateField("beneficial_owner_type");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        BeneficialOwnerType: e.detail.selectedValue
      }));
    }}
				></dc-select>
				<dc-input
					.readonly=${props.q11e.isReadonly()}
					.label=${getLabel("first_name", true)}
					.value=${currentBeneficialOwner.FirstName}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("first_name")}
					@change=${(e) => {
      var _a2;
      beneficialOwnerValidationContext.validateField("first_name");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), { FirstName: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
				></dc-input>
				<dc-input
					.readonly=${props.q11e.isReadonly()}
					.label=${getLabel("last_name", true)}
					.value=${currentBeneficialOwner.LastName}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("last_name")}
					@change=${(e) => {
      var _a2;
      beneficialOwnerValidationContext.validateField("last_name");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), { LastName: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
				></dc-input>
				<dc-input
					.readonly=${props.q11e.isReadonly()}
					.label=${getLabel("birth_name", true)}
					.value=${currentBeneficialOwner.BirthName}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("birth_name")}
					@change=${(e) => {
      var _a2;
      beneficialOwnerValidationContext.validateField("birth_name");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), { BirthName: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
				></dc-input>
				<dc-datepicker
					.readonly=${props.q11e.isReadonly()}
					.label=${getLabel("date_of_birth", true)}
					.value=${currentBeneficialOwner.DateOfBirth}
					.max=${/* @__PURE__ */ new Date()}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("date_of_birth")}
					@change=${(e) => {
      if (checkAnswerDate(e.detail.value)) {
        beneficialOwnerValidationContext.validateField("date_of_birth");
        setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), { DateOfBirth: e.detail.value }));
      }
    }}
				></dc-datepicker>
				<dc-select
					.label=${getLabel("country_of_birth", true)}
					.selectedValues=${[currentBeneficialOwner.CountryOfBirth]}
					.dataSource=${props.worldCountries.map((country) => ({ label: country.Name, value: country.Code }))}
					.readonly=${props.q11e.isReadonly()}
					.filterable=${true}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("country_of_birth")}
					@change=${(e) => {
      beneficialOwnerValidationContext.validateField("country_of_birth");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        CountryOfBirth: e.detail.selectedValue
      }));
    }}
				></dc-select>
				<dc-input
					.label=${getLabel("city_of_birth", true)}
					.value=${currentBeneficialOwner.CityOfBirth}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("city_of_birth")}
					@change=${(e) => {
      var _a2;
      beneficialOwnerValidationContext.validateField("city_of_birth");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), { CityOfBirth: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
				></dc-input>
				<dc-select
					.label=${getLabel("citizenship", true)}
					.selectedValues=${[currentBeneficialOwner.Nationality]}
					.dataSource=${props.worldCountries.map((country) => ({ label: country.Name, value: country.Code }))}
					.readonly=${props.q11e.isReadonly()}
					.filterable=${true}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("citizenship")}
					@change=${(e) => {
      beneficialOwnerValidationContext.validateField("citizenship");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        Nationality: e.detail.selectedValue
      }));
    }}
				></dc-select>
			</div>
			<div class="my-8">
				<label class="text-xl font-bold mt-4"> ${i18next.t("Address")} </label>
			</div>
			<div class="grid md:grid-cols-2 gap-8 mt-8">
				<dc-select
					.label=${getLabel("address_country", true)}
					.selectedValues=${[currentBeneficialOwner.AddressCountry]}
					.dataSource=${props.worldCountries.map((country) => ({ label: country.Name, value: country.Code }))}
					.readonly=${props.q11e.isReadonly()}
					.filterable=${true}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("address_country")}
					@change=${(e) => {
      beneficialOwnerValidationContext.validateField("address_country");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        AddressCountry: e.detail.selectedValue
      }));
    }}
				></dc-select>
				<dc-input
					.readonly=${props.q11e.isReadonly()}
					.label=${getLabel("address_city", true)}
					.value=${currentBeneficialOwner.Address.City}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("address_city")}
					@change=${(e) => {
      var _a2;
      beneficialOwnerValidationContext.validateField("address_city");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        Address: new Address(__spreadProps(__spreadValues({}, currentBeneficialOwner.Address), { City: (_a2 = e.detail) == null ? void 0 : _a2.value }))
      }));
    }}
				></dc-input>
				<dc-input
					.readonly=${props.q11e.isReadonly()}
					.label=${getLabel("address_street", true)}
					.value=${currentBeneficialOwner.Address.Street}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("address_street")}
					@change=${(e) => {
      var _a2;
      beneficialOwnerValidationContext.validateField("address_street");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        Address: new Address(__spreadProps(__spreadValues({}, currentBeneficialOwner.Address), { Street: (_a2 = e.detail) == null ? void 0 : _a2.value }))
      }));
    }}
				></dc-input>
				<dc-input
					.readonly=${props.q11e.isReadonly()}
					.label=${getLabel("address_streetnumber", true)}
					.value=${currentBeneficialOwner.Address.StreetNumber}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("address_streetnumber")}
					@change=${(e) => {
      var _a2;
      beneficialOwnerValidationContext.validateField("address_streetnumber");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        Address: new Address(__spreadProps(__spreadValues({}, currentBeneficialOwner.Address), { StreetNumber: (_a2 = e.detail) == null ? void 0 : _a2.value }))
      }));
    }}
				></dc-input>
				<dc-input
					.readonly=${props.q11e.isReadonly()}
					.label=${getLabel("address_zip", true)}
					.value=${currentBeneficialOwner.Address.Zip}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("address_zip")}
					@change=${(e) => {
      var _a2;
      beneficialOwnerValidationContext.validateField("address_zip");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        Address: new Address(__spreadProps(__spreadValues({}, currentBeneficialOwner.Address), { Zip: (_a2 = e.detail) == null ? void 0 : _a2.value }))
      }));
    }}
				></dc-input>
			</div>
			<div class="my-8">
				<label class="text-xl font-bold mt-4"> ${i18next.t("Statement")} </label>
			</div>
			<div class="grid md:grid-cols-2 gap-8 mt-8">
				<dc-select
					class=${`${(currentBeneficialOwner == null ? void 0 : currentBeneficialOwner.BeneficialOwnerType) === BeneficialOwnerBeneficialOwnerType.NaturalPerson ? "" : "hidden"}`}
					.label=${getLabel("type_of_ownership", true)}
					.selectedValues=${[currentBeneficialOwner.TypeOfOwnership]}
					.tooltip=${(_d = (_c = getSubQuestion("type_of_ownership")) == null ? void 0 : _c.Tooltip) != null ? _d : ""}
					.dataSource=${TYPE_OF_OWNERSHIP}
					.readonly=${props.q11e.isReadonly()}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("type_of_ownership")}
					@change=${(e) => {
      beneficialOwnerValidationContext.validateField("type_of_ownership");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        TypeOfOwnership: e.detail.selectedValue
      }));
    }}
				></dc-select>
				<dc-input
					class=${`${(currentBeneficialOwner == null ? void 0 : currentBeneficialOwner.BeneficialOwnerType) === BeneficialOwnerBeneficialOwnerType.NaturalPerson ? "" : "hidden"}`}
					.readonly=${props.q11e.isReadonly()}
					.type=${"number"}
					.label=${getLabel("extent_of_ownership", false)}
					.tooltip=${(_f = (_e = getSubQuestion("extent_of_ownership")) == null ? void 0 : _e.Tooltip) != null ? _f : ""}
					.value=${currentBeneficialOwner.ExtentOfOwnership}
					.validationMessage=${beneficialOwnerValidationContext.getValidationMessage("extent_of_ownership")}
					@change=${(e) => {
      var _a2;
      beneficialOwnerValidationContext.validateField("extent_of_ownership");
      setCurrentBeneficialOwner(__spreadProps(__spreadValues({}, currentBeneficialOwner), {
        ExtentOfOwnership: Number((_a2 = e.detail) == null ? void 0 : _a2.value)
      }));
    }}
				></dc-input>
			</div>
			${templateYesNoQuestions()}
			<div class="mt-8">
				<button class="btn btn-primary" @click=${cancelBeneficialOwner}>${i18next.t("Cancel")}</button>
				${props.q11e.isReadonly() ? "" : html`<button class="btn btn-primary ml-4" @click=${saveBeneficialOwner}>${i18next.t("Save")}</button>`}
			</div>
		</div>`;
  };
  const templateBeneficialOwners = () => {
    return html`<div>
				${beneficialOwners.length > 0 ? beneficialOwners.map(
      (beneficialOwner) => html`<div class="flex justify-between py-2">
										<div>
											<a class="link" href="javascript:void(0);" @click=${() => editBeneficialOwner(beneficialOwner.Id)}
												>${html`${beneficialOwner.LastName}, ${beneficialOwner.FirstName}`}
											</a>
										</div>
										${props.q11e.isReadonly() ? "" : html`<div>
													<a class="link" href="javascript:void(0);" @click=${() => removeBeneficialOwner(beneficialOwner.Id)}>${i18next.t("Remove")}</a>
											  </div>`}
									</div>
									<hr />`
    ) : html`${currentBeneficialOwner === void 0 ? html`<div class="italic mb-10">
									${i18next.t(
      "The identification of the beneficial owner is an obligation set forth in the law for the purposes of preventing and combating money laundering and the financing of terrorism. Please add every beneficial owner."
    )}
							  </div>` : ""}`}
			</div>
			<div class="mt-8">
				${currentBeneficialOwner !== void 0 ? templateBeneficialOwner() : props.q11e.isReadonly() ? "" : html`<button class="btn btn-primary" @click=${startAddingBeneficialOwner}>${i18next.t("Add new Beneficial Owner")}</button>`}
			</div>`;
  };
  return html`${props.q11e !== void 0 && question !== void 0 ? html` <div>
				<div class="my-8">${templateTitle(i18next.t("Beneficial Owner"))}</div>
				${templateBeneficialOwners()}
		  </div>` : ""}`;
};
