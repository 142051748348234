import { Country } from "./../../Common/MasterData/Country";
import { DateConverter } from "./../../../../converters/DateConverter";
import { Document } from "./../../CompanyInfo/Detail/Document";
import { VatIdentificationNumber } from "./../../Common/VatIdentificationNumber";
import { BankAccountConfig } from "./../../CompanyInfo/Detail/BankAccountConfig";
import { FrequencyConfig } from "./FrequencyConfig";
export class VatRegistration {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRegistration
     */
    this.$type = "V1.Companies.CompanyInfo.VatRegistration";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["BankAccountConfig"]) {
        const bankAccountConfig_ = new BankAccountConfig();
        this.BankAccountConfig = bankAccountConfig_.init(_data["BankAccountConfig"]);
      }
      this.DeregistrationConfirmationDocumentId = _data["DeregistrationConfirmationDocumentId"];
      this.DeregistrationDate = DateConverter.from(_data["DeregistrationDate"]);
      if (_data["Documents"] && _data["Documents"].constructor === Array) {
        this.Documents = [];
        for (let item of _data["Documents"]) {
          const documents_ = new Document();
          this.Documents.push(documents_.init(item));
        }
      }
      if (_data["EslFrequencies"] && _data["EslFrequencies"].constructor === Array) {
        this.EslFrequencies = [];
        for (let item of _data["EslFrequencies"]) {
          const eslFrequencies_ = new FrequencyConfig();
          this.EslFrequencies.push(eslFrequencies_.init(item));
        }
      }
      this.EUVATCertificatesReferenceId = _data["EUVATCertificatesReferenceId"];
      if (_data["EUVatNumber"]) {
        const eUVatNumber_ = new VatIdentificationNumber();
        this.EUVatNumber = eUVatNumber_.init(_data["EUVatNumber"]);
      }
      this.EUVatRegistrationDate = DateConverter.from(_data["EUVatRegistrationDate"]);
      this.Id = _data["Id"];
      if (_data["IntrastatFrequencies"] && _data["IntrastatFrequencies"].constructor === Array) {
        this.IntrastatFrequencies = [];
        for (let item of _data["IntrastatFrequencies"]) {
          const intrastatFrequencies_ = new FrequencyConfig();
          this.IntrastatFrequencies.push(intrastatFrequencies_.init(item));
        }
      }
      this.IsEslSubscribed = _data["IsEslSubscribed"];
      this.IsVlsSubscribed = _data["IsVlsSubscribed"];
      this.LocalVATCertificatesReferenceId = _data["LocalVATCertificatesReferenceId"];
      this.LocalVatNumber = _data["LocalVatNumber"];
      this.LocalVatRegistrationDate = DateConverter.from(_data["LocalVatRegistrationDate"]);
      this.PaymentReference = _data["PaymentReference"];
      this.PoaRevocationConfirmationDocumentId = _data["PoaRevocationConfirmationDocumentId"];
      this.PoaRevocationDate = DateConverter.from(_data["PoaRevocationDate"]);
      if (_data["PrimaryVatFrequencies"] && _data["PrimaryVatFrequencies"].constructor === Array) {
        this.PrimaryVatFrequencies = [];
        for (let item of _data["PrimaryVatFrequencies"]) {
          const primaryVatFrequencies_ = new FrequencyConfig();
          this.PrimaryVatFrequencies.push(primaryVatFrequencies_.init(item));
        }
      }
      this.RegistrationDocumentStatus = VatRegistrationRegistrationDocumentStatus[_data["RegistrationDocumentStatus"]];
      if (_data["SecondaryVatFrequencies"] && _data["SecondaryVatFrequencies"].constructor === Array) {
        this.SecondaryVatFrequencies = [];
        for (let item of _data["SecondaryVatFrequencies"]) {
          const secondaryVatFrequencies_ = new FrequencyConfig();
          this.SecondaryVatFrequencies.push(secondaryVatFrequencies_.init(item));
        }
      }
      if (_data["ServiceCountry"]) {
        const serviceCountry_ = new Country();
        this.ServiceCountry = serviceCountry_.init(_data["ServiceCountry"]);
      }
      this.ServiceKind = VatRegistrationServiceKind[_data["ServiceKind"]];
      this.StartDate = DateConverter.from(_data["StartDate"]);
      this.Status = VatRegistrationStatus[_data["Status"]];
      this.TaxIdentifier = _data["TaxIdentifier"];
      if (_data["VlsFrequencies"] && _data["VlsFrequencies"].constructor === Array) {
        this.VlsFrequencies = [];
        for (let item of _data["VlsFrequencies"]) {
          const vlsFrequencies_ = new FrequencyConfig();
          this.VlsFrequencies.push(vlsFrequencies_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["BankAccountConfig"] = this.BankAccountConfig ? this.BankAccountConfig.toJSON() : void 0;
    _data["DeregistrationConfirmationDocumentId"] = this.DeregistrationConfirmationDocumentId;
    _data["DeregistrationDate"] = DateConverter.toUtcDateString(this.DeregistrationDate);
    if (this.Documents && this.Documents.constructor === Array) {
      _data["Documents"] = [];
      for (let item of this.Documents) {
        _data["Documents"].push(item.toJSON());
      }
    }
    if (this.EslFrequencies && this.EslFrequencies.constructor === Array) {
      _data["EslFrequencies"] = [];
      for (let item of this.EslFrequencies) {
        _data["EslFrequencies"].push(item.toJSON());
      }
    }
    _data["EUVATCertificatesReferenceId"] = this.EUVATCertificatesReferenceId;
    _data["EUVatNumber"] = this.EUVatNumber ? this.EUVatNumber.toJSON() : void 0;
    _data["EUVatRegistrationDate"] = DateConverter.toUtcDateString(this.EUVatRegistrationDate);
    _data["Id"] = this.Id;
    if (this.IntrastatFrequencies && this.IntrastatFrequencies.constructor === Array) {
      _data["IntrastatFrequencies"] = [];
      for (let item of this.IntrastatFrequencies) {
        _data["IntrastatFrequencies"].push(item.toJSON());
      }
    }
    _data["IsEslSubscribed"] = this.IsEslSubscribed;
    _data["IsVlsSubscribed"] = this.IsVlsSubscribed;
    _data["LocalVATCertificatesReferenceId"] = this.LocalVATCertificatesReferenceId;
    _data["LocalVatNumber"] = this.LocalVatNumber;
    _data["LocalVatRegistrationDate"] = DateConverter.toUtcDateString(this.LocalVatRegistrationDate);
    _data["PaymentReference"] = this.PaymentReference;
    _data["PoaRevocationConfirmationDocumentId"] = this.PoaRevocationConfirmationDocumentId;
    _data["PoaRevocationDate"] = DateConverter.toUtcDateString(this.PoaRevocationDate);
    if (this.PrimaryVatFrequencies && this.PrimaryVatFrequencies.constructor === Array) {
      _data["PrimaryVatFrequencies"] = [];
      for (let item of this.PrimaryVatFrequencies) {
        _data["PrimaryVatFrequencies"].push(item.toJSON());
      }
    }
    _data["RegistrationDocumentStatus"] = VatRegistrationRegistrationDocumentStatus[this.RegistrationDocumentStatus];
    if (this.SecondaryVatFrequencies && this.SecondaryVatFrequencies.constructor === Array) {
      _data["SecondaryVatFrequencies"] = [];
      for (let item of this.SecondaryVatFrequencies) {
        _data["SecondaryVatFrequencies"].push(item.toJSON());
      }
    }
    _data["ServiceCountry"] = this.ServiceCountry ? this.ServiceCountry.toJSON() : void 0;
    _data["ServiceKind"] = VatRegistrationServiceKind[this.ServiceKind];
    _data["StartDate"] = DateConverter.toUtcDateString(this.StartDate);
    _data["Status"] = VatRegistrationStatus[this.Status];
    _data["TaxIdentifier"] = this.TaxIdentifier;
    if (this.VlsFrequencies && this.VlsFrequencies.constructor === Array) {
      _data["VlsFrequencies"] = [];
      for (let item of this.VlsFrequencies) {
        _data["VlsFrequencies"].push(item.toJSON());
      }
    }
    return _data;
  }
}
export var VatRegistrationRegistrationDocumentStatus = /* @__PURE__ */ ((VatRegistrationRegistrationDocumentStatus2) => {
  VatRegistrationRegistrationDocumentStatus2["None"] = "None";
  VatRegistrationRegistrationDocumentStatus2["InProgress"] = "InProgress";
  VatRegistrationRegistrationDocumentStatus2["Generated"] = "Generated";
  VatRegistrationRegistrationDocumentStatus2["Error"] = "Error";
  return VatRegistrationRegistrationDocumentStatus2;
})(VatRegistrationRegistrationDocumentStatus || {});
export var VatRegistrationServiceKind = /* @__PURE__ */ ((VatRegistrationServiceKind2) => {
  VatRegistrationServiceKind2["NewReg"] = "NewReg";
  VatRegistrationServiceKind2["TakOver"] = "TakOver";
  return VatRegistrationServiceKind2;
})(VatRegistrationServiceKind || {});
export var VatRegistrationStatus = /* @__PURE__ */ ((VatRegistrationStatus2) => {
  VatRegistrationStatus2["Pending"] = "Pending";
  VatRegistrationStatus2["WaitingForSignature"] = "WaitingForSignature";
  VatRegistrationStatus2["DocumentsSigned"] = "DocumentsSigned";
  VatRegistrationStatus2["TranslationInProgress"] = "TranslationInProgress";
  VatRegistrationStatus2["SentToTA"] = "SentToTA";
  VatRegistrationStatus2["LocalTaxReceived"] = "LocalTaxReceived";
  VatRegistrationStatus2["RegistrationCompleted"] = "RegistrationCompleted";
  VatRegistrationStatus2["Deregistered"] = "Deregistered";
  VatRegistrationStatus2["DeregistrationInProgress"] = "DeregistrationInProgress";
  VatRegistrationStatus2["PoARevoked"] = "PoARevoked";
  VatRegistrationStatus2["RevokingPoA"] = "RevokingPoA";
  return VatRegistrationStatus2;
})(VatRegistrationStatus || {});
