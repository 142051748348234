import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as PDFHelper from "GlobalShared/helpers/pdfHelper";
import { ADDITIONAL_TAX_SERVICES, NUMBERED_TEXTS, UNORDERED_TEXTS } from "./kpmg-pdf-data";
import { formatDate } from "GlobalShared/helpers/dateHelper";
import { colorLightGray, colorDarkGray, colorWhite, colorBlack, iossTermsAndConditions, privacyStatement, horizontalLine } from "../../../helpers/pdfHelper";
import { iossSection, ossSection, vatSection } from "./pdf-common";
const doc = new jsPDF({ unit: "mm" });
const sideMargin = 10;
const pageCenter = doc.internal.pageSize.width / 2;
const WWDoc = {
  doc,
  // Alignment Constants in [mm]
  sideMargin,
  topMargin: 12,
  topMarginForText: 27,
  footerY: 285,
  pageWidth: doc.internal.pageSize.width,
  pageCenter,
  contentWidth: doc.internal.pageSize.width - 2 * sideMargin,
  // numbered textbox
  marginBetweenNumberedTextBoxes: 1,
  numberedTextBoxWidth: 85,
  rightNumberedX: pageCenter + 6,
  numberedTextBoxIndent: 6,
  // unordered textbox
  marginBetweenUnorderedTextBoxes: 0,
  leftUnorderedX: sideMargin + 5,
  rightUnorderedX: pageCenter + 10,
  unorderedTextBoxIndent: 5,
  unorderedTextBoxWidth: 82,
  // small unordered textbox
  marginBetweenSmallUnorderedTextBoxes: 1,
  rightSmallUnorderedX: pageCenter + 15,
  smallUnorderedTextBoxIndent: 10,
  smallUnorderedTextBoxWidth: 77,
  // header image
  headerImageWidth: 15,
  headerImageHeight: 6
};
const header = (wwdoc) => {
  wwdoc.doc.addImage("/images/kpmg_logo.png", "PNG", wwdoc.sideMargin, wwdoc.topMargin, wwdoc.headerImageWidth, wwdoc.headerImageHeight);
};
const footer = (wwdoc, pageNumber) => {
  wwdoc.doc.setFontSize(9);
  wwdoc.doc.setFont("times", "normal");
  wwdoc.doc.text(`Page ${pageNumber}`, wwdoc.sideMargin, wwdoc.footerY);
  wwdoc.doc.text(`Indirect Tax Services`, wwdoc.pageWidth - wwdoc.sideMargin - 2, wwdoc.footerY, { align: "right" });
};
const firstPage = (wwdoc, pdfModel) => {
  header(wwdoc);
  wwdoc.doc.setFont("times", "bold");
  wwdoc.doc.setFontSize(11);
  PDFHelper.textCentered(wwdoc.doc, "Engagement contract for Core Tax Compliance Services and Additional Tax Services", wwdoc.topMarginForText);
  wwdoc.doc.setFontSize(9);
  const partnerY = 40;
  const marginBottom = 10;
  wwdoc.doc.text("Full legal name of the contracting party", wwdoc.sideMargin, partnerY);
  wwdoc.doc.text("Legal status of the contracting party", wwdoc.sideMargin, partnerY + marginBottom);
  wwdoc.doc.text("Registered address of the contracting party", wwdoc.sideMargin, partnerY + 2 * marginBottom);
  wwdoc.doc.setLineWidth(0.1);
  wwdoc.doc.text("VAT Number", wwdoc.sideMargin, partnerY + 3 * marginBottom);
  wwdoc.doc.text("Signature", wwdoc.sideMargin, partnerY + 4 * marginBottom);
  horizontalLine(wwdoc.sideMargin + 19, partnerY + 4 * marginBottom + 1, wwdoc.pageCenter - 10, wwdoc.doc);
  wwdoc.doc.text("Date", wwdoc.sideMargin, partnerY + 5 * marginBottom);
  wwdoc.doc.setFont("times", "normal");
  wwdoc.doc.text(pdfModel.contractDate ? formatDate(pdfModel.contractDate) : "", wwdoc.sideMargin + 40, partnerY + 5 * marginBottom);
  horizontalLine(wwdoc.sideMargin + 19, partnerY + 5 * marginBottom + 1, wwdoc.pageCenter - 10, wwdoc.doc);
  wwdoc.doc.setFont("times", "bold");
  wwdoc.doc.text("KPMG Reference", wwdoc.sideMargin, partnerY + 6 * marginBottom);
  wwdoc.doc.setFont("times", "normal");
  wwdoc.doc.text(pdfModel.kpmgReference !== void 0 ? pdfModel.kpmgReference : "", wwdoc.sideMargin + 40, partnerY + 6 * marginBottom);
  wwdoc.doc.setFont("times", "bold");
  horizontalLine(wwdoc.sideMargin + 30, partnerY + 6 * marginBottom + 1, wwdoc.pageCenter - 10, wwdoc.doc);
  wwdoc.doc.setFont("times", "normal");
  wwdoc.doc.text(pdfModel.partnerName ? pdfModel.partnerName : "", wwdoc.pageCenter, partnerY);
  wwdoc.doc.text(pdfModel.legalStatus ? pdfModel.legalStatus : "", wwdoc.pageCenter, partnerY + marginBottom);
  wwdoc.doc.text(pdfModel.addressLine ? pdfModel.addressLine : "", wwdoc.pageCenter, partnerY + 2 * marginBottom, {
    maxWidth: 80,
    lineHeightFactor: 1.2
  });
  wwdoc.doc.text(pdfModel.vatNumber ? pdfModel.vatNumber : "", wwdoc.pageCenter, partnerY + 3 * marginBottom);
  wwdoc.doc.setLineWidth(0.3);
  wwdoc.doc.setLineHeightFactor(16);
  horizontalLine(wwdoc.sideMargin, 107, wwdoc.pageWidth - wwdoc.sideMargin, wwdoc.doc);
  wwdoc.doc.setLineHeightFactor(1.4);
  PDFHelper.titleText(`General`, wwdoc.sideMargin, 113, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(0, 2), 119, wwdoc.sideMargin, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.appendixText("Appendix 1.", 5, "", wwdoc.sideMargin + 50, 151, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[2].displayNumber, NUMBERED_TEXTS[2].text, wwdoc.sideMargin, 156, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.appendixText("Appendix 2.", 6, "", wwdoc.sideMargin + 19, 164, wwdoc.doc);
  PDFHelper.linkInFrontOfTextTextBox(
    NUMBERED_TEXTS[3].linkTitle,
    iossTermsAndConditions,
    NUMBERED_TEXTS[3].displayNumber,
    NUMBERED_TEXTS[3].firstLine,
    NUMBERED_TEXTS[3].text,
    wwdoc.sideMargin,
    170,
    wwdoc.numberedTextBoxWidth,
    wwdoc.doc
  );
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[4].displayNumber, NUMBERED_TEXTS[4].text, wwdoc.sideMargin, 184, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.titleText(`Our services`, wwdoc.sideMargin, 213, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[5].displayNumber, NUMBERED_TEXTS[5].text, wwdoc.sideMargin, 219, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(0, 2), 225, wwdoc.leftUnorderedX, wwdoc.unorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes + 5, wwdoc.doc);
  PDFHelper.linkWithBrackets("www.kpmg-vat-oss.com/gb/en", wwdoc.leftUnorderedX + wwdoc.unorderedTextBoxIndent, 229, wwdoc.unorderedTextBoxWidth, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(2, 4), 116, wwdoc.rightUnorderedX, wwdoc.unorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(4, 6), 175, wwdoc.rightSmallUnorderedX, wwdoc.smallUnorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes + 1, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(6, 7), 190, wwdoc.rightUnorderedX, wwdoc.unorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(7, 8), 200, wwdoc.rightSmallUnorderedX, wwdoc.smallUnorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes + 1, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(8, 10), 209, wwdoc.rightUnorderedX, wwdoc.unorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes, wwdoc.doc);
  footer(wwdoc, 1);
};
const secondPage = (wwdoc) => {
  wwdoc.doc.addPage();
  header(wwdoc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(10, 14), wwdoc.topMarginForText, wwdoc.leftUnorderedX, wwdoc.unorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes + 1, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[6].displayNumber, NUMBERED_TEXTS[6].text, wwdoc.sideMargin, 96, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(14, 15), 117, wwdoc.leftUnorderedX, wwdoc.unorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes, wwdoc.doc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(15, 19), 137, wwdoc.leftUnorderedX, wwdoc.unorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[7].displayNumber, NUMBERED_TEXTS[7].text, wwdoc.sideMargin, 179, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.titleText(`Your obligations`, wwdoc.sideMargin, 216, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(8, 9), 223, wwdoc.sideMargin, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(9, 12), wwdoc.topMarginForText, wwdoc.rightNumberedX, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(12, 14), 189, wwdoc.rightNumberedX, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  footer(wwdoc, 2);
};
const thirdPage = (wwdoc) => {
  wwdoc.doc.addPage();
  header(wwdoc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(14, 19), wwdoc.topMarginForText, wwdoc.sideMargin, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(19, 23), wwdoc.topMarginForText, wwdoc.rightNumberedX, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.titleText(`Payment for services`, wwdoc.rightNumberedX, 130, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(23, 27), 136, wwdoc.rightNumberedX, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.titleText(`Termination`, wwdoc.rightNumberedX, 239, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[27].displayNumber, NUMBERED_TEXTS[27].text, wwdoc.rightNumberedX, 245, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  footer(wwdoc, 3);
};
const fourthPage = (wwdoc) => {
  wwdoc.doc.addPage();
  header(wwdoc);
  PDFHelper.unorderedParagraph(UNORDERED_TEXTS.slice(19, 22), wwdoc.topMarginForText, wwdoc.leftUnorderedX, wwdoc.unorderedTextBoxWidth, wwdoc.marginBetweenUnorderedTextBoxes + 1, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(28, 31), 80, wwdoc.sideMargin, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.numberedParagraph(NUMBERED_TEXTS.slice(31, 33), wwdoc.topMarginForText, wwdoc.rightNumberedX, wwdoc.numberedTextBoxWidth, wwdoc.marginBetweenNumberedTextBoxes, wwdoc.doc);
  PDFHelper.titleText(`Limitation of liability`, wwdoc.rightNumberedX, 88, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[33].displayNumber, NUMBERED_TEXTS[33].text, wwdoc.rightNumberedX, 94, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.titleText(`Data Privacy`, wwdoc.rightNumberedX, 127, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[34].displayNumber, NUMBERED_TEXTS[34].text, wwdoc.rightNumberedX, 133, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.linkText("Privacy Statement", privacyStatement, wwdoc.rightNumberedX + 6, 151, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  PDFHelper.titleText(`The Engagement Contract`, wwdoc.rightNumberedX, 158, wwdoc.doc);
  PDFHelper.numberedTextBox(NUMBERED_TEXTS[35].displayNumber, NUMBERED_TEXTS[35].text, wwdoc.rightNumberedX, 164, wwdoc.numberedTextBoxWidth, wwdoc.doc);
  footer(wwdoc, 4);
};
const fifthPage = (wwdoc, pdfModel) => {
  wwdoc.doc.addPage();
  header(wwdoc);
  wwdoc.doc.setFontSize(11);
  PDFHelper.titleText("Appendix 1", wwdoc.sideMargin, wwdoc.topMarginForText, wwdoc.doc);
  wwdoc.doc.setFontSize(9);
  wwdoc.doc.text(`KPMG will provide the following Core Tax Compliance Services and the Annual Service Fee will apply:`, wwdoc.sideMargin, wwdoc.topMarginForText + 7);
  const tableProps = {
    lineHeight: 6,
    tableLeftPadding: wwdoc.sideMargin + 40,
    eurChar: "EUR",
    secondCol: 80,
    paragraphMargin: 5
  };
  const hasIossService = pdfModel.iossTier !== void 0;
  const hasVatService = pdfModel.hasVatService;
  const hasEuOssVat = false;
  const tableSectionTop = wwdoc.topMarginForText + tableProps.paragraphMargin + tableProps.lineHeight * 2;
  const lineObj = {
    line: 0
  };
  const availableServices = [].concat(hasVatService ? [vatSection] : []).concat(hasEuOssVat ? [ossSection] : []).concat(hasIossService ? [iossSection] : []);
  availableServices.forEach((service, index) => {
    const sectionLetter = String.fromCharCode("a".charCodeAt(0) + index);
    const cumulativeTop = index > 0 ? tableSectionTop + lineObj.line * tableProps.lineHeight + 2 * tableProps.paragraphMargin : tableSectionTop + lineObj.line * tableProps.lineHeight;
    service(sectionLetter, lineObj, cumulativeTop, tableProps, wwdoc, pdfModel);
  });
  footer(wwdoc, 5);
};
const sixthPage = (wwdoc) => {
  wwdoc.doc.addPage();
  header(wwdoc);
  wwdoc.doc.setFontSize(11);
  PDFHelper.titleText("Appendix 2: Additional Tax Services", wwdoc.sideMargin, wwdoc.topMarginForText, wwdoc.doc);
  wwdoc.doc.setFontSize(9);
  wwdoc.doc.text(`The following Additional Tax Services may be provided upon your explicit request:`, wwdoc.sideMargin, wwdoc.topMarginForText + 7);
  autoTable(wwdoc.doc, {
    head: ADDITIONAL_TAX_SERVICES.header,
    body: ADDITIONAL_TAX_SERVICES.body,
    theme: "grid",
    startY: wwdoc.topMarginForText + 14,
    styles: {
      textColor: colorBlack,
      overflow: "linebreak",
      font: "times",
      fontSize: 9,
      lineColor: colorLightGray,
      valign: "middle",
      cellPadding: { top: 2, horizontal: 2, bottom: 3 }
    },
    headStyles: {
      fillColor: colorDarkGray,
      textColor: colorWhite
    },
    columnStyles: {
      0: { cellWidth: 45 },
      1: { cellWidth: 24 },
      2: { cellWidth: 28 }
    },
    didParseCell: (data) => {
      if (data.row.index === 1 || data.row.index === 5 || data.row.index === 6 || data.row.index === 7 || data.row.index === 19) {
        data.cell.styles.cellPadding = { top: 1, horizontal: 2, bottom: 7 };
      }
      if (data.row.index === 8 || data.row.index === 9) {
        if (data.column.index === 1) {
          data.cell.colSpan = 2;
        }
        if (data.column.index === 2) {
          data.cell.colSpan = 0;
        }
      }
    }
  });
  footer(wwdoc, 6);
};
export const generateKpmgPDF = (pdfModel) => {
  firstPage(WWDoc, pdfModel);
  secondPage(WWDoc);
  thirdPage(WWDoc);
  fourthPage(WWDoc);
  fifthPage(WWDoc, pdfModel);
  sixthPage(WWDoc);
  WWDoc.doc.save(`Contract_${pdfModel.partnerName}_${pdfModel.kpmgReference}.pdf`);
  return WWDoc.doc.output("blob");
};
