import { TswagClient } from "./../commons/TswagClient";
import { Country } from "./../models/V1/Common/MasterData/Country";
export class MasterData {
  /**
   * Creates an instance of MasterData.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof MasterData
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GetCountriesRq_ _getCountriesRq
   * @returns Promise<GetCountriesRq_>
   * @memberof MasterData
   */
  getCountries(pGetCountriesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/MasterData/Countries",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "locale", value: pGetCountriesRq.locale }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Country, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetServiceCountriesRq_ _getServiceCountriesRq
   * @returns Promise<GetServiceCountriesRq_>
   * @memberof MasterData
   */
  getServiceCountries(pGetServiceCountriesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/MasterData/ServiceCountries",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "locale", value: pGetServiceCountriesRq.locale }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Country, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetEuCountriesRq_ _getEuCountriesRq
   * @returns Promise<GetEuCountriesRq_>
   * @memberof MasterData
   */
  getEuCountries(pGetEuCountriesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/MasterData/EuCountries",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "locale", value: pGetEuCountriesRq.locale }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Country, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetBirthCountriesRq_ _getBirthCountriesRq
   * @returns Promise<GetBirthCountriesRq_>
   * @memberof MasterData
   */
  getBirthCountries(pGetBirthCountriesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/MasterData/BirthCountries",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addQueryParams([
      { name: "locale", value: pGetBirthCountriesRq.locale }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Country, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
