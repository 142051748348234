export const TermOpTypes = [
  { value: "Eq", label: "Equals" },
  { value: "In", label: "Includes" },
  { value: "Gt", label: "Greater than" },
  { value: "Lt", label: "Less than" },
  { value: "Lte", label: "Less than equals" },
  { value: "Gte", label: "Greater than equals" }
];
export const getTermOpTypeListItemsByDSFieldType = (fieldType) => {
  if (fieldType === "DateTime") {
    return TermOpTypes.filter((type) => type.value !== "In");
  } else if (fieldType === "Decimal" || fieldType === "Int") {
    return TermOpTypes.filter((type) => type.value !== "In");
  } else if (fieldType === "String") {
    return TermOpTypes.filter((type) => type.value === "In" || type.value === "Eq");
  } else {
    return [];
  }
};
export const RegTypeDataSource = ["NewReg", "TakeOver"];
export const VatRegTemplateStatusDataSource = ["Draft", "Enabled", "Disabled"];
export const CompanyTemplateStatusDataSource = ["Draft", "Enabled", "Disabled"];
export const CompanyTypeDataSource = ["Individual", "Company"];
export const CategoryDataSource = ["ForInformationOnly", "RequiredByPost", "RequiredElectronicallyOnly"];
export const DEFAULT_TEMPLATE = {
  Id: void 0,
  Name: void 0,
  Type: void 0,
  Culture: void 0,
  Custom: {},
  Versions: []
};
export const DEFAULT_DRAFT_TEMPLATE_VERSION = {
  TemplateId: 1,
  Version: void 0,
  Draft: true,
  Disabled: false,
  ValidFrom: void 0,
  ValidTo: void 0,
  PDFFields: [],
  MappingsItems: []
};
export const TEMPLATE_TYPES = [
  {
    Id: "MPCompany",
    Label: "Marketplace Contract",
    Triggers: [
      {
        Id: "OrderCreated",
        Label: "Order Created"
      }
    ]
  },
  {
    Id: "MPVatReg",
    Label: "Marketplace Vat Registration",
    Triggers: [
      {
        Id: "Q11EApproved",
        Label: "Questionnaire - Approved"
      },
      {
        Id: "Q11ECompleted",
        Label: "Questionnaire - Completed"
      },
      {
        Id: "Q11EIncomplete",
        Label: "Questionnaire - Incomplete"
      },
      {
        Id: "Q11EVerified",
        Label: "Questionnaire - Verified"
      },
      {
        Id: "CompanyCreated",
        Label: "Company - Created"
      },
      {
        Id: "CompanyRejected",
        Label: "Company - Rejected"
      },
      {
        Id: "VatRegPending",
        Label: "VatReg - Pending"
      },
      {
        Id: "VatRegWaitingForSignature",
        Label: "VatReg - WaitingForSignature"
      },
      {
        Id: "VatRegDocumentsSigned",
        Label: "VatReg - DocumentsSigned"
      },
      {
        Id: "VatRegTranslationInProgress",
        Label: "VatReg - TranslationInProgress"
      },
      {
        Id: "VatRegSentToTA",
        Label: "VatReg - SentToTA"
      },
      {
        Id: "VatRegLocalTaxReceived",
        Label: "VatReg - LocalTaxReceived"
      },
      {
        Id: "VatRegRegistrationCompleted",
        Label: "VatReg - RegistrationCompleted"
      }
    ]
  }
];
export const getAllowedDSFieldTypesByPDFFieldType = (pdfFieldType, expressionType) => {
  if (pdfFieldType === "Text") {
    if (expressionType === "DATEFORMAT") {
      return ["DateTime"];
    } else if (expressionType === "IDENTITY" || expressionType === "CONCAT" || expressionType === "SUBSTRING") {
      return ["String", "Decimal", "Int"];
    } else {
      return ["String", "Decimal", "Int", "DateTime"];
    }
  } else if (pdfFieldType === "Checkbox") {
    return ["String", "Decimal", "Int", "DateTime", "Boolean"];
  } else if (pdfFieldType === "Dropdown") {
    return ["Enum"];
  } else {
    return ["String", "Decimal", "Int", "DateTime", "Boolean", "Enum"];
  }
};
export const getAllowedExpressionTypes = (pdfFieldType) => {
  if (pdfFieldType === "Text") {
    return ["IDENTITY", "CONCAT", "SUBSTRING", "DATEFORMAT"];
  } else if (pdfFieldType === "Checkbox") {
    return ["CONDITION"];
  } else if (pdfFieldType === "Dropdown") {
    return ["ENUM"];
  } else {
    return [];
  }
};
