import i18next from "i18next";
export const TYPE_AND_RATE_LABELS = {
  Standard: i18next.t("Standard"),
  Standard2: i18next.t("Standard2"),
  Reduced: i18next.t("Reduced"),
  Reduced2: `${i18next.t("Reduced")}2`,
  SuperReduced: i18next.t("SuperReduced"),
  SuperReduced2: i18next.t("SuperReduced2"),
  Exempt: i18next.t("Exempt"),
  ZeroRated: i18next.t("ZeroRated"),
  Middle: i18next.t("Middle")
};
