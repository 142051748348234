var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient, useLoadingReducer } from "GlobalShared/haunted/CustomHooks";
import { templateTitle } from "GlobalShared/templates/commons";
import { html } from "lit-html";
import { Reporting as ReportingClient } from "AdminServices/APIs/self/clients";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
export function Reporting() {
  const downloadReport = (type) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        dispatchLoading("inc");
        let file;
        switch (type) {
          case "Account":
            file = (yield reportingClient.generateAccountReport()).data;
            break;
          case "ClientRegistration":
            file = (yield reportingClient.generateClientRegistrationReport()).data;
            break;
          case "SubscriptionFeedback":
            file = (yield reportingClient.generateSubscriptionFeedbackReport()).data;
            break;
          default:
            break;
        }
        simulateDownload(file);
      }),
      (error) => {
        notifyError(error);
      },
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const reportingClient = useClient(ReportingClient);
  const [loading, dispatchLoading] = useLoadingReducer();
  return html`<div class="my-2 mx-8 mb-16 mt-8 xl:w-2/3">
		${templateTitle("Reporting")}
		${loading.count > 0 ? html`<dc-loader></dc-loader>` : html` <div>
					<div class="flex items-end mt-8">
						<button class="btn btn-primary" @click=${() => downloadReport("Account")}>Download Account Report</button>
					</div>
					<div class="flex items-end mt-8">
						<button class="btn btn-primary" @click=${() => downloadReport("ClientRegistration")}>Download Client Registration Report</button>
					</div>
					<div class="flex items-end mt-8">
						<button class="btn btn-primary" @click=${() => downloadReport("SubscriptionFeedback")}>Download Subscription Feedback Report</button>
					</div>
			  </div>`}
	</div>`;
}
