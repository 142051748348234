import { DIGITS, LOWERCASE_CHARACTER, SPECIAL_CHARACTER, UPPERCASE_CHARACTER } from "GlobalShared/common/Constants";
const DEFAULTS = {
  length: 8,
  complexity: 3
};
export function validatePassword(password, _length, _complexity) {
  const length = _length !== void 0 ? _length : DEFAULTS.length;
  const complexity = _complexity !== void 0 ? _complexity : DEFAULTS.complexity;
  let complexityCount = 0;
  let lengthValid = false;
  if (password.length >= length) {
    lengthValid = true;
  }
  if (DIGITS.test(password)) {
    complexityCount++;
  }
  if (UPPERCASE_CHARACTER.test(password)) {
    complexityCount++;
  }
  if (LOWERCASE_CHARACTER.test(password)) {
    complexityCount++;
  }
  if (SPECIAL_CHARACTER.test(password)) {
    complexityCount++;
  }
  return lengthValid && complexityCount >= complexity;
}
