import { AbstractValidator } from "fluent-ts-validator";
import { DCValidationResult } from "GlobalShared/haunted/HooksHelpers";
import { getDay, getFirstDayOfMonth, getLastDayOfMonth, getLastDayOfPreviousMonth, MAX_DATE } from "GlobalShared/helpers/dateHelper";
import { MAX_DATE_MESSAGE, REQUIRED_MESSAGE } from "GlobalShared/helpers/stringHelper";
import i18next from "i18next";
import { calcNewComplianceEndDate, calcNewComplianceStartDate, calcEffectiveComplianceEndDate, canSetIntrastatStartDate } from "./CreateServiceModels";
export class IossSelectorValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIf((s) => s.tier).isNotEqualTo(void 0).withPropertyName("tier").withFailureMessage(REQUIRED_MESSAGE);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class IossServiceValidator extends AbstractValidator {
  constructor(iossSelection, currentCompanyServices) {
    super();
    this.validateIf((s) => s.hasIossNumber).isNotEqualTo(void 0).withPropertyName("hasIossNumber").withFailureMessage(REQUIRED_MESSAGE).when((_) => {
      var _a, _b;
      return (iossSelection == null ? void 0 : iossSelection.tier) !== void 0 && ((_b = (_a = currentCompanyServices == null ? void 0 : currentCompanyServices.GlobalServices) == null ? void 0 : _a.IossService) == null ? void 0 : _b.Tier) === void 0;
    });
    this.validateIf((s) => s.hasFixedEuEstablishment).isNotEqualTo(void 0).withPropertyName("hasFixedEuEstablishment").withFailureMessage(REQUIRED_MESSAGE).when((_) => {
      var _a, _b;
      return (iossSelection == null ? void 0 : iossSelection.tier) !== void 0 && ((_b = (_a = currentCompanyServices == null ? void 0 : currentCompanyServices.GlobalServices) == null ? void 0 : _a.IossService) == null ? void 0 : _b.Tier) === void 0;
    });
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export const SOME_VAT_COUNTRIES = "someVatCountries";
export class CountrySelectorValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIf((s) => s.some((taxCountry) => taxCountry.selected)).isEqualTo(true).withPropertyName("someVatCountries").withFailureMessage(i18next.t("At least one country must be selected"));
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class VatServicesValidator extends AbstractValidator {
  constructor(priceConfiguration) {
    super();
    this.validateIfEach((t) => t).fulfills(new VatCountryServiceValidator(priceConfiguration)).withPropertyName("vatCountries");
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class VatCountryServiceValidator extends AbstractValidator {
  constructor(priceConfiguration) {
    super();
    this.validateIf((s) => s.hasVatNumber).isDefined().withPropertyName("hasVatNumber").withFailureMessage(REQUIRED_MESSAGE).when((s) => {
      var _a;
      return s.selected && ((_a = priceConfiguration.perCountry[s.code]) == null ? void 0 : _a.registration) !== void 0;
    });
    this.validateIfDate((s) => s.vatStartingDate).isBefore(MAX_DATE).withPropertyName("vatStartingDate").withFailureMessage(MAX_DATE_MESSAGE).when((s) => {
      var _a;
      return s.selected && ((_a = priceConfiguration.perCountry[s.code]) == null ? void 0 : _a.registration) !== void 0;
    });
    this.validateIfDate((s) => s.vatStartingDate).isNotNull().withPropertyName("vatStartingDate").withFailureMessage(REQUIRED_MESSAGE).when((s) => {
      var _a;
      return s.selected && ((_a = priceConfiguration.perCountry[s.code]) == null ? void 0 : _a.registration) !== void 0;
    });
    this.validateIfDate((s) => s.intrastatInDate).isNotNull().withPropertyName("intrastatInDate").withFailureMessage(REQUIRED_MESSAGE).when((s) => canSetIntrastatStartDate(s.intrastatIn, s.origComplianceStartDate, s.origComplianceEndDate, s.origIntrastatInEndDate));
    this.validateIfDate((s) => s.intrastatOutDate).isNotNull().withPropertyName("intrastatOutDate").withFailureMessage(REQUIRED_MESSAGE).when((s) => canSetIntrastatStartDate(s.intrastatOut, s.origComplianceStartDate, s.origComplianceEndDate, s.origIntrastatOutEndDate));
    const getNewComplianceEndDate = (s) => calcNewComplianceEndDate(calcNewComplianceStartDate(s, getFirstDayOfMonth(/* @__PURE__ */ new Date())).newComplianceStartDate);
    const DATE_MUST_BE_BIGGER_THAN_COMPLIANCE_START_DATE = i18next.t("Date must be bigger or equal than compliance period start date");
    const DATE_MUST_BE_BIGGER_THAN_RESTART_START_DATE = i18next.t("Date must be bigger or equal than restart period start date");
    const DATE_MUST_BE_SMALLER_THAN_COMPLIANCE_END_DATE = i18next.t("Date must be smaller or equal than compliance period end date");
    const wrapFailureMessage = (msg) => {
      return msg;
    };
    this.validateIf((s) => s).fulfills((s) => s.intrastatInDate === void 0 || getDay(getFirstDayOfMonth(s.intrastatInDate)) >= getDay(getFirstDayOfMonth(s.vatStartingDate))).withPropertyName("intrastatInDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_BIGGER_THAN_COMPLIANCE_START_DATE)).when((s) => s.intrastatIn && s.selected && s.vatStartingDate !== void 0);
    this.validateIf((s) => s).fulfills((s) => s.intrastatOutDate === void 0 || getDay(getFirstDayOfMonth(s.intrastatOutDate)) >= getDay(getFirstDayOfMonth(s.vatStartingDate))).withPropertyName("intrastatOutDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_BIGGER_THAN_COMPLIANCE_START_DATE)).when((s) => s.intrastatOut && s.selected && s.vatStartingDate !== void 0);
    this.validateIf((s) => s).fulfills((s) => s.intrastatInDate === void 0 || getDay(s.intrastatInDate) >= getDay(getFirstDayOfMonth(s.origComplianceStartDate))).withPropertyName("intrastatInDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_BIGGER_THAN_COMPLIANCE_START_DATE)).when((s) => s.intrastatIn && !s.selected);
    this.validateIf((s) => s).fulfills((s) => s.intrastatOutDate === void 0 || getDay(s.intrastatOutDate) >= getDay(getFirstDayOfMonth(s.origComplianceStartDate))).withPropertyName("intrastatOutDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_BIGGER_THAN_COMPLIANCE_START_DATE)).when((s) => s.intrastatOut && !s.selected);
    this.validateIf((s) => s).fulfills((s) => s.intrastatInDate === void 0 || getDay(s.intrastatInDate) >= getDay(getFirstDayOfMonth(s.restartComplianceStartDate))).withPropertyName("intrastatInDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_BIGGER_THAN_RESTART_START_DATE)).when((s) => s.intrastatIn && s.selected && s.restartCompliance && s.restartComplianceStartDate !== void 0);
    this.validateIf((s) => s).fulfills((s) => s.intrastatOutDate === void 0 || getDay(s.intrastatOutDate) >= getDay(getFirstDayOfMonth(s.restartComplianceStartDate))).withPropertyName("intrastatOutDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_BIGGER_THAN_RESTART_START_DATE)).when((s) => s.intrastatOut && s.selected && s.restartCompliance && s.restartComplianceStartDate !== void 0);
    this.validateIf((s) => s).fulfills((s) => s.intrastatInDate === void 0 || getDay(s.intrastatInDate) >= getDay(getFirstDayOfMonth(s.origComplianceStartDate))).withPropertyName("intrastatInDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_BIGGER_THAN_COMPLIANCE_START_DATE)).when((s) => s.intrastatIn && s.selected && s.origComplianceStartDate !== void 0 && !s.monthlyCompliance);
    this.validateIf((s) => s).fulfills((s) => s.intrastatOutDate === void 0 || getDay(s.intrastatOutDate) >= getDay(getFirstDayOfMonth(s.origComplianceStartDate))).withPropertyName("intrastatOutDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_BIGGER_THAN_COMPLIANCE_START_DATE)).when((s) => s.intrastatOut && s.selected && s.origComplianceStartDate !== void 0 && !s.monthlyCompliance);
    this.validateIf((s) => s).fulfills((s) => s.intrastatInDate === void 0 || getDay(s.intrastatInDate) >= getDay(getFirstDayOfMonth(s.origComplianceStartDate))).withPropertyName("intrastatInDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_BIGGER_THAN_COMPLIANCE_START_DATE)).when((s) => s.intrastatIn && s.selected && s.monthlyCompliance && s.monthlyComplianceEndDate !== void 0);
    this.validateIf((s) => s).fulfills((s) => s.intrastatOutDate === void 0 || getDay(s.intrastatOutDate) >= getDay(getFirstDayOfMonth(s.origComplianceStartDate))).withPropertyName("intrastatOutDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_BIGGER_THAN_COMPLIANCE_START_DATE)).when((s) => s.intrastatOut && s.selected && s.monthlyCompliance && s.monthlyComplianceEndDate !== void 0);
    this.validateIf((s) => s).fulfills((s) => s.intrastatInDate === void 0 || getDay(s.intrastatInDate) <= getDay(getNewComplianceEndDate(s))).withPropertyName("intrastatInDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_SMALLER_THAN_COMPLIANCE_END_DATE)).when((s) => s.intrastatIn && s.selected && s.vatStartingDate !== void 0);
    this.validateIf((s) => s).fulfills((s) => s.intrastatOutDate === void 0 || getDay(s.intrastatOutDate) <= getDay(getNewComplianceEndDate(s))).withPropertyName("intrastatOutDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_SMALLER_THAN_COMPLIANCE_END_DATE)).when((s) => s.intrastatOut && s.selected && s.vatStartingDate !== void 0);
    this.validateIf((s) => s).fulfills((s) => s.intrastatInDate === void 0 || getDay(s.intrastatInDate) <= getDay(getLastDayOfMonth(s.origComplianceEndDate))).withPropertyName("intrastatInDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_SMALLER_THAN_COMPLIANCE_END_DATE)).when((s) => s.intrastatIn && !s.selected);
    this.validateIf((s) => s).fulfills((s) => s.intrastatOutDate === void 0 || getDay(s.intrastatOutDate) <= getDay(getLastDayOfMonth(s.origComplianceEndDate))).withPropertyName("intrastatOutDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_SMALLER_THAN_COMPLIANCE_END_DATE)).when((s) => s.intrastatOut && !s.selected);
    this.validateIf((s) => s).fulfills((s) => s.intrastatInDate === void 0 || getDay(s.intrastatInDate) <= getDay(getNewComplianceEndDate(s))).withPropertyName("intrastatInDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_SMALLER_THAN_COMPLIANCE_END_DATE)).when((s) => s.intrastatIn && s.selected && s.restartCompliance && s.restartComplianceStartDate !== void 0);
    this.validateIf((s) => s).fulfills((s) => s.intrastatOutDate === void 0 || getDay(s.intrastatOutDate) <= getDay(getNewComplianceEndDate(s))).withPropertyName("intrastatOutDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_SMALLER_THAN_COMPLIANCE_END_DATE)).when((s) => s.intrastatOut && s.selected && s.restartCompliance && s.restartComplianceStartDate !== void 0);
    this.validateIf((s) => s).fulfills((s) => s.intrastatInDate === void 0 || getDay(s.intrastatInDate) <= getDay(getNewComplianceEndDate(s))).withPropertyName("intrastatInDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_SMALLER_THAN_COMPLIANCE_END_DATE)).when((s) => s.intrastatIn && s.selected && !s.monthlyCompliance);
    this.validateIf((s) => s).fulfills((s) => s.intrastatOutDate === void 0 || getDay(s.intrastatOutDate) <= getDay(getNewComplianceEndDate(s))).withPropertyName("intrastatOutDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_SMALLER_THAN_COMPLIANCE_END_DATE)).when((s) => s.intrastatOut && s.selected && !s.monthlyCompliance);
    this.validateIf((s) => s).fulfills((s) => s.intrastatInDate === void 0 || getDay(s.intrastatInDate) <= getDay(getLastDayOfMonth(s.monthlyComplianceEndDate))).withPropertyName("intrastatInDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_SMALLER_THAN_COMPLIANCE_END_DATE)).when((s) => s.intrastatIn && s.selected && s.monthlyCompliance && s.monthlyComplianceEndDate !== void 0);
    this.validateIf((s) => s).fulfills((s) => s.intrastatOutDate === void 0 || getDay(s.intrastatOutDate) <= getDay(getLastDayOfMonth(s.monthlyComplianceEndDate))).withPropertyName("intrastatOutDate").withFailureMessage(wrapFailureMessage(DATE_MUST_BE_SMALLER_THAN_COMPLIANCE_END_DATE)).when((s) => s.intrastatOut && s.selected && s.monthlyCompliance && s.monthlyComplianceEndDate !== void 0);
    this.validateIf((s) => s.spanishBankAccount).isNotNull().withPropertyName("spanishBankAccount").withFailureMessage(REQUIRED_MESSAGE).when((s) => {
      var _a;
      return s.selected && ((_a = priceConfiguration.perCountry[s.code]) == null ? void 0 : _a.spanishBankAccount) !== void 0;
    });
    const selfRevisionValidationWhen = (s) => {
      return (s.selected || s.origComplianceEndDate !== void 0) && s.selfRevision;
    };
    this.validateIfDate((s) => s.selfRevisionStartDate).isNotNull().withPropertyName("selfRevisionStartDate").withFailureMessage(REQUIRED_MESSAGE).when(selfRevisionValidationWhen);
    this.validateIfDate((s) => s.selfRevisionStartDate).isBefore(MAX_DATE).withPropertyName("selfRevisionStartDate").withFailureMessage(MAX_DATE_MESSAGE).when(selfRevisionValidationWhen);
    this.validateIfDate((s) => s.selfRevisionEndDate).isNotNull().withPropertyName("selfRevisionEndDate").withFailureMessage(REQUIRED_MESSAGE).when(selfRevisionValidationWhen);
    this.validateIf((s) => s).fulfills((s) => getDay(s.selfRevisionEndDate) <= getLastDayOfMonth(/* @__PURE__ */ new Date())).withPropertyName("selfRevisionEndDate").withFailureMessage(i18next.t("Can't be later than the current month")).when(selfRevisionValidationWhen);
    this.validateIf((s) => s).fulfills((s) => getDay(s.selfRevisionEndDate) <= calcEffectiveComplianceEndDate(s, /* @__PURE__ */ new Date())).withPropertyName("selfRevisionEndDate").withFailureMessage(i18next.t("Must be smaller or equal with the current or would-be compliance end date")).when(selfRevisionValidationWhen);
    this.validateIf((s) => s).fulfills((s) => s.origComplianceStartDate === void 0 || getLastDayOfMonth(s.selfRevisionEndDate) >= getLastDayOfPreviousMonth(s.origComplianceStartDate)).withPropertyName("selfRevisionEndDate").withFailureMessage(i18next.t("Can't be smaller than the original compliance start date")).when(selfRevisionValidationWhen);
    this.validateIf((s) => s).fulfills((s) => s.selfRevisionEndDate > s.selfRevisionStartDate).withPropertyName("selfRevisionEndDate").withFailureMessage(i18next.t("Must be bigger than Start Date")).when((s) => s.selfRevisionStartDate !== void 0 && s.selfRevisionEndDate !== void 0);
    const retrospectiveValidationWhen = (s) => {
      return (s.selected || s.origComplianceEndDate !== void 0) && s.retrospective && !s.restartCompliance;
    };
    this.validateIfDate((s) => s.retrospectiveStartDate).isNotNull().withPropertyName("retrospectiveStartDate").withFailureMessage(REQUIRED_MESSAGE).when(retrospectiveValidationWhen);
    this.validateIf((s) => s).fulfills((s) => getDay(s.retrospectiveStartDate) < getDay(getFirstDayOfMonth(s.origComplianceStartDate))).withPropertyName("retrospectiveStartDate").withFailureMessage(i18next.t("Must be lower than the first day of the compliance start month")).when((s) => s.retrospective && !s.restartCompliance && s.origComplianceStartDate !== void 0);
    this.validateIfDate((s) => s.restartComplianceStartDate).isNotNull().withPropertyName("restartComplianceStartDate").withFailureMessage(REQUIRED_MESSAGE).when((s) => s.selected && s.origComplianceEndDate !== void 0 && s.restartCompliance);
    this.validateIfDate((s) => s.restartComplianceStartDate).isBefore(MAX_DATE).withPropertyName("restartComplianceStartDate").withFailureMessage(MAX_DATE_MESSAGE).when((s) => s.selected && s.origComplianceEndDate !== void 0 && s.restartCompliance);
    this.validateIf((s) => s).fulfills((s) => getDay(s.restartComplianceStartDate) >= getDay(s.origComplianceEndDate)).withPropertyName("restartComplianceStartDate").withFailureMessage(i18next.t("Restart date must be bigger than or equal to compliance end date")).when((s) => s.restartComplianceStartDate !== void 0 && s.restartCompliance);
    this.validateIfDate((s) => s.monthlyComplianceEndDate).isNotNull().withPropertyName("monthlyComplianceEndDate").withFailureMessage(REQUIRED_MESSAGE).when((s) => s.selected && s.origComplianceEndDate !== void 0 && s.monthlyCompliance);
    this.validateIfDate((s) => s.monthlyComplianceEndDate).isBefore(MAX_DATE).withPropertyName("monthlyComplianceEndDate").withFailureMessage(MAX_DATE_MESSAGE).when((s) => s.selected && s.origComplianceEndDate !== void 0 && s.monthlyCompliance);
    this.validateIf((s) => s).fulfills((s) => getDay(s.monthlyComplianceEndDate) > getDay(s.origComplianceEndDate)).withPropertyName("monthlyComplianceEndDate").withFailureMessage(i18next.t("Monthly compliance end date must be bigger than compliance end date")).when((s) => s.monthlyComplianceEndDate !== void 0 && s.monthlyCompliance);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
export class GlobalServiceValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIf((s) => s.generalVatAdviceHours).isDefined().withPropertyName("generalVatAdviceHours").withFailureMessage(REQUIRED_MESSAGE).when((s) => s.generalVatAdvice);
    this.validateIf((s) => s.specialistVatAdvicePrice).isDefined().withPropertyName("specialistVatAdvice").withFailureMessage(REQUIRED_MESSAGE).when((s) => s.specialistVatAdvice);
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
