import { Answer } from "./../Answer";
export class FileReferenceAnswer extends Answer {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof FileReferenceAnswer
     */
    this.$type = "V1.CompanyInfo.Questionnaire.Answers.FileReferenceAnswer";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.OriginalName = _data["OriginalName"];
      this.Value = _data["Value"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["OriginalName"] = this.OriginalName;
    _data["Value"] = this.Value;
    return _data;
  }
}
