import { ReturnInformation } from "./ReturnInformation";
import { IntrastatReturnInformation } from "./IntrastatReturnInformation";
import { PrimaryVatReturnInformation } from "./PrimaryVatReturnInformation";
export class ReturnInformationFactory {
  static create($type) {
    if ($type === "V1.Submission.StatusChanges.ReturnInformation") {
      const result = new ReturnInformation();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.IntrastatReturnInformation") {
      const result = new IntrastatReturnInformation();
      return result;
    }
    if ($type === "V1.Submission.StatusChanges.PrimaryVatReturnInformation") {
      const result = new PrimaryVatReturnInformation();
      return result;
    }
    throw new Error($type + " not found");
  }
}
