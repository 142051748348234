export class VatRegTemplateFilter {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRegTemplateFilter
     */
    this.$type = "V1.TemplateMapping.TemplateSearch.VatRegTemplateFilter";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Category = VatRegTemplateFilterCategory[_data["Category"]];
      this.CompanyType = VatRegTemplateFilterCompanyType[_data["CompanyType"]];
      this.Country = _data["Country"];
      this.Culture = _data["Culture"];
      this.EstablishmentCountry = _data["EstablishmentCountry"];
      this.Name = _data["Name"];
      this.RegistrationType = VatRegTemplateFilterRegistrationType[_data["RegistrationType"]];
      this.TemplateStatus = VatRegTemplateFilterTemplateStatus[_data["TemplateStatus"]];
      this.Trigger = VatRegTemplateFilterTrigger[_data["Trigger"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Category"] = VatRegTemplateFilterCategory[this.Category];
    _data["CompanyType"] = VatRegTemplateFilterCompanyType[this.CompanyType];
    _data["Country"] = this.Country;
    _data["Culture"] = this.Culture;
    _data["EstablishmentCountry"] = this.EstablishmentCountry;
    _data["Name"] = this.Name;
    _data["RegistrationType"] = VatRegTemplateFilterRegistrationType[this.RegistrationType];
    _data["TemplateStatus"] = VatRegTemplateFilterTemplateStatus[this.TemplateStatus];
    _data["Trigger"] = VatRegTemplateFilterTrigger[this.Trigger];
    return _data;
  }
}
export var VatRegTemplateFilterCategory = /* @__PURE__ */ ((VatRegTemplateFilterCategory2) => {
  VatRegTemplateFilterCategory2["RequiredElectronicallyOnly"] = "RequiredElectronicallyOnly";
  VatRegTemplateFilterCategory2["RequiredByPost"] = "RequiredByPost";
  VatRegTemplateFilterCategory2["ForInformationOnly"] = "ForInformationOnly";
  return VatRegTemplateFilterCategory2;
})(VatRegTemplateFilterCategory || {});
export var VatRegTemplateFilterCompanyType = /* @__PURE__ */ ((VatRegTemplateFilterCompanyType2) => {
  VatRegTemplateFilterCompanyType2["Individual"] = "Individual";
  VatRegTemplateFilterCompanyType2["Company"] = "Company";
  return VatRegTemplateFilterCompanyType2;
})(VatRegTemplateFilterCompanyType || {});
export var VatRegTemplateFilterRegistrationType = /* @__PURE__ */ ((VatRegTemplateFilterRegistrationType2) => {
  VatRegTemplateFilterRegistrationType2["NewReg"] = "NewReg";
  VatRegTemplateFilterRegistrationType2["TakeOver"] = "TakeOver";
  return VatRegTemplateFilterRegistrationType2;
})(VatRegTemplateFilterRegistrationType || {});
export var VatRegTemplateFilterTemplateStatus = /* @__PURE__ */ ((VatRegTemplateFilterTemplateStatus2) => {
  VatRegTemplateFilterTemplateStatus2["Draft"] = "Draft";
  VatRegTemplateFilterTemplateStatus2["Enabled"] = "Enabled";
  VatRegTemplateFilterTemplateStatus2["Disabled"] = "Disabled";
  return VatRegTemplateFilterTemplateStatus2;
})(VatRegTemplateFilterTemplateStatus || {});
export var VatRegTemplateFilterTrigger = /* @__PURE__ */ ((VatRegTemplateFilterTrigger2) => {
  VatRegTemplateFilterTrigger2["Q11EApproved"] = "Q11EApproved";
  VatRegTemplateFilterTrigger2["Q11ECompleted"] = "Q11ECompleted";
  VatRegTemplateFilterTrigger2["Q11EIncomplete"] = "Q11EIncomplete";
  VatRegTemplateFilterTrigger2["Q11EVerified"] = "Q11EVerified";
  VatRegTemplateFilterTrigger2["CompanyCreated"] = "CompanyCreated";
  VatRegTemplateFilterTrigger2["CompanyRejected"] = "CompanyRejected";
  VatRegTemplateFilterTrigger2["VatRegPending"] = "VatRegPending";
  VatRegTemplateFilterTrigger2["VatRegWaitingForSignature"] = "VatRegWaitingForSignature";
  VatRegTemplateFilterTrigger2["VatRegDocumentsSigned"] = "VatRegDocumentsSigned";
  VatRegTemplateFilterTrigger2["VatRegTranslationInProgress"] = "VatRegTranslationInProgress";
  VatRegTemplateFilterTrigger2["VatRegSentToTA"] = "VatRegSentToTA";
  VatRegTemplateFilterTrigger2["VatRegLocalTaxReceived"] = "VatRegLocalTaxReceived";
  VatRegTemplateFilterTrigger2["VatRegRegistrationCompleted"] = "VatRegRegistrationCompleted";
  return VatRegTemplateFilterTrigger2;
})(VatRegTemplateFilterTrigger || {});
