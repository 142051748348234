import { Answer } from "./Answer";
import { FileReferenceAnswer } from "./Answers/FileReferenceAnswer";
import { AccountingLocationAnswer } from "./Answers/AccountingLocationAnswer";
import { AddressAnswer } from "./Answers/AddressAnswer";
import { BankInformationAnswer } from "./Answers/BankInformationAnswer";
import { BeneficialOwnersAnswer } from "./Answers/BeneficialOwnersAnswer";
import { BoolAnswer } from "./Answers/BoolAnswer";
import { BusinessOwnersAnswer } from "./Answers/BusinessOwnersAnswer";
import { ClientBaseAnswer } from "./Answers/ClientBaseAnswer";
import { CountryAnswer } from "./Answers/CountryAnswer";
import { DateAnswer } from "./Answers/DateAnswer";
import { ExistingVatNumbersAnswer } from "./Answers/ExistingVatNumbersAnswer";
import { FixedEstablishmentsAnswer } from "./Answers/FixedEstablishmentsAnswer";
import { LegalEntityFormAnswer } from "./Answers/LegalEntityFormAnswer";
import { LegalEntityTypeAnswer } from "./Answers/LegalEntityTypeAnswer";
import { LegalRepresentativesAnswer } from "./Answers/LegalRepresentativesAnswer";
import { MoneyAnswer } from "./Answers/MoneyAnswer";
import { MultiCountryAnswer } from "./Answers/MultiCountryAnswer";
import { MultiStringAnswer } from "./Answers/MultiStringAnswer";
import { NameAnswer } from "./Answers/NameAnswer";
import { OssDetailAnswer } from "./Answers/OssDetailAnswer";
import { ProductTypeAnswer } from "./Answers/ProductTypeAnswer";
import { StringAnswer } from "./Answers/StringAnswer";
import { TurnOversAnswer } from "./Answers/TurnOversAnswer";
import { VatNumberAnswer } from "./Answers/VatNumberAnswer";
export class AnswerFactory {
  static create($type) {
    if ($type === "V1.CompanyInfo.Questionnaire.Answer") {
      const result = new Answer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.FileReferenceAnswer") {
      const result = new FileReferenceAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.AccountingLocationAnswer") {
      const result = new AccountingLocationAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.AddressAnswer") {
      const result = new AddressAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.BankInformationAnswer") {
      const result = new BankInformationAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.BeneficialOwnersAnswer") {
      const result = new BeneficialOwnersAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.BoolAnswer") {
      const result = new BoolAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.BusinessOwnersAnswer") {
      const result = new BusinessOwnersAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.ClientBaseAnswer") {
      const result = new ClientBaseAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.CountryAnswer") {
      const result = new CountryAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.DateAnswer") {
      const result = new DateAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.ExistingVatNumbersAnswer") {
      const result = new ExistingVatNumbersAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.FixedEstablishmentsAnswer") {
      const result = new FixedEstablishmentsAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.LegalEntityFormAnswer") {
      const result = new LegalEntityFormAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.LegalEntityTypeAnswer") {
      const result = new LegalEntityTypeAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.LegalRepresentativesAnswer") {
      const result = new LegalRepresentativesAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.MoneyAnswer") {
      const result = new MoneyAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.MultiCountryAnswer") {
      const result = new MultiCountryAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.MultiStringAnswer") {
      const result = new MultiStringAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.NameAnswer") {
      const result = new NameAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.OssDetailAnswer") {
      const result = new OssDetailAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.ProductTypeAnswer") {
      const result = new ProductTypeAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.StringAnswer") {
      const result = new StringAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.TurnOversAnswer") {
      const result = new TurnOversAnswer();
      return result;
    }
    if ($type === "V1.CompanyInfo.Questionnaire.Answers.VatNumberAnswer") {
      const result = new VatNumberAnswer();
      return result;
    }
    throw new Error($type + " not found");
  }
}
