import { Name } from "./../Common/Person/Name";
import { BusinessDetail } from "./BusinessDetail";
export class IndividualDetail extends BusinessDetail {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof IndividualDetail
     */
    this.$type = "V1.CompanyInfo.IndividualDetail";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["Name"]) {
        const name_ = new Name();
        this.Name = name_.init(_data["Name"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["Name"] = this.Name ? this.Name.toJSON() : void 0;
    return _data;
  }
}
