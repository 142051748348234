export var MigrationSubscriptionsPrimaryFrequency = /* @__PURE__ */ ((MigrationSubscriptionsPrimaryFrequency2) => {
  MigrationSubscriptionsPrimaryFrequency2["Monthly"] = "Monthly";
  MigrationSubscriptionsPrimaryFrequency2["Quarterly"] = "Quarterly";
  MigrationSubscriptionsPrimaryFrequency2["QuarterlyShifted1"] = "QuarterlyShifted1";
  MigrationSubscriptionsPrimaryFrequency2["QuarterlyShifted2"] = "QuarterlyShifted2";
  MigrationSubscriptionsPrimaryFrequency2["BiMonthly"] = "BiMonthly";
  MigrationSubscriptionsPrimaryFrequency2["FourMonthly"] = "FourMonthly";
  MigrationSubscriptionsPrimaryFrequency2["SixMonthly"] = "SixMonthly";
  MigrationSubscriptionsPrimaryFrequency2["Yearly"] = "Yearly";
  return MigrationSubscriptionsPrimaryFrequency2;
})(MigrationSubscriptionsPrimaryFrequency || {});
export var MigrationSubscriptionsSecondaryFrequency = /* @__PURE__ */ ((MigrationSubscriptionsSecondaryFrequency2) => {
  MigrationSubscriptionsSecondaryFrequency2["Monthly"] = "Monthly";
  MigrationSubscriptionsSecondaryFrequency2["Quarterly"] = "Quarterly";
  MigrationSubscriptionsSecondaryFrequency2["QuarterlyShifted1"] = "QuarterlyShifted1";
  MigrationSubscriptionsSecondaryFrequency2["QuarterlyShifted2"] = "QuarterlyShifted2";
  MigrationSubscriptionsSecondaryFrequency2["BiMonthly"] = "BiMonthly";
  MigrationSubscriptionsSecondaryFrequency2["FourMonthly"] = "FourMonthly";
  MigrationSubscriptionsSecondaryFrequency2["SixMonthly"] = "SixMonthly";
  MigrationSubscriptionsSecondaryFrequency2["Yearly"] = "Yearly";
  return MigrationSubscriptionsSecondaryFrequency2;
})(MigrationSubscriptionsSecondaryFrequency || {});
export var MigrationSubscriptionsEslFrequency = /* @__PURE__ */ ((MigrationSubscriptionsEslFrequency2) => {
  MigrationSubscriptionsEslFrequency2["Monthly"] = "Monthly";
  MigrationSubscriptionsEslFrequency2["Quarterly"] = "Quarterly";
  MigrationSubscriptionsEslFrequency2["QuarterlyShifted1"] = "QuarterlyShifted1";
  MigrationSubscriptionsEslFrequency2["QuarterlyShifted2"] = "QuarterlyShifted2";
  MigrationSubscriptionsEslFrequency2["BiMonthly"] = "BiMonthly";
  MigrationSubscriptionsEslFrequency2["FourMonthly"] = "FourMonthly";
  MigrationSubscriptionsEslFrequency2["SixMonthly"] = "SixMonthly";
  MigrationSubscriptionsEslFrequency2["Yearly"] = "Yearly";
  return MigrationSubscriptionsEslFrequency2;
})(MigrationSubscriptionsEslFrequency || {});
export var MigrationSubscriptionsVlsFrequency = /* @__PURE__ */ ((MigrationSubscriptionsVlsFrequency2) => {
  MigrationSubscriptionsVlsFrequency2["Monthly"] = "Monthly";
  MigrationSubscriptionsVlsFrequency2["Quarterly"] = "Quarterly";
  MigrationSubscriptionsVlsFrequency2["QuarterlyShifted1"] = "QuarterlyShifted1";
  MigrationSubscriptionsVlsFrequency2["QuarterlyShifted2"] = "QuarterlyShifted2";
  MigrationSubscriptionsVlsFrequency2["BiMonthly"] = "BiMonthly";
  MigrationSubscriptionsVlsFrequency2["FourMonthly"] = "FourMonthly";
  MigrationSubscriptionsVlsFrequency2["SixMonthly"] = "SixMonthly";
  MigrationSubscriptionsVlsFrequency2["Yearly"] = "Yearly";
  return MigrationSubscriptionsVlsFrequency2;
})(MigrationSubscriptionsVlsFrequency || {});
export var MigrationSubscriptionsRegistrationStatus = /* @__PURE__ */ ((MigrationSubscriptionsRegistrationStatus2) => {
  MigrationSubscriptionsRegistrationStatus2["LocalTaxReceived"] = "LocalTaxReceived";
  MigrationSubscriptionsRegistrationStatus2["RegistrationCompleted"] = "RegistrationCompleted";
  return MigrationSubscriptionsRegistrationStatus2;
})(MigrationSubscriptionsRegistrationStatus || {});
export var MigrationSubscriptionsIntrastatFrequency = /* @__PURE__ */ ((MigrationSubscriptionsIntrastatFrequency2) => {
  MigrationSubscriptionsIntrastatFrequency2["Monthly"] = "Monthly";
  MigrationSubscriptionsIntrastatFrequency2["Quarterly"] = "Quarterly";
  MigrationSubscriptionsIntrastatFrequency2["QuarterlyShifted1"] = "QuarterlyShifted1";
  MigrationSubscriptionsIntrastatFrequency2["QuarterlyShifted2"] = "QuarterlyShifted2";
  MigrationSubscriptionsIntrastatFrequency2["BiMonthly"] = "BiMonthly";
  MigrationSubscriptionsIntrastatFrequency2["FourMonthly"] = "FourMonthly";
  MigrationSubscriptionsIntrastatFrequency2["SixMonthly"] = "SixMonthly";
  MigrationSubscriptionsIntrastatFrequency2["Yearly"] = "Yearly";
  return MigrationSubscriptionsIntrastatFrequency2;
})(MigrationSubscriptionsIntrastatFrequency || {});
