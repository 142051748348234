import { useState } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { getLocalizedUrl } from "../globals";
import { validateEmail } from "GlobalShared/validators/email-validator";
import { useEffect } from "haunted";
import { SessionManagerStorage } from "AdminServices/session/SessionManagerStorage";
import { useGoogleReCaptcha } from "GlobalShared/reCaptcha/useGoogleReCaptcha";
export const observedAttributes = ["email", "error"];
export const name = "ww-login";
export const DEFAULT_LOCKOUT_TIME_IN_MINUTES = 15;
export const Component = (host) => {
  const props = {
    email: host.email,
    error: host.error
  };
  const generateError = () => {
    if (props.error) {
      switch (props.error) {
        case "User account locked out.":
          return `Your account is locked out for ${DEFAULT_LOCKOUT_TIME_IN_MINUTES} minutes. Please contact our support team.`;
        case "Invalid login attempt.":
          return "The e-mail address and password do not match.";
        default:
          return props.error;
      }
    }
    if (email && !validateEmail(email)) {
      return "Please provide a valid e-mail address.";
    }
    return void 0;
  };
  const hasError = () => {
    return !email || !password || generateError() !== void 0 ? true : false;
  };
  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState(void 0);
  const [dontHaveAccountYetOpened, setDontHaveAccountYetOpened] = useState(false);
  const submitWithCaptcha = useGoogleReCaptcha("loginForm", "AdminLogin");
  useEffect(() => {
    new SessionManagerStorage().clearPermissions();
  }, []);
  return html`<div class="w-full px-4 md:w-1/2 md:p-24">
		<div>
			<a href="${getLocalizedUrl("")}" data-routing="server">
				<img src="/images/kpmg_logo.png" alt="KPMG logo" />
			</a>
		</div>
		<div class="md:text-xl font-bold py-6 lg:py-12 text-center max-w-screen-lg m-auto text-shadow">
			${"Welcome to the Admin site! Please note that you need to be invited in order to login. If you don\u2019t have access but wish to login, please contact the project leaders."}
		</div>
		<div class="text-3xl font-bold py-8">Sign in to your account</div>
		<div class="bg-white shadow-md rounded px-8 py-4 m-auto flex flex-col">
			<div class="mb-4">
				<dc-input .label=${"Email"} .name=${"Email"} .value=${email} .placeholder=${"Email"} @change=${(e) => setEmail(e.detail.value)}></dc-input>
			</div>
			<div class="mb-6">
				<dc-input
					.label=${"Password"}
					.type=${"password"}
					.name=${"Password"}
					.value=${password}
					.placeholder=${"*************"}
					@change=${(e) => setPassword(e.detail.value)}
				></dc-input>
			</div>
			<div>
				<div class="absolute text-red-400 ${!hasError() ? "invisible" : ""}">${generateError()}</div>
			</div>
			<div class="text-right mt-8">
				<button class="btn btn-primary" @click=${submitWithCaptcha}>${"Sign in"}</button>
			</div>
			<div class="md:flex justify-between">
				<div>
					<a class="link" href=${getLocalizedUrl("/account/forgotpassword")} data-routing="server">Forgot your password?</a>
				</div>
				<div class="mt-4 md:mt-0">
					<a class="link" href="javascript:void(0);" @click=${() => setDontHaveAccountYetOpened(!dontHaveAccountYetOpened)} data-routing="server">Don't have account yet?</a>
				</div>
			</div>
			${dontHaveAccountYetOpened ? html`<div class="mt-4">
						This admin site is only available via invitation. Please write to support:
						<a class="link" href="mailto:tobedefined@kpmg.hu">tobedefined@kpmg.hu</a>
				  </div>` : ""}
		</div>
	</div>`;
};
