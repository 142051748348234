import "./dc-notifier.scss";
import { html } from "lit-html";
import { repeat } from "lit-html/directives/repeat";
import { component, useEffect } from "haunted";
import { classMap } from "lit-html/directives/class-map";
import { DcNotifierReducer } from "./dc-notifier-reducer";
import { ReduxContext } from "GlobalShared/ReduxContext";
import { useReduxState } from "GlobalShared/haunted/CustomHooks";
export var DcNotificationType = /* @__PURE__ */ ((DcNotificationType2) => {
  DcNotificationType2["Success"] = "Success";
  DcNotificationType2["Warning"] = "Warning";
  DcNotificationType2["Error"] = "Error";
  return DcNotificationType2;
})(DcNotificationType || {});
let id = 0;
const removeNote = (noteId) => {
  ReduxContext.store.dispatch(DcNotifierReducer.removeNotification(noteId));
};
export const Component = () => {
  const notes = useReduxState(DcNotifierReducer.getPath("notifications"));
  useEffect(() => {
    if (notes == null ? void 0 : notes.some((note) => note.isProcessed === void 0)) {
      ReduxContext.store.dispatch(
        DcNotifierReducer.changeNotifications((note) => {
          if (note.isProcessed === void 0) {
            note.isProcessed = true;
            note.id = id++;
            if (note.autoRemove) {
              window.setTimeout(() => removeNote(note.id), 6e3);
            }
          }
          return note;
        })
      );
    }
  }, [notes]);
  return html`${notes !== void 0 ? html`<ul class="dc-notifier" id="dcNotifier">
				${repeat(
    notes,
    (note) => note.id,
    (note) => html`<li
							class=${classMap({
      "animate-appear-and-disappear": note.autoRemove,
      "animate-appear": !note.autoRemove
    })}
						>
							${!note.autoRemove ? html`<button @click=${() => removeNote(note.id)}>&times;</button>` : ""}
							<span
								class=${classMap({
      "text-white": true,
      "bg-red-500": note.type === "Error" /* Error */,
      "bg-green-600": note.type === "Success" /* Success */,
      "bg-orange-600": note.type === "Warning" /* Warning */
    })}
								>${Array.isArray(note.message) ? note.message.map((m) => html`<span class="notification-line">${m}</span>`) : note.message}</span
							>
						</li>`
  )}
		  </ul>` : ""}`;
};
customElements.define(
  "dc-notifier",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
