import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { PossibleStatusChange } from "./../models/V1/Company/VatRegistration/PossibleStatusChange";
import { VatRegSearchResult } from "./../models/V1/Company/VatRegistration/Search/VatRegSearchResult";
import { DataSourceField } from "./../models/V1/DocGenerator/DataSourceField";
import { PredefinedBankAccount } from "./../models/V1/CompanyInfo/VatRegistration/PredefinedBankAccount";
export class VatRegistration {
  /**
   * Creates an instance of VatRegistration.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof VatRegistration
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param DownloadVatRegDocumentRq_ _downloadVatRegDocumentRq
   * @returns Promise<DownloadVatRegDocumentRq_>
   * @memberof VatRegistration
   */
  downloadVatRegDocument(pDownloadVatRegDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registration/{vatRegId}/template/{templateId}",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addQueryParams([
      { name: "type", value: pDownloadVatRegDocumentRq.type }
    ]);
    client.addPathParams([
      { name: "companyId", value: pDownloadVatRegDocumentRq.companyId, required: true },
      { name: "vatRegId", value: pDownloadVatRegDocumentRq.vatRegId, required: true },
      { name: "templateId", value: pDownloadVatRegDocumentRq.templateId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadSignedVatRegDocumentRq_ _uploadSignedVatRegDocumentRq
   * @returns Promise<UploadSignedVatRegDocumentRq_>
   * @memberof VatRegistration
   */
  uploadSignedVatRegDocument(pUploadSignedVatRegDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registration/{vatRegId}/template/{templateId}/signed",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUploadSignedVatRegDocumentRq.companyId, required: true },
      { name: "vatRegId", value: pUploadSignedVatRegDocumentRq.vatRegId, required: true },
      { name: "templateId", value: pUploadSignedVatRegDocumentRq.templateId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadSignedVatRegDocumentRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetAvailableStatusChangesRq_ _getAvailableStatusChangesRq
   * @returns Promise<GetAvailableStatusChangesRq_>
   * @memberof VatRegistration
   */
  getAvailableStatusChanges(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/registrations/statuses",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: PossibleStatusChange, isArray: true },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeVatRegStatusRq_ _changeVatRegStatusRq
   * @returns Promise<ChangeVatRegStatusRq_>
   * @memberof VatRegistration
   */
  changeVatRegStatus(pChangeVatRegStatusRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registration/{vatRegId}/status",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeVatRegStatusRq.companyId, required: true },
      { name: "vatRegId", value: pChangeVatRegStatusRq.vatRegId, required: true }
    ]);
    client.addBody({ value: pChangeVatRegStatusRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadVatCertificateRq_ _downloadVatCertificateRq
   * @returns Promise<DownloadVatCertificateRq_>
   * @memberof VatRegistration
   */
  downloadVatCertificate(pDownloadVatCertificateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registration/{vatRegId}/certificate/{certificateType}",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDownloadVatCertificateRq.companyId, required: true },
      { name: "vatRegId", value: pDownloadVatCertificateRq.vatRegId, required: true },
      { name: "certificateType", value: pDownloadVatCertificateRq.certificateType, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadDeregistrationDocumentRq_ _downloadDeregistrationDocumentRq
   * @returns Promise<DownloadDeregistrationDocumentRq_>
   * @memberof VatRegistration
   */
  downloadDeregistrationDocument(pDownloadDeregistrationDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registration/{vatRegId}/deregistrationDocument",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDownloadDeregistrationDocumentRq.companyId, required: true },
      { name: "vatRegId", value: pDownloadDeregistrationDocumentRq.vatRegId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadPoaRevokedConfirmationDocumentRq_ _downloadPoaRevokedConfirmationDocumentRq
   * @returns Promise<DownloadPoaRevokedConfirmationDocumentRq_>
   * @memberof VatRegistration
   */
  downloadPoaRevokedConfirmationDocument(pDownloadPoaRevokedConfirmationDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registration/{vatRegId}/poarevokeconfirmationdocument",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDownloadPoaRevokedConfirmationDocumentRq.companyId, required: true },
      { name: "vatRegId", value: pDownloadPoaRevokedConfirmationDocumentRq.vatRegId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadDocumentRq_ _uploadDocumentRq
   * @returns Promise<UploadDocumentRq_>
   * @memberof VatRegistration
   */
  uploadDocument(pUploadDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registration/{vatRegId}/document",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUploadDocumentRq.companyId, required: true },
      { name: "vatRegId", value: pUploadDocumentRq.vatRegId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadDocumentRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GenerateVatRegDocumentRq_ _generateVatRegDocumentRq
   * @returns Promise<GenerateVatRegDocumentRq_>
   * @memberof VatRegistration
   */
  generateVatRegDocument(pGenerateVatRegDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registration/{vatRegId}/template/{templateId}/creation",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pGenerateVatRegDocumentRq.companyId, required: true },
      { name: "vatRegId", value: pGenerateVatRegDocumentRq.vatRegId, required: true },
      { name: "templateId", value: pGenerateVatRegDocumentRq.templateId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchVatRegistrationRq_ _searchVatRegistrationRq
   * @returns Promise<SearchVatRegistrationRq_>
   * @memberof VatRegistration
   */
  searchVatRegistration(pSearchVatRegistrationRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/registration",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchVatRegistrationRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: VatRegSearchResult },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportVatRegistrationsRq_ _exportVatRegistrationsRq
   * @returns Promise<ExportVatRegistrationsRq_>
   * @memberof VatRegistration
   */
  exportVatRegistrations(pExportVatRegistrationsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/export",
      "POST",
      "application/json; charset=UTF-8",
      "text/csv, application/json",
      options
    );
    client.addBody({ value: pExportVatRegistrationsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetDataSourceRq_ _getDataSourceRq
   * @returns Promise<GetDataSourceRq_>
   * @memberof VatRegistration
   */
  getDataSource(pGetDataSourceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/countries/{vatRegCountry}/template/{templateId}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pGetDataSourceRq.companyId, required: true },
      { name: "vatRegCountry", value: pGetDataSourceRq.vatRegCountry, required: true },
      { name: "templateId", value: pGetDataSourceRq.templateId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: DataSourceField, isArray: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeBankAccountRq_ _changeBankAccountRq
   * @returns Promise<ChangeBankAccountRq_>
   * @memberof VatRegistration
   */
  changeBankAccount(pChangeBankAccountRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registration/{vatRegId}/BankAccount",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeBankAccountRq.companyId, required: true },
      { name: "vatRegId", value: pChangeBankAccountRq.vatRegId, required: true }
    ]);
    client.addBody({ value: pChangeBankAccountRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeFrequenciesRq_ _changeFrequenciesRq
   * @returns Promise<ChangeFrequenciesRq_>
   * @memberof VatRegistration
   */
  changeFrequencies(pChangeFrequenciesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registration/{vatRegId}/ChangeFrequencies",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeFrequenciesRq.companyId, required: true },
      { name: "vatRegId", value: pChangeFrequenciesRq.vatRegId, required: true }
    ]);
    client.addBody({ value: pChangeFrequenciesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetEmptyDataSourceRq_ _getEmptyDataSourceRq
   * @returns Promise<GetEmptyDataSourceRq_>
   * @memberof VatRegistration
   */
  getEmptyDataSource(pGetEmptyDataSourceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/countries/{vatRegCountry}/template/{templateId}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "vatRegCountry", value: pGetEmptyDataSourceRq.vatRegCountry, required: true },
      { name: "templateId", value: pGetEmptyDataSourceRq.templateId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: DataSourceField, isArray: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetPredefinedBankAccountsRq_ _getPredefinedBankAccountsRq
   * @returns Promise<GetPredefinedBankAccountsRq_>
   * @memberof VatRegistration
   */
  getPredefinedBankAccounts(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/PredefinedBankAccounts",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: PredefinedBankAccount, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangePaymentReferenceRq_ _changePaymentReferenceRq
   * @returns Promise<ChangePaymentReferenceRq_>
   * @memberof VatRegistration
   */
  changePaymentReference(pChangePaymentReferenceRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registrations/{vatRegistrationId}/paymentreference",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangePaymentReferenceRq.companyId, required: true },
      { name: "vatRegistrationId", value: pChangePaymentReferenceRq.vatRegistrationId, required: true }
    ]);
    client.addBody({ value: pChangePaymentReferenceRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeTaxIdentifierRq_ _changeTaxIdentifierRq
   * @returns Promise<ChangeTaxIdentifierRq_>
   * @memberof VatRegistration
   */
  changeTaxIdentifier(pChangeTaxIdentifierRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registrations/{vatRegistrationId}/taxidentifier",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeTaxIdentifierRq.companyId, required: true },
      { name: "vatRegistrationId", value: pChangeTaxIdentifierRq.vatRegistrationId, required: true }
    ]);
    client.addBody({ value: pChangeTaxIdentifierRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateAdministratorRq_ _updateAdministratorRq
   * @returns Promise<UpdateAdministratorRq_>
   * @memberof VatRegistration
   */
  updateAdministrator(pUpdateAdministratorRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registrations/{vatRegistrationId}/administrator",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateAdministratorRq.companyId, required: true },
      { name: "vatRegistrationId", value: pUpdateAdministratorRq.vatRegistrationId, required: true }
    ]);
    client.addBody({ value: pUpdateAdministratorRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteAdministratorRq_ _deleteAdministratorRq
   * @returns Promise<DeleteAdministratorRq_>
   * @memberof VatRegistration
   */
  deleteAdministrator(pDeleteAdministratorRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/companies/{companyId}/registrations/{vatRegistrationId}/administrator",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDeleteAdministratorRq.companyId, required: true },
      { name: "vatRegistrationId", value: pDeleteAdministratorRq.vatRegistrationId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
export var DownloadVatRegDocumentType = /* @__PURE__ */ ((DownloadVatRegDocumentType2) => {
  DownloadVatRegDocumentType2["Unsigned"] = "Unsigned";
  DownloadVatRegDocumentType2["Signed"] = "Signed";
  return DownloadVatRegDocumentType2;
})(DownloadVatRegDocumentType || {});
export var DownloadVatCertificateCertificateType = /* @__PURE__ */ ((DownloadVatCertificateCertificateType2) => {
  DownloadVatCertificateCertificateType2["Local"] = "Local";
  DownloadVatCertificateCertificateType2["Eu"] = "Eu";
  return DownloadVatCertificateCertificateType2;
})(DownloadVatCertificateCertificateType || {});
