var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { component } from "haunted";
import { useState, useQueryState, useEffect, useClient, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { useBirthCountries, useEUCountries, usePermissionManager, useWorldCountries } from "AdminComponents/hooks/AdminCustomHooks";
import { Questionnaire as QuestionnaireClient } from "AdminServices/APIs/self/clients/Questionnaire";
import { Company as CompanyClient } from "AdminServices/APIs/self/clients";
import { QuestionnaireStatus as AdminQuestionnaireStatus } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Questionnaire";
import { QuestionnaireContext } from "GlobalShared/components/q11e/QuestionnaireContext";
import { QUESTIONS } from "GlobalShared/components/q11e/questions";
import { Q11eValidator } from "GlobalShared/components/q11e/Q11eValidator";
import { convertAnswerEvent } from "GlobalShared/components/q11e/answer-utils";
import { notifyError, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { currentLocale } from "../../globals";
import { getQuestionnaireStatusChange } from "./ClientsModels";
import { mapCompanyDetailToCompanyServicesModel } from "GlobalShared/mappers/ServicesMapper";
const QUESTIONNAIRE_STEPS = [i18next.t("Business Details"), i18next.t("Authorized Signatory"), i18next.t("Beneficial Owner"), i18next.t("Banking Information")];
export const Component = (host) => {
  const props = {
    clientId: host.clientId
  };
  const q11eCanBeApproved = () => {
    return hasPendingBankTransferPayment !== void 0 && !hasPendingBankTransferPayment && (currentCompany == null ? void 0 : currentCompany.GisIdentifier) !== void 0 && (currentCompany == null ? void 0 : currentCompany.SentinelNumber) !== void 0 && (currentCompany == null ? void 0 : currentCompany.SentinelValidTo) !== void 0;
  };
  const nextStep = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };
  const previousStep = () => {
    setCurrentStep(currentStep > 0 ? currentStep - 1 : currentStep);
  };
  const onAnswerChanged = (e) => __async(void 0, null, function* () {
    const answer = convertAnswerEvent(e);
    withErrorHandling(
      () => __async(void 0, null, function* () {
        yield questionnaireClient.saveModification({ companyId: props.clientId, body: [answer] });
        setContext(context.getNewContextWithSetAnswer(answer));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const changeStatus = (oldStatus, newStatus, comment) => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const statusChange = getQuestionnaireStatusChange(oldStatus, newStatus, comment);
        yield questionnaireClient.changeQuestionnaireStatus({
          companyId: props.clientId,
          body: statusChange
        });
        window.location.reload();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const openBackToClientModal = () => {
    setBackToClientText("");
    setShowBackToClientModal(true);
  };
  const sendBackToClient = () => __async(void 0, null, function* () {
    setShowBackToClientModal(false);
    withErrorHandling(
      () => __async(void 0, null, function* () {
        yield questionnaireClient.incompleteQuestionnaire({
          companyId: props.clientId,
          body: backToClientText
        });
        window.location.reload();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const [questionnaire, setQuestionnaire] = useState(void 0);
  const [company, setCompany] = useState(void 0);
  const [currentCompany, setCurrentCompany] = useState(void 0);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [showBackToClientModal, setShowBackToClientModal] = useState(false);
  const [backToClientText, setBackToClientText] = useState("");
  const [hasPendingBankTransferPayment, setHasPendingBankTransferPayment] = useState(void 0);
  const [context, setContext] = useState(void 0);
  const worldCountries = useWorldCountries(currentLocale);
  const euCountries = useEUCountries(currentLocale);
  const birthCountries = useBirthCountries(currentLocale);
  const [currentStep, setCurrentStep] = useQueryState("step", 0);
  const questionnaireClient = useClient(QuestionnaireClient);
  const companyClient = useClient(CompanyClient);
  const [q11eValidator, setQ11eValidator] = useState(new Q11eValidator(context));
  const q11eValidationContext = useValidationContext(q11eValidator);
  const permissionManager = usePermissionManager();
  const init = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const questionnaireResponse = yield questionnaireClient.getQuestionnaire({ companyId: props.clientId });
        setQuestionnaire(questionnaireResponse.data);
        const companyDetail = yield companyClient.getCompanyDetail({
          companyId: props.clientId
        });
        setCompany(companyDetail.data);
        setCurrentCompany(mapCompanyDetailToCompanyServicesModel(worldCountries, companyDetail.data));
        const pendingPaymentResponse = yield companyClient.hasPendingBankTransferPayment({
          companyId: props.clientId
        });
        setHasPendingBankTransferPayment(pendingPaymentResponse.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  useEffect(init, []);
  useEffect(() => {
    q11eValidationContext.validate(context);
  }, [q11eValidator]);
  useEffect(() => {
    if (context !== void 0) {
      setQ11eValidator(new Q11eValidator(context));
    }
  }, [context]);
  useEffect(() => {
    if (questionnaire && euCountries && company) {
      setContext(new QuestionnaireContext(questionnaire, true, euCountries, QUESTIONS, company, permissionManager.hasPermission("Company_Questionnaire_Modify")));
    }
  }, [questionnaire, euCountries, company, permissionManager]);
  const templateValidationModal = () => {
    return html`
			<div style="max-width: 300px">
				<dc-modal
					.visible=${showValidationModal}
					@close=${() => setShowValidationModal(false)}
					.header=${i18next.t("Validation errors")}
					.content=${html`<ul>
						${q11eValidationContext.validationResult.getFailures().map((failure) => {
      var _a;
      return html`<li class="text-red-400">${(_a = QUESTIONS.filter((question) => question.Id === failure.propertyName)[0]) == null ? void 0 : _a.Label}: ${failure.message}</li>`;
    })}
					</ul>`}
				>
				</dc-modal>
			</div>
		`;
  };
  const templateBackToClientModal = () => {
    return html`
			<div style="max-width: 300px">
				<dc-modal
					.visible=${showBackToClientModal}
					@close=${() => setShowBackToClientModal(false)}
					.header=${i18next.t("Give some comment to client")}
					.content=${html`<div>
						<textarea
							class="form-textarea h-48"
							@change=${function() {
      setBackToClientText(this.value);
    }}
						>
${backToClientText}</textarea
						>
						<div>
							<button class="btn btn-primary ml-4" @click=${() => setShowBackToClientModal(false)}>Cancel</button
							><button class="btn btn-primary ml-4" @click=${() => sendBackToClient()}>Send</button>
						</div>
					</div>`}
				>
				</dc-modal>
			</div>
		`;
  };
  const templateBusinessDetails = () => {
    return html`<ww-q11e-business-details .q11e=${context} .worldCountries=${worldCountries} .euCountries=${euCountries} @change=${onAnswerChanged}></ww-q11e-business-details>`;
  };
  const templateLegalRepresentatives = () => {
    return html`<ww-answer-legal-representatives
			.q11e=${context}
			.worldCountries=${worldCountries}
			.birthCountries=${birthCountries}
			.questionId=${"legal_rep"}
			@change=${onAnswerChanged}
		></ww-answer-legal-representatives>`;
  };
  const templateBeneficialOwners = () => {
    return html`<ww-answer-beneficial-owners .q11e=${context} .questionId=${"beneficial_owner"} .worldCountries=${worldCountries} @change=${onAnswerChanged}></ww-answer-beneficial-owners>`;
  };
  const templateBankingInformation = () => {
    return html`<ww-q11e-banking-information .q11e=${context} @change=${onAnswerChanged} .worldCountries=${worldCountries}></ww-q11e-banking-information>`;
  };
  return html`<div class="my-2 mx-8">
		<div class="m-auto">
			<div class="flex justify-around w-full my-4">
				<dc-step-progressbar
					.items=${QUESTIONNAIRE_STEPS}
					.activeIndex=${currentStep}
					.inactiveClickable=${true}
					@click=${(e) => {
    setCurrentStep(e.detail.step);
  }}
				></dc-step-progressbar>
			</div>
			<div class="w-full text-right relative">
				<div class="absolute right-0 flex" style="top: 36px">
					${(context == null ? void 0 : context.getQuestionnaireStatus()) === AdminQuestionnaireStatus.Completed ? html`<button
									class="btn btn-primary"
									?disabled=${!permissionManager.hasPermission("Company_Questionnaire_Modify")}
									@click=${() => changeStatus(AdminQuestionnaireStatus.Completed, AdminQuestionnaireStatus.DataVerified)}
								>
									Verify</button
								><button class="btn btn-primary ml-4" ?disabled=${!permissionManager.hasPermission("Company_Questionnaire_Modify")} @click=${openBackToClientModal}>
									Back to Client
								</button>` : ""}
					${(context == null ? void 0 : context.getQuestionnaireStatus()) === AdminQuestionnaireStatus.DataVerified ? html`<button
									class="btn btn-primary"
									?disabled=${!permissionManager.hasPermission("Company_Questionnaire_Approve")}
									@click=${() => {
    if (!q11eCanBeApproved()) {
      notifyWarning("Sentinel, GIS data and Approved bank transfer payment is required");
    } else {
      changeStatus(AdminQuestionnaireStatus.DataVerified, AdminQuestionnaireStatus.Approved);
    }
  }}
								>
									Approve
								</button>
								<button
									class="btn btn-primary ml-4"
									?disabled=${!permissionManager.hasPermission("Company_Questionnaire_Modify")}
									@click=${() => changeStatus(AdminQuestionnaireStatus.DataVerified, AdminQuestionnaireStatus.Completed)}
								>
									Back to Completed
								</button>` : ""}
					${(context == null ? void 0 : context.getQuestionnaireStatus()) === AdminQuestionnaireStatus.Approved ? html`<button
								class="btn btn-primary"
								?disabled=${!permissionManager.hasPermission("Company_Questionnaire_Modify")}
								@click=${() => changeStatus(AdminQuestionnaireStatus.Approved, AdminQuestionnaireStatus.DataVerified)}
						  >
								Back to Verified
						  </button>` : ""}
				</div>
			</div>
			${currentStep === 0 ? templateBusinessDetails() : ""} ${currentStep === 1 ? templateLegalRepresentatives() : ""} ${currentStep === 2 ? templateBeneficialOwners() : ""}
			${currentStep === 3 ? templateBankingInformation() : ""}
			<div class="mt-8 flex w-full justify-between">
				<button class="px-4 h-12 border-2 border-brand-primary-light rounded text-brand-primary text-sm bg-white font-semibold ${currentStep === 0 ? "invisible" : ""}" @click=${previousStep}>
					${i18next.t("Previous")}
				</button>
				<button class="btn btn-primary h-12 ${currentStep === 3 ? "invisible" : ""}" @click=${nextStep}>${i18next.t("Next")}</button>
			</div>
		</div>
		${templateValidationModal()} ${templateBackToClientModal()}
	</div>`;
};
customElements.define(
  "ww-clients-questionnaire",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
