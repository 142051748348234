import { useState } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { getLocalizedUrl } from "../globals";
import { getParameter } from "GlobalShared/router";
import { validateEmail } from "GlobalShared/validators/email-validator";
import { validatePassword } from "GlobalShared/validators/password-validator";
export const observedAttributes = ["email", "error"];
export const name = "ww-register";
export const Component = (host) => {
  const props = {
    email: host.email,
    error: host.error
  };
  const generateError = () => {
    if (props.error) {
      switch (props.error) {
        case "A token must be supplied":
          return "Please use the link from the invitation email!";
        case "User already registered":
          return "You already have an account. Please sign in";
        case "Token is invalid or expired":
          return "Please add the e-mail address where you received the invitation e-mail";
        default:
          return props.error;
      }
    }
    if (email && !validateEmail(email)) {
      return "Please provide a valid e-mail address.";
    }
    if (password && !validatePassword(password)) {
      return "Password must be at least 8 digits long and contain any three of the following four qualities: Uppercase Characters, Lowercase Characters, Alphanumeric Characters, Special Characters.";
    }
    return void 0;
  };
  const hasError = () => {
    return !email || !password || generateError() !== void 0 ? true : false;
  };
  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState(void 0);
  return html`<div class="w-full px-4 md:w-1/2 md:p-24">
		<div>
			<a href="${getLocalizedUrl("")}" data-routing="server">
				<img src="/images/kpmg_logo.png" alt="KPMG logo" />
			</a>
		</div>
		<div class="md:text-xl font-bold py-6 lg:py-12 text-center max-w-screen-lg m-auto text-shadow">
			${"Welcome to the Admin site! Please note that you need to be invited in order to create an account. If you don\u2019t have access but wish to login, please contact the project leaders."}
		</div>
		${getParameter("success") !== void 0 ? html`<div>
					<div class="text-3xl font-bold py-8">You have successfully registered</div>
					<div>
						<a class="btn btn-primary" href=${getLocalizedUrl("/account/login")} data-routing="server"> ${"Sign in"} </a>
					</div>
			  </div>` : html`<div>
					<div class="bg-white shadow-md rounded px-8 py-4 m-auto flex flex-col">
						<div class="mb-4">
							<dc-input
								.label=${"Email"}
								.name=${"Email"}
								.value=${email}
								.placeholder=${"Email"}
								.autocomplete=${"off"}
								@change=${(e) => setEmail(e.detail.value)}
							></dc-input>
						</div>
						<div class="mb-6">
							<dc-input
								.label=${"Password"}
								.type=${"password"}
								.name=${"Password"}
								.value=${password}
								.placeholder=${"*************"}
								.autocomplete=${"off"}
								@change=${(e) => setPassword(e.detail.value)}
							></dc-input>
						</div>
						${hasError() ? html`<div class="mb-6">
									<label class="text-red-400"> ${generateError()} </label>
							  </div>` : ""}

						<div class="text-right">
							<button class="btn btn-primary" type="submit">${"Register"}</button>
						</div>
					</div>
			  </div>`}
	</div>`;
};
