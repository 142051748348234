export class Invitation {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Invitation
     */
    this.$type = "V1.Agents.Invitation";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Email = _data["Email"];
      if (_data["RoleNames"] && _data["RoleNames"].constructor === Array) {
        this.RoleNames = [];
        for (let item of _data["RoleNames"]) {
          this.RoleNames.push(item);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Email"] = this.Email;
    if (this.RoleNames && this.RoleNames.constructor === Array) {
      _data["RoleNames"] = [];
      for (let item of this.RoleNames) {
        _data["RoleNames"].push(item);
      }
    }
    return _data;
  }
}
