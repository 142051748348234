import { AbstractValidator } from "fluent-ts-validator";
import { DCValidationResult } from "GlobalShared/haunted/HooksHelpers";
export const ALL_TAX_CODE_DEF_FIELDS = ["ReturnType", "ReportingCountry", "TransactionTypeCode", "VATRateType", "TypeOfGoods", "DistanceSalesCountry"];
export class TaxCodeDefValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfString((s) => s.returnType).isNotEmpty().withPropertyName("ReturnType").withFailureMessage("Required");
    this.validateIfString((s) => {
      var _a;
      return (_a = s.taxCode) == null ? void 0 : _a.tranTypeCode;
    }).isNotEmpty().withPropertyName("TransactionTypeCode").withFailureMessage("Required");
    this.validateIfString((s) => {
      var _a;
      return (_a = s.taxCode) == null ? void 0 : _a.tranTypeCode;
    }).hasMaxLength(2).withPropertyName("TransactionTypeCode").withFailureMessage("Maximum 2 characters");
    this.validateIfString((s) => {
      var _a;
      return (_a = s.taxCode) == null ? void 0 : _a.typeOfGoods;
    }).isNotEmpty().withPropertyName("TypeOfGoods").withFailureMessage("Required");
    this.validateIfString((s) => {
      var _a;
      return (_a = s.taxCode) == null ? void 0 : _a.typeOfGoods;
    }).hasMaxLength(1).withPropertyName("TypeOfGoods").withFailureMessage("Maximum 1 character");
  }
  validateAllFields(item) {
    return new DCValidationResult(super.validate(item));
  }
}
