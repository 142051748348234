import { useState } from "GlobalShared/haunted/CustomHooks";
import { getParameter } from "GlobalShared/router";
import { validateEmail } from "GlobalShared/validators/email-validator";
import { validatePassword } from "GlobalShared/validators/password-validator";
import { html } from "lit-html";
import { getLocalizedUrl } from "../globals";
export const observedAttributes = ["email", "error"];
export const name = "ww-reset-password";
export const Component = (host) => {
  const props = {
    email: host.email,
    error: host.error
  };
  const generateError = () => {
    if (props.error) {
      return props.error;
    }
    if (email && !validateEmail(email)) {
      return "Please provide a valid e-mail address.";
    }
    if (password && !validatePassword(password)) {
      return "Password must be at least 8 digits long and contain any three of the following four qualities: Uppercase Characters, Lowercase Characters, Alphanumeric Characters, Special Characters.";
    }
    if (password && confirmPassword && password !== confirmPassword) {
      return "The two passwords must be the same.";
    }
    return void 0;
  };
  const hasError = () => {
    return !email || !password || !confirmPassword || generateError() !== void 0 ? true : false;
  };
  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState(void 0);
  const [confirmPassword, setConfirmPassword] = useState(void 0);
  return html`<div class="w-full px-8 md:w-1/2 md:p-24">
		<div>
			<a href="${getLocalizedUrl("")}" data-routing="server">
				<img src="/images/kpmg_logo.png" alt="KPMG logo" />
			</a>
		</div>
		${getParameter("success") !== void 0 ? html`<div>
					<div class="text-3xl font-bold py-8">${"Password successfully reset"}</div>
					<div>
						<a class="btn btn-primary" href=${getLocalizedUrl("/account/login")} data-routing="server"> ${"Sign in"} </a>
					</div>
			  </div>` : html`<div>
					<div class="text-3xl font-bold py-8">${"Reset Password"}</div>
					<div class="bg-white shadow-md rounded px-8 py-4 m-auto flex flex-col">
						<div class="mb-4">
							<dc-input
								.label=${"Email"}
								.name=${"email"}
								.value=${email}
								.placeholder=${"Email"}
								.autocomplete=${"off"}
								@change=${(e) => setEmail(e.detail.value)}
							></dc-input>
						</div>
						<div class="mb-6">
							<dc-input
								.label=${"Password"}
								.type=${"password"}
								.name=${"password"}
								.placeholder=${"*************"}
								.autocomplete=${"off"}
								.value=${password}
								@change=${(e) => setPassword(e.detail.value)}
							></dc-input>
						</div>
						<div class="mb-6">
							<dc-input
								.label=${"Confirm Password"}
								.type=${"password"}
								.name=${"confirmPassword"}
								.placeholder=${"*************"}
								.autocomplete=${"off"}
								.value=${confirmPassword}
								@change=${(e) => setConfirmPassword(e.detail.value)}
							></dc-input>
						</div>
						${hasError() ? html`<div class="mb-6">
									<label class="text-red-400"> ${generateError()} </label>
							  </div>` : ""}

						<div class="text-right">
							<button class="btn btn-primary" type="submit">${"Change"}</button>
						</div>
					</div>
			  </div>`}
	</div>`;
};
