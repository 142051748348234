import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { UserEmail } from "./../models/V1/Account/UserEmail";
import { SearchResult } from "./../models/V1/Account/Search/SearchResult";
export class Account {
  /**
   * Creates an instance of Account.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Account
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param ChangeEmailRq_ _changeEmailRq
   * @returns Promise<ChangeEmailRq_>
   * @memberof Account
   */
  changeEmail(pChangeEmailRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/account/email",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pChangeEmailRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200 },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetEmailAddressesRq_ _getEmailAddressesRq
   * @returns Promise<GetEmailAddressesRq_>
   * @memberof Account
   */
  getEmailAddresses(pGetEmailAddressesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/account/emails",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pGetEmailAddressesRq.body, isArray: true, isPrimitive: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: UserEmail, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeEmailForUserRq_ _changeEmailForUserRq
   * @returns Promise<ChangeEmailForUserRq_>
   * @memberof Account
   */
  changeEmailForUser(pChangeEmailForUserRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/account/{userId}/email",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "userId", value: pChangeEmailForUserRq.userId, required: true }
    ]);
    client.addBody({ value: pChangeEmailForUserRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchUsersRq_ _searchUsersRq
   * @returns Promise<SearchUsersRq_>
   * @memberof Account
   */
  searchUsers(pSearchUsersRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/account/search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchUsersRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param PatchCompaniesRq_ _patchCompaniesRq
   * @returns Promise<PatchCompaniesRq_>
   * @memberof Account
   */
  patchCompanies(pPatchCompaniesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/account/{userId}/companies",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "userId", value: pPatchCompaniesRq.userId, required: true }
    ]);
    client.addBody({ value: pPatchCompaniesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param InviteUserRq_ _inviteUserRq
   * @returns Promise<InviteUserRq_>
   * @memberof Account
   */
  inviteUser(pInviteUserRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/account/invite",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pInviteUserRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 409, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
