var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { BankInformationValidator } from "./AnswerValidators";
import { BankInformationAnswer as WebBankInformationAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers/BankInformationAnswer";
import { BankInformationAnswer as AdminBankInformationAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers/BankInformationAnswer";
import { Address as AdminAddress } from "AdminServices/APIs/self/models/V1/Common";
import { Address as WebAddress } from "WebServices/APIs/self/models/V1/Common";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-bank-information";
const DEFAULTS = {
  q11e: void 0,
  questionId: void 0,
  validation: {
    required: false
  },
  worldCountries: []
};
export class BankInformationChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  var _a;
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    validation: host.validation !== void 0 ? __spreadValues(__spreadValues({}, DEFAULTS.validation), host.validation) : DEFAULTS.validation,
    worldCountries: (_a = host.worldCountries) != null ? _a : DEFAULTS.worldCountries,
    withDefaultCountry: host.withDefaultCountry
  };
  const getLabel = (fieldName, required = false) => {
    var _a2;
    return ((_a2 = getSubQuestion(fieldName)) == null ? void 0 : _a2.Label) + (required ? "*" : "");
  };
  const getSubQuestion = (questionId) => {
    const filteredSubQuestions = question.SubQuestions.filter((subQuestion) => subQuestion.Id === questionId);
    if (filteredSubQuestions.length === 1) {
      return filteredSubQuestions[0];
    } else {
      return void 0;
    }
  };
  const getAnswer = () => {
    var _a2;
    if ((_a2 = props.q11e) == null ? void 0 : _a2.isAdmin) {
      return new AdminBankInformationAnswer({
        Name: bankName,
        Address: new AdminAddress(__spreadValues({}, address)),
        AddressCountry: addressCountry,
        Owner: bankOwner,
        Iban: iban,
        Swift: swift,
        QuestionId: props.questionId
      });
    }
    return new WebBankInformationAnswer({
      Name: bankName,
      Address: new WebAddress(__spreadValues({}, address)),
      AddressCountry: addressCountry,
      Owner: bankOwner,
      Iban: iban,
      Swift: swift,
      QuestionId: props.questionId
    });
  };
  const [bankName, setBankName] = useState(void 0);
  const [bankOwner, setBankOwner] = useState(void 0);
  const [iban, setIban] = useState(void 0);
  const [swift, setSwift] = useState(void 0);
  const [addressCountry, setAddressCountry] = useState(void 0);
  const [address, setAddress] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new BankInformationValidator(props.validation));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof AdminBankInformationAnswer || answer instanceof WebBankInformationAnswer) {
        setBankName(answer.Name);
        setBankOwner(answer.Owner);
        setIban(answer.Iban);
        setSwift(answer.Swift);
        setAddress(answer.Address);
        setAddressCountry(answer.AddressCountry);
      }
    }
  }, [props.q11e, props.questionId]);
  useEffect(() => {
    if (props == null ? void 0 : props.withDefaultCountry) {
      setAddressCountry(props.withDefaultCountry);
    }
  }, [props.withDefaultCountry]);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new BankInformationChangeEvent({
          answer: getAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(getAnswer());
  }, [bankName, bankOwner, iban, swift, address, addressCountry]);
  return html`
		${props.q11e !== void 0 && question !== void 0 ? html`<div class="grid md:grid-cols-2 gap-4 mt-4">
					<dc-input
						.readonly=${props.q11e.isReadonly()}
						.label=${getLabel("name", true)}
						.value=${bankName}
						.validationMessage=${validationContext.getValidationMessage("name")}
						@change=${(e) => {
    setBankName(e.detail.value);
    setDispatchIfValid(true);
    validationContext.validateField("name");
  }}
					></dc-input>

					<dc-select
						class="${props.withDefaultCountry !== void 0 ? "hidden" : ""}"
						.label=${getLabel("address_country", true)}
						.selectedValues=${[addressCountry]}
						.dataSource=${props.worldCountries.map((country) => ({
    label: country.Name,
    value: country.Code
  }))}
						.filterable=${true}
						.readonly=${props.q11e.isReadonly()}
						.validationMessage=${validationContext.getValidationMessage("address_country")}
						@change=${(e) => {
    setAddressCountry(e.detail.selectedValue);
    setDispatchIfValid(true);
    validationContext.validateField("address_country");
  }}
					></dc-select>
					<dc-input
						.readonly=${props.q11e.isReadonly()}
						.label=${getLabel("address_city", true)}
						.value=${address == null ? void 0 : address.City}
						.validationMessage=${validationContext.getValidationMessage("address_city")}
						@change=${(e) => {
    setAddress(new WebAddress(__spreadProps(__spreadValues({}, address), { City: e.detail.value })));
    setDispatchIfValid(true);
    validationContext.validateField("address_city");
  }}
					></dc-input>
					<dc-input
						.readonly=${props.q11e.isReadonly()}
						.label=${getLabel("address_street", true)}
						.value=${address == null ? void 0 : address.Street}
						.validationMessage=${validationContext.getValidationMessage("address_street")}
						@change=${(e) => {
    setAddress(new WebAddress(__spreadProps(__spreadValues({}, address), { Street: e.detail.value })));
    setDispatchIfValid(true);
    validationContext.validateField("address_street");
  }}
					></dc-input>
					<dc-input
						.readonly=${props.q11e.isReadonly()}
						.label=${getLabel("address_street_number", true)}
						.value=${address == null ? void 0 : address.StreetNumber}
						.validationMessage=${validationContext.getValidationMessage("address_street_number")}
						@change=${(e) => {
    setAddress(new WebAddress(__spreadProps(__spreadValues({}, address), { StreetNumber: e.detail.value })));
    setDispatchIfValid(true);
    validationContext.validateField("address_street_number");
  }}
					></dc-input>
					<dc-input
						.readonly=${props.q11e.isReadonly()}
						.label=${getLabel("address_zip", true)}
						.value=${address == null ? void 0 : address.Zip}
						.validationMessage=${validationContext.getValidationMessage("address_zip")}
						@change=${(e) => {
    setAddress(new WebAddress(__spreadProps(__spreadValues({}, address), { Zip: e.detail.value })));
    setDispatchIfValid(true);
    validationContext.validateField("address_zip");
  }}
					></dc-input>

					<dc-input
						.readonly=${props.q11e.isReadonly()}
						.label=${getLabel("owner", true)}
						.value=${bankOwner}
						.validationMessage=${validationContext.getValidationMessage("owner")}
						@change=${(e) => {
    setBankOwner(e.detail.value);
    setDispatchIfValid(true);
    validationContext.validateField("owner");
  }}
					></dc-input>
					<dc-input
						.readonly=${props.q11e.isReadonly()}
						.label=${getLabel("iban", true)}
						.value=${iban}
						.tooltip=${getSubQuestion("iban").Tooltip !== void 0 ? getSubQuestion("iban").Tooltip : ""}
						.validationMessage=${validationContext.getValidationMessage("iban")}
						@change=${(e) => {
    setIban(e.detail.value);
    setDispatchIfValid(true);
    validationContext.validateField("iban");
  }}
					></dc-input>
					<dc-input
						.readonly=${props.q11e.isReadonly()}
						.label=${getLabel("swift", true)}
						.value=${swift}
						.tooltip=${getSubQuestion("swift").Tooltip !== void 0 ? getSubQuestion("swift").Tooltip : ""}
						.validationMessage=${validationContext.getValidationMessage("swift")}
						@change=${(e) => {
    setSwift(e.detail.value);
    setDispatchIfValid(true);
    validationContext.validateField("swift");
  }}
					></dc-input>
			  </div>` : ""}
	`;
};
