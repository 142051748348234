import { DateConverter } from "./../../../../../converters/DateConverter";
export class EoriRegistration {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof EoriRegistration
     */
    this.$type = "V1.CompanyInfo.Detail.EoriRegistrations.EoriRegistration";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.RegistrationType = EoriRegistrationRegistrationType[_data["RegistrationType"]];
      this.StartDate = DateConverter.from(_data["StartDate"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["RegistrationType"] = EoriRegistrationRegistrationType[this.RegistrationType];
    _data["StartDate"] = DateConverter.toUtcDateString(this.StartDate);
    return _data;
  }
}
export var EoriRegistrationRegistrationType = /* @__PURE__ */ ((EoriRegistrationRegistrationType2) => {
  EoriRegistrationRegistrationType2["UK"] = "UK";
  EoriRegistrationRegistrationType2["EU"] = "EU";
  return EoriRegistrationRegistrationType2;
})(EoriRegistrationRegistrationType || {});
