var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { component } from "haunted";
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { currentLocale } from "../../globals";
import { ComplianceMasterData as ComplianceMasterDataClient } from "AdminServices/APIs/self/clients";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { usePermissionManager, useServiceCountries } from "AdminComponents/hooks/AdminCustomHooks";
import { useDefaultVatRates } from "GlobalShared/components/default-vat-rates/default-vat-rates";
import { DefaultVatRateType } from "AdminServices/APIs/self/models/V1/ComplianceMasterData";
export const Component = (host) => {
  const props = {
    clientId: host.clientId
  };
  const getDefaultVatRateTypes = () => __async(void 0, null, function* () {
    return withErrorHandling(
      () => __async(void 0, null, function* () {
        const response = yield complianceMasterDataClient.getDefaultVatRateTypes({ companyId: props.clientId });
        return response.data.map((dvrt) => {
          const vm = {
            CountryCode: dvrt.CountryCode,
            Type: dvrt.Type
          };
          return vm;
        });
      }),
      (err) => {
        throw err;
      }
    );
  });
  const getVATRateTypeSettings = () => __async(void 0, null, function* () {
    return withErrorHandling(
      () => __async(void 0, null, function* () {
        const response = yield complianceMasterDataClient.getVATRateTypeSettings();
        return response.data.map((vrts) => {
          var _a;
          const vm = {
            CountryCode: vrts.CountryCode,
            TypeRateOptions: (_a = vrts.TypeRateOptions) == null ? void 0 : _a.map((tro) => {
              const vmTro = {
                Rate: tro.Rate,
                Type: tro.Type
              };
              return vmTro;
            })
          };
          return vm;
        });
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const saveDefaultVatRateTypes = (dvrts) => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        yield complianceMasterDataClient.setDefaultVatRateTypes({
          companyId: props.clientId,
          body: dvrts.map((elem) => {
            return new DefaultVatRateType({
              CountryCode: elem.CountryCode,
              Type: elem.Type
            });
          })
        });
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const serviceCountries = useServiceCountries(currentLocale);
  const complianceMasterDataClient = useClient(ComplianceMasterDataClient);
  const permissionManager = usePermissionManager();
  const defaultVatRatesHook = useDefaultVatRates({
    isAdmin: false,
    permissionManager,
    serviceCountries,
    getDefaultVatRateTypes,
    getVATRateTypeSettings,
    saveDefaultVatRateTypes
  });
  return defaultVatRatesHook.mainTemplate;
};
customElements.define(
  "ww-clients-default-vat-rates",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
