import { Money } from "./../Common/Money";
export class BankTransferApproveRequest {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof BankTransferApproveRequest
     */
    this.$type = "V1.Payments.BankTransferApproveRequest";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Collected"]) {
        const collected_ = new Money();
        this.Collected = collected_.init(_data["Collected"]);
      }
      this.Reference = _data["Reference"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Collected"] = this.Collected ? this.Collected.toJSON() : void 0;
    _data["Reference"] = this.Reference;
    return _data;
  }
}
