import { DateConverter } from "./../../../../converters/DateConverter";
import { IossRegistrationStatusChange } from "./IossRegistrationStatusChange";
export class AddCertificateStatusChange extends IossRegistrationStatusChange {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof AddCertificateStatusChange
     */
    this.$type = "V1.Company.IossRegistration.AddCertificateStatusChange";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.CertificateReferenceId = _data["CertificateReferenceId"];
      this.RegistrationDate = DateConverter.from(_data["RegistrationDate"]);
      this.RegistrationNumber = _data["RegistrationNumber"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["CertificateReferenceId"] = this.CertificateReferenceId;
    _data["RegistrationDate"] = DateConverter.toUtcDateString(this.RegistrationDate);
    _data["RegistrationNumber"] = this.RegistrationNumber;
    return _data;
  }
}
