import { SubscriptionSearch } from "./SubscriptionSearch";
import { VatRegistrationSubscriptionSearch } from "./VatRegistrationSubscriptionSearch";
export class SubscriptionSearchFactory {
  static create($type) {
    if ($type === "V1.Subscriptions.SubscriptionSearch") {
      const result = new SubscriptionSearch();
      return result;
    }
    if ($type === "V1.Subscriptions.VatRegistrationSubscriptionSearch") {
      const result = new VatRegistrationSubscriptionSearch();
      return result;
    }
    throw new Error($type + " not found");
  }
}
