import i18next from "i18next";
import { html, svg } from "lit-html";
export const templateTitle = (text) => html`<div class="text-2xl font-bold">${text}</div>`;
export const headerTemplate = () => html`
	<header class="h-16 bg-white w-full border-b border-solid border-gray-400">
		<div class="container h-16 px-8 flex items-center justify-between relative">
			<a href="/" data-routing="server">
				<img src="/images/kpmg_logo.png" class="h-10" />
			</a>
			<input type="checkbox" class="client-hamburger" id="hamburger" />
			<label for="hamburger" class="client-hamburger-label"></label>
			<div class="client-menu">
				<ww-language-select></ww-language-select>
			</div>
		</div>
	</header>
`;
export const editIcon = () => svg`
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_240_547)">
			<path
				d="M17.0015 0.904694L14.7327 3.17344L20.8265 9.26719L23.0952 6.99844C24.2671 5.82657 24.2671 3.92813 23.0952 2.75626L21.2484 0.904694C20.0765 -0.267181 18.178 -0.267181 17.0062 0.904694H17.0015ZM13.6734 4.23282L2.74679 15.1641C2.25929 15.6516 1.90304 16.2563 1.70617 16.9172L0.0467943 22.5563C-0.0703932 22.9547 0.0374193 23.3813 0.328044 23.6719C0.618669 23.9625 1.04523 24.0703 1.43898 23.9578L7.07804 22.2984C7.73898 22.1016 8.34367 21.7453 8.83117 21.2578L19.7671 10.3266L13.6734 4.23282Z"
				fill="#005EB8"
			/>
		</g>
        <title>${i18next.t("Edit")}</title>
		<defs>
			<clipPath id="clip0_240_547">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>`;
export const removeIcon = () => svg`<svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_240_545)">
			<path
				d="M6.3375 0.829688C6.59062 0.31875 7.11094 0 7.67813 0H13.3219C13.8891 0 14.4094 0.31875 14.6625 0.829688L15 1.5H19.5C20.3297 1.5 21 2.17031 21 3C21 3.82969 20.3297 4.5 19.5 4.5H1.5C0.670312 4.5 0 3.82969 0 3C0 2.17031 0.670312 1.5 1.5 1.5H6L6.3375 0.829688ZM1.5 6H19.5V21C19.5 22.6547 18.1547 24 16.5 24H4.5C2.84531 24 1.5 22.6547 1.5 21V6ZM6 9C5.5875 9 5.25 9.3375 5.25 9.75V20.25C5.25 20.6625 5.5875 21 6 21C6.4125 21 6.75 20.6625 6.75 20.25V9.75C6.75 9.3375 6.4125 9 6 9ZM10.5 9C10.0875 9 9.75 9.3375 9.75 9.75V20.25C9.75 20.6625 10.0875 21 10.5 21C10.9125 21 11.25 20.6625 11.25 20.25V9.75C11.25 9.3375 10.9125 9 10.5 9ZM15 9C14.5875 9 14.25 9.3375 14.25 9.75V20.25C14.25 20.6625 14.5875 21 15 21C15.4125 21 15.75 20.6625 15.75 20.25V9.75C15.75 9.3375 15.4125 9 15 9Z"
				fill="#BC204B"
			/>
		</g>
        <title>${i18next.t("Delete")}</title>
		<defs>
			<clipPath id="clip0_240_545">
				<rect width="21" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>`;
export const downloadPDFIcon = (title) => svg`
	<svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_240_546)">
			<path
				d="M3 0C1.34531 0 0 1.34531 0 3V21C0 22.6547 1.34531 24 3 24H15C16.6547 24 18 22.6547 18 21V7.5H12C11.1703 7.5 10.5 6.82969 10.5 6V0H3ZM12 0V6H18L12 0ZM5.25 12H12.75C13.1625 12 13.5 12.3375 13.5 12.75C13.5 13.1625 13.1625 13.5 12.75 13.5H5.25C4.8375 13.5 4.5 13.1625 4.5 12.75C4.5 12.3375 4.8375 12 5.25 12ZM5.25 15H12.75C13.1625 15 13.5 15.3375 13.5 15.75C13.5 16.1625 13.1625 16.5 12.75 16.5H5.25C4.8375 16.5 4.5 16.1625 4.5 15.75C4.5 15.3375 4.8375 15 5.25 15ZM5.25 18H12.75C13.1625 18 13.5 18.3375 13.5 18.75C13.5 19.1625 13.1625 19.5 12.75 19.5H5.25C4.8375 19.5 4.5 19.1625 4.5 18.75C4.5 18.3375 4.8375 18 5.25 18Z"
				fill="#005EB8"
			/>
		</g>
        <title>${title != null ? title : i18next.t("Download PDF")}</title>
		<defs>
			<clipPath id="clip0_240_546">
				<rect width="18" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
`;
export const footerLinksTemplate = () => html`
	<div class="text-xs font-semibold text-center uppercase mt-2 md:mt-4">
		<a class="hover:underline" target="_blank" rel="noopener" href="https://home.kpmg/content/dam/kpmg/hu/pdf/KGSH_Privacy_Statement_v2.pdf" download="Privacy_Statament.pdf"
			>${i18next.t("Privacy")}</a
		>
		&nbsp;|&nbsp;
		<a class="hover:underline" target="_blank" rel="noopener" href="/files/Legal_Statement.pdf" download="Legal_Statament.pdf">${i18next.t("Legal")}</a>
	</div>
`;
export const footerTextTemplate = () => html`
	<div class="text-sm text-center m-auto max-w-screen-xl">
		&copy; ${(/* @__PURE__ */ new Date()).getFullYear()}
		${i18next.t(
  "KPMG Global Services Hungary Ltd., a member firm of the KPMG global organization of independent member firms affiliated with KPMG International Limited, a private English company limited by guarantee. All rights reserved."
)}
	</div>
`;
export const subscribeIcon = (fillType) => html`<span class="cursor-pointer hover:scale-110 transform">
		<svg width="20" height="24" viewBox="0 0 302 346" fill="none" class="hover:scale-110 transform">
			<path
				d="M172.562 21.625V34.6C221.769 44.6218 258.843 88.257 258.843 140.562V153.267C258.843 185.096 270.504 215.709 291.535 239.497L296.523 245.106C302.185 251.526 303.601 260.581 300.096 268.353C296.591 276.124 288.906 281.125 280.413 281.125H21.5701C13.0768 281.125 5.37285 276.124 1.8933 268.353C-1.58625 260.581 -0.194496 251.526 5.44902 245.106L10.4479 239.497C31.5059 215.709 43.1403 185.096 43.1403 153.267V140.562C43.1403 88.257 79.6075 44.6218 129.421 34.6V21.625C129.421 9.68395 139.06 0 150.991 0C162.923 0 172.562 9.68395 172.562 21.625ZM150.991 346C139.532 346 128.545 341.472 120.456 333.363C112.367 325.254 107.851 313.63 107.851 302.75H194.132C194.132 313.63 189.616 325.254 181.527 333.363C173.438 341.472 161.844 346 150.991 346Z"
				fill=${fillType === "Active" ? "#EAAA00" : fillType === "Inactive" ? "#666666" : "#005EB8"}
			/>
		</svg>
	</span>`;
