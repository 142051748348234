import { html } from "lit-html";
import { getLocalizedUrl, getRouteByKey } from "../globals";
import { addHandler, getCurrentFullPath, getParameter, getRouteElement, navigateTo, removeOptionals } from "GlobalShared/router";
import { useEffect, useMemo, useState } from "GlobalShared/haunted/CustomHooks";
import { usePermissionManager } from "./hooks/AdminCustomHooks";
const svgIcon = (path) => {
  return html`<svg class="inline mr-2" width="24" height="24" viewBox="0 0 500 600" fill="white">
		<path d=${path}></path>
	</svg>`;
};
const isPathStartsWith = (pathPart) => window.location.pathname.substring("/gb/en/".length).startsWith(pathPart);
const MAIN_MENUS = [
  // {
  // 	label: "Acquisition",
  // 	key: "acquisition",
  // 	icon: svgIcon("M347.94 129.86L203.6 195.83a31.938 31.938 0 0 0-15.77 15.77l-65.97 144.34c-7.61 16.65 9.54 33.81 26.2 26.2l144.34-65.97a31.938 31.938 0 0 0 15.77-15.77l65.97-144.34c7.61-16.66-9.54-33.81-26.2-26.2zm-77.36 148.72c-12.47 12.47-32.69 12.47-45.16 0-12.47-12.47-12.47-32.69 0-45.16 12.47-12.47 32.69-12.47 45.16 0 12.47 12.47 12.47 32.69 0 45.16zM248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm0 448c-110.28 0-200-89.72-200-200S137.72 56 248 56s200 89.72 200 200-89.72 200-200 200z"),
  // },
  {
    key: "clients(/:clientId)",
    label: "Clients",
    icon: svgIcon(
      "M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"
    ),
    isSubMenuOpen: () => isPathStartsWith("clients") && getRouteElement("clientId") !== void 0,
    permissions: ["Companies_Read"],
    subMenus: [
      {
        key: "clients(/:clientId)",
        label: "Client details",
        routePostfix: () => `/${getRouteElement("clientId")}`,
        icon: svgIcon(
          "M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v340a6 6 0 0 1-6 6zm-42-92v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm-252 12c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36z"
        ),
        isHighlighted: () => getParameter("sm") === void 0,
        permissions: ["Company_Details_Read"]
      },
      {
        key: "clients(/:clientId)",
        label: "Questionnaire",
        routePostfix: () => `/${getRouteElement("clientId")}?sm=q11e`,
        icon: svgIcon(
          "M527.9 224H480v-48c0-26.5-21.5-48-48-48H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h400c16.5 0 31.9-8.5 40.7-22.6l79.9-128c20-31.9-3-73.4-40.7-73.4zM48 118c0-3.3 2.7-6 6-6h134.1l64 64H426c3.3 0 6 2.7 6 6v42H152c-16.8 0-32.4 8.8-41.1 23.2L48 351.4zm400 282H72l77.2-128H528z"
        ),
        isHighlighted: () => getParameter("sm") === "q11e",
        permissions: ["Company_Questionnaire_Read"]
      },
      {
        key: "clients(/:clientId)",
        label: "Amazon SKUs",
        routePostfix: () => `/${getRouteElement("clientId")}?sm=sku`,
        icon: svgIcon(
          "M527.9 224H480v-48c0-26.5-21.5-48-48-48H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h400c16.5 0 31.9-8.5 40.7-22.6l79.9-128c20-31.9-3-73.4-40.7-73.4zM48 118c0-3.3 2.7-6 6-6h134.1l64 64H426c3.3 0 6 2.7 6 6v42H152c-16.8 0-32.4 8.8-41.1 23.2L48 351.4zm400 282H72l77.2-128H528z"
        ),
        isHighlighted: () => getParameter("sm") === "sku",
        permissions: ["Company_Amazon_SKUs_Read"]
      },
      {
        key: "clients(/:clientId)",
        label: "VAT Rates",
        routePostfix: () => `/${getRouteElement("clientId")}?sm=vat_rates`,
        icon: svgIcon(
          "M527.9 224H480v-48c0-26.5-21.5-48-48-48H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h400c16.5 0 31.9-8.5 40.7-22.6l79.9-128c20-31.9-3-73.4-40.7-73.4zM48 118c0-3.3 2.7-6 6-6h134.1l64 64H426c3.3 0 6 2.7 6 6v42H152c-16.8 0-32.4 8.8-41.1 23.2L48 351.4zm400 282H72l77.2-128H528z"
        ),
        isHighlighted: () => getParameter("sm") === "vat_rates",
        permissions: ["Company_VAT_Rates_Read"]
      }
    ]
  },
  {
    key: "client-accounts",
    label: "Client Accounts",
    icon: svgIcon(
      "M336 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm0 464H48V48h288v416zM144 112h96c8.8 0 16-7.2 16-16s-7.2-16-16-16h-96c-8.8 0-16 7.2-16 16s7.2 16 16 16zm48 176c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2z"
    ),
    permissions: ["Client_Read"]
  },
  {
    key: "reporting",
    label: "Reporting",
    icon: svgIcon(
      "M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
    ),
    permissions: ["Reporting_Read"]
  },
  {
    key: "orders(/:orderId)",
    label: "Orders and Payments",
    icon: svgIcon(
      "M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zM64 72c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8V72zm0 80v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8zm144 263.88V440c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-24.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V232c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v24.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07z"
    ),
    permissions: ["Orders_and_Payments_Read"]
  },
  {
    key: "agents",
    label: "Agents",
    icon: svgIcon(
      "M336 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm0 464H48V48h288v416zM144 112h96c8.8 0 16-7.2 16-16s-7.2-16-16-16h-96c-8.8 0-16 7.2-16 16s7.2 16 16 16zm48 176c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2z"
    ),
    permissions: ["Agent_Read"]
  },
  {
    key: "taxcodes(/:taxCode)",
    label: "Tax Codes",
    icon: svgIcon(
      "M128,256A128,128,0,1,0,256,384,128,128,0,0,0,128,256Zm379-54.86L400.07,18.29a37.26,37.26,0,0,0-64.14,0L229,201.14C214.76,225.52,232.58,256,261.09,256H474.91C503.42,256,521.24,225.52,507,201.14ZM480,288H320a32,32,0,0,0-32,32V480a32,32,0,0,0,32,32H480a32,32,0,0,0,32-32V320A32,32,0,0,0,480,288Z"
    ),
    permissions: ["Tax_Codes_Read"]
  },
  {
    key: "compliance-uploads",
    label: "Data Upload",
    icon: svgIcon(
      "M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
    ),
    permissions: ["Compliance_Upload_Read"]
  },
  {
    key: "return-library",
    label: "Return Library",
    icon: svgIcon(
      "M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"
    ),
    permissions: ["Submission_Period_Read"]
  },
  {
    key: "migration",
    label: "Migration",
    icon: svgIcon(
      "M347.94 129.86L203.6 195.83a31.938 31.938 0 0 0-15.77 15.77l-65.97 144.34c-7.61 16.65 9.54 33.81 26.2 26.2l144.34-65.97a31.938 31.938 0 0 0 15.77-15.77l65.97-144.34c7.61-16.66-9.54-33.81-26.2-26.2zm-77.36 148.72c-12.47 12.47-32.69 12.47-45.16 0-12.47-12.47-12.47-32.69 0-45.16 12.47-12.47 32.69-12.47 45.16 0 12.47 12.47 12.47 32.69 0 45.16zM248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm0 448c-110.28 0-200-89.72-200-200S137.72 56 248 56s200 89.72 200 200-89.72 200-200 200z"
    ),
    permissions: ["Migration"]
  },
  {
    key: "vat-rates-types",
    label: "VAT Rate Types",
    icon: svgIcon(
      "M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128A64 64 0 1 0 0 128a64 64 0 1 0 128 0zM384 384a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"
    ),
    permissions: ["VAT_Rates_Read"]
  },
  {
    key: "templates(/:templateTypeId)(/:templateId)",
    label: "Templates",
    icon: svgIcon(
      "M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"
    ),
    isSubMenuOpen: () => isPathStartsWith("templates"),
    permissions: ["Template_Read"],
    subMenus: [
      {
        key: "templates(/:templateTypeId)(/:templateId)",
        label: "VAT Registrations",
        routePostfix: () => "/MPVatReg",
        icon: html`&#9679`,
        permissions: ["Template_Read"]
      },
      {
        key: "templates(/:templateTypeId)(/:templateId)",
        label: "Companies",
        routePostfix: () => "/MPCompany",
        icon: html`&#9679`,
        permissions: ["Template_Read"]
      }
    ]
  },
  {
    key: "registrations",
    label: "Registrations",
    icon: svgIcon(
      "M501.62 92.11L267.24 2.04a31.958 31.958 0 0 0-22.47 0L10.38 92.11A16.001 16.001 0 0 0 0 107.09V144c0 8.84 7.16 16 16 16h480c8.84 0 16-7.16 16-16v-36.91c0-6.67-4.14-12.64-10.38-14.98zM64 192v160H48c-8.84 0-16 7.16-16 16v48h448v-48c0-8.84-7.16-16-16-16h-16V192h-64v160h-96V192h-64v160h-96V192H64zm432 256H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h480c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"
    ),
    isSubMenuOpen: () => isPathStartsWith("registrations"),
    permissions: ["VAT_Read", "IOSS_Read"],
    subMenus: [
      { key: "registrations/vat", label: "VAT", icon: html`&#9679`, permissions: ["VAT_Read"] },
      { key: "registrations/ioss", label: "IOSS", icon: html`&#9679`, permissions: ["IOSS_Read"] }
    ]
  },
  {
    key: "deadline-configuration",
    label: "Deadline Configuration",
    icon: svgIcon(
      "M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z"
    ),
    permissions: ["Submission_DeadlineConfiguration_Read"]
  }
];
export function Header() {
  const onToggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [, setCurrentPathname] = useState(window.location.pathname);
  const isPowerMode = useMemo(() => getParameter("chckn") !== void 0, []);
  const permissionManager = usePermissionManager();
  const init = () => {
    return addHandler("adminheader", (location) => {
      setCurrentPathname(location.pathname);
    });
  };
  useEffect(init, []);
  const loginLogout = () => {
    return html`<div class="relative bg-white w-full mr-4">
			<a @click=${() => setProfileMenuOpen(!profileMenuOpen)} class="text-gray-800 font-semibold cursor-pointer px-4 flex justify-between select-none">
				${ClientContext.UserName}
				<div class="${profileMenuOpen ? "-rotate-90" : "rotate-90"} block transform ml-2">&#10095;</div>
			</a>
			<div class="${profileMenuOpen ? "block  shadow-lg" : "hidden"} absolute bg-white w-full">
				<div class="relative px-4 py-2">
					<form method="POST" action="/account/logout">
						<input type="hidden" name="returnUrl" value="${getCurrentFullPath()}" />
						<button type="submit" class="w-full text-left">Logout</button>
					</form>
				</div>
			</div>
		</div>`;
  };
  const htmlMenuItem = (menuItem, isSubMenu = false) => {
    const path = removeOptionals(getRouteByKey(menuItem.key)) + (menuItem.routePostfix ? menuItem.routePostfix() : "");
    const isHighlighted = menuItem.isHighlighted ? menuItem.isHighlighted() : window.location.pathname.startsWith(path);
    return html`<a
			href=${path}
			@click=${(e) => {
      setMobileMenuOpen(false);
      if (!e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
        navigateTo(path);
      }
    }}
			><div
				class="py-2 px-2 mt-1 hover:bg-brand-secondary-light text-white rounded cursor-pointer ${isSubMenu ? "ml-6 mr-2" : "mx-2 font-bold"} ${isHighlighted ? "bg-brand-secondary-light" : ""}"
			>
				${isPowerMode ? html`&#128020;` : menuItem.icon}${menuItem.label ? html` ${menuItem.label}` : ""}
			</div>
		</a>`;
  };
  const htmlMenu = () => {
    return html`<div id="menu" style="width: 280px;" class="${mobileMenuOpen ? "block" : "hidden"} bg-brand-secondary-dark fixed md:block min-h-screen sm:h-full">
			<div class="flex flex-col justify-between min-h-screen">
				<div>
					<div class="pl-4 pt-4 hidden md:block">
						<a href="${getLocalizedUrl("")}">
							<div class="text-center">
								<div>
									<img src="/images/kpmg_logo_white.png" alt="KPMG logo" width="90" height="36" />
								</div>
							</div>
						</a>
					</div>
					<button type="button" @click=${onToggleMobileMenu} class="block text-white h-16 ml-4 focus:text-brand-secondary hover:text-brand-secondary focus:outline-none md:invisible">
						<svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
							<path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"></path>
						</svg>
					</button>

					${MAIN_MENUS.filter((m) => permissionManager.hasAnyPermission(m.permissions)).map((menuItem) => {
      var _a;
      const filteredSubMenus = (_a = menuItem.subMenus) == null ? void 0 : _a.filter((m) => permissionManager.hasAnyPermission(m.permissions));
      return html`<div>
							${htmlMenuItem(menuItem)}
							${(filteredSubMenus == null ? void 0 : filteredSubMenus.length) > 0 && menuItem.isSubMenuOpen() ? filteredSubMenus.map((subMenuItem) => {
        return htmlMenuItem(subMenuItem, true);
      }) : ""}
						</div>`;
    })}
				</div>
			</div>
		</div>`;
  };
  const htmlHeader = () => {
    return html`<div class="fixed bg-white h-16 flex w-full justify-between sm:items-center select-none border-b-2 border-gray-300">
			<div class="relative flex items-center justify-between sm:p-0">
				<div>
					${!mobileMenuOpen ? html`<button type="button" @click=${onToggleMobileMenu} class="block ml-4 text-brand-secondary focus:text-brand-secondary hover:text-brand-secondary focus:outline-none">
								<svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
									<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
								</svg>
						  </button>` : ""}
				</div>
				<div class="pl-2 flex items-center md:hidden">
					<a href="${getLocalizedUrl("admin")}">
						<div class="text-center">
							<div>
								<img src="/images/kpmg_logo.png" alt="KPMG logo" width="90" height="36" />
							</div>
						</div>
					</a>
				</div>
			</div>

			<div class="flex">${loginLogout()}</div>
		</div>`;
  };
  return html`<header class="relative">${htmlHeader()}${htmlMenu()}<dc-notifier></dc-notifier></header>`;
}
