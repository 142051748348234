import { DateTimeUtcRange } from "./../../Common/DateTime/DateTimeUtcRange";
import { DateRange } from "./../../Common/DateTime/DateRange";
export class Filter {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Filter
     */
    this.$type = "V1.Company.Search.Filter";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.AccountManagerEmail = _data["AccountManagerEmail"];
      this.ClientSource = FilterClientSource[_data["ClientSource"]];
      this.CompanyId = _data["CompanyId"];
      this.CompanyName = _data["CompanyName"];
      this.CoordinatorEmail = _data["CoordinatorEmail"];
      this.IsAssignedToMe = _data["IsAssignedToMe"];
      this.IsSignedUp = _data["IsSignedUp"];
      this.PreparerEmail = _data["PreparerEmail"];
      this.QuestionnaireStatus = FilterQuestionnaireStatus[_data["QuestionnaireStatus"]];
      if (_data["RegistrationDate"]) {
        const registrationDate_ = new DateTimeUtcRange();
        this.RegistrationDate = registrationDate_.init(_data["RegistrationDate"]);
      }
      this.ReviewerEmail = _data["ReviewerEmail"];
      if (_data["SentinelValidTo"]) {
        const sentinelValidTo_ = new DateRange();
        this.SentinelValidTo = sentinelValidTo_.init(_data["SentinelValidTo"]);
      }
      this.Status = FilterStatus[_data["Status"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["AccountManagerEmail"] = this.AccountManagerEmail;
    _data["ClientSource"] = FilterClientSource[this.ClientSource];
    _data["CompanyId"] = this.CompanyId;
    _data["CompanyName"] = this.CompanyName;
    _data["CoordinatorEmail"] = this.CoordinatorEmail;
    _data["IsAssignedToMe"] = this.IsAssignedToMe;
    _data["IsSignedUp"] = this.IsSignedUp;
    _data["PreparerEmail"] = this.PreparerEmail;
    _data["QuestionnaireStatus"] = FilterQuestionnaireStatus[this.QuestionnaireStatus];
    _data["RegistrationDate"] = this.RegistrationDate ? this.RegistrationDate.toJSON() : void 0;
    _data["ReviewerEmail"] = this.ReviewerEmail;
    _data["SentinelValidTo"] = this.SentinelValidTo ? this.SentinelValidTo.toJSON() : void 0;
    _data["Status"] = FilterStatus[this.Status];
    return _data;
  }
}
export var FilterClientSource = /* @__PURE__ */ ((FilterClientSource2) => {
  FilterClientSource2["Amazon"] = "Amazon";
  FilterClientSource2["KPMG"] = "KPMG";
  FilterClientSource2["FedEx"] = "FedEx";
  FilterClientSource2["LaPoste"] = "LaPoste";
  return FilterClientSource2;
})(FilterClientSource || {});
export var FilterQuestionnaireStatus = /* @__PURE__ */ ((FilterQuestionnaireStatus2) => {
  FilterQuestionnaireStatus2["Incomplete"] = "Incomplete";
  FilterQuestionnaireStatus2["Completed"] = "Completed";
  FilterQuestionnaireStatus2["Approved"] = "Approved";
  FilterQuestionnaireStatus2["DataVerified"] = "DataVerified";
  return FilterQuestionnaireStatus2;
})(FilterQuestionnaireStatus || {});
export var FilterStatus = /* @__PURE__ */ ((FilterStatus2) => {
  FilterStatus2["Active"] = "Active";
  FilterStatus2["Rejected"] = "Rejected";
  FilterStatus2["Inactive"] = "Inactive";
  return FilterStatus2;
})(FilterStatus || {});
