import { VatIdentificationNumber } from "./../../Common/VatIdentificationNumber";
import { DateConverter } from "./../../../../converters/DateConverter";
import { VatRegistrationStatusChange } from "./VatRegistrationStatusChange";
export class AddCertificateStatusChange extends VatRegistrationStatusChange {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof AddCertificateStatusChange
     */
    this.$type = "V1.Company.VatRegistration.AddCertificateStatusChange";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.EUVATCertificateReferenceId = _data["EUVATCertificateReferenceId"];
      if (_data["EUVatNumber"]) {
        const eUVatNumber_ = new VatIdentificationNumber();
        this.EUVatNumber = eUVatNumber_.init(_data["EUVatNumber"]);
      }
      this.EUVatRegistrationDate = DateConverter.from(_data["EUVatRegistrationDate"]);
      this.NotifyUser = _data["NotifyUser"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["EUVATCertificateReferenceId"] = this.EUVATCertificateReferenceId;
    _data["EUVatNumber"] = this.EUVatNumber ? this.EUVatNumber.toJSON() : void 0;
    _data["EUVatRegistrationDate"] = DateConverter.toUtcDateString(this.EUVatRegistrationDate);
    _data["NotifyUser"] = this.NotifyUser;
    return _data;
  }
}
