var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./return-library.scss";
import { formatPeriod, generateAllPeriods } from "../../helpers/periodHelper";
import {
  ALL_ACTION_LABELS,
  ALL_STATUSES,
  ALL_TAX_RETURN_TYPES,
  ReturnLibraryFieldNames,
  getPaymentStatusLabel,
  getStatusLabel,
  PAYMENT_PROOF_DS,
  ALL_WEB_STATUSES,
  ALL_ESTABLISHMENT_COUNTRY_TYPES,
  ALL_TAX_RETURN_TYPE_LABELS,
  ALL_SUBMISSION_STATUS_LABELS
} from "./SubmissionModels";
import { useEffect, useMemo, useQueryGridState, useState } from "GlobalShared/haunted/CustomHooks";
import { formatDate, formatDateTime, getDay } from "GlobalShared/helpers/dateHelper";
import { extractFirstError, notifyError, notifyErrorWithWarning, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { mapBooleanToState } from "GlobalShared/helpers/booleanHelper";
import { templateTitle } from "GlobalShared/templates/commons";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import i18next from "i18next";
import { html } from "lit-html";
import { useReturnLibraryModals } from "./return-library-modals";
import moment from "moment";
const INITIAL_FILTERS = [];
const DEFAULT_FILTERS = [];
export const useReturnLibrary = (props) => {
  const loadSubmissions = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        const result = yield props.searchSubmissions(
          {
            gridState,
            paymentProofFilter: selectedPaymentProofFilter
          },
          onlyAssignedToMe,
          assigneeFragment
        );
        setSearchResult(result);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const onDocumentDownload = (submissionPeriodId, documentType, fileReference) => __async(void 0, null, function* () {
    yield props.downloadDocument(submissionPeriodId, documentType, fileReference);
  });
  const changeStatus = (submissionPeriodId, oldStatus, newStatus, comment, attachmentIds, isRevision, returnGeneratedModel) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.changeStatus(submissionPeriodId, oldStatus, newStatus, comment, attachmentIds, isRevision, returnGeneratedModel);
        notifySuccess(i18next.t("Status changed successfully"));
        loadSubmissions();
      }),
      (error) => {
        const firstError = extractFirstError(error);
        if ((firstError == null ? void 0 : firstError.code) === "SubmissionPeriodInvalidApproveAfterSubmissionDueDate") {
          notifyWarning(i18next.t("Return cannot be submitted after submission due date"));
        } else {
          notifyErrorWithWarning(error);
        }
      }
    );
  });
  const changePaymentStatus = (submissionPeriodId, newStatus) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.changePaymentStatus(submissionPeriodId, newStatus);
        notifySuccess(i18next.t("Payment status changed successfully"));
      }),
      (error) => {
        notifyError(error);
      },
      () => {
        loadSubmissions();
      }
    );
  });
  const getNextAdminStatuses = (item) => {
    let nextStatuses = [];
    const status = item.Status;
    const isPaperFiling = !item.EFiling;
    if (status === "New") {
      nextStatuses = ["DataUploaded", "ReturnGenerated", "NotToBeSubmitted"];
    } else if (status === "DataUploaded") {
      nextStatuses = ["New", "ReturnGenerated", "ErrorReturnGen"];
    } else if (status === "ErrorReturnGen") {
      nextStatuses = ["New", "DataUploaded", "ReturnGenerated"];
    } else if (status === "ReturnGenerated") {
      nextStatuses = ["New", "DataUploaded", "ErrorReturnGen", "ApprovedByClient", "RefusedByClient", "NotToBeSubmitted"];
    } else if (status === "ApprovedByClient") {
      nextStatuses = ["ReturnGenerated", "RefusedByClient", "ErrorAtTA", "Submitted", "NotToBeSubmitted"];
      if (isPaperFiling) {
        nextStatuses = nextStatuses.concat(["SubmittedByClient"]);
      }
    } else if (status === "RefusedByClient") {
      nextStatuses = ["New", "ApprovedByClient", "ReturnGenerated", "NotToBeSubmitted"];
    } else if (status === "ErrorAtTA") {
      nextStatuses = ["New", "ReturnGenerated", "ApprovedByClient"];
    } else if (status === "SentToTA") {
      nextStatuses = ["ErrorAtTA", "ReturnGenerated", "Rejected", "Submitted"];
    } else if (status === "Rejected") {
      nextStatuses = ["New", "SentToTA", "Submitted"];
    } else if (status === "Submitted") {
      nextStatuses = ["New", "SentToTA", "Rejected"];
    } else if (status === "SubmittedByClient") {
      nextStatuses = ["New", "ApprovedByClient"];
    } else if (status === "NotToBeSubmitted") {
      nextStatuses = ["New", "ReturnGenerated", "RefusedByClient", "ApprovedByClient"];
    }
    return nextStatuses;
  };
  const getNextPaymentStatuses = (paymentStatus) => {
    let nextStatuses = [];
    if (paymentStatus === "Due") {
      nextStatuses = ["Paid", "PaidByClient"];
    } else if (paymentStatus === "Paid") {
      nextStatuses = ["PaidByClient", "Due"];
    } else if (paymentStatus === "PaidByClient") {
      nextStatuses = ["Paid", "Due"];
    }
    return nextStatuses;
  };
  const getActionsDataSource = (item) => {
    if (props.isAdmin && !props.permissionManager.hasPermission("Submission_Period_Modify")) {
      return [];
    }
    let actions = [];
    const isPaperFiling = !item.EFiling;
    if (!props.isAdmin) {
      if (item.Status === "ReturnGenerated") {
        actions = ["Approve", "Reject"];
      } else if (item.Status === "ApprovedByClient" && isPaperFiling) {
        actions = ["Submit"];
      } else if (item.Status === "RefusedByClient") {
        actions = (isPaperFiling ? ["Reopen"] : []).concat(["Approve"]);
      } else if (item.Status === "SubmittedByClient" && isPaperFiling) {
        actions = ["ReturnToApproved"];
      }
    } else {
      if (item.TaxReturnType !== "IOSS") {
        if (item.Status === "DataUploaded" || item.Status === "ErrorReturnGen") {
          actions = ["ManualUpload"];
        }
      }
    }
    if (props.isAdmin && item.Status === "NotToBeSubmitted") {
      actions.push("UploadNotToBeSubmittedProof");
    }
    const uploadPaymentProofStatuses = ["ApprovedByClient", "ErrorAtTA", "SentToTA", "Submitted", "SubmittedByClient"];
    if (uploadPaymentProofStatuses.includes(item.Status)) {
      if (item.TaxReturnType === "IOSS" || item.TaxReturnType === "PrimaryVAT" && item.PaymentCategory === "Payable") {
        actions.push("UploadPaymentProof");
      }
    }
    const payComplianceFeeStatuses = ["SentToTA", "Submitted", "SubmittedByClient"];
    if (payComplianceFeeStatuses.includes(item.Status) && item.TaxReturnType === "IOSS" && item.PaymentStatus === "Due" && item.PaymentUrl !== void 0) {
      actions.push("PayComplianceFee");
    }
    const submissionProofStatuses = ["Submitted", "SubmittedByClient"];
    if (submissionProofStatuses.includes(item.Status)) {
      if ((props.isAdmin || isPaperFiling) && item.TaxReturnType !== "Intrastat") {
        actions.push("UploadSubmissionProof");
      }
      if (props.isAdmin && item.TaxReturnType === "Intrastat") {
        actions.push("UploadIntrastatInSubmissionProof");
        actions.push("UploadIntrastatOutSubmissionProof");
      }
    }
    return Object.keys(ALL_ACTION_LABELS).filter((a) => actions.includes(a)).map((a) => ({ label: ALL_ACTION_LABELS[a], value: a }));
  };
  const [gridState, setGridState] = useQueryGridState({
    pageIndex: 0,
    appliedFilters: INITIAL_FILTERS,
    pageSize: 20,
    orderBy: "PeriodStartDate",
    orderDir: "desc"
  });
  const [searchResult, setSearchResult] = useState(void 0);
  const [selectedPaymentProofFilter, setSelectedPaymentProofFilter] = useState("All");
  const [onlyAssignedToMe, setOnlyAssignedToMe] = useState(false);
  const [showNearDeadlines, setShowNearDeadlines] = useState(false);
  const [assigneeFragment, setAssigneeFragment] = useState(void 0);
  const periodsDataSource = useMemo(() => generateAllPeriods(), []);
  const [showRejectionCommentModal, setShowRejectionCommentModal] = useState(false);
  const [rejectionComment, setRejectionComment] = useState(void 0);
  const returnLibraryModalsHook = useReturnLibraryModals({
    availableCurrencies: props.availableCurrencies,
    loadSubmissions,
    changeStatus,
    uploadPaymentProof: props.uploadPaymentProof,
    uploadSubmissionProof: props.uploadSubmissionProof,
    uploadSubmissionProof2: props.uploadSubmissionProof2,
    uploadReturnDocuments: props.uploadReturnDocuments,
    downloadDocument: props.downloadDocument,
    uploadRejectionAttachments: props.uploadRejectionAttachments,
    uploadNotToBeSubmittedProof: props.uploadNotToBeSubmittedProof
  });
  useEffect(() => __async(void 0, null, function* () {
    loadSubmissions();
  }), [gridState, selectedPaymentProofFilter, onlyAssignedToMe, assigneeFragment]);
  useEffect(() => __async(void 0, null, function* () {
    if (showNearDeadlines) {
      const newStatuses = ["New", "DataUploaded", "ReturnGenerated", "ApprovedByClient", "RefusedByClient", "ErrorReturnGen"];
      setGridState(__spreadProps(__spreadValues({}, gridState), {
        pageIndex: 0,
        appliedFilters: mergeAppliedFilter(
          mergeAppliedFilter(gridState.appliedFilters, {
            field: ReturnLibraryFieldNames.SubmissionDueDate,
            filterProps: {
              operator: "in",
              min: moment().toDate(),
              max: moment().add(6, "days").toDate()
            }
          }),
          {
            field: ReturnLibraryFieldNames.Status,
            filterProps: {
              operator: "in",
              selectedValues: newStatuses
            }
          }
        )
      }));
    } else {
      setGridState(__spreadProps(__spreadValues({}, gridState), {
        pageIndex: 0,
        appliedFilters: gridState.appliedFilters.filter((f) => f.field !== ReturnLibraryFieldNames.Status && f.field !== ReturnLibraryFieldNames.SubmissionDueDate)
      }));
    }
  }), [showNearDeadlines]);
  useEffect(() => __async(void 0, null, function* () {
    if (searchResult !== void 0) {
      yield withErrorHandling(
        () => __async(void 0, null, function* () {
          const newSubmissions = [];
          let changed = false;
          for (const sub of searchResult.submissions) {
            if (sub.DetailsOpen && sub.ReturnFiles === void 0) {
              const newSubmissionFiles = yield props.getSubmissionPeriodFiles(sub.Id);
              const newSubmission = __spreadValues(__spreadValues({}, sub), newSubmissionFiles);
              newSubmission.DetailsOpen = true;
              newSubmissions.push(newSubmission);
              changed = true;
            } else {
              newSubmissions.push(sub);
            }
          }
          if (changed) {
            setSearchResult(__spreadProps(__spreadValues({}, searchResult), { submissions: newSubmissions }));
          }
        }),
        (error) => {
          notifyError(error);
        }
      );
    }
  }), [searchResult]);
  const htmlSubmissionDetails = (submission) => {
    var _a, _b;
    if (submission.ReturnFiles === void 0) {
      return html`<div>${i18next.t("Loading")}...</div>`;
    } else {
      return html`<div class="space-y-4">
				<div>
					<div class="font-bold">${i18next.t("Transactional data")}</div>
					<div class="mt-2">
						${submission.TransactionFiles ? submission.TransactionFiles.map((f) => {
        return html`<div>
										<a @click=${() => onDocumentDownload(submission.Id, "Transaction", f.Reference)}>${f.Name}${f.CreatedAt ? html`(${formatDateTime(f.CreatedAt)})` : ""}</a>
									</div>`;
      }) : ""}
					</div>
				</div>
				${props.isAdmin ? html`<div>
								<div class="font-bold">DataVat</div>
								<div class="mt-2">
									${submission.DataVatFiles ? submission.DataVatFiles.map((f) => {
        return html`<div>
													<a @click=${() => onDocumentDownload(submission.Id, "DataVat", f.Reference)}
														>${f.Name}${f.CreatedAt ? html`(${formatDateTime(f.CreatedAt)})` : ""}</a
													>
												</div>`;
      }) : ""}
								</div>
							</div>
							<div>
								<div class="font-bold">Out of scope files</div>
								<div class="mt-2">
									${submission.OutOfScopeFiles ? submission.OutOfScopeFiles.map((f) => {
        return html`<div>
													<a @click=${() => onDocumentDownload(submission.Id, "OutOfScopeFile", f.Reference)}
														>${f.Name}${f.CreatedAt ? html`(${formatDateTime(f.CreatedAt)})` : ""}</a
													>
												</div>`;
      }) : ""}
								</div>
							</div>` : ""}
				<div>
					<div class="font-bold">${i18next.t("Invalid validation reports")}</div>
					<div class="mt-2">
						${submission.InvalidVatNumbersReportFiles ? submission.InvalidVatNumbersReportFiles.map((f) => {
        return html`<div>
										<a @click=${() => onDocumentDownload(submission.Id, "InvalidVatNumbersReport", f.Reference)}
											>${f.Name}${f.CreatedAt ? html`(${formatDateTime(f.CreatedAt)})` : ""}</a
										>
									</div>`;
      }) : ""}
					</div>
				</div>
				<div>
					<div class="font-bold">${i18next.t("Return documents")}</div>
					<div class="mt-2">
						${submission.ReturnFiles ? submission.ReturnFiles.map((f) => {
        return html`<div>
										<a @click=${() => onDocumentDownload(submission.Id, "Return", f.Reference)}>${f.Name}${f.CreatedAt ? html`(${formatDateTime(f.CreatedAt)})` : ""}</a>
									</div>`;
      }) : ""}
					</div>
				</div>
				${submission.PaymentProof ? html`<div>
							<div class="font-bold">${i18next.t("Payment proof")}</div>
							<div class="mt-2">
								<a @click=${() => onDocumentDownload(submission.Id, "PaymentProof", submission.PaymentProof.Reference)}>${submission.PaymentProof.Name}</a>
							</div>
					  </div>` : ""}
				${submission.SubmissionProof ? html`<div>
							<div class="font-bold">${i18next.t("Submission proof")}</div>
							<div class="mt-2">
								<a
									@click=${() => onDocumentDownload(
        submission.Id,
        submission.TaxReturnType === "Intrastat" ? "SubmissionProofIntrastatIn" : "SubmissionProof",
        submission.SubmissionProof.Reference
      )}
									>${submission.SubmissionProof.Name}${submission.SubmissionProofDate ? html`(${formatDateTime(submission.SubmissionProofDate)})` : ""}</a
								>
							</div>
					  </div>` : ""}
				${submission.SubmissionProof2 ? html`<div>
							<div class="font-bold">${i18next.t("Submission proof")} 2</div>
							<div class="mt-2">
								<a @click=${() => onDocumentDownload(submission.Id, "SubmissionProofIntrastatOut", submission.SubmissionProof2.Reference)}
									>${submission.SubmissionProof2.Name}${submission.SubmissionProof2Date ? html`(${formatDateTime(submission.SubmissionProof2Date)})` : ""}</a
								>
							</div>
					  </div>` : ""}
				${submission.NotToBeSubmittedProof ? html`<div>
							<div class="font-bold">${i18next.t("Not to be submitted proof")}</div>
							<div class="mt-2">
								<a @click=${() => onDocumentDownload(submission.Id, "NotToBeSubmittedProof", submission.NotToBeSubmittedProof.Reference)}
									>${submission.NotToBeSubmittedProof.Name}${submission.NotToBeSubmittedProof.CreatedAt ? html`(${formatDateTime(submission.NotToBeSubmittedProof.CreatedAt)})` : ""}</a
								>
							</div>
					  </div>` : ""}
				${props.isAdmin && ((_a = submission.Errors) == null ? void 0 : _a.length) > 0 ? html`<div>
							<div class="font-bold text-red-500">Desucla Errors</div>
							<div class="mt-2">
								${submission.Errors.map((e) => {
        return html`<div class="text-red-500">${e}</div>`;
      })}
							</div>
					  </div>` : ""}
				${props.isAdmin && ((_b = submission.RejectReasons) == null ? void 0 : _b.length) > 0 ? html`
                            <div>
                                <div class="font-bold">Return Refused Reason</div>
                                <div class="mt-2 space-y-3">
                                    ${submission.RejectReasons.map((e) => {
        var _a2, _b2, _c, _d;
        return html`<div class="ml-4">
											<div class="flex">
												<div class="font-bold mr-1">Date:</div>
												<div>${formatDateTime(e.RejectedAt)}</div>
											</div>
											<div class="flex">
												<div class="font-bold mr-1">Comment:</div>
												<div>${(_a2 = e.Comment) == null ? void 0 : _a2.substring(0, 200)}</div>
												${((_b2 = e.Comment) == null ? void 0 : _b2.length) > 200 ? html`<div>...</div>
															<dc-view-button
																class="my-auto ml-2"
																title="View full comment"
																@click=${() => {
          setRejectionComment(e.Comment);
          setShowRejectionCommentModal(true);
        }}
															></dc-view-button>` : ""}
											</div>
											${((_c = e.ReturnFiles) == null ? void 0 : _c.length) > 0 ? html`
                                                            <div class="font-bold">Attached files:</div>
                                                            <div>
                                                                ${(_d = e.ReturnFiles) == null ? void 0 : _d.map((rf) => {
          return html`<div>
																		<a @click=${() => onDocumentDownload(submission.Id, "RefusedReasonFile", rf.Reference)}
																			>${rf.Name}${rf.CreatedAt ? html`(${formatDateTime(rf.CreatedAt)})` : ""}</a
																		>
																	</div>`;
        })}
                                                            </div></div>` : ""}
										</div>`;
      })}
                                </div>
                            </div>
                            </div>` : ""}
			</div>`;
    }
  };
  const htmlGrid = () => {
    var _a;
    const columns = [
      {
        field: ReturnLibraryFieldNames.Details,
        label: "",
        columnClass: "w-10"
      }
    ].concat(
      props.isAdmin ? [
        {
          field: ReturnLibraryFieldNames.CompanyId,
          label: i18next.t("ID"),
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: ReturnLibraryFieldNames.CompanyName,
          columnClass: "w-20",
          label: i18next.t("Client name"),
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        }
      ] : []
    ).concat([
      {
        field: ReturnLibraryFieldNames.TaxReturnType,
        label: i18next.t("Return type"),
        filterable: true,
        sortable: true,
        filterDescriptor: {
          type: "select",
          multiSelect: true,
          searchable: true,
          searchProps: { type: "includes" },
          dataSource: ALL_TAX_RETURN_TYPES.map((rt) => ({
            value: rt,
            label: ALL_TAX_RETURN_TYPE_LABELS[rt]
          }))
        },
        columnType: "enum"
      },
      {
        field: ReturnLibraryFieldNames.ServiceCountry,
        columnClass: "w-16",
        label: i18next.t("Country"),
        filterable: true,
        sortable: true,
        filterDescriptor: {
          type: "select",
          dataSource: props.serviceCountries.map((sc) => ({ label: sc.Name, value: sc.Code })),
          searchable: true,
          searchProps: { type: "includes" }
        },
        columnType: "enum"
      }
    ]).concat(
      props.isAdmin ? [
        {
          field: ReturnLibraryFieldNames.EstablishmentCountryType,
          label: i18next.t("Est. Country type"),
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: ALL_ESTABLISHMENT_COUNTRY_TYPES.map((ct) => ({ label: ct, value: ct }))
          },
          columnType: "enum"
        }
      ] : []
    ).concat([
      {
        field: ReturnLibraryFieldNames.PeriodStartDate,
        label: i18next.t("Period"),
        sortable: true,
        filterable: true,
        filterDescriptor: {
          type: "select",
          searchable: true,
          searchProps: { type: "includes" },
          multiSelect: true,
          dataSource: periodsDataSource
        },
        columnType: "enum"
      },
      {
        field: ReturnLibraryFieldNames.ClientApprovalDueDate,
        label: i18next.t("Approval due"),
        sortable: true,
        filterable: true,
        columnType: "date",
        filterDescriptor: { from: true, to: true, type: "date" }
      },
      {
        field: ReturnLibraryFieldNames.SubmissionDueDate,
        label: i18next.t("Submission due"),
        sortable: true,
        filterable: true,
        columnType: "date",
        filterDescriptor: { from: true, to: true, type: "date" }
      }
    ]).concat(
      props.isAdmin ? [
        {
          field: ReturnLibraryFieldNames.ReturnGenerationDueDate,
          label: i18next.t("Return gen due"),
          sortable: true,
          filterable: true,
          columnType: "date",
          filterDescriptor: { from: true, to: true, type: "date" }
        }
      ] : []
    ).concat([
      {
        field: ReturnLibraryFieldNames.PaymentDueDate,
        label: i18next.t("Payment due"),
        sortable: true,
        filterable: true,
        columnType: "date",
        filterDescriptor: { from: true, to: true, type: "date" }
      },
      {
        field: ReturnLibraryFieldNames.PaymentStatus,
        columnClass: "w-24",
        label: i18next.t("Payment status"),
        // TODO: implement filter, sort
        // sortable: true,
        // filterable: true,
        // filterDescriptor: {
        // 	type: "select",
        // 	multiSelect: true,
        // 	dataSource: ALL_PAYMENT_STATUSES,
        // },
        // columnType: "enum",
        columnType: "string"
      }
    ]).concat(
      props.isAdmin ? [
        {
          field: ReturnLibraryFieldNames.Status,
          columnClass: "w-64",
          label: i18next.t("Status"),
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            multiSelect: true,
            searchable: true,
            searchProps: { type: "includes" },
            dataSource: ALL_STATUSES.map((s) => ({
              value: s,
              label: ALL_SUBMISSION_STATUS_LABELS[s]
            })),
            rightAlign: true
          },
          columnType: "enum"
        },
        {
          field: ReturnLibraryFieldNames.Revision,
          columnClass: "w-10",
          label: i18next.t("Revision"),
          columnType: "string"
        }
      ] : []
    ).concat(
      !props.isAdmin ? [
        {
          field: ReturnLibraryFieldNames.Status,
          columnClass: "w-64",
          label: i18next.t("Status"),
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            multiSelect: true,
            searchable: true,
            searchProps: { type: "includes", ignoreCase: true },
            dataSource: ALL_WEB_STATUSES.map((s) => ({
              value: s,
              label: ALL_SUBMISSION_STATUS_LABELS[s]
            })),
            rightAlign: true
          },
          columnType: "enum"
        }
      ] : []
    ).concat([
      {
        field: ReturnLibraryFieldNames.Actions,
        label: ""
      }
    ]);
    const vm = {
      columns,
      data: searchResult.submissions,
      // eslint-disable-next-line complexity
      cellTemplate: (index, field) => {
        var _a2, _b;
        const item = searchResult.submissions[index];
        const htmlDueDate = (dueDate, dateToCheck) => {
          if (dueDate === void 0) {
            return i18next.t("Not Applicable");
          } else if (dateToCheck === void 0 && getDay(dueDate) < getDay(/* @__PURE__ */ new Date())) {
            return html`<span class="text-red-500">${formatDate(dueDate)}</span>`;
          } else {
            return formatDate(dueDate);
          }
        };
        if (field === ReturnLibraryFieldNames.Details) {
          return html`
						<button
							class="focus:outline-none"
							@click=${() => {
            setSearchResult(__spreadProps(__spreadValues({}, searchResult), {
              submissions: searchResult.submissions.map((o, i) => {
                if (i === index) {
                  return __spreadProps(__spreadValues({}, o), { DetailsOpen: !o.DetailsOpen });
                } else {
                  return o;
                }
              })
            }));
          }}
						>
							<div class="px-1 font-bold w-5 transform ${item.DetailsOpen ? "-rotate-90" : "rotate-90"}">&#10095;</div>
						</button>
					`;
        } else if (field === ReturnLibraryFieldNames.CompanyId) {
          return item.ClientId.toString();
        } else if (field === ReturnLibraryFieldNames.CompanyName) {
          return html`<div class="absolute left-0 right-0 vertical-center truncate px-3" title="${item.ClientName}">${item.ClientName}</div>`;
        } else if (field === ReturnLibraryFieldNames.TaxReturnType) {
          return ALL_TAX_RETURN_TYPE_LABELS[item.TaxReturnType];
        } else if (field === ReturnLibraryFieldNames.ServiceCountry) {
          return ((_a2 = item.ServiceCountry) == null ? void 0 : _a2.Code) === "EL" ? "GR" : (_b = item.ServiceCountry) == null ? void 0 : _b.Code;
        } else if (field === ReturnLibraryFieldNames.EstablishmentCountryType) {
          return item.EstablishmentCountryType;
        } else if (field === ReturnLibraryFieldNames.PeriodStartDate) {
          return formatPeriod(moment.utc(item.PeriodStartDate), item.FrequencyType);
        } else if (field === ReturnLibraryFieldNames.ReturnGenerationDueDate) {
          return htmlDueDate(item.ReturnGenerationDueDate, item.ReturnGenerationDate);
        } else if (field === ReturnLibraryFieldNames.ClientApprovalDueDate) {
          return htmlDueDate(item.ClientApprovalDueDate, item.ClientApprovalDate);
        } else if (field === ReturnLibraryFieldNames.SubmissionDueDate) {
          return htmlDueDate(item.SubmissionDueDate, item.SubmissionDate);
        } else if (field === ReturnLibraryFieldNames.PaymentDueDate) {
          return htmlDueDate(item.SubmittedAndPaidDueDate, item.SubmittedAndPaidDate);
        } else if (field === ReturnLibraryFieldNames.PaymentProofUploaded) {
          if (item.PaymentProof) {
            return html`<a
							class="absolute left-0 right-0 vertical-center truncate"
							title="${item.PaymentProof.Name}"
							@click=${() => {
              props.downloadDocument(item.Id, "PaymentProof", item.PaymentProof.Reference);
            }}
							>${item.PaymentProof.Name}</a
						>`;
          } else {
            return "";
          }
        } else if (field === ReturnLibraryFieldNames.Status) {
          if (props.isAdmin) {
            return html`<dc-dropdown
							.isStatusLike=${true}
							.buttonText=${getStatusLabel(item.Status)}
							.selectedValues=${[item.Status]}
							.align=${"right"}
							.readonly=${!props.permissionManager.hasPermission("Submission_Period_Modify")}
							.dataSource=${getNextAdminStatuses(item).map((status) => ({
              label: getStatusLabel(status),
              value: status
            }))}
							@click=${(e) => __async(void 0, null, function* () {
              const newSubmissionStatus = e.detail.selectedValue;
              if (newSubmissionStatus === "New" && item.TaxReturnType !== "IOSS") {
                returnLibraryModalsHook.openStatusChangedToNewModal(item);
              } else if (newSubmissionStatus === "ReturnGenerated" && item.Status !== "ApprovedByClient" && (item.TaxReturnType === "Intrastat" || item.TaxReturnType === "PrimaryVAT")) {
                returnLibraryModalsHook.openStatusChangedToReturnGeneratedModal(item);
              } else {
                if (newSubmissionStatus === "ApprovedByClient" && item.TaxReturnType === "IOSS" && item.PaymentDueDate.getDate() < Date.now()) {
                  notifyWarning(i18next.t("Please note that your return will only be submitted if payment is received by the VAT intermediary before the submission deadline."));
                }
                const oldSubmissionStatus = item.Status;
                yield changeStatus(item.Id, oldSubmissionStatus, newSubmissionStatus, void 0, void 0, void 0, void 0);
                if (oldSubmissionStatus === "ApprovedByClient" && newSubmissionStatus === "Submitted") {
                  returnLibraryModalsHook.openUploadModal(item, "SubmissionProof");
                }
              }
            })}
						></dc-dropdown>`;
          } else {
            const localizedStatus = ALL_SUBMISSION_STATUS_LABELS[item.Status];
            return html`<span title=${localizedStatus}>${localizedStatus}</span>`;
          }
        } else if (field === ReturnLibraryFieldNames.PaymentStatus) {
          const readOnly = props.isAdmin && !props.permissionManager.hasPermission("Submission_Period_Modify") || !props.isAdmin && item.TaxReturnType !== "PrimaryVAT";
          const nextPaymentStatuses = getNextPaymentStatuses(item.PaymentStatus);
          if (readOnly || nextPaymentStatuses.length === 0) {
            return getPaymentStatusLabel(item.PaymentStatus);
          } else {
            return html`<dc-dropdown
							.isStatusLike=${true}
							.buttonText=${getPaymentStatusLabel(item.PaymentStatus)}
							.selectedValues=${[item.PaymentStatus]}
							.align=${"right"}
							.dataSource=${nextPaymentStatuses.map((status) => ({
              label: getPaymentStatusLabel(status),
              value: status
            }))}
							@click=${(e) => {
              const newPaymentStatus = e.detail.selectedValue;
              changePaymentStatus(item.Id, newPaymentStatus);
            }}
						></dc-dropdown>`;
          }
        } else if (field === ReturnLibraryFieldNames.Revision) {
          return mapBooleanToState(item.Revision);
        } else if (field === ReturnLibraryFieldNames.Actions) {
          return getActionsDataSource(item).length === 0 ? "" : html`<dc-dropdown
								.buttonText=${i18next.t("Actions")}
								.align=${"right"}
								.dataSource=${getActionsDataSource(item)}
								@click=${(e) => {
            const action = e.detail.selectedValue;
            if (item.Status === "ReturnGenerated" && action === "Approve") {
              if (item.TaxReturnType === "IOSS" && item.PaymentDueDate.getDate() < Date.now()) {
                notifyWarning(
                  i18next.t("Please note that your return will only be submitted if payment is received by the VAT intermediary before the submission deadline.")
                );
              }
              changeStatus(item.Id, item.Status, "ApprovedByClient", void 0, void 0, void 0, void 0);
            } else if (item.Status === "ReturnGenerated" && action === "Reject") {
              returnLibraryModalsHook.openRefusedByClientModal(item);
            } else if (item.Status === "ApprovedByClient" && action === "Submit") {
              changeStatus(item.Id, item.Status, "SubmittedByClient", void 0, void 0, void 0, void 0);
            } else if (item.Status === "RefusedByClient" && action === "Approve") {
              changeStatus(item.Id, item.Status, "ApprovedByClient", void 0, void 0, void 0, void 0);
            } else if (item.Status === "RefusedByClient" && action === "Reopen") {
              returnLibraryModalsHook.openStatusChangedToNewModal(item);
            } else if (item.Status === "SubmittedByClient" && action === "ReturnToApproved") {
              changeStatus(item.Id, item.Status, "ApprovedByClient", void 0, void 0, void 0, void 0);
            } else if (action === "UploadPaymentProof") {
              returnLibraryModalsHook.openUploadModal(item, "PaymentProof");
            } else if (action === "ManualUpload") {
              returnLibraryModalsHook.openReturnDocumentsUploadModal(item);
            } else if (action === "PayComplianceFee") {
              returnLibraryModalsHook.openComplianceFeeModal(item);
            } else if (action === "UploadSubmissionProof" || action === "UploadIntrastatInSubmissionProof") {
              returnLibraryModalsHook.openUploadModal(item, "SubmissionProof");
            } else if (action === "UploadIntrastatOutSubmissionProof") {
              returnLibraryModalsHook.openUploadModal(item, "SubmissionProof2");
            } else if (action === "UploadNotToBeSubmittedProof") {
              returnLibraryModalsHook.openUploadModal(item, "NotToBeSubmittedProof");
            } else {
              notifyWarning("Action cannot be performed");
            }
          }}
						  ></dc-dropdown>`;
        } else {
          return "";
        }
      },
      cellClass: (_index, field) => {
        if (field === ReturnLibraryFieldNames.Actions || field === ReturnLibraryFieldNames.Status || field === ReturnLibraryFieldNames.PaymentStatus) {
          return "actions";
        }
        return "";
      },
      rowDetailsTemplate: (index) => {
        if (searchResult.submissions[index].DetailsOpen) {
          return html`<tr style="background-color:white">
						<td colspan="8">
							<div class="my-4">${htmlSubmissionDetails(searchResult.submissions[index])}</div>
						</td>
					</tr>`;
        } else {
          return html``;
        }
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: searchResult.total
      }
    };
    return html`<div class="mt-4 ">
			<div>
				<div>${templateTitle(i18next.t("Return Library"))}</div>
				<div class="flex space-x-4 my-4 items-end">
					${props.isAdmin ? html`
								<dc-input
									class="w-64"
									.label=${"Assignee"}
									.value=${assigneeFragment}
									@change=${(e) => {
      setAssigneeFragment(e.detail.value);
    }}
								></dc-input>
						  ` : ""}
					<dc-select
						class="w-64"
						.label=${i18next.t("Payment proof")}
						.dataSource=${PAYMENT_PROOF_DS}
						.selectedValues=${selectedPaymentProofFilter}
						@change=${(e) => {
      const newVal = e.detail.selectedValue;
      setSelectedPaymentProofFilter(newVal);
    }}
					></dc-select>
					<div class="flex space-x-4 items-center">
						${props.isAdmin ? html`<div>
									<button
										class="btn btn-primary"
										?disabled=${(searchResult == null ? void 0 : searchResult.total) === 0}
										@click=${() => props.exportSubmissions({
      gridState: __spreadProps(__spreadValues({}, gridState), {
        pageIndex: 0,
        pageSize: (searchResult == null ? void 0 : searchResult.total) === 0 ? 1 : searchResult == null ? void 0 : searchResult.total
      }),
      paymentProofFilter: selectedPaymentProofFilter
    })}
									>
										Export as CSV
									</button>
							  </div>` : ""}
						${props.isAdmin ? html`
									<div>
										<dc-checkbox2
											.checked=${onlyAssignedToMe}
											.label=${"Only assigned to me"}
											.labelnowrap=${true}
											@change=${(e) => {
      setOnlyAssignedToMe(e.detail.checked);
    }}
										></dc-checkbox2>
									</div>
									<div>
										<dc-checkbox2
											.checked=${showNearDeadlines}
											.label=${"Show near deadlines"}
											.labelnowrap=${true}
											@change=${(e) => {
      setShowNearDeadlines(e.detail.checked);
    }}
										></dc-checkbox2>
									</div>
							  ` : ""}
						${((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html`<div>
									<button
										class="btn btn-primary whitespace-no-wrap"
										@click=${() => {
      setGridState(__spreadProps(__spreadValues({}, gridState), {
        pageIndex: 0,
        appliedFilters: DEFAULT_FILTERS
      }));
      setShowNearDeadlines(false);
    }}
									>
										${i18next.t("Reset filters")}
									</button>
							  </div>` : ""}
					</div>
				</div>
			</div>
			<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>
		</div>`;
  };
  const onSortChanged = (e) => {
    const OrderByValue = e.detail.orderBy;
    const OrderDirValue = e.detail.orderDir;
    setGridState(__spreadProps(__spreadValues({}, gridState), {
      orderBy: OrderByValue,
      orderDir: OrderDirValue
    }));
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridFilter);
  };
  const rejectionCommentModal = () => {
    return html`
			<div style="max-width: 300px">
				<dc-modal .visible=${showRejectionCommentModal} @close=${() => setShowRejectionCommentModal(false)} .header=${"Refuse comment"} .content=${html`<pre>${rejectionComment}</pre>`}>
				</dc-modal>
			</div>
		`;
  };
  const mainTemplate = html`<div class="my-2 mx-8 mb-16 mt-8 flex justify-between">
		<div class="grid grid-cols-1 gap-2 mb-8 w-full">${searchResult ? htmlGrid() : `${i18next.t("Loading")}...`}</div>
		${returnLibraryModalsHook.htmlAllModals} ${rejectionCommentModal()}
	</div>`;
  return { mainTemplate };
};
