import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { MultiCountryAnswer as WebMultiCountryAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { MultiCountryAnswer as AdminMultiCountryAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { MultiCountryValidator } from "./AnswerValidators";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-multicountry";
const DEFAULTS = {
  nolabel: false
};
export class MultiCountryChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    countries: host.countries,
    nolabel: host.nolabel !== void 0 ? host.nolabel : DEFAULTS.nolabel
  };
  const getCountryAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminMultiCountryAnswer({ CountryCodes: currentValues, QuestionId: props.questionId });
    }
    return new WebMultiCountryAnswer({ CountryCodes: currentValues, QuestionId: props.questionId });
  };
  const getLabel = () => {
    return props.nolabel ? void 0 : question.Label + "*";
  };
  const [currentValues, setCurrentValues] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new MultiCountryValidator());
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new MultiCountryChangeEvent({
          answer: getCountryAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    if (props.q11e && props.questionId) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof WebMultiCountryAnswer || answer instanceof AdminMultiCountryAnswer) {
        setCurrentValues(answer.CountryCodes);
      }
    }
  }, [props.q11e, props.questionId]);
  useEffect(() => {
    validationContext.validate(getCountryAnswer());
  }, [currentValues]);
  const templateSelect = () => {
    return html`<dc-select
			.readonly=${props.q11e.isReadonly()}
			.label=${getLabel()}
			.tooltip=${question.Tooltip !== void 0 ? question.Tooltip : ""}
			.selectedValues=${currentValues}
			.validationMessage=${validationContext.getValidationMessage("countryCodes")}
			.dataSource=${props.countries.map((country) => ({
      label: country.Name,
      value: country.Code
    }))}
			.filterable=${true}
			.multiSelect=${true}
			@change=${(e) => {
      setCurrentValues(e.detail.selectedValues);
      setDispatchIfValid(true);
      validationContext.validateField("countryCodes");
    }}
		></dc-select>`;
  };
  return html` ${props.q11e !== void 0 && question !== void 0 ? html`${templateSelect()}` : ""} `;
};
