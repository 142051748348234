var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { SubmissionPeriods as SubmissionClient } from "AdminServices/APIs/self/clients/SubmissionPeriods";
import { DateRange } from "AdminServices/APIs/self/models/V1/Common/DateTime/DateRange";
import { Due, Paid, PaidByClient } from "AdminServices/APIs/self/models/V1/Submission/PaymentStatusChanges";
import { Field, Filter, Pager, SearchRequest } from "AdminServices/APIs/self/models/V1/Submission/Search";
import { FieldOrder } from "AdminServices/APIs/self/models/V1/Submission/Search/Field";
import { RevisionChangeRequest } from "AdminServices/APIs/self/models/V1/Submission/RevisionChangeRequest";
import {
  ApprovedByClient,
  DataUploaded,
  ErrorAtTA,
  ErrorReturnGen,
  IntrastatReturnInformation,
  New,
  NotToBeSubmitted,
  PrimaryVatReturnInformation,
  RefusedByClient,
  Rejected,
  ReturnGenerated,
  SentToTA,
  Submitted,
  SubmittedByClient
} from "AdminServices/APIs/self/models/V1/Submission/StatusChanges";
import { useReturnLibrary } from "GlobalShared/components/return-library/return-library";
import {
  ReturnLibraryFieldNames,
  mapSearchResultItemToVM,
  mapSubmissionPeriodFilesToVM,
  mapSubmissionPeriodFileListToVM
} from "GlobalShared/components/return-library/SubmissionModels";
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { notifyError, notifyErrorWithWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import { useCurrencies, usePermissionManager, useServiceCountries } from "AdminComponents/hooks/AdminCustomHooks";
import { currentLocale } from "../../globals";
import { Money } from "AdminServices/APIs/self/models/V1/Common";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
export function OfficeReturnLibrary() {
  const getTargetSubmissionStatus = (_from, to, isRevision, returnGeneratedModel) => {
    if (to === "New") {
      return new New({ IsRevision: isRevision });
    } else if (to === "DataUploaded") {
      return new DataUploaded();
    } else if (to === "ErrorReturnGen") {
      return new ErrorReturnGen();
    } else if (to === "ReturnGenerated") {
      if ((returnGeneratedModel == null ? void 0 : returnGeneratedModel.isIntrastatInApplicable) || (returnGeneratedModel == null ? void 0 : returnGeneratedModel.isIntrastatOutApplicable)) {
        return new ReturnGenerated({
          ReturnInformation: new IntrastatReturnInformation({
            IntraInVatAmount: returnGeneratedModel.isIntrastatInApplicable ? new Money({
              Currency: returnGeneratedModel.intrastatInCurrency,
              Value: returnGeneratedModel.intrastatInValue
            }) : void 0,
            IntraOutVatAmount: returnGeneratedModel.isIntrastatOutApplicable ? new Money({
              Currency: returnGeneratedModel.intrastatOutCurrency,
              Value: returnGeneratedModel.intrastatOutValue
            }) : void 0
          })
        });
      } else if (returnGeneratedModel == null ? void 0 : returnGeneratedModel.isVATApplicable) {
        return new ReturnGenerated({
          ReturnInformation: new PrimaryVatReturnInformation({
            VatAmount: new Money({
              Currency: returnGeneratedModel.vatCurrency,
              Value: returnGeneratedModel.vatValue
            }),
            PaymentCategory: returnGeneratedModel.vatPayment
          })
        });
      } else {
        return new ReturnGenerated();
      }
    } else if (to === "ApprovedByClient") {
      return new ApprovedByClient();
    } else if (to === "RefusedByClient") {
      return new RefusedByClient();
    } else if (to === "ErrorAtTA") {
      return new ErrorAtTA();
    } else if (to === "SentToTA") {
      return new SentToTA();
    } else if (to === "Submitted") {
      return new Submitted();
    } else if (to === "Rejected") {
      return new Rejected();
    } else if (to === "SubmittedByClient") {
      return new SubmittedByClient();
    } else if (to === "NotToBeSubmitted") {
      return new NotToBeSubmitted();
    }
    return void 0;
  };
  const getTargetPaymentStatus = (to) => {
    if (to === "Due") {
      return new Due();
    } else if (to === "Paid") {
      return new Paid();
    } else if (to === "PaidByClient") {
      return new PaidByClient();
    }
    return void 0;
  };
  const uploadSubmissionProof = (submissionPeriodId, file) => __async(this, null, function* () {
    yield submissionClient.uploadSubmissionProof({
      submissionPeriodId,
      file
    });
  });
  const uploadSubmissionProof2 = (submissionPeriodId, file) => __async(this, null, function* () {
    yield submissionClient.uploadSubmissionProof2({
      submissionPeriodId,
      file
    });
  });
  const uploadPaymentProof = (submissionPeriodId, file) => __async(this, null, function* () {
    yield submissionClient.uploadPaymentProof({
      submissionPeriodId,
      file
    });
  });
  const uploadNotToBeSubmittedProof = (submissionPeriodId, file) => __async(this, null, function* () {
    yield submissionClient.uploadNotToBeSubmittedProof({
      submissionPeriodId,
      file
    });
  });
  const uploadReturnDocuments = (submissionPeriodId, files) => __async(this, null, function* () {
    yield submissionClient.uploadReturnDocuments({
      submissionPeriodId,
      files
    });
  });
  const uploadRejectionAttachments = (_submissionPeriodId, _files) => __async(this, null, function* () {
    return [];
  });
  const downloadDocument = (submissionPeriodId, documentType, fileReference) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield submissionClient.downloadDocument({
          type: documentType,
          submissionPeriodId,
          documentId: fileReference
        });
        simulateDownload(response.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const changeStatus = (submissionPeriodId, oldStatus, newStatus, _comment, _attachmentIds, isRevision, returnGeneratedModel) => __async(this, null, function* () {
    const targetStatus = getTargetSubmissionStatus(oldStatus, newStatus, isRevision, returnGeneratedModel);
    yield submissionClient.changeStatus({
      submissionPeriodId,
      body: targetStatus
    });
  });
  const changeRevision = (submissionPeriodId, isRevision) => __async(this, null, function* () {
    yield submissionClient.changeRevision({
      submissionPeriodId,
      body: new RevisionChangeRequest({
        IsRevision: isRevision
      })
    });
  });
  const changePaymentStatus = (submissionPeriodId, newStatus) => __async(this, null, function* () {
    const targetStatus = getTargetPaymentStatus(newStatus);
    yield submissionClient.changePaymentStatus({
      submissionPeriodId,
      body: targetStatus
    });
  });
  const getFilter = (searchRequest, onlyAssignedToMe, assigneeFragment) => {
    const clientFilter = new Filter();
    if (searchRequest.paymentProofFilter !== "All") {
      clientFilter.PaymentProofUploaded = searchRequest.paymentProofFilter === "Exists";
    }
    if (onlyAssignedToMe) {
      clientFilter.IsAssignedToMe = true;
    }
    if (!isEmpty(assigneeFragment)) {
      clientFilter.AgentEmailPart = assigneeFragment;
    }
    if (searchRequest.gridState.appliedFilters.length > 0) {
      searchRequest.gridState.appliedFilters.forEach((filter) => {
        if (filter.field === ReturnLibraryFieldNames.Status) {
          if (filter.filterProps.selectedValues) {
            clientFilter.Statuses = filter.filterProps.selectedValues;
          }
        } else if (filter.field === ReturnLibraryFieldNames.CompanyName) {
          if (filter.filterProps.selectedValues) {
            clientFilter.CompanyName = filter.filterProps.selectedValues;
          }
        } else if (filter.field === ReturnLibraryFieldNames.CompanyId) {
          if (filter.filterProps.selectedValues) {
            clientFilter.CompanyIdPart = filter.filterProps.selectedValues;
          }
        } else if (filter.field === ReturnLibraryFieldNames.TaxReturnType) {
          if (filter.filterProps.selectedValues) {
            clientFilter.TaxReturnTypes = filter.filterProps.selectedValues;
          }
        } else if (filter.field === ReturnLibraryFieldNames.ServiceCountry) {
          clientFilter.ServiceCountryCode = filter.filterProps.selectedValues;
        } else if (filter.field === ReturnLibraryFieldNames.EstablishmentCountryType) {
          clientFilter.EstablishmentCountryType = filter.filterProps.selectedValues;
        } else if (filter.field === ReturnLibraryFieldNames.PeriodStartDate) {
          if (filter.filterProps.selectedValues) {
            clientFilter.Periods = filter.filterProps.selectedValues;
          }
        } else if (filter.field === ReturnLibraryFieldNames.ClientApprovalDueDate) {
          if (filter.filterProps.min || filter.filterProps.max) {
            clientFilter.ClientApprovalDueDate = new DateRange({
              From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
              To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
            });
          }
        } else if (filter.field === ReturnLibraryFieldNames.SubmissionDueDate) {
          if (filter.filterProps.min || filter.filterProps.max) {
            clientFilter.SubmissionDueDate = new DateRange({
              From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
              To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
            });
          }
        } else if (filter.field === ReturnLibraryFieldNames.ReturnGenerationDueDate) {
          if (filter.filterProps.min || filter.filterProps.max) {
            clientFilter.ReturnGenerationDueDate = new DateRange({
              From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
              To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
            });
          }
        } else if (filter.field === ReturnLibraryFieldNames.PaymentDueDate) {
          if (filter.filterProps.min || filter.filterProps.max) {
            clientFilter.PaymentDueDate = new DateRange({
              From: filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0,
              To: filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0
            });
          }
        }
      });
    }
    return clientFilter;
  };
  const mapAdminSearchResultItemToVM = (searchResultItem) => {
    const vm = __spreadProps(__spreadValues({}, mapSearchResultItemToVM(searchResultItem)), {
      Errors: searchResultItem.Errors
    });
    return vm;
  };
  const searchSubmissions = (searchRequest, onlyAssignedToMe, assigneeFragment) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield submissionClient.searchSubmissions({
          body: new SearchRequest({
            Filter: getFilter(searchRequest, onlyAssignedToMe, assigneeFragment),
            Pager: new Pager({
              Skip: searchRequest.gridState.pageIndex * searchRequest.gridState.pageSize,
              Top: searchRequest.gridState.pageSize,
              OrderBy: [
                new Field({
                  Order: searchRequest.gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: searchRequest.gridState.orderBy
                })
              ]
            })
          })
        });
        const vm = {
          submissions: response.data.SubmissionPeriods.map(mapAdminSearchResultItemToVM),
          total: response.data.Count
        };
        return vm;
      }),
      (error) => {
        throw error;
      }
    );
  });
  const exportSubmissions = (searchRequest) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield submissionClient.exportSubmissions({
          body: new SearchRequest({
            Filter: getFilter(searchRequest, false, void 0),
            Pager: new Pager({
              Skip: searchRequest.gridState.pageIndex * searchRequest.gridState.pageSize,
              Top: searchRequest.gridState.pageSize,
              OrderBy: [
                new Field({
                  Order: searchRequest.gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: searchRequest.gridState.orderBy
                })
              ]
            })
          })
        });
        simulateDownload(response.data);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const getSubmissionPeriodFiles = (submissionPeriodId) => __async(this, null, function* () {
    const response = yield submissionClient.getSubmissionPeriodFiles({
      submissionPeriodId
    });
    const vm = __spreadProps(__spreadValues({}, mapSubmissionPeriodFilesToVM(response.data)), {
      RejectReasons: response.data.RejectReasons.map(mapRejectReasonToVM)
    });
    return vm;
  });
  const mapRejectReasonToVM = (rejectReason) => {
    const vm = {
      Comment: rejectReason.Comment,
      RejectedAt: rejectReason.RejectedAt,
      ReturnFiles: mapSubmissionPeriodFileListToVM(rejectReason.Attachments)
    };
    return vm;
  };
  const availableCurrencies = useCurrencies();
  const submissionClient = useClient(SubmissionClient);
  const serviceCountries = useServiceCountries(currentLocale);
  const permissionManager = usePermissionManager();
  const returnLibraryHook = useReturnLibrary({
    isAdmin: true,
    permissionManager,
    serviceCountries,
    availableCurrencies,
    searchSubmissions,
    exportSubmissions,
    getSubmissionPeriodFiles,
    uploadSubmissionProof,
    uploadSubmissionProof2,
    uploadPaymentProof,
    uploadReturnDocuments,
    downloadDocument,
    uploadNotToBeSubmittedProof,
    changeStatus,
    changeRevision,
    changePaymentStatus,
    uploadRejectionAttachments
  });
  return returnLibraryHook.mainTemplate;
}
