import { MappingExpressionBase } from "./MappingExpressionBase";
export class DateExpression extends MappingExpressionBase {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof DateExpression
     */
    this.$type = "V1.TemplateMapping.DateExpression";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.FieldPath = _data["FieldPath"];
      this.Format = _data["Format"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["FieldPath"] = this.FieldPath;
    _data["Format"] = this.Format;
    return _data;
  }
}
