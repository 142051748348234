import { DateConverter } from "./../../../../converters/DateConverter";
import { IossRegistrationStatusChange } from "./IossRegistrationStatusChange";
export class DeregisteredStatusChange extends IossRegistrationStatusChange {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof DeregisteredStatusChange
     */
    this.$type = "V1.Company.IossRegistration.DeregisteredStatusChange";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.DateOfDeregistration = DateConverter.from(_data["DateOfDeregistration"]);
      this.DeregistrationConfirmationDocumentId = _data["DeregistrationConfirmationDocumentId"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["DateOfDeregistration"] = DateConverter.toUtcDateString(this.DateOfDeregistration);
    _data["DeregistrationConfirmationDocumentId"] = this.DeregistrationConfirmationDocumentId;
    return _data;
  }
}
