export class FrequencyConfigurationOption {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof FrequencyConfigurationOption
     */
    this.$type = "V1.Submission.FrequencyConfigurationOption";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CountryCode = _data["CountryCode"];
      this.EFiling = _data["EFiling"];
      this.FilingExtension = _data["FilingExtension"];
      this.FrequencyType = FrequencyConfigurationOptionFrequencyType[_data["FrequencyType"]];
      this.ReturnType = FrequencyConfigurationOptionReturnType[_data["ReturnType"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CountryCode"] = this.CountryCode;
    _data["EFiling"] = this.EFiling;
    _data["FilingExtension"] = this.FilingExtension;
    _data["FrequencyType"] = FrequencyConfigurationOptionFrequencyType[this.FrequencyType];
    _data["ReturnType"] = FrequencyConfigurationOptionReturnType[this.ReturnType];
    return _data;
  }
}
export var FrequencyConfigurationOptionFrequencyType = /* @__PURE__ */ ((FrequencyConfigurationOptionFrequencyType2) => {
  FrequencyConfigurationOptionFrequencyType2["Monthly"] = "Monthly";
  FrequencyConfigurationOptionFrequencyType2["Quarterly"] = "Quarterly";
  FrequencyConfigurationOptionFrequencyType2["QuarterlyShifted1"] = "QuarterlyShifted1";
  FrequencyConfigurationOptionFrequencyType2["QuarterlyShifted2"] = "QuarterlyShifted2";
  FrequencyConfigurationOptionFrequencyType2["BiMonthly"] = "BiMonthly";
  FrequencyConfigurationOptionFrequencyType2["FourMonthly"] = "FourMonthly";
  FrequencyConfigurationOptionFrequencyType2["SixMonthly"] = "SixMonthly";
  FrequencyConfigurationOptionFrequencyType2["Yearly"] = "Yearly";
  return FrequencyConfigurationOptionFrequencyType2;
})(FrequencyConfigurationOptionFrequencyType || {});
export var FrequencyConfigurationOptionReturnType = /* @__PURE__ */ ((FrequencyConfigurationOptionReturnType2) => {
  FrequencyConfigurationOptionReturnType2["PrimaryVAT"] = "PrimaryVAT";
  FrequencyConfigurationOptionReturnType2["SecondaryVAT"] = "SecondaryVAT";
  FrequencyConfigurationOptionReturnType2["ESL"] = "ESL";
  FrequencyConfigurationOptionReturnType2["VLS"] = "VLS";
  FrequencyConfigurationOptionReturnType2["IOSS"] = "IOSS";
  FrequencyConfigurationOptionReturnType2["Intrastat"] = "Intrastat";
  FrequencyConfigurationOptionReturnType2["OSS"] = "OSS";
  return FrequencyConfigurationOptionReturnType2;
})(FrequencyConfigurationOptionReturnType || {});
