var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { MAX_DATE, MIN_DATE, formatDate } from "GlobalShared/helpers/dateHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { getWebLanguageByLocale } from "GlobalShared/helpers/webLocaleHelper";
import {
  getClientAcceptanceStatusLabel,
  ClientAcceptanceLabels
} from "GlobalShared/models/ServicesModels";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { editIcon } from "GlobalShared/templates/commons";
import { useBusinessDetailsEditor } from "../company-details/company-details";
import { useIossService } from "./ioss-service";
import { useVatService } from "./vat-service";
import { LEGAL_STATUS } from "../orders/business-details/BusinessDetailsData";
import { useVatNumbersModal } from "./vat-numbers-modal";
import { checkError, notifyError, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { useConfirmModal } from "../modals/commonModals";
export const useServices = (props) => {
  var _a, _b;
  const hasGlobalService = (globalServices) => {
    return globalServices.EuEori !== void 0 || globalServices.UkEori !== void 0 || globalServices.IossService !== void 0;
  };
  const getContactName = (companyDetail) => {
    return `${companyDetail.contactTitle} ${companyDetail.contactFirstName} ${companyDetail.contactLastName}`;
  };
  const flipSubscriptionStatus = (vatRegistrationId, eventType, subscribe) => {
    setCompanyServicesModel(__spreadProps(__spreadValues({}, companyServicesModel), {
      VatRegistrations: companyServicesModel.VatRegistrations.map((vr) => {
        if (vr.Id === vatRegistrationId) {
          return __spreadProps(__spreadValues({}, vr), {
            RegNotificationSubscriptions: vr.RegNotificationSubscriptions.map((rns) => {
              return rns.eventType === eventType ? __spreadProps(__spreadValues({}, rns), { isSubscribed: subscribe }) : rns;
            })
          });
        } else {
          return vr;
        }
      })
    }));
  };
  const isClientAcceptanceValid = () => {
    if (currentClientAcceptance !== void 0 && currentClientAcceptance !== "Completed")
      return true;
    return currentClientAcceptanceCompleted !== void 0 && currentClientAcceptanceCompleted > MIN_DATE && currentClientAcceptanceCompleted <= MAX_DATE;
  };
  const updateRegNotificationSubscription = (companyId, vatRegistrationId, eventType, userId, subscribe) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        if (subscribe) {
          yield props.callbackHandler.subscribeRegNotification(companyId, vatRegistrationId, eventType, userId);
        } else {
          yield props.callbackHandler.unsubscribeRegNotification(companyId, vatRegistrationId, eventType, userId);
        }
        if (!props.isAdmin) {
          flipSubscriptionStatus(vatRegistrationId, eventType, subscribe);
        }
        notifySuccess(i18next.t("Saved successfully"));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const updateSentinelInformation = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.callbackHandler.updateSentinelInformation(currentSentinelNumber, currentSentinelValidTo);
        setCompanyServicesModel(__spreadProps(__spreadValues({}, companyServicesModel), {
          SentinelNumber: currentSentinelNumber,
          SentinelValidTo: currentSentinelValidTo
        }));
        notifySuccess("Sentinel Information Updated!");
        setShowSentinelModal(false);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const updateClientAcceptance = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.callbackHandler.updateClientAcceptance(currentClientAcceptance, currentClientAcceptanceCompleted);
        notifySuccess("Client acceptance Updated!");
        setShowClientAcceptanceModal(false);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const updateGisNumber = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.callbackHandler.updateGisNumber(currentGisIdentifier);
        setCompanyServicesModel(__spreadProps(__spreadValues({}, companyServicesModel), {
          GisIdentifier: currentGisIdentifier
        }));
        notifySuccess("GIS Number Updated!");
        setShowGisModal(false);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const updateEmsNumber = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.callbackHandler.updateEmsNumber(currentEmsIdentifier);
        setCompanyServicesModel(__spreadProps(__spreadValues({}, companyServicesModel), {
          EmsIdentifier: currentEmsIdentifier
        }));
        notifySuccess("EMS Number Updated!");
        setShowEmsModal(false);
      }),
      (error) => {
        if (checkError(error, 409, "EMSNumberDuplicated")) {
          notifyWarning("EMS number already exists");
        } else {
          notifyError(error);
        }
      }
    );
  });
  const generateLines = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.callbackHandler.generatePeriods(props.companyServicesModel.Id);
        notifySuccess("Lines generated successfully");
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const [companyServicesModel, setCompanyServicesModel] = useState(void 0);
  const [currentEmsIdentifier, setCurrentEmsIdentifier] = useState("");
  const [currentGisIdentifier, setCurrentGisIdentifier] = useState("");
  const [currentSentinelNumber, setCurrentSentinelNumber] = useState("");
  const [currentSentinelValidTo, setCurrentSentinelValidTo] = useState(void 0);
  const [currentClientAcceptance, setCurrentClientAcceptance] = useState("Pending");
  const [currentClientAcceptanceCompleted, setCurrentClientAcceptanceCompleted] = useState(void 0);
  const [showEmsModal, setShowEmsModal] = useState(false);
  const [showGisModal, setShowGisModal] = useState(false);
  const [showCancelledServices, setShowCancelledServices] = useState(false);
  const [showBusinessDetailsModal, setShowBusinessDetailsModal] = useState(false);
  const [showSentinelModal, setShowSentinelModal] = useState(false);
  const [showClientAcceptanceModal, setShowClientAcceptanceModal] = useState(false);
  const companyDetailsHook = useBusinessDetailsEditor({
    hasIossService: ((_a = props.companyServicesModel) == null ? void 0 : _a.GlobalServices.IossService) !== void 0,
    companyDetails: (_b = props.companyServicesModel) == null ? void 0 : _b.CompanyDetails,
    worldCountries: props.worldCountries,
    handlers: {
      close: () => setShowBusinessDetailsModal(false),
      save: (companyDetails) => __async(void 0, null, function* () {
        yield props.callbackHandler.saveCompanyDetails(companyDetails);
        setShowBusinessDetailsModal(false);
      })
    }
  });
  const confirmModal = useConfirmModal();
  const iossServiceHook = useIossService({
    isAdmin: props.isAdmin,
    permissionManager: props.permissionManager,
    showCancelledServices,
    callbackHandler: props.callbackHandler,
    globalServices: companyServicesModel == null ? void 0 : companyServicesModel.GlobalServices
  });
  const vatServiceHook = useVatService({
    isAdmin: props.isAdmin,
    permissionManager: props.permissionManager,
    showCancelledServices,
    callbackHandler: props.callbackHandler,
    predefinedBankAccounts: props.predefinedBankAccounts,
    submissionConfig: props.submissionConfig,
    euCountries: props.euCountries,
    companyServices: companyServicesModel,
    updateRegNotificationSubscription
  });
  const vatNumbersModal = useVatNumbersModal({
    isAdmin: props.isAdmin,
    permissionManager: props.permissionManager,
    euCountries: props.euCountries,
    companyDetail: props.companyDetail,
    saveAnswer: props.callbackHandler.saveAnswer,
    getQuestionnaire: props.callbackHandler.getQuestionnaire
  });
  useEffect(() => {
    if (props.companyServicesModel) {
      setCompanyServicesModel(props.companyServicesModel);
    }
  }, [props.companyServicesModel]);
  const htmlBusinessDetailsModal = () => {
    if (showBusinessDetailsModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showBusinessDetailsModal}
						@close=${() => setShowBusinessDetailsModal(false)}
						.header=${"Edit business details"}
						.content=${html`<div>${companyDetailsHook.template()}</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlEmsModal = () => {
    if (showEmsModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showEmsModal}
						@close=${() => setShowEmsModal(false)}
						.header=${"Update EMS ID"}
						.content=${html`<div>
							<dc-input
								class="w-56"
								.label=${"EMS ID"}
								.value=${companyServicesModel == null ? void 0 : companyServicesModel.EmsIdentifier}
								@change=${(e) => {
        setCurrentEmsIdentifier(e.detail.value);
      }}
							></dc-input>
							<div class="mt-4 flex space-x-4">
								<button class="btn btn-primary" @click=${updateEmsNumber}>Save</button>
								<button class="btn btn-primary" @click=${() => setShowEmsModal(false)}>Cancel</button>
							</div>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlGisModal = () => {
    if (showGisModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showGisModal}
						@close=${() => setShowGisModal(false)}
						.header=${"Update GIS ID"}
						.content=${html`<div>
							<dc-input
								class="w-56"
								.label=${"GIS ID"}
								.value=${companyServicesModel == null ? void 0 : companyServicesModel.GisIdentifier}
								@change=${(e) => {
        setCurrentGisIdentifier(e.detail.value);
      }}
							></dc-input>
							<div class="mt-4 flex space-x-4">
								<button class="btn btn-primary" @click=${() => setShowGisModal(false)}>Cancel</button>
								<button class="btn btn-primary" @click=${updateGisNumber}>Save</button>
							</div>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlClientAcceptanceModal = () => {
    if (showClientAcceptanceModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showClientAcceptanceModal}
						@close=${() => setShowClientAcceptanceModal(false)}
						.header=${"Update Client acceptance Information"}
						.content=${html`<div class="client-acceptance-modal">
							<div class="xl:w-1/2">
								<dc-dropdown
									.isStatusLike=${true}
									.buttonText=${getClientAcceptanceStatusLabel(currentClientAcceptance)}
									.selectedValues=${[currentClientAcceptance]}
									.dataSource=${ClientAcceptanceLabels}
									@click=${(e) => {
        const newStatus = e.detail.selectedValue;
        setCurrentClientAcceptance(newStatus);
        if (newStatus !== "Completed") {
          setCurrentClientAcceptanceCompleted(void 0);
        }
      }}
								></dc-dropdown>
							</div>
							${currentClientAcceptance === "Completed" ? html`
										<dc-datepicker
											class="w-56 mt-4"
											.label=${"Completed"}
											.min=${MIN_DATE}
											.max=${MAX_DATE}
											.value=${currentClientAcceptanceCompleted}
											.validationMessage=${!isClientAcceptanceValid() ? "This field is required" : void 0}
											@change=${(e) => {
        setCurrentClientAcceptanceCompleted(e.detail.value);
      }}
										></dc-datepicker>
								  ` : html``}
							<div class="mt-4 flex space-x-4">
								<button class="btn btn-primary" @click=${() => setShowClientAcceptanceModal(false)}>Cancel</button>
								<button
									class="btn btn-primary"
									@click=${() => __async(void 0, null, function* () {
        if (!isClientAcceptanceValid()) {
          return;
        }
        yield updateClientAcceptance();
      })}
								>
									Save
								</button>
							</div>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlSentinelModal = () => {
    if (showSentinelModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showSentinelModal}
						@close=${() => setShowSentinelModal(false)}
						.header=${"Update Sentinel Information"}
						.content=${html`<div>
							<dc-input
								class="w-56"
								.label=${"Number"}
								.value=${companyServicesModel == null ? void 0 : companyServicesModel.SentinelNumber}
								@change=${(e) => {
        setCurrentSentinelNumber(e.detail.value);
      }}
							></dc-input>
							<dc-datepicker
								class="w-56 mt-4"
								.label=${"Valid Until"}
								.value=${companyServicesModel == null ? void 0 : companyServicesModel.SentinelValidTo}
								.min=${/* @__PURE__ */ new Date()}
								@change=${(e) => {
        setCurrentSentinelValidTo(e.detail.value);
      }}
							></dc-datepicker>
							<div class="mt-4 flex space-x-4">
								<button class="btn btn-primary" @click=${() => setShowSentinelModal(false)}>Cancel</button>
								<button class="btn btn-primary" @click=${updateSentinelInformation}>Save</button>
							</div>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlGlobalServices = (globalServices) => {
    return html`
			${globalServices.UkEori && globalServices.UkEori.ValidFrom ? html` <div class="mt-8 shadow border mx-8 px-8 py-4 xl:mx-auto xl:w-1/2 rounded">
						<div class="font-bold text-lg mt-1 mb-3">${i18next.t("EORI number application in the UK")}</div>
						<div class="text-sm">${i18next.t("Service start date")}: ${formatDate(globalServices.UkEori.ValidFrom)}</div>
				  </div>` : ""}
			${globalServices.EuEori && globalServices.EuEori.ValidFrom ? html` <div class="mt-8 shadow border mx-8 px-8 py-4 xl:mx-auto xl:w-1/2 rounded">
						<div class="font-bold text-lg mt-1 mb-3">${i18next.t("EORI number application in the EU")}</div>
						<div class="text-sm">${i18next.t("Service start date")}: ${formatDate(globalServices.EuEori.ValidFrom)}</div>
				  </div>` : ""}
			${iossServiceHook.mainTemplate}
		`;
  };
  const htmlBusinessMainDetails = (servicesModel) => {
    var _a2, _b2;
    return html`<div class="mt-8 bg-gray-200 w-full py-6 px-8 xl:px-0">
			<div class="xl:mx-auto xl:w-1/2 px-8">
				<div class="flex justify-between mb-3">
					<div class="flex">
						${props.isAdmin && props.permissionManager.hasPermission("Company_Details_Modify") ? html`<span
									class="edit-icon-container mr-4"
									@click=${() => {
      setShowBusinessDetailsModal(true);
    }}
							  >
									${editIcon()}
							  </span>` : ""}
						<span class="bg-gray-300 border rounded-full mb-1 mr-3 py-2 px-4 text-xs uppercase font-semibold">
							${LEGAL_STATUS.find((ls) => ls.value === servicesModel.CompanyDetails.legalStatus).label}</span
						><span class="text-sm">
							${servicesModel.EstablishmentCountryName}, ${servicesModel.CompanyDetails.addressZip}, ${servicesModel.CompanyDetails.addressProvince}
							${servicesModel.CompanyDetails.addressCity}, ${servicesModel.CompanyDetails.addressStreet} ${servicesModel.CompanyDetails.addressStreetNo}
						</span>
					</div>
					<div class="text-xs uppercase font-semibold">${i18next.t("Id")}: ${servicesModel.Id}</div>
				</div>
				<div class="flex justify-between font-bold text-lg mb-3">
					<div>${servicesModel.FullName}</div>
					<div>${servicesModel.CompanyDetails.countryOfEstablishment}${servicesModel.CompanyDetails.vatNumber}</div>
				</div>
				<div class="flex justify-between text-sm">
					<div class="flex flex-start">${servicesModel.CompanyDetails.phone} | ${getContactName(servicesModel.CompanyDetails)}</div>
					<div>
						${getWebLanguageByLocale(servicesModel.CompanyDetails.preferredLanguage) !== void 0 ? html`
									<span>${(_a2 = getWebLanguageByLocale(servicesModel.CompanyDetails.preferredLanguage)) == null ? void 0 : _a2.lang.toUpperCase()} </span
									><img height="20" width="20" src="/images/${(_b2 = getWebLanguageByLocale(servicesModel.CompanyDetails.preferredLanguage)) == null ? void 0 : _b2.lang.toLocaleLowerCase()}_flag.svg" />
							  ` : ""}
					</div>
				</div>
				<div class="flex justify-start text-sm">
					<div class=${`flex justify-center text-sm ${props.isAdmin ? "" : "hidden"}`}>
						<div class="flex items-center mr-4">
							${props.permissionManager.hasPermission("Company_Details_Modify") ? html`<span
										class="edit-icon-container"
										@click=${() => {
      setCurrentGisIdentifier(companyServicesModel.GisIdentifier);
      setShowGisModal(true);
    }}
								  >
										${editIcon()}
								  </span>` : ""}

							<div class="font-bold ml-2">${"GIS ID:"} ${servicesModel.GisIdentifier ? servicesModel.GisIdentifier : ""}</div>
						</div>
						<div class="flex items-center mr-4">
							${servicesModel.IsEmsNumberEditable === true && props.permissionManager.hasPermission("Company_Details_Modify") ? html`
										<span
											class="edit-icon-container"
											@click=${() => {
      setCurrentEmsIdentifier(companyServicesModel.EmsIdentifier);
      setShowEmsModal(true);
    }}
										>
											${editIcon()}
										</span>
								  ` : ""}
							<div class="font-bold ml-2">${"EMS ID:"} ${servicesModel.EmsIdentifier ? servicesModel.EmsIdentifier : ""}</div>
						</div>
						<div class="flex items-center mr-4">
							${props.permissionManager.hasPermission("Company_Details_Modify") ? html`<span
										class="edit-icon-container"
										@click=${() => {
      setCurrentSentinelNumber(companyServicesModel.SentinelNumber);
      setCurrentSentinelValidTo(companyServicesModel.SentinelValidTo);
      setShowSentinelModal(true);
    }}
								  >
										${editIcon()}
								  </span>` : ""}
							<div class="font-bold ml-2">
								${"Sentinel:"}
								${servicesModel.SentinelNumber ? html`${servicesModel.SentinelNumber} ${servicesModel.SentinelValidTo ? html`(${formatDate(servicesModel.SentinelValidTo)})` : ""}` : ""}
							</div>
						</div>
						<div class="flex items-center mr-4">
							${props.permissionManager.hasPermission("Company_Details_Modify") ? html`<span
										class="edit-icon-container"
										@click=${() => {
      setCurrentClientAcceptance(companyServicesModel.ClientAcceptance);
      setCurrentClientAcceptanceCompleted(companyServicesModel.ClientAcceptanceCompleted);
      setShowClientAcceptanceModal(true);
    }}
								  >
										${editIcon()}
								  </span>` : ""}

							<div class="font-bold ml-2">
								${"Client acceptance:"} ${servicesModel.ClientAcceptance}
								${servicesModel.ClientAcceptanceCompleted ? html`(${formatDate(servicesModel.ClientAcceptanceCompleted)})` : ""}
							</div>
						</div>
					</div>
				</div>
				<div class="flex justify-between text-sm mt-4">
					${props.isAdmin ? html`<dc-checkbox
								.checked=${servicesModel.IsInvoicingEnabled}
								.label=${"Client invoicing"}
								.readonly=${!props.permissionManager.hasPermission("Company_Details_Modify")}
								@change=${(e) => {
      (() => __async(void 0, null, function* () {
        if (yield confirmModal.confirm(i18next.t("Are you sure you want to change client invoicing?"))) {
          props.callbackHandler.updateInvoicing(e.detail.checked);
        }
      }))();
      e.preventDefault();
    }}
						  ></dc-checkbox>` : html`<div></div>`}
					<div class="flex space-x-4">
						${props.isAdmin && props.permissionManager.hasPermission("Compliance_Upload_Generate") && props.permissionManager.hasPermission("Submission_Period_Generate") ? html`<div>
									<button
										class="btn btn-sm btn-primary whitespace-no-wrap"
										@click=${() => __async(void 0, null, function* () {
      if (yield confirmModal.confirm("Are you sure you would like to generate the Data Upload and Return Library lines for the Client?")) {
        generateLines();
      }
    })}
									>
										Generate Lines
									</button>
							  </div>` : ""}
						<div>
							<button class="btn btn-sm btn-primary whitespace-no-wrap" @click=${() => vatNumbersModal.show()}>${i18next.t("VAT Numbers")}</button>
						</div>
					</div>
				</div>
			</div>
		</div>`;
  };
  const mainTemplate = () => {
    return companyServicesModel ? html`${htmlBusinessMainDetails(companyServicesModel)}
					<div class="flex justify-between xl:mx-auto xl:w-1/2 mt-2">
						<div></div>
						<div>
							<dc-checkbox2
								.checked=${showCancelledServices}
								.label=${i18next.t("Show inactive services")}
								@change=${(e) => {
      setShowCancelledServices(e.detail.checked);
    }}
							></dc-checkbox2>
						</div>
					</div>
					${companyServicesModel && hasGlobalService(companyServicesModel.GlobalServices) ? htmlGlobalServices(companyServicesModel.GlobalServices) : ""} ${iossServiceHook.htmlModals}
					${vatServiceHook.mainTemplate} ${vatServiceHook.htmlModals} ${htmlEmsModal()} ${htmlGisModal()} ${htmlSentinelModal()} ${htmlClientAcceptanceModal()} ${htmlBusinessDetailsModal()}
					${vatNumbersModal.template()} ${confirmModal.mainTemplate()}` : html`${i18next.t("Loading")}...`;
  };
  return {
    mainTemplate
  };
};
