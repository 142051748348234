import { DateConverter } from "./../../../../converters/DateConverter";
export class FrequencyConfig {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof FrequencyConfig
     */
    this.$type = "V1.Company.CompanyInfo.FrequencyConfig";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.EFiling = _data["EFiling"];
      this.FilingExtension = _data["FilingExtension"];
      this.FrequencyType = FrequencyConfigFrequencyType[_data["FrequencyType"]];
      this.Id = _data["Id"];
      this.ValidFrom = DateConverter.from(_data["ValidFrom"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["EFiling"] = this.EFiling;
    _data["FilingExtension"] = this.FilingExtension;
    _data["FrequencyType"] = FrequencyConfigFrequencyType[this.FrequencyType];
    _data["Id"] = this.Id;
    _data["ValidFrom"] = DateConverter.toUtcDateString(this.ValidFrom);
    return _data;
  }
}
export var FrequencyConfigFrequencyType = /* @__PURE__ */ ((FrequencyConfigFrequencyType2) => {
  FrequencyConfigFrequencyType2["Monthly"] = "Monthly";
  FrequencyConfigFrequencyType2["Quarterly"] = "Quarterly";
  FrequencyConfigFrequencyType2["QuarterlyShifted1"] = "QuarterlyShifted1";
  FrequencyConfigFrequencyType2["QuarterlyShifted2"] = "QuarterlyShifted2";
  FrequencyConfigFrequencyType2["BiMonthly"] = "BiMonthly";
  FrequencyConfigFrequencyType2["FourMonthly"] = "FourMonthly";
  FrequencyConfigFrequencyType2["SixMonthly"] = "SixMonthly";
  FrequencyConfigFrequencyType2["Yearly"] = "Yearly";
  return FrequencyConfigFrequencyType2;
})(FrequencyConfigFrequencyType || {});
