import languagesJson from "../../../../../Infrastructure/Services/WonderWoman.WebHost/languages.json";
export const webLanguages = Object.assign(
  {},
  ...languagesJson.Languages.Items.map((item) => {
    const key = item.key;
    delete item.key;
    return { [key]: item };
  })
);
export function getWebLanguageByCountry(country) {
  const langKeys = Object.keys(webLanguages).filter((key) => webLanguages[key].country === country);
  if (langKeys.length === 1) {
    return webLanguages[langKeys[0]];
  } else {
    return void 0;
  }
}
export function getWebLanguageByLocale(locale) {
  const langKeys = Object.keys(webLanguages).filter((key) => webLanguages[key].locale === locale);
  if (langKeys.length === 1) {
    return webLanguages[langKeys[0]];
  } else {
    return void 0;
  }
}
