import { default as moment } from "moment";
import { WebRegistrationRequest } from "AdminServices/APIs/self/models/V1/DataUpload/WebRegistrationRequest";
var CsvHeaders = /* @__PURE__ */ ((CsvHeaders2) => {
  CsvHeaders2[CsvHeaders2["MerchantId"] = 0] = "MerchantId";
  CsvHeaders2[CsvHeaders2["EstablishmentCountry"] = 1] = "EstablishmentCountry";
  CsvHeaders2[CsvHeaders2["SelectedCountries"] = 2] = "SelectedCountries";
  CsvHeaders2[CsvHeaders2["Provider"] = 3] = "Provider";
  CsvHeaders2[CsvHeaders2["CheckOutDate"] = 4] = "CheckOutDate";
  CsvHeaders2[CsvHeaders2["SignTermsDate"] = 5] = "SignTermsDate";
  CsvHeaders2[CsvHeaders2["FBAEnabled"] = 6] = "FBAEnabled";
  CsvHeaders2[CsvHeaders2["SailEnabledCountries"] = 7] = "SailEnabledCountries";
  CsvHeaders2[CsvHeaders2["VatCountries"] = 8] = "VatCountries";
  CsvHeaders2[CsvHeaders2["QualtricsRecordedDate"] = 9] = "QualtricsRecordedDate";
  CsvHeaders2[CsvHeaders2["Email"] = 10] = "Email";
  CsvHeaders2[CsvHeaders2["Phone"] = 11] = "Phone";
  CsvHeaders2[CsvHeaders2["WeChat"] = 12] = "WeChat";
  return CsvHeaders2;
})(CsvHeaders || {});
export function mapAmazonAcquisitionRowToDto(row) {
  return {
    MerchantId: row[0 /* MerchantId */].trim(),
    EstablishmentCountry: row[1 /* EstablishmentCountry */].trim(),
    SelectedCountries: row[2 /* SelectedCountries */] === "" ? [] : row[2 /* SelectedCountries */].split(",").map((c) => c.trim()),
    CheckOutDate: moment(row[4 /* CheckOutDate */], "dd/mm/yyyy"),
    SignTermsDate: moment(row[5 /* SignTermsDate */], "dd/mm/yyyy"),
    FBAEnabled: row[6 /* FBAEnabled */] === "N" ? false : true,
    SailEnabledCountries: row[7 /* SailEnabledCountries */] === "" ? [] : row[7 /* SailEnabledCountries */].split(",").map((c) => c.trim()),
    VatCountries: row[8 /* VatCountries */] === "" ? [] : row[8 /* VatCountries */].split(",").map((c) => c.trim()),
    QualtricsRecordedDate: moment(row[9 /* QualtricsRecordedDate */], "dd/mm/yyyy"),
    Email: row[10 /* Email */].trim(),
    Phone: row[11 /* Phone */].trim(),
    WeChat: row[12 /* WeChat */].trim()
  };
}
export function mapAmazonAcquisitionRowToWebRegistrationRequest(row, countries) {
  return new WebRegistrationRequest({
    MerchantId: row.MerchantId,
    EstablishmentCountry: countries.find((c) => c.Code === row.EstablishmentCountry),
    SelectedCountries: countries.filter((c) => row.SelectedCountries.includes(c.Code)),
    CheckOutDate: row.CheckOutDate.toDate(),
    SignTermsDate: row.SignTermsDate.toDate(),
    PanEuropeanFbaEnabled: row.FBAEnabled,
    SailEnabledCountries: row.SailEnabledCountries.length > 0 ? countries.filter((c) => row.SailEnabledCountries.includes(c.Code)) : void 0,
    VatCountries: row.VatCountries.length > 0 ? countries.filter((c) => row.VatCountries.includes(c.Code)) : void 0,
    QualtricsRecordedDate: row.QualtricsRecordedDate.toDate(),
    Email: row.Email,
    Phone: row.Phone,
    WeChat: row.WeChat
  });
}
