export class IossRegistrationStatusChange {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof IossRegistrationStatusChange
     */
    this.$type = "V1.Company.IossRegistration.IossRegistrationStatusChange";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.From = IossRegistrationStatusChangeFrom[_data["From"]];
      this.To = IossRegistrationStatusChangeTo[_data["To"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["$type"] = this.$type;
    _data["From"] = IossRegistrationStatusChangeFrom[this.From];
    _data["To"] = IossRegistrationStatusChangeTo[this.To];
    return _data;
  }
}
export var IossRegistrationStatusChangeFrom = /* @__PURE__ */ ((IossRegistrationStatusChangeFrom2) => {
  IossRegistrationStatusChangeFrom2["Pending"] = "Pending";
  IossRegistrationStatusChangeFrom2["WaitingForSignature"] = "WaitingForSignature";
  IossRegistrationStatusChangeFrom2["DocumentsSigned"] = "DocumentsSigned";
  IossRegistrationStatusChangeFrom2["SentToTA"] = "SentToTA";
  IossRegistrationStatusChangeFrom2["RegistrationCompleted"] = "RegistrationCompleted";
  IossRegistrationStatusChangeFrom2["Failed"] = "Failed";
  IossRegistrationStatusChangeFrom2["Declined"] = "Declined";
  IossRegistrationStatusChangeFrom2["Deregistered"] = "Deregistered";
  IossRegistrationStatusChangeFrom2["DeregistrationInProgress"] = "DeregistrationInProgress";
  return IossRegistrationStatusChangeFrom2;
})(IossRegistrationStatusChangeFrom || {});
export var IossRegistrationStatusChangeTo = /* @__PURE__ */ ((IossRegistrationStatusChangeTo2) => {
  IossRegistrationStatusChangeTo2["Pending"] = "Pending";
  IossRegistrationStatusChangeTo2["WaitingForSignature"] = "WaitingForSignature";
  IossRegistrationStatusChangeTo2["DocumentsSigned"] = "DocumentsSigned";
  IossRegistrationStatusChangeTo2["SentToTA"] = "SentToTA";
  IossRegistrationStatusChangeTo2["RegistrationCompleted"] = "RegistrationCompleted";
  IossRegistrationStatusChangeTo2["Failed"] = "Failed";
  IossRegistrationStatusChangeTo2["Declined"] = "Declined";
  IossRegistrationStatusChangeTo2["Deregistered"] = "Deregistered";
  IossRegistrationStatusChangeTo2["DeregistrationInProgress"] = "DeregistrationInProgress";
  return IossRegistrationStatusChangeTo2;
})(IossRegistrationStatusChangeTo || {});
