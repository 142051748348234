var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { GlobalServiceValidator } from "./OrderServiceValidator";
import i18next from "i18next";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import { DEFAULT_CURRENCY_CODE } from "../OrderModels";
export const useGlobalServices = (props) => {
  const [globalService, setGlobalService] = useState({});
  const [isValidGlobalServices, setIsValidGlobalServices] = useState(false);
  const globalServiceValidationContext = useValidationContext(new GlobalServiceValidator());
  useEffect(() => __async(void 0, null, function* () {
    setIsValidGlobalServices(globalServiceValidationContext.validationResult.isValid());
  }), [globalServiceValidationContext.validationResult]);
  useEffect(() => {
    if (globalService) {
      globalServiceValidationContext.validate(globalService);
    }
  }, [globalService]);
  useEffect(() => __async(void 0, null, function* () {
    if (props.currentCompanyServices) {
      setGlobalService({});
    }
  }), [props.currentCompanyServices]);
  const htmlGlobalServices = () => {
    var _a;
    return html`<div class="mt-4">
			${props.priceConfig.changeCompanyDetail !== void 0 ? html`<div class="lg:flex lg:justify-between">
						<div
							class="btn-toggle mt-6 mb-1 xl:w-1/2 ${globalService.changeCompanyDetail ? "selected" : ""}"
							@click=${() => {
      setGlobalService(__spreadProps(__spreadValues({}, globalService), {
        changeCompanyDetail: !globalService.changeCompanyDetail
      }));
    }}
						>
							<div class="flex justify-between">
								<div class="mr-2">${i18next.t("Change Company Detail")}</div>
							</div>
						</div>
				  </div>` : ""}
			${props.priceConfig.generalVatAdviceHourlyFee !== void 0 ? html`<div class="lg:flex lg:justify-between">
						<div
							class="btn-toggle mt-6 mb-1 xl:w-1/2 ${globalService.generalVatAdvice ? "selected" : ""}"
							@click=${() => {
      setGlobalService(__spreadProps(__spreadValues({}, globalService), {
        generalVatAdvice: !globalService.generalVatAdvice
      }));
    }}
						>
							<div class="flex justify-between">
								<div class="mr-2">${i18next.t("General VAT Advice")}</div>
							</div>
						</div>
						<dc-input
							class=${globalService.generalVatAdvice ? "" : "hidden"}
							.label=${"Hours"}
							.autocomplete=${"off"}
							.value=${globalService.generalVatAdviceHours}
							.validationMessage=${globalServiceValidationContext.getValidationMessage("generalVatAdviceHours")}
							@change=${(e) => {
      const noOfHours = toSafeNumber(e.detail.value);
      setGlobalService(__spreadProps(__spreadValues({}, globalService), {
        generalVatAdviceHours: noOfHours
      }));
      globalServiceValidationContext.validateField("generalVatAdviceHours");
    }}
						></dc-input>
				  </div>` : ""}
			${props.priceConfig.specialistVatAdvice !== void 0 ? html`<div class="lg:flex lg:justify-between">
						<div
							class="btn-toggle mt-6 mb-1 xl:w-1/2 ${globalService.specialistVatAdvice ? "selected" : ""}"
							@click=${() => {
      setGlobalService(__spreadProps(__spreadValues({}, globalService), {
        specialistVatAdvice: !globalService.specialistVatAdvice
      }));
    }}
						>
							<div class="flex justify-between">
								<div class="mr-2">${i18next.t("Specialist VAT Advice")}</div>
							</div>
						</div>
                        <dc-input
                        class=${globalService.specialistVatAdvice ? "" : "hidden"}
                        .label=${`Price (${DEFAULT_CURRENCY_CODE})`}
                        .autocomplete=${"off"}
                        .value=${(_a = globalService.specialistVatAdvicePrice) == null ? void 0 : _a.Value}
                        .validationMessage=${globalServiceValidationContext.getValidationMessage("specialistVatAdvicePrice")}
                        @change=${(e) => {
      const customPrice = toSafeNumber(e.detail.value);
      setGlobalService(__spreadProps(__spreadValues({}, globalService), {
        specialistVatAdvicePrice: { Value: customPrice, Currency: DEFAULT_CURRENCY_CODE }
      }));
      globalServiceValidationContext.validateField("specialistVatAdvicePrice");
    }}
                    >
				  </div>` : ""}
			${props.priceConfig.communicatingWithTaxAuthorities !== void 0 ? html`<div class="lg:flex lg:justify-between">
						<div
							class="btn-toggle mt-6 mb-1 xl:w-1/2 ${globalService.communicatingWithTaxAuthorities ? "selected" : ""}"
							@click=${() => {
      setGlobalService(__spreadProps(__spreadValues({}, globalService), {
        communicatingWithTaxAuthorities: !globalService.communicatingWithTaxAuthorities
      }));
    }}
						>
							<div class="flex justify-between">
								<div class="mr-2">${i18next.t("Communicating with Tax Authorities")}</div>
							</div>
						</div>
				  </div>` : ""}
		</div>`;
  };
  return {
    htmlGlobalServices,
    globalService,
    isValidGlobalServices
  };
};
