import { DatedVatIdentificationNumber } from "./DatedVatIdentificationNumber";
import { Answer } from "./../Answer";
export class ExistingVatNumbersAnswer extends Answer {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof ExistingVatNumbersAnswer
     */
    this.$type = "V1.CompanyInfo.Questionnaire.Answers.ExistingVatNumbersAnswer";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["Value"] && _data["Value"].constructor === Array) {
        this.Value = [];
        for (let item of _data["Value"]) {
          const value_ = new DatedVatIdentificationNumber();
          this.Value.push(value_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    if (this.Value && this.Value.constructor === Array) {
      _data["Value"] = [];
      for (let item of this.Value) {
        _data["Value"].push(item.toJSON());
      }
    }
    return _data;
  }
}
