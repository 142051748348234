import { ALPHANUMERIC_REGEX, EMAIL_REGEX } from "GlobalShared/common/Constants";
import { OperationStatus } from "./ValidationModels";
const validationOkResult = {
  message: "File parsed successfully.",
  status: OperationStatus.OK
};
export function validateRows(rows, countries, serviceCountries) {
  let validationResults = [];
  for (const row of rows) {
    const validationResult = validateRow(rows, row, countries, serviceCountries);
    validationResults = validationResults.concat(validationResult);
  }
  return validationResults;
}
function validateRow(rows, row, countries, serviceCountries) {
  const validationErrors = [];
  validationRules.forEach((rule) => {
    if (!rule.isValid({ row, countries, serviceCountries })) {
      const error = {
        message: rule.message,
        lineNumber: rows.indexOf(row),
        status: OperationStatus.Error
      };
      validationErrors.push(error);
    }
  });
  if (validationErrors.length > 0) {
    return validationErrors;
  }
  return [validationOkResult];
}
const validationRules = [
  {
    isValid: ({ row }) => ALPHANUMERIC_REGEX.test(row.MerchantId),
    message: "Merchant id only consists of numbers and letters"
  },
  {
    isValid: ({ row, countries }) => countries.includes(row.EstablishmentCountry),
    message: "Invalid establishment country. It must be a country code."
  },
  {
    isValid: ({ row, serviceCountries: vatCountries }) => row.SelectedCountries === void 0 || row.SelectedCountries.length === 0 || row.SelectedCountries.every((c) => vatCountries.includes(c)),
    message: "Invalid selected country. It must be a country code."
  },
  {
    isValid: ({ row }) => row.CheckOutDate.isValid(),
    message: "Checkout date is invalid. Supported format: 'dd/mm/yyyy"
  },
  {
    isValid: ({ row }) => row.SignTermsDate.isValid(),
    message: "Sign terms date is invalid. Supported format: 'dd/mm/yyyy"
  },
  {
    isValid: ({ row, serviceCountries: vatCountries }) => row.SailEnabledCountries === void 0 || row.SailEnabledCountries.length === 0 || row.SailEnabledCountries.every((c) => vatCountries.includes(c)),
    message: "Invalid sail enabled country. It must be a country code."
  },
  {
    isValid: ({ row, serviceCountries: vatCountries }) => row.VatCountries === void 0 || row.VatCountries.length === 0 || row.VatCountries.every((c) => vatCountries.includes(c)),
    message: "Invalid vat country. It must be a country code."
  },
  {
    isValid: ({ row }) => row.QualtricsRecordedDate.isValid(),
    message: "Qualtrics recorded date is invalid. Supported format: 'dd/mm/yyyy"
  },
  {
    isValid: ({ row }) => EMAIL_REGEX.test(row.Email),
    message: "Email is invalid"
  },
  {
    isValid: ({ row }) => ALPHANUMERIC_REGEX.test(row.Phone),
    message: "Phone only consists of numbers and letters"
  },
  {
    isValid: ({ row }) => row.WeChat === void 0 || row.WeChat === "" || ALPHANUMERIC_REGEX.test(row.WeChat),
    message: "WeChat only consists of numbers and letters"
  }
];
