import "../../../assets/css/bundle.scss";
import "../../../assets/css/admin/adminbundle.scss";
import routesJson from "../../../../Infrastructure/Services/WonderWoman.AdminHost/routes.json";
import "./globals";
import "AdminComponents";
import * as Router from "GlobalShared/router";
import { ReduxContext } from "GlobalShared/ReduxContext";
import "AdminComponents/reducers";
ReduxContext.init();
Router.startRouting(routesJson, (route) => route.area === "SPAMain");
