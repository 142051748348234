export class SellerEntity {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SellerEntity
     */
    this.$type = "V1.ComplianceUploads.Configuration.Amazon.SellerEntity";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Id = _data["Id"];
      this.IsAutomated = _data["IsAutomated"];
      this.IsEnabled = _data["IsEnabled"];
      this.MwsAuthToken = _data["MwsAuthToken"];
      this.Name = _data["Name"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Id"] = this.Id;
    _data["IsAutomated"] = this.IsAutomated;
    _data["IsEnabled"] = this.IsEnabled;
    _data["MwsAuthToken"] = this.MwsAuthToken;
    _data["Name"] = this.Name;
    return _data;
  }
}
