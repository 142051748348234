import { MappingExpressionBaseFactory } from "./MappingExpressionBaseFactory";
export class MappingItem {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof MappingItem
     */
    this.$type = "V1.TemplateMapping.MappingItem";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["EnumSourceValues"] && _data["EnumSourceValues"].constructor === Array) {
        this.EnumSourceValues = [];
        for (let item of _data["EnumSourceValues"]) {
          this.EnumSourceValues.push(item);
        }
      }
      const $typeExpression = _data["Expression"] && _data["Expression"].$type;
      if ($typeExpression) {
        const expression_ = MappingExpressionBaseFactory.create($typeExpression);
        this.Expression = expression_.init(_data["Expression"]);
      } else {
        this.Expression = _data["Expression"];
      }
      this.Id = _data["Id"];
      this.PdfFieldName = _data["PdfFieldName"];
      this.TemplateVersionId = _data["TemplateVersionId"];
      this.Type = _data["Type"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.EnumSourceValues && this.EnumSourceValues.constructor === Array) {
      _data["EnumSourceValues"] = [];
      for (let item of this.EnumSourceValues) {
        _data["EnumSourceValues"].push(item);
      }
    }
    _data["Expression"] = this.Expression ? this.Expression.toJSON() : void 0;
    _data["Id"] = this.Id;
    _data["PdfFieldName"] = this.PdfFieldName;
    _data["TemplateVersionId"] = this.TemplateVersionId;
    _data["Type"] = this.Type;
    return _data;
  }
}
