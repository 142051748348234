import { Service } from "./Service";
import { CountryBasedSubscriptionService } from "./CountryBasedSubscriptionService";
import { CustomPricedService } from "./CustomPricedService";
import { HourlyService } from "./HourlyService";
import { SimpleService } from "./SimpleService";
import { SubscriptionService } from "./SubscriptionService";
export class ServiceFactory {
  static create($type) {
    if ($type === "V1.Orders.Create.Service") {
      const result = new Service();
      return result;
    }
    if ($type === "V1.Orders.Create.CountryBasedSubscriptionService") {
      const result = new CountryBasedSubscriptionService();
      return result;
    }
    if ($type === "V1.Orders.Create.CustomPricedService") {
      const result = new CustomPricedService();
      return result;
    }
    if ($type === "V1.Orders.Create.HourlyService") {
      const result = new HourlyService();
      return result;
    }
    if ($type === "V1.Orders.Create.SimpleService") {
      const result = new SimpleService();
      return result;
    }
    if ($type === "V1.Orders.Create.SubscriptionService") {
      const result = new SubscriptionService();
      return result;
    }
    throw new Error($type + " not found");
  }
}
