import { TswagClient } from "./../commons/TswagClient";
import { SearchResult } from "./../models/V1/ComplianceMasterData/SearchSku/SearchResult";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { UploadResult } from "./../models/V1/ComplianceMasterData/Upload/UploadResult";
import { DefaultVatRateType } from "./../models/V1/ComplianceMasterData/DefaultVatRateType";
import { VatRateTypeSetting } from "./../models/V1/ComplianceMasterData/VatRateTypeSetting";
import { GTUCode } from "./../models/V1/ComplianceMasterData/GTUCode";
import { Currency } from "./../models/V1/ComplianceMasterData/Currency";
export class ComplianceMasterData {
  /**
   * Creates an instance of ComplianceMasterData.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof ComplianceMasterData
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SearchSKUsRq_ _searchSKUsRq
   * @returns Promise<SearchSKUsRq_>
   * @memberof ComplianceMasterData
   */
  searchSKUs(pSearchSKUsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/Companies/{companyId}/Skus/Search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pSearchSKUsRq.companyId, required: true }
    ]);
    client.addBody({ value: pSearchSKUsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportSkusRq_ _exportSkusRq
   * @returns Promise<ExportSkusRq_>
   * @memberof ComplianceMasterData
   */
  exportSkus(pExportSkusRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/Companies/{companyId}/Skus/Export",
      "POST",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pExportSkusRq.companyId, required: true }
    ]);
    client.addBody({ value: pExportSkusRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadSkusRq_ _uploadSkusRq
   * @returns Promise<UploadSkusRq_>
   * @memberof ComplianceMasterData
   */
  uploadSkus(pUploadSkusRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/Companies/{companyId}/Skus/Upload",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUploadSkusRq.companyId, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadSkusRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: UploadResult },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param HasAnyIncompleteSKUsRq_ _hasAnyIncompleteSKUsRq
   * @returns Promise<HasAnyIncompleteSKUsRq_>
   * @memberof ComplianceMasterData
   */
  hasAnyIncompleteSKUs(pHasAnyIncompleteSKUsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/Companies/{companyId}/Skus/Any",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pHasAnyIncompleteSKUsRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param AddSkuRq_ _addSkuRq
   * @returns Promise<AddSkuRq_>
   * @memberof ComplianceMasterData
   */
  addSku(pAddSkuRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/Companies/{companyId}/Skus",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pAddSkuRq.companyId, required: true }
    ]);
    client.addBody({ value: pAddSkuRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 201 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateSkuRq_ _updateSkuRq
   * @returns Promise<UpdateSkuRq_>
   * @memberof ComplianceMasterData
   */
  updateSku(pUpdateSkuRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/Companies/{companyId}/Skus/{id}",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateSkuRq.companyId, required: true },
      { name: "id", value: pUpdateSkuRq.id, required: true }
    ]);
    client.addBody({ value: pUpdateSkuRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RemoveSkuRq_ _removeSkuRq
   * @returns Promise<RemoveSkuRq_>
   * @memberof ComplianceMasterData
   */
  removeSku(pRemoveSkuRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/Companies/{companyId}/Skus/{id}",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pRemoveSkuRq.companyId, required: true },
      { name: "id", value: pRemoveSkuRq.id, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetDefaultVatRateTypesRq_ _getDefaultVatRateTypesRq
   * @returns Promise<GetDefaultVatRateTypesRq_>
   * @memberof ComplianceMasterData
   */
  getDefaultVatRateTypes(pGetDefaultVatRateTypesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/Companies/{companyId}/DefaultVatRateTypes",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pGetDefaultVatRateTypesRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: DefaultVatRateType, isArray: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SetDefaultVatRateTypesRq_ _setDefaultVatRateTypesRq
   * @returns Promise<SetDefaultVatRateTypesRq_>
   * @memberof ComplianceMasterData
   */
  setDefaultVatRateTypes(pSetDefaultVatRateTypesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/Companies/{companyId}/DefaultVatRateTypes",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pSetDefaultVatRateTypesRq.companyId, required: true }
    ]);
    client.addBody({ value: pSetDefaultVatRateTypesRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetVATRateTypeSettingsRq_ _getVATRateTypeSettingsRq
   * @returns Promise<GetVATRateTypeSettingsRq_>
   * @memberof ComplianceMasterData
   */
  getVATRateTypeSettings(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/VATRateTypeSettings",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: VatRateTypeSetting, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetGTUCodesRq_ _getGTUCodesRq
   * @returns Promise<GetGTUCodesRq_>
   * @memberof ComplianceMasterData
   */
  getGTUCodes(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/GTUCodes",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: GTUCode, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetAvailableCurrenciesRq_ _getAvailableCurrenciesRq
   * @returns Promise<GetAvailableCurrenciesRq_>
   * @memberof ComplianceMasterData
   */
  getAvailableCurrencies(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/AvailableCurrencies",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Currency, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportCurrenciesRq_ _exportCurrenciesRq
   * @returns Promise<ExportCurrenciesRq_>
   * @memberof ComplianceMasterData
   */
  exportCurrencies(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/ExportCurrencies",
      "GET",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
