var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import i18next from "i18next";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
export const usePaymentReferenceModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [paymentReference, setPaymentReference] = useState(void 0);
  const [validationMessage, setValidationMessage] = useState(void 0);
  useEffect(() => {
    setPaymentReference(props == null ? void 0 : props.paymentReference);
  }, [props == null ? void 0 : props.paymentReference]);
  const show = () => __async(void 0, null, function* () {
    setValidationMessage(void 0);
    setShowModal(true);
  });
  const template = () => {
    return html`${showModal ? html`
					<div>
						<dc-modal
							.visible=${showModal}
							@close=${() => setShowModal(false)}
							.header=${i18next.t("Payment reference")}
							.content=${html`<div style="height: 50vh">
								<div class="mt-4">
									<div class="my-4 xl:w-1/2">
										<dc-input
											.label=${"Payment reference"}
											.value=${paymentReference}
											.validationMessage=${validationMessage}
											@change=${(e) => {
      setPaymentReference(e.detail.value);
    }}
										></dc-input>
									</div>
									<button class="btn btn-primary ml-4" @click=${() => setShowModal(false)}>Cancel</button>
									<button
										class="btn btn-primary"
										@click=${() => __async(void 0, null, function* () {
      if (paymentReference === void 0) {
        setValidationMessage("Required");
        return;
      }
      yield props.save(paymentReference);
      setShowModal(false);
    })}
									>
										Save
									</button>
								</div>
							</div>`}
						>
						</dc-modal>
					</div>
			  ` : html``}`;
  };
  return { show, template };
};
