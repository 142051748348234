export class Agent {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Agent
     */
    this.$type = "V1.Agents.Agent";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Email = _data["Email"];
      this.Id = _data["Id"];
      this.IsTwoFactorEnabled = _data["IsTwoFactorEnabled"];
      if (_data["Roles"] && _data["Roles"].constructor === Array) {
        this.Roles = [];
        for (let item of _data["Roles"]) {
          this.Roles.push(item);
        }
      }
      this.Status = AgentStatus[_data["Status"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Email"] = this.Email;
    _data["Id"] = this.Id;
    _data["IsTwoFactorEnabled"] = this.IsTwoFactorEnabled;
    if (this.Roles && this.Roles.constructor === Array) {
      _data["Roles"] = [];
      for (let item of this.Roles) {
        _data["Roles"].push(item);
      }
    }
    _data["Status"] = AgentStatus[this.Status];
    return _data;
  }
}
export var AgentStatus = /* @__PURE__ */ ((AgentStatus2) => {
  AgentStatus2["Enabled"] = "Enabled";
  AgentStatus2["Disabled"] = "Disabled";
  return AgentStatus2;
})(AgentStatus || {});
