import { Payment } from "./Payment";
import { BankTransferPayment } from "./BankTransferPayment";
import { DesuclaPayment } from "./DesuclaPayment";
import { PayPalPayment } from "./PayPalPayment";
export class PaymentFactory {
  static create($type) {
    if ($type === "V1.Orders.Get.Payment") {
      const result = new Payment();
      return result;
    }
    if ($type === "V1.Orders.Get.BankTransferPayment") {
      const result = new BankTransferPayment();
      return result;
    }
    if ($type === "V1.Orders.Get.DesuclaPayment") {
      const result = new DesuclaPayment();
      return result;
    }
    if ($type === "V1.Orders.Get.PayPalPayment") {
      const result = new PayPalPayment();
      return result;
    }
    throw new Error($type + " not found");
  }
}
