import { useState } from "GlobalShared/haunted/CustomHooks";
import { getParameter } from "GlobalShared/router";
import { validateEmail } from "GlobalShared/validators/email-validator";
import { html } from "lit-html";
import { getLocalizedUrl } from "../globals";
export const observedAttributes = ["error"];
export const name = "ww-forgot-password";
export const Component = (host) => {
  const props = {
    error: host.error
  };
  const generateError = () => {
    if (props.error) {
      switch (props.error) {
        case "Invalid email.":
          return "It looks like there is no account with this email address.";
        case "Account is not confirmed yet.":
          return "Please confirm your email address.";
        default:
          return props.error;
      }
    }
    if (email && !validateEmail(email)) {
      return "Please provide a valid e-mail address.";
    }
    return void 0;
  };
  const hasError = () => {
    return generateError() === void 0 ? false : true;
  };
  const [email, setEmail] = useState(void 0);
  return html`<div class="w-full px-4 md:w-1/2 md:p-24">
		<div>
			<a href="${getLocalizedUrl("")}" data-routing="server">
				<img src="/images/kpmg_logo.png" alt="KPMG logo" />
			</a>
		</div>
		${getParameter("success") !== void 0 ? html`<div>
					<div class="text-3xl font-bold py-8">${"Password reset email sent. Please check your inbox."}</div>
			  </div>` : html`<div>
					<div class="md:text-xl font-bold my-8 lg:my-16 text-center max-w-screen-md m-auto">
						${"Forgot the password of your admin account? No problem, use the form below to reset your password."}
					</div>
					<div class="text-3xl font-bold text-center max-w-screen-md m-auto">${"Account recovery"}</div>
					<div class="bg-white shadow-md rounded px-8 py-4 m-auto flex flex-col">
						<div class="mb-4">
							<dc-input
								.label=${"Email"}
								.name=${"Email"}
								.placeholder=${"Email"}
								.autocomplete=${"off"}
								.value=${email}
								@change=${(e) => setEmail(e.detail.value)}
							></dc-input>
						</div>
						${hasError() ? html`<div class="mb-6">
									<label class="text-red-400"> ${generateError()} </label>
							  </div>` : ""}

						<div class="text-right">
							<button class="btn btn-primary" type="submit">${"Reset password"}</button>
						</div>
					</div>
			  </div>`}
	</div>`;
};
