var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useMemo, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { notifyError, notifyErrorWithWarning, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { PrimaryVATPaymentDataSource } from "./SubmissionModels";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import { AllReturnGeneratedValidatorFieldNames, SubmissionValidator } from "./SubmissionValidator";
import { EU_CURRENCIES } from "GlobalShared/helpers/countryHelper";
import { NOT_TO_BE_SUBMITTED_PROOF_DOC_ACCEPT_LIST, RETURN_DOC_ACCEPT_LIST, RETURN_PROOF_DOC_ACCEPT_LIST, RETURN_REJECTION_DOC_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
const DEFAULT_RETURN_GENERATED_MODEL = {
  isIntrastatInApplicable: false,
  intrastatInValue: void 0,
  intrastatInCurrency: void 0,
  isIntrastatOutApplicable: false,
  intrastatOutValue: void 0,
  intrastatOutCurrency: void 0,
  isVATApplicable: false,
  vatValue: void 0,
  vatCurrency: void 0,
  vatPayment: void 0
};
export const useReturnLibraryModals = (props) => {
  const openUploadModal = (submissionPeriod, uploadMode2) => {
    setUploadMode(uploadMode2);
    setCurrentSubmissionPeriod(submissionPeriod);
    setShowUploadModal(true);
  };
  const openReturnDocumentsUploadModal = (submissionPeriod) => {
    setCurrentSubmissionPeriod(submissionPeriod);
    setShowReturnDocumentsUploadModal(true);
  };
  const openStatusChangedToReturnGeneratedModal = (submissionPeriod) => {
    setCurrentSubmissionPeriod(submissionPeriod);
    returnGeneratedValidationContext.clearFieldValidations();
    setReturnGeneratedModel(__spreadProps(__spreadValues({}, DEFAULT_RETURN_GENERATED_MODEL), {
      isIntrastatInApplicable: submissionPeriod.TaxReturnType === "Intrastat",
      isIntrastatOutApplicable: submissionPeriod.TaxReturnType === "Intrastat",
      isVATApplicable: submissionPeriod.TaxReturnType === "PrimaryVAT"
    }));
    setShowStatusChangedToReturnGeneratedModal(true);
  };
  const openStatusChangedToNewModal = (submissionPeriod) => {
    setCurrentSubmissionPeriod(submissionPeriod);
    setShowStatusChangedToNewModal(true);
  };
  const openComplianceFeeModal = (submissionPeriod) => {
    setCurrentSubmissionPeriod(submissionPeriod);
    setShowComplianceFeeModal(true);
  };
  const openRefusedByClientModal = (submissionPeriod) => {
    setCurrentSubmissionPeriod(submissionPeriod);
    setReasonOfRejection(void 0);
    setShowRefusedByClientModal(true);
  };
  const uploadTitle = () => {
    if (uploadMode === "PaymentProof") {
      return "Upload Payment proof";
    }
    if (uploadMode === "NotToBeSubmittedProof") {
      return "Upload not to be submitted proof";
    }
    if (uploadMode === "SubmissionProof") {
      if (currentSubmissionPeriod.TaxReturnType === "Intrastat") {
        return "Upload Intrastat In submission proof";
      } else {
        return "Upload submission proof";
      }
    }
    if (uploadMode === "SubmissionProof2") {
      return "Upload Intrastat Out submission proof";
    }
    return "Upload file";
  };
  const onFileUpload = (file) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        if (uploadMode === "PaymentProof") {
          yield props.uploadPaymentProof(currentSubmissionPeriod.Id, file);
        }
        if (uploadMode === "SubmissionProof") {
          yield props.uploadSubmissionProof(currentSubmissionPeriod.Id, file);
        }
        if (uploadMode === "SubmissionProof2") {
          yield props.uploadSubmissionProof2(currentSubmissionPeriod.Id, file);
        }
        if (uploadMode === "NotToBeSubmittedProof") {
          yield props.uploadNotToBeSubmittedProof(currentSubmissionPeriod.Id, file);
        }
        notifySuccess(i18next.t("Document uploaded successfully"));
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      () => {
        props.loadSubmissions();
      }
    );
    setShowUploadModal(false);
  });
  const onReturnDocumentsUpload = (files) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.uploadReturnDocuments(currentSubmissionPeriod.Id, files);
        notifySuccess(i18next.t("Documents uploaded successfully"));
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      () => {
        props.loadSubmissions();
      }
    );
    setShowReturnDocumentsUploadModal(false);
  });
  const onAttachmentsUpload = (files) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        setRejectionAttachmentsUploading(true);
        const fileReferences = yield props.uploadRejectionAttachments(currentSubmissionPeriod.Id, files);
        setRejectionAttachments(fileReferences);
        notifySuccess(i18next.t("Attachments uploaded successfully"));
      }),
      (error) => {
        notifyError(error);
      },
      () => {
        setRejectionAttachmentsUploading(false);
      }
    );
  });
  const availableCurrenciesList = useMemo(
    () => EU_CURRENCIES.map((ac) => ({
      value: ac,
      label: ac
    })),
    []
  );
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showReturnDocumentsUploadModal, setShowReturnDocumentsUploadModal] = useState(false);
  const [showStatusChangedToNewModal, setShowStatusChangedToNewModal] = useState(false);
  const [showStatusChangedToReturnGeneratedModal, setShowStatusChangedToReturnGeneratedModal] = useState(false);
  const [showRefusedByClientModal, setShowRefusedByClientModal] = useState(false);
  const [showComplianceFeeModal, setShowComplianceFeeModal] = useState(false);
  const [uploadMode, setUploadMode] = useState("None");
  const [currentSubmissionPeriod, setCurrentSubmissionPeriod] = useState(void 0);
  const [reasonOfRejection, setReasonOfRejection] = useState(void 0);
  const [rejectionAttachments, setRejectionAttachments] = useState(void 0);
  const [rejectionAttachmentsUploading, setRejectionAttachmentsUploading] = useState(false);
  const [returnGeneratedModel, setReturnGeneratedModel] = useState(DEFAULT_RETURN_GENERATED_MODEL);
  const returnGeneratedValidationContext = useValidationContext(new SubmissionValidator(), () => __async(void 0, null, function* () {
    yield props.changeStatus(currentSubmissionPeriod.Id, currentSubmissionPeriod.Status, "ReturnGenerated", void 0, void 0, void 0, returnGeneratedModel);
    setShowStatusChangedToReturnGeneratedModal(false);
  }));
  useEffect(() => {
    returnGeneratedValidationContext.validate(returnGeneratedModel);
  }, [returnGeneratedModel]);
  const htmlUploadModal = () => {
    return html`<dc-modal
			.visible=${showUploadModal}
			@close=${() => setShowUploadModal(false)}
			.header=${uploadTitle()}
			.content=${html`<div>
				<dc-fileupload
					.label=${i18next.t("Upload file")}
					.accept=${uploadMode === "NotToBeSubmittedProof" ? NOT_TO_BE_SUBMITTED_PROOF_DOC_ACCEPT_LIST : RETURN_PROOF_DOC_ACCEPT_LIST}
					@upload=${(event) => onFileUpload(event.detail.files[0])}
				></dc-fileupload>
			</div>`}
		>
		</dc-modal>`;
  };
  const htmlReturnDocumentsUploadModal = () => {
    return html`<dc-modal
			.visible=${showReturnDocumentsUploadModal}
			@close=${() => setShowReturnDocumentsUploadModal(false)}
			.header=${`Upload return documents`}
			.content=${html`<div>
				<dc-fileupload
					.label=${i18next.t("Return documents")}
					.accept=${RETURN_DOC_ACCEPT_LIST}
					.multiple=${true}
					@upload=${(event) => onReturnDocumentsUpload(Array.from(event.detail.files))}
				></dc-fileupload>
			</div>`}
		>
		</dc-modal>`;
  };
  const htmlStatusChangedToNewModal = () => {
    return html`<dc-modal
			.visible=${showStatusChangedToNewModal}
			@close=${() => setShowStatusChangedToNewModal(false)}
			.header=${i18next.t("Confirmation")}
			.content=${html`<div>
				<dc-checkbox
					.checked=${currentSubmissionPeriod == null ? void 0 : currentSubmissionPeriod.Revision}
					.label=${i18next.t("Self revision")}
					@change=${(e) => {
      setCurrentSubmissionPeriod(__spreadProps(__spreadValues({}, currentSubmissionPeriod), {
        Revision: e.detail.checked
      }));
    }}
				></dc-checkbox>
				<button
					class="btn btn-primary"
					@click=${() => {
      props.changeStatus(currentSubmissionPeriod.Id, currentSubmissionPeriod.Status, "New", void 0, void 0, currentSubmissionPeriod.Revision, void 0);
      setShowStatusChangedToNewModal(false);
    }}
				>
					Confirm status change
				</button>
			</div>`}
		>
		</dc-modal>`;
  };
  const htmlStatusChangedToReturnGeneratedModal = () => {
    return html`<dc-modal
			.visible=${showStatusChangedToReturnGeneratedModal}
			@close=${() => setShowStatusChangedToReturnGeneratedModal(false)}
			.header=${`Confirm Return Generated`}
			.content=${html`<div>
					${(currentSubmissionPeriod == null ? void 0 : currentSubmissionPeriod.TaxReturnType) === "Intrastat" ? html`<div class="grid grid-cols-3 gap-4">
								<div>
									<div class="form-label">Intrastat In</div>
									<dc-toggle
										.state=${(returnGeneratedModel == null ? void 0 : returnGeneratedModel.isIntrastatInApplicable) ? "Yes" : "No"}
										@change=${(e) => {
      const isApplicable = e.detail.state === "Yes";
      setReturnGeneratedModel(__spreadProps(__spreadValues({}, returnGeneratedModel), {
        isIntrastatInApplicable: isApplicable
      }));
    }}
									></dc-toggle>
								</div>
								<dc-input
									.label=${"Value"}
									.value=${returnGeneratedModel == null ? void 0 : returnGeneratedModel.intrastatInValue}
									.validationMessage=${returnGeneratedValidationContext.getValidationMessage("intrastatInValue")}
									.readonly=${(returnGeneratedModel == null ? void 0 : returnGeneratedModel.isIntrastatInApplicable) !== true}
									@change=${(e) => {
      returnGeneratedValidationContext.validateField("intrastatInValue");
      setReturnGeneratedModel(__spreadProps(__spreadValues({}, returnGeneratedModel), {
        intrastatInValue: toSafeNumber(e.detail.value)
      }));
    }}
								></dc-input>
								<dc-select
									.label=${i18next.t("Currency")}
									.dataSource=${availableCurrenciesList}
									.filterable=${true}
									.selectedValues=${returnGeneratedModel.intrastatInCurrency}
									.validationMessage=${returnGeneratedValidationContext.getValidationMessage("intrastatInCurrency")}
									.readonly=${(returnGeneratedModel == null ? void 0 : returnGeneratedModel.isIntrastatInApplicable) !== true}
									@change=${(e) => {
      returnGeneratedValidationContext.validateField("intrastatInCurrency");
      setReturnGeneratedModel(__spreadProps(__spreadValues({}, returnGeneratedModel), {
        intrastatInCurrency: e.detail.selectedValue
      }));
    }}
								></dc-select>
								<div>
									<div class="form-label">Intrastat Out</div>
									<dc-toggle
										.state=${(returnGeneratedModel == null ? void 0 : returnGeneratedModel.isIntrastatOutApplicable) ? "Yes" : "No"}
										@change=${(e) => {
      const isApplicable = e.detail.state === "Yes";
      setReturnGeneratedModel(__spreadProps(__spreadValues({}, returnGeneratedModel), {
        isIntrastatOutApplicable: isApplicable
      }));
    }}
									></dc-toggle>
								</div>
								<dc-input
									.label=${"Value"}
									.value=${returnGeneratedModel == null ? void 0 : returnGeneratedModel.intrastatOutValue}
									.validationMessage=${returnGeneratedValidationContext.getValidationMessage("intrastatOutValue")}
									.readonly=${(returnGeneratedModel == null ? void 0 : returnGeneratedModel.isIntrastatOutApplicable) !== true}
									@change=${(e) => {
      returnGeneratedValidationContext.validateField("intrastatOutValue");
      setReturnGeneratedModel(__spreadProps(__spreadValues({}, returnGeneratedModel), {
        intrastatOutValue: toSafeNumber(e.detail.value)
      }));
    }}
								></dc-input>
								<dc-select
									.label=${i18next.t("Currency")}
									.dataSource=${availableCurrenciesList}
									.filterable=${true}
									.selectedValues=${returnGeneratedModel.intrastatOutCurrency}
									.validationMessage=${returnGeneratedValidationContext.getValidationMessage("intrastatOutCurrency")}
									.readonly=${(returnGeneratedModel == null ? void 0 : returnGeneratedModel.isIntrastatOutApplicable) !== true}
									@change=${(e) => {
      returnGeneratedValidationContext.validateField("intrastatOutCurrency");
      setReturnGeneratedModel(__spreadProps(__spreadValues({}, returnGeneratedModel), {
        intrastatOutCurrency: e.detail.selectedValue
      }));
    }}
								></dc-select>
						  </div>` : ""}
					${(currentSubmissionPeriod == null ? void 0 : currentSubmissionPeriod.TaxReturnType) === "PrimaryVAT" ? html`<div class="grid grid-cols-3 gap-4">
								<dc-input
									.label=${"Value"}
									.value=${returnGeneratedModel == null ? void 0 : returnGeneratedModel.vatValue}
									.validationMessage=${returnGeneratedValidationContext.getValidationMessage("vatValue")}
									@change=${(e) => {
      returnGeneratedValidationContext.validateField("vatValue");
      setReturnGeneratedModel(__spreadProps(__spreadValues({}, returnGeneratedModel), {
        vatValue: toSafeNumber(e.detail.value)
      }));
    }}
								></dc-input>
								<dc-select
									.label=${i18next.t("Currency")}
									.dataSource=${availableCurrenciesList}
									.filterable=${true}
									.selectedValues=${returnGeneratedModel.vatCurrency}
									.validationMessage=${returnGeneratedValidationContext.getValidationMessage("vatCurrency")}
									@change=${(e) => {
      returnGeneratedValidationContext.validateField("vatCurrency");
      setReturnGeneratedModel(__spreadProps(__spreadValues({}, returnGeneratedModel), {
        vatCurrency: e.detail.selectedValue
      }));
    }}
								></dc-select>
								<dc-select
									.label=${i18next.t("Final position")}
									.dataSource=${PrimaryVATPaymentDataSource}
									.selectedValues=${returnGeneratedModel.vatPayment}
									.validationMessage=${returnGeneratedValidationContext.getValidationMessage("vatPaymentCategory")}
									@change=${(e) => {
      returnGeneratedValidationContext.validateField("vatPaymentCategory");
      setReturnGeneratedModel(__spreadProps(__spreadValues({}, returnGeneratedModel), {
        vatPayment: e.detail.selectedValue
      }));
    }}
								></dc-select>
						  </div>` : ""}
				</div>
				<div class="mt-64 space-x-4">
					<button
						class="btn btn-primary mt-4"
						@click=${() => {
      returnGeneratedValidationContext.validateAndDispatch(returnGeneratedModel, AllReturnGeneratedValidatorFieldNames);
    }}
					>
						Save
					</button>
					<button
						class="btn btn-primary mt-4"
						@click=${() => {
      setShowStatusChangedToReturnGeneratedModal(false);
    }}
					>
						Cancel
					</button>
				</div>`}
		>
		</dc-modal>`;
  };
  const htmlRefusedByClientModal = () => {
    return html`<dc-modal
			.visible=${showRefusedByClientModal}
			@close=${() => setShowRefusedByClientModal(false)}
			.header=${i18next.t("Reason of Rejection")}
			.content=${html`<div>
				<textarea
					class="form-textarea h-48"
					.placeholder=${i18next.t("Please give a detailed reason for the rejection.")}
					@change=${function() {
      setReasonOfRejection(this.value);
    }}
				>
${reasonOfRejection}</textarea
				>
				<dc-fileupload
					.label=${i18next.t("Attach file")}
					.tooltip=${i18next.t("Please attach a file that will help us understand why you think this return is incorrect.")}
					.accept=${RETURN_REJECTION_DOC_ACCEPT_LIST}
					.multiple=${true}
					@upload=${(event) => onAttachmentsUpload(Array.from(event.detail.files))}
				></dc-fileupload>

				<button
					class="btn btn-primary mt-4"
					?disabled=${!reasonOfRejection || reasonOfRejection.trim().length < 20 || rejectionAttachmentsUploading}
					@click=${() => {
      props.changeStatus(currentSubmissionPeriod.Id, currentSubmissionPeriod.Status, "RefusedByClient", reasonOfRejection, rejectionAttachments, void 0, void 0);
      setShowRefusedByClientModal(false);
    }}
				>
					${i18next.t("Confirm rejection")}
				</button>
			</div>`}
		>
		</dc-modal>`;
  };
  const htmlComplianceFeeModal = () => {
    return html`<dc-modal
			.visible=${showComplianceFeeModal}
			@close=${() => setShowComplianceFeeModal(false)}
			.header=${`Payment details`}
			.content=${html`<div>
				<div class="flex justify-around">
					<div class="flex flex-col items-center w-1/2">
						<div>
							${i18next.t(
      "pay-monthly-compliance-fee",
      "Please click the button to assess the VAT intermediary\u2019s system where you must approve and confirm the payment amount, and follow the instruction to make payment."
    )}
							${i18next.t("If your return does not contain any payable VAT, there is no action required from your end.")}
						</div>
						<div class="mt-4">
							<button
								class="btn btn-primary"
								@click=${() => {
      window.open(currentSubmissionPeriod.PaymentUrl, "_blank", "noopener");
      setShowComplianceFeeModal(false);
    }}
							>
								${i18next.t("Confirm Payment")}
							</button>
						</div>
					</div>
				</div>
			</div>`}
		>
		</dc-modal>`;
  };
  const htmlAllModals = html`${htmlUploadModal()} ${htmlReturnDocumentsUploadModal()} ${htmlComplianceFeeModal()} ${htmlStatusChangedToNewModal()} ${htmlStatusChangedToReturnGeneratedModal()}
	${htmlRefusedByClientModal()}`;
  return {
    htmlAllModals,
    openUploadModal,
    openReturnDocumentsUploadModal,
    openStatusChangedToNewModal,
    openStatusChangedToReturnGeneratedModal,
    openComplianceFeeModal,
    openRefusedByClientModal
  };
};
