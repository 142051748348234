import "./dc-loader.scss";
import { html } from "lit-html";
import { component } from "haunted";
export const Component = (host) => {
  const props = {
    progress: host.progress !== void 0 ? host.progress : void 0
  };
  return html`
		<div class="loader-overlay">
			<div class="loader-background"></div>
			<div class="${props.progress !== void 0 ? "loader-with-progress" : "loader"}" data-progress=${props.progress}></div>
			${props.progress !== void 0 ? html` <div class="progress">${props.progress}<span>%</span></div>` : ""}
		</div>
	`;
};
customElements.define(
  "dc-loader",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
