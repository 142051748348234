var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { VatNumberAnswer as WebVatNumberAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { VatNumberAnswer as AdminVatNumberAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { VatNumberValidator } from "./AnswerValidators";
import { VatIdentificationNumber as WebVatIdentificationNumber } from "WebServices/APIs/self/models/V1/Common";
import { VatIdentificationNumber as AdminVatIdentificationNumber } from "AdminServices/APIs/self/models/V1/Common";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-vatnumber";
const DEFAULTS = {
  validation: {
    required: false
  }
};
export class VatNumberChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    countryCode: host.countryCode,
    validation: host.validation !== void 0 ? __spreadValues(__spreadValues({}, DEFAULTS.validation), host.validation) : DEFAULTS.validation
  };
  const getVatNumberAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminVatNumberAnswer({
        Value: new AdminVatIdentificationNumber({ CountryCode: props.countryCode, VatNumber: currentVal }),
        QuestionId: props.questionId
      });
    }
    return new WebVatNumberAnswer({
      Value: new WebVatIdentificationNumber({ CountryCode: props.countryCode, VatNumber: currentVal }),
      QuestionId: props.questionId
    });
  };
  const [currentVal, setCurrentVal] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new VatNumberValidator(props.validation));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new VatNumberChangeEvent({
          answer: getVatNumberAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof WebVatNumberAnswer || answer instanceof AdminVatNumberAnswer) {
        setCurrentVal(answer.Value.VatNumber);
      }
    }
  }, [props.q11e, props.questionId]);
  useEffect(() => {
    validationContext.validate(getVatNumberAnswer());
  }, [currentVal]);
  return html`
		${props.q11e !== void 0 && question !== void 0 ? html`
					${question.Label ? html`<label class="form-label"
								>${question.Label + (props.validation.required ? "*" : "")}${question.Tooltip !== void 0 && question.Tooltip.length > 0 ? html`<dc-tooltip .label=${question.Tooltip}></dc-tooltip>` : ""}</label
						  >` : ""}
					<div class="flex">
						<dc-input class="w-16" .readonly=${true} .value=${props.countryCode}></dc-input>
						<dc-input
							class="lg:col-span-2"
							.readonly=${props.q11e.isReadonly()}
							.value=${currentVal}
							.validationMessage=${validationContext.getValidationMessage("vatNumber")}
							@change=${(e) => {
    setCurrentVal(e.detail.value);
    setDispatchIfValid(true);
    validationContext.validateField("vatNumber");
  }}
						></dc-input>
					</div>
			  ` : ""}
	`;
};
