export class Field {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Field
     */
    this.$type = "V1.Company.IossRegistration.Search.Field";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Order = FieldOrder[_data["Order"]];
      this.OrderByField = FieldOrderByField[_data["OrderByField"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Order"] = FieldOrder[this.Order];
    _data["OrderByField"] = FieldOrderByField[this.OrderByField];
    return _data;
  }
}
export var FieldOrder = /* @__PURE__ */ ((FieldOrder2) => {
  FieldOrder2["Ascending"] = "Ascending";
  FieldOrder2["Descending"] = "Descending";
  return FieldOrder2;
})(FieldOrder || {});
export var FieldOrderByField = /* @__PURE__ */ ((FieldOrderByField2) => {
  FieldOrderByField2["ClientId"] = "ClientId";
  FieldOrderByField2["ClientName"] = "ClientName";
  FieldOrderByField2["SignupDate"] = "SignupDate";
  FieldOrderByField2["RegistrationStatus"] = "RegistrationStatus";
  FieldOrderByField2["RegistrationType"] = "RegistrationType";
  FieldOrderByField2["ClientSource"] = "ClientSource";
  FieldOrderByField2["CompanyStatus"] = "CompanyStatus";
  FieldOrderByField2["SubscriptionStatus"] = "SubscriptionStatus";
  FieldOrderByField2["SubscriptionValidTo"] = "SubscriptionValidTo";
  return FieldOrderByField2;
})(FieldOrderByField || {});
