export class RolesModify {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof RolesModify
     */
    this.$type = "V1.Agents.RolesModify";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Roles"] && _data["Roles"].constructor === Array) {
        this.Roles = [];
        for (let item of _data["Roles"]) {
          this.Roles.push(item);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.Roles && this.Roles.constructor === Array) {
      _data["Roles"] = [];
      for (let item of this.Roles) {
        _data["Roles"].push(item);
      }
    }
    return _data;
  }
}
