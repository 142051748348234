import { AbstractValidator } from "fluent-ts-validator";
import { DCValidationResult } from "GlobalShared/haunted/HooksHelpers";
export const getAllBankAccountConfigFields = () => {
  return ["BankAccountRef", "BankAccountUrl", "BankName", "BankAddress", "BeneficiaryName", "BeneficiaryAddress", "Iban", "Swift"];
};
export class BankAccountConfigValidator extends AbstractValidator {
  constructor(bankAccountType) {
    super();
    this.validateIfString((s) => s.BankAccountRef).isNotEmpty().withPropertyName("BankAccountRef").withFailureMessage("Required").when(() => bankAccountType === "BankAccountRef");
    this.validateIfString((s) => {
      var _a;
      return (_a = s.BankAccount) == null ? void 0 : _a.BankName;
    }).isNotEmpty().withPropertyName("BankName").withFailureMessage("Required").when(() => bankAccountType === "Manual");
    this.validateIfString((s) => {
      var _a;
      return (_a = s.BankAccount) == null ? void 0 : _a.BankName;
    }).hasMaxLength(255).withPropertyName("BankName").withFailureMessage("Maximum 255 characters").when(() => bankAccountType === "Manual");
    this.validateIfString((s) => {
      var _a;
      return (_a = s.BankAccount) == null ? void 0 : _a.BankAddress;
    }).hasMaxLength(255).withPropertyName("BankAddress").withFailureMessage("Maximum 255 characters").when((s) => {
      var _a;
      return bankAccountType === "Manual" && ((_a = s.BankAccount) == null ? void 0 : _a.BankAddress) !== void 0;
    });
    this.validateIfString((s) => {
      var _a;
      return (_a = s.BankAccount) == null ? void 0 : _a.BeneficiaryName;
    }).hasMaxLength(255).withPropertyName("BeneficiaryName").withFailureMessage("Maximum 255 characters").when((s) => {
      var _a;
      return bankAccountType === "Manual" && ((_a = s.BankAccount) == null ? void 0 : _a.BeneficiaryName) !== void 0;
    });
    this.validateIfString((s) => {
      var _a;
      return (_a = s.BankAccount) == null ? void 0 : _a.BeneficiaryAddress;
    }).hasMaxLength(255).withPropertyName("BeneficiaryAddress").withFailureMessage("Maximum 255 characters").when((s) => {
      var _a;
      return bankAccountType === "Manual" && ((_a = s.BankAccount) == null ? void 0 : _a.BeneficiaryAddress) !== void 0;
    });
    this.validateIfString((s) => {
      var _a;
      return (_a = s.BankAccount) == null ? void 0 : _a.Swift;
    }).isNotEmpty().withPropertyName("Swift").withFailureMessage("Required").when(() => bankAccountType === "Manual");
    this.validateIfString((s) => {
      var _a;
      return (_a = s.BankAccount) == null ? void 0 : _a.Swift;
    }).hasMaxLength(11).withPropertyName("Swift").withFailureMessage("Maximum 11 characters").when(() => bankAccountType === "Manual");
    this.validateIfString((s) => {
      var _a;
      return (_a = s.BankAccount) == null ? void 0 : _a.Iban;
    }).isNotEmpty().withPropertyName("Iban").withFailureMessage("Required").when(() => bankAccountType === "Manual");
    this.validateIfString((s) => {
      var _a;
      return (_a = s.BankAccount) == null ? void 0 : _a.Iban;
    }).hasMaxLength(34).withPropertyName("Iban").withFailureMessage("Maximum 34 characters").when(() => bankAccountType === "Manual");
    this.validateIfString((s) => s.BankAccountUrl).isNotEmpty().withPropertyName("BankAccountUrl").withFailureMessage("Required").when(() => bankAccountType === "BankAccountUrl");
    this.validateIfString((s) => s.BankAccountUrl).hasMaxLength(255).withPropertyName("BankAccountUrl").withFailureMessage("Maximum 255 characters").when(() => bankAccountType === "BankAccountUrl");
  }
  validateAllFields(item) {
    return new DCValidationResult(super.validate(item));
  }
}
