import { Address } from "./../../../Common/Address";
import { Answer } from "./../Answer";
export class BankInformationAnswer extends Answer {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof BankInformationAnswer
     */
    this.$type = "V1.CompanyInfo.Questionnaire.Answers.BankInformationAnswer";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["Address"]) {
        const address_ = new Address();
        this.Address = address_.init(_data["Address"]);
      }
      this.AddressCountry = _data["AddressCountry"];
      this.Iban = _data["Iban"];
      this.Name = _data["Name"];
      this.Owner = _data["Owner"];
      this.Swift = _data["Swift"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["Address"] = this.Address ? this.Address.toJSON() : void 0;
    _data["AddressCountry"] = this.AddressCountry;
    _data["Iban"] = this.Iban;
    _data["Name"] = this.Name;
    _data["Owner"] = this.Owner;
    _data["Swift"] = this.Swift;
    return _data;
  }
}
