import "./dc-pager.scss";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import i18next from "i18next";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { createRange } from "GlobalShared/helpers/collectionHelper";
import { component } from "haunted";
const PAGE_INDEX_START = 0;
const DEFAULTS = {
  pageSize: 20,
  pageIndex: 0,
  itemCount: void 0,
  pageButtonCount: 10,
  pageSizes: [],
  showInfo: true,
  showPreviousNext: true
};
export class PagerChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail });
  }
}
export const Component = (host) => {
  const props = {
    pageSize: host.pageSize !== void 0 ? host.pageSize : DEFAULTS.pageSize,
    pageIndex: host.pageIndex !== void 0 ? host.pageIndex : DEFAULTS.pageIndex,
    itemCount: host.itemCount !== void 0 ? host.itemCount : DEFAULTS.itemCount,
    pageButtonCount: host.pageButtonCount !== void 0 ? host.pageButtonCount : DEFAULTS.pageButtonCount,
    pageSizes: host.pageSizes !== void 0 ? host.pageSizes : DEFAULTS.pageSizes,
    showInfo: host.showInfo !== void 0 ? host.showInfo : DEFAULTS.showInfo,
    showPreviousNext: host.showPreviousNext !== void 0 ? host.showPreviousNext : DEFAULTS.showPreviousNext
  };
  const getPagerModel = () => {
    const { firstPage, lastPage, firstItem, lastItem, noOfPages } = calculatePagingRange(selectedPageIndex, selectedPageSize, props.pageButtonCount, props.itemCount);
    return {
      firstPage,
      lastPage,
      range: createRange(firstPage, lastPage),
      showPreviousEllipsis: firstPage > 1,
      showNextEllipsis: props.itemCount === void 0 || lastPage < noOfPages,
      isFirstShown: props.showPreviousNext,
      isFirstEnabled: selectedPageIndex !== 0,
      isPreviousShown: props.showPreviousNext,
      isPreviousEnabled: selectedPageIndex !== 0,
      isNextShown: props.showPreviousNext,
      isNextEnabled: props.itemCount === void 0 || selectedPageIndex < noOfPages - 1,
      isLastShown: props.showPreviousNext && props.itemCount !== void 0,
      isLastEnabled: selectedPageIndex < noOfPages - 1 && props.itemCount !== void 0,
      firstItem,
      lastItem,
      noOfPages
    };
  };
  const onPageIndexSelected = (pageIndex) => {
    return (_e) => {
      if (pageIndex !== selectedPageIndex) {
        setSelectedPageIndex(pageIndex);
        host.dispatchEvent(
          new PagerChangeEvent({
            selectedPageIndex: pageIndex,
            selectedPageSize
          })
        );
      }
    };
  };
  const onPageSizeChanged = (e) => {
    const pageSize = parseInt(e.detail.selectedValue, 10);
    setSelectedPageSize(pageSize);
    setSelectedPageIndex(PAGE_INDEX_START);
    host.dispatchEvent(
      new PagerChangeEvent({
        selectedPageIndex: PAGE_INDEX_START,
        selectedPageSize: pageSize
      })
    );
  };
  const [selectedPageIndex, setSelectedPageIndex] = useState(DEFAULTS.pageIndex);
  const [selectedPageSize, setSelectedPageSize] = useState(DEFAULTS.pageSize);
  useEffect(() => {
    let pageSize = DEFAULTS.pageSize;
    if (props.pageSize !== void 0) {
      if (props.pageSize > 0) {
        pageSize = props.pageSize;
      }
      setSelectedPageSize(pageSize);
    }
    if (props.pageIndex !== void 0) {
      if (props.pageIndex < 0) {
        setSelectedPageIndex(0);
      } else {
        const noOfPages = props.itemCount !== void 0 ? Math.ceil(props.itemCount / props.pageSize) : void 0;
        if (noOfPages !== void 0) {
          if (props.pageIndex > noOfPages - 1) {
            setSelectedPageIndex(noOfPages - 1);
          } else {
            setSelectedPageIndex(props.pageIndex);
          }
        } else {
          setSelectedPageIndex(props.pageIndex);
        }
      }
    }
  }, [props.pageIndex, props.pageSize]);
  const pagerModel = getPagerModel();
  const templatePagerItems = () => {
    return html`<div class="dc-pager-items">
			${pagerModel.isFirstShown ? pagerModel.isFirstEnabled ? html` <div @click=${onPageIndexSelected(0)}>&#8739;&#9664;</div> ` : html` <div class="disabled">&#8739;&#9664;</div> ` : ""}
			${pagerModel.isPreviousShown ? pagerModel.isPreviousEnabled ? html` <div @click=${onPageIndexSelected(selectedPageIndex - 1)}>&#9664;</div> ` : html` <div class="disabled">&#9664;</div> ` : ""}
			${pagerModel.showPreviousEllipsis ? html` <div @click=${onPageIndexSelected(pagerModel.firstPage - 2)}>...</div> ` : ""}
			${pagerModel.range.map(
      (i) => html`
					<div
						@click=${onPageIndexSelected(i - 1)}
						class=${classMap({
        "current-page": i === selectedPageIndex + 1
      })}
					>
						${i}
					</div>
				`
    )}
			${pagerModel.showNextEllipsis ? html` <div @click=${onPageIndexSelected(pagerModel.lastPage)}>...</div> ` : ""}
			${pagerModel.isNextShown ? pagerModel.isNextEnabled ? html` <div @click=${onPageIndexSelected(selectedPageIndex + 1)}>&#9654;</div> ` : html` <div class="disabled">&#9654;</div> ` : ""}
			${pagerModel.isLastShown ? pagerModel.isLastEnabled ? html` <div @click=${onPageIndexSelected(pagerModel.noOfPages - 1)}>&#9654;&#8739;</div> ` : html` <div class="disabled">&#9654;&#8739;</div> ` : ""}
		</div>`;
  };
  const templateSizeAndInfo = () => {
    return html`<div>
				${props.pageSizes.length > 0 ? html` <dc-select class="page-size-selector sm" .dataSource=${props.pageSizes} .selectedValues=${selectedPageSize} @change=${onPageSizeChanged}> </dc-select> ` : ""}
			</div>
			<div class="pager-show-info">
				${props.showInfo ? html`
							${props.itemCount !== void 0 ? i18next.t("Showing {{firstItem}}-{{lastItem}} of {{itemCount}}", {
      firstItem: pagerModel.firstItem,
      lastItem: pagerModel.lastItem,
      itemCount: props.itemCount
    }) : i18next.t("Showing {{firstItem}}-{{lastItem}}", {
      firstItem: pagerModel.firstItem,
      lastItem: pagerModel.lastItem
    })}
					  ` : ""}
			</div>`;
  };
  return html` <div class="dc-pager">${templatePagerItems()} ${templateSizeAndInfo()}</div> `;
};
export const calculatePagingRange = (pPageIndex, pageSize, pageNumberCount, itemCount) => {
  let pageIndex = pPageIndex < 0 ? 0 : pPageIndex;
  if (itemCount === void 0) {
    const noOfPagesBeforeUnlimited = Math.floor(pageIndex / pageNumberCount) * pageNumberCount;
    return {
      firstPage: noOfPagesBeforeUnlimited + 1,
      lastPage: noOfPagesBeforeUnlimited + pageNumberCount,
      firstItem: pageIndex * pageSize + 1,
      lastItem: (pageIndex + 1) * pageSize
    };
  } else {
    const noOfPages = Math.max(1, Math.ceil(itemCount / pageSize));
    pageIndex = pageIndex > noOfPages - 1 ? noOfPages - 1 : pageIndex;
    const noOfPagesBefore = Math.floor(pageIndex / pageNumberCount) * pageNumberCount;
    return {
      firstPage: noOfPagesBefore + 1,
      lastPage: Math.min(noOfPagesBefore + pageNumberCount, noOfPages),
      noOfPages,
      firstItem: Math.min(pageIndex * pageSize + 1, itemCount),
      lastItem: Math.min((pageIndex + 1) * pageSize, itemCount)
    };
  }
};
customElements.define(
  "dc-pager",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
