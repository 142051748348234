var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useLoadingReducer, useMemo, useQueryGridState, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { notifyError, notifyWarning, notifySuccess, withErrorHandling, notifyErrorWithWarning, extractFirstError } from "GlobalShared/helpers/errorHelper";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import i18next from "i18next";
import { editIcon, removeIcon, templateTitle, downloadPDFIcon } from "GlobalShared/templates/commons";
import {
  DEFAULT_COMPLIANCE_UPLOAD_STATE,
  ComplianceUploadFieldNames,
  mapValidationMessage,
  COMPLIANCE_UPLOAD_TYPES
} from "./ComplianceUploadModels";
import { getAllSellerEntityFields, SellerEntityValidator } from "./SellerEntityValidator";
import { navigateTo } from "GlobalShared/router";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
import { mapBooleanToState } from "GlobalShared/helpers/booleanHelper";
import moment from "moment";
import { ComplianceUploadType } from "AdminServices/APIs/self/models/V1/ComplianceUploads/ComplianceUpload";
import { generateMonthlyPeriods } from "GlobalShared/helpers/periodHelper";
import { useConfirmModal } from "../modals/commonModals";
import { AMAZON_TRANSACTION_ACCEPT_LIST, ONLY_XLSX_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
const REFUND_LABELS = /* @__PURE__ */ new Map([
  [
    "Normal",
    i18next.t(
      "The right to deduct VAT on refunds where the goods are not returned must be considered on a case by case basis and KPMG is unable to carry out this assessment. Therefore, by default, KPMG only deducts VAT where there is a matching return of goods and the VAT deductibility is clear. This is the default option we use for VAT compliance."
    )
  ],
  [
    "WithMfn",
    i18next.t(
      "The Amazon VAT Transactions Report does not contain any details of the returns in case of MFN transactions. By selecting this refund option, you accept the risk that the tax authorities may seek to challenge the VAT deduction in case of an audit. Alternatively, MFN credit notes may be provided in the KPMG template files. "
    )
  ],
  [
    "DeductAll",
    i18next.t(
      "The right to deduct VAT on refunds where the goods are not returned must be considered on a case by case basis and KPMG is unable to carry out this assessment. Therefore, by default, KPMG only deducts VAT where there is a matching return of goods and the VAT deductibility is clear. By selecting DEDUCT ALL REFUND option, we deduct all refunds which happened in a given month and you accept the risk that the tax authorities may seek to challenge the VAT deduction in case of an audit."
    )
  ]
]);
const REFUND_MODES = [
  { label: i18next.t("Return and Refund"), value: "Normal" },
  { label: i18next.t("Return and Refund plus MFN"), value: "WithMfn" },
  { label: i18next.t("Deduct all Refund"), value: "DeductAll" }
];
const DEFAULT_FILTERS = [];
const DEFAULT_VAT_CONFIG = {
  useAmazonTemplate: false,
  useKpmgTemplate: false,
  amazonConfiguration: {
    sellerEntities: [],
    useAmazonRates: false,
    refundMode: "DeductAll"
    // !!! default DeductAll added because of KPMG-1578
  }
};
const DEFAULT_VALIDATION_DETAILS = {
  validationMessages: void 0,
  simpleMessage: void 0,
  numberOfTotalLinesWithError: 0,
  type: "None",
  sellerEntityId: void 0
};
const Statuses = [
  {
    label: i18next.t("New"),
    value: "New"
  },
  {
    label: i18next.t("Invalid Data"),
    value: "InvalidData"
  },
  {
    label: i18next.t("Waiting For Finalize"),
    value: "WaitingForFinalize"
  },
  {
    label: i18next.t("SAF-T Compliance"),
    value: "SaftCompliance"
  },
  {
    label: i18next.t("Master Data Setup"),
    value: "MasterDataSetup"
  },
  {
    label: i18next.t("Data Upload Finalized"),
    value: "DataUploadFinalized"
  },
  {
    label: i18next.t("Error"),
    value: "Error"
  },
  {
    label: i18next.t("Sent To Return Generation"),
    value: "SentToReturnGeneration"
  },
  {
    label: i18next.t("Upload processed"),
    value: "UploadProcessed"
  },
  {
    label: i18next.t("Insufficient credit"),
    value: "InsufficientCredit"
  },
  {
    label: i18next.t("DataVAT generated"),
    value: "DataVatGenerated"
  }
];
export const useComplianceUploads = (props) => {
  const getStatusLabel = (status) => {
    const item = Statuses.find((s) => s.value === status);
    return item && item.label ? item.label : status;
  };
  const SHOW_CONFIGURATION_TEMPLATE = !props.isAdmin;
  const resetUploadModalState = () => {
    setValidationDetails(DEFAULT_VALIDATION_DETAILS);
    setCurrentComplianceUploadState(DEFAULT_COMPLIANCE_UPLOAD_STATE);
  };
  const showVatConfiguration = () => {
    var _a;
    return defaultCompanyConfiguration !== void 0 && ((_a = companyServices == null ? void 0 : companyServices.VatRegistrations) == null ? void 0 : _a.length) > 0;
  };
  const showVatConfigurationLoading = () => {
    return props.isAdmin && defaultCompanyConfiguration === void 0 || companyServices === void 0;
  };
  const getDefaultCompanyConfiguration = (companyId) => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a;
        const companyConfig = yield props.getDefaultCompanyConfiguration(companyId);
        setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, companyConfig), {
          amazonConfiguration: __spreadProps(__spreadValues({}, companyConfig.amazonConfiguration), {
            refundMode: (_a = companyConfig.amazonConfiguration) == null ? void 0 : _a.refundMode
          })
        }));
        setAutoSaveCompanyConfiguration(false);
      }),
      (error) => {
        if (error.statusCode === 404) {
          setDefaultCompanyConfiguration(DEFAULT_VAT_CONFIG);
        } else {
          notifyError(error);
        }
      }
    );
  });
  const getCompanyDetail = (companyId) => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const companyDetail = yield props.getCompanyServices(companyId);
        setCompanyServices(companyDetail);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const loadComplianceUploads = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const result = yield props.searchComplianceUploads(gridState, showAll);
        setSearchResult(result);
        if (currentComplianceUpload && result) {
          const element = result.uploads.find((f) => f.Id === currentComplianceUpload.Id);
          setCurrentComplianceUpload(element);
        }
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const checkIncompleteSkus = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const result = yield props.hasAnyIncompleteSKUs(currentComplianceUpload.ClientId);
        setHasAnyIncompleteSKUs(result ? "Yes" : "No");
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const linkGenerator = (complianceUploadId, reference) => {
    return `${window.location.origin}/api/v1/ComplianceUploads/${complianceUploadId}/Files/${reference}`;
  };
  const confirmButtonEnabled = () => {
    var _a, _b, _c;
    if (((_a = validationDetails.validationMessages) == null ? void 0 : _a.length) > 0) {
      return false;
    }
    if ((currentComplianceUpload == null ? void 0 : currentComplianceUpload.Type) === "IOSS") {
      if (currentComplianceUpload.Status !== "WaitingForFinalize") {
        return false;
      }
      return ((_b = currentComplianceUpload.IossFile) == null ? void 0 : _b.Name) && (searchResult == null ? void 0 : searchResult.uploads.some((f) => {
        var _a2;
        return ((_a2 = f.IossFile) == null ? void 0 : _a2.Name) === currentComplianceUpload.IossFile.Name;
      }));
    } else {
      if (defaultCompanyConfiguration === void 0) {
        return false;
      }
      if ((currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "New") {
        const isAllAmazonFilesUploaded = defaultCompanyConfiguration.amazonConfiguration.sellerEntities.every(
          (se) => {
            var _a2;
            return !se.isEnabled || ((_a2 = currentComplianceUpload.AmazonFiles) == null ? void 0 : _a2.some((af) => af.SellerEntity.Id === se.id && af.Reference));
          }
        );
        const hasEnabledSellerEntity = defaultCompanyConfiguration.amazonConfiguration.sellerEntities.some((se) => se.isEnabled);
        const isAmazonValid = !defaultCompanyConfiguration.useAmazonTemplate || hasEnabledSellerEntity && isAllAmazonFilesUploaded && (defaultCompanyConfiguration.amazonConfiguration.refundMode !== void 0 || true);
        //!!! added || true just for KPMG-1578
        const isKpmgFileUploaded = ((_c = currentComplianceUpload.KpmgTemplateFile) == null ? void 0 : _c.Reference) !== void 0;
        const isKpmgValid = !defaultCompanyConfiguration.useKpmgTemplate || isKpmgFileUploaded;
        return (defaultCompanyConfiguration.useAmazonTemplate || defaultCompanyConfiguration.useKpmgTemplate) && isAmazonValid && isKpmgValid;
      } else if ((currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "MasterDataSetup") {
        return hasAnyIncompleteSKUs === "No";
      } else {
        return currentComplianceUpload.SaftFile !== void 0;
      }
    }
  };
  const saveSellerEntity = () => {
    setCurrentSellerEntity(void 0);
    if (sellerEntityEditMode) {
      setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, defaultCompanyConfiguration), {
        amazonConfiguration: __spreadProps(__spreadValues({}, defaultCompanyConfiguration.amazonConfiguration), {
          sellerEntities: defaultCompanyConfiguration.amazonConfiguration.sellerEntities.map((se) => se.id === currentSellerEntity.id ? currentSellerEntity : se)
        })
      }));
    } else {
      setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, defaultCompanyConfiguration), {
        amazonConfiguration: __spreadProps(__spreadValues({}, defaultCompanyConfiguration.amazonConfiguration), {
          sellerEntities: defaultCompanyConfiguration.amazonConfiguration.sellerEntities.concat(currentSellerEntity)
        })
      }));
    }
  };
  const getComplianceUploadModalStepTitle = () => {
    if (isTransactionalModalReadonlyMode) {
      return "";
    }
    if ((currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "New" || (currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "WaitingForFinalize") {
      return i18next.t("Transactions");
    } else if ((currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "MasterDataSetup") {
      return i18next.t("Master data");
    } else if ((currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "SaftCompliance") {
      return i18next.t("SAF-T");
    } else {
      return i18next.t("Finalized");
    }
  };
  const isPeriodOverdue = (cu) => {
    return false;
    if (!cu) {
      return false;
    }
    const period = moment(cu == null ? void 0 : cu.Period);
    return cu.Type === ComplianceUploadType.IOSS && !cu.IgnoreOverdue && period.add(2, "M").add(-6, "d") < moment();
  };
  const openUploadModal = (uploadId, readOnlyMode) => {
    const cu = searchResult.uploads.find((f) => f.Id === uploadId);
    setCurrentComplianceUpload(cu);
    setIsTransactionalModalReadonlyMode(readOnlyMode);
    setShowUploadModal(true);
  };
  const resendNotification = (complianceUploadId) => __async(void 0, null, function* () {
    yield props.resendNotification(complianceUploadId);
  });
  const regenerateDataVat = (complianceUploadId) => __async(void 0, null, function* () {
    yield props.regenerateDataVat(complianceUploadId);
  });
  const changeIgnoreOverdue = (complianceUploadId, isOverdue) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.changeIgnoreOverdue(complianceUploadId, isOverdue);
        notifySuccess(`Ignore data upload successfully ${isOverdue ? "enabled" : "disabled"}.`);
        loadComplianceUploads();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const isFinalizedStatusLike = (status) => {
    return status === "DataUploadFinalized" || status === "Error" || status === "SentToReturnGeneration" || status === "UploadProcessed" || status === "InsufficientCredit" || status === "DataVatGenerated";
  };
  const onVatTransactionsUpload = (file, ignoreWarnings) => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a, _b;
        dispatchLoading("inc");
        const uploadResult = yield props.uploadVatTransactions(currentComplianceUpload.Id, ignoreWarnings, file);
        if (uploadResult === void 0) {
          setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
            type: "Vat",
            simpleMessage: i18next.t("Upload Failed")
          }));
        } else {
          if ((_a = uploadResult.validationMessages) == null ? void 0 : _a.some((m) => m.validationCode === "TaxableBasisReportingExceededTheLimit" || m.validationCode === "OutOfPeriodTransactions")) {
            setCurrentComplianceUploadState(__spreadProps(__spreadValues({}, DEFAULT_COMPLIANCE_UPLOAD_STATE), {
              file,
              transactionType: "Vat",
              showWarningIgnoreButton: false
            }));
          } else {
            setCurrentComplianceUploadState(DEFAULT_COMPLIANCE_UPLOAD_STATE);
          }
          if (((_b = uploadResult.validationMessages) == null ? void 0 : _b.length) === 0) {
            notifySuccess(i18next.t("Uploaded successfully"));
            setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
              type: "None"
            }));
          } else {
            setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
              type: "Vat",
              validationMessages: uploadResult == null ? void 0 : uploadResult.validationMessages,
              numberOfTotalLinesWithError: uploadResult == null ? void 0 : uploadResult.numberOfTotalLinesWithError
            }));
          }
          loadComplianceUploads();
        }
      }),
      (error) => {
        setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
          type: "Vat",
          simpleMessage: i18next.t("Upload Failed")
        }));
        notifyErrorWithWarning(error);
      },
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const onAmazonTransactionsUpload = (file, sellerEntityId, ignoreWarnings) => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a, _b;
        dispatchLoading("inc");
        const uploadResult = yield props.uploadAmazonTransactions(currentComplianceUpload.Id, sellerEntityId, ignoreWarnings, file);
        if (uploadResult === void 0) {
          setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
            type: "Amazon",
            simpleMessage: i18next.t("Upload Failed"),
            sellerEntityId
          }));
        } else {
          if ((_a = uploadResult.validationMessages) == null ? void 0 : _a.some((m) => m.validationCode === "TaxableBasisReportingExceededTheLimit" || m.validationCode === "OutOfPeriodTransactions")) {
            setCurrentComplianceUploadState(__spreadProps(__spreadValues({}, DEFAULT_COMPLIANCE_UPLOAD_STATE), {
              file,
              transactionType: "Amazon",
              sellerEntityId,
              showWarningIgnoreButton: false
            }));
          } else {
            setCurrentComplianceUploadState(DEFAULT_COMPLIANCE_UPLOAD_STATE);
          }
          if (((_b = uploadResult.validationMessages) == null ? void 0 : _b.length) === 0) {
            notifySuccess(i18next.t("Uploaded successfully"));
            setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
              type: "None"
            }));
          } else {
            setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
              type: "Amazon",
              validationMessages: uploadResult == null ? void 0 : uploadResult.validationMessages,
              numberOfTotalLinesWithError: uploadResult == null ? void 0 : uploadResult.numberOfTotalLinesWithError,
              sellerEntityId
            }));
          }
          loadComplianceUploads();
        }
      }),
      (error) => {
        setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
          type: "Amazon",
          simpleMessage: i18next.t("Upload Failed"),
          sellerEntityId
        }));
        notifyErrorWithWarning(error);
      },
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const onIossFileUpload = (file, ignoreWarnings) => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a, _b;
        dispatchLoading("inc");
        const uploadResult = yield props.uploadIossTransactions(currentComplianceUpload.Id, ignoreWarnings, file);
        if (uploadResult === void 0) {
          setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
            type: "Ioss",
            simpleMessage: i18next.t("Upload Failed")
          }));
        } else {
          if ((_a = uploadResult.validationMessages) == null ? void 0 : _a.some((m) => m.validationCode === "TaxableBasisReportingExceededTheLimit" || m.validationCode === "OutOfPeriodTransactions")) {
            setCurrentComplianceUploadState(__spreadProps(__spreadValues({}, DEFAULT_COMPLIANCE_UPLOAD_STATE), {
              file,
              transactionType: "Ioss",
              showWarningIgnoreButton: true
            }));
          } else {
            setCurrentComplianceUploadState(DEFAULT_COMPLIANCE_UPLOAD_STATE);
          }
          if (((_b = uploadResult.validationMessages) == null ? void 0 : _b.length) === 0) {
            notifySuccess(i18next.t("Uploaded successfully"));
            setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
              type: "None"
            }));
          } else {
            setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
              type: "Ioss",
              validationMessages: uploadResult == null ? void 0 : uploadResult.validationMessages,
              numberOfTotalLinesWithError: uploadResult == null ? void 0 : uploadResult.numberOfTotalLinesWithError
            }));
          }
          loadComplianceUploads();
        }
      }),
      (error) => {
        setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
          type: "Ioss",
          simpleMessage: i18next.t("Upload Failed")
        }));
        notifyErrorWithWarning(error);
      },
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const onSaftTransactionsUpload = (file) => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a;
        dispatchLoading("inc");
        const uploadResult = yield props.uploadSaftTransactions(currentComplianceUpload.Id, file);
        if (uploadResult === void 0) {
          setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
            type: "Saft",
            simpleMessage: i18next.t("Upload Failed")
          }));
        } else {
          if (((_a = uploadResult.validationMessages) == null ? void 0 : _a.length) === 0) {
            notifySuccess(i18next.t("Uploaded successfully"));
            setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
              type: "None"
            }));
          } else {
            setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
              type: "Saft",
              validationMessages: uploadResult == null ? void 0 : uploadResult.validationMessages,
              numberOfTotalLinesWithError: uploadResult == null ? void 0 : uploadResult.numberOfTotalLinesWithError
            }));
          }
          loadComplianceUploads();
        }
      }),
      (error) => {
        setValidationDetails(__spreadProps(__spreadValues({}, DEFAULT_VALIDATION_DETAILS), {
          type: "Saft",
          simpleMessage: i18next.t("Upload Failed")
        }));
        notifyErrorWithWarning(error);
      },
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const finalizeUpload = (complianceUpload, confirmNoSaft, acknowledgeIncompleteSkus) => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a;
        dispatchLoading("inc");
        //!!! this if is added just because of KPMG-1578
        if ((defaultCompanyConfiguration == null ? void 0 : defaultCompanyConfiguration.amazonConfiguration) !== void 0 && !((_a = defaultCompanyConfiguration == null ? void 0 : defaultCompanyConfiguration.amazonConfiguration) == null ? void 0 : _a.refundMode)) {
          const defaultConfig = __spreadProps(__spreadValues({}, defaultCompanyConfiguration), {
            amazonConfiguration: __spreadProps(__spreadValues({}, defaultCompanyConfiguration.amazonConfiguration), {
              refundMode
            })
          });
          setDefaultCompanyConfiguration(defaultConfig);
          setAutoSaveCompanyConfiguration(false);
          yield props.updateDefaultCompanyConfiguration(defaultConfig, currentComplianceUpload == null ? void 0 : currentComplianceUpload.ClientId);
        }
        yield props.finalizeComplianceUpload(complianceUpload, confirmNoSaft, acknowledgeIncompleteSkus);
        notifySuccess(i18next.t("Successfully done"));
      }),
      (error) => {
        const firstError = extractFirstError(error);
        if ((firstError == null ? void 0 : firstError.code) === "ComplianceUploadFileMissing") {
          notifyWarning(i18next.t("There is no file uploaded for the current revision. Please reupload the file."));
        } else {
          notifyErrorWithWarning(error);
        }
      },
      () => {
        dispatchLoading("dec");
        setCloseUploadModelOnFinalize(true);
        loadComplianceUploads();
      }
    );
  };
  const addSellerEntity = () => {
    setCurrentSellerEntity({
      isAutomated: false,
      isEnabled: true,
      isAutomatedManuallyChecked: false
    });
    setSellerEntityEditMode(false);
    sellerEntityValidationContext.clearFieldValidations();
  };
  const editSellerEntity = (sellerEntity) => {
    setCurrentSellerEntity(__spreadProps(__spreadValues({}, sellerEntity), { isAutomatedManuallyChecked: true }));
    setSellerEntityEditMode(true);
    sellerEntityValidationContext.clearFieldValidations();
  };
  const removeSellerEntity = (sellerEntity) => {
    setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, defaultCompanyConfiguration), {
      amazonConfiguration: __spreadProps(__spreadValues({}, defaultCompanyConfiguration.amazonConfiguration), {
        sellerEntities: defaultCompanyConfiguration.amazonConfiguration.sellerEntities.filter((se) => se.id !== sellerEntity.id)
      })
    }));
  };
  const onSortChanged = (e) => {
    if (props.isAdmin) {
      const OrderByValue = e.detail.orderBy;
      const OrderDirValue = e.detail.orderDir;
      setGridState(__spreadProps(__spreadValues({}, gridState), {
        orderBy: OrderByValue,
        orderDir: OrderDirValue
      }));
    }
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    if (props.isAdmin) {
      const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
        pageIndex: 0,
        appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
      });
      setGridState(newGridFilter);
    }
  };
  const reconfirm = (complianceUploadId) => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const newStatus = yield props.reconfirmUpload(complianceUploadId);
        setSearchResult({
          total: searchResult.total,
          uploads: searchResult.uploads.map((u) => {
            if (u.Id === complianceUploadId) {
              u.Status = newStatus;
            }
            return u;
          })
        });
        notifySuccess(i18next.t("Status updated to: ") + getStatusLabel(newStatus));
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  };
  const cancel = (complianceUploadId) => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const newStatus = yield props.cancelUpload(complianceUploadId);
        setSearchResult({
          total: searchResult.total,
          uploads: searchResult.uploads.map((u) => {
            if (u.Id === complianceUploadId) {
              u.Status = newStatus;
            }
            return u;
          })
        });
        notifySuccess(i18next.t("Upload successfully canceled"));
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  };
  const [gridState, setGridState] = useQueryGridState(
    {
      pageIndex: 0,
      appliedFilters: DEFAULT_FILTERS,
      pageSize: 20,
      orderBy: "Period",
      orderDir: "desc"
    },
    "ugs"
  );
  const [loading, dispatchLoading] = useLoadingReducer();
  const [searchResult, setSearchResult] = useState(void 0);
  const [showAll, setShowAll] = useState(false);
  const [currentComplianceUpload, setCurrentComplianceUpload] = useState(void 0);
  const [currentComplianceUploadState, setCurrentComplianceUploadState] = useState(DEFAULT_COMPLIANCE_UPLOAD_STATE);
  const [hasAnyIncompleteSKUs, setHasAnyIncompleteSKUs] = useState(void 0);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [closeUploadModelOnFinalize, setCloseUploadModelOnFinalize] = useState(false);
  const [isTransactionalModalReadonlyMode, setIsTransactionalModalReadonlyMode] = useState(false);
  const [validationDetails, setValidationDetails] = useState(void 0);
  const [defaultCompanyConfiguration, setDefaultCompanyConfiguration] = useState(void 0);
  const [refundMode, setRefundMode] = useState("DeductAll");
  const [autoSaveCompanyConfiguration, setAutoSaveCompanyConfiguration] = useState(true);
  const [companyServices, setCompanyServices] = useState(void 0);
  const [currentSellerEntity, setCurrentSellerEntity] = useState(void 0);
  const [sellerEntityEditMode, setSellerEntityEditMode] = useState(false);
  const sellerEntityValidationContext = useValidationContext(new SellerEntityValidator(defaultCompanyConfiguration, sellerEntityEditMode));
  const [dispatchIfSellerEntityValid, setDispatchIfSellerEntityValid] = useState(false);
  const periodsDataSource = useMemo(() => generateMonthlyPeriods(), []);
  const confirmModal = useConfirmModal();
  useEffect(() => __async(void 0, null, function* () {
    if (!props.isAdmin) {
      yield getDefaultCompanyConfiguration();
      yield getCompanyDetail();
    }
  }), []);
  useEffect(() => __async(void 0, null, function* () {
    if (defaultCompanyConfiguration !== void 0) {
      if (autoSaveCompanyConfiguration) {
        yield props.updateDefaultCompanyConfiguration(defaultCompanyConfiguration, currentComplianceUpload == null ? void 0 : currentComplianceUpload.ClientId);
        yield getDefaultCompanyConfiguration(currentComplianceUpload == null ? void 0 : currentComplianceUpload.ClientId);
      } else {
        setAutoSaveCompanyConfiguration(true);
      }
    }
  }), [defaultCompanyConfiguration]);
  useEffect(() => __async(void 0, null, function* () {
    loadComplianceUploads();
  }), [showAll, gridState]);
  useEffect(resetUploadModalState, [showUploadModal]);
  useEffect(() => __async(void 0, null, function* () {
    if (props.isAdmin && currentComplianceUpload !== void 0) {
      if (currentComplianceUpload.Type !== "IOSS") {
        yield getDefaultCompanyConfiguration(currentComplianceUpload.ClientId);
      }
      yield getCompanyDetail(currentComplianceUpload.ClientId);
    }
    if (currentComplianceUpload !== void 0) {
      if (currentComplianceUpload.Type === "VAT" && currentComplianceUpload.Status === "MasterDataSetup") {
        setHasAnyIncompleteSKUs("DontKnow");
        yield checkIncompleteSkus();
      }
    }
    if ((currentComplianceUpload === void 0 || currentComplianceUpload.Status === "DataUploadFinalized") && closeUploadModelOnFinalize && showUploadModal) {
      setCloseUploadModelOnFinalize(false);
      setShowUploadModal(false);
    }
  }), [currentComplianceUpload]);
  useEffect(() => __async(void 0, null, function* () {
    if (dispatchIfSellerEntityValid) {
      setDispatchIfSellerEntityValid(false);
      if (sellerEntityValidationContext.validationResult.isValid()) {
        saveSellerEntity();
        loadComplianceUploads();
      }
    }
  }), [sellerEntityValidationContext.validationResult]);
  useEffect(() => {
    if (currentSellerEntity !== void 0) {
      sellerEntityValidationContext.validate(currentSellerEntity);
    }
  }, [currentSellerEntity]);
  const htmlGrid = () => {
    var _a;
    const columns = (props.isAdmin ? [
      {
        field: ComplianceUploadFieldNames.ClientId,
        columnClass: "w-16",
        label: "ID",
        sortable: props.isAdmin,
        filterable: props.isAdmin,
        filterDescriptor: { type: "string" },
        columnType: "string"
      },
      {
        field: ComplianceUploadFieldNames.ClientName,
        label: "Client Name",
        sortable: props.isAdmin,
        filterable: props.isAdmin,
        filterDescriptor: { type: "string" },
        columnType: "string"
      }
    ] : []).concat([
      {
        field: ComplianceUploadFieldNames.Period,
        label: i18next.t("Period"),
        filterable: props.isAdmin,
        sortable: props.isAdmin,
        filterDescriptor: { type: "select", multiSelect: true, dataSource: periodsDataSource },
        columnType: "enum"
      },
      {
        field: ComplianceUploadFieldNames.Type,
        label: i18next.t("Type"),
        filterable: props.isAdmin,
        sortable: props.isAdmin,
        filterDescriptor: { type: "select", multiSelect: true, dataSource: COMPLIANCE_UPLOAD_TYPES },
        columnType: "enum"
      },
      {
        field: ComplianceUploadFieldNames.Status,
        label: i18next.t("Status"),
        filterable: props.isAdmin,
        sortable: props.isAdmin,
        filterDescriptor: {
          type: "select",
          dataSource: Statuses
        },
        columnType: "enum"
      },
      {
        field: ComplianceUploadFieldNames.Actions,
        label: ""
      }
    ]);
    const vm = {
      columns,
      data: searchResult.uploads,
      // eslint-disable-next-line complexity
      cellTemplate: (index, field) => {
        const item = searchResult.uploads[index];
        if (field === ComplianceUploadFieldNames.ClientId) {
          return item.ClientId.toString();
        } else if (field === ComplianceUploadFieldNames.ClientName) {
          return item.ClientName;
        } else if (field === ComplianceUploadFieldNames.Period) {
          return moment(item.Period, "MMM-YYYY", "en").locale(moment.locale()).format("MMM-YYYY");
        } else if (field === ComplianceUploadFieldNames.Type) {
          return item.Type;
        } else if (field === ComplianceUploadFieldNames.Status) {
          const statusLabel = getStatusLabel(item.Status);
          return props.isAdmin && statusLabel === "Error" && item.ErrorReason !== void 0 ? html`<span title=${`${item.ErrorReason} (${item.ErrorMessage})`}>${statusLabel}</span>` : statusLabel;
        } else if (field === ComplianceUploadFieldNames.Actions) {
          return html` <div class="space-x-2">
						${!isFinalizedStatusLike(item.Status) && (!props.isAdmin || props.permissionManager.hasPermission("Compliance_Upload_Modify")) ? html` <button
									class="btn btn-sm btn-primary"
									?disabled="${item.IsOverdue !== void 0 && item.IgnoreOverdue !== void 0 && item.IsOverdue && !item.IgnoreOverdue}"
									@click=${() => openUploadModal(item.Id, false)}
							  >
									${i18next.t("Update")}
							  </button>` : ""}
						${!props.isAdmin && item.IsOverdue !== void 0 && item.IgnoreOverdue !== void 0 && item.IsOverdue && !item.IgnoreOverdue ? html` <dc-tooltip .label=${i18next.t("Upload is forbidden for overdue periods. Please contact your account manager.")}></dc-tooltip>` : ""}
						${item.Status === "InsufficientCredit" && (!props.isAdmin || props.permissionManager.hasPermission("Compliance_Upload_Modify")) ? html` <button class="btn btn-sm btn-primary" @click=${() => reconfirm(item.Id)}>${i18next.t("Reconfirm Upload")}</button>` : ""}
						${props.isAdmin && item.Status === "InsufficientCredit" && props.permissionManager.hasPermission("Compliance_Upload_Modify") ? html` <button class="btn btn-sm btn-primary" @click=${() => cancel(item.Id)}>${i18next.t("Cancel")}</button>` : ""}
						${props.isAdmin && item.CompanyConfigSnapshot !== void 0 ? html` <button class="btn btn-sm btn-primary" @click=${() => openUploadModal(item.Id, true)}>${i18next.t("View")}</button>` : ""}
						${props.isAdmin && isFinalizedStatusLike(item.Status) ? html` <button class="btn btn-sm btn-primary" @click=${() => props.generateTextFile(item.Id)}>TXT</button>` : ""}
						${props.isAdmin && item.Status === "SentToReturnGeneration" && props.permissionManager.hasPermission("Compliance_Upload_Modify") ? html` <button class="btn btn-sm btn-primary" @click=${() => resendNotification(item.Id)}>${i18next.t("Resend")}</button>
									<button class="btn btn-sm btn-primary" @click=${() => regenerateDataVat(item.Id)}>${i18next.t("Regenerate")}</button>` : ""}
						${props.isAdmin && item.Status === "Error" && props.permissionManager.hasPermission("Compliance_Upload_Modify") ? html`${item.ErrorReason === "UnableToSentToReturnGeneration" ? html` <button class="btn btn-sm btn-primary" @click=${() => resendNotification(item.Id)}>${i18next.t("Resend")}</button>` : ""} <button class="btn btn-sm btn-primary" @click=${() => regenerateDataVat(item.Id)}>${i18next.t("Regenerate")}</button>` : ""}
						${props.isAdmin && item.Type === "IOSS" && item.IsOverdue && props.permissionManager.hasPermission("Compliance_Upload_Modify") ? html` <button class="btn btn-sm btn-primary" @click=${() => changeIgnoreOverdue(item.Id, !item.IgnoreOverdue)}>
									${i18next.t(item.IgnoreOverdue ? "Disable" : "Enable")}
							  </button>` : ""}
					</div>`;
        } else {
          return "";
        }
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: searchResult.total
      }
    };
    return html`
			<div>${templateTitle(i18next.t("Data Upload"))}</div>
			<div>
				<div class="flex space-x-4 my-4">
					${props.isAdmin ? html` <div class="whitespace-no-wrap">
								<button class="btn btn-primary" @click=${() => props.exportComplianceUploads(__spreadProps(__spreadValues({}, gridState), { pageIndex: 0, pageSize: searchResult == null ? void 0 : searchResult.total }), showAll)}>
									Export Table as CSV
								</button>
						  </div>` : ""}
					<div class="flex space-x-4">
						<div class="whitespace-no-wrap">
							<button class="btn btn-primary text-white">
								<a href="/filetemplates/IOSS_Source Template_v1.2.xlsx" target="_blank" rel="noopener" download="IOSS_Template.xlsx">${i18next.t("Download IOSS Template")}</a>
							</button>
						</div>
						<div class="whitespace-no-wrap">
							<button class="btn btn-primary">
								<a href="/filetemplates/Transactional_Data_KPMG_Template.xlsx" target="_blank" rel="noopener" download="VAT_Template.xlsx">${i18next.t("Download VAT/OSS Template")}</a>
							</button>
						</div>
					</div>
				</div>
				<div class="flex space-x-4 my-4">
					<dc-checkbox2
						.checked=${showAll}
						.label=${i18next.t("Show All")}
						@change=${(e) => {
      setShowAll(e.detail.checked);
    }}
					></dc-checkbox2>
					${((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html` <div class="whitespace-no-wrap">
								<button
									class="btn btn-primary"
									@click=${() => setGridState(__spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: DEFAULT_FILTERS
    }))}
								>
									Reset filters
								</button>
						  </div>` : ""}
				</div>
			</div>
			<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>
		`;
  };
  const htmlSellerEntities = (modalEmbedded, fromSnapshotAndReadOnly) => {
    var _a;
    const companyConfiguration = fromSnapshotAndReadOnly ? currentComplianceUpload.CompanyConfigSnapshot : defaultCompanyConfiguration;
    return html`
			<div class="mt-4">
				<div><h3 class="font-bold">${i18next.t("Seller Accounts")}</h3></div>
				<div class="mt-4">
					<div class="grid grid-cols-${!modalEmbedded || modalEmbedded && !fromSnapshotAndReadOnly ? "5" : "2"}">
						${((_a = companyConfiguration == null ? void 0 : companyConfiguration.amazonConfiguration) == null ? void 0 : _a.sellerEntities.length) > 0 ? companyConfiguration.amazonConfiguration.sellerEntities.map(
      (sellerEntity) => {
        var _a2, _b, _c, _d, _e;
        return html` <div class="py-2 border-b col-span-${!modalEmbedded ? "4" : fromSnapshotAndReadOnly ? 1 : 2}">
												${sellerEntity.id}, ${sellerEntity.name},
												${sellerEntity.isEnabled ? i18next.t("Enabled") : i18next.t("Disabled")}${!modalEmbedded ? html`, ${i18next.t("Automation")}: ${sellerEntity.isAutomated ? i18next.t("On") : i18next.t("Off")}` : ""}
											</div>
											${!modalEmbedded || !fromSnapshotAndReadOnly ? html` <div class="py-2 border-b ${!modalEmbedded ? "flex justify-between" : ""}">
														<div class="flex space-x-2">
															<span class="edit-icon-container" @click=${() => editSellerEntity(sellerEntity)}> ${editIcon()} </span>
															<span
																class="remove-icon-container"
																@click=${() => __async(void 0, null, function* () {
          if (yield confirmModal.confirm(i18next.t("Are you sure to delete?"))) {
            removeSellerEntity(sellerEntity);
          }
        })}
															>
																${removeIcon()}
															</span>
														</div>
												  </div>` : ""}
											${modalEmbedded ? html` <div class="py-2 border-b align-right col-span-${fromSnapshotAndReadOnly ? "1" : "2"}">
														<div
															class=${validationDetails.type === "Amazon" && ((_a2 = validationDetails.validationMessages) == null ? void 0 : _a2.length) > 0 && validationDetails.sellerEntityId === sellerEntity.id ? "bg-red-200 p-1" : "p-1"}
														>
															<dc-fileupload
																.label=${i18next.t("Upload Amazon Transactions")}
																.fileReference=${(_c = (_b = currentComplianceUpload == null ? void 0 : currentComplianceUpload.AmazonFiles) == null ? void 0 : _b.find((af) => af.SellerEntity.Id === sellerEntity.id)) == null ? void 0 : _c.Reference}
																.fileName=${(_e = (_d = currentComplianceUpload == null ? void 0 : currentComplianceUpload.AmazonFiles) == null ? void 0 : _d.find((af) => af.SellerEntity.Id === sellerEntity.id)) == null ? void 0 : _e.Name}
																.accept=${AMAZON_TRANSACTION_ACCEPT_LIST}
																.linkGenerator=${(reference) => linkGenerator(currentComplianceUpload.Id, reference)}
																.readonly=${fromSnapshotAndReadOnly || !sellerEntity.isEnabled}
																@upload=${(event) => onAmazonTransactionsUpload(event.detail.files[0], sellerEntity.id, false)}
															></dc-fileupload>
														</div>
												  </div>` : ""}`;
      }
    ) : ""}
					</div>
					<div class="mt-4">
						${currentSellerEntity ? html` <div class="space-y-4">
									<dc-input
										.label=${i18next.t("Id")}
										.value=${currentSellerEntity.id}
										.readonly=${sellerEntityEditMode}
										.validationMessage=${sellerEntityValidationContext.getValidationMessage("Id")}
										@change=${(e) => {
      sellerEntityValidationContext.validateField("Id");
      setCurrentSellerEntity(__spreadProps(__spreadValues({}, currentSellerEntity), { id: e.detail.value }));
    }}
									></dc-input>
									<dc-input
										.label=${i18next.t("Name")}
										.value=${currentSellerEntity.name}
										.validationMessage=${sellerEntityValidationContext.getValidationMessage("Name")}
										@change=${(e) => {
      sellerEntityValidationContext.validateField("Name");
      setCurrentSellerEntity(__spreadProps(__spreadValues({}, currentSellerEntity), { name: e.detail.value }));
    }}
									></dc-input>
									<dc-input
										.label=${i18next.t("MWSAuthToken")}
										.value=${currentSellerEntity.mwsAuthToken}
										.validationMessage=${sellerEntityValidationContext.getValidationMessage("MwsAuthToken")}
										@change=${(e) => {
      const newMwsToken = e.detail.value;
      let newIsAutomated = currentSellerEntity.isAutomated;
      if (!currentSellerEntity.isAutomatedManuallyChecked && !isEmpty(newMwsToken)) {
        newIsAutomated = true;
      }
      sellerEntityValidationContext.validateField("MwsAuthToken");
      setCurrentSellerEntity(__spreadProps(__spreadValues({}, currentSellerEntity), {
        mwsAuthToken: e.detail.value,
        isAutomated: newIsAutomated
      }));
    }}
									></dc-input>
									<dc-checkbox
										.checked=${currentSellerEntity.isEnabled}
										.label=${"Enabled"}
										@change=${(e) => {
      setCurrentSellerEntity(__spreadProps(__spreadValues({}, currentSellerEntity), {
        isEnabled: e.detail.checked
      }));
    }}
									></dc-checkbox>
									<dc-checkbox
										.checked=${currentSellerEntity.isAutomated}
										.label=${"Automated"}
										@change=${(e) => {
      setCurrentSellerEntity(__spreadProps(__spreadValues({}, currentSellerEntity), {
        isAutomated: e.detail.checked,
        isAutomatedManuallyChecked: true
      }));
    }}
									></dc-checkbox>
									<div class="flex space-x-4 mt-4">
										<div class="flex space-x-4"><span>${i18next.t("Read our guide how to automate transactions with Amazon:")}</span>${downloadPDFIcon()}</div>
									</div>
									<div class="flex mt-4 space-x-4">
										<button
											class="btn btn-primary"
											@click=${() => {
      setCurrentSellerEntity(void 0);
    }}
										>
											${i18next.t("Cancel")}
										</button>
										<button
											class="btn btn-primary"
											@click=${() => {
      setCurrentSellerEntity(__spreadValues({}, currentSellerEntity));
      sellerEntityValidationContext.validateFields(getAllSellerEntityFields());
      setDispatchIfSellerEntityValid(true);
    }}
										>
											${i18next.t("Save")}
										</button>
									</div>
							  </div>` : html`${!fromSnapshotAndReadOnly ? html` <button class="btn btn-primary" @click=${addSellerEntity}>${i18next.t("Add")}</button>` : ""}`}
					</div>
				</div>
			</div>
		`;
  };
  const htmlConfigurationTemplate = (modalEmbedded, fromSnapshotAndReadOnly) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const companyConfiguration = fromSnapshotAndReadOnly ? currentComplianceUpload.CompanyConfigSnapshot : defaultCompanyConfiguration;
    return html` <div class="my-4">
			${showVatConfigurationLoading() ? `${i18next.t("Loading")}...` : ""}
			${showVatConfiguration() ? html`
						<div>
							<div class="font-bold text-xl">${i18next.t("VAT Compliance Settings")}</div>
						</div>
						<div class="mt-4 flex justify-between">
							<div>
								<span class="form-label">${i18next.t("Use KPMG template to upload data")}</span>
								<dc-toggle
									.state=${companyConfiguration.useKpmgTemplate ? "Yes" : "No"}
									.readonly=${fromSnapshotAndReadOnly}
									@change=${(e) => __async(void 0, null, function* () {
      const useKpmgTemplate = e.detail.state === "Yes";
      setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, defaultCompanyConfiguration), {
        useKpmgTemplate
      }));
      if (!useKpmgTemplate && (currentComplianceUpload == null ? void 0 : currentComplianceUpload.Id) && currentComplianceUpload.Type === "VAT") {
        yield props.removeUploadedVatTransactions(currentComplianceUpload.Id);
        loadComplianceUploads();
      }
    })}
								></dc-toggle>
							</div>
							${modalEmbedded && companyConfiguration.useKpmgTemplate ? html` <div class=${validationDetails.type === "Vat" ? "bg-red-200 p-1" : "p-1"}>
										<dc-fileupload
											.label=${i18next.t("Upload VAT Transactions")}
											.fileReference=${(_a = currentComplianceUpload == null ? void 0 : currentComplianceUpload.KpmgTemplateFile) == null ? void 0 : _a.Reference}
											.fileName=${(_b = currentComplianceUpload == null ? void 0 : currentComplianceUpload.KpmgTemplateFile) == null ? void 0 : _b.Name}
											.accept=${ONLY_XLSX_ACCEPT_LIST}
											.linkGenerator=${(reference) => linkGenerator(currentComplianceUpload.Id, reference)}
											.readonly=${fromSnapshotAndReadOnly}
											@upload=${(event) => onVatTransactionsUpload(event.detail.files[0], false)}
										></dc-fileupload>
								  </div>` : ""}
						</div>
						<hr class="mt-4" />
						<div class="mt-4">
							<span class="form-label">${i18next.t("Use Amazon's template to upload data")}</span>
							<dc-toggle
								.state=${companyConfiguration.useAmazonTemplate ? "Yes" : "No"}
								.readonly=${fromSnapshotAndReadOnly}
								@change=${(e) => __async(void 0, null, function* () {
      const useAmazonTemplate = e.detail.state === "Yes";
      setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, defaultCompanyConfiguration), {
        useAmazonTemplate
      }));
      if (!useAmazonTemplate && (currentComplianceUpload == null ? void 0 : currentComplianceUpload.Id) && currentComplianceUpload.Type === "VAT") {
        yield props.removeUploadedAmazonTransactions(currentComplianceUpload.Id);
        loadComplianceUploads();
      }
    })}
							></dc-toggle>
						</div>
						${companyConfiguration.useAmazonTemplate ? html` <div>
									${(currentComplianceUpload == null ? void 0 : currentComplianceUpload.IsAmazonInvoicingServiceOptional) === true ? html` <div class="mt-4">
												<label class="form-label">${i18next.t("Amazon invoicing services before 2021 July")}</label>
												<div>
													<dc-toggle
														.state=${mapBooleanToState((_c = companyConfiguration == null ? void 0 : companyConfiguration.amazonConfiguration) == null ? void 0 : _c.useAmazonRates)}
														.readonly=${fromSnapshotAndReadOnly}
														@change=${(e) => {
      const useAmazonRates = e.detail.state === "Yes";
      setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, defaultCompanyConfiguration), {
        amazonConfiguration: __spreadProps(__spreadValues({}, defaultCompanyConfiguration == null ? void 0 : defaultCompanyConfiguration.amazonConfiguration), {
          useAmazonRates
        })
      }));
    }}
													></dc-toggle>
												</div>
												<div class="text-sm italic mt-4 text-gray-600">
													${i18next.t(
      "amazon_vat_rates_warning",
      "We will apply the VAT rates that is provided in the amazon data when available / We will use our default rates. After July-2021 KPMG must use the Amazon provided rates."
    )}
												</div>
										  </div>` : ""}
									${htmlSellerEntities(modalEmbedded, fromSnapshotAndReadOnly)}
									<div class="mt-4">
										<label class="form-label">${i18next.t("Mode of Refund")}</label>
										<div class="flex items-center space-x-4">
											<dc-select
												.selectedValues=${[(_e = (_d = companyConfiguration == null ? void 0 : companyConfiguration.amazonConfiguration) == null ? void 0 : _d.refundMode) != null ? _e : refundMode]}
												.readonly=${fromSnapshotAndReadOnly || ((_f = companyConfiguration == null ? void 0 : companyConfiguration.amazonConfiguration) == null ? void 0 : _f.refundMode) !== void 0 || true}
												.dataSource=${REFUND_MODES}
												@change=${(e) => {
      setRefundMode(e.detail.selectedValue);
    }}
											></dc-select>
											<div
												class=${refundMode === void 0 || true ? "hidden" : ""}
											>
												<button
													class="btn btn-primary"
													@click=${() => __async(void 0, null, function* () {
      if (yield confirmModal.confirm(
        i18next.t(
          "refund-mode-confirm",
          "Once you confirmed, you cannot modify the mode of refund. The selected option will be the default setting for future VAT reporting."
        )
      )) {
        setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, defaultCompanyConfiguration), {
          amazonConfiguration: __spreadProps(__spreadValues({}, defaultCompanyConfiguration == null ? void 0 : defaultCompanyConfiguration.amazonConfiguration), {
            refundMode
          })
        }));
        setRefundMode(void 0);
      }
    })}
												>
													${i18next.t("Confirm")}
												</button>
											</div>
										</div>
									</div>
									<div>
										<span class="text-sm italic mt-4 text-gray-600">${REFUND_LABELS.get((_h = (_g = companyConfiguration == null ? void 0 : companyConfiguration.amazonConfiguration) == null ? void 0 : _g.refundMode) != null ? _h : refundMode)}</span>
									</div>
							  </div>` : ""}
				  ` : ""}
		</div>`;
  };
  const htmlTransactionUploadStep = (fromSnapshotAndReadOnly) => {
    var _a, _b, _c, _d;
    return html` <div class="w-full h-full">
			<div class="md:grid md:grid-cols-2 md:gap-4">
				<div>
					${(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Type) === "IOSS" ? html` <div class=${validationDetails.type === "Ioss" ? "bg-red-200 p-1" : "p-1"}>
								<dc-fileupload
									.label=${i18next.t("Upload IOSS Transactions")}
									.fileReference=${(_a = currentComplianceUpload == null ? void 0 : currentComplianceUpload.IossFile) == null ? void 0 : _a.Reference}
									.fileName=${(_b = currentComplianceUpload == null ? void 0 : currentComplianceUpload.IossFile) == null ? void 0 : _b.Name}
									.accept=${ONLY_XLSX_ACCEPT_LIST}
									.linkGenerator=${(reference) => linkGenerator(currentComplianceUpload.Id, reference)}
									.readonly=${fromSnapshotAndReadOnly}
									@upload=${(event) => onIossFileUpload(event.detail.files[0], false)}
								></dc-fileupload>
						  </div>` : ""}
					${(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Type) === "VAT" ? htmlConfigurationTemplate(true, fromSnapshotAndReadOnly) : ""}
				</div>
				<div>
					<div>
						${loading.count > 0 ? `${i18next.t("Processing")}...` : html` <div>
										${(validationDetails == null ? void 0 : validationDetails.numberOfTotalLinesWithError) > 0 ? html` <div class="my-2 text-red-400 font-semibold">
													${i18next.t("total-number-of-incorrect-lines", "Total number of incorrect lines: {{num}}", {
      num: validationDetails.numberOfTotalLinesWithError
    })}
											  </div>` : ""}
										${validationDetails.simpleMessage ? html` <div class="text-red-400 mt-2">${validationDetails.simpleMessage}</div>` : ""}
										${((_c = validationDetails.validationMessages) == null ? void 0 : _c.length) > 0 ? html` <ul class="list-disc ml-4">
													${(_d = validationDetails.validationMessages) == null ? void 0 : _d.map((msg) => html` <li class="text-red-400">${mapValidationMessage(msg, validationDetails.type)}</li>`)}
											  </ul>` : ""}
									</div>
									${currentComplianceUploadState.showWarningIgnoreButton ? html` <div class="mt-4">
												<button
													class="btn btn-primary"
													@click=${() => __async(void 0, null, function* () {
      if (currentComplianceUploadState.transactionType === "Ioss") {
        onIossFileUpload(currentComplianceUploadState.file, true);
      } else if (currentComplianceUploadState.transactionType === "Vat") {
        onVatTransactionsUpload(currentComplianceUploadState.file, true);
      } else if (currentComplianceUploadState.transactionType === "Amazon") {
        onAmazonTransactionsUpload(currentComplianceUploadState.file, currentComplianceUploadState.sellerEntityId, true);
      }
    })}
												>
													${i18next.t("Ignore and upload")}
												</button>
										  </div>` : ""}`}
					</div>
				</div>
			</div>
			<div>
				<div class="mt-8 flex space-x-4">
					${!fromSnapshotAndReadOnly ? html` <button class="btn btn-primary" ?disabled=${!confirmButtonEnabled()} @click=${() => __async(void 0, null, function* () {
      return finalizeUpload(currentComplianceUpload);
    })}>
								${i18next.t("Confirm and Generate Return")}
						  </button>` : ""}
					<button class="btn btn-primary" @click=${() => __async(void 0, null, function* () {
      return setShowUploadModal(false);
    })}>${i18next.t("Cancel")}</button>
				</div>
			</div>
		</div>`;
  };
  const htmlSaftConfirmStep = () => {
    return html` <div>
			<div>
				<div>
					${props.isAdmin ? unsafeHTML(i18next.t("saft-acknowledgement-admin", "I acknowledge to have added all SAF-T related information to the uploaded KPMG Template.")) : unsafeHTML(
      i18next.t(
        "saft-acknowledgement-web",
        "I acknowledge to have added all SAF-T related information to the uploaded KPMG Template. If you have any questions please check our {{- link_start}}Guides and Tutorials{{- link_end}}.",
        {
          link_start: `<a class=underline text-brand-primary cursor-pointer href=${props.getRouteByKey("guides-and-tutorials")}>`,
          link_end: "</a>"
        }
      )
    )}
				</div>
			</div>
			<div>
				<div class="mt-8 flex space-x-4">
					<button class="btn btn-primary" @click=${() => __async(void 0, null, function* () {
      return finalizeUpload(currentComplianceUpload);
    })}>${i18next.t("Acknowledge")}</button>
				</div>
			</div>
		</div>`;
  };
  const htmlSaftStep = () => {
    var _a, _b;
    return html` <div>
			<div>
				<div>${i18next.t("You are required to declare SAF-T data. You can download the template below and with adding necessary fields please upload it back.")}</div>
				<div class="flex justify-around mt-8">
					<div class="space-y-4">
						<div class="flex justify-around">
							<a
								class="link"
								href="javascript:void(0);"
								@click=${() => __async(void 0, null, function* () {
      try {
        dispatchLoading("inc");
        yield props.generateSaftTemplate(currentComplianceUpload.Id);
      } finally {
        dispatchLoading("dec");
      }
    })}
								>${i18next.t("Download SAF-T Template")}</a
							>
						</div>
						<div class="flex justify-around">
							<dc-fileupload
								.label=${i18next.t("Upload SAF-T data")}
								.fileReference=${(_a = currentComplianceUpload == null ? void 0 : currentComplianceUpload.SaftFile) == null ? void 0 : _a.Reference}
								.fileName=${(_b = currentComplianceUpload == null ? void 0 : currentComplianceUpload.SaftFile) == null ? void 0 : _b.Name}
								.accept=${ONLY_XLSX_ACCEPT_LIST}
								.linkGenerator=${(reference) => linkGenerator(currentComplianceUpload.Id, reference)}
								@upload=${(event) => onSaftTransactionsUpload(event.detail.files[0])}
							></dc-fileupload>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="mt-8 flex space-x-4">
					${!confirmButtonEnabled() ? html` <button class="btn btn-primary" @click=${() => __async(void 0, null, function* () {
      return finalizeUpload(currentComplianceUpload, true);
    })}>${i18next.t("Confirm without adding SAF-T data")}</button>` : ""}
					<button class="btn btn-primary" ?disabled=${!confirmButtonEnabled()} @click=${() => __async(void 0, null, function* () {
      return finalizeUpload(currentComplianceUpload);
    })}>
						${i18next.t("Confirm and Generate Return")}
					</button>
					<button class="btn btn-primary" @click=${() => __async(void 0, null, function* () {
      return setShowUploadModal(false);
    })}>${i18next.t("Cancel")}</button>
				</div>
			</div>
		</div>`;
  };
  const htmlMasterDataStep = () => {
    return html` <div>
			<div>
				${hasAnyIncompleteSKUs === "Yes" ? i18next.t(
      "master-data-is-incomplete",
      "Master data is incomplete, some SKUs don't have price. Please setup or confirm ignoring setting up. If you do not add these missing prices, we will not be able to include the value of movements of own goods in the VAT returns and the ESL reports, which can trigger tax audits or lead to penalties, VIES deactivation or deregistration. You are solely responsible for the consequences. We hereby ask you to complete all missing values before proceeding."
    ) : ""}
				${hasAnyIncompleteSKUs === "No" ? i18next.t("Master data is complete. Please submit data.") : ""} ${hasAnyIncompleteSKUs === "DontKnow" ? `${i18next.t("Loading")}...` : ""}
			</div>
			<div>
				<div class="mt-8 flex space-x-4">
					${hasAnyIncompleteSKUs === "Yes" && !props.isAdmin ? html` <button
								class="btn btn-primary"
								@click=${() => __async(void 0, null, function* () {
      navigateTo(props.getRouteByKey("admin/master-data/amazon-skus"));
    })}
						  >
								${i18next.t("Go to Master Data/Amazon SKUs")}
						  </button>` : ""}
					${hasAnyIncompleteSKUs === "Yes" ? html` <button class="btn btn-primary" ?disabled=${confirmButtonEnabled()} @click=${() => __async(void 0, null, function* () {
      return finalizeUpload(currentComplianceUpload, void 0, true);
    })}>
								${i18next.t("Confirm without Master Data setup")}
						  </button>` : ""}
					${hasAnyIncompleteSKUs === "No" ? html` <button class="btn btn-primary" ?disabled=${!confirmButtonEnabled()} @click=${() => __async(void 0, null, function* () {
      return finalizeUpload(currentComplianceUpload);
    })}>
								${i18next.t("Confirm and Generate Return")}
						  </button>` : ""}

					<button class="btn btn-primary" @click=${() => __async(void 0, null, function* () {
      return setShowUploadModal(false);
    })}>${i18next.t("Cancel")}</button>
				</div>
			</div>
		</div>`;
  };
  const htmlFinalizedStep = () => {
    return html`Finalized`;
  };
  const htmlUploadModal = () => {
    var _a;
    return html` <dc-modal
			.visible=${showUploadModal}
			@close=${() => setShowUploadModal(false)}
			.header=${`Data Upload ${props.isAdmin ? `| ${currentComplianceUpload == null ? void 0 : currentComplianceUpload.ClientName} (${currentComplianceUpload == null ? void 0 : currentComplianceUpload.ClientId}) ` : ""}| ${currentComplianceUpload == null ? void 0 : currentComplianceUpload.Period} | ${getComplianceUploadModalStepTitle()}`}
			.content=${html` <div>
				${isPeriodOverdue(currentComplianceUpload) ? html` <div>
							${i18next.t(
      "disabled_ioss_upload_for_this_month",
      "You did not upload transaction data for this month. As it is not possible to submit IOSS returns retrospectively, the upload is not possible anymore. If you had sales in this month that must be reported via IOSS, you must add these transactions to the source data of the current IOSS return."
    )}
					  </div>` : isTransactionalModalReadonlyMode ? htmlTransactionUploadStep(true) : html`${(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "New" || (currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "WaitingForFinalize" ? htmlTransactionUploadStep(false) : ""}
					  ${(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "SaftCompliance" ? html`${((_a = currentComplianceUpload == null ? void 0 : currentComplianceUpload.AmazonFiles) == null ? void 0 : _a.length) > 0 ? htmlSaftStep() : htmlSaftConfirmStep()}` : ""}
					  ${(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "MasterDataSetup" ? htmlMasterDataStep() : ""} ${isFinalizedStatusLike(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) ? htmlFinalizedStep() : ""}`}
			</div>`}
		>
		</dc-modal>`;
  };
  const mainTemplate = html` <div class="my-2 mx-8 mb-16 mt-8 flex justify-between xl:w-3/4">
		<div class="grid grid-cols-1 md:grid-cols-3 mb-8 gap-8 w-full">
			<div class="${SHOW_CONFIGURATION_TEMPLATE ? "md:col-span-2" : "md:col-span-3"}">${searchResult ? htmlGrid() : `${i18next.t("Loading")}...`}</div>
			${SHOW_CONFIGURATION_TEMPLATE ? html` <div class="">${htmlConfigurationTemplate(false, false)}</div>` : ""}
		</div>
		${htmlUploadModal()} ${confirmModal.mainTemplate()} ${loading.count > 0 ? html` <dc-loader></dc-loader>` : ""}
	</div>`;
  return { mainTemplate };
};
