var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./client-accounts.scss";
import { useEffect, useLoadingReducer, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { extractFirstErrorMessage, notifyError, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import { html } from "lit-html";
import { validateEmail } from "GlobalShared/validators/email-validator";
import i18next from "i18next";
import { templateTitle } from "GlobalShared/templates/commons";
import { UserCompaniesColumnNames } from "./ClientAccountsModels";
import { getAllInvitationFields, InvitationValidator } from "./InvitationValidator";
import { useConfirmModal } from "../modals/commonModals";
const DEFAULT_FILTERS = [];
const DEFAULT_INVITATION_MODEL = {
  email: "",
  clientSource: void 0,
  companies: []
};
export const CLIENT_SOURCES = ["KPMG", "FedEx", "LaPoste"];
export const useClientAccounts = (props) => {
  const loadUserCompanies = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        dispatchLoading("inc");
        if ((emailSearchFragment == null ? void 0 : emailSearchFragment.length) > 1 || (companySearchFragment == null ? void 0 : companySearchFragment.length) > 1) {
          const result = yield props.searchUserCompanies(gridState, emailSearchFragment, companySearchFragment);
          setUserCompaniesSearchResult(result);
        } else {
          setUserCompaniesSearchResult(void 0);
        }
      }),
      (error) => {
        notifyError(error);
      },
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const inviteUser = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        dispatchSending("inc");
        yield props.inviteUser(currentInvitation.email, currentInvitation.companies, currentInvitation.clientSource);
        notifySuccess(i18next.t("user-successfully-invited", "User successfully invited"));
        setCurrentInvitation(DEFAULT_INVITATION_MODEL);
        setCompaniesForInvitation(void 0);
        validationContext.clearFieldValidations();
      }),
      (error) => {
        if (error.statusCode === 409) {
          notifyWarning("User has been already invited.");
        } else {
          notifyError(error);
        }
      },
      () => {
        dispatchSending("dec");
      }
    );
  });
  const toSelectItem = (company) => {
    return {
      value: company.id.toString(),
      label: `${company.name} (${company.id.toString()})`
    };
  };
  const onSortChanged = (e) => {
    const OrderByValue = e.detail.orderBy;
    const OrderDirValue = e.detail.orderDir;
    setGridState(__spreadProps(__spreadValues({}, gridState), {
      orderBy: OrderByValue,
      orderDir: OrderDirValue
    }));
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridFilter);
  };
  const removeCompanyFromInvitedCompanies = (company) => {
    setCurrentInvitation(__spreadProps(__spreadValues({}, currentInvitation), {
      companies: currentInvitation.companies.filter((c2) => c2.id !== company.id)
    }));
  };
  const removeCompanyFromAssignments = (company) => {
    setCurrentAssignments(__spreadProps(__spreadValues({}, currentAssignments), {
      changedCompanies: currentAssignments.changedCompanies.filter((c2) => c2.id !== company.id)
    }));
  };
  const addCompanyToAssignments = (company) => {
    if (!currentAssignments.changedCompanies.some((c) => c.id === company.id)) {
      setCurrentAssignments(__spreadProps(__spreadValues({}, currentAssignments), {
        changedCompanies: currentAssignments.changedCompanies.concat(company)
      }));
    }
  };
  const saveCompanyAssignments = (userId, addedCompanies, removedCompanies) => __async(void 0, null, function* () {
    if (userId !== void 0 && ((addedCompanies == null ? void 0 : addedCompanies.length) > 0 || (removedCompanies == null ? void 0 : removedCompanies.length) > 0)) {
      yield withErrorHandling(
        () => __async(void 0, null, function* () {
          yield props.assignCompanies(userId, addedCompanies, removedCompanies);
          notifySuccess(i18next.t("Saved successfully"));
          setShowAssignModal(false);
          yield loadUserCompanies();
        }),
        (error) => {
          if (error.statusCode === 409) {
            const errorMessage = extractFirstErrorMessage(error, i18next.t("Invalid data"));
            if (errorMessage.startsWith("The last user of the company cannot be removed")) {
              notifyWarning(i18next.t("The last user of the company cannot be removed"));
            } else {
              notifyWarning(errorMessage);
            }
          } else {
            notifyError(error);
          }
        }
      );
    }
  });
  const saveNewEmail = () => __async(void 0, null, function* () {
    setEmailValidationMessage("");
    if (validateEmail(currentChangeEmailModel.newEmail)) {
      yield withErrorHandling(
        () => __async(void 0, null, function* () {
          yield props.changeEmail(currentChangeEmailModel.userId, currentChangeEmailModel.newEmail);
          notifySuccess("Email change request sent to your new email address. Please check your new inbox");
        }),
        (error) => {
          if (error.statusCode === 409) {
            notifyError("Email already exists");
          } else {
            if (error.statusCode >= 400 && error.statusCode < 500) {
              notifyWarning(extractFirstErrorMessage(error, "Error occured"));
            } else {
              notifyError(error);
            }
          }
        },
        () => {
          setShowEmailChangeModal(false);
        }
      );
    } else {
      setEmailValidationMessage("Invalid email format");
    }
  });
  const [gridState, setGridState] = useState({
    pageIndex: 0,
    appliedFilters: DEFAULT_FILTERS,
    pageSize: 20,
    orderBy: "Email",
    orderDir: "asc"
  });
  const [currentInvitation, setCurrentInvitation] = useState(DEFAULT_INVITATION_MODEL);
  const [selectedCompanyForInvitation, setSelectedCompanyForInvitation] = useState(void 0);
  const [companiesForInvitation, setCompaniesForInvitation] = useState(void 0);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [emailSearchFragment, setEmailSearchFragment] = useState("");
  const [companySearchFragment, setCompanySearchFragment] = useState("");
  const [userCompaniesSearchResult, setUserCompaniesSearchResult] = useState(void 0);
  const [loading, dispatchLoading] = useLoadingReducer();
  const [sending, dispatchSending] = useLoadingReducer();
  const [currentAssignments, setCurrentAssignments] = useState(void 0);
  const [selectedCompanyForAssignment, setSelectedCompanyForAssignment] = useState(void 0);
  const [companiesForAssignment, setCompaniesForAssignment] = useState(void 0);
  const [currentChangeEmailModel, setCurrentChangeEmailModel] = useState(void 0);
  const [showEmailChangeModal, setShowEmailChangeModal] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState("");
  const validationContext = useValidationContext(new InvitationValidator(props.isAdmin));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  const confirmModal = useConfirmModal();
  useEffect(() => __async(void 0, null, function* () {
    if (dispatchIfValid) {
      setDispatchIfValid(false);
      if (validationContext.validationResult.isValid()) {
        yield inviteUser();
      }
    }
  }), [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(currentInvitation);
  }, [currentInvitation]);
  useEffect(() => __async(void 0, null, function* () {
    loadUserCompanies();
  }), [emailSearchFragment, companySearchFragment]);
  useEffect(() => {
    setSelectedCompanyForAssignment(void 0);
    setCompaniesForAssignment(void 0);
  }, [showAssignModal]);
  useEffect(() => {
    if (selectedCompanyForInvitation !== void 0) {
      if (!currentInvitation.companies.some((c) => c.id === selectedCompanyForInvitation.id)) {
        setCurrentInvitation(__spreadProps(__spreadValues({}, currentInvitation), {
          companies: currentInvitation.companies.concat(selectedCompanyForInvitation)
        }));
      }
      setSelectedCompanyForInvitation(void 0);
    }
  }, [selectedCompanyForInvitation]);
  useEffect(() => {
    if (selectedCompanyForAssignment !== void 0) {
      addCompanyToAssignments(selectedCompanyForAssignment);
      setSelectedCompanyForAssignment(void 0);
    }
  }, [selectedCompanyForAssignment]);
  const htmlEmailChangeModal = () => {
    if (showEmailChangeModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showEmailChangeModal}
						@close=${() => {
        setShowEmailChangeModal(false);
      }}
						.header=${"Change email"}
						.content=${html`<div>
							<dc-input
								class="w-56"
								.label=${"New email"}
								.value=${currentChangeEmailModel.newEmail}
								.validationMessage=${emailValidationMessage}
								@change=${(e) => {
        setCurrentChangeEmailModel(__spreadProps(__spreadValues({}, currentChangeEmailModel), {
          newEmail: e.detail.value
        }));
      }}
							></dc-input>
							<div class="mt-4 flex space-x-4">
								<button class="btn btn-primary" @click=${() => __async(void 0, null, function* () {
        return saveNewEmail();
      })}>Save</button>
								<button
									class="btn btn-primary"
									@click=${() => {
        setShowEmailChangeModal(false);
      }}
								>
									Cancel
								</button>
							</div>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlInviteClient = () => {
    var _a;
    return html`
			<div>${templateTitle(i18next.t("Invite Client"))}</div>
			<div class="grid xl:grid-cols-2 gap-4 mt-2">
				<div class="flex space-x-4">
					<dc-input
						.name=${"Email"}
						.label=${i18next.t("Email")}
						.value=${currentInvitation.email}
						.validationMessage=${validationContext.getValidationMessage("Email")}
						@change=${(e) => {
      validationContext.validateField("Email");
      setCurrentInvitation(__spreadProps(__spreadValues({}, currentInvitation), { email: e.detail.value }));
    }}
					></dc-input>
					${props.isAdmin ? html`<dc-select
								class="w-64"
								.label=${i18next.t("Client source")}
								.dataSource=${CLIENT_SOURCES}
								.value=${currentInvitation.clientSource}
								.validationMessage=${validationContext.getValidationMessage("ClientSource")}
								@change=${(e) => {
      validationContext.validateField("ClientSource");
      setCurrentInvitation(__spreadProps(__spreadValues({}, currentInvitation), {
        clientSource: e.detail.selectedValue
      }));
    }}
						  ></dc-select>` : ""}
					<dc-select
						class="w-64"
						.label=${i18next.t("Company")}
						.dataSource=${(text) => __async(void 0, null, function* () {
      if (text === void 0) {
        return i18next.t("Type at least 2 characters");
      } else if (text.length < 2) {
        return i18next.t("Type at least 2 characters");
      } else {
        const result = yield props.searchCompanies(text);
        if (result.length > 0) {
          setCompaniesForInvitation(result);
          return result.map(toSelectItem);
        } else {
          return i18next.t("No result found");
        }
      }
    })}
						.filterable=${true}
						.debounceMs=${150}
						.selectedValues=${selectedCompanyForInvitation == null ? void 0 : selectedCompanyForInvitation.id}
						@change=${(e) => {
      const companyId = parseInt(e.detail.selectedValue);
      const company = companiesForInvitation.find((cr) => cr.id === companyId);
      setSelectedCompanyForInvitation(company);
    }}
					></dc-select>
				</div>
				<div>
					${(currentInvitation == null ? void 0 : currentInvitation.companies) ? html`<div>
								<div class="font-bold whitespace-no-wrap text-sm">${i18next.t("Selected companies")}</div>
								<div class="flex flex-wrap mt-1">
									${(_a = currentInvitation == null ? void 0 : currentInvitation.companies) == null ? void 0 : _a.map((c) => html`<div class="mr-4">${htmlCompanyChip(c, () => removeCompanyFromInvitedCompanies(c))}</div>`)}
								</div>
								${validationContext.getValidationMessage("Companies") ? html`<div class="text-red-400">${validationContext.getValidationMessage("Companies")}</div>` : ""}
						  </div>` : ""}
				</div>
			</div>

			<div>
				<button
					type="button"
					class="btn btn-primary whitespace-no-wrap md:mt-6 mr-5"
					?disabled=${sending.count > 0}
					@click=${() => {
      setCurrentInvitation(__spreadValues({}, currentInvitation));
      validationContext.validateFields(getAllInvitationFields());
      setDispatchIfValid(true);
    }}
				>
					${i18next.t("Send invitation")}
				</button>
			</div>
		`;
  };
  const htmlCompanyChip = (company, removeHandler) => {
    return html`<span class="mb-2 mr-2 py-2 px-4 rounded-full text-xs border border-solid border-brand-primary whitespace-no-wrap inline-flex items-center bg-white text-brand-primary">
			${company.name} (${company.id})
			${props.permissionManager.hasPermission("Client_Modify") ? html`<span class="ml-2 text-xs cursor-pointer font-semibold text-black bg-gray-400 rounded-full px-1" @click="${() => removeHandler(company)}">&#10005;</span>` : ""}
		</span>`;
  };
  const htmlEditCompaniesModal = () => {
    return html`<dc-modal
			.visible=${showAssignModal}
			@close=${() => setShowAssignModal(false)}
			.header=${i18next.t("Edit companies")}
			.content=${html`<div>
				<div class="flex space-x-4">
					<dc-select
						class="w-64"
						.label=${i18next.t("Company")}
						.dataSource=${(text) => __async(void 0, null, function* () {
      if (text === void 0) {
        return i18next.t("Type at least 2 characters");
      } else if (text.length < 2) {
        return i18next.t("Type at least 2 characters");
      } else {
        const result = yield props.searchCompanies(text);
        if (result.length > 0) {
          setCompaniesForAssignment(result);
          return result.map(toSelectItem);
        } else {
          return i18next.t("No result found");
        }
      }
    })}
						.filterable=${true}
						.debounceMs=${150}
						.selectedValues=${selectedCompanyForAssignment == null ? void 0 : selectedCompanyForAssignment.id}
						@change=${(e) => {
      setSelectedCompanyForAssignment(companiesForAssignment.find((cr) => cr.id === parseInt(e.detail.selectedValue)));
    }}
					></dc-select>
				</div>
				<div class="space-x-2 my-4">${currentAssignments == null ? void 0 : currentAssignments.changedCompanies.map((company) => htmlCompanyChip(company, removeCompanyFromAssignments))}</div>
				<div>
					<button
						class="btn btn-primary m-auto"
						@click=${() => {
      setShowAssignModal(false);
      const addedCompanies = currentAssignments.changedCompanies.filter((c) => !currentAssignments.origCompanies.some((oc) => oc.id === c.id));
      const removedCompanies = currentAssignments.origCompanies.filter((c) => !currentAssignments.changedCompanies.some((oc) => oc.id === c.id));
      saveCompanyAssignments(currentAssignments.userId, addedCompanies, removedCompanies);
    }}
					>
						${i18next.t("Save")}
					</button>
				</div>
			</div>`}
		>
		</dc-modal>`;
  };
  const htmlUserCompaniesGrid = () => {
    var _a;
    const columns = [
      {
        field: UserCompaniesColumnNames.Email,
        label: i18next.t("Email"),
        sortable: true,
        filterable: false,
        filterDescriptor: { type: "string" },
        columnType: "string"
      },
      {
        field: UserCompaniesColumnNames.Companies,
        label: i18next.t("Companies"),
        filterable: false,
        sortable: false,
        filterDescriptor: { type: "string" },
        columnType: "string",
        columnClass: "w-2/3"
      },
      {
        field: UserCompaniesColumnNames.Details,
        columnClass: "w-48"
      }
    ];
    const vm = {
      columns,
      data: userCompaniesSearchResult == null ? void 0 : userCompaniesSearchResult.userCompanies,
      cellTemplate: (index, field) => {
        const item = userCompaniesSearchResult == null ? void 0 : userCompaniesSearchResult.userCompanies[index];
        if (field === UserCompaniesColumnNames.Email) {
          return item.email;
        } else if (field === UserCompaniesColumnNames.Companies) {
          return html`
						<div class="flex flex-wrap mt-2">
							${(item == null ? void 0 : item.companies) ? item.companies.map(
            (c) => htmlCompanyChip(c, (c2) => __async(void 0, null, function* () {
              if (yield confirmModal.confirm(i18next.t("Are you sure to revoke access to this company?"))) {
                saveCompanyAssignments(item.userId, [], [c2]);
              }
            }))
          ) : ""}
						</div>
					`;
        } else if (field === UserCompaniesColumnNames.Details) {
          return html`
						<div>
							<div class="flex space-x-4">
								<div class=${!props.isAdmin || props.permissionManager.hasPermission("Client_Modify") ? "" : "hidden"}>
									<button
										class="btn btn-primary btn-sm"
										@click=${() => {
            setCurrentAssignments({
              userId: item.userId,
              origCompanies: item.companies,
              changedCompanies: item.companies
            });
            setShowAssignModal(true);
          }}
									>
										${i18next.t("Edit companies")}
									</button>
								</div>
								${props.isAdmin && props.permissionManager.hasPermission("Client_Modify_Change_Email") ? html`<div>
											<button
												class="btn btn-primary btn-sm"
												@click=${() => {
            setCurrentChangeEmailModel({ userId: item.userId, newEmail: "" });
            setShowEmailChangeModal(true);
          }}
											>
												${i18next.t("Change email")}
											</button>
									  </div>` : ""}
							</div>
						</div>
					`;
        } else {
          return "";
        }
      },
      cellClass: (_index, field) => {
        if (field === UserCompaniesColumnNames.Email || field === UserCompaniesColumnNames.Companies) {
          return "actions";
        }
        return "";
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: userCompaniesSearchResult == null ? void 0 : userCompaniesSearchResult.total
      }
    };
    return html`<div class="mt-4">
			<div class="flex justify-between mb-3 items-center">
				<div class="flex space-x-4">
					<div>${templateTitle(i18next.t("Client Account"))}</div>
				</div>
				<div class="flex space-x-4 items-end">
					${((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html`<div>
								<button class="btn btn-primary whitespace-no-wrap" @click=${() => setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex: 0, appliedFilters: DEFAULT_FILTERS }))}>
									${i18next.t("Reset filters")}
								</button>
						  </div>` : ""}
				</div>
			</div>
			<div class="flex justify-start space-x-4">
				<dc-input
					.label=${i18next.t("Email")}
					.onInputDebounceMs=${250}
					@input=${(e) => {
      setEmailSearchFragment(e.detail.value);
    }}
				></dc-input
				><dc-input
					.label=${i18next.t("Company")}
					.onInputDebounceMs=${250}
					@input=${(e) => {
      setCompanySearchFragment(e.detail.value);
    }}
				></dc-input>
			</div>
			<div class="my-2 text-xs">${i18next.t("Type at least two characters for either email or company")}</div>
			${loading.count === 0 ? userCompaniesSearchResult ? html`<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>` : "" : `${i18next.t("Loading")}...`}
		</div>`;
  };
  const mainTemplate = html`<div class="mt-8 mx-8">
		${!props.isAdmin || props.permissionManager.hasPermission("Client_Invite") ? htmlInviteClient() : ""} ${htmlUserCompaniesGrid()} ${htmlEditCompaniesModal()} ${htmlEmailChangeModal()}
		${confirmModal.mainTemplate()}
	</div>`;
  return { mainTemplate };
};
