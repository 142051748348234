import { formatTimestampUTC, parseTimestampUTC } from "GlobalShared/helpers/dateHelper";
import { PrefixedStorage } from "./PrefixedStorage";
import { default as moment } from "moment";
const PREFIX = "sm";
export class SessionManagerStorage extends PrefixedStorage {
  constructor() {
    super(PREFIX, window.sessionStorage);
    this.permissionsKey = "permissions";
    this.permissionsTsKey = "permissions_ts";
  }
  get permissions() {
    const permissions = this.getItem(this.permissionsKey);
    return permissions ? permissions.split("|") : [];
  }
  set permissions(value) {
    this.setItem(this.permissionsTsKey, formatTimestampUTC(moment()));
    this.setItem(this.permissionsKey, value.join("|"));
  }
  arePermissionsAvailable() {
    if (this.getItem(this.permissionsTsKey) !== null) {
      const permissionsTs = parseTimestampUTC(this.getItem(this.permissionsTsKey));
      const fiveMinutesAgo = moment().subtract(5, "minutes");
      if (permissionsTs.isBefore(fiveMinutesAgo)) {
        return false;
      }
    }
    return this.getItem(this.permissionsKey) !== null;
  }
  clearPermissions() {
    if (this.getItem(this.permissionsKey) !== null) {
      this.setItem(this.permissionsKey, void 0);
    }
    if (this.getItem(this.permissionsTsKey) !== null) {
      this.setItem(this.permissionsTsKey, void 0);
    }
  }
}
