var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { useState } from "GlobalShared/haunted/CustomHooks";
export const useTaxIdentifierModal = (props) => {
  const save = () => __async(void 0, null, function* () {
    if (taxIdentifier) {
      yield props.save(taxIdentifier);
      setTaxIdentifier(void 0);
      setShowModal(false);
    }
  });
  const [showModal, setShowModal] = useState(false);
  const [taxIdentifier, setTaxIdentifier] = useState(void 0);
  const show = () => __async(void 0, null, function* () {
    setShowModal(true);
  });
  const template = () => {
    return html`${showModal ? html`
					<div>
						<dc-modal
							.visible=${showModal}
							@close=${() => setShowModal(false)}
							.header=${"Edit tax identifier"}
							.content=${html` <div>
								<div>
									<dc-input
										class="w-1/3"
										.label=${"Tax Identifier"}
										.value=${taxIdentifier}
										@change=${(e) => {
      setTaxIdentifier(e.detail.value);
    }}
									></dc-input>
								</div>
								<div class="mt-4 space-x-4">
									<button class="btn btn-primary" @click=${() => setShowModal(false)}>Cancel</button>
									<button class="btn btn-primary" @click=${() => save()}>Save</button>
								</div>
							</div>`}
						>
						</dc-modal>
					</div>
			  ` : ""} `;
  };
  return { show, template };
};
