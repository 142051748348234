var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { AddressAnswer as WebAddressAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { AddressAnswer as AdminAddressAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { Address as WebAddress } from "WebServices/APIs/self/models/V1/Common";
import { Address as AdminAddress } from "AdminServices/APIs/self/models/V1/Common";
import { AddressValidator } from "./AnswerValidators";
export const observedAttributes = ["q11e", "questionId", "worldCountries", "withCountry"];
export const useShadowDOM = false;
export const name = "ww-answer-address";
const DEFAULTS = {
  worldCountries: [],
  withCountry: false,
  validation: {
    required: false
  }
};
export class AddressChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    worldCountries: host.worldCountries ? host.worldCountries : DEFAULTS.worldCountries,
    withCountry: host.withCountry !== void 0 ? host.withCountry : DEFAULTS.withCountry,
    validation: host.validation !== void 0 ? __spreadValues(__spreadValues({}, DEFAULTS.validation), host.validation) : DEFAULTS.validation
  };
  const getLabel = (fieldName) => {
    var _a;
    return ((_a = getSubQuestion(fieldName)) == null ? void 0 : _a.Label) + (props.validation.required ? "*" : "");
  };
  const getSubQuestion = (questionId) => {
    var _a, _b;
    const filteredSubQuestions = (_b = (_a = question.SubQuestions) == null ? void 0 : _a.filter((subQuestion) => subQuestion.Id === questionId)) != null ? _b : [];
    if (filteredSubQuestions.length === 1) {
      return filteredSubQuestions[0];
    } else {
      return void 0;
    }
  };
  const getAddressAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminAddressAnswer({
        Address: new AdminAddress({ City: city, Street: street, StreetNumber: streetNo, Zip: zip }),
        CountryCode: countryCode,
        QuestionId: props.questionId
      });
    }
    return new WebAddressAnswer({
      Address: new WebAddress({ City: city, Street: street, StreetNumber: streetNo, Zip: zip }),
      CountryCode: countryCode,
      QuestionId: props.questionId
    });
  };
  const [countryCode, setCountryCode] = useState(void 0);
  const [city, setCity] = useState(void 0);
  const [street, setStreet] = useState(void 0);
  const [streetNo, setStreetNo] = useState(void 0);
  const [zip, setZip] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new AddressValidator(props.validation, props.withCountry));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof AdminAddressAnswer || answer instanceof WebAddressAnswer) {
        setCountryCode(answer.CountryCode);
        setCity(answer.Address.City);
        setStreet(answer.Address.Street);
        setStreetNo(answer.Address.StreetNumber);
        setZip(answer.Address.Zip);
      }
    }
  }, [props.q11e, props.questionId]);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new AddressChangeEvent({
          answer: getAddressAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(getAddressAnswer());
  }, [countryCode, city, street, streetNo, zip, question]);
  return html`
		${props.q11e !== void 0 && question !== void 0 ? html`<div class="grid md:grid-cols-2 gap-4 mt-4">
					<dc-select
						class=${props.withCountry ? "" : "hidden"}
						.label=${getLabel("country")}
						.selectedValues=${[countryCode]}
						.dataSource=${props.worldCountries.map((country) => ({
    label: country.Name,
    value: country.Code
  }))}
						.filterable=${true}
						.readonly=${props.q11e.isReadonly()}
						.validationMessage=${validationContext.getValidationMessage("countryCode")}
						@change=${(e) => {
    setCountryCode(e.detail.selectedValue);
    setDispatchIfValid(true);
    validationContext.validateField("countryCode");
  }}
					></dc-select>
					<dc-input
						.readonly=${props.q11e.isReadonly()}
						.label=${getLabel("city")}
						.value=${city}
						.validationMessage=${validationContext.getValidationMessage("city")}
						@change=${(e) => {
    setCity(e.detail.value);
    setDispatchIfValid(true);
    validationContext.validateField("city");
  }}
					></dc-input>
					<dc-input
						.readonly=${props.q11e.isReadonly()}
						.label=${getLabel("street")}
						.value=${street}
						.validationMessage=${validationContext.getValidationMessage("street")}
						@change=${(e) => {
    setStreet(e.detail.value);
    setDispatchIfValid(true);
    validationContext.validateField("street");
  }}
					></dc-input>
					<dc-input
						.readonly=${props.q11e.isReadonly()}
						.label=${getLabel("street_number")}
						.value=${streetNo}
						.validationMessage=${validationContext.getValidationMessage("streetNo")}
						@change=${(e) => {
    setStreetNo(e.detail.value);
    setDispatchIfValid(true);
    validationContext.validateField("streetNo");
  }}
					></dc-input>
					<dc-input
						.readonly=${props.q11e.isReadonly()}
						.label=${getLabel("zip")}
						.value=${zip}
						.validationMessage=${validationContext.getValidationMessage("zip")}
						@change=${(e) => {
    setZip(e.detail.value);
    setDispatchIfValid(true);
    validationContext.validateField("zip");
  }}
					></dc-input>
			  </div>` : ""}
	`;
};
