var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import "./sub-cancellation-modal.scss";
import { getDay, MAX_DATE, MIN_DATE } from "GlobalShared/helpers/dateHelper";
import { checkError, notifyError, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
export const useSubCancellationModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [cancellationDate, setCancellationDate] = useState(void 0);
  const [validationMessage, setValidationMessage] = useState(void 0);
  useEffect(() => {
    if ((props == null ? void 0 : props.contractTo) !== void 0 && getDay(props.contractTo) < getDay(/* @__PURE__ */ new Date())) {
      setCancellationDate(props.contractTo);
    } else {
      setCancellationDate(/* @__PURE__ */ new Date());
    }
  }, [props == null ? void 0 : props.contractTo]);
  const show = () => __async(void 0, null, function* () {
    setValidationMessage(void 0);
    setShowModal(true);
  });
  const cancelSubscription = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.cancelSubscription(props.subscriptionId, cancellationDate);
        notifySuccess("Subscription successfully cancelled");
      }),
      (error) => {
        if (checkError(error, 409, "SubscriptionExpired")) {
          notifyWarning("The cancellation date must be between contarcting start date and expiration date");
        } else {
          notifyError(error);
        }
      }
    );
  });
  const template = () => {
    var _a, _b;
    return html`${showModal ? html`
					<div>
						<dc-modal
							.visible=${showModal}
							@close=${() => setShowModal(false)}
							.header=${"Cancellation"}
							.content=${html`<div>
								<div class="flex mb-5">
									<dc-datepicker
										.label=${"Cancelled at"}
										.min=${(_a = props.contractFrom) != null ? _a : MIN_DATE}
										.max=${(_b = props.contractTo) != null ? _b : MAX_DATE}
										.value=${cancellationDate}
										.validationMessage=${validationMessage}
										@change=${(e) => {
      const selectedDate = e.detail.value;
      let cancellationDateValidationMessage;
      if (selectedDate >= props.contractTo) {
        cancellationDateValidationMessage = "Date must be smaller than contracting end date";
      } else if (selectedDate < props.contractFrom) {
        cancellationDateValidationMessage = "Date must be bigger or equal than contracting from date";
      }
      setValidationMessage(cancellationDateValidationMessage);
      setCancellationDate(selectedDate);
    }}
									></dc-datepicker>
								</div>
								<div class="mb-5">Are you sure you would like to cancel this subscription?</div>
								<div>
									<div class="space-x-4">
										<button
											class="btn btn-primary"
											@click=${() => {
      setShowModal(false);
    }}
										>
											No
										</button>
										<button
											class="btn btn-primary"
											?disabled=${validationMessage !== void 0}
											@click=${() => {
      setShowModal(false);
      cancelSubscription();
    }}
										>
											Yes
										</button>
									</div>
								</div>
							</div>`}
						>
						</dc-modal>
					</div>
			  ` : html``}`;
  };
  return { show, template };
};
