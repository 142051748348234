var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { EnumAnswerValidator } from "./AnswerValidators";
export const useEnumAnswer = (props) => {
  const [question, setQuestion] = useState(void 0);
  const [answer, setAnswer] = useState(void 0);
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  const validationContext = useValidationContext(new EnumAnswerValidator());
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      props.dispatch(answer);
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    if (props.q11e && props.questionId) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const storedAnswer = props.q11e.getAnswer(props.questionId);
      setAnswer({ QuestionId: props.questionId, Value: storedAnswer == null ? void 0 : storedAnswer.Value });
    }
  }, [props.q11e, props.questionId]);
  useEffect(() => {
    validationContext.validate(answer);
  }, [answer]);
  const mainTemplate = html` ${props.q11e !== void 0 && question !== void 0 ? html`<dc-select
				.readonly=${props.q11e.isReadonly(props.questionId)}
				.label=${`${question.Label}*`}
				.tooltip=${question.Tooltip !== void 0 ? question.Tooltip : ""}
				.selectedValues=${answer.Value}
				.validationMessage=${validationContext.getValidationMessage("value")}
				.dataSource=${props.dataSource}
				.filterable=${true}
				.multiSelect=${props.isMultiSelect}
				@change=${(e) => {
    const tempValues = props.isMultiSelect ? e.detail.selectedValues : [e.detail.selectedValue];
    setAnswer(__spreadProps(__spreadValues({}, answer), { Value: tempValues }));
    setDispatchIfValid(true);
    validationContext.validateField("value");
  }}
		  ></dc-select>` : ""}`;
  return { mainTemplate };
};
