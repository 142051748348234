export const ADDITIONAL_TAX_SERVICES = {
  header: [["Task", "Fee", "Fee Basis", "Comments"]],
  body: [
    [
      "Country specific VAT registration & compliance",
      "\u20AC900",
      "Annual fee/country",
      "VAT registration (if required), preparation and submission of VAT returns, EC Sales and/or Purchase Lists and VAT local listings (where required)."
    ],
    [
      "Fiscal representation in IT, FR, PL and ES",
      "\u20AC1000",
      "Annual fee/ country",
      "Non-EU incorporated Sellers have to appoint a fiscal representative in some EU countries. We can provide this service for a fixed annual fee in France, Italy, Poland and Spain."
    ],
    [
      "Fiscal representation in other countries",
      "By agreement",
      "By agreement",
      "In case a fiscal representation is required in other countries, we can offer this service by separate agreement."
    ],
    ["Filing agent fee in DE", "\u20AC600", "Annual fee/ country", "Non-EU incorporated Sellers have to appoint a German established filing agent. We can provide this service for a fixed annual fee."],
    ["EU One-Stop-Shop (OSS) VAT registration and compliance", "\u20AC3000", "Annual fee", "EU OSS VAT registration (if required), preparation and submission of quarterly OSS VAT returns."],
    [
      "EU Import-One-Stop-Shop (IOSS) VAT registration and compliance",
      "\u20AC2500",
      "Annual fee",
      "EU  IOSS  VAT  registration  (if  required),  preparation  and  submission  of monthly  IOSS  VAT  returns,  including  VAT  intermediary  services  (if required)"
    ],
    [
      "Intrastat threshold check",
      "\u20AC300",
      "Per request per country per year",
      "In case your company whish us to check if the Intrastat threshold for your company in a given country has been exceeded we can offer it as an additional service. (Both directions included)"
    ],
    [
      "Intrastat reports",
      "\u20AC480",
      "Annual fee/ country / direction",
      "In case your company exceeds an Intrastat threshold (please note that you have to notify KPMG in this regards, as KPMG is not responsible for monitoring the Iintrastat threshold), KPMG can assist you with the preparation and submission of the required monthly reports. The reports for dispatches and arrivals are considered two separate reports and will be charged separately."
    ],
    ["Retrospective returns", "In proportion to the annual fee paid for the service", "", "In case you have to submit historic VAT returns, KPMG can assist you with its submission."],
    [
      "Self-revisions",
      "In proportion to the annual fee paid for the service",
      "",
      "In case you request any amendments in your VAT returns after it has been prepared or submitted, a self-revision has to be prepared. We can do this for a fixed fee."
    ],
    [
      "VAT payment processing",
      "\u20AC120",
      "Per year",
      "Required if you would like us to make VAT payments on your behalf in Spain. This fee includes the 4 quarterly VAT payments to the tax authorities. \u20AC30/transfer will be charged for any additional transfer besides these."
    ],
    ["Communicating with tax authorities", "\u20AC50", "Per case", "Investigating/mitigating late payment penalties etc."],
    ["Changing company details", "\u20AC100", "Per request", "Dealing with a change of company name, change of director etc."],
    ["General VAT advice and audit assistance", "\u20AC150", "Per hour", "Sellers will be notified in advance if work falls into this category."],
    [
      "Specialist VAT advice",
      "By agreement",
      "By agreement",
      "For specialist advice, we will work with our teams in local KPMG offices. Fees vary from country to country and you will be provided with the fees in advance of starting any work."
    ],
    [
      "VAT Deregistration",
      "\u20AC300",
      "Per request",
      "In case your contract expires before the deregistration happens, KPMG is not responsible for the submission of further (nil) returns to the tax authorities and penalties levied for any missing return. Additional VAT returns and associated reports (e.g. EC Sales List) after the contractual period are additional chargeable services.  KPMG cannot guarantee a successful deregistration in case you have any outstanding liabilities in a given country."
    ],
    ["EORI number application", "\u20AC150", "Per request", "Required in order to register as an importer."],
    [
      "VAT re-registration",
      "\u20AC300",
      "Per case",
      "Tax Offices can deactivate a Seller\u2019s VAT ID due to inactivity (i.e. not submitting the necessary VAT returns for more reporting periods). KPMG can assist you in the re-registration process for a fixed fee."
    ],
    [
      "Import VAT deferment account setup",
      "By agreement",
      "By agreement",
      "By applying for an import VAT deferment license you are not required to pay the VAT on import immediately. The VAT can be paid when you file your VAT return. As a foreign entrepreneur you usually must engage a fiscal representative to apply on your behalf."
    ],
    [
      "Sworn Translations",
      "By agreement",
      "By agreement",
      "Carrying out sworn translation is required to obtain the VAT registration numbers in some countries. KPMG may arrange for a third party to perform sworn translations on your behalf and charge the service fees to you."
    ]
  ]
};
export const IOSS_TIER_FEES = {
  body: [
    ["Name of the Services", "One time registration/takeover fee", "Core Tax Compliance Services Fee, including VAT intermediary services"],
    ["Tier 1", "None", "EUR 800 for up to 199 packages in a 12 month period, renewable after the 199 packages credit is used up or after 12 months."],
    ["Tier 2", "None", "EUR 2500 for up to 999 packages in a 12 month period, renewable after the 999 packages credit is used up or after 12 months."],
    ["Tier 3", "None", "EUR 4800 for unlimited packages in a 12 month period, renewable after 12 months."]
  ]
};
export const NUMBERED_TEXTS = [
  {
    displayNumber: 1,
    text: `This engagement contract governs the services that KPMG Global Services Hungary Ltd, V\xE1ci \xFAt 41, 1134 Budapest, Hungary (\u201CKPMG\u201D, \u201Cwe\u201D) will provide to the contracting party indicated above (\u201Cyou\u201D).`
  },
  {
    displayNumber: 2,
    text: `Upon entering into this contract, you agree that KPMG will provide the Import-One-Stop-Shop (\u201CIOSS\u201D) VAT Compliance Services set out in clause 6 for the Annual Service Fee set out in`
  },
  {
    displayNumber: 3,
    text: `In addition, upon your specific request, KPMG may provide you with Additional Tax Services for Additional Service Fees, which are set out in`
  },
  {
    displayNumber: 4,
    text: `this contract. Whenever the contract refers to working days, it is understood as working days in Hungary.`,
    firstLine: `(KPMG) form an integral part of`,
    linkTitle: `The General Terms of Business`
  },
  {
    displayNumber: 5,
    text: `KPMG has a process of acceptance of new clients, which includes the assessment of our compliance with applicable regulatory requirements. KPMG\u2019s acceptance of this contract is subject to the satisfactory completion of KPMG\u2019s pre-approval procedures and Anti-Money Laundering obligations.`
  },
  {
    displayNumber: 6,
    text: `The IOSS VAT Compliance Services include the following services:`
  },
  {
    displayNumber: 7,
    text: `For the avoidance of doubt, the following services are not included in the scope of the IOSS VAT Compliance Services, although they can be provided as Additional Tax Services upon payment of the respective Additional Service Fee:`
  },
  {
    displayNumber: 8,
    text: `Should we or you believe that there is a need to consult with a specialist indirect tax adviser before answering specific tax authority questions, we can arrange for a KPMG Firm in the relevant country to provide advice to you as our subcontractor. Such assistance will be subject to a separate fee to be advised by the other KPMG firm. We will not engage the subcontractor without your prior written acceptance of their fee estimate.`
  },
  {
    displayNumber: 9,
    text: `KPMG shall not accept any liability for errors arising from incomplete information or inaccuracies in the data provided to us. KPMG will not verify the data provided by you.`
  },
  {
    displayNumber: 10,
    text: `In order for us to prepare your IOSS VAT return, you must provide us the KPMG template file available on the KPMG IOSS Compliance portal once you have subscribed to our services. The KPMG IOSS Compliance Portal has been tailored to process this specific data report and requires this fixed format.`
  },
  {
    displayNumber: 11,
    text: `You must provide us with all required data to prepare the IOSS VAT returns for each calendar month by 17:00 Central European Time (\u201CCET\u201D) on the 6th calendar day following the end of the calendar month to be reported. If data is received late, we reserve the right to charge you a penalty fee of EUR 50 per occurrence of your default/delay.`
  },
  {
    displayNumber: 12,
    text: `If no data is received by the statutory submission deadline for a particular return and you have not instructed us to submit nil returns, KPMG will not submit a IOSS return to the tax authority for the particular period. This may result in financial or other consequences from the tax authority, for which you will be fully liable. KPMG shall not be held liable for any financial or other consequences if the return was submitted according to this paragraph.`
  },
  {
    displayNumber: 13,
    text: `We will ask you to approve each IOSS return prior to us submitting it to the relevant tax authority. If we do not receive your explicit approval (or alternative instruction) by the earlier of 5 working days after we send the draft return to you or 10:00 CET on the submission deadline which is the end of the month following the return period, we will assume that you agree with our calculations and that KPMG should submit the return to the tax authority, where permitted to do so. Note that if a payment of VAT is due then this payment must be received as cleared funds in Euro by the appointed Intermediary at the latest  by 10.00 CET, 3 (three) working days before the due payment deadline which is also the end of the month following the return period end in order for the payment to be made by the payment deadline. Should you wish to transfer the payment in a currency other than Euro then you can if you wish arrange an FX conversion through the Intermediary and will need to agree to the Intermediary\u2019s specific terms and conditions in respect of this service.`
  },
  {
    displayNumber: 14,
    text: `You shall retain responsibility for the correctness and completeness of all returns we submit on your behalf, and for the payment of your tax liabilities.`
  },
  {
    displayNumber: 15,
    text: `KPMG shall not accept any responsibility for penalties and other surcharges imposed by tax authorities if returns are submitted late due to the late delivery of data to us or if you pay your VAT liabilities late. KPMG liability should be limited to the amount set in clause 28 of the engagement contract. In any case, we shall not be liable for your loss resulting from interest amount due on the VAT liability that was not settled in time with the respective tax authorities.`
  },
  {
    displayNumber: 16,
    text: `You shall send to us promptly any notices, assessments or determinations received by tax authorities regarding your IOSS returns prepared by KPMG or the Services requiring action by us.`
  },
  {
    displayNumber: 17,
    text: `You shall retain responsibility for maintaining records and associated papers concerning your tax affairs in accordance with legal requirements. The retention period for all IOSS records is 10 years.`
  },
  {
    displayNumber: 18,
    text: `You shall keep your IOSS number confidential and only use it for your own shipments.`
  },
  {
    displayNumber: 19,
    text: `All IOSS VAT Compliance Service Fees must be paid annually in advance through bank transfer, PayPal or any other means accepted on the KPMG IOSS Portal.`
  },
  {
    displayNumber: 20,
    text: `Where you request us to provide Additional Tax Services, we will seek your approval for the estimated fees and disbursements in advance that should be provided before we start the respective service.`
  },
  {
    displayNumber: 21,
    text: `KPMG will issue an invoice electronically by email after payment of the respective fees. KPMG shall have no obligation to provide services if payment is not received in advance.`
  },
  {
    displayNumber: 22,
    text: `Our fee amounts and rates set out in Appendix 1 are fixed for one year from the date of payment of the first Annual Service Fee and may be revised annually thereafter.`
  },
  {
    displayNumber: 23,
    text: `All fees are exclusive of VAT (where applicable) and any nonHungarian taxes payable thereon or deductible therefrom.`
  },
  {
    displayNumber: 24,
    text: `Either party has the right to terminate this contract by providing 30 days written notice to  ${ClientContext.ContactEmailAddress} (if cancelled by you) or to the designated contact email (if cancelled by KPMG). If you do so:`
  },
  {
    displayNumber: 25,
    text: `KPMG may be required to terminate this contract for legal, regulatory or professional reasons. In these circumstances, the contract may be cancelled immediately and we shall not be`
  },
  {
    displayNumber: 26,
    text: `KPMG [or your appointed IOSS Intermediary] will notify you by email if you fail to make VAT payment within the appropriate deadline. If you do not rectify the failure within 5 working days after the notification, KPMG reserves the right to terminate this contract. We can also terminate the contract if you refrain from cooperation with the tax authorities during the tax audit or another similar proceedings. In these circumstances, the contract may be cancelled immediately after the warning sent by KPMG and we shall not be required to carry out any further work following termination nor shall we be responsible or liable for any consequences arising from such termination.`
  },
  {
    displayNumber: 27,
    text: `If KPMG cancels this contract due to a material breach committed by you (such as the breaches mentioned in clause 26), no refund will be made of the Annual Service Fee. In case of cancellation by KPMG for any other reason, a refund of the Annual Service Fee will be paid pro-rata according to the number of completed months of service.`
  },
  {
    displayNumber: 28,
    text: `Exclusions and limitations on KPMG\u2019s liability to you are set out in clauses 34 to 38 of the General Terms of Business. By signing this contract, you and we mutually agree that KPMG\u2019s liability to you in connection with the services provided in each annual`
  },
  {
    displayNumber: 29,
    text: `The services defined under this contract require us to collect and process your personal data and act as data processor. The website below contains our privacy statements and explains the way how we process your data and ensure appropriate security levels:`,
    linkTitle: `https://home.kpmg/content/dam/kpmg/hu/pdf/KGSH_Privacy_Statement_v2.pdf`
  },
  {
    displayNumber: 30,
    text: `The Engagement Contract sets out the entire agreement and understanding between us in connection with the services and supersedes any prior agreements, understandings, arrangements, statements or representations (unless made fraudulently) relating to the services. The contract may be accepted via the Marketplace portal with online acceptance, and unless you request, KPMG will not prepare a signed copy of it, but the electronic version will guide the relationship. Any modifications or variations to the Engagement Contract shall be concluded with the same formalities as described above.`
  }
];
export const UNORDERED_TEXTS = [
  "Providing access to the KPMG IOSS Compliance Portal",
  "Arranging the IOSS registration on your behalf. If you are based outside the EU, we will appoint a VAT intermediary for you on your behalf. Your IOSS number is linked to the VAT",
  "Prepare the monthly IOSS returns for approval by you before submission. KPMG will prepare these returns based on data that you upload to the KPMG IOSS Compliance Portal.",
  "Where the necessary data is received according to the deadline set out in clause 11 and there are no additional queries on the data, KPMG will provide the draft returns to you at least 7 working days prior to the statutory submission deadline.",
  "Submitting your IOSS returns to the appropriate tax authority and confirm submission to you.",
  "Providing payment instructions via email (i.e. cover letter) or other suitable means to you regarding your VAT liabilities.",
  "Informing you of any action related to your VAT reporting and liabilities as per correspondence received from the tax authority.",
  "In case of contract termination, KPMG will not prepare and submit your IOSS VAT returns for tax reporting periods where the filing deadline falls after the termination date ( as per clauses 24-26.) or inform you about actions required because of tax authorities\u2019 correspondence.",
  "Non-IOSS related VAT registration and compliance",
  "Preparing and submitting IOSS VAT returns for tax reporting periods which ended before the date of this contract.",
  "Reviewing invoices and confirming that they meet the required regulatory requirements",
  "Preparing your IOSS VAT returns based on data which is not provided in the correct format, unless specifically agreed with you",
  "Auditing or verifying the correctness and completeness of the data you provide to us.",
  "Note that payments will be undertaken through your appointed Intermediary, not through KPMG",
  "The termination date of the contract will be the 30th day after the written notice was given to KPMG.",
  "Within the first 14 calendar days from the date of payment of the first Annual Service Fee, you will be eligible for a full refund of all fees paid, minus EUR 200 administration fee to cover costs already incurred by KPMG.",
  "After the first 14 calendar days but within the first 12 months, no refund of the Annual Service Fee will be paid.",
  "After the first 12 months, a refund of the Annual Service Fee will be paid pro-rata according to the number of completed months of service."
];
