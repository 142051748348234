import { DateConverter } from "./../../../../converters/DateConverter";
export class Filter {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Filter
     */
    this.$type = "V1.SubmissionDeadlineConfigurations.Search.Filter";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientApprovalDueDateFrom = DateConverter.from(_data["ClientApprovalDueDateFrom"]);
      this.ClientApprovalDueDateTo = DateConverter.from(_data["ClientApprovalDueDateTo"]);
      this.DeadlineFrom = DateConverter.from(_data["DeadlineFrom"]);
      this.DeadlineTo = DateConverter.from(_data["DeadlineTo"]);
      this.EFiling = _data["EFiling"];
      this.FilingExtension = _data["FilingExtension"];
      if (_data["FrequencyTypes"] && _data["FrequencyTypes"].constructor === Array) {
        this.FrequencyTypes = [];
        for (let item of _data["FrequencyTypes"]) {
          this.FrequencyTypes.push(FilterFrequencyTypes[item]);
        }
      }
      this.IsEPayment = _data["IsEPayment"];
      this.PaymentDueDateFrom = DateConverter.from(_data["PaymentDueDateFrom"]);
      this.PaymentDueDateTo = DateConverter.from(_data["PaymentDueDateTo"]);
      this.ReturnGenerationDueDateFrom = DateConverter.from(_data["ReturnGenerationDueDateFrom"]);
      this.ReturnGenerationDueDateTo = DateConverter.from(_data["ReturnGenerationDueDateTo"]);
      if (_data["ReturnTypes"] && _data["ReturnTypes"].constructor === Array) {
        this.ReturnTypes = [];
        for (let item of _data["ReturnTypes"]) {
          this.ReturnTypes.push(FilterReturnTypes[item]);
        }
      }
      this.ServiceCountryCode = _data["ServiceCountryCode"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientApprovalDueDateFrom"] = DateConverter.toUtcDateString(this.ClientApprovalDueDateFrom);
    _data["ClientApprovalDueDateTo"] = DateConverter.toUtcDateString(this.ClientApprovalDueDateTo);
    _data["DeadlineFrom"] = DateConverter.toUtcDateString(this.DeadlineFrom);
    _data["DeadlineTo"] = DateConverter.toUtcDateString(this.DeadlineTo);
    _data["EFiling"] = this.EFiling;
    _data["FilingExtension"] = this.FilingExtension;
    if (this.FrequencyTypes && this.FrequencyTypes.constructor === Array) {
      _data["FrequencyTypes"] = [];
      for (let item of this.FrequencyTypes) {
        _data["FrequencyTypes"].push(FilterFrequencyTypes[item]);
      }
    }
    _data["IsEPayment"] = this.IsEPayment;
    _data["PaymentDueDateFrom"] = DateConverter.toUtcDateString(this.PaymentDueDateFrom);
    _data["PaymentDueDateTo"] = DateConverter.toUtcDateString(this.PaymentDueDateTo);
    _data["ReturnGenerationDueDateFrom"] = DateConverter.toUtcDateString(this.ReturnGenerationDueDateFrom);
    _data["ReturnGenerationDueDateTo"] = DateConverter.toUtcDateString(this.ReturnGenerationDueDateTo);
    if (this.ReturnTypes && this.ReturnTypes.constructor === Array) {
      _data["ReturnTypes"] = [];
      for (let item of this.ReturnTypes) {
        _data["ReturnTypes"].push(FilterReturnTypes[item]);
      }
    }
    _data["ServiceCountryCode"] = this.ServiceCountryCode;
    return _data;
  }
}
export var FilterFrequencyTypes = /* @__PURE__ */ ((FilterFrequencyTypes2) => {
  FilterFrequencyTypes2["Monthly"] = "Monthly";
  FilterFrequencyTypes2["Quarterly"] = "Quarterly";
  FilterFrequencyTypes2["QuarterlyShifted1"] = "QuarterlyShifted1";
  FilterFrequencyTypes2["QuarterlyShifted2"] = "QuarterlyShifted2";
  FilterFrequencyTypes2["BiMonthly"] = "BiMonthly";
  FilterFrequencyTypes2["FourMonthly"] = "FourMonthly";
  FilterFrequencyTypes2["SixMonthly"] = "SixMonthly";
  FilterFrequencyTypes2["Yearly"] = "Yearly";
  return FilterFrequencyTypes2;
})(FilterFrequencyTypes || {});
export var FilterReturnTypes = /* @__PURE__ */ ((FilterReturnTypes2) => {
  FilterReturnTypes2["PrimaryVAT"] = "PrimaryVAT";
  FilterReturnTypes2["SecondaryVAT"] = "SecondaryVAT";
  FilterReturnTypes2["ESL"] = "ESL";
  FilterReturnTypes2["VLS"] = "VLS";
  FilterReturnTypes2["IOSS"] = "IOSS";
  FilterReturnTypes2["Intrastat"] = "Intrastat";
  FilterReturnTypes2["OSS"] = "OSS";
  return FilterReturnTypes2;
})(FilterReturnTypes || {});
