import { TswagClient } from "./../commons/TswagClient";
import { TemplateResponse } from "./../models/V1/TemplateMapping/TemplateResponse";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { VatRegTemplateSearchResult } from "./../models/V1/TemplateMapping/TemplateSearch/VatRegTemplateSearchResult";
import { CompanyTemplateSearchResult } from "./../models/V1/TemplateMapping/TemplateSearch/CompanyTemplateSearchResult";
import { TemplateVersion } from "./../models/V1/TemplateMapping/TemplateVersion";
import { MappingItem } from "./../models/V1/TemplateMapping/MappingItem";
import { PdfFieldResponse } from "./../models/V1/TemplateMapping/PdfFieldResponse";
export class Templates {
  /**
   * Creates an instance of Templates.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Templates
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GetTemplateRq_ _getTemplateRq
   * @returns Promise<GetTemplateRq_>
   * @memberof Templates
   */
  getTemplate(pGetTemplateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/{templateId}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "templateId", value: pGetTemplateRq.templateId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: TemplateResponse },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchVatRegTemplatesRq_ _searchVatRegTemplatesRq
   * @returns Promise<SearchVatRegTemplatesRq_>
   * @memberof Templates
   */
  searchVatRegTemplates(pSearchVatRegTemplatesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/search/vatregistration",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchVatRegTemplatesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: VatRegTemplateSearchResult }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchCompanyTemplatesRq_ _searchCompanyTemplatesRq
   * @returns Promise<SearchCompanyTemplatesRq_>
   * @memberof Templates
   */
  searchCompanyTemplates(pSearchCompanyTemplatesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/search/company",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchCompanyTemplatesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: CompanyTemplateSearchResult }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetTemplatesRq_ _getTemplatesRq
   * @returns Promise<GetTemplatesRq_>
   * @memberof Templates
   */
  getTemplates(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: TemplateResponse, isArray: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateTemplateRq_ _updateTemplateRq
   * @returns Promise<UpdateTemplateRq_>
   * @memberof Templates
   */
  updateTemplate(pUpdateTemplateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pUpdateTemplateRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param AddTemplateRq_ _addTemplateRq
   * @returns Promise<AddTemplateRq_>
   * @memberof Templates
   */
  addTemplate(pAddTemplateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pAddTemplateRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 201, clazz: TemplateResponse }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteTemplateVersionRq_ _deleteTemplateVersionRq
   * @returns Promise<DeleteTemplateVersionRq_>
   * @memberof Templates
   */
  deleteTemplateVersion(pDeleteTemplateVersionRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/{templateId}/{templateVersion}",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "templateId", value: pDeleteTemplateVersionRq.templateId, required: true },
      { name: "templateVersion", value: pDeleteTemplateVersionRq.templateVersion, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param PublishTemplateVersionRq_ _publishTemplateVersionRq
   * @returns Promise<PublishTemplateVersionRq_>
   * @memberof Templates
   */
  publishTemplateVersion(pPublishTemplateVersionRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/{templateId}/{templateVersion}/publish",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "templateId", value: pPublishTemplateVersionRq.templateId, required: true },
      { name: "templateVersion", value: pPublishTemplateVersionRq.templateVersion, required: true }
    ]);
    client.addBody({ value: pPublishTemplateVersionRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param EnableTemplateVersionRq_ _enableTemplateVersionRq
   * @returns Promise<EnableTemplateVersionRq_>
   * @memberof Templates
   */
  enableTemplateVersion(pEnableTemplateVersionRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/{templateId}/{templateVersion}/enable",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "templateId", value: pEnableTemplateVersionRq.templateId, required: true },
      { name: "templateVersion", value: pEnableTemplateVersionRq.templateVersion, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DisableTemplateVersionRq_ _disableTemplateVersionRq
   * @returns Promise<DisableTemplateVersionRq_>
   * @memberof Templates
   */
  disableTemplateVersion(pDisableTemplateVersionRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/{templateId}/{templateVersion}/enable",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "templateId", value: pDisableTemplateVersionRq.templateId, required: true },
      { name: "templateVersion", value: pDisableTemplateVersionRq.templateVersion, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetTemplateVersionRq_ _getTemplateVersionRq
   * @returns Promise<GetTemplateVersionRq_>
   * @memberof Templates
   */
  getTemplateVersion(pGetTemplateVersionRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/{templateId}/versions/{templateVersion}",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "templateId", value: pGetTemplateVersionRq.templateId, required: true },
      { name: "templateVersion", value: pGetTemplateVersionRq.templateVersion, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: TemplateVersion },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CreateDraftTemplateVersionRq_ _createDraftTemplateVersionRq
   * @returns Promise<CreateDraftTemplateVersionRq_>
   * @memberof Templates
   */
  createDraftTemplateVersion(pCreateDraftTemplateVersionRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/{templateId}/versions/draft",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "templateId", value: pCreateDraftTemplateVersionRq.templateId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: TemplateVersion }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CloneTemplateVersionToDraftRq_ _cloneTemplateVersionToDraftRq
   * @returns Promise<CloneTemplateVersionToDraftRq_>
   * @memberof Templates
   */
  cloneTemplateVersionToDraft(pCloneTemplateVersionToDraftRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/{templateId}/versions/{templateVersion}/clone",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "templateId", value: pCloneTemplateVersionToDraftRq.templateId, required: true },
      { name: "templateVersion", value: pCloneTemplateVersionToDraftRq.templateVersion, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: TemplateVersion },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SaveMappingsRq_ _saveMappingsRq
   * @returns Promise<SaveMappingsRq_>
   * @memberof Templates
   */
  saveMappings(pSaveMappingsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/{templateId}/versions/{templateVersion}/mappings",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "templateId", value: pSaveMappingsRq.templateId, required: true },
      { name: "templateVersion", value: pSaveMappingsRq.templateVersion, required: true }
    ]);
    client.addBody({ value: pSaveMappingsRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: MappingItem, isArray: true },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RemoveMappingRq_ _removeMappingRq
   * @returns Promise<RemoveMappingRq_>
   * @memberof Templates
   */
  removeMapping(pRemoveMappingRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/{templateId}/versions/{templateVersion}/mappings/{pdfFieldName}",
      "DELETE",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "templateId", value: pRemoveMappingRq.templateId, required: true },
      { name: "templateVersion", value: pRemoveMappingRq.templateVersion, required: true },
      { name: "pdfFieldName", value: pRemoveMappingRq.pdfFieldName, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadPdfTemplateRq_ _uploadPdfTemplateRq
   * @returns Promise<UploadPdfTemplateRq_>
   * @memberof Templates
   */
  uploadPdfTemplate(pUploadPdfTemplateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/{templateId}/versions/{templateVersion}/uploadPdfTemplate",
      "PUT",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "templateId", value: pUploadPdfTemplateRq.templateId, required: true },
      { name: "templateVersion", value: pUploadPdfTemplateRq.templateVersion, required: true }
    ]);
    client.addFormData("multipart/form-data", [
      { name: "file", value: pUploadPdfTemplateRq.file }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: PdfFieldResponse },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadPdfTemplateRq_ _downloadPdfTemplateRq
   * @returns Promise<DownloadPdfTemplateRq_>
   * @memberof Templates
   */
  downloadPdfTemplate(pDownloadPdfTemplateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Templates/{templateId}/versions/{templateVersion}/downloadPdfTemplate",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([
      { name: "templateId", value: pDownloadPdfTemplateRq.templateId, required: true },
      { name: "templateVersion", value: pDownloadPdfTemplateRq.templateVersion, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
