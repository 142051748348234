import { ReturnInformationFactory } from "./ReturnInformationFactory";
import { SubmissionPeriodStatusChange } from "./SubmissionPeriodStatusChange";
export class ReturnGenerated extends SubmissionPeriodStatusChange {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof ReturnGenerated
     */
    this.$type = "V1.Submission.StatusChanges.ReturnGenerated";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      const $typeReturnInformation = _data["ReturnInformation"] && _data["ReturnInformation"].$type;
      if ($typeReturnInformation) {
        const returnInformation_ = ReturnInformationFactory.create($typeReturnInformation);
        this.ReturnInformation = returnInformation_.init(_data["ReturnInformation"]);
      } else {
        this.ReturnInformation = _data["ReturnInformation"];
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["ReturnInformation"] = this.ReturnInformation ? this.ReturnInformation.toJSON() : void 0;
    return _data;
  }
}
