import { useCallback } from "GlobalShared/haunted/CustomHooks";
const ERROR_SCRIPT_NOT_AVAILABLE = "Google recaptcha is not available";
const ERROR_FORM_NOT_FOUND = "Form not found";
export const useGoogleReCaptcha = (formId, action) => {
  const submitWithCaptcha = useCallback(
    (e) => {
      e.preventDefault();
      const projectWindow = window;
      const { grecaptcha } = projectWindow;
      if (!grecaptcha) {
        throw new Error(ERROR_SCRIPT_NOT_AVAILABLE);
      }
      const form = document.getElementById(formId);
      if (!form) {
        throw new Error(ERROR_FORM_NOT_FOUND);
      }
      grecaptcha.ready(() => {
        grecaptcha.execute(ClientContext.RecaptchaSiteKey, { action }).then((newToken) => {
          const element = document.getElementById("recaptchaToken");
          element.value = newToken;
          form.submit();
        });
      });
    },
    [formId, action]
  );
  return submitWithCaptcha;
};
