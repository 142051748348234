import { Address } from "./../../../Common/Address";
import { DateConverter } from "./../../../../../converters/DateConverter";
export class LegalRepresentative {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof LegalRepresentative
     */
    this.$type = "V1.CompanyInfo.Questionnaire.LegalRepresentative.LegalRepresentative";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Address"]) {
        const address_ = new Address();
        this.Address = address_.init(_data["Address"]);
      }
      this.AddressCountry = _data["AddressCountry"];
      this.BirthName = _data["BirthName"];
      this.CityOfBirth = _data["CityOfBirth"];
      this.CountryOfBirth = _data["CountryOfBirth"];
      this.DateOfBirth = DateConverter.from(_data["DateOfBirth"]);
      this.DocumentExpirationDate = DateConverter.from(_data["DocumentExpirationDate"]);
      this.DocumentNumber = _data["DocumentNumber"];
      this.DocumentType = LegalRepresentativeDocumentType[_data["DocumentType"]];
      this.Email = _data["Email"];
      this.FatherName = _data["FatherName"];
      this.FirstName = _data["FirstName"];
      this.FiscalCode = _data["FiscalCode"];
      this.HomeAddressIsSameAsRegistered = _data["HomeAddressIsSameAsRegistered"];
      this.IsOnlyDirector = _data["IsOnlyDirector"];
      this.LastName = _data["LastName"];
      this.MiddleName = _data["MiddleName"];
      this.MotherBirthName = _data["MotherBirthName"];
      this.Nationality = _data["Nationality"];
      this.OwnMoreThan25Percent = _data["OwnMoreThan25Percent"];
      this.Phone = _data["Phone"];
      this.PhotoCopyReference = _data["PhotoCopyReference"];
      this.Position = LegalRepresentativePosition[_data["Position"]];
      this.PositionDescription = _data["PositionDescription"];
      this.Title = LegalRepresentativeTitle[_data["Title"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Address"] = this.Address ? this.Address.toJSON() : void 0;
    _data["AddressCountry"] = this.AddressCountry;
    _data["BirthName"] = this.BirthName;
    _data["CityOfBirth"] = this.CityOfBirth;
    _data["CountryOfBirth"] = this.CountryOfBirth;
    _data["DateOfBirth"] = DateConverter.toUtcDateString(this.DateOfBirth);
    _data["DocumentExpirationDate"] = DateConverter.toUtcDateString(this.DocumentExpirationDate);
    _data["DocumentNumber"] = this.DocumentNumber;
    _data["DocumentType"] = LegalRepresentativeDocumentType[this.DocumentType];
    _data["Email"] = this.Email;
    _data["FatherName"] = this.FatherName;
    _data["FirstName"] = this.FirstName;
    _data["FiscalCode"] = this.FiscalCode;
    _data["HomeAddressIsSameAsRegistered"] = this.HomeAddressIsSameAsRegistered;
    _data["IsOnlyDirector"] = this.IsOnlyDirector;
    _data["LastName"] = this.LastName;
    _data["MiddleName"] = this.MiddleName;
    _data["MotherBirthName"] = this.MotherBirthName;
    _data["Nationality"] = this.Nationality;
    _data["OwnMoreThan25Percent"] = this.OwnMoreThan25Percent;
    _data["Phone"] = this.Phone;
    _data["PhotoCopyReference"] = this.PhotoCopyReference;
    _data["Position"] = LegalRepresentativePosition[this.Position];
    _data["PositionDescription"] = this.PositionDescription;
    _data["Title"] = LegalRepresentativeTitle[this.Title];
    return _data;
  }
}
export var LegalRepresentativeDocumentType = /* @__PURE__ */ ((LegalRepresentativeDocumentType2) => {
  LegalRepresentativeDocumentType2["Passport"] = "Passport";
  LegalRepresentativeDocumentType2["PersonalId"] = "PersonalId";
  LegalRepresentativeDocumentType2["DrivingLicense"] = "DrivingLicense";
  return LegalRepresentativeDocumentType2;
})(LegalRepresentativeDocumentType || {});
export var LegalRepresentativePosition = /* @__PURE__ */ ((LegalRepresentativePosition2) => {
  LegalRepresentativePosition2["Owner"] = "Owner";
  LegalRepresentativePosition2["Director"] = "Director";
  LegalRepresentativePosition2["Ceo"] = "Ceo";
  LegalRepresentativePosition2["Other"] = "Other";
  return LegalRepresentativePosition2;
})(LegalRepresentativePosition || {});
export var LegalRepresentativeTitle = /* @__PURE__ */ ((LegalRepresentativeTitle2) => {
  LegalRepresentativeTitle2["Mr"] = "Mr";
  LegalRepresentativeTitle2["Mrs"] = "Mrs";
  LegalRepresentativeTitle2["Ms"] = "Ms";
  return LegalRepresentativeTitle2;
})(LegalRepresentativeTitle || {});
