import { AbstractValidator } from "fluent-ts-validator";
import { DCValidationResult } from "GlobalShared/haunted/HooksHelpers";
import i18next from "i18next";
import {
  AddressValidator,
  BankInformationValidator,
  BeneficialOwnersValidator,
  BoolValidator,
  BusinessOwnersValidator,
  CountryValidator,
  DateValidator,
  EnumAnswerValidator,
  ExistingVatNumbersValidator,
  FileRefValidator,
  FixedEstablishmentsValidator,
  LegalRepsValidator,
  MoneyValidator,
  MultiCountryValidator,
  MultiStringValidator,
  OssDetailValidator,
  StringValidator,
  TurnOversValidator,
  VatNumberValidator
} from "./AnswerValidators";
import { INVALID_MESSAGE, REQUIRED_MESSAGE } from "GlobalShared/helpers/stringHelper";
export class Q11eValidator extends AbstractValidator {
  constructor(q11e) {
    super();
    this.validateStringAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new StringValidator({ required: true })).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateDateAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new DateValidator({ required: true })).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateMultiStringAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new MultiStringValidator({ required: true })).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateMoneyAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new MoneyValidator({ required: true })).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateAddressAnswer = (fieldName, withCounty) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new AddressValidator({ required: true }, withCounty)).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateBoolAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new BoolValidator({ required: true })).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateFileRefAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new FileRefValidator({ required: true })).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateExistingVatNumbersAnswer = (fieldName) => {
      this.validateIf(
        (context) => ({
          value: (context == null ? void 0 : context.getAnswer(fieldName)).Value
        })
      ).fulfills(new ExistingVatNumbersValidator(false)).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateLegalRepsAnswer = (fieldName, q11e) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new LegalRepsValidator({}, q11e, false)).withPropertyName(fieldName).withFailureMessage(INVALID_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
      this.validateIfNumber((context) => (context == null ? void 0 : context.getAnswer(fieldName)).Value.length).isGreaterThan(0).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateBusinessOwnersAnswer = (fieldName, q11e) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new BusinessOwnersValidator({}, q11e)).withPropertyName(fieldName).withFailureMessage(INVALID_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
      this.validateIfNumber((context) => (context == null ? void 0 : context.getAnswer(fieldName)).Value.length).isGreaterThan(0).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateBeneficialOwnersAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new BeneficialOwnersValidator()).withPropertyName(fieldName).withFailureMessage(INVALID_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
      this.validateIfNumber((context) => (context == null ? void 0 : context.getAnswer(fieldName)).Value.length).isGreaterThan(0).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateBankInformationAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new BankInformationValidator({})).withPropertyName(fieldName).withFailureMessage(INVALID_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateVatNumberAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new VatNumberValidator({ required: true })).withPropertyName(fieldName).withFailureMessage(i18next.t(`Invalid ({{-fieldName}})`, { fieldName })).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateFixedEstablishmentsAnswer = (fieldName, q11e) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new FixedEstablishmentsValidator({}, q11e)).withPropertyName(fieldName).withFailureMessage(INVALID_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
      this.validateIfNumber((context) => (context == null ? void 0 : context.getAnswer(fieldName)).Value.length).isGreaterThan(0).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateCountryAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new CountryValidator({ required: true })).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateMultiCountryAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new MultiCountryValidator()).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateEnumAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new EnumAnswerValidator()).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateOssDetailAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new OssDetailValidator()).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateTurnOversAnswer = (fieldName) => {
      this.validateIf((context) => context == null ? void 0 : context.getAnswer(fieldName)).fulfills(new TurnOversValidator()).withPropertyName(fieldName).withFailureMessage(INVALID_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
      this.validateIfNumber((context) => (context == null ? void 0 : context.getAnswer(fieldName)).Value.length).isGreaterThan(0).withPropertyName(fieldName).withFailureMessage(REQUIRED_MESSAGE).when((context) => context == null ? void 0 : context.isVisible(fieldName));
    };
    this.validateStringAnswer("comp_reg_num");
    this.validateDateAnswer("incorp_date");
    this.validateMoneyAnswer("amount_of_cap");
    this.validateFileRefAnswer("business_cert");
    this.validateFileRefAnswer("article_assoc");
    this.validateBoolAnswer("corresp_is_same");
    this.validateAddressAnswer("corresp_address", true);
    this.validateVatNumberAnswer("uk_vat");
    this.validateMoneyAnswer("uk_annual");
    this.validateVatNumberAnswer("de_vat");
    this.validateMoneyAnswer("de_annual");
    this.validateStringAnswer("de_storage_address");
    this.validateStringAnswer("de_local_tax");
    this.validateDateAnswer("de_store_start");
    this.validateCountryAnswer("de_import_country");
    this.validateMoneyAnswer("de_est_profit");
    this.validateMoneyAnswer("de_est_vat");
    this.validateEnumAnswer("de_product_type");
    this.validateVatNumberAnswer("at_vat");
    this.validateMoneyAnswer("at_annual");
    this.validateStringAnswer("at_local_tax");
    this.validateEnumAnswer("at_product_type");
    this.validateVatNumberAnswer("be_vat");
    this.validateMoneyAnswer("be_annual");
    this.validateVatNumberAnswer("ie_vat");
    this.validateMoneyAnswer("ie_annual");
    this.validateVatNumberAnswer("nl_vat");
    this.validateMoneyAnswer("nl_annual");
    this.validateVatNumberAnswer("dk_vat");
    this.validateMoneyAnswer("dk_annual");
    this.validateVatNumberAnswer("ro_vat");
    this.validateMoneyAnswer("ro_annual");
    this.validateVatNumberAnswer("se_vat");
    this.validateMoneyAnswer("se_annual");
    this.validateVatNumberAnswer("pt_vat");
    this.validateMoneyAnswer("pt_annual");
    this.validateVatNumberAnswer("hu_vat");
    this.validateMoneyAnswer("hu_annual");
    this.validateVatNumberAnswer("sk_vat");
    this.validateMoneyAnswer("sk_annual");
    this.validateVatNumberAnswer("fi_vat");
    this.validateMoneyAnswer("fi_annual");
    this.validateVatNumberAnswer("el_vat");
    this.validateMoneyAnswer("el_annual");
    this.validateVatNumberAnswer("lu_vat");
    this.validateMoneyAnswer("lu_annual");
    this.validateVatNumberAnswer("bg_vat");
    this.validateMoneyAnswer("bg_annual");
    this.validateVatNumberAnswer("hr_vat");
    this.validateMoneyAnswer("hr_annual");
    this.validateVatNumberAnswer("cy_vat");
    this.validateMoneyAnswer("cy_annual");
    this.validateVatNumberAnswer("ee_vat");
    this.validateMoneyAnswer("ee_annual");
    this.validateVatNumberAnswer("lt_vat");
    this.validateMoneyAnswer("lt_annual");
    this.validateVatNumberAnswer("lv_vat");
    this.validateMoneyAnswer("lv_annual");
    this.validateVatNumberAnswer("mt_vat");
    this.validateMoneyAnswer("mt_annual");
    this.validateVatNumberAnswer("si_vat");
    this.validateMoneyAnswer("si_annual");
    this.validateVatNumberAnswer("fr_vat");
    this.validateMoneyAnswer("fr_annual");
    this.validateBoolAnswer("fr_vat_liability");
    this.validateBoolAnswer("fr_supplies");
    this.validateBoolAnswer("fr_acquisitions");
    this.validateMultiCountryAnswer("fr_cntry_disp");
    this.validateMultiCountryAnswer("fr_cntry_inv");
    this.validateStringAnswer("prev_fisc_rep");
    this.validateAddressAnswer("fisc_rep_address", false);
    this.validateVatNumberAnswer("it_vat");
    this.validateMoneyAnswer("it_annual");
    this.validateBoolAnswer("it_emp");
    this.validateBoolAnswer("it_sales");
    this.validateBoolAnswer("it_dist_sales");
    this.validateTurnOversAnswer("it_turnovers");
    this.validateFileRefAnswer("it_vat_cert");
    this.validateVatNumberAnswer("es_vat");
    this.validateMoneyAnswer("es_annual");
    this.validateVatNumberAnswer("cz_vat");
    this.validateMoneyAnswer("cz_annual");
    this.validateVatNumberAnswer("pl_vat");
    this.validateMoneyAnswer("pl_annual");
    this.validateExistingVatNumbersAnswer("exs_vats");
    this.validateBoolAnswer("other_vats");
    this.validateVatNumberAnswer("estab_vat");
    this.validateFileRefAnswer("estab_vat_cert");
    if (q11e !== void 0 && !q11e.isIndividual()) {
      this.validateStringAnswer("com_reg_name");
    }
    this.validateStringAnswer("tax_auth_name");
    this.validateBoolAnswer("is_on_stock");
    this.validateStringAnswer("stock_name");
    this.validateStringAnswer("mp_store_name");
    this.validateStringAnswer("sell_id");
    this.validateMultiStringAnswer("mp_name");
    this.validateStringAnswer("web_link");
    this.validateLegalRepsAnswer("legal_rep", q11e);
    this.validateBusinessOwnersAnswer("business_owner", q11e);
    this.validateBeneficialOwnersAnswer("beneficial_owner");
    this.validateBoolAnswer("has_pl_bank");
    this.validateBankInformationAnswer("pl_bank");
    this.validateBankInformationAnswer("other_bank");
    this.validateFileRefAnswer("bank_cert");
    this.validateMultiStringAnswer("ioss_exs_ioss");
    this.validateStringAnswer("ioss_web_link");
    this.validateFixedEstablishmentsAnswer("ioss_fixed_estab", q11e);
    this.validateBoolAnswer("oss_has_registration");
    this.validateOssDetailAnswer("oss_details");
    this.validateEnumAnswer("account_loc");
    this.validateEnumAnswer("leg_ent_from");
    this.validateEnumAnswer("leg_ent_type");
    this.validateEnumAnswer("client_base");
    this.validateAddressAnswer("account_addrs", true);
    this.validateStringAnswer("commercial_reg_num");
    this.validateDateAnswer("commercial_reg_date");
    this.validateStringAnswer("name_of_com_auth");
  }
  validateModel(item) {
    return new DCValidationResult(super.validate(item));
  }
}
