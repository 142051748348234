export class ValidationMessage {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof ValidationMessage
     */
    this.$type = "V1.ComplianceUploads.ValidationMessage";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ColumnCode = ValidationMessageColumnCode[_data["ColumnCode"]];
      this.ColumnName = ValidationMessageColumnName[_data["ColumnName"]];
      this.IsWarning = _data["IsWarning"];
      if (_data["Parameters"] && _data["Parameters"].constructor === Array) {
        this.Parameters = [];
        for (let item of _data["Parameters"]) {
          this.Parameters.push(item);
        }
      }
      this.RowNumber = _data["RowNumber"];
      this.TotalCount = _data["TotalCount"];
      this.ValidationCode = ValidationMessageValidationCode[_data["ValidationCode"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ColumnCode"] = ValidationMessageColumnCode[this.ColumnCode];
    _data["ColumnName"] = ValidationMessageColumnName[this.ColumnName];
    _data["IsWarning"] = this.IsWarning;
    if (this.Parameters && this.Parameters.constructor === Array) {
      _data["Parameters"] = [];
      for (let item of this.Parameters) {
        _data["Parameters"].push(item);
      }
    }
    _data["RowNumber"] = this.RowNumber;
    _data["TotalCount"] = this.TotalCount;
    _data["ValidationCode"] = ValidationMessageValidationCode[this.ValidationCode];
    return _data;
  }
}
export var ValidationMessageColumnCode = /* @__PURE__ */ ((ValidationMessageColumnCode2) => {
  ValidationMessageColumnCode2["A"] = "A";
  ValidationMessageColumnCode2["B"] = "B";
  ValidationMessageColumnCode2["C"] = "C";
  ValidationMessageColumnCode2["D"] = "D";
  ValidationMessageColumnCode2["E"] = "E";
  ValidationMessageColumnCode2["F"] = "F";
  ValidationMessageColumnCode2["G"] = "G";
  ValidationMessageColumnCode2["H"] = "H";
  ValidationMessageColumnCode2["I"] = "I";
  ValidationMessageColumnCode2["J"] = "J";
  ValidationMessageColumnCode2["K"] = "K";
  ValidationMessageColumnCode2["L"] = "L";
  ValidationMessageColumnCode2["M"] = "M";
  ValidationMessageColumnCode2["N"] = "N";
  ValidationMessageColumnCode2["O"] = "O";
  ValidationMessageColumnCode2["P"] = "P";
  ValidationMessageColumnCode2["Q"] = "Q";
  ValidationMessageColumnCode2["R"] = "R";
  ValidationMessageColumnCode2["S"] = "S";
  ValidationMessageColumnCode2["T"] = "T";
  ValidationMessageColumnCode2["U"] = "U";
  ValidationMessageColumnCode2["V"] = "V";
  ValidationMessageColumnCode2["W"] = "W";
  ValidationMessageColumnCode2["X"] = "X";
  ValidationMessageColumnCode2["Y"] = "Y";
  ValidationMessageColumnCode2["Z"] = "Z";
  ValidationMessageColumnCode2["AA"] = "AA";
  ValidationMessageColumnCode2["AB"] = "AB";
  ValidationMessageColumnCode2["AC"] = "AC";
  ValidationMessageColumnCode2["AD"] = "AD";
  ValidationMessageColumnCode2["AE"] = "AE";
  ValidationMessageColumnCode2["AF"] = "AF";
  ValidationMessageColumnCode2["AG"] = "AG";
  ValidationMessageColumnCode2["AH"] = "AH";
  ValidationMessageColumnCode2["AI"] = "AI";
  ValidationMessageColumnCode2["AJ"] = "AJ";
  ValidationMessageColumnCode2["AK"] = "AK";
  ValidationMessageColumnCode2["AL"] = "AL";
  ValidationMessageColumnCode2["AM"] = "AM";
  ValidationMessageColumnCode2["AN"] = "AN";
  ValidationMessageColumnCode2["AO"] = "AO";
  ValidationMessageColumnCode2["AP"] = "AP";
  ValidationMessageColumnCode2["AQ"] = "AQ";
  ValidationMessageColumnCode2["AR"] = "AR";
  ValidationMessageColumnCode2["AS"] = "AS";
  ValidationMessageColumnCode2["AT"] = "AT";
  ValidationMessageColumnCode2["AU"] = "AU";
  ValidationMessageColumnCode2["AV"] = "AV";
  ValidationMessageColumnCode2["AW"] = "AW";
  ValidationMessageColumnCode2["AX"] = "AX";
  ValidationMessageColumnCode2["AY"] = "AY";
  ValidationMessageColumnCode2["AZ"] = "AZ";
  ValidationMessageColumnCode2["BA"] = "BA";
  ValidationMessageColumnCode2["BB"] = "BB";
  ValidationMessageColumnCode2["BC"] = "BC";
  ValidationMessageColumnCode2["BD"] = "BD";
  ValidationMessageColumnCode2["BE"] = "BE";
  ValidationMessageColumnCode2["BF"] = "BF";
  ValidationMessageColumnCode2["BG"] = "BG";
  ValidationMessageColumnCode2["BH"] = "BH";
  ValidationMessageColumnCode2["BI"] = "BI";
  ValidationMessageColumnCode2["BJ"] = "BJ";
  ValidationMessageColumnCode2["BK"] = "BK";
  ValidationMessageColumnCode2["BL"] = "BL";
  return ValidationMessageColumnCode2;
})(ValidationMessageColumnCode || {});
export var ValidationMessageColumnName = /* @__PURE__ */ ((ValidationMessageColumnName2) => {
  ValidationMessageColumnName2["TransactionType"] = "TransactionType";
  ValidationMessageColumnName2["TransactionDate"] = "TransactionDate";
  ValidationMessageColumnName2["DestinationCountry"] = "DestinationCountry";
  ValidationMessageColumnName2["Currency"] = "Currency";
  ValidationMessageColumnName2["VatRate"] = "VatRate";
  ValidationMessageColumnName2["GrossAmount"] = "GrossAmount";
  ValidationMessageColumnName2["TransactionIdentifier"] = "TransactionIdentifier";
  ValidationMessageColumnName2["DateOfSupply"] = "DateOfSupply";
  ValidationMessageColumnName2["PaymentDate"] = "PaymentDate";
  ValidationMessageColumnName2["InvoiceDate"] = "InvoiceDate";
  ValidationMessageColumnName2["Description"] = "Description";
  ValidationMessageColumnName2["Quantity"] = "Quantity";
  ValidationMessageColumnName2["DepartureCountry"] = "DepartureCountry";
  ValidationMessageColumnName2["TaxableBasis"] = "TaxableBasis";
  ValidationMessageColumnName2["VatAmount"] = "VatAmount";
  ValidationMessageColumnName2["ConsignmentNumber"] = "ConsignmentNumber";
  ValidationMessageColumnName2["InvoiceCountry"] = "InvoiceCountry";
  ValidationMessageColumnName2["CommodityCode"] = "CommodityCode";
  ValidationMessageColumnName2["ExchangeRate"] = "ExchangeRate";
  ValidationMessageColumnName2["ReportingGrossAmount"] = "ReportingGrossAmount";
  ValidationMessageColumnName2["ReportingTaxableBasis"] = "ReportingTaxableBasis";
  ValidationMessageColumnName2["ReportingVatAmount"] = "ReportingVatAmount";
  ValidationMessageColumnName2["InvoiceNumber"] = "InvoiceNumber";
  return ValidationMessageColumnName2;
})(ValidationMessageColumnName || {});
export var ValidationMessageValidationCode = /* @__PURE__ */ ((ValidationMessageValidationCode2) => {
  ValidationMessageValidationCode2["WrongTemplateColumns"] = "WrongTemplateColumns";
  ValidationMessageValidationCode2["WrongHeader"] = "WrongHeader";
  ValidationMessageValidationCode2["TaxableBasisReportingExceededTheLimit"] = "TaxableBasisReportingExceededTheLimit";
  ValidationMessageValidationCode2["OutOfPeriodTransactions"] = "OutOfPeriodTransactions";
  ValidationMessageValidationCode2["Required"] = "Required";
  ValidationMessageValidationCode2["RequiredOrWrongFormat"] = "RequiredOrWrongFormat";
  ValidationMessageValidationCode2["RequiredReportingFields"] = "RequiredReportingFields";
  ValidationMessageValidationCode2["WrongExchangeRate"] = "WrongExchangeRate";
  ValidationMessageValidationCode2["InvalidData"] = "InvalidData";
  ValidationMessageValidationCode2["InvalidDecimal"] = "InvalidDecimal";
  ValidationMessageValidationCode2["ConstraintNotFulfilled"] = "ConstraintNotFulfilled";
  ValidationMessageValidationCode2["TransactionIsInTheFuture"] = "TransactionIsInTheFuture";
  ValidationMessageValidationCode2["TransactionIsBeforeService"] = "TransactionIsBeforeService";
  ValidationMessageValidationCode2["UnsupportedCurrency"] = "UnsupportedCurrency";
  return ValidationMessageValidationCode2;
})(ValidationMessageValidationCode || {});
