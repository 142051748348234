import { DateConverter } from "./../../../../converters/DateConverter";
import { Money } from "./../../Common/Money";
export class SearchOrder {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SearchOrder
     */
    this.$type = "V1.Orders.Search.SearchOrder";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientSource = SearchOrderClientSource[_data["ClientSource"]];
      this.CompanyId = _data["CompanyId"];
      this.CompanyName = _data["CompanyName"];
      this.ConfirmedAt = DateConverter.from(_data["ConfirmedAt"]);
      this.ContractId = _data["ContractId"];
      this.CreatedAt = DateConverter.from(_data["CreatedAt"]);
      this.HasContractReference = _data["HasContractReference"];
      this.HasProcessingFailure = _data["HasProcessingFailure"];
      this.Id = _data["Id"];
      this.OrderPaymentStatus = SearchOrderOrderPaymentStatus[_data["OrderPaymentStatus"]];
      this.Status = SearchOrderStatus[_data["Status"]];
      if (_data["TotalPrice"]) {
        const totalPrice_ = new Money();
        this.TotalPrice = totalPrice_.init(_data["TotalPrice"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientSource"] = SearchOrderClientSource[this.ClientSource];
    _data["CompanyId"] = this.CompanyId;
    _data["CompanyName"] = this.CompanyName;
    _data["ConfirmedAt"] = DateConverter.toIsoUtcString(this.ConfirmedAt);
    _data["ContractId"] = this.ContractId;
    _data["CreatedAt"] = DateConverter.toIsoUtcString(this.CreatedAt);
    _data["HasContractReference"] = this.HasContractReference;
    _data["HasProcessingFailure"] = this.HasProcessingFailure;
    _data["Id"] = this.Id;
    _data["OrderPaymentStatus"] = SearchOrderOrderPaymentStatus[this.OrderPaymentStatus];
    _data["Status"] = SearchOrderStatus[this.Status];
    _data["TotalPrice"] = this.TotalPrice ? this.TotalPrice.toJSON() : void 0;
    return _data;
  }
}
export var SearchOrderClientSource = /* @__PURE__ */ ((SearchOrderClientSource2) => {
  SearchOrderClientSource2["Amazon"] = "Amazon";
  SearchOrderClientSource2["KPMG"] = "KPMG";
  SearchOrderClientSource2["FedEx"] = "FedEx";
  SearchOrderClientSource2["LaPoste"] = "LaPoste";
  return SearchOrderClientSource2;
})(SearchOrderClientSource || {});
export var SearchOrderOrderPaymentStatus = /* @__PURE__ */ ((SearchOrderOrderPaymentStatus2) => {
  SearchOrderOrderPaymentStatus2["None"] = "None";
  SearchOrderOrderPaymentStatus2["Pending"] = "Pending";
  SearchOrderOrderPaymentStatus2["Approved"] = "Approved";
  return SearchOrderOrderPaymentStatus2;
})(SearchOrderOrderPaymentStatus || {});
export var SearchOrderStatus = /* @__PURE__ */ ((SearchOrderStatus2) => {
  SearchOrderStatus2["Pending"] = "Pending";
  SearchOrderStatus2["Cancelled"] = "Cancelled";
  SearchOrderStatus2["Draft"] = "Draft";
  SearchOrderStatus2["Confirmed"] = "Confirmed";
  SearchOrderStatus2["Abandoned"] = "Abandoned";
  return SearchOrderStatus2;
})(SearchOrderStatus || {});
