export const CompanyLegalStatusTypes = ["Company", "Individual", "Partnership"];
export const ClientAcceptanceTypes = ["Pending", "MFE", "Failed", "Completed"];
export const NameTitleTypes = ["Mr", "Mrs", "Ms"];
export const CSVTypes = ["CompaniesCSV", "SubscriptionsCSV", "VATNumberCSV", "SellerAccountsCSV"];
export const FrequencyTypes = ["Monthly", "Quarterly", "QuarterlyShifted1", "QuarterlyShifted2", "BiMonthly", "FourMonthly", "SixMonthly", "Yearly"];
export const ExtendedFrequencyTypeMap = /* @__PURE__ */ new Map([
  ["ShiftedQuarterly1", "QuarterlyShifted1"],
  ["ShiftedQuarterly2", "QuarterlyShifted2"]
]);
export const RegistrationStatusTypes = ["LocalTaxReceived", "RegistrationCompleted"];
