import { SubscriptionSearch } from "./SubscriptionSearch";
export class VatRegistrationSubscriptionSearch extends SubscriptionSearch {
  constructor(_data) {
    super();
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRegistrationSubscriptionSearch
     */
    this.$type = "V1.Subscriptions.VatRegistrationSubscriptionSearch";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.CompanyId = _data["CompanyId"];
      this.EventType = VatRegistrationSubscriptionSearchEventType[_data["EventType"]];
      this.VatRegistrationId = _data["VatRegistrationId"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["CompanyId"] = this.CompanyId;
    _data["EventType"] = VatRegistrationSubscriptionSearchEventType[this.EventType];
    _data["VatRegistrationId"] = this.VatRegistrationId;
    return _data;
  }
}
export var VatRegistrationSubscriptionSearchEventType = /* @__PURE__ */ ((VatRegistrationSubscriptionSearchEventType2) => {
  VatRegistrationSubscriptionSearchEventType2["ESLTaxReturnType"] = "ESLTaxReturnType";
  VatRegistrationSubscriptionSearchEventType2["VLSTaxReturnType"] = "VLSTaxReturnType";
  return VatRegistrationSubscriptionSearchEventType2;
})(VatRegistrationSubscriptionSearchEventType || {});
