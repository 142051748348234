import { TswagClient } from "./../commons/TswagClient";
export class Reporting {
  /**
   * Creates an instance of Reporting.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Reporting
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GenerateAccountReportRq_ _generateAccountReportRq
   * @returns Promise<GenerateAccountReportRq_>
   * @memberof Reporting
   */
  generateAccountReport(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Reporting/Accounts",
      "POST",
      "application/json; charset=UTF-8",
      "text/csv",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GenerateClientRegistrationReportRq_ _generateClientRegistrationReportRq
   * @returns Promise<GenerateClientRegistrationReportRq_>
   * @memberof Reporting
   */
  generateClientRegistrationReport(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Reporting/ClientRegistrations",
      "POST",
      "application/json; charset=UTF-8",
      "text/csv",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GenerateSubscriptionFeedbackReportRq_ _generateSubscriptionFeedbackReportRq
   * @returns Promise<GenerateSubscriptionFeedbackReportRq_>
   * @memberof Reporting
   */
  generateSubscriptionFeedbackReport(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Reporting/SubscriptionFeedbacks",
      "GET",
      "application/json; charset=UTF-8",
      "text/csv",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
