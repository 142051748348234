import { AmountRange } from "./../../Primitives/AmountRange";
import { DateRange } from "./../../Common/DateTime/DateRange";
export class Filter {
  constructor(_data) {
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Filter
     */
    this.$type = "V1.VatRateTypeSettings.Search.Filter";
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CountryCode = _data["CountryCode"];
      this.LatestOnly = _data["LatestOnly"];
      if (_data["RateRange"]) {
        const rateRange_ = new AmountRange();
        this.RateRange = rateRange_.init(_data["RateRange"]);
      }
      this.Type = FilterType[_data["Type"]];
      if (_data["ValidFromRange"]) {
        const validFromRange_ = new DateRange();
        this.ValidFromRange = validFromRange_.init(_data["ValidFromRange"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CountryCode"] = this.CountryCode;
    _data["LatestOnly"] = this.LatestOnly;
    _data["RateRange"] = this.RateRange ? this.RateRange.toJSON() : void 0;
    _data["Type"] = FilterType[this.Type];
    _data["ValidFromRange"] = this.ValidFromRange ? this.ValidFromRange.toJSON() : void 0;
    return _data;
  }
}
export var FilterType = /* @__PURE__ */ ((FilterType2) => {
  FilterType2["Standard"] = "Standard";
  FilterType2["Standard2"] = "Standard2";
  FilterType2["Reduced"] = "Reduced";
  FilterType2["Reduced2"] = "Reduced2";
  FilterType2["SuperReduced"] = "SuperReduced";
  FilterType2["SuperReduced2"] = "SuperReduced2";
  FilterType2["Exempt"] = "Exempt";
  FilterType2["ZeroRated"] = "ZeroRated";
  FilterType2["Middle"] = "Middle";
  return FilterType2;
})(FilterType || {});
