import { MappingExpressionBase } from "./MappingExpressionBase";
import { ConcatExpression } from "./ConcatExpression";
import { ConditionExpression } from "./ConditionExpression";
import { DateExpression } from "./DateExpression";
import { EnumExpression } from "./EnumExpression";
import { IdentityExpression } from "./IdentityExpression";
import { SubstringExpression } from "./SubstringExpression";
export class MappingExpressionBaseFactory {
  static create($type) {
    if ($type === "V1.TemplateMapping.MappingExpressionBase") {
      const result = new MappingExpressionBase();
      return result;
    }
    if ($type === "V1.TemplateMapping.ConcatExpression") {
      const result = new ConcatExpression();
      return result;
    }
    if ($type === "V1.TemplateMapping.ConditionExpression") {
      const result = new ConditionExpression();
      return result;
    }
    if ($type === "V1.TemplateMapping.DateExpression") {
      const result = new DateExpression();
      return result;
    }
    if ($type === "V1.TemplateMapping.EnumExpression") {
      const result = new EnumExpression();
      return result;
    }
    if ($type === "V1.TemplateMapping.IdentityExpression") {
      const result = new IdentityExpression();
      return result;
    }
    if ($type === "V1.TemplateMapping.SubstringExpression") {
      const result = new SubstringExpression();
      return result;
    }
    throw new Error($type + " not found");
  }
}
