var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./amazon-skus.scss";
import {
  getLocalizedValidationMessage,
  SkuFieldNames,
  GTU_CODES,
  SupportedSkuCurrencyDataSource
} from "./AmazonSkuModels";
import { useEffect, useLoadingReducer, useState } from "GlobalShared/haunted/CustomHooks";
import { extractFirstErrorMessage, notifyError, notifyErrorWithWarning, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import { editIcon, removeIcon, templateTitle } from "GlobalShared/templates/commons";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import i18next from "i18next";
import { html } from "lit-html";
import { isApiError } from "GlobalShared/common/ApiError";
import { useAwesomeValidator, Validation } from "GlobalShared/validation/AwesomeValidator";
import { useConfirmModal } from "../modals/commonModals";
import { ONLY_XLSX_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
const DEFAULT_FILTERS = [];
const EXPORT_SKU_PAGE_SIZE = 1e5;
export const useAmazonSkus = (props) => {
  const loadSkus = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const result = yield props.loadSkus(gridState, showOnlyMissing);
        setSearchResult(result);
      }),
      (error) => {
        setSearchResult({
          skus: void 0,
          total: 0
        });
        notifyErrorWithWarning(error);
      }
    );
  });
  const loadGTUCodes = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const gtuCodes2 = yield props.loadGtuCodes();
        setGtuCodes(
          gtuCodes2.map((gc) => ({
            value: gc.id,
            label: `${gc.id} - ${GTU_CODES[gc.id].length > 50 ? GTU_CODES[gc.id].substring(0, 50) + "..." : GTU_CODES[gc.id]}`
          }))
        );
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const onSortChanged = (e) => {
    const newGridState = __spreadProps(__spreadValues({}, gridState), {
      orderBy: e.detail.orderBy,
      orderDir: e.detail.orderDir
    });
    setGridState(newGridState);
  };
  const onPageChanged = (e) => {
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex: e.detail.selectedPageIndex, pageSize: e.detail.selectedPageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridState = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridState);
  };
  const editSku = (sku) => {
    setSkuEditMode(true);
    setCurrentSku(sku);
    setShowSkuModal(true);
  };
  const removeSku = (sku) => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.removeSku(sku.Id);
        yield loadSkus();
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  };
  const addSku = () => {
    setSkuEditMode(false);
    setCurrentSku({});
    setShowSkuModal(true);
  };
  const saveSku = () => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        if (skuEditMode) {
          yield props.updateSku(currentSku);
        } else {
          yield props.addSku(currentSku);
        }
        yield loadSkus();
        notifySuccess(i18next.t("Saved successfully"));
        setShowSkuModal(false);
      }),
      (error) => {
        if (error.statusCode === 404) {
          const errorMessage = extractFirstErrorMessage(error, i18next.t("Invalid data"));
          if (errorMessage.startsWith("Sku not found")) {
            notifyWarning(
              i18next.t("Sku not found ({{id}})", {
                id: `sellerEntityId: ${currentSku.SellerEntityId}, skuId: ${currentSku.SkuId}`
              })
            );
          } else if (errorMessage.startsWith("Currency not found")) {
            notifyWarning(
              i18next.t("Currency not found ({{id}})", {
                id: currentSku.Price.Currency
              })
            );
          } else {
            notifyWarning(errorMessage);
          }
        } else if (error.statusCode === 409) {
          notifyWarning(
            i18next.t("Sku already exists ({{id}})", {
              id: `sellerEntityId: ${currentSku.SellerEntityId}, skuId: ${currentSku.SkuId}`
            })
          );
        } else {
          notifyError(error);
        }
      }
    );
  };
  const downloadSkus = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        dispatchLoading("inc");
        yield props.downloadSkus(EXPORT_SKU_PAGE_SIZE, gridState, showOnlyMissing);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const downloadCurrencies = () => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.downloadCurrencies();
      }),
      (error) => {
        notifyError(error);
      }
    );
  };
  const uploadSkus = (file) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        setUploadProcessing(true);
        dispatchLoading("inc");
        const uploadResult = yield props.uploadSkus(file);
        if (uploadResult.validationMessages.length > 0) {
          setUploadValidationMessages(uploadResult.validationMessages.map(getLocalizedValidationMessage));
        } else if (!uploadResult.isSuccess) {
          setUploadValidationMessages([i18next.t("Wrong excel format")]);
        } else {
          setShowUploadModal(false);
          yield loadSkus();
          notifySuccess("File uploaded successfully");
        }
      }),
      (error) => {
        if (isApiError(error)) {
          const errorMessages = error.ErrorMessages.map((e) => {
            const splitMessage = e.Message.split(";");
            if (splitMessage.length === 3) {
              return {
                columnName: splitMessage[0],
                rowNumber: splitMessage[1],
                validationCode: splitMessage[2]
              };
            } else {
              return void 0;
            }
          });
          setUploadValidationMessages(errorMessages.filter((e) => e).map(getLocalizedValidationMessage));
        } else {
          notifyError(error);
        }
      },
      () => {
        setUploadProcessing(false);
        dispatchLoading("dec");
      }
    );
  });
  const [loading, dispatchLoading] = useLoadingReducer();
  const [searchResult, setSearchResult] = useState(void 0);
  const [gtuCodes, setGtuCodes] = useState(void 0);
  const [gridState, setGridState] = useState({
    pageIndex: 0,
    appliedFilters: DEFAULT_FILTERS,
    pageSize: 20,
    orderBy: "SkuId",
    orderDir: "asc"
  });
  const confirmModal = useConfirmModal();
  const [showOnlyMissing, setShowOnlyMissing] = useState(true);
  const [currentSku, setCurrentSku] = useState(void 0);
  const [showSkuModal, setShowSkuModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadProcessing, setUploadProcessing] = useState(false);
  const [uploadValidationMessages, setUploadValidationMessages] = useState([]);
  const [skuEditMode, setSkuEditMode] = useState(false);
  const skuValidator = useAwesomeValidator(
    [
      Validation.ruleFor("SkuId", (vm) => vm.SkuId).isRequired(),
      Validation.ruleFor("SellerEntityId", (vm) => vm.SellerEntityId).isRequired(),
      Validation.ruleFor("PriceValue", (vm) => {
        var _a;
        return (_a = vm.Price) == null ? void 0 : _a.Value;
      }).isRequired(i18next.t("Number required")),
      Validation.ruleFor("PriceCurrency", (vm) => {
        var _a;
        return (_a = vm.Price) == null ? void 0 : _a.Currency;
      }).isRequired().hasLength(3)
    ],
    () => saveSku()
  );
  const init = () => __async(void 0, null, function* () {
    yield loadGTUCodes();
  });
  useEffect(init, []);
  useEffect(loadSkus, [gridState, showOnlyMissing]);
  useEffect(() => {
    if (showSkuModal) {
      skuValidator.clear();
    }
  }, [showSkuModal]);
  useEffect(() => {
    if (showUploadModal) {
      setUploadValidationMessages([]);
    }
  }, [showUploadModal]);
  useEffect(() => {
    if (currentSku !== void 0) {
      skuValidator.validate(currentSku);
    }
  }, [currentSku]);
  const htmlUploadModal = () => {
    return html` <dc-modal
			.visible=${showUploadModal}
			@close=${() => setShowUploadModal(false)}
			.header=${i18next.t("Upload SKUs")}
			.content=${html`
				<div class="w-full h-full">
					<div class="md:grid md:grid-cols-2 md:gap-4">
						<div>
							<dc-fileupload
								.label=${i18next.t("Upload Skus")}
								.accept=${ONLY_XLSX_ACCEPT_LIST}
								@upload=${(event) => {
      const uploadedFile = event.detail.files[0];
      if (uploadedFile) {
        uploadSkus(uploadedFile);
      }
    }}
							></dc-fileupload>
						</div>
						<div>
							<div>${uploadValidationMessages == null ? void 0 : uploadValidationMessages.map((msg) => html` <div class="my-2 text-red-400">${msg}</div>`)}</div>
						</div>
						<div>${uploadProcessing ? `${i18next.t("Processing")}...` : ""}</div>
					</div>
					<div>
						<div class="mt-8 flex space-x-4">
							<button class="btn btn-primary" @click=${() => __async(void 0, null, function* () {
      return setShowUploadModal(false);
    })}>${i18next.t("Cancel")}</button>
						</div>
					</div>
				</div>
			`}
		>
		</dc-modal>`;
  };
  const htmlSkuModal = () => {
    var _a, _b;
    return html` <dc-modal
			.visible=${showSkuModal}
			@close=${() => setShowSkuModal(false)}
			.header=${skuEditMode ? i18next.t("Edit SKU") : i18next.t("Add SKU")}
			.content=${currentSku === void 0 ? "" : html`
						<div class="w-full" style="height: 60vh">
							<div class="md:grid md:grid-cols-2 md:gap-4">
								<dc-input
									.label=${i18next.t("SkuId")}
									.value=${currentSku.SkuId}
									.validationMessage=${skuValidator.getMessage("SkuId")}
									.readonly=${skuEditMode}
									@change=${(e) => {
      skuValidator.showMessageFor("SkuId");
      setCurrentSku(__spreadProps(__spreadValues({}, currentSku), { SkuId: e.detail.value }));
    }}
								></dc-input>
								<dc-input
									.label=${i18next.t("Seller account")}
									.value=${currentSku.SellerEntityId}
									.validationMessage=${skuValidator.getMessage("SellerEntityId")}
									.readonly=${skuEditMode}
									@change=${(e) => {
      skuValidator.showMessageFor("SellerEntityId");
      setCurrentSku(__spreadProps(__spreadValues({}, currentSku), { SellerEntityId: e.detail.value }));
    }}
								></dc-input>
								<dc-input
									.label=${i18next.t("Commodity code")}
									.value=${currentSku.CommodityCode}
									.validationMessage=${skuValidator.getMessage("CommodityCode")}
									@change=${(e) => {
      skuValidator.showMessageFor("CommodityCode");
      setCurrentSku(__spreadProps(__spreadValues({}, currentSku), { CommodityCode: e.detail.value }));
    }}
								></dc-input>
								<dc-select
									.label=${i18next.t("Item identifier")}
									.dataSource=${gtuCodes}
									.selectedValues=${currentSku.ItemIdentifier}
									.validationMessage=${skuValidator.getMessage("ItemIdentifier")}
									@change=${(e) => {
      skuValidator.showMessageFor("ItemIdentifier");
      setCurrentSku(__spreadProps(__spreadValues({}, currentSku), { ItemIdentifier: e.detail.selectedValue }));
    }}
								></dc-select>
								<div>
									<div class="flex">
										<dc-input
											.label=${i18next.t("Price")}
											.value=${(_a = currentSku.Price) == null ? void 0 : _a.Value}
											.validationMessage=${skuValidator.getMessage("PriceValue")}
											@change=${(e) => {
      skuValidator.showMessageFor("PriceValue");
      setCurrentSku(__spreadProps(__spreadValues({}, currentSku), {
        Price: __spreadProps(__spreadValues({}, currentSku.Price), {
          Value: toSafeNumber(e.detail.value)
        })
      }));
    }}
										></dc-input>
										<div class=" ml-5">
											<dc-select
												.label=${i18next.t("Currency")}
												.dataSource=${SupportedSkuCurrencyDataSource}
												.selectedValues=${(_b = currentSku.Price) == null ? void 0 : _b.Currency}
												.filterable=${true}
												.validationMessage=${skuValidator.getMessage("PriceCurrency")}
												@change=${(e) => {
      skuValidator.showMessageFor("PriceCurrency");
      setCurrentSku(__spreadProps(__spreadValues({}, currentSku), {
        Price: __spreadProps(__spreadValues({}, currentSku.Price), {
          Currency: e.detail.selectedValue
        })
      }));
    }}
											></dc-select>
										</div>
									</div>
									<div></div>
								</div>
							</div>
							<div>
								<div class="mt-8 flex space-x-4">
									<button class="btn btn-primary" @click=${() => __async(void 0, null, function* () {
      return setShowSkuModal(false);
    })}>${i18next.t("Cancel")}</button>
									<button
										class="btn btn-primary"
										@click=${() => __async(void 0, null, function* () {
      skuValidator.validateAndDispatch(currentSku);
    })}
									>
										${i18next.t("Save")}
									</button>
								</div>
							</div>
						</div>
				  `}
		>
		</dc-modal>`;
  };
  const htmlGrid = () => {
    var _a;
    const columns = [
      {
        field: SkuFieldNames.SkuId,
        label: i18next.t("Sku"),
        sortable: true,
        filterable: true,
        filterDescriptor: { type: "string" },
        columnType: "string"
      },
      {
        field: SkuFieldNames.SellerEntityId,
        label: i18next.t("Seller account"),
        sortable: true,
        filterable: true,
        filterDescriptor: { type: "string" },
        columnType: "string"
      },
      {
        field: SkuFieldNames.CommodityCode,
        label: i18next.t("Commodity code"),
        sortable: true,
        filterable: true,
        filterDescriptor: { type: "string" },
        columnType: "string"
      },
      {
        field: SkuFieldNames.ItemIdentifier,
        label: i18next.t("Item identifier"),
        sortable: true,
        filterable: true,
        filterDescriptor: {
          type: "select",
          multiSelect: true,
          dataSource: gtuCodes
        },
        columnType: "enum"
      },
      {
        field: SkuFieldNames.Price,
        label: i18next.t("Price"),
        sortable: true,
        filterable: true,
        filterDescriptor: { from: true, to: true, type: "number" },
        columnType: "number"
      },
      {
        field: SkuFieldNames.Actions,
        label: ""
      }
    ];
    const vm = {
      columns,
      data: searchResult.skus,
      cellTemplate: (index, field) => {
        const item = searchResult.skus[index];
        if (field === SkuFieldNames.SkuId) {
          return item.SkuId;
        } else if (field === SkuFieldNames.SellerEntityId) {
          return item.SellerEntityId;
        } else if (field === SkuFieldNames.CommodityCode) {
          return item.CommodityCode;
        } else if (field === SkuFieldNames.ItemIdentifier) {
          return item.ItemIdentifier;
        } else if (field === SkuFieldNames.Price) {
          return item.Price ? `${item.Price.Value} ${item.Price.Currency}` : "";
        } else {
          return html` <div class="flex space-x-2 ${props.permissionManager.hasPermission("Company_Amazon_SKUs_Modify") ? "" : "hidden"}">
						<span class="edit-icon-container" @click=${() => editSku(item)}> ${editIcon()} </span>
						<span
							class="remove-icon-container"
							@click=${() => __async(void 0, null, function* () {
            if (yield confirmModal.confirm(i18next.t("Are you sure to delete?"))) {
              removeSku(item);
            }
          })}
						>
							${removeIcon()}
						</span>
					</div>`;
        }
      },
      appliedFilters: gridState.appliedFilters,
      sorting: {
        orderBy: gridState.orderBy,
        orderDir: gridState.orderDir
      },
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: searchResult.total
      }
    };
    return html` <div>
				<div>${templateTitle(i18next.t("Amazon SKUs"))}</div>
				<div class="flex space-x-4 justify-between mb-4 items-center my-4">
					<button class="btn btn-primary" ?disabled=${!props.permissionManager.hasPermission("Company_Amazon_SKUs_Modify")} @click=${addSku}>${i18next.t("Add")}</button>
					<button class="btn btn-primary" @click=${downloadSkus}>${i18next.t("Download SKUs")}</button>
					<button class="btn btn-primary" ?disabled=${!props.permissionManager.hasPermission("Company_Amazon_SKUs_Modify")} @click=${() => setShowUploadModal(true)}>
						${i18next.t("Upload SKUs")}
					</button>
					<button class="btn btn-primary" @click=${downloadCurrencies}>${i18next.t("Download Currencies")}</button>
					<dc-checkbox2
						.checked=${showOnlyMissing}
						.label=${i18next.t("Show only missing data")}
						@change=${(e) => {
      setShowOnlyMissing(e.detail.checked);
    }}
					></dc-checkbox2>
					${((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html` <div>
								<button
									class="btn btn-primary"
									@click=${() => setGridState(__spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: DEFAULT_FILTERS
    }))}
								>
									Reset filters
								</button>
						  </div>` : ""}
				</div>
			</div>
			<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>`;
  };
  const mainTemplate = html` <div class="my-2 mx-4 mb-16 mt-8 flex justify-between xl:w-3/4">
		<div>${searchResult ? htmlGrid() : "Loading..."}</div>
		${htmlSkuModal()}${htmlUploadModal()}${confirmModal.mainTemplate()}${loading.count > 0 ? html` <dc-loader></dc-loader>` : ""}
	</div>`;
  return { mainTemplate };
};
