var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { formatDate, getDay } from "GlobalShared/helpers/dateHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { CountrySelectorValidator, SOME_VAT_COUNTRIES } from "./OrderServiceValidator";
export const useCountrySelector = (props) => {
  const toggleCountry = (countryCode) => {
    setVatCountrySelection(
      vatCountrySelection.map((vatCountry) => {
        if (vatCountry.code === countryCode) {
          vatCountry.selected = !vatCountry.selected;
        }
        return vatCountry;
      })
    );
  };
  const [vatCountrySelection, setVatCountrySelection] = useState(void 0);
  const [isValidCountrySelector, setIsValidCountrySelector] = useState(false);
  const countrySelectorValidationContext = useValidationContext(new CountrySelectorValidator());
  useEffect(() => __async(void 0, null, function* () {
    setIsValidCountrySelector(!countrySelectorValidationContext.validationResult.getFailures().some((failure) => failure.propertyName === SOME_VAT_COUNTRIES));
  }), [countrySelectorValidationContext.validationResult]);
  useEffect(() => {
    if (vatCountrySelection) {
      countrySelectorValidationContext.validate(vatCountrySelection);
    }
  }, [vatCountrySelection]);
  useEffect(() => {
    var _a;
    if (((_a = props.serviceCountries) == null ? void 0 : _a.length) > 0 && (!props.isAdmin || props.currentCompanyServices)) {
      setVatCountrySelection(
        props.serviceCountries.map((sc) => {
          var _a2, _b, _c, _d;
          const countryService = (_a2 = props.currentCompanyServices) == null ? void 0 : _a2.CountryServices.find((cs) => cs.Country.Code === sc.Code);
          const vm = {
            label: (_b = sc.Name) == null ? void 0 : _b.trim(),
            code: sc.Code,
            selected: false,
            origComplianceStartDate: countryService == null ? void 0 : countryService.ContractFrom,
            origComplianceEndDate: countryService == null ? void 0 : countryService.ContractTo,
            origIntrastatInEndDate: (countryService == null ? void 0 : countryService.IntrastatIn) !== void 0 && ((_c = countryService == null ? void 0 : countryService.IntrastatIn) == null ? void 0 : _c.Status) === "Active" ? countryService.IntrastatIn.EndDate : void 0,
            origIntrastatOutEndDate: (countryService == null ? void 0 : countryService.IntrastatOut) !== void 0 && ((_d = countryService == null ? void 0 : countryService.IntrastatOut) == null ? void 0 : _d.Status) === "Active" ? countryService.IntrastatOut.EndDate : void 0,
            intrastatIn: false,
            intrastatOut: false
          };
          return vm;
        })
      );
    }
  }, [props.serviceCountries, props.currentCompanyServices]);
  const htmlCountrySelector = () => {
    return html`<div>
			${(vatCountrySelection == null ? void 0 : vatCountrySelection.some((s) => s.origComplianceEndDate !== void 0)) ? html`<div class="font-bold mt-4">${i18next.t("Renew Country")}</div>
						<div class="">${i18next.t("Select countries, where you would like to extend KPMG engagement by 1 year")}</div>
						<div class="grid md:grid-cols-2 xl:grid-cols-4 gap-8 mt-4">
							${vatCountrySelection.filter((s) => {
      var _a;
      return s.origComplianceEndDate !== void 0 && ((_a = props.priceConfig) == null ? void 0 : _a.perCountry[s.code]);
    }).map(
      (vatCountry) => {
        var _a, _b;
        return html`<div @click=${() => toggleCountry(vatCountry.code)} class="btn-toggle ${vatCountry.selected ? "selected" : ""}">
											<div>
												${(_b = (_a = props.worldCountries) == null ? void 0 : _a.find((wc) => wc.Code === vatCountry.code)) == null ? void 0 : _b.Name}
												<div>
													${getDay(vatCountry.origComplianceEndDate) < getDay(/* @__PURE__ */ new Date()) ? html`<div class="text-xs text-red-500">${i18next.t("expired")}: ${formatDate(vatCountry.origComplianceEndDate)}</div>` : html`<div class="text-xs">${i18next.t("expires")}: ${formatDate(vatCountry.origComplianceEndDate)}</div>`}
												</div>
											</div>
										</div>`;
      }
    )}
						</div>
						<hr class="my-8" />` : ""}
			<div class="font-bold mt-4">${i18next.t("Add New Country")}</div>
			<div class="">${i18next.t("Select countries, where you would like to start KPMG engagement")}</div>
			${vatCountrySelection === void 0 ? html`<div class="mt-4">${i18next.t("Loading")}...</div>` : ""}
			<div class="grid md:grid-cols-2 xl:grid-cols-4 gap-8 mt-4">
				${vatCountrySelection == null ? void 0 : vatCountrySelection.filter((s) => {
      var _a;
      return s.origComplianceEndDate === void 0 && ((_a = props.priceConfig) == null ? void 0 : _a.perCountry[s.code]);
    }).map(
      (vatCountry) => {
        var _a, _b;
        return html`<div @click=${() => toggleCountry(vatCountry.code)} class="btn-toggle ${vatCountry.selected ? "selected" : ""}">
								${(_b = (_a = props.worldCountries) == null ? void 0 : _a.find((wc) => wc.Code === vatCountry.code)) == null ? void 0 : _b.Name}
							</div>`;
      }
    )}
			</div>
		</div>`;
  };
  return {
    htmlCountrySelector,
    vatCountrySelection,
    isValidCountrySelector
  };
};
