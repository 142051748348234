import { html } from "lit-html";
import { resolveCountryName, uploadFileToQuestion } from "GlobalShared/helpers/questionnaireHelper";
import i18next from "i18next";
import { templateTitle } from "GlobalShared/templates/commons";
import { DIGITS } from "GlobalShared/common/Constants";
export const observedAttributes = ["q11e", "worldCountries", "euCountries"];
export const useShadowDOM = false;
export const name = "ww-q11e-business-details";
const DEFAULTS = {
  worldCountries: [],
  euCountries: []
};
export const Component = (host) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A, _B, _C, _D, _E, _F, _G, _H, _I, _J, _K, _L, _M, _N, _O, _P, _Q, _R, _S, _T, _U, _V, _W, _X, _Y, _Z, __, _$, _aa, _ba, _ca, _da, _ea, _fa, _ga, _ha, _ia, _ja, _ka, _la, _ma, _na, _oa, _pa, _qa, _ra;
  const props = {
    q11e: host.q11e,
    worldCountries: host.worldCountries ? host.worldCountries : DEFAULTS.worldCountries,
    euCountries: host.euCountries ? host.euCountries : DEFAULTS.euCountries
  };
  const templateVatCountry = (countryCode) => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2, _i2, _j2, _k2, _l2, _m2, _n2, _o2, _p2, _q2, _r2, _s2, _t2, _u2, _v2, _w2, _x2, _y2, _z2, _A2, _B2, _C2, _D2, _E2;
    const countryPrefix = `${countryCode.toLowerCase() === "gb" ? "uk" : countryCode.toLowerCase()}`;
    return html`<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
			<ww-answer-money
				class=${((_a2 = props.q11e) == null ? void 0 : _a2.isVisible(`${countryPrefix}_annual`)) ? "" : "hidden"}
				.q11e=${props.q11e}
				.questionId=${`${countryPrefix}_annual`}
				.validation=${{ required: true }}
			></ww-answer-money>
			<div class=${`mb-2 w-64 ${((_b2 = props.q11e) == null ? void 0 : _b2.isVisible(`${countryPrefix}_vat`)) ? "" : "hidden"}`}>
				<div>
					<ww-answer-vatnumber .q11e=${props.q11e} .nolabel=${true} .questionId=${`${countryPrefix}_vat`} .validation=${{ required: true }} .countryCode=${countryCode}>
					</ww-answer-vatnumber>
				</div>
			</div>
			${countryPrefix === "de" ? html`<ww-answer-string
							class=${((_c2 = props.q11e) == null ? void 0 : _c2.isVisible("de_storage_address")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"de_storage_address"}
							.validation=${{ required: true }}
						></ww-answer-string>
						<ww-answer-string
							class=${((_d2 = props.q11e) == null ? void 0 : _d2.isVisible("de_local_tax")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"de_local_tax"}
							.validation=${{
      required: true,
      regEx: DIGITS,
      regExValidationMessage: i18next.t("Please use only numbers.")
    }}
						></ww-answer-string>
						<ww-answer-date
							class=${((_e2 = props.q11e) == null ? void 0 : _e2.isVisible("de_store_start")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"de_store_start"}
							.validation=${{ required: true }}
						></ww-answer-date>
						<ww-answer-country
							class=${((_f2 = props.q11e) == null ? void 0 : _f2.isVisible("de_import_country")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"de_import_country"}
							.validation=${{ required: true }}
							.countries=${props.euCountries}
						></ww-answer-country>
						<ww-answer-money
							class=${((_g2 = props.q11e) == null ? void 0 : _g2.isVisible("de_est_profit")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"de_est_profit"}
							.validation=${{ required: true }}
						></ww-answer-money>
						<ww-answer-money
							class=${((_h2 = props.q11e) == null ? void 0 : _h2.isVisible("de_est_vat")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"de_est_vat"}
							.validation=${{ required: true }}
						></ww-answer-money>
						<ww-answer-producttype class=${((_i2 = props.q11e) == null ? void 0 : _i2.isVisible("de_product_type")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"de_product_type"}></ww-answer-producttype>` : ""}
			${countryPrefix === "at" ? html` <ww-answer-string
							class=${((_j2 = props.q11e) == null ? void 0 : _j2.isVisible("at_local_tax")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"at_local_tax"}
							.validation=${{
      required: true,
      regEx: DIGITS,
      regExValidationMessage: i18next.t("Please use only numbers.")
    }}
						></ww-answer-string>
						<ww-answer-producttype class=${((_k2 = props.q11e) == null ? void 0 : _k2.isVisible("at_product_type")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"at_product_type"}></ww-answer-producttype>` : ""}
			${countryPrefix === "fr" ? html`<ww-answer-bool
							class=${((_l2 = props.q11e) == null ? void 0 : _l2.isVisible("fr_vat_liability")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"fr_vat_liability"}
							.validation=${{ required: true }}
						></ww-answer-bool
						><ww-answer-bool
							class=${((_m2 = props.q11e) == null ? void 0 : _m2.isVisible("fr_supplies")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"fr_supplies"}
							.validation=${{ required: true }}
						></ww-answer-bool
						><ww-answer-bool
							class=${((_n2 = props.q11e) == null ? void 0 : _n2.isVisible("fr_acquisitions")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"fr_acquisitions"}
							.validation=${{ required: true }}
						></ww-answer-bool>
						<ww-answer-multicountry
							class=${((_o2 = props.q11e) == null ? void 0 : _o2.isVisible("fr_cntry_disp")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"fr_cntry_disp"}
							.validation=${{ required: true }}
							.countries=${props.worldCountries}
						></ww-answer-multicountry>
						<ww-answer-multicountry
							class=${((_p2 = props.q11e) == null ? void 0 : _p2.isVisible("fr_cntry_inv")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"fr_cntry_inv"}
							.validation=${{ required: true }}
							.countries=${props.worldCountries}
						></ww-answer-multicountry>
						<ww-answer-string
							class=${((_q2 = props.q11e) == null ? void 0 : _q2.isVisible("prev_fisc_rep")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"prev_fisc_rep"}
							.validation=${{ required: true }}
						></ww-answer-string>
						<div class="lg:col-span-2 ${((_r2 = props.q11e) == null ? void 0 : _r2.isVisible("prev_fisc_rep")) ? "" : "hidden"}">
							<label class="text-lg font-bold"> ${i18next.t("Add the address of the previous fiscal representative in France")} </label>
							<ww-answer-address
								class=${((_s2 = props.q11e) == null ? void 0 : _s2.isVisible("fisc_rep_address")) ? "" : "hidden"}
								.q11e=${props.q11e}
								.questionId=${"fisc_rep_address"}
								.withCountry=${false}
								.validation=${{ required: true }}
							></ww-answer-address>
						</div> ` : ""}
			${countryPrefix === "it" ? html`<ww-answer-bool class=${((_t2 = props.q11e) == null ? void 0 : _t2.isVisible("it_emp")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"it_emp"} .validation=${{ required: true }}></ww-answer-bool>
						<ww-answer-legalentitytype class=${((_u2 = props.q11e) == null ? void 0 : _u2.isVisible("leg_ent_type")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"leg_ent_type"}></ww-answer-legalentitytype>
						<ww-answer-clientbase class=${((_v2 = props.q11e) == null ? void 0 : _v2.isVisible("client_base")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"client_base"}></ww-answer-clientbase>
						<ww-answer-bool class=${((_w2 = props.q11e) == null ? void 0 : _w2.isVisible("it_sales")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"it_sales"} .validation=${{ required: true }}></ww-answer-bool>
						<ww-answer-bool
							class=${((_x2 = props.q11e) == null ? void 0 : _x2.isVisible("it_dist_sales")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"it_dist_sales"}
							.validation=${{ required: true }}
						></ww-answer-bool>
						<ww-answer-turnovers class=${((_y2 = props.q11e) == null ? void 0 : _y2.isVisible("it_turnovers")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"it_turnovers"}></ww-answer-turnovers>
						<ww-answer-fileref .q11e=${props.q11e} .questionId=${"it_vat_cert"} .validation=${{ required: true }} .onUpload=${uploadFileToQuestion}></ww-answer-fileref>` : ""}
			${countryPrefix === "pl" ? html`
						<ww-answer-accountinglocation class=${((_z2 = props.q11e) == null ? void 0 : _z2.isVisible("account_loc")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"account_loc"}></ww-answer-accountinglocation>
						<div class="lg:col-span-2">
							<label class="text-lg font-bold"> ${i18next.t("Please add the address where your accounting records are kept.")} </label>
							<ww-answer-address
								class=${((_A2 = props.q11e) == null ? void 0 : _A2.isVisible("account_addrs")) ? "" : "hidden"}
								.q11e=${props.q11e}
								.questionId=${"account_addrs"}
								.worldCountries=${props.worldCountries}
								.withCountry=${true}
								.validation=${{ required: true }}
							></ww-answer-address>
						</div>
						<ww-answer-string
							class=${((_B2 = props.q11e) == null ? void 0 : _B2.isVisible("commercial_reg_num")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"commercial_reg_num"}
							.validation=${{ required: true }}
						></ww-answer-string>
						<ww-answer-date
							class=${((_C2 = props.q11e) == null ? void 0 : _C2.isVisible("commercial_reg_date")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"commercial_reg_date"}
							.validation=${{ required: true }}
						></ww-answer-date>
						<ww-answer-string
							class=${((_D2 = props.q11e) == null ? void 0 : _D2.isVisible("name_of_com_auth")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"name_of_com_auth"}
							.validation=${{ required: true }}
						></ww-answer-string>
						<ww-answer-legalentityform class=${((_E2 = props.q11e) == null ? void 0 : _E2.isVisible("leg_ent_from")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"leg_ent_from"}></ww-answer-legalentityform>
				  ` : ""}
		</div>`;
  };
  return html`
		<div class="my-8">${templateTitle(i18next.t("Business Details"))}</div>
		<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
			<dc-input
				class=${!((_a = props.q11e) == null ? void 0 : _a.isIndividual()) ? "" : "hidden"}
				.readonly=${true}
				.label=${i18next.t("Company Name")}
				.value=${!((_b = props.q11e) == null ? void 0 : _b.isIndividual()) ? (_d = (_c = props.q11e) == null ? void 0 : _c.getCompanyDetail()) == null ? void 0 : _d.CompanyName : ""}
			></dc-input>
		</div>
		<div>
			<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
				<dc-input
					class=${((_e = props.q11e) == null ? void 0 : _e.isIndividual()) ? "" : "hidden"}
					.readonly=${true}
					.label=${i18next.t("First Name of Proprietor")}
					.value=${((_f = props.q11e) == null ? void 0 : _f.isIndividual()) ? (_g = props.q11e) == null ? void 0 : _g.getIndividualDetail().Name.FirstName : ""}
				></dc-input
				><dc-input
					class=${((_h = props.q11e) == null ? void 0 : _h.isIndividual()) ? "" : "hidden"}
					.readonly=${true}
					.label=${i18next.t("Last Name of Proprietor")}
					.value=${((_i = props.q11e) == null ? void 0 : _i.isIndividual()) ? (_j = props.q11e) == null ? void 0 : _j.getIndividualDetail().Name.LastName : ""}
				></dc-input>
			</div>
		</div>
		<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
			<ww-answer-string
				class=${((_k = props.q11e) == null ? void 0 : _k.isVisible("com_reg_name")) ? "" : "hidden"}
				.q11e=${props.q11e}
				.questionId=${"com_reg_name"}
				.validation=${{ required: !((_l = props.q11e) == null ? void 0 : _l.isIndividual()), maxLength: 200 }}
			></ww-answer-string>
			<ww-answer-string
				class=${((_m = props.q11e) == null ? void 0 : _m.isVisible("comp_reg_num")) ? "" : "hidden"}
				.q11e=${props.q11e}
				.questionId=${"comp_reg_num"}
				.validation=${{ required: (_n = props.q11e) == null ? void 0 : _n.isVisible("comp_reg_num"), maxLength: 200 }}
			></ww-answer-string>
			<ww-answer-money class=${((_o = props.q11e) == null ? void 0 : _o.isVisible("amount_of_cap")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"amount_of_cap"} .validation=${{ required: true }}></ww-answer-money>
			<ww-answer-date class=${((_p = props.q11e) == null ? void 0 : _p.isVisible("incorp_date")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"incorp_date"} .validation=${{ required: true }}></ww-answer-date>
		</div>
		<div>
			<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
				<dc-select
					.label=${i18next.t("Country of incorporation")}
					.selectedValues=${[(_q = props.q11e) == null ? void 0 : _q.getEstablishmentCountry()]}
					.dataSource=${props.worldCountries.map((country) => ({
    label: country.Name,
    value: country.Code
  }))}
					.filterable=${true}
					.readonly=${true}
				></dc-select>
				<dc-input .readonly=${true} .label=${i18next.t("City")} .value=${(_u = (_t = (_s = (_r = props.q11e) == null ? void 0 : _r.company) == null ? void 0 : _s.BusinessDetail) == null ? void 0 : _t.Address) == null ? void 0 : _u.City}></dc-input>
				<dc-input .readonly=${true} .label=${i18next.t("Street name")} .value=${(_y = (_x = (_w = (_v = props.q11e) == null ? void 0 : _v.company) == null ? void 0 : _w.BusinessDetail) == null ? void 0 : _x.Address) == null ? void 0 : _y.Street}></dc-input>
				<dc-input .readonly=${true} .label=${i18next.t("Street number")} .value=${(_C = (_B = (_A = (_z = props.q11e) == null ? void 0 : _z.company) == null ? void 0 : _A.BusinessDetail) == null ? void 0 : _B.Address) == null ? void 0 : _C.StreetNumber}></dc-input>
				<dc-input .readonly=${true} .label=${i18next.t("Postal/Zip code")} .value=${(_G = (_F = (_E = (_D = props.q11e) == null ? void 0 : _D.company) == null ? void 0 : _E.BusinessDetail) == null ? void 0 : _F.Address) == null ? void 0 : _G.Zip}></dc-input>
				<dc-input
					class=${((_K = (_J = (_I = (_H = props.q11e) == null ? void 0 : _H.company) == null ? void 0 : _I.BusinessDetail) == null ? void 0 : _J.Address) == null ? void 0 : _K.Province) === void 0 ? "hidden" : ""}
					.readonly=${true}
					.label=${i18next.t("Province")}
					.value=${(_O = (_N = (_M = (_L = props.q11e) == null ? void 0 : _L.company) == null ? void 0 : _M.BusinessDetail) == null ? void 0 : _N.Address) == null ? void 0 : _O.Province}
				></dc-input>
			</div>
		</div>
		<div class="grid lg:grid-cols-2 gap-4 mt-4">
			<div class="lg:col-span-2">
				<ww-answer-fileref .q11e=${props.q11e} .questionId=${"business_cert"} .validation=${{ required: true }} .onUpload=${uploadFileToQuestion}></ww-answer-fileref>
			</div>
			<div class="lg:col-span-2">
				<ww-answer-fileref
					class=${((_P = props.q11e) == null ? void 0 : _P.isVisible("article_assoc")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"article_assoc"}
					.validation=${{ required: true }}
					.onUpload=${uploadFileToQuestion}
				></ww-answer-fileref>
			</div>
			<div class=${`lg:col-span-2 ${((_Q = props.q11e) == null ? void 0 : _Q.isVisible("corresp_is_same")) ? "" : "hidden"}`}>
				<ww-answer-bool .q11e=${props.q11e} .questionId=${"corresp_is_same"} .validation=${{ required: true }}></ww-answer-bool>
			</div>
		</div>
		<div class=${`${((_R = props.q11e) == null ? void 0 : _R.isVisible("corresp_address")) ? "" : "hidden"}`}>
			<ww-answer-address .q11e=${props.q11e} .questionId=${"corresp_address"} .worldCountries=${props.worldCountries} .withCountry=${true} .validation=${{ required: true }}></ww-answer-address>
		</div>
		<div>
			<div class=${`my-8 ${((_S = props.q11e) == null ? void 0 : _S.hasVatService()) ? "" : "hidden"}`}>${templateTitle(i18next.t("Countries"))}</div>
			${(_U = (_T = props.q11e) == null ? void 0 : _T.getServiceCountryCodes()) == null ? void 0 : _U.map(
    (country) => html`<div>
						<div class="text-xl font-bold my-4">${resolveCountryName(props.worldCountries, country)}</div>
						<div>${templateVatCountry(country)}</div>
					</div>`
  )}
		</div>
		<div class="my-8">${templateTitle(i18next.t("Other VAT Related Questions"))}</div>
		<div>
			<ww-answer-bool
				class="lg:col-span-2 ${((_V = props.q11e) == null ? void 0 : _V.isVisible("other_vats")) ? "" : "hidden"}"
				.q11e=${props.q11e}
				.questionId=${"other_vats"}
				.validation=${{ required: true }}
			></ww-answer-bool>
			<div class="mt-4">
				<ww-answer-existing-vat-numbers .q11e=${props.q11e} .questionId=${"exs_vats"} .euCountries=${props.euCountries}></ww-answer-existing-vat-numbers>
			</div>
		</div>
		<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
			<ww-answer-vatnumber
				class=${((_W = props.q11e) == null ? void 0 : _W.isVisible("estab_vat")) ? "" : "hidden"}
				.q11e=${props.q11e}
				.questionId=${"estab_vat"}
				.validation=${{ required: (_X = props.q11e) == null ? void 0 : _X.isVisible("estab_vat") }}
				.countryCode=${(_Y = props.q11e) == null ? void 0 : _Y.getEstablishmentCountry()}
			>
			</ww-answer-vatnumber>
			<div class=${((_Z = props.q11e) == null ? void 0 : _Z.isVisible("estab_vat")) ? "" : "hidden"}>
				<div></div>
			</div>
		</div>
		<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
			<ww-answer-fileref
				class=${`lg:col-span-2 ${((__ = props.q11e) == null ? void 0 : __.hasVatService()) ? "" : "hidden"}`}
				.q11e=${props.q11e}
				.questionId=${"estab_vat_cert"}
				.validation=${{ required: (_$ = props.q11e) == null ? void 0 : _$.isVisible("estab_vat_cert") }}
				.onUpload=${uploadFileToQuestion}
			></ww-answer-fileref>
		</div>
		<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
			<ww-answer-string
				class=${((_aa = props.q11e) == null ? void 0 : _aa.isVisible("tax_auth_name")) ? "" : "hidden"}
				.q11e=${props.q11e}
				.questionId=${"tax_auth_name"}
				.validation=${{ required: (_ba = props.q11e) == null ? void 0 : _ba.isVisible("tax_auth_name") }}
			></ww-answer-string>
		</div>
		<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
			<ww-answer-bool class="lg:col-span-2" .q11e=${props.q11e} .questionId=${"is_on_stock"} .validation=${{ required: true }}></ww-answer-bool>
			<ww-answer-string class=${((_ca = props.q11e) == null ? void 0 : _ca.isVisible("stock_name")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"stock_name"} .validation=${{ required: true }}></ww-answer-string>
			<ww-answer-string class=${((_da = props.q11e) == null ? void 0 : _da.isVisible("ticker_symbol")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"ticker_symbol"} .validation=${{ required: true }}></ww-answer-string>
		</div>
		<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
			<ww-answer-string class=${((_ea = props.q11e) == null ? void 0 : _ea.isVisible("mp_store_name")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"mp_store_name"} .validation=${{ required: true }}></ww-answer-string>
			<ww-answer-string class=${((_fa = props.q11e) == null ? void 0 : _fa.isVisible("sell_id")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"sell_id"} .validation=${{ required: true }}></ww-answer-string>
			<ww-answer-multistring class=${((_ga = props.q11e) == null ? void 0 : _ga.isVisible("mp_name")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"mp_name"} .validation=${{ required: true }}></ww-answer-multistring>
			<ww-answer-string class=${((_ha = props.q11e) == null ? void 0 : _ha.isVisible("web_link")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"web_link"} .validation=${{ required: true }}></ww-answer-string>
		</div>
		<ww-answer-business-owners .q11e=${props.q11e} .questionId=${"business_owner"}></ww-answer-business-owners>

		<div class=${`my-8 ${((_ia = props.q11e) == null ? void 0 : _ia.hasIossService()) ? "" : "hidden"}`}>${templateTitle(i18next.t("IOSS Registration Related Questions"))}</div>
		<div class="grid md:grid-cols-2 gap-4 mt-4 items-end">
			<ww-answer-string
				class=${((_ja = props.q11e) == null ? void 0 : _ja.isVisible("ioss_web_link")) ? "" : "hidden"}
				.q11e=${props.q11e}
				.questionId=${"ioss_web_link"}
				.validation=${{ required: (_ka = props.q11e) == null ? void 0 : _ka.isVisible("ioss_web_link") }}
			></ww-answer-string>
			<ww-answer-string class=${((_la = props.q11e) == null ? void 0 : _la.isVisible("trading_name")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"trading_name"} .validation=${{ required: false }}></ww-answer-string>
			<ww-answer-multistring
				class=${((_ma = props.q11e) == null ? void 0 : _ma.isVisible("ioss_exs_ioss")) ? "" : "hidden"}
				.q11e=${props.q11e}
				.questionId=${"ioss_exs_ioss"}
				.validation=${{ required: (_na = props.q11e) == null ? void 0 : _na.isVisible("ioss_exs_ioss") }}
			></ww-answer-multistring>
		</div>

		<ww-answer-fixed-establishments
			class=${((_oa = props.q11e) == null ? void 0 : _oa.isVisible("ioss_fixed_estab")) ? "" : "hidden"}
			.q11e=${props.q11e}
			.euCountries=${props.euCountries}
			.questionId=${"ioss_fixed_estab"}
		></ww-answer-fixed-establishments>

		<div class=${`my-8 ${((_pa = props.q11e) == null ? void 0 : _pa.hasVatService()) ? "" : "hidden"}`}>${templateTitle(i18next.t("OSS Information"))}</div>
		<ww-answer-bool
			class=${`lg:col-span-2 ${((_qa = props.q11e) == null ? void 0 : _qa.isVisible("oss_has_registration")) ? "" : "hidden"}`}
			.q11e=${props.q11e}
			.questionId=${"oss_has_registration"}
			.validation=${{ required: true }}
		></ww-answer-bool>
		<ww-answer-oss-details class=${((_ra = props.q11e) == null ? void 0 : _ra.isVisible("oss_details")) ? "" : "hidden"} .q11e=${props.q11e} .euCountries=${props.euCountries} .questionId=${"oss_details"}></ww-answer-oss-details>
	`;
};
