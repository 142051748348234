var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient, useEffect, useLoadingReducer, useState, useValidationContext, useCallback } from "GlobalShared/haunted/CustomHooks";
import { checkError, notifyError, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { addHandler, getRouteElement, navigateTo, removeOptionals } from "GlobalShared/router";
import { getRouteByKey, currentLocale } from "../../globals";
import { html } from "lit-html";
import {
  toTaxCodeString,
  REPORTING_COUNTRY_TYPE_LABELS,
  DEFAULT_TAX_CODE as DEFAULT_TAX_CODE_DEF,
  VAT_RATE_TYPE_LABELS,
  TERM_CODE_MAPPING,
  TERM_CODE_LABELS,
  MULTI_SELECT_OP_LABELS,
  NUMBER_OP_LABELS,
  RETURN_TYPE_LABELS,
  TRANSACTION_TYPE_LABELS,
  CUSTOMER_TYPE_LABELS,
  YES_NO_LABELS,
  TAX_COLLECTION_LABELS,
  DELIVERY_CONDITION_LABELS,
  DEPARTURE_AND_ARRIVAL_COUNTRY_LABELS,
  SOURCE_TYPE_LABELS,
  mapTaxCodeDefToVM,
  DISTANCE_SALES_COUNTRY_TYPE_LABELS,
  mapTaxCodeDefVMToCreateDTO,
  mapTaxCodeDefVMToUpdateDTO,
  REPORTING_COUNTRY_SHORT_CODE_MAPPING,
  DISTANCE_SALES_COUNTRY_SHORT_CODE_MAPPING,
  VAT_RATE_TYPE_SHORT_CODE_MAPPING,
  TYPE_OF_SALE_LABELS,
  PRICE_OF_ITEM_LABELS
} from "./TaxCodeModels";
import { templateTitle } from "GlobalShared/templates/commons";
import { ALL_TAX_CODE_DEF_FIELDS, TaxCodeDefValidator } from "./TaxCodeValidator";
import { usePermissionManager, useWorldCountries } from "AdminComponents/hooks/AdminCustomHooks";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import { TaxCodeDefinitions as TaxCodeDefinitionsClient } from "AdminServices/APIs/self/clients";
import { SearchRequest } from "AdminServices/APIs/self/models/V1/TaxEngine/Search/SearchRequest";
import { Pager } from "AdminServices/APIs/self/models/V1/TaxEngine/Search/Pager";
import { formatDate } from "GlobalShared/helpers/dateHelper";
import { DateRange } from "AdminServices/APIs/self/models/V1/TaxEngine";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
const PAGE_SIZE = 20;
const NEW_TAX_CODE = "NEW";
export function TaxCodes() {
  const navigate = (taxCode) => {
    navigateTo(`${removeOptionals(getRouteByKey("taxcodes(/:taxCode)"))}${taxCode ? `/${taxCode}` : ""}`);
    if (taxCode === void 0) {
      loadTaxCodes();
    }
  };
  const getTaxCodeByString = (returnType, taxCodeStr) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        dispatchLoading("inc");
        const resp = yield taxCodeDefinitionsClient.getTaxCodeByString({
          returnType,
          taxCodeStr
        });
        setCurrentTaxCode(mapTaxCodeDefToVM(resp.data));
      }),
      (error) => {
        if (error.statusCode === 404 && error.Message !== void 0) {
          notifyError(error.Message);
        } else {
          notifyError(error);
        }
      },
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const loadTaxCodes = () => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        var _a;
        dispatchLoading("inc");
        const response = yield taxCodeDefinitionsClient.searchTaxCodes({
          body: new SearchRequest({
            Fragment: searchFragment,
            IsEnabled: enabledTaxCodesOnly ? true : void 0,
            Pager: new Pager({ Skip: selectedPageIndex * selectedPageSize, Top: selectedPageSize })
          })
        });
        setTaxCodeDefsTotal(response.data.Count);
        setTaxCodeDefs((_a = response.data.TaxCodes) == null ? void 0 : _a.map(mapTaxCodeDefToVM));
      }),
      (error) => {
        notifyError(error);
      },
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const toggleTaxCode = (taxCodeDefId, enabled) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        dispatchLoading("inc");
        yield taxCodeDefinitionsClient.toggleEnabled({ taxCodeDefId, body: enabled });
        loadTaxCodes();
      }),
      (error) => {
        notifyError(error);
      },
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const addTaxCodeDef = (taxCodeDef) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield taxCodeDefinitionsClient.addTaxCodeDef({ body: mapTaxCodeDefVMToCreateDTO(taxCodeDef) });
        notifySuccess("Tax code successfully created");
        navigate();
      }),
      (error) => {
        if (checkError(error, 409, "DuplicatedEntity")) {
          notifyWarning("The Tax code is already exist");
        } else {
          notifyError(error);
        }
      }
    );
  });
  const updateTaxCodeDef = (taxCodeDef) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield taxCodeDefinitionsClient.updateTaxCodeDef({
          taxCodeDefId: taxCodeDef.id,
          body: mapTaxCodeDefVMToUpdateDTO(taxCodeDef)
        });
        notifySuccess("Tax code successfully updated");
        navigate();
      }),
      (error) => {
        if (error.statusCode === 404 && error.Message !== void 0) {
          notifyError(error.Message);
        } else {
          notifyError(error);
        }
      }
    );
  });
  const setTerm = (newTerm, conditionIndex, termIndex) => {
    setCurrentTaxCode(__spreadProps(__spreadValues({}, currentTaxCode), {
      conditions: currentTaxCode.conditions.map((conditionObj, i) => {
        if (conditionIndex === i) {
          return {
            condition: conditionObj.condition.map((term, i2) => {
              if (termIndex === i2) {
                return newTerm;
              } else {
                return term;
              }
            })
          };
        } else {
          return conditionObj;
        }
      })
    }));
  };
  const validateConditions = () => {
    let isValid = true;
    if (currentTaxCode.conditions.length === 0 || currentTaxCode.conditions.filter((conditionObj) => conditionObj.condition.length === 0).length > 0) {
      setValidationMessageCondition("At least one term is needed at each OR section");
      isValid = false;
    } else {
      setValidationMessageCondition(void 0);
    }
    const newTaxCode = __spreadProps(__spreadValues({}, currentTaxCode), {
      conditions: currentTaxCode.conditions.map((conditionObj) => {
        return {
          condition: conditionObj.condition.map((term) => {
            const newTerm = __spreadProps(__spreadValues({}, term), { validationMessages: {} });
            if (term.code === void 0) {
              newTerm.validationMessages.code = "Required";
              isValid = false;
            } else {
              const termType = TERM_CODE_MAPPING[term.code];
              if (term.op === void 0) {
                newTerm.validationMessages.op = "Required";
                isValid = false;
              }
              if (termType === "Multiselect" && (term.multiSelectVal === void 0 || term.multiSelectVal.length === 0) || termType === "Select" && term.selectVal === void 0) {
                newTerm.validationMessages.value = "Required";
                isValid = false;
              }
              if (termType === "Number" && term.numberVal === void 0) {
                newTerm.validationMessages.value = "Number required";
                isValid = false;
              }
            }
            return newTerm;
          })
        };
      })
    });
    setCurrentTaxCode(newTaxCode);
    return isValid;
  };
  const worldCountries = useWorldCountries(currentLocale);
  const [taxCodeDefs, setTaxCodeDefs] = useState(void 0);
  const [taxCodeDefsTotal, setTaxCodeDefsTotal] = useState(void 0);
  const [currentTaxCodeString, setCurrentTaxCodeString] = useState(void 0);
  const [currentTaxCodeReturnType, setCurrentTaxCodeReturnType] = useState(void 0);
  const [currentTaxCode, setCurrentTaxCode] = useState(void 0);
  const [loading, dispatchLoading] = useLoadingReducer();
  const [currentPathname, setCurrentPathname] = useState(window.location.pathname);
  const [enabledTaxCodesOnly, setEnabledTaxCodesOnly] = useState(true);
  const [searchFragment, setSearchFragment] = useState("");
  const taxCodeValidationContext = useValidationContext(new TaxCodeDefValidator());
  const [dispatchIfTaxCodeDefIsValid, setDispatchIfTaxCodeDefIsValid] = useState(false);
  const [validationMessageCondition, setValidationMessageCondition] = useState(void 0);
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const [selectedPageSize, setSelectedPageSize] = useState(PAGE_SIZE);
  const taxCodeDefinitionsClient = useClient(TaxCodeDefinitionsClient);
  const permissionManager = usePermissionManager();
  const [taxCodeEstCountries, setTaxCodeEstCountries] = useState(void 0);
  const estCountryDataSourceCallback = useCallback(
    (text) => {
      return taxCodeEstCountries.filter((item) => isEmpty(text) || item.label.toLowerCase().includes(text.toLowerCase()));
    },
    [taxCodeEstCountries]
  );
  const init = () => {
    return addHandler("taxcodes", (location) => {
      setCurrentPathname(location.pathname);
    });
  };
  useEffect(() => {
    setTaxCodeEstCountries(
      [
        { label: "<ALL>", value: "ALL" },
        { label: "<EU>", value: "EU" },
        { label: "<NONEU>", value: "NONEU" }
      ].concat(
        worldCountries.map((country) => ({
          label: country.Name,
          value: country.Code
        }))
      )
    );
  }, [worldCountries]);
  useEffect(init, []);
  useEffect(() => __async(this, null, function* () {
    if (currentTaxCodeString !== void 0 && currentTaxCodeString !== NEW_TAX_CODE) {
      yield getTaxCodeByString(currentTaxCodeReturnType, currentTaxCodeString);
    } else {
      taxCodeValidationContext.clearFieldValidations();
      setCurrentTaxCode(DEFAULT_TAX_CODE_DEF);
    }
  }), [currentTaxCodeReturnType, currentTaxCodeString]);
  useEffect(() => {
    loadTaxCodes();
  }, [searchFragment, enabledTaxCodesOnly, selectedPageIndex, selectedPageSize]);
  useEffect(() => __async(this, null, function* () {
    const taxCode = getRouteElement("taxCode");
    const [crettype, tcstring] = taxCode ? taxCode === NEW_TAX_CODE ? [void 0, NEW_TAX_CODE] : taxCode.split("-") : [void 0, void 0];
    setCurrentTaxCodeReturnType(crettype);
    setCurrentTaxCodeString(tcstring);
  }), [currentPathname]);
  useEffect(() => {
    if (currentTaxCode !== void 0) {
      taxCodeValidationContext.validate(currentTaxCode);
    }
  }, [currentTaxCode]);
  useEffect(() => __async(this, null, function* () {
    if (dispatchIfTaxCodeDefIsValid) {
      setDispatchIfTaxCodeDefIsValid(false);
      if (taxCodeValidationContext.validationResult.isValid()) {
        if (currentTaxCode.id === void 0) {
          yield addTaxCodeDef(currentTaxCode);
        } else {
          yield updateTaxCodeDef(currentTaxCode);
        }
      }
    }
  }), [taxCodeValidationContext.validationResult]);
  const onNewTaxCodeClicked = () => {
    navigate(NEW_TAX_CODE);
  };
  const onEditTaxCodeClicked = (taxCode) => {
    navigate(`${taxCode.returnType}-${toTaxCodeString(taxCode.taxCode)}`);
  };
  const onToggleTaxCodeClicked = (taxCodeDefId, enable) => {
    toggleTaxCode(taxCodeDefId, enable);
  };
  const onTaxCodeSaveClicked = () => {
    taxCodeValidationContext.validateFields(ALL_TAX_CODE_DEF_FIELDS);
    if (validateConditions()) {
      setDispatchIfTaxCodeDefIsValid(true);
    }
  };
  const addConditionClicked = () => {
    setCurrentTaxCode(__spreadProps(__spreadValues({}, currentTaxCode), { conditions: currentTaxCode.conditions.concat([{ condition: [] }]) }));
  };
  const addTermClicked = (conditionIndex) => {
    setCurrentTaxCode(__spreadProps(__spreadValues({}, currentTaxCode), {
      conditions: currentTaxCode.conditions.map((conditionObj, i) => {
        if (i === conditionIndex) {
          return { condition: conditionObj.condition.concat([{}]) };
        } else {
          return conditionObj;
        }
      })
    }));
  };
  const removeConditionClicked = (conditionIndex) => {
    setCurrentTaxCode(__spreadProps(__spreadValues({}, currentTaxCode), {
      conditions: currentTaxCode.conditions.filter((_, i2) => i2 !== conditionIndex)
    }));
  };
  const removeTermClicked = (conditionIndex, termIndex) => {
    setCurrentTaxCode(__spreadProps(__spreadValues({}, currentTaxCode), {
      conditions: currentTaxCode.conditions.map((conditionObj, i) => {
        if (i === conditionIndex) {
          return { condition: conditionObj.condition.filter((_, i2) => i2 !== termIndex) };
        } else {
          return conditionObj;
        }
      })
    }));
  };
  const onPageChanged = (e) => __async(this, null, function* () {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setSelectedPageIndex(pageIndex);
    setSelectedPageSize(pageSize);
  });
  const htmlTaxCodeString = (taxCode) => {
    var _a, _b;
    return html`<div class="text-xl">
			<span>${taxCode.returnType} - </span>
			<span>${taxCode.taxCode.reportingCountry ? REPORTING_COUNTRY_SHORT_CODE_MAPPING[taxCode.taxCode.reportingCountry] : "XX"}</span
			><span class="text-red-400">${(_a = taxCode.taxCode.tranTypeCode) == null ? void 0 : _a.toUpperCase()}</span
			><span>${taxCode.taxCode.vatRateType === void 0 ? "XXX" : VAT_RATE_TYPE_SHORT_CODE_MAPPING[taxCode.taxCode.vatRateType]}</span
			><span class="text-red-400">${(_b = taxCode.taxCode.typeOfGoods) == null ? void 0 : _b.toUpperCase()}</span>${taxCode.taxCode.distanceSalesCountry === void 0 ? "" : html`<span>${DISTANCE_SALES_COUNTRY_SHORT_CODE_MAPPING[taxCode.taxCode.distanceSalesCountry]}</span>`}
		</div>`;
  };
  const htmlTermEditor = (term, conditionIndex, termIndex) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s;
    const termType = TERM_CODE_MAPPING[term.code];
    const htmlTermCode = html`<dc-select
			.dataSource=${TERM_CODE_LABELS}
			.selectedValues=${term.code}
			.validationMessage=${(_a = term.validationMessages) == null ? void 0 : _a.code}
			.label=${""}
			.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
			@change=${(e) => {
      const newTermCode = e.detail.selectedValue;
      const newTermType = TERM_CODE_MAPPING[newTermCode];
      const newTerm = __spreadProps(__spreadValues({}, term), {
        code: newTermCode,
        op: newTermType === "Select" ? "Is" : newTermType === "DateRange" ? "IsBetween" : void 0,
        type: newTermType,
        selectVal: void 0,
        multiSelectVal: void 0,
        numberVal: void 0,
        validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), { code: newTermCode ? void 0 : "Required" })
      });
      setTerm(newTerm, conditionIndex, termIndex);
    }}
		></dc-select>`;
    let htmlTermOp = html``;
    if (termType === "Select") {
      htmlTermOp = html`IS`;
    } else if (termType === "Multiselect") {
      htmlTermOp = html`
				<dc-select
					.label=${""}
					.dataSource=${MULTI_SELECT_OP_LABELS}
					.selectedValues=${term.op}
					.validationMessage=${(_b = term.validationMessages) == null ? void 0 : _b.op}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newOp = e.detail.selectedValue;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            op: newOp,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), { op: newOp ? void 0 : "Required" })
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-select>
			`;
    } else if (termType === "Number") {
      htmlTermOp = html`
				<dc-select
					.label=${""}
					.dataSource=${NUMBER_OP_LABELS}
					.selectedValues=${term.op}
					.validationMessage=${(_c = term.validationMessages) == null ? void 0 : _c.op}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newOp = e.detail.selectedValue;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            op: newOp,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
              op: newOp ? void 0 : "Number required"
            })
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-select>
			`;
    } else if (termType === "DateRange") {
      htmlTermOp = html`Is Between`;
    }
    let htmlTermVal = html``;
    if (term.code === "TransactionType") {
      htmlTermVal = html`
				<dc-select
					.label=${""}
					.dataSource=${TRANSACTION_TYPE_LABELS}
					.selectedValues=${term.multiSelectVal}
					.validationMessage=${(_d = term.validationMessages) == null ? void 0 : _d.value}
					.multiSelect=${true}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newVal = e.detail.selectedValues;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            multiSelectVal: newVal,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
              value: newVal !== void 0 && newVal.length > 0 ? void 0 : "Required"
            })
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-select>
			`;
    } else if (term.code === "EstablishmentCountry" || term.code === "DepartureCountry" || term.code === "ArrivalCountry" || term.code === "OSSRegistrationCountry") {
      htmlTermVal = html`
				<dc-multiselect
					class="flex flex-wrap w-64"
					.label=${""}
					.selectedItems=${(_f = (_e = term.multiSelectVal) == null ? void 0 : _e.map((value) => {
        var _a2;
        return {
          label: (_a2 = taxCodeEstCountries.find((c) => c.value === value)) == null ? void 0 : _a2.label,
          value
        };
      })) != null ? _f : []}
					.validationMessage=${(_g = term.validationMessages) == null ? void 0 : _g.value}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newVal = e.detail.items.map((item) => item.value);
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            multiSelectVal: newVal,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
              value: newVal !== void 0 && newVal.length > 0 ? void 0 : "Required"
            })
          }),
          conditionIndex,
          termIndex
        );
      }}
					.dataSource=${estCountryDataSourceCallback}
				></dc-multiselect>
			`;
    } else if (term.code === "CustomerType") {
      htmlTermVal = html`
				<dc-select
					.label=${""}
					.dataSource=${CUSTOMER_TYPE_LABELS}
					.selectedValues=${term.selectVal}
					.validationMessage=${(_h = term.validationMessages) == null ? void 0 : _h.value}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newVal = e.detail.selectedValue;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            selectVal: newVal,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
              value: newVal ? void 0 : "Required"
            })
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-select>
			`;
    } else if (term.code === "NetConsignmentVal") {
      htmlTermVal = html`
				<dc-input
					.label=${""}
					.value=${term.numberVal}
					.validationMessage=${(_i = term.validationMessages) == null ? void 0 : _i.value}
                    .readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const tempNum = toSafeNumber(e.detail.value);
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            numberVal: tempNum,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
              value: tempNum ? void 0 : "Number required"
            })
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-select>
			`;
    } else if (term.code === "OnlineMarketplace" || term.code === "AccountWithReverseCharge" || term.code === "ExportOutsideEU" || term.code === "ClientHasOSSNumber") {
      htmlTermVal = html`
				<dc-select
					.label=${""}
					.dataSource=${YES_NO_LABELS}
					.selectedValues=${term.selectVal}
					.validationMessage=${(_j = term.validationMessages) == null ? void 0 : _j.value}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newVal = e.detail.selectedValue;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            selectVal: newVal,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
              value: newVal ? void 0 : "Required"
            })
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-select>
			`;
    } else if (term.code === "TaxCollectionResponsibility") {
      htmlTermVal = html`
				<dc-select
					.label=${""}
					.dataSource=${TAX_COLLECTION_LABELS}
					.selectedValues=${term.selectVal}
					.validationMessage=${(_k = term.validationMessages) == null ? void 0 : _k.value}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newVal = e.detail.selectedValue;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            selectVal: newVal,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
              value: newVal ? void 0 : "Required"
            })
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-select>
			`;
    } else if (term.code === "DeliveryCondition") {
      htmlTermVal = html`
				<dc-select
					.label=${""}
					.dataSource=${DELIVERY_CONDITION_LABELS}
					.selectedValues=${term.selectVal}
					.validationMessage=${(_l = term.validationMessages) == null ? void 0 : _l.value}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newVal = e.detail.selectedValue;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            selectVal: newVal,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
              value: newVal ? void 0 : "Required"
            })
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-select>
			`;
    } else if (term.code === "TransactionDate") {
      htmlTermVal = html`<div class="flex space-x-8">
				<dc-datepicker
					.value=${(_m = term.dateRange) == null ? void 0 : _m.From}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newFromVal = e.detail.value;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            dateRange: new DateRange(__spreadProps(__spreadValues({}, term.dateRange), {
              From: newFromVal
            }))
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-datepicker>
				<dc-datepicker
					.value=${(_n = term.dateRange) == null ? void 0 : _n.To}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newToVal = e.detail.value;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            dateRange: new DateRange(__spreadProps(__spreadValues({}, term.dateRange), {
              To: newToVal
            }))
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-datepicker>
			</div>`;
    } else if (term.code === "SourceType") {
      htmlTermVal = html`
				<dc-select
					.label=${""}
					.dataSource=${SOURCE_TYPE_LABELS}
					.selectedValues=${term.selectVal}
					.validationMessage=${(_o = term.validationMessages) == null ? void 0 : _o.value}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newVal = e.detail.selectedValue;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            selectVal: newVal,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
              value: newVal ? void 0 : "Required"
            })
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-select>
			`;
    } else if (term.code === "IsRegisteredInArrivalCountry") {
      htmlTermVal = html`
				<dc-select
					.label=${""}
					.dataSource=${YES_NO_LABELS}
					.selectedValues=${term.selectVal}
					.validationMessage=${(_p = term.validationMessages) == null ? void 0 : _p.value}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newVal = e.detail.selectedValue;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            selectVal: newVal,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
              value: newVal ? void 0 : "Required"
            })
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-select>
			`;
    } else if (term.code === "DepartureAndArrivalCountry") {
      htmlTermVal = html`
				<dc-select
					.label=${""}
					.dataSource=${DEPARTURE_AND_ARRIVAL_COUNTRY_LABELS}
					.selectedValues=${term.selectVal}
					.validationMessage=${(_q = term.validationMessages) == null ? void 0 : _q.value}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newVal = e.detail.selectedValue;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            selectVal: newVal,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
              value: newVal ? void 0 : "Required"
            })
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-select>
			`;
    } else if (term.code === "TypeOfSale") {
      htmlTermVal = html`
				<dc-select
					.label=${""}
					.dataSource=${TYPE_OF_SALE_LABELS}
					.selectedValues=${term.selectVal}
					.validationMessage=${(_r = term.validationMessages) == null ? void 0 : _r.value}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newVal = e.detail.selectedValue;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            selectVal: newVal,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
              value: newVal ? void 0 : "Required"
            })
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-select>
			`;
    } else if (term.code === "PriceOfItem") {
      htmlTermVal = html`
				<dc-select
					.label=${""}
					.dataSource=${PRICE_OF_ITEM_LABELS}
					.selectedValues=${term.selectVal}
					.validationMessage=${(_s = term.validationMessages) == null ? void 0 : _s.value}
					.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
					@change=${(e) => {
        const newVal = e.detail.selectedValue;
        setTerm(
          __spreadProps(__spreadValues({}, term), {
            selectVal: newVal,
            validationMessages: __spreadProps(__spreadValues({}, term.validationMessages), {
              value: newVal ? void 0 : "Required"
            })
          }),
          conditionIndex,
          termIndex
        );
      }}
				></dc-select>
			`;
    }
    return html`<div class="flex space-x-4 mb-4 items-center">
			<div>${htmlTermCode}</div>
			<div>${htmlTermOp}</div>
			<div>${htmlTermVal}</div>
			<div class=${permissionManager.hasPermission("Tax_Codes_Modify") ? "" : "hidden"}>
				<button class="btn btn-primary btn-sm" @click=${() => removeTermClicked(conditionIndex, termIndex)}>X</button>
			</div>
		</div>`;
  };
  const htmlTaxCodeEditor = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return html`<div>
			${loading.count > 0 ? "Loading..." : html`<div>
						<div>${currentTaxCode.id !== void 0 ? htmlTaxCodeString(currentTaxCode) : html`<div class="text-xl">${NEW_TAX_CODE}</div>`}</div>
						<div class="flex space-x-8 mt-4 items-end">
							<dc-select
								.label=${"Return Type*"}
								.selectedValues=${currentTaxCode.returnType}
								.dataSource=${RETURN_TYPE_LABELS}
								.validationMessage=${taxCodeValidationContext.getValidationMessage("ReturnType")}
								.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
								@change=${(e) => {
      taxCodeValidationContext.validateField("ReturnType");
      setCurrentTaxCode(__spreadProps(__spreadValues({}, currentTaxCode), {
        returnType: e.detail.selectedValue
      }));
    }}
							></dc-select>
							<dc-select
								.label=${"Reporting Country"}
								.selectedValues=${((_a = currentTaxCode.taxCode) == null ? void 0 : _a.reportingCountry) ? (_b = currentTaxCode.taxCode) == null ? void 0 : _b.reportingCountry : "NOT_SET"}
								.dataSource=${[{ value: "NOT_SET", label: "<Not Set> (XX)" }].concat(REPORTING_COUNTRY_TYPE_LABELS)}
								.validationMessage=${taxCodeValidationContext.getValidationMessage("ReportingCountry")}
								.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
								@change=${(e) => {
      const tempVal = e.detail.selectedValue === "NOT_SET" ? void 0 : e.detail.selectedValue;
      taxCodeValidationContext.validateField("ReportingCountry");
      setCurrentTaxCode(__spreadProps(__spreadValues({}, currentTaxCode), {
        taxCode: __spreadProps(__spreadValues({}, currentTaxCode.taxCode), {
          reportingCountry: tempVal
        })
      }));
    }}
							></dc-select>
							<dc-input
								.value=${(_c = currentTaxCode.taxCode) == null ? void 0 : _c.tranTypeCode}
								.label=${"Transaction Type*"}
								.validationMessage=${taxCodeValidationContext.getValidationMessage("TransactionTypeCode")}
								.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
								@change=${(e) => {
      taxCodeValidationContext.validateField("TransactionTypeCode");
      setCurrentTaxCode(__spreadProps(__spreadValues({}, currentTaxCode), {
        taxCode: __spreadProps(__spreadValues({}, currentTaxCode.taxCode), {
          tranTypeCode: e.detail.value
        })
      }));
    }}
							></dc-input>
							<dc-select
								.label=${"VAT Rate Type"}
								.selectedValues=${((_d = currentTaxCode.taxCode) == null ? void 0 : _d.vatRateType) ? (_e = currentTaxCode.taxCode) == null ? void 0 : _e.vatRateType : "NOT_SET"}
								.dataSource=${[{ value: "NOT_SET", label: "<Not Set> (XXX)" }].concat(VAT_RATE_TYPE_LABELS)}
								.validationMessage=${taxCodeValidationContext.getValidationMessage("VATRateType")}
								.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
								@change=${(e) => {
      taxCodeValidationContext.validateField("VATRateType");
      const tempVal = e.detail.selectedValue === "NOT_SET" ? void 0 : e.detail.selectedValue;
      setCurrentTaxCode(__spreadProps(__spreadValues({}, currentTaxCode), {
        taxCode: __spreadProps(__spreadValues({}, currentTaxCode.taxCode), {
          vatRateType: tempVal
        })
      }));
    }}
							></dc-select>
							<dc-input
								.value=${(_f = currentTaxCode.taxCode) == null ? void 0 : _f.typeOfGoods}
								.label=${"Type of Goods*"}
								.validationMessage=${taxCodeValidationContext.getValidationMessage("TypeOfGoods")}
								.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
								@change=${(e) => {
      taxCodeValidationContext.validateField("TypeOfGoods");
      setCurrentTaxCode(__spreadProps(__spreadValues({}, currentTaxCode), {
        taxCode: __spreadProps(__spreadValues({}, currentTaxCode.taxCode), {
          typeOfGoods: e.detail.value
        })
      }));
    }}
							></dc-input>
							<dc-select
								.label=${"Distance Sales Country"}
								.selectedValues=${((_g = currentTaxCode.taxCode) == null ? void 0 : _g.distanceSalesCountry) ? (_h = currentTaxCode.taxCode) == null ? void 0 : _h.distanceSalesCountry : "NOT_SET"}
								.dataSource=${[{ value: "NOT_SET", label: "<Not Set>" }].concat(DISTANCE_SALES_COUNTRY_TYPE_LABELS)}
								.validationMessage=${taxCodeValidationContext.getValidationMessage("DistanceSalesCountry")}
								.readonly=${!permissionManager.hasPermission("Tax_Codes_Modify")}
								@change=${(e) => {
      taxCodeValidationContext.validateField("DistanceSalesCountry");
      const tempVal = e.detail.selectedValue === "NOT_SET" ? void 0 : e.detail.selectedValue;
      setCurrentTaxCode(__spreadProps(__spreadValues({}, currentTaxCode), {
        taxCode: __spreadProps(__spreadValues({}, currentTaxCode.taxCode), {
          distanceSalesCountry: tempVal
        })
      }));
    }}
							></dc-select>
							<div class=${permissionManager.hasPermission("Tax_Codes_Modify") ? "" : "hidden"}>
								<button class="btn btn-primary" @click=${onTaxCodeSaveClicked}>Save</button>
							</div>
						</div>
						<div class="mt-8">
							<div class="font-bold">Condition:</div>
							${validationMessageCondition ? html`<div class="text-red-400 my-2">${validationMessageCondition}</div>` : ""}
							${currentTaxCode.conditions.map(
      (conditionObj, conditionIndex) => html`<div>
										${conditionIndex > 0 ? html`<div class="mt-4">OR</div>` : ""}</div>
										<div class="flex space-x-8 mt-4">
											<div class="border p-4">
												<div>
													${conditionObj.condition.map((term, termIndex) => html`<div>${htmlTermEditor(term, conditionIndex, termIndex)}</div>`)}
													<div class=${permissionManager.hasPermission("Tax_Codes_Modify") ? "" : "hidden"}>
														<button
                                                            class="btn btn-primary btn-sm"
															@click=${() => addTermClicked(conditionIndex)}
														>
															+
														</button>
													</div>
												</div>
											</div>
											<div class=${permissionManager.hasPermission("Tax_Codes_Modify") ? "" : "hidden"}>
                                                <button
                                                    class="btn btn-primary"
                                                    @click=${() => removeConditionClicked(conditionIndex)}
                                                >
                                                    Remove
                                                </button>
											</div>
										</div>
									</div>`
    )}
							<div class=${permissionManager.hasPermission("Tax_Codes_Modify") ? "" : "hidden"}>
								<button class="btn btn-primary mt-4" @click=${addConditionClicked}>+</button>
							</div>
						</div>
				  </div>`}
		</div>`;
  };
  const htmlTaxCodeMain = () => {
    return html`<div>
			<div class="grid grid-col-1 gap-4 justify-between">
				<div>${templateTitle("Tax Codes")}</div>
				<div class="flex space-x-4 items-center">
					<dc-input
						.placeholder=${"Search"}
						.onInputDebounceMs=${500}
						@input=${(e) => {
      setSearchFragment(e.detail.value);
    }}
					></dc-input>
					<button ?disabled=${!permissionManager.hasPermission("Tax_Codes_Modify")} class="btn btn-primary" @click=${onNewTaxCodeClicked}>New</button>

					<dc-checkbox2
						.checked=${enabledTaxCodesOnly}
						.label=${"Enabled only"}
						.labelnowrap=${true}
						@change=${(e) => {
      setEnabledTaxCodesOnly(e.detail.checked);
    }}
					></dc-checkbox2>
				</div>
			</div>
			<div class="mt-4">${loading.count > 0 ? "Loading..." : htmlTaxCodeList()}</div>
			<dc-pager .pageIndex=${selectedPageIndex} .pageSize=${selectedPageSize} .pageSizes=${[20, 50]} .itemCount=${taxCodeDefsTotal} @change=${onPageChanged}></dc-pager>
		</div>`;
  };
  const htmlTerm = (term) => {
    var _a;
    if (term.type === "Select") {
      return html`${term.code} == ${term.selectVal}`;
    }
    if (term.type === "Multiselect") {
      return html`${term.code} ${term.op === "In" ? "IN" : "NOT IN"} [${(_a = term.multiSelectVal) == null ? void 0 : _a.join(", ")}]`;
    }
    if (term.type === "Number") {
      return html`${term.code} ${term.op} [${term.numberVal}]`;
    }
    if (term.type === "DateRange") {
      return html`${term.code} ${term.op} [${term.dateRange.From ? formatDate(term.dateRange.From) : html`<span class="text-2xl">-&#x221e;</span>`} -
			${term.dateRange.To ? formatDate(term.dateRange.To) : html`<span class="text-2xl">&#x221e;</span>`}]`;
    }
    return html`<div class="text-red-400">Unknown Term</div>`;
  };
  const htmlTaxCodeEntry = (taxCodeDef) => {
    var _a, _b;
    return html`<div class="space-y-4">
			<div class="flex justify-between">
				<div class="flex space-x-4 items-baseline">
					${htmlTaxCodeString(taxCodeDef)}
					<div>
						${(_a = REPORTING_COUNTRY_TYPE_LABELS.find((l) => l.value === taxCodeDef.taxCode.reportingCountry)) == null ? void 0 : _a.label}
						${taxCodeDef.taxCode.distanceSalesCountry === void 0 ? "" : html` | ${(_b = REPORTING_COUNTRY_TYPE_LABELS.find((l) => l.value === taxCodeDef.taxCode.distanceSalesCountry)) == null ? void 0 : _b.label}`}
					</div>
				</div>
				<div class="flex space-x-4">
					<button class="btn btn-primary" @click=${() => onEditTaxCodeClicked(taxCodeDef)}>${permissionManager.hasPermission("Tax_Codes_Modify") ? "Edit" : "View"}</button>
					<button class="btn btn-primary" ?disabled=${!permissionManager.hasPermission("Tax_Codes_Modify")} @click=${() => onToggleTaxCodeClicked(taxCodeDef.id, !taxCodeDef.enabled)}>
						${taxCodeDef.enabled ? "Disable" : "Enable"}
					</button>
				</div>
			</div>
			<div>
				<hr />
			</div>
			<div>
				${taxCodeDef.conditions.map(
      (conditionObj, i) => html`<div>
							${i > 0 ? html`<div class="text-gray-500 my-2">OR</div>` : ""}
							<div class="flex space-x-4">${conditionObj.condition.map((term) => html`<div class="bg-gray-400 rounded px-2 py-1">${htmlTerm(term)}</div>`)}</div>
						</div>`
    )}
			</div>
		</div>`;
  };
  const htmlTaxCodeList = () => {
    return html`<div>
			<div class="mt-4">${taxCodeDefs == null ? void 0 : taxCodeDefs.map((taxCodeDef) => html`<div class="my-8 p-4 border">${htmlTaxCodeEntry(taxCodeDef)}</div>`)}</div>
		</div>`;
  };
  return html`<div class="my-2 mx-8 mb-16 mt-8">${currentTaxCodeString === void 0 ? htmlTaxCodeMain() : htmlTaxCodeEditor()}</div>`;
}
