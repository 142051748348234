import "./dc-validation-summary.scss";
import { html } from "lit-html";
import { component } from "haunted";
export const Component = (host) => {
  const props = host;
  return html`
		<div class="validation-summary">
			<ul>
				${(props.messages || []).map((message) => html` <li>${message}</li> `)}
			</ul>
		</div>
	`;
};
customElements.define(
  "dc-validation-summary",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
