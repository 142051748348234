var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { usePermissionManager, useServiceCountries } from "AdminComponents/hooks/AdminCustomHooks";
import { formatDate } from "GlobalShared/helpers/dateHelper";
import { templateTitle } from "GlobalShared/templates/commons";
import {
  ALL_ESTABLISHMENT_COUNTRY_TYPE_TYPE_LABELS,
  ALL_ESTABLISHMENT_COUNTRY_TYPE_TYPES,
  ALL_FREQUENCY_TYPE_LABELS,
  ALL_FREQUENCY_TYPES,
  ALL_TAX_RETURN_TYPE_LABELS,
  ALL_TAX_RETURN_TYPES,
  DeadlineConfigurationFieldNames,
  mapDeadlineConfiguration
} from "AdminComponents/deadline-configuration/DeadlineConfigurationModels";
import { useClient, useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { notifyErrorWithWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { SubmissionDeadlineConfigurations as SubmissionDeadlineConfigurationsClient } from "AdminServices/APIs/self/clients";
import { SearchRequest, Pager, Field, Filter } from "AdminServices/APIs/self/models/V1/SubmissionDeadlineConfigurations/Search";
import { FieldOrder } from "AdminServices/APIs/self/models/V1/SubmissionDeadlineConfigurations/Search/Field";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
const DEFAULT_GRID_STATE = {
  pageIndex: 0,
  appliedFilters: [],
  pageSize: 20,
  orderBy: DeadlineConfigurationFieldNames.ServiceCountryCode,
  orderDir: "asc"
};
export function DeadlineConfiguration() {
  const getFilter = (gridState2) => {
    const clientFilter = new Filter();
    if (gridState2.appliedFilters.length > 0) {
      gridState2.appliedFilters.forEach((filter) => {
        if (filter.field === DeadlineConfigurationFieldNames.ServiceCountryCode) {
          clientFilter.ServiceCountryCode = filter.filterProps.selectedValues;
        } else if (filter.field === DeadlineConfigurationFieldNames.ReturnType) {
          if (filter.filterProps.selectedValues) {
            clientFilter.ReturnTypes = filter.filterProps.selectedValues;
          }
        } else if (filter.field === DeadlineConfigurationFieldNames.FrequencyType) {
          if (filter.filterProps.selectedValues) {
            clientFilter.FrequencyTypes = filter.filterProps.selectedValues;
          }
        } else if (filter.field === DeadlineConfigurationFieldNames.FilingExtension) {
          clientFilter.FilingExtension = filter.filterProps.selectedValues === "Yes";
        } else if (filter.field === DeadlineConfigurationFieldNames.EFiling) {
          clientFilter.EFiling = filter.filterProps.selectedValues === "Yes";
        } else if (filter.field === DeadlineConfigurationFieldNames.IsEPayment) {
          clientFilter.IsEPayment = filter.filterProps.selectedValues === "Yes";
        } else if (filter.field === DeadlineConfigurationFieldNames.Deadline) {
          clientFilter.DeadlineFrom = filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0;
          clientFilter.DeadlineTo = filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0;
        } else if (filter.field === DeadlineConfigurationFieldNames.ClientApprovalDueDate) {
          clientFilter.ClientApprovalDueDateFrom = filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0;
          clientFilter.ClientApprovalDueDateTo = filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0;
        } else if (filter.field === DeadlineConfigurationFieldNames.PaymentDueDate) {
          clientFilter.PaymentDueDateFrom = filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0;
          clientFilter.PaymentDueDateTo = filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0;
        } else if (filter.field === DeadlineConfigurationFieldNames.ReturnGenerationDueDate) {
          clientFilter.ReturnGenerationDueDateFrom = filter.filterProps.min !== void 0 ? filter.filterProps.min : void 0;
          clientFilter.ReturnGenerationDueDateTo = filter.filterProps.max !== void 0 ? filter.filterProps.max : void 0;
        }
      });
    }
    return clientFilter;
  };
  const search = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const searchResult2 = yield deadlineConfigurationClient.search({
          body: new SearchRequest({
            Filter: getFilter(gridState),
            Pager: new Pager({
              Skip: gridState.pageIndex * gridState.pageSize,
              Top: gridState.pageSize,
              OrderBy: [
                new Field({
                  Order: gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: gridState.orderBy
                })
              ]
            })
          })
        });
        setSearchResult({
          TotalCount: searchResult2.data.TotalCount,
          DeadlineConfigurations: searchResult2.data.SubmissionDeadlineConfigurations.map(mapDeadlineConfiguration)
        });
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const onSortChanged = (e) => {
    setGridState(__spreadProps(__spreadValues({}, gridState), { orderBy: e.detail.orderBy, orderDir: e.detail.orderDir }));
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridFilter);
  };
  const deadlineConfigurationClient = useClient(SubmissionDeadlineConfigurationsClient);
  const serviceCountries = useServiceCountries("en-GB");
  const permissionManager = usePermissionManager();
  const [gridState, setGridState] = useState(DEFAULT_GRID_STATE);
  const [searchResult, setSearchResult] = useState(void 0);
  useEffect(() => {
    search();
  }, [gridState]);
  const htmlDeadlineConfigGrid = () => {
    var _a;
    const vm = {
      columns: [
        {
          field: DeadlineConfigurationFieldNames.ServiceCountryCode,
          label: "Service Country",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: serviceCountries.map((sc) => ({ label: sc.Name, value: sc.Code })),
            searchable: true,
            searchProps: { type: "includes" }
          },
          columnType: "enum"
        },
        {
          field: DeadlineConfigurationFieldNames.ReturnType,
          label: "Return type",
          filterable: true,
          sortable: true,
          filterDescriptor: {
            type: "select",
            multiSelect: true,
            searchable: true,
            searchProps: { type: "includes" },
            dataSource: ALL_TAX_RETURN_TYPES.map((rt) => ({
              value: rt,
              label: ALL_TAX_RETURN_TYPE_LABELS[rt]
            }))
          },
          columnType: "enum"
        },
        {
          field: DeadlineConfigurationFieldNames.FrequencyType,
          label: "Frequency type",
          filterable: true,
          sortable: true,
          filterDescriptor: {
            type: "select",
            multiSelect: true,
            searchable: true,
            searchProps: { type: "includes" },
            dataSource: ALL_FREQUENCY_TYPES.map((rt) => ({
              value: rt,
              label: ALL_FREQUENCY_TYPE_LABELS[rt]
            }))
          },
          columnType: "enum"
        },
        {
          field: DeadlineConfigurationFieldNames.PeriodNumber,
          label: "Period",
          columnType: "string",
          filterable: false,
          sortable: true,
          filterDescriptor: { type: "string" }
        },
        {
          field: DeadlineConfigurationFieldNames.FilingExtension,
          label: "Filing Extension",
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: ["Yes", "No"]
          },
          columnType: "enum"
        },
        {
          field: DeadlineConfigurationFieldNames.EFiling,
          label: "E Filing",
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: ["Yes", "No"]
          },
          columnType: "enum"
        },
        {
          field: DeadlineConfigurationFieldNames.Deadline,
          label: "Submission due",
          columnType: "date",
          sortable: true,
          filterable: true,
          filterDescriptor: { from: true, to: true, type: "date" }
        },
        {
          field: DeadlineConfigurationFieldNames.ClientApprovalDueDate,
          label: "Approval due",
          columnType: "date",
          sortable: true,
          filterable: true,
          filterDescriptor: { from: true, to: true, type: "date" }
        },
        {
          field: DeadlineConfigurationFieldNames.PaymentDueDate,
          label: "Payment due",
          columnType: "date",
          sortable: true,
          filterable: true,
          filterDescriptor: { from: true, to: true, type: "date" }
        },
        {
          field: DeadlineConfigurationFieldNames.ReturnGenerationDueDate,
          label: "Return gen due",
          columnTitle: "Return generation due",
          columnType: "date",
          sortable: true,
          filterable: true,
          filterDescriptor: { from: true, to: true, type: "date" }
        },
        {
          field: DeadlineConfigurationFieldNames.EstablishmentCountryType,
          label: "Establishment country type",
          filterable: true,
          sortable: true,
          filterDescriptor: {
            type: "select",
            multiSelect: true,
            searchable: true,
            searchProps: { type: "includes" },
            dataSource: ALL_ESTABLISHMENT_COUNTRY_TYPE_TYPES.map((rt) => ({
              value: rt,
              label: ALL_ESTABLISHMENT_COUNTRY_TYPE_TYPE_LABELS[rt]
            }))
          },
          columnType: "enum"
        },
        {
          field: DeadlineConfigurationFieldNames.IsEPayment,
          label: "E-Payment",
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: ["Yes", "No"],
            rightAlign: true
          },
          columnType: "enum"
        }
      ],
      data: searchResult == null ? void 0 : searchResult.DeadlineConfigurations,
      sorting: {
        orderBy: gridState.orderBy,
        orderDir: gridState.orderDir
      },
      cellTemplate: (index, field) => {
        const item = searchResult == null ? void 0 : searchResult.DeadlineConfigurations[index];
        if (field === DeadlineConfigurationFieldNames.ServiceCountryCode) {
          return item.ServiceCountryCode;
        } else if (field === DeadlineConfigurationFieldNames.ReturnType) {
          return item.ReturnType;
        } else if (field === DeadlineConfigurationFieldNames.FrequencyType) {
          return item.FrequencyType;
        } else if (field === DeadlineConfigurationFieldNames.FilingExtension) {
          return item.FilingExtension ? "Yes" : "No";
        } else if (field === DeadlineConfigurationFieldNames.EFiling) {
          return item.EFiling ? "Yes" : "No";
        } else if (field === DeadlineConfigurationFieldNames.PeriodNumber) {
          return item.Period;
        } else if (field === DeadlineConfigurationFieldNames.Deadline) {
          return formatDate(item.Deadline);
        } else if (field === DeadlineConfigurationFieldNames.ReturnGenerationDueDate) {
          return item.ReturnGenerationDueDate ? formatDate(item.ReturnGenerationDueDate) : "";
        } else if (field === DeadlineConfigurationFieldNames.ClientApprovalDueDate) {
          return item.ClientApprovalDueDate ? formatDate(item.ClientApprovalDueDate) : "";
        } else if (field === DeadlineConfigurationFieldNames.PaymentDueDate) {
          return item.PaymentDueDate ? formatDate(item.PaymentDueDate) : "";
        } else if (field === DeadlineConfigurationFieldNames.EstablishmentCountryType) {
          return item.EstablishmentCountryType === "NonEU" ? "Non EU" : item.EstablishmentCountryType;
        } else if (field === DeadlineConfigurationFieldNames.IsEPayment) {
          if (item.IsEPayment === void 0) {
            return "";
          }
          return item.IsEPayment ? "Yes" : "No";
        } else {
          return "";
        }
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: searchResult == null ? void 0 : searchResult.TotalCount
      }
    };
    return html` <div class="space-y-4">
			<div>${templateTitle("Submission Deadline Configuration")}</div>
			<div class="flex space-x-2">
				${((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html`
							<button
								class="btn btn-primary"
								@click=${() => {
      setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex: 0, appliedFilters: [] }));
    }}
							>
								Reset filters
							</button>
					  ` : ""}
			</div>
			<div>
				<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>
			</div>
		</div>`;
  };
  return html` <div class="mt-8 mx-8">${permissionManager.hasPermission("Submission_DeadlineConfiguration_Read") ? htmlDeadlineConfigGrid() : ""}</div>`;
}
