export var FieldOrderByField = /* @__PURE__ */ ((FieldOrderByField2) => {
  FieldOrderByField2["CompanyId"] = "CompanyId";
  FieldOrderByField2["CompanyName"] = "CompanyName";
  FieldOrderByField2["Status"] = "Status";
  FieldOrderByField2["QuestionnaireStatus"] = "QuestionnaireStatus";
  FieldOrderByField2["RegistrationDate"] = "RegistrationDate";
  FieldOrderByField2["IsSignedUp"] = "IsSignedUp";
  FieldOrderByField2["SentinelValidTo"] = "SentinelValidTo";
  FieldOrderByField2["ClientSource"] = "ClientSource";
  FieldOrderByField2["AccountManagerEmail"] = "AccountManagerEmail";
  FieldOrderByField2["PreparerEmail"] = "PreparerEmail";
  FieldOrderByField2["ReviewerEmail"] = "ReviewerEmail";
  FieldOrderByField2["CoordinatorEmail"] = "CoordinatorEmail";
  return FieldOrderByField2;
})(FieldOrderByField || {});
export var FieldOrder = /* @__PURE__ */ ((FieldOrder2) => {
  FieldOrder2["Ascending"] = "Ascending";
  FieldOrder2["Descending"] = "Descending";
  return FieldOrder2;
})(FieldOrder || {});
