var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { formatDate, interpretYearOneAsUndefined } from "GlobalShared/helpers/dateHelper";
import { notifyWarning } from "GlobalShared/helpers/errorHelper";
import { COMPLIANCE_SUBSCRIPTION_STATUS_LABELS } from "GlobalShared/models/ServicesModels";
import i18next from "i18next";
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { useCommentModal } from "../reg-modals/comment-modal";
import { getStatusLabel } from "./services-common";
import { downloadPDFIcon } from "GlobalShared/templates/commons";
import { useIOSSModal } from "../reg-modals/ioss-modal";
import { useConfirmModal } from "../modals/commonModals";
import { ONLY_PDF_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
import { useSubCancellationModal } from "./sub-cancellation-modal";
import { useDeregistrationModal } from "../reg-modals/deregistration-modal";
export const useIossService = (props) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A, _B, _C;
  const getValidNextIossTransition = (from) => {
    var _a2;
    return (_a2 = validIOSSTransitions == null ? void 0 : validIOSSTransitions.reduce((aggr, curr) => {
      if (curr.From === from) {
        return aggr.concat(curr.To);
      } else {
        return aggr;
      }
    }, [])) != null ? _a2 : [];
  };
  const mapToClientCompatibleIOSSStatus = (status) => {
    if (status === "Failed" || status === "Declined") {
      return getStatusLabel("UnderReview");
    } else {
      return getStatusLabel(status);
    }
  };
  const [showSignedIOSSDocumentUploadModal, setShowSignedIOSSDocumentUploadModal] = useState(false);
  const [currentIOSSRegId, setCurrentIOSSRegId] = useState(void 0);
  const [currentIOSSDocumentId, setCurrentIOSSDocumentId] = useState(void 0);
  const [validIOSSTransitions, setValidIOSSTransitions] = useState([]);
  const [currentCommentModalProps, setCurrentCommentModalProps] = useState(void 0);
  const commentModal = useCommentModal(currentCommentModalProps);
  const [currentIOSSModalProps, setCurrentIOSSModalProps] = useState(void 0);
  const iossModal = useIOSSModal(currentIOSSModalProps);
  const confirmModal = useConfirmModal();
  const [currentCancellationModalProps, setCurrentCancellationModalProps] = useState(void 0);
  const subCancellationModal = useSubCancellationModal(currentCancellationModalProps);
  const [currentDeregistrationModalProps, setCurrentDeregistrationModalProps] = useState(void 0);
  const deregistrationModal = useDeregistrationModal(currentDeregistrationModalProps);
  useEffect(() => __async(void 0, null, function* () {
    if (props == null ? void 0 : props.isAdmin) {
      setValidIOSSTransitions(yield props.callbackHandler.getPossibleIossStatuses());
    }
  }), [props.callbackHandler]);
  const htmlSignedIOSSDocumentUploadModal = () => {
    if (showSignedIOSSDocumentUploadModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showSignedIOSSDocumentUploadModal}
						@close=${() => setShowSignedIOSSDocumentUploadModal(false)}
						.header=${"Upload signed PDF"}
						.content=${html`<div>
							<dc-fileupload
								.label=${"PDF Document"}
								.fileName=${void 0}
								.fileReference=${void 0}
								.validationMessage=${void 0}
								.accept=${ONLY_PDF_ACCEPT_LIST}
								@upload=${(e) => __async(void 0, null, function* () {
        var _a2;
        if (((_a2 = e.detail) == null ? void 0 : _a2.files) && e.detail.files.length > 0) {
          yield props.callbackHandler.uploadIOSSSignedRegistrationDocument(currentIOSSRegId, currentIOSSDocumentId, e.detail.files[0]);
          setShowSignedIOSSDocumentUploadModal(false);
        }
      })}
							></dc-fileupload>

							<button class="btn btn-primary mt-4" @click=${() => setShowSignedIOSSDocumentUploadModal(false)}>Cancel</button>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const mainTemplate = ((_a = props.globalServices) == null ? void 0 : _a.IossService) !== void 0 && (props.showCancelledServices || ((_b = props.globalServices) == null ? void 0 : _b.IossService.ComplianceSubscriptionStatus) !== "Cancelled") ? html`
					<div class="mt-8 shadow border mx-8 px-8 py-4 xl:mx-auto xl:w-1/2 rounded ${props.globalServices.IossService.ComplianceSubscriptionStatus === "Cancelled" ? "bg-red-200" : ""}">
						<div class="flex justify-between">
							<div class="font-bold text-lg mt-1 mb-3">${i18next.t("IOSS Registration and Compliance")}</div>
							<div>
								${(props == null ? void 0 : props.isAdmin) ? html`<dc-dropdown
											.isStatusLike=${true}
											.buttonText=${getStatusLabel(props.globalServices.IossService.RegistrationStatus)}
											.selectedValues=${[props.globalServices.IossService.RegistrationStatus]}
											.dataSource=${[
    {
      label: getStatusLabel(props.globalServices.IossService.RegistrationStatus),
      value: props.globalServices.IossService.RegistrationStatus
    }
  ].concat(
    getValidNextIossTransition(props.globalServices.IossService.RegistrationStatus).map((status) => ({
      label: getStatusLabel(status),
      value: status
    }))
  )}
											.readonly=${!props.permissionManager.hasPermission("IOSS_Modify")}
											@click=${(e) => {
    var _a2;
    const newIOSSStatus = e.detail.selectedValue;
    if (props.globalServices.IossService.RegistrationStatus === "DocumentsSigned" && newIOSSStatus === "WaitingForSignature") {
      setCurrentCommentModalProps({
        save: (comment) => __async(void 0, null, function* () {
          yield props.callbackHandler.changeIossRegistrationStatus(
            props.globalServices.IossService.Id,
            props.globalServices.IossService.RegistrationStatus,
            newIOSSStatus,
            comment
          );
        })
      });
      commentModal.show();
    } else if (props.globalServices.IossService.RegistrationStatus === "SentToTA" && newIOSSStatus === "RegistrationCompleted") {
      setCurrentIOSSModalProps({
        iossCertificate: props.globalServices.IossService.IOSSCertificate,
        save: (iossCertificate) => __async(void 0, null, function* () {
          yield props.callbackHandler.changeIossRegistrationStatus(
            props.globalServices.IossService.Id,
            props.globalServices.IossService.RegistrationStatus,
            newIOSSStatus,
            void 0,
            iossCertificate
          );
        }),
        uploadIOSSCertificate: (file) => {
          return props.callbackHandler.uploadIossDocument(props.globalServices.IossService.Id, file);
        }
      });
      iossModal.show();
    } else if (props.globalServices.IossService.RegistrationStatus === "DeregistrationInProgress" && newIOSSStatus === "Deregistered") {
      setCurrentDeregistrationModalProps({
        change: {},
        save: (change) => __async(void 0, null, function* () {
          yield props.callbackHandler.changeIossRegistrationStatus(
            props.globalServices.IossService.Id,
            props.globalServices.IossService.RegistrationStatus,
            newIOSSStatus,
            void 0,
            void 0,
            change
          );
        }),
        uploadDocument: (file) => {
          return props.callbackHandler.uploadIossDocument(props.globalServices.IossService.Id, file);
        }
      });
      deregistrationModal.show();
    } else if (props.globalServices.IossService.RegistrationStatus === "WaitingForSignature" && newIOSSStatus === "DocumentsSigned") {
      if (((_a2 = props.globalServices.IossService.Documents) == null ? void 0 : _a2.length) > 0 && !props.globalServices.IossService.Documents.some((d) => d.Category === "RequiredElectronicallyOnly" && d.SignedDocumentReference === void 0)) {
        props.callbackHandler.changeIossRegistrationStatus(
          props.globalServices.IossService.Id,
          props.globalServices.IossService.RegistrationStatus,
          newIOSSStatus
        );
      } else {
        notifyWarning(i18next.t("Signed documents need to be uploaded"));
      }
    } else {
      props.callbackHandler.changeIossRegistrationStatus(
        props.globalServices.IossService.Id,
        props.globalServices.IossService.RegistrationStatus,
        newIOSSStatus
      );
    }
  }}
									  ></dc-dropdown>` : html`<div>${mapToClientCompatibleIOSSStatus(props.globalServices.IossService.RegistrationStatus)}</div>
											<div class="${props.globalServices.IossService.RegistrationStatus === "WaitingForSignature" ? "" : "hidden"}">
												<button
													class="btn btn-primary"
													?disabled=${props.globalServices.IossService.RegistrationStatus !== "WaitingForSignature" || props.globalServices.IossService.Documents.some(
    (doc) => doc.Category === "RequiredElectronicallyOnly" && doc.SignedDocumentReference === void 0
  )}
													@click=${() => __async(void 0, null, function* () {
    var _a2;
    if (((_a2 = props.globalServices.IossService.Documents) == null ? void 0 : _a2.length) === 0) {
      notifyWarning(i18next.t("No document generated"));
    } else {
      yield props.callbackHandler.finalizeIOSSUpload(props.globalServices.IossService.Id);
    }
  })}
												>
													${i18next.t("Finalize Upload")}
												</button>
											</div>`}
							</div>
						</div>
						<div class="border-b border-gray-300 pb-4">
							<div class="text-sm">
								<span>${i18next.t("Service start date")}: ${formatDate(props.globalServices.IossService.ValidFrom)}</span>
								&nbsp;|&nbsp;
								<span
									>${i18next.t("Service expiration date")}:
									${props.globalServices.IossService.ComplianceSubscriptionStatus === "Pending" ? "-" : formatDate(props.globalServices.IossService.ComplianceValidTo)}</span
								>
							</div>
							${props.isAdmin ? html`<div class="text-sm">
											<span>${i18next.t("Merchant Id")}: ${props.globalServices.IossService.MerchantId}</span>
										</div>
										<div class="text-sm">
											<span>${i18next.t("Product Id")}: ${props.globalServices.IossService.ProductId}</span>
										</div>` : ""}
							<div class="text-sm">
								<span>${i18next.t("Start date")}: ${formatDate(props.globalServices.IossService.RegistrationStartDate)}</span>
							</div>
						</div>
						<div class="justify-between border-b border-gray-300 items-center py-4">
							<div>
								${i18next.t("Compliance status")}: ${COMPLIANCE_SUBSCRIPTION_STATUS_LABELS[props.globalServices.IossService.ComplianceSubscriptionStatus]}
								${interpretYearOneAsUndefined(props.globalServices.IossService.ComplianceSubscriptionCancellationDate) ? html`(${formatDate(props.globalServices.IossService.ComplianceSubscriptionCancellationDate)})` : ""}
							</div>
							${props.globalServices.IossService.Tier === "Tier1" || props.globalServices.IossService.Tier === "Tier2" ? html`<div>${i18next.t("Available credit")}: ${props.globalServices.IossService.PackageAmount}</div>` : ""}
							<div class="pt-2">
								${props.isAdmin && props.permissionManager.hasPermission("IOSS_Modify") && props.globalServices.IossService.ComplianceSubscriptionStatus === "Active" ? html`<button
											class="btn btn-primary"
											@click=${() => __async(void 0, null, function* () {
    setCurrentCancellationModalProps({
      contractFrom: props.globalServices.IossService.ValidFrom,
      contractTo: props.globalServices.IossService.ComplianceValidTo,
      subscriptionId: props.globalServices.IossService.ComplianceSubscriptionId,
      cancelSubscription: props.callbackHandler.cancelSubscription
    });
    subCancellationModal.show();
  })}
									  >
											${i18next.t("Cancel")}
									  </button>` : ""}
							</div>
						</div>
						${((_e = (_d = (_c = props.globalServices) == null ? void 0 : _c.IossService) == null ? void 0 : _d.IOSSCertificate) == null ? void 0 : _e.CertificateReferenceId) || ((_h = (_g = (_f = props.globalServices) == null ? void 0 : _f.IossService) == null ? void 0 : _g.IOSSCertificate) == null ? void 0 : _h.RegistrationNumber) || ((_k = (_j = (_i = props.globalServices) == null ? void 0 : _i.IossService) == null ? void 0 : _j.IOSSCertificate) == null ? void 0 : _k.RegistrationDate) ? html`<div class="flex space-x-2 mt-4">
										<div>${i18next.t("IOSS Number")}:</div>
										<div>
											${(_n = (_m = (_l = props.globalServices) == null ? void 0 : _l.IossService) == null ? void 0 : _m.IOSSCertificate) == null ? void 0 : _n.RegistrationNumber}
											(${formatDate((_q = (_p = (_o = props.globalServices) == null ? void 0 : _o.IossService) == null ? void 0 : _p.IOSSCertificate) == null ? void 0 : _q.RegistrationDate)})
										</div>
										${((_t = (_s = (_r = props.globalServices) == null ? void 0 : _r.IossService) == null ? void 0 : _s.IOSSCertificate) == null ? void 0 : _t.CertificateReferenceId) ? html`<div
													class="cursor-pointer"
													@click=${() => __async(void 0, null, function* () {
    yield props.callbackHandler.downloadIOSSCertificate(props.globalServices.IossService.Id);
  })}
											  >
													${downloadPDFIcon()}
											  </div>` : ""}
									</div>
									<div class="mt-4">
										<span>${i18next.t("Country of registration")}: ${(_u = props.globalServices.IossService.CountryOfRegistration) == null ? void 0 : _u.Code}</span>
									</div>
									${props.globalServices.IossService.DeregistrationInfo ? html`<div class="flex space-x-2">
												<div>${i18next.t("Deregistered")}: (${formatDate(props.globalServices.IossService.DeregistrationInfo.DeregisteredAt)})</div>
												${((_v = props.globalServices.IossService.DeregistrationInfo) == null ? void 0 : _v.DocumentId) ? html` <div
															class="cursor-pointer"
															@click=${() => __async(void 0, null, function* () {
    yield props.callbackHandler.downloadIossDeregistrationDocument(props.globalServices.IossService.Id);
  })}
													  >
															${downloadPDFIcon()}
													  </div>` : ""}
										  </div>` : ""}` : ""}
						${((_w = props.globalServices.IossService.Documents) == null ? void 0 : _w.length) > 0 ? html`<div class="space-y-4">
									<div class="my-2">${i18next.t("Documents")}:</div>
									${props.globalServices.IossService.Documents.some((d) => d.Category === "RequiredElectronicallyOnly") ? html`<div>
												<div class="text-sm">
													${i18next.t("Required electronically only")}:<dc-tooltip
														.label=${i18next.t(
    "Please download the document(s) listed here, print, have them signed by the authorized signatory, then scan and upload. We will review the document(s), you don't have to post them as we can proceed with the signed copies."
  )}
													></dc-tooltip>
												</div>
												<table class="w-full mt-2 mb-1">
													<tr class="border-b h-10">
														<th class="text-left">Name</th>
														<th>Generated</th>
														<th>Signed</th>
													</tr>
													${props.globalServices.IossService.Documents.filter((d) => d.Category === "RequiredElectronicallyOnly").map(
    (document) => html`<tr class="border-b h-10">
																<td>
																	<div class="text-left">${document.Name}</div>
																</td>
																<td>
																	<div class="flex justify-around">
																		<div
																			class="cursor-pointer "
																			@click=${() => __async(void 0, null, function* () {
      yield props.callbackHandler.downloadIOSSRegistrationDocument(
        props.globalServices.IossService.Id,
        document.Id,
        "Unsigned"
      );
    })}
																		>
																			${downloadPDFIcon()}
																		</div>
																	</div>
																</td>
																<td>
																	<div class="flex justify-around">
																		<div class="flex space-x-2 items-center">
																			<div>
																				${document.SignedDocumentReference ? html`<div
																							class="cursor-pointer"
																							@click=${() => __async(void 0, null, function* () {
      yield props.callbackHandler.downloadIOSSRegistrationDocument(
        props.globalServices.IossService.Id,
        document.Id,
        "Signed"
      );
    })}
																					  >
																							${downloadPDFIcon()}
																					  </div>` : html``}
																			</div>
																			${props.globalServices.IossService.RegistrationStatus === "WaitingForSignature" ? html`<div>
																						<button
																							class="btn btn-primary btn-sm cursor-pointer"
																							@click=${() => __async(void 0, null, function* () {
      setCurrentIOSSRegId(props.globalServices.IossService.Id);
      setCurrentIOSSDocumentId(document.Id);
      setShowSignedIOSSDocumentUploadModal(true);
    })}
																						>
																							Upload
																						</button>
																				  </div>` : ""}
																		</div>
																	</div>
																</td>
															</tr>`
  )}
												</table>
										  </div>` : ""}
									${props.globalServices.IossService.Documents.some((d) => d.Category === "ForInformationOnly") ? html`<div>
												<div class="text-sm">
													${i18next.t("For information only")}<dc-tooltip .label=${i18next.t("There is no action required on your side.")}></dc-tooltip>
												</div>
												<table class="w-full">
													<tr>
														<th>Name</th>
														<th>Generated</th>
													</tr>
													${props.globalServices.IossService.Documents.filter((d) => d.Category === "ForInformationOnly").map(
    (document) => html`<tr class="border-b h-10">
																<td>
																	<div class="flex justify-around">${document.Name}</div>
																</td>
																<td>
																	<div class="flex justify-around">
																		<div
																			class="cursor-pointer "
																			@click=${() => __async(void 0, null, function* () {
      yield props.callbackHandler.downloadIOSSRegistrationDocument(
        props.globalServices.IossService.Id,
        document.Id,
        "Unsigned"
      );
    })}
																		>
																			${downloadPDFIcon()}
																		</div>
																	</div>
																</td>
																<td></td>
															</tr>`
  )}
												</table>
										  </div>` : ""}
							  </div>` : ""}
						${(props == null ? void 0 : props.isAdmin) && ((_z = (_y = (_x = props.globalServices) == null ? void 0 : _x.IossService) == null ? void 0 : _y.Errors) == null ? void 0 : _z.length) > 0 ? html`<div class="space-y-2 mt-4 text-red-500">
									<div>${i18next.t("Desucla Errors")}:</div>
									${(_C = (_B = (_A = props.globalServices) == null ? void 0 : _A.IossService) == null ? void 0 : _B.Errors) == null ? void 0 : _C.map((e) => html`<div class="ml-4">${e}</div>`)}
							  </div>` : ""}
					</div>
			  ` : "";
  const htmlModals = html`${htmlSignedIOSSDocumentUploadModal()} ${commentModal.template()} ${iossModal.template} ${confirmModal.mainTemplate()} ${subCancellationModal.template()}
	${deregistrationModal.template}`;
  return { mainTemplate, htmlModals };
};
