import { formatDate } from "GlobalShared/helpers/dateHelper";
import moment from "moment";
const YEARLY_PERIOD_COUNT = 5;
const INTERVALS = {
  Monthly: 1,
  Yearly: 12,
  BiMonthly: 2,
  Quarterly: 3,
  QuarterlyShifted1: 3,
  QuarterlyShifted2: 3,
  FourMonthly: 4,
  SixMonthly: 6
};
export const generateMonthlyPeriods = () => {
  const result = [];
  const actualYear = (/* @__PURE__ */ new Date()).getFullYear();
  const actualMonth = (/* @__PURE__ */ new Date()).getMonth();
  const startingDate = moment(new Date(actualYear - YEARLY_PERIOD_COUNT, 0, 1));
  let currentDate = moment(new Date(actualYear, actualMonth, 1));
  while (startingDate <= currentDate) {
    result.push({ startDate: currentDate, freq: "Monthly" });
    currentDate = moment(currentDate).subtract(1, "months");
  }
  return result.map((p) => ({
    label: formatPeriod(p.startDate, p.freq),
    value: `${formatDate(p.startDate)},${p.freq}`
  }));
};
export const generateAllPeriods = () => {
  const result = [];
  const actualYear = (/* @__PURE__ */ new Date()).getFullYear();
  const actualMonth = (/* @__PURE__ */ new Date()).getMonth();
  const startingDate = moment(new Date(actualYear - YEARLY_PERIOD_COUNT, 0, 1));
  let currentDate = moment(new Date(actualYear, actualMonth, 1));
  while (startingDate <= currentDate) {
    const currentMonth = currentDate.month();
    result.push({ startDate: currentDate, freq: "Monthly" });
    if (currentMonth % INTERVALS.Yearly === 0) {
      result.push({ startDate: currentDate, freq: "Yearly" });
    }
    if (currentMonth % INTERVALS.BiMonthly === 0) {
      result.push({ startDate: currentDate, freq: "BiMonthly" });
    }
    if (currentMonth % INTERVALS.Quarterly === 0) {
      result.push({ startDate: currentDate, freq: "Quarterly" });
    }
    if ((currentMonth - 1) % INTERVALS.QuarterlyShifted1 === 0) {
      result.push({ startDate: currentDate, freq: "QuarterlyShifted1" });
    }
    if ((currentMonth - 2) % INTERVALS.QuarterlyShifted2 === 0) {
      result.push({ startDate: currentDate, freq: "QuarterlyShifted2" });
    }
    if (currentMonth % INTERVALS.FourMonthly === 0) {
      result.push({ startDate: currentDate, freq: "FourMonthly" });
    }
    if (currentMonth % INTERVALS.SixMonthly === 0) {
      result.push({ startDate: currentDate, freq: "SixMonthly" });
    }
    currentDate = moment(currentDate).subtract(1, "months");
  }
  return result.map((p) => ({
    label: formatPeriod(p.startDate, p.freq),
    value: formatPeriod(moment(p.startDate).locale("en"), p.freq)
  }));
};
export const formatPeriod = (startDate, freq) => {
  if (freq === "Monthly") {
    return startDate.format("MMM-YYYY");
  } else if (freq === "Yearly") {
    return startDate.format("YYYY");
  }
  const endDate = getPeriodEndDate(startDate, freq);
  return `${startDate.format("MMM")}-${endDate.format("MMM")}-${startDate.format("YYYY")}`;
};
const getPeriodEndDate = (startDate, freq) => {
  return moment(startDate).add(INTERVALS[freq], "months").add(-1, "day");
};
