import { TswagClient } from "./../commons/TswagClient";
import { CompanyConfiguration } from "./../models/V1/ComplianceUploads/Configuration/CompanyConfiguration";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class ComplianceCompany {
  /**
   * Creates an instance of ComplianceCompany.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof ComplianceCompany
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GetDefaultConfigurationRq_ _getDefaultConfigurationRq
   * @returns Promise<GetDefaultConfigurationRq_>
   * @memberof ComplianceCompany
   */
  getDefaultConfiguration(pGetDefaultConfigurationRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceCompany/Companies/{companyId}/DefaultConfiguration",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pGetDefaultConfigurationRq.companyId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: CompanyConfiguration },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param AddConfigurationRq_ _addConfigurationRq
   * @returns Promise<AddConfigurationRq_>
   * @memberof ComplianceCompany
   */
  addConfiguration(pAddConfigurationRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceCompany/Companies/{companyId}/DefaultConfiguration",
      "PUT",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pAddConfigurationRq.companyId, required: true }
    ]);
    client.addBody({ value: pAddConfigurationRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateDefaultConfigurationRq_ _updateDefaultConfigurationRq
   * @returns Promise<UpdateDefaultConfigurationRq_>
   * @memberof ComplianceCompany
   */
  updateDefaultConfiguration(pUpdateDefaultConfigurationRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceCompany/Companies/{companyId}/DefaultConfiguration",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUpdateDefaultConfigurationRq.companyId, required: true }
    ]);
    client.addBody({ value: pUpdateDefaultConfigurationRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 204 },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
