var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { formatDate, interpretYearOneAsUndefined } from "GlobalShared/helpers/dateHelper";
import {
  COMPLIANCE_SUBSCRIPTION_STATUS_LABELS,
  SUBMISSION_FREQUENCY_TYPE_LABELS
} from "GlobalShared/models/ServicesModels";
import i18next from "i18next";
import { html } from "lit-html";
import {
  VAT_REG_DOCUMENT_STATUS_TRANSLATIONS,
  VAT_REG_DOCUMENT_ERROR_TRANSLATIONS
} from "GlobalShared/models/VatRegModels";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { useCommentModal } from "../reg-modals/comment-modal";
import { getStatusLabel } from "./services-common";
import { editIcon, downloadPDFIcon, subscribeIcon } from "GlobalShared/templates/commons";
import { useBankAccountModal } from "./bank-account-modal";
import { useFrequencyModal } from "./frequency-modal";
import { mapBooleanToState } from "GlobalShared/helpers/booleanHelper";
import { isEuCountry } from "GlobalShared/helpers/countryHelper";
import { canHavePaymentReference, getFrequencyDataSource } from "./service-helpers";
import { FrequenciesChangeRequestReturnType } from "AdminServices/APIs/self/models/V1/Company/VatRegistration/Frequency/FrequenciesChangeRequest";
import { useVatRegLocalVatModal } from "../reg-modals/local-vat-modal";
import { useVatRegEuVatModal } from "../reg-modals/euvat-modal";
import { usePaymentReferenceModal } from "./payment-reference-modal";
import { useConfirmModal } from "../modals/commonModals";
import { ONLY_PDF_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
import { useSubCancellationModal } from "./sub-cancellation-modal";
import { useDeregistrationModal } from "../reg-modals/deregistration-modal";
import { usePoARevokedModal } from "../reg-modals/poarevoke-modal";
import { useSubscriptionModal } from "./subscription-modal";
import { notifyError } from "GlobalShared/helpers/errorHelper";
import { useTaxIdentifierModal } from "GlobalShared/components/services/tax-identifier-modal";
export const useVatService = (props) => {
  var _a;
  const getEffectiveFrequency = (frequencies) => {
    const now = /* @__PURE__ */ new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1;
    return frequencies.filter((freq) => freq.ValidFromYear < currentYear || freq.ValidFromYear === currentYear && freq.ValidFromMonth <= currentMonth).reduce(
      (aggr, curr) => {
        if (curr.ValidFromYear > aggr.year || curr.ValidFromYear === currentYear && curr.ValidFromMonth > aggr.month) {
          return { year: curr.ValidFromYear, month: curr.ValidFromMonth, freq: curr };
        } else {
          return aggr;
        }
      },
      { year: 1920, month: 1, freq: void 0 }
    ).freq;
  };
  const canEditFreq = (freqOptions, freqs) => {
    const thereIsFreq = freqOptions.length > 0;
    const freqIsSet = getEffectiveFrequency(freqs) !== void 0;
    const moreThanOneFreqCanBeSelected = freqOptions.length > 1;
    return props.isAdmin && props.permissionManager.hasPermission("VAT_Modify") && (thereIsFreq && !freqIsSet || moreThanOneFreqCanBeSelected);
  };
  const canShowFreq = (freqOptions, freqs) => {
    return freqOptions.length > 0 || getEffectiveFrequency(freqs) !== void 0;
  };
  const isIntrastat = (intrastat) => {
    return intrastat === i18next.t("Intrastat In") || intrastat === i18next.t("Intrastat Out");
  };
  const getValidNextVatTransition = (from) => {
    var _a2;
    return (_a2 = validVatTransitions == null ? void 0 : validVatTransitions.reduce((aggr, curr) => {
      if (curr.From === from) {
        return aggr.concat(curr.To);
      } else {
        return aggr;
      }
    }, [])) != null ? _a2 : [];
  };
  const [showSignedVatRegPDFUploadModal, setShowSignedVatRegPDFUploadModal] = useState(false);
  const [currentVatRegId, setCurrentVatRegId] = useState(void 0);
  const [currentTemplateId, setCurrentTemplateId] = useState(void 0);
  const [currentCommentModalProps, setCurrentCommentModalProps] = useState(void 0);
  const commentModal = useCommentModal(currentCommentModalProps);
  const [currentLocalVatModalProps, setCurrentLocalVatModalProps] = useState(void 0);
  const localVatModal = useVatRegLocalVatModal(currentLocalVatModalProps);
  const [currentEuVatModalProps, setCurrentEuVatModalProps] = useState(void 0);
  const euVatModal = useVatRegEuVatModal(currentEuVatModalProps);
  const [currentBankAccountModalProps, setCurrentBankAccountModalProps] = useState(void 0);
  const bankAccountModal = useBankAccountModal(currentBankAccountModalProps);
  const [currentTaxIdentifierModalProps, setCurrentTaxIdentifierModalProps] = useState(void 0);
  const taxIdentifierModal = useTaxIdentifierModal(currentTaxIdentifierModalProps);
  const [currentPaymentReferenceModalProps, setCurrentPaymentReferenceModalProps] = useState(void 0);
  const paymentReferenceModal = usePaymentReferenceModal(currentPaymentReferenceModalProps);
  const [frequencyModalProps, setFrequencyModalProps] = useState(void 0);
  const frequencyModal = useFrequencyModal(frequencyModalProps);
  const confirmModal = useConfirmModal();
  const [currentCancellationModalProps, setCurrentCancellationModalProps] = useState(void 0);
  const subCancellationModal = useSubCancellationModal(currentCancellationModalProps);
  const [currentDeregistrationModalProps, setCurrentDeregistrationModalProps] = useState(void 0);
  const deregistrationModal = useDeregistrationModal(currentDeregistrationModalProps);
  const [currentPoARevokedModalProps, setCurrentPoARevokedModalProps] = useState(void 0);
  const poaRevokedModal = usePoARevokedModal(currentPoARevokedModalProps);
  const [subscriptionModalProps, setSubscriptionModalProps] = useState(void 0);
  const subscriptionModal = useSubscriptionModal(subscriptionModalProps);
  const [validVatTransitions, setValidVatTransitions] = useState([]);
  useEffect(() => __async(void 0, null, function* () {
    if (props == null ? void 0 : props.isAdmin) {
      setValidVatTransitions(yield props.callbackHandler.getPossibleVatStatuses());
    }
  }), [props.callbackHandler]);
  const htmlCountrySubServiceChip = (label, sub) => {
    if (sub.Status === "Cancelled") {
      return html`<span class="bg-red-300 border rounded-full mb-1 mr-4 py-2 px-4 text-xs font-semibold"
				><span class="uppercase">${label}</span> ${sub.StartDate ? html`(${i18next.t("Cancelled at")}: ${formatDate(sub.CancellationDate)})` : ""}</span
			>`;
    } else {
      return html`<span class=" ${sub.EndDate < /* @__PURE__ */ new Date() && isIntrastat(label) ? "bg-red-300" : "bg-gray-300"} border rounded-full mb-1 mr-4 py-2 px-4 text-xs font-semibold"
				><span class="uppercase">${label}</span> ${sub.StartDate ? html`(${i18next.t("From")}: ${formatDate(sub.StartDate)}` : ""}
				${isIntrastat(label) ? html`; ${i18next.t("To")}: ${formatDate(sub.EndDate)}` : ""})
				${props.isAdmin && props.permissionManager.hasPermission("VAT_Modify") && sub.EndDate >= /* @__PURE__ */ new Date() ? html`<span
							class="ml-2 text-xs cursor-pointer font-semibold text-black bg-gray-400 rounded-full px-1"
							@click=${() => __async(void 0, null, function* () {
        setCurrentCancellationModalProps({
          contractFrom: sub.StartDate,
          contractTo: sub.EndDate,
          subscriptionId: sub.Id,
          cancelSubscription: props.callbackHandler.cancelSubscription
        });
        subCancellationModal.show();
      })}
							>&#10005;</span
					  >` : ""}</span
			>`;
    }
  };
  const htmlCountrySubServices = (countryService) => {
    return html`<div class="flex flex-wrap">
			${countryService.IntrastatIn ? htmlCountrySubServiceChip(i18next.t("Intrastat In"), countryService.IntrastatIn) : ""}
			${countryService.IntrastatOut ? htmlCountrySubServiceChip(i18next.t("Intrastat Out"), countryService.IntrastatOut) : ""}
			${countryService.FiscalRep ? htmlCountrySubServiceChip(i18next.t("Fiscal representation"), countryService.FiscalRep) : ""}
			${countryService.SpanishBankAccount ? htmlCountrySubServiceChip(i18next.t("Spanish bank account"), countryService.SpanishBankAccount) : ""}
			${countryService.Retrospective ? htmlCountrySubServiceChip(i18next.t("Retrospective"), countryService.Retrospective) : ""}
			${countryService.FilingAgent ? htmlCountrySubServiceChip(i18next.t("Filing agent"), countryService.FilingAgent) : ""}
		</div>`;
  };
  const htmlRequiredElectronicallyOnly = (vatReg) => {
    var _a2;
    return ((_a2 = vatReg.Documents) == null ? void 0 : _a2.filter((d) => d.Category === "RequiredElectronicallyOnly").length) > 0 ? html`<div class="text-sm">
						${i18next.t("Required electronically only")}
						<dc-tooltip
							.label=${i18next.t(
      "Please download the document(s) listed here, print, have them signed by the authorized signatory, then scan and upload. We will review the document(s), you don't have to post them as we can proceed with the signed copies."
    )}
						></dc-tooltip>
					</div>
					<table class="w-full">
						<tr>
							<th></th>
							<th>${i18next.t("Generated")}</th>
							<th>${i18next.t("Signed")}</th>
						</tr>
						${vatReg.Documents.filter((d) => d.Category === "RequiredElectronicallyOnly").map(
      (vatRegDocument) => html`<tr class="border-b h-10">
									<td>${vatRegDocument.TemplateName}</td>
									<td>
										<div class="flex justify-around">
											${vatRegDocument.Status === "InProgress" ? html`${VAT_REG_DOCUMENT_STATUS_TRANSLATIONS[vatRegDocument.Status]}` : ""}
											${vatRegDocument.Status === "Generated" ? html`<div
														class="cursor-pointer"
														@click=${() => __async(void 0, null, function* () {
        yield props.callbackHandler.downloadVatRegDocument(vatReg.Id, vatRegDocument.TemplateId, "Unsigned");
      })}
												  >
														${downloadPDFIcon()}
												  </div>` : ""}
											${vatRegDocument.Status === "Error" ? html`<div class="flex items-center">
														<div class="text-red-500" title=${VAT_REG_DOCUMENT_ERROR_TRANSLATIONS[vatRegDocument.ErrorCode]}>
															${VAT_REG_DOCUMENT_STATUS_TRANSLATIONS[vatRegDocument.Status]}
														</div>
														${(props == null ? void 0 : props.isAdmin) && props.permissionManager.hasPermission("VAT_Modify") && vatRegDocument.Status === "Error" ? html`<div class="ml-2">
																	<button
																		class="btn btn-primary btn-sm cursor-pointer"
																		@click=${() => __async(void 0, null, function* () {
        yield props.callbackHandler.generateVatRegDocument(vatReg.Id, vatRegDocument.TemplateId);
      })}
																	>
																		${i18next.t("Generate")}
																	</button>
															  </div>` : ""}
												  </div>` : ""}
										</div>
									</td>
									<td>
										<div class="flex justify-around">
											<div class="flex space-x-2 items-center">
												<div>
													${vatRegDocument.SignedDocumentReference ? html`<div
																class="cursor-pointer"
																@click=${() => __async(void 0, null, function* () {
        yield props.callbackHandler.downloadVatRegDocument(vatReg.Id, vatRegDocument.TemplateId, "Signed");
      })}
														  >
																${downloadPDFIcon()}
														  </div>` : ""}
												</div>
												<div>
													${!props.isAdmin && props.permissionManager.hasPermission("VAT_Modify") && vatReg.Status !== "WaitingForSignature" ? "" : html`<button
																class="btn btn-primary btn-sm cursor-pointer"
																@click=${() => __async(void 0, null, function* () {
        setCurrentVatRegId(vatReg.Id);
        setCurrentTemplateId(vatRegDocument.TemplateId);
        setShowSignedVatRegPDFUploadModal(true);
      })}
														  >
																${i18next.t("Upload")}
														  </button>`}
												</div>
											</div>
										</div>
									</td>
								</tr>`
    )}
					</table>` : "";
  };
  const htmlRequiredByPost = (vatReg) => {
    var _a2;
    return ((_a2 = vatReg.Documents) == null ? void 0 : _a2.filter((d) => d.Category === "RequiredByPost").length) > 0 ? html`<div class="text-sm">
						${i18next.t("Required by post")}
						<dc-tooltip
							.label=${i18next.t(
      "Please download the document(s) listed here, print, have them signed by the authorized signatory, then scan and upload. We will review the document(s), if everything is in order you must send them to our address by post."
    )}
						></dc-tooltip>
					</div>
					<table class="w-full">
						<tr>
							<th></th>
							<th>${i18next.t("Generated")}</th>
							<th>${i18next.t("Signed")}</th>
						</tr>
						${vatReg.Documents.filter((d) => d.Category === "RequiredByPost").map(
      (vatRegDocument) => html`<tr class="border-b h-10">
									<td>${vatRegDocument.TemplateName}</td>
									<td>
										<div class="flex justify-around">
											${vatRegDocument.Status === "InProgress" ? html`${VAT_REG_DOCUMENT_STATUS_TRANSLATIONS[vatRegDocument.Status]}` : ""}
											${vatRegDocument.Status === "Generated" ? html`<div
														class="cursor-pointer "
														@click=${() => __async(void 0, null, function* () {
        yield props.callbackHandler.downloadVatRegDocument(vatReg.Id, vatRegDocument.TemplateId, "Unsigned");
      })}
												  >
														${downloadPDFIcon()}
												  </div>` : ""}
											${vatRegDocument.Status === "Error" ? html`<div class="flex items-center">
														<div class="text-red-500" title=${VAT_REG_DOCUMENT_ERROR_TRANSLATIONS[vatRegDocument.ErrorCode]}>
															${VAT_REG_DOCUMENT_STATUS_TRANSLATIONS[vatRegDocument.Status]}
														</div>
														${(props == null ? void 0 : props.isAdmin) && props.permissionManager.hasPermission("VAT_Modify") && vatRegDocument.Status === "Error" ? html`<div class="ml-2">
																	<button
																		class="btn btn-primary btn-sm cursor-pointer"
																		@click=${() => __async(void 0, null, function* () {
        yield props.callbackHandler.generateVatRegDocument(vatReg.Id, vatRegDocument.TemplateId);
      })}
																	>
																		${i18next.t("Generate")}
																	</button>
															  </div>` : ""}
												  </div>` : ""}
										</div>
									</td>
									<td>
										<div class="flex justify-around">
											<div class="flex space-x-2 items-center">
												<div>
													${vatRegDocument.SignedDocumentReference ? html`<div
																class="cursor-pointer"
																@click=${() => __async(void 0, null, function* () {
        yield props.callbackHandler.downloadVatRegDocument(vatReg.Id, vatRegDocument.TemplateId, "Signed");
      })}
														  >
																${downloadPDFIcon()}
														  </div>` : ""}
												</div>
												<div>
													${!props.isAdmin && props.permissionManager.hasPermission("VAT_Modify") && vatReg.Status !== "WaitingForSignature" ? "" : html`<button
																class="btn btn-primary btn-sm cursor-pointer"
																@click=${() => __async(void 0, null, function* () {
        setCurrentVatRegId(vatReg.Id);
        setCurrentTemplateId(vatRegDocument.TemplateId);
        setShowSignedVatRegPDFUploadModal(true);
      })}
														  >
																${i18next.t("Upload")}
														  </button>`}
												</div>
											</div>
										</div>
									</td>
								</tr>`
    )}
					</table>` : "";
  };
  const htmlForInformationOnly = (vatReg) => {
    var _a2;
    return ((_a2 = vatReg.Documents) == null ? void 0 : _a2.filter((d) => d.Category === "ForInformationOnly").length) > 0 ? html`<div class="text-sm">
						${i18next.t("For information only")}
						<dc-tooltip .label=${i18next.t("There is no action required on your side.")}></dc-tooltip>
					</div>
					<table class="w-full">
						<tr>
							<th></th>
							<th>Generated</th>
						</tr>
						${vatReg.Documents.filter((d) => d.Category === "ForInformationOnly").map(
      (vatRegDocument) => html`<tr class="border-b h-10">
									<td>${vatRegDocument.TemplateName}</td>
									<td>
										<div class="flex justify-around">
											${vatRegDocument.Status === "InProgress" ? html`${VAT_REG_DOCUMENT_STATUS_TRANSLATIONS[vatRegDocument.Status]}` : ""}
											${vatRegDocument.Status === "Generated" ? html`<div
														class="cursor-pointer "
														@click=${() => __async(void 0, null, function* () {
        yield props.callbackHandler.downloadVatRegDocument(vatReg.Id, vatRegDocument.TemplateId, "Unsigned");
      })}
												  >
														${downloadPDFIcon()}
												  </div>` : ""}
											${vatRegDocument.Status === "Error" ? html`<div class="flex items-center">
														<div class="text-red-500" title=${VAT_REG_DOCUMENT_ERROR_TRANSLATIONS[vatRegDocument.ErrorCode]}>
															${VAT_REG_DOCUMENT_STATUS_TRANSLATIONS[vatRegDocument.Status]}
														</div>
														${(props == null ? void 0 : props.isAdmin) && props.permissionManager.hasPermission("VAT_Modify") && vatRegDocument.Status === "Error" ? html`<div class="ml-2">
																	<button
																		class="btn btn-primary btn-sm cursor-pointer"
																		@click=${() => __async(void 0, null, function* () {
        yield props.callbackHandler.generateVatRegDocument(vatReg.Id, vatRegDocument.TemplateId);
      })}
																	>
																		${i18next.t("Generate")}
																	</button>
															  </div>` : ""}
												  </div>` : ""}
										</div>
									</td>
								</tr>`
    )}
					</table>` : "";
  };
  const htmlDocuments = (vatReg) => {
    var _a2;
    return html`${((_a2 = vatReg.Documents) == null ? void 0 : _a2.length) > 0 ? html`<div class="my-2">${i18next.t("Documents")}:</div>` : ""} ${htmlRequiredElectronicallyOnly(vatReg)} ${htmlRequiredByPost(vatReg)}
		${htmlForInformationOnly(vatReg)}`;
  };
  const htmlStatus = (companyServices, vatReg, countryService) => {
    return html`<div>
			${(props == null ? void 0 : props.isAdmin) ? html`<dc-dropdown
						.isStatusLike=${true}
						.buttonText=${getStatusLabel(vatReg.Status)}
						.selectedValues=${[vatReg.Status]}
						.readonly=${!props.permissionManager.hasPermission("VAT_Modify")}
						.dataSource=${[
      {
        label: getStatusLabel(vatReg.Status),
        value: vatReg.Status
      }
    ].concat(
      getValidNextVatTransition(vatReg.Status).map((status) => ({
        label: getStatusLabel(status),
        value: status
      }))
    )}
						@click=${(e) => {
      const newVatRegStatus = e.detail.selectedValue;
      if (vatReg.Status === "DocumentsSigned" && newVatRegStatus === "WaitingForSignature") {
        setCurrentCommentModalProps({
          save: (comment) => __async(void 0, null, function* () {
            yield props.callbackHandler.changeVatRegStatus(vatReg.Id, vatReg.Status, newVatRegStatus, void 0, comment, void 0);
          })
        });
        commentModal.show();
      } else if (vatReg.Status === "SentToTA" && newVatRegStatus === "LocalTaxReceived") {
        const hasIntrastat = countryService.IntrastatIn !== void 0 || countryService.IntrastatOut !== void 0;
        setCurrentLocalVatModalProps({
          localVatCertificate: vatReg.LocalVatCertificate,
          submissionConfig: props.submissionConfig,
          euCountries: props.euCountries,
          bankAccountConfig: vatReg.BankAccountConfig,
          predefinedBankAccounts: props.predefinedBankAccounts,
          serviceCountryCode: vatReg.ServiceCountry.Code,
          establishmentCountryCode: companyServices.EstablishmentCountryCode,
          hasIntrastatSubscription: hasIntrastat,
          save: (localVatCertificate) => __async(void 0, null, function* () {
            yield props.callbackHandler.changeVatRegStatus(vatReg.Id, vatReg.Status, newVatRegStatus, void 0, void 0, localVatCertificate);
          }),
          uploadLocalVatCertificate: (file) => {
            return props.callbackHandler.uploadVatDocument(vatReg.Id, file);
          }
        });
        localVatModal.show();
      } else if (vatReg.Status === "LocalTaxReceived" && newVatRegStatus === "RegistrationCompleted") {
        setCurrentEuVatModalProps({
          euVatCertificate: vatReg.EUVatCertificate,
          serviceCountryCode: vatReg.ServiceCountry.Code,
          save: (registrationCompletedChange) => __async(void 0, null, function* () {
            yield props.callbackHandler.changeVatRegStatus(vatReg.Id, vatReg.Status, newVatRegStatus, registrationCompletedChange, void 0, void 0);
          }),
          uploadEuVatCertificate: (file) => {
            return props.callbackHandler.uploadVatDocument(vatReg.Id, file);
          }
        });
        euVatModal.show();
      } else if (vatReg.Status === "DeregistrationInProgress" && newVatRegStatus === "Deregistered") {
        setCurrentDeregistrationModalProps({
          change: {},
          save: (change) => __async(void 0, null, function* () {
            yield props.callbackHandler.changeVatRegStatus(vatReg.Id, vatReg.Status, newVatRegStatus, void 0, void 0, void 0, change);
          }),
          uploadDocument: (file) => {
            return props.callbackHandler.uploadVatDocument(vatReg.Id, file);
          }
        });
        deregistrationModal.show();
      } else if (vatReg.Status === "RevokingPoA" && newVatRegStatus === "PoARevoked") {
        setCurrentPoARevokedModalProps({
          change: {},
          save: (change) => __async(void 0, null, function* () {
            yield props.callbackHandler.changeVatRegStatus(vatReg.Id, vatReg.Status, newVatRegStatus, void 0, void 0, void 0, void 0, change);
          }),
          uploadDocument: (file) => {
            return props.callbackHandler.uploadVatDocument(vatReg.Id, file);
          }
        });
        poaRevokedModal.show();
      } else {
        props.callbackHandler.changeVatRegStatus(vatReg.Id, vatReg.Status, newVatRegStatus);
      }
    }}
				  ></dc-dropdown>` : html`<div>
						<div>${getStatusLabel(vatReg.Status)}</div>
						<div class="${vatReg.Status === "WaitingForSignature" ? "" : "hidden"}">
							<button
								class="btn btn-primary"
								?disabled=${vatReg.Status !== "WaitingForSignature" || vatReg.Documents.some((doc) => doc.SignedDocumentReference === void 0 && doc.Category !== "ForInformationOnly")}
								@click=${() => __async(void 0, null, function* () {
      yield props.callbackHandler.finalizeVatRegUpload(vatReg.Id);
    })}
							>
								${i18next.t("Finalize Upload")}
							</button>
						</div>
				  </div>`}
		</div>`;
  };
  const htmlFrequencyLabel = (freq) => {
    if (freq === void 0) {
      return i18next.t("N/A");
    }
    return html`${SUBMISSION_FREQUENCY_TYPE_LABELS[freq.FrequencyType]}${freq.EFiling !== void 0 && !freq.EFiling ? html`, ${i18next.t("E-filing")}: ${mapBooleanToState(freq.EFiling)}` : ""}${freq.FilingExtension !== void 0 && freq.FilingExtension ? html`, ${i18next.t("Filing extension")}: ${mapBooleanToState(freq.FilingExtension)}` : ""}`;
  };
  const htmlConfigs = (companyServices, vatReg) => {
    let bankAccountText = "-";
    if (vatReg.BankAccountConfig) {
      if (vatReg.BankAccountConfig.BankAccount) {
        const bankAccount = vatReg.BankAccountConfig.BankAccount;
        bankAccountText = `${bankAccount.BankName} (${bankAccount.BankAddress ? bankAccount.BankAddress : "-"}), Iban: ${bankAccount.Iban}, Swift: ${bankAccount.Swift}`;
      } else if (vatReg.BankAccountConfig.BankAccountRef) {
        bankAccountText = "Loading...";
        if (props.predefinedBankAccounts) {
          const bankAccount = props.predefinedBankAccounts.find((pba) => pba.Id === vatReg.BankAccountConfig.BankAccountRef);
          if (bankAccount !== void 0) {
            bankAccountText = `${bankAccount.BankName} (${bankAccount.BankAddress ? bankAccount.BankAddress : "-"}), Iban: ${bankAccount.Iban}, Swift: ${bankAccount.Swift}`;
          }
        }
      } else if (vatReg.BankAccountConfig.BankAccountUrl) {
        bankAccountText = vatReg.BankAccountConfig.BankAccountUrl;
      }
    }
    const primaryVatFrequencies = getFrequencyDataSource(props.submissionConfig, vatReg.ServiceCountry.Code, "PrimaryVAT");
    const secondaryVatFrequencies = getFrequencyDataSource(props.submissionConfig, vatReg.ServiceCountry.Code, "SecondaryVAT");
    const vlsFrequencies = getFrequencyDataSource(props.submissionConfig, vatReg.ServiceCountry.Code, "VLS");
    const eslFrequencies = getFrequencyDataSource(props.submissionConfig, vatReg.ServiceCountry.Code, "ESL");
    const intrastatFrequencies = getFrequencyDataSource(props.submissionConfig, vatReg.ServiceCountry.Code, "Intrastat");
    const hasIntrastatService = companyServices.CountryServices.some((cs) => cs.Country.Code === vatReg.ServiceCountry.Code && (cs.IntrastatIn || cs.IntrastatOut));
    const htmlSetNotification = (eventType) => {
      var _a2, _b, _c;
      if (props.isAdmin) {
        return html`<span
					class="hover:pointer"
					title="Edit client subscriptions"
					@click=${() => {
          setSubscriptionModalProps({
            eventType,
            search: () => {
              return props.callbackHandler.searchRegNotificationSubscriptions(props.companyServices.Id, vatReg.Id, eventType);
            },
            subscribe: (userId) => __async(void 0, null, function* () {
              try {
                props.updateRegNotificationSubscription(props.companyServices.Id, vatReg.Id, eventType, userId, true);
              } catch (err) {
                notifyError(err);
              }
            }),
            unsubscribe: (userId) => __async(void 0, null, function* () {
              yield props.updateRegNotificationSubscription(props.companyServices.Id, vatReg.Id, eventType, userId, false);
            })
          });
          subscriptionModal.show();
        }}
					>${subscribeIcon("Button")}</span
				>`;
      }
      {
        const isSubscribed = (_c = (_b = (_a2 = vatReg.RegNotificationSubscriptions) == null ? void 0 : _a2.find((notSub) => {
          return notSub.eventType === eventType;
        })) == null ? void 0 : _b.isSubscribed) != null ? _c : true;
        return html`<span
					class="hover:pointer"
					title=${isSubscribed ? i18next.t("Unsubscribe") : i18next.t("Subscribe")}
					@click=${() => __async(void 0, null, function* () {
          if (isSubscribed) {
            if (yield confirmModal.confirm(
              html`<div>
										<div>
											${i18next.t("freq-notification-confirmation", {
                eventType,
                countryName: vatReg.ServiceCountry.Name
              })}
										</div>
										<div>${i18next.t("freq-notification-confirmation-affect")}</div>
									</div>`
            )) {
              yield props.updateRegNotificationSubscription(props.companyServices.Id, vatReg.Id, eventType, void 0, false);
            }
          } else {
            yield props.updateRegNotificationSubscription(props.companyServices.Id, vatReg.Id, eventType, void 0, true);
          }
        })}
					>${subscribeIcon(isSubscribed ? "Active" : "Inactive")}</span
				>`;
      }
    };
    const htmlConfigRow = (showEditCol1, showViewCol2, htmlEditCol1, htmlViewCol2, htmlNotification) => {
      if (showEditCol1 || showViewCol2) {
        return html`<div>${showEditCol1 ? htmlEditCol1() : ""}</div>
					<div class="flex space-x-2">${showViewCol2 ? html`<div>${htmlViewCol2()}</div>` : ""} ${htmlNotification ? htmlNotification() : ""}</div>`;
      } else {
        return "";
      }
    };
    return html`<div class="space-y-2">
			<div class="grid-key-value">
				<!-- Bank Account -->
				${htmlConfigRow(
      props.isAdmin && props.permissionManager.hasPermission("VAT_Modify"),
      true,
      () => html`
						<span
							class="edit-icon-container"
							@click=${() => {
        setCurrentBankAccountModalProps({
          serviceCountryCode: vatReg.ServiceCountry.Code,
          isInEu: isEuCountry(props.euCountries, companyServices.EstablishmentCountryCode, true),
          bankAccountConfig: vatReg.BankAccountConfig,
          predefinedBankAccounts: props.predefinedBankAccounts,
          save: (bankAccountConfig) => __async(void 0, null, function* () {
            yield props.callbackHandler.changeBankAccountConfig(vatReg.Id, bankAccountConfig);
          })
        });
        bankAccountModal.show();
      }}
						>
							${editIcon()}
						</span>
					`,
      () => html`${i18next.t("Bank account")}: ${bankAccountText}`,
      void 0
    )}
				<!-- Payment reference -->
				${htmlConfigRow(
      props.isAdmin && props.permissionManager.hasPermission("VAT_Modify") && canHavePaymentReference(vatReg.ServiceCountry.Code),
      canHavePaymentReference(vatReg.ServiceCountry.Code),
      () => html`<span
							class="edit-icon-container"
							@click=${() => {
        setCurrentPaymentReferenceModalProps({
          paymentReference: vatReg.PaymentReference,
          save: (paymentReference) => __async(void 0, null, function* () {
            yield props.callbackHandler.changePaymentReference(vatReg.Id, paymentReference);
          })
        });
        paymentReferenceModal.show();
      }}
						>
							${editIcon()}
						</span>`,
      () => {
        var _a2;
        return html`${i18next.t("Payment reference")}: ${(_a2 = vatReg.PaymentReference) != null ? _a2 : "-"}`;
      },
      void 0
    )}
				<!-- Primary VAT frequency -->
				${htmlConfigRow(
      canEditFreq(primaryVatFrequencies, vatReg.PrimaryVATFrequencies),
      canShowFreq(primaryVatFrequencies, vatReg.PrimaryVATFrequencies),
      () => html`<span
							class="edit-icon-container"
							@click=${() => {
        setFrequencyModalProps({
          serviceCountryCode: vatReg.ServiceCountry.Code,
          isInEu: isEuCountry(props.euCountries, companyServices.EstablishmentCountryCode),
          submissionConfig: props.submissionConfig,
          currentFrequencies: vatReg.PrimaryVATFrequencies,
          returnType: FrequenciesChangeRequestReturnType.PrimaryVAT,
          save: (sendEmail, newFrequencyConfigs, frequencyConfigIdsToBeRemoved) => __async(void 0, null, function* () {
            yield props.callbackHandler.changeFrequencies(
              vatReg.Id,
              sendEmail,
              FrequenciesChangeRequestReturnType.PrimaryVAT,
              newFrequencyConfigs,
              frequencyConfigIdsToBeRemoved
            );
          })
        });
        frequencyModal.show();
      }}
						>
							${editIcon()}
						</span>`,
      () => html`${i18next.t("Primary Vat Frequency")}: ${htmlFrequencyLabel(getEffectiveFrequency(vatReg.PrimaryVATFrequencies))}`,
      void 0
    )}

				<!-- Secondary VAT frequency -->
				${htmlConfigRow(
      canEditFreq(secondaryVatFrequencies, vatReg.SecondaryVATFrequencies),
      canShowFreq(secondaryVatFrequencies, vatReg.SecondaryVATFrequencies),
      () => html`<span
							class="edit-icon-container"
							@click=${() => {
        setFrequencyModalProps({
          serviceCountryCode: vatReg.ServiceCountry.Code,
          isInEu: isEuCountry(props.euCountries, companyServices.EstablishmentCountryCode),
          submissionConfig: props.submissionConfig,
          currentFrequencies: vatReg.SecondaryVATFrequencies,
          returnType: FrequenciesChangeRequestReturnType.SecondaryVAT,
          save: (sendEmail, newFrequencyConfigs, frequencyConfigIdsToBeRemoved) => __async(void 0, null, function* () {
            yield props.callbackHandler.changeFrequencies(
              vatReg.Id,
              sendEmail,
              FrequenciesChangeRequestReturnType.SecondaryVAT,
              newFrequencyConfigs,
              frequencyConfigIdsToBeRemoved
            );
          })
        });
        frequencyModal.show();
      }}
						>
							${editIcon()}
						</span>`,
      () => html`${i18next.t("Secondary Vat Frequency")}: ${htmlFrequencyLabel(getEffectiveFrequency(vatReg.SecondaryVATFrequencies))}`,
      void 0
    )}
				<!-- VLS frequency -->
				${htmlConfigRow(
      canEditFreq(vlsFrequencies, vatReg.VLSFrequencies),
      canShowFreq(vlsFrequencies, vatReg.VLSFrequencies),
      () => html`<span
							class="edit-icon-container"
							@click=${() => {
        setFrequencyModalProps({
          serviceCountryCode: vatReg.ServiceCountry.Code,
          isInEu: isEuCountry(props.euCountries, companyServices.EstablishmentCountryCode),
          submissionConfig: props.submissionConfig,
          currentFrequencies: vatReg.VLSFrequencies,
          returnType: FrequenciesChangeRequestReturnType.VLS,
          save: (sendEmail, newFrequencyConfigs, frequencyConfigIdsToBeRemoved) => __async(void 0, null, function* () {
            yield props.callbackHandler.changeFrequencies(vatReg.Id, sendEmail, FrequenciesChangeRequestReturnType.VLS, newFrequencyConfigs, frequencyConfigIdsToBeRemoved);
          })
        });
        frequencyModal.show();
      }}
						>
							${editIcon()}
						</span>`,
      () => html`${i18next.t("VLS Frequency")}: ${htmlFrequencyLabel(getEffectiveFrequency(vatReg.VLSFrequencies))}`,
      () => {
        return htmlSetNotification("VLS");
      }
    )}
				<!-- ESL frequency -->
				${htmlConfigRow(
      canEditFreq(eslFrequencies, vatReg.ESLFrequencies),
      canShowFreq(eslFrequencies, vatReg.ESLFrequencies),
      () => html`<span
							class="edit-icon-container"
							@click=${() => {
        setFrequencyModalProps({
          serviceCountryCode: vatReg.ServiceCountry.Code,
          isInEu: isEuCountry(props.euCountries, companyServices.EstablishmentCountryCode),
          submissionConfig: props.submissionConfig,
          currentFrequencies: vatReg.ESLFrequencies,
          returnType: FrequenciesChangeRequestReturnType.ESL,
          save: (sendEmail, newFrequencyConfigs, frequencyConfigIdsToBeRemoved) => __async(void 0, null, function* () {
            yield props.callbackHandler.changeFrequencies(vatReg.Id, sendEmail, FrequenciesChangeRequestReturnType.ESL, newFrequencyConfigs, frequencyConfigIdsToBeRemoved);
          })
        });
        frequencyModal.show();
      }}
						>
							${editIcon()}
						</span>`,
      () => html`${i18next.t("ESL Frequency")}: ${htmlFrequencyLabel(getEffectiveFrequency(vatReg.ESLFrequencies))}`,
      () => {
        return htmlSetNotification("ESL");
      }
    )}
				<!-- Intrastat frequency -->
				${htmlConfigRow(
      hasIntrastatService && canEditFreq(intrastatFrequencies, vatReg.IntrastatFrequencies),
      hasIntrastatService && canShowFreq(intrastatFrequencies, vatReg.IntrastatFrequencies),
      () => html`<span
							class="edit-icon-container"
							@click=${() => {
        setFrequencyModalProps({
          serviceCountryCode: vatReg.ServiceCountry.Code,
          isInEu: isEuCountry(props.euCountries, companyServices.EstablishmentCountryCode),
          submissionConfig: props.submissionConfig,
          currentFrequencies: vatReg.IntrastatFrequencies,
          returnType: FrequenciesChangeRequestReturnType.Intrastat,
          save: (sendEmail, newFrequencyConfigs, frequencyConfigIdsToBeRemoved) => __async(void 0, null, function* () {
            yield props.callbackHandler.changeFrequencies(
              vatReg.Id,
              sendEmail,
              FrequenciesChangeRequestReturnType.Intrastat,
              newFrequencyConfigs,
              frequencyConfigIdsToBeRemoved
            );
          })
        });
        frequencyModal.show();
      }}
						>
							${editIcon()}
						</span>`,
      () => html`${i18next.t("Intrastat Frequency")}: ${htmlFrequencyLabel(getEffectiveFrequency(vatReg.IntrastatFrequencies))}`,
      void 0
    )}
			</div>
		</div>`;
  };
  const htmlCountryService = (companyServices, countryService, vatReg) => {
    var _a2, _b, _c;
    return html`<div class="mt-8 shadow border mx-8 px-8 py-4 xl:mx-auto xl:w-1/2 rounded ${countryService.ComplianceSubscriptionStatus === "Cancelled" ? "bg-red-200" : ""}">
			<div class="flex justify-between">
				<div class="font-bold text-lg">
					<div>${countryService.Country.Name}</div>
					<div class="uppercase">${countryService.VatNumber ? `(${countryService.VatNumber})` : ""}</div>
				</div>
				${htmlStatus(companyServices, vatReg, countryService)}
			</div>
			<div class="text-sm mt-4 border-b border-gray-300 pb-4">
				<span>${i18next.t("Service start date")}: ${countryService.ContractFrom ? formatDate(countryService.ContractFrom) : "-"}</span>
				&nbsp;|&nbsp;
				<span>${i18next.t("Service expiration date")}: ${countryService.ContractTo ? formatDate(countryService.ContractTo) : "-"}</span>
				&nbsp;|&nbsp;
				<span
					>${(vatReg == null ? void 0 : vatReg.ServiceKind) === "NewReg" ? i18next.t("Effective date of registration") : i18next.t("Start date of representation by KPMG")}:
					${formatDate(countryService.DateOfRegistration)}</span
				>
			</div>
			<div class="text-sm mt-4 pb-4">
				<div class="flex justify-between border-b border-gray-300 items-center pb-4">
					<div>
						${i18next.t("Compliance status")}: ${COMPLIANCE_SUBSCRIPTION_STATUS_LABELS[countryService.ComplianceSubscriptionStatus]}
						${interpretYearOneAsUndefined(countryService.ComplianceSubscriptionCancellationDate) ? html`(${formatDate(countryService.ComplianceSubscriptionCancellationDate)})` : ""}
					</div>
					<div>
						${props.isAdmin && props.permissionManager.hasPermission("VAT_Modify") && countryService.ComplianceSubscriptionStatus === "Active" ? html`<button
									class="btn btn-primary"
									@click=${() => {
      setCurrentCancellationModalProps({
        contractFrom: countryService.ContractFrom,
        contractTo: countryService.ContractTo,
        subscriptionId: countryService.ComplianceSubscriptionId,
        cancelSubscription: props.callbackHandler.cancelSubscription
      });
      subCancellationModal.show();
    }}
							  >
									${i18next.t("Cancel")}
							  </button>` : ""}
					</div>
				</div>
				<div class="text-sm mt-4 border-b border-gray-300 pb-4">${htmlConfigs(companyServices, vatReg)}</div>
				<div class="my-2 text-gray-400">${i18next.t("Services")}:</div>

				${htmlCountrySubServices(countryService)} ${htmlDocuments(vatReg)}
				${vatReg.LocalVatCertificate.LocalVatNumber ? html`<div class="flex space-x-2">
							<div>${i18next.t("Local VAT Number")}:</div>
							<div>${vatReg.LocalVatCertificate.LocalVatNumber} (${formatDate(vatReg.LocalVatCertificate.LocalVatRegistrationDate)})</div>
							${vatReg.LocalVatCertificate.LocalVATCertificateReferenceId ? html`<div
										class="cursor-pointer"
										@click=${() => __async(void 0, null, function* () {
      yield props.callbackHandler.downloadVatCertificate(vatReg.Id, "Local");
    })}
								  >
										${downloadPDFIcon()}
								  </div>` : ""}
					  </div>` : ""}
				${((_a2 = vatReg.EUVatCertificate.EUVatNumber) == null ? void 0 : _a2.VatNumber) ? html`<div class="flex space-x-2">
							<div>${i18next.t("EU VAT Number")}:</div>
							<div>${vatReg.EUVatCertificate.EUVatNumber.CountryCode}${vatReg.EUVatCertificate.EUVatNumber.VatNumber} (${formatDate(vatReg.EUVatCertificate.EUVatRegistrationDate)})</div>
							${vatReg.EUVatCertificate.EUVATCertificateReferenceId ? html`<div
										class="cursor-pointer"
										@click=${() => __async(void 0, null, function* () {
      yield props.callbackHandler.downloadVatCertificate(vatReg.Id, "Eu");
    })}
								  >
										${downloadPDFIcon()}
								  </div>` : ""}
					  </div>` : ""}
				${vatReg.TaxIdentifier ? html`<div class="flex space-x-2">
							${props.isAdmin && props.permissionManager.hasPermission("VAT_Modify_TaxIdentifier") ? html`<span
										class="edit-icon-container"
										@click=${() => __async(void 0, null, function* () {
      setCurrentTaxIdentifierModalProps({
        save: (taxIdentifier) => __async(void 0, null, function* () {
          yield props.callbackHandler.changeTaxIdentifier(props.companyServices.Id, vatReg.Id, taxIdentifier);
        })
      });
      yield taxIdentifierModal.show();
    })}
								  >
										${editIcon()}
								  </span>` : ""}

							<div>${i18next.t("Tax Identifier")}:</div>
							<div>${vatReg.TaxIdentifier}</div>
					  </div>` : ""}
				${vatReg.DeregistrationInfo ? html`<div class="flex space-x-2">
							<div>${i18next.t("Deregistered")}: (${formatDate(vatReg.DeregistrationInfo.DeregisteredAt)})</div>
							${((_b = vatReg.DeregistrationInfo) == null ? void 0 : _b.DocumentId) ? html`<div
										class="cursor-pointer"
										@click=${() => __async(void 0, null, function* () {
      yield props.callbackHandler.downloadVatDeregistrationDocument(vatReg.Id);
    })}
								  >
										${downloadPDFIcon()}
								  </div>` : ""}
					  </div>` : ""}
				${vatReg.PoARevocationInfo ? html`<div class="flex space-x-2">
							<div>${i18next.t("PoA revoked")}: (${formatDate(vatReg.PoARevocationInfo.PoARevokedAt)})</div>
							${((_c = vatReg.PoARevocationInfo) == null ? void 0 : _c.DocumentId) ? html`<div
										class="cursor-pointer"
										@click=${() => __async(void 0, null, function* () {
      yield props.callbackHandler.downloadPoaRevokedConfirmationDocument(vatReg.Id);
    })}
								  >
										${downloadPDFIcon()}
								  </div>` : ""}
					  </div>` : ""}
			</div>
		</div>`;
  };
  const htmlSignedVatRegPDFUploadModal = () => {
    if (showSignedVatRegPDFUploadModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showSignedVatRegPDFUploadModal}
						@close=${() => setShowSignedVatRegPDFUploadModal(false)}
						.header=${"Upload signed PDF"}
						.content=${html`<div>
							<dc-fileupload
								.label=${"PDF Document"}
								.fileName=${void 0}
								.fileReference=${void 0}
								.validationMessage=${void 0}
								.accept=${ONLY_PDF_ACCEPT_LIST}
								@upload=${(e) => __async(void 0, null, function* () {
        var _a2;
        if (((_a2 = e.detail) == null ? void 0 : _a2.files) && e.detail.files.length > 0) {
          yield props.callbackHandler.uploadSignedVatRegDocument(currentVatRegId, currentTemplateId, e.detail.files[0]);
          setShowSignedVatRegPDFUploadModal(false);
        }
      })}
							></dc-fileupload>

							<button class="btn btn-primary mt-4" @click=${() => setShowSignedVatRegPDFUploadModal(false)}>Cancel</button>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlModals = html`${htmlSignedVatRegPDFUploadModal()} ${commentModal.template()} ${localVatModal.template()} ${euVatModal.template()} ${subCancellationModal.template()}
	${deregistrationModal.template} ${poaRevokedModal.template}`;
  const servicesTemplate = (_a = props.companyServices) == null ? void 0 : _a.CountryServices.filter((cs) => props.showCancelledServices || cs.ComplianceSubscriptionStatus !== "Cancelled").map(
    (countryService) => htmlCountryService(
      props.companyServices,
      countryService,
      props.companyServices.VatRegistrations.find((vatReg) => vatReg.ServiceCountry.Code === countryService.Country.Code)
    )
  );
  const mainTemplate = html`${servicesTemplate}${bankAccountModal.template()}${paymentReferenceModal.template()}${frequencyModal.template()}${subscriptionModal.template()}${confirmModal.mainTemplate()}${taxIdentifierModal.template()}`;
  return { mainTemplate, htmlModals };
};
