import { TswagClient } from "./../commons/TswagClient";
import { SearchResult } from "./../models/V1/SubmissionDeadlineConfigurations/Search/SearchResult";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class SubmissionDeadlineConfigurations {
  /**
   * Creates an instance of SubmissionDeadlineConfigurations.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof SubmissionDeadlineConfigurations
   */
  constructor(baseUrl, http) {
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SearchRq_ _searchRq
   * @returns Promise<SearchRq_>
   * @memberof SubmissionDeadlineConfigurations
   */
  search(pSearchRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/SubmissionDeadlineConfigurations/search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pSearchRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
