import { AbstractValidator } from "fluent-ts-validator";
import { DCValidationResult } from "GlobalShared/haunted/HooksHelpers";
export const getEUVatChangeFields = () => {
  return [
    "EUVatCertificate",
    "EUVatNumber",
    "EUVatRegistrationDate",
    "PrimaryVatFrequency",
    "SecondaryVatFrequency",
    "VlsFrequency",
    "EslFrequency",
    "IntrastatFrequency",
    "PrimaryVatEFiling",
    "SecondaryVatEFiling",
    "PrimaryVatFilingExtension",
    "SecondaryVatFilingExtension",
    "VlsEFiling",
    "VlsFilingExtension",
    "EslEFiling",
    "EslFilingExtension",
    "IntrastatEFiling",
    "IntrastatFilingExtension"
  ];
};
export class EuVatChangeValidator extends AbstractValidator {
  constructor() {
    super();
    this.validateIfString((s) => {
      var _a;
      return (_a = s.EUVatCertificate) == null ? void 0 : _a.EUVATCertificateReferenceId;
    }).isNotEmpty().withPropertyName("EUVatCertificate").withFailureMessage("Required");
    this.validateIfString((s) => {
      var _a;
      return (_a = s.EUVatCertificate) == null ? void 0 : _a.EUVatNumber.VatNumber;
    }).isNotEmpty().withPropertyName("EUVatNumber").withFailureMessage("Required");
    this.validateIfDate((s) => {
      var _a;
      return (_a = s.EUVatCertificate) == null ? void 0 : _a.EUVatRegistrationDate;
    }).isNotEmpty().withPropertyName("EUVatRegistrationDate").withFailureMessage("Required");
  }
  validateAllFields(item) {
    return new DCValidationResult(super.validate(item));
  }
}
