import { SubscriptionIdentifier } from "./SubscriptionIdentifier";
import { VatRegistrationSubscription } from "./VatRegistrationSubscription";
export class SubscriptionIdentifierFactory {
  static create($type) {
    if ($type === "V1.Subscriptions.SubscriptionIdentifier") {
      const result = new SubscriptionIdentifier();
      return result;
    }
    if ($type === "V1.Subscriptions.VatRegistrationSubscription") {
      const result = new VatRegistrationSubscription();
      return result;
    }
    throw new Error($type + " not found");
  }
}
